import {gql} from '@apollo/client';

export const GET_ALL_FORMS = gql`
  query GetAllForms($searchString: String!, $offset: Int!, $limit: Int!) {
    forms(
      offset: $offset
      limit: $limit
      where: {name: {_ilike: $searchString}, isDeleted: {_eq: false}}
      order_by: {createdOn: desc}
    ) {
      name
      id
      categoryId
      isDefault
      statusId
      isHealthComponentIncluded
      formCategory {
        name
        code
      }
      metadata {
        metadata
      }
      # TODO: needed for tackling backend changes
      # formLog_aggregate {
      #   aggregate {
      #     count
      #   }
      # }
      formResponses_aggregate(where: {formLog: {status: {code: {_eq: "SUBMITTED"}}}}) {
        aggregate {
          count
        }
      }
    }
    aggregateForms(
      where: {name: {_ilike: $searchString}, isDeleted: {_eq: false}}
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const SEARCH_FORMS = gql`
  query GetAllForms($where: Form_bool_exp!, $offset: Int!, $limit: Int!) {
  forms(
    offset: $offset
    limit: $limit
    where: $where
    order_by: {createdOn: desc}
  ) {
    name
    id
    categoryId
    isDefault
    statusId
    isHealthComponentIncluded
    formCategory {
      name
      code
    }
    metadata {
      metadata
    }
    formResponses_aggregate(where: {formLog: {status: {code: {_eq: "SUBMITTED"}}}}) {
      aggregate {
        count
      }
    }
  }
  aggregateForms(where: $where) {
    aggregate {
      count
    }
  }
}
`;

const GET_ALL_FROMS_WITHOUT_AGGREGATE = gql`
  query GetAllForms {
    forms(order_by: {createdOn: desc}) {
      name
      id
      categoryId
      isDefault
      formCategory {
        name
        code
      }
      metadata {
        metadata
      }
    }
  }
`;

export const GET_FORMS_COUNT_FOR_NAME = gql`
  query getFormsCountForName($searchString: String!) {
    aggregateForms(
      where: {name: {_eq: $searchString}, isDeleted: {_eq: false}}
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_FORM_CATEGORY_BY_NAME = gql`
  query getFormCategoryByName($searchString: String!,$tenantId: uuid!) {
    formCategories(where: {isDeleted: {_eq: false}, tenantId: {_eq: $tenantId}, name: {_ilike: $searchString}}) {
      id
    }
  }
`;

export const GET_FORM_CATEGORY_BY_CODE = gql`
  query getFormCategoryByCode($searchString: String!, $tenantId: uuid!) {
    formCategories(where: {isDeleted: {_eq: false}, tenantId: {_eq: $tenantId}, code: {_ilike: $searchString}}) {
      id
    }
  }
`;


export const ADD_NEW_FORM = gql`
  mutation AddNewForm($data: FormBuilder!) {
    createFormBuilder(params: $data) {
      id
    }
  }
`;

export const ADD_NEW_FORM_CATEGORY = gql`
  mutation AddNewFormCategory($data: formCategory_insert_input!) {
    createFormCategory(object: $data) {
      id
    }
  }
`;

export const GET_FORM_BY_ID = gql`
  query GetFormById($id: uuid!) {
    form: getFormComponents(params: {formId: $id}) {
      components
      id
      name
      resourceTypeId
      formResource
      formMetadata
      categoryId
      isDefault
      isDeleted
      isShownToPatient
      isShareWithEHR
      formLocations {
        id
        isDeleted
        locationId
      }
    }
  }
`;

export const GET_FORM_NAME_BY_IDS = gql`
  query GetFormDetailById($formIds: [uuid!]) {
    forms(where: {id: {_in: $formIds}}) {
      id
      name
    }
  }
`;

export const GET_FORM_BY_CODE = gql`
  query GetFormByCode($code: String!) {
    form: getFormComponents(params: {code: $code}) {
      components
      id
      name
      resourceTypeId
      formResource
      formMetadata
    }
  }
`;

export const GET_FORM_SUBMISSION_REPORT = gql`
  query MyQuery($workflowMasterId: String!) {
    report: getFormSubmissionReport(
      params: {workflowMasterId: $workflowMasterId}
    ) {
      totalCount
      workflowMasterId
      stateCounts {
        count
        state
      }
    }
  }
`;

export const SUBMIT_FORM_RESPONSE = gql`
  mutation SubmitFormResponse($data: FormResponseInput!) {
    submitFormResponse(params: $data) {
      id
      contactId
    }
  }
`;

export const GET_FORMS_BY_CATEGORY = gql`
  query getFormsByCategoryCode($code: String!) {
    forms(where: {formCategory: {code: {_eq: $code}}, isDeleted: {_eq: false}}) {
      name
      id
      categoryId
      isDefault
      statusId
      isHealthComponentIncluded
      formCategory {
        name
        code
      }
      metadata {
        metadata
      }
    }
  }
`;

export const SUBMIT_TPA_FORM_RESPONSE = gql`
  mutation submitTpaFormResponse($data: FormResponseInput!) {
    submitTpaFormResponse(params: $data) {
      id
      contactId
    }
  }
`;

export const UPDATE_FORM_BY_ID = gql`
  mutation UpdateFormById($data: FormBuilder!) {
    updateFormBuilder(params: $data) {
      id
    }
  }
`;

export const DELETE_FORM_BY_ID = gql`
  mutation DeleteFormById($id: uuid!) {
    deleteFormBuilder(params: {id: $id}) {
      id
    }
  }
`;

export const GET_FORMS_WITH_NAMES = gql`
  query GetFormsWithNames($formNames: [String!]) {
    forms(where: {name: {_in: $formNames}, isDeleted: {_eq: false}}) {
      name
      id
    }
  }
`;

export const GET_FORM_RESPONSES_COUNT = gql`
  query MyQuery($workflowMasterId: String!) {
    report: getFormSubmissionReport(
      params: {workflowMasterId: $workflowMasterId}
    ) {
      totalCount
      workflowMasterId
      stateCounts {
        count
        state
      }
    }
  }
`;

export const GET_FORM_RESPONSE_BY_ID = gql`
  query GetFormResponseById($formId: uuid!) {
    formResponses(
      where: {
        formId: {_eq: $formId}
        formLog: {
          status: {code: {_eq: "SUBMITTED"}}
        }
      }
      order_by: {createdOn: desc}
    ) {
      createdOn
      updatedOn
      form {
        name
      }
      contactId
      formLog {
        createdOn
      }
      metadataScore(where: {isDeleted: {_eq: false}}) {
        id
        groupId
        metadata
      }
      id
      isFormDigitallySignedByContact
      isContactSignComponentPresent
    }
  }
`;
export const GET_FORM_RESPONSE_BY_ID_AND_LOCATION_IDS = gql`
  query GetFormResponseById($formId: uuid!, $locationIds: [uuid!]) {
    formResponses(
      where: {
        formId: {_eq: $formId}
        formLog: {
          status: {code: {_eq: "SUBMITTED"}}
          _or: [
            {accountLocationId: {_is_null: true}}
            {accountLocationId: {_in: $locationIds}}
          ]
        }
      }
      order_by: {createdOn: desc}
    ) {
      createdOn
      updatedOn
      form {
        name
      }
      contactId
      formLog {
        createdOn
      }
      metadataScore(where: {isDeleted: {_eq: false}}) {
        id
        groupId
        metadata
      }
      id
      isFormDigitallySignedByContact
      isContactSignComponentPresent
    }
  }
`;

export const GET_FORMS_BY_NAMES_WITH_AGGREGATE = gql`
  query GetFormsByNamesWithAggregate($formNames: [String!], $contactId: uuid!) {
    forms(where: {name: {_in: $formNames}, isDeleted: {_eq: false}}) {
      name
      id
      formResponses_aggregate(
        where: {isDeleted: {_eq: false}, contactId: {_eq: $contactId}, formLog: {status: {code: {_eq: "SUBMITTED"}}}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_FORMS_AGGREGATE_FOR_CONTACT = gql`
  query GetFormsAggregateForContact($contactId: uuid!, $formIds: [uuid!]) {
    forms(where: {id: {_in: $formIds}, isDeleted: {_eq: false}}) {
      id
      formResponses_aggregate(where: {isDeleted: {_eq: false}, contactId: {_eq: $contactId}, formLog: {status: {code: {_eq: "SUBMITTED"}}}}) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_FORMS_NAME_BY_ID = gql`
  query GetFormsAggregateForContact($formId: uuid!) {
    forms(where: {id: {_eq: $formId}, isDeleted: {_eq: false}}) {
      id
      name
    }
  }
`;

export const GET_FORM_RESPONSE = gql`
  query GetFormResponse($id: uuid!) {
    formResponse(id: $id) {
      id
      updatedOn
      formLog {
        createdOn
      }
      metadataScore(
        where: {isDeleted: {_eq: false}}
      ) {
        id
        groupId
        metadata
      }
      formResponse
    }
  }
`;

export const GET_DETAILS_FOR_CONTACTS = gql`
  query GetDetailsForContacts($where: contacts_bool_exp) {
    contactAggregate(where: $where) {
      aggregate {
        count
      }
    }
    contacts(where: $where) {
      id
      name
      email
      uuid
      contactType {
        contactType {
          value
          code
          id
        }
      }
      patient {
        patientUuid
      }
      additionalAttributes
      person {
        firstName
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          id
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
    }
  }
`;

export const GET_SUBMITTED_FORM = gql`
  query GetSubmittedForms($contactId: uuid) {
    formResponses(
      where: {contactId: {_eq: $contactId}, formLog: {status: {code: {_eq: "SUBMITTED"}}}}
      order_by: {createdOn: desc}
    ) {
      id
      updatedOn
      createdOn
      metadataScore {
        id
        groupId
        metadata
      }
      form {
        id
        name
        code
      }
      formResponse
    }
  }
`;

export const GET_SUBMITTED_FORM_BY_FORM_NAME_FILTER = gql`
  query GetSubmittedForms1($formName: String!, $contactId: uuid) {
    formResponses(
      order_by: {createdOn: desc}
      where: {form: {name: {_ilike: $formName}}, contactId: {_eq: $contactId}, formLog: {status: {code: {_eq: "SUBMITTED"}}}}
    ) {
      id
      updatedOn
      createdOn
      metadataScore {
        id
        groupId
        metadata
      }
      form {
        id
        name
        code
      }
      formResponse
    }
  }
`;

export const GET_CONTACT_FORM_LIST_BY_NAME = gql`
  query GetSubmittedForms1($formName: String!, $contactId: uuid) {
    contactFormsStatus(
      where: {name: {_ilike: $formName}, contactId: {_eq: $contactId}}
    ) {
      formId
      contactId
      name
      pendingCount
      submittedCount
    }
  }
`;

export const GET_CONTACT_DIGITAL_SIGNED_FORM_RESPONSE_BY_FORM_ID = gql`
  query GetSubmittedForms1($formIds: [uuid!]!, $contactId: uuid!) {
    formResponses(
      where: {
        formId: {_in: $formIds}
        contactId: {_eq: $contactId}
        isContactSignComponentPresent: {_eq: true}
        formLog: {status: {code: {_eq: "SUBMITTED"}}}
      }
    ) {
      id
      formId
      isFormDigitallySignedByContact
      isContactSignComponentPresent
    }
  }
`;

export const GET_CONTACT_PENDING_FORM_LIST = gql`
  query GetPendingForms($formId: uuid, $statusIds: [uuid!], $contactId: uuid) {
    formLogs(
      order_by: {createdOn: desc}
      where: {
        formId: {_eq: $formId}
        statusId: {_in: $statusIds}
        contactId: {_eq: $contactId}
      }
    ) {
      id
      createdOn
      sourceId
      createdBy
    }
  }
`;

export const GET_CONTACT_SUBMITED_FORM_LIST = gql`
  query GetSubmittedForms1($formId: uuid, $statusIds: [uuid!], $contactId: uuid) {
    formLogs(
      order_by: {createdOn: desc}
      where: {
        formId: {_eq: $formId}
        statusId: {_in: $statusIds}
        contactId: {_eq: $contactId}
      }
    ) {
      createdBy
      requestedById
      requestedByTypeCode
      sourceId
      formResponseId
      formResponse {
        metadataScore {
          id
          groupId
          metadata
        }
        formLog {
          createdOn
        }
        formResponse
        createdOn
        updatedOn
        isFormDigitallySignedByContact
        isContactSignComponentPresent
      }
    }
  }
`;

export const GET_CONTACT_SUBMITTED_FORM_BY_FORM_LOG_ID = gql`
  query GetSubmittedForms1($formLogId: uuid, $statusIds: [uuid!], $contactId: uuid) {
    formLogs(
      order_by: {createdOn: desc}
      where: {
        id: {_eq: $formLogId},
        statusId: {_in: $statusIds}
        contactId: {_eq: $contactId}
      },
      limit: 1
    ) {
      sourceId
      formResponseId
      formResponse {
        metadataScore {
          id
          groupId
          metadata
        }
        formResponse
        createdOn
        isFormDigitallySignedByContact
        isContactSignComponentPresent
      }
    }
  }
`;

export const CREATE_FORM_LOG = gql`
  mutation CreateFormLog($data: formLog_insert_input!) {
    createFormLog(object: $data) {
      id
    }
  }
`;

export const CREATE_MULTIPLE_FORM_LOG = gql`
  mutation CreateFormLogs($data: [formLog_insert_input!]!) {
    createFormLogs(objects: $data) {
      returning {
        id
      }
    }
  }
`;

export const GET_FORM_CATEGORIES = gql`
  query GetAllCategories {
    formCategories(
      where: {isDeleted: {_eq: false}}
      order_by: {createdOn: desc}
    ) {
      name
      id
      code
      description
    }
  }
`;

export const GET_DEFAULT_FORMS = gql`
  query GetAllDefaultForms {
    forms(where: {isDeleted: {_eq: false}}) {
      name
      id
      categoryId
      isDefault
      formCategory {
        name
        code
      }
    }
  }
`;

export const GET_FORM_METADATA = gql`
  query getFormMetadata($formId: uuid!) {
    form(id: $formId) {
      id
      name
      metadata {
        metadata
      }
    }
  }
`;

export const GET_FORMS_LOGS_BY_SUBJECT_ID = gql`
  query getFormStatus($formId: uuid!, $subjectId: uuid) {
    form(id: $formId) {
      name
    }
    formLogs(where: {formId: {_eq: $formId}, subjectId: {_eq: $subjectId}}) {
      status {
        code
        description
      }
    }
  }
`;

export const GET_FORMS_LOGS_BY_SUBJECT_ID_LIST = gql`
  query getFormsLogsBySubjectIdList($subjectIdList: [uuid!]) {
    submitted: aggregateFormLogs(where: {status: {code: {_eq: "SUBMITTED"}}, subjectId: {_in: $subjectIdList}}) {
      aggregate {
        count
      }
    }
    pending: aggregateFormLogs(where: {status: {code: {_eq: "PENDING"}}, subjectId: {_in: $subjectIdList}}) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_FORMS_LOGS = gql`
  query GetFormLogsData($formId: uuid!, $contactId: uuid!, $subjectId: uuid!) {
    formLogs(
      where: {
        formId: {_eq: $formId}
        contactId: {_eq: $contactId}
        subjectId: {_eq: $subjectId}
      }
    ) {
      statusId
      status {
        code
      }
      formResponse {
				id
        formResponse
      }
    }
  }
`;

export const GET_OR_CREATE_FORMS_LOGS = gql`
  mutation GetOrCreateFormLog($formId: uuid!, $contactId: uuid!, $subjectId: String!, $sourceId: uuid) {
    formLog: getOrCreateFormLog(params: {
      contactId: $contactId,
      formId: $formId,
      sourceId: $sourceId,
      subjectId: $subjectId,
    }) {
      id
      subjectId
      statusId
      accountLocationId
      status {
        code
      }
      formResponse {
        id
        formResponse
        foldPrefilledDataProgressByComponent,
        foldPrefilledDataProgressPercentage,
      }
    }
  }
`;

export const AUTHENTICATE_FORM_RESPONSE_WIDGET = gql`
  mutation authenticateSubmitFormResponseWidget($params: AuthenticateSubmitFormResponseWidgetInput!) {
    authenticateSubmitFormResponseWidget(params: $params) {
      formWidgetAccessToken
      formLogStatusCode
      contactProfileId
      formStatus {
        id
        code
        value
      }
    }
  }
`;

export const AUTHENTICATE_WIDGET_TOKEN = gql`
  mutation authenticateWidgetToken($params: AuthenticateWidgetTokenInput!) {
    authenticateWidgetToken(params: $params) {
      widgetAccessToken
    }
  }
`;

export const GENERATE_FORM_CODE_TYPE_SUBMIT_FORM_RESPONSE_WIDGET_TOKEN = gql`
  query generateFormCodeTypeSubmitFormResponseWidgetToken($params: GenerateFormCodeTypeSubmitFormResponseWidgetTokenInput!) {
    generateFormCodeTypeSubmitFormResponseWidgetToken(params: $params) {
      widgetToken
    }
  }
`;

export const GENERATE_WIDGET_TOKEN = gql`
  query generateWidgetToken($params: GenerateWidgetTokenInput!) {
    generateWidgetToken(params: $params) {
      widgetToken
    }
  }
`;


export const GET_FORMS_APPOINTMENT_TASKS_COUNT = gql`
  query GetFormsAppointmentTasksCount($tenantId: uuid) {
    formResponsesCount: aggregateFormResponses(
      where: {
        tenantId: {_eq: $tenantId},
        isDeleted: {_eq: false},
        formLog: {status: {code: {_eq: "SUBMITTED"}}}
      }
    ) {
      aggregate {
        count
      }
    }
    appointmentCount: aggregateAppointments(
      where: {
        tenantId: {_eq: $tenantId}
        status: {code: {_eq: "checked-out"}}
        isDeleted: {_eq: false}
      }
    ) {
      aggregate {
        count
      }
    }
    tasksCount: aggregateTasks(
      where: {
        tenantId: {_eq: $tenantId}
        status: {code: {_eq: "completed"}}
        isDeleted: {_eq: false}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_LAST_SUBMITTED_FORM = gql`
  query GetLastDaySubmittedForms($fromDate: timestamptz, $toDate: timestamptz) {
    formResponses(where: {createdOn: {_gt: $fromDate, _lt: $toDate}, formLog: {status: {code: {_eq: "SUBMITTED"}}, source: { code: {_nin: ["PATIENT_NOTES"]} } }}) {
      id
      updatedOn
      createdOn
      metadataScore {
        id
        groupId
        metadata
      }
      contactId
      form {
        id
        name
        code
      }
      formResponse
    }
  }
`;

export const GET_DEFAULT_THEME = gql`
  query GetDefaultTheme {
    accountConfigurations(
      where: {configuration: {code: {_eq: "DEFAULT_THEME"}}}
    ) {
      value
    }
  }
`;

export const GET_CONTACT_UUID_FROM_PATIENT_ID_AND_APPOINT_TYPE_ID = gql`
  query getAppointmentParticipants(
    $offset: Int
    $limit: Int
    $where: appointment_participant_bool_exp
  ) {
    appointmentParticipants(
      order_by: {contactId: asc}
      distinct_on: [contactId]
      where: $where
      offset: $offset
      limit: $limit
    ) {
      contactId
    }
    aggregateAppointmentParticipants(
      order_by: {contactId: asc}
      distinct_on: [contactId]
      where: $where
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const GET_NOTES_FORM = gql`
  query GetAllForms($searchString: String!, $categoryId: uuid!) {
    forms(
      where: {
        name: {_ilike: $searchString}
        isDeleted: {_eq: false}
        categoryId: {_eq: $categoryId}
      }
      order_by: {createdOn: desc}
    ) {
      name
      id
      categoryId
      isDefault
      formCategory {
        name
        code
      }
      metadata {
        metadata
      }
      formResponses_aggregate(where: {formLog: {status: {code: {_eq: "SUBMITTED"}}}}) {
        aggregate {
          count
        }
      }
    }
    aggregateForms(
      where: {
        name: {_ilike: $searchString}
        isDeleted: {_eq: false}
        categoryId: {_eq: $categoryId}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const GET_DEFAULT_FORM = gql`
  query GetDefaultForm($userId: uuid!, $userPreferencesTypeId: uuid!) {
    userPreferences(
      where: {
        userPreferencesTypeId: {_eq: $userPreferencesTypeId}
        userId: {_eq: $userId}
      }
    ) {
      id
      preferencesJson
    }
  }
`;

export const CREATE_USER_DEFAULT_NOTE_FORM = gql`
  mutation createOrUpdateDefaultNoteForm(
    $object: user_preferences_insert_input!
  ) {
    createUserPreference(
      object: $object
      on_conflict: {
        constraint: user_preferences_pkey
        update_columns: [preferencesJson, preferencesModelVersion]
      }
    ) {
      id
    }
  }
`;

export const GET_MEMBERS_SATISFACTION = gql`
  query GET_MEMBERS_SATISFACTION {
    forms(
      where: {
        isDeleted: {_eq: false}
        formCategory: {code: {_eq: "MEMBER_SATISFACTION"}}
      }
      order_by: {createdOn: desc}
    ) {
      name
      code
      id
      categoryId
      isDefault
      statusId
      formCategory {
        name
        code
      }
      metadata {
        metadata
      }

      formResponses_aggregate(where: {formLog: {status: {code: {_eq: "SUBMITTED"}}}}) {
        aggregate {
          count
        }
      }
    }
    aggregateForms {
      aggregate {
        count
      }
    }
  }
`;

export const GET_USERS_SATISFACTION = gql`
  query GET_MEMBERS_SATISFACTION {
    forms(
      where: {
        isDeleted: {_eq: false}
        formCategory: {code: {_eq: "USER_SATISFACTION"}}
      }
      order_by: {createdOn: desc}
    ) {
      name
      id
      categoryId
      isDefault
      statusId
      formCategory {
        name
        code
      }
      metadata {
        metadata
      }

      formResponses_aggregate(where: {formLog: {status: {code: {_eq: "SUBMITTED"}}}}) {
        aggregate {
          count
        }
      }
    }
    aggregateForms {
      aggregate {
        count
      }
    }
  }
`;

export const GET_EMPLOYER_SATISFACTION = gql`
  query GET_MEMBERS_SATISFACTION {
    forms(
      where: {
        isDeleted: {_eq: false}
        formCategory: {code: {_eq: "EMPLOYER_SATISFACTION"}}
      }
      order_by: {createdOn: desc}
    ) {
      name
      id
      categoryId
      isDefault
      statusId
      formCategory {
        name
        code
      }
      metadata {
        metadata
      }

      formResponses_aggregate(where: {formLog: {status: {code: {_eq: "SUBMITTED"}}}}) {
        aggregate {
          count
        }
      }
    }
    aggregateForms {
      aggregate {
        count
      }
    }
  }
`;

export const GET_FORMS_WITH_FILTERS = gql`
  query getAllForms($params: GetFormsInput!) {
    getForms(params: $params) {
      forms
      total
    }
  }
`

export const GET_FORM_LIST_WITH_FILTERS = gql`
  query GetAllForms(
    $offset: Int!
    $limit: Int!
    $where: Form_bool_exp!
    $aggregateWhere: Form_bool_exp!
  ) {
    forms(
      offset: $offset
      limit: $limit
      where: $where
      order_by: {createdOn: desc}
    ) {
      name
      id
      categoryId
      isDefault
      statusId
      formCategory {
        name
        code
      }
      metadata {
        metadata
      }
      formResponses_aggregate(
        where: {formLog: {status: {code: {_eq: "SUBMITTED"}}}}
      ) {
        aggregate {
          count
        }
      }
    }
    aggregateForms(where: $aggregateWhere) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PENDING_FORMS = gql`
query GetPendingForms($formId: uuid, $statusIds: [uuid!], $limit: Int!, $offset:Int!) {
  formLogs(
    order_by: {createdOn: desc}
    where: {
      formId: {_eq: $formId},
      statusId: {_in: $statusIds}
    },
    limit: $limit,
    offset: $offset
  ) {
    contactId
    createdBy
    createdOn
  }
}
`;

export const GET_PENDING_FORMS_CONTACT_UUIDS = gql`
  query GetPendingForms(
    $formId: uuid
    $statusIds: [uuid!]
    $locationIds: [uuid!]
  ) {
    aggregateFormLogs(
      where: {
        formId: {_eq: $formId}
        statusId: {_in: $statusIds}
        _or: [
          {accountLocationId: {_is_null: true}}
          {accountLocationId: {_in: $locationIds}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    formLogs(
      order_by: {updatedOn: desc}
      where: {
        formId: {_eq: $formId}
        statusId: {_in: $statusIds}
        _or: [
          {accountLocationId: {_is_null: true}}
          {accountLocationId: {_in: $locationIds}}
        ]
      }
    ) {
      contactId
    }
  }
`;

export const GET_PENDING_FORMS_COUNT = gql`
  query GetPendingForms($formId: uuid, $statusIds: [uuid!]) {
    aggregateFormLogs(
      where: {
        formId: {_eq: $formId}
        statusId: {_in: $statusIds}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const GET_PENDING_FORMS_COUNT_WITH_LOCATION_IDS = gql`
  query GetPendingForms($formId: uuid, $statusIds: [uuid!], $locationIds: [uuid!]) {
    aggregateFormLogs(
      where: {
        formId: {_eq: $formId}
        statusId: {_in: $statusIds}
        _or: [
          {accountLocationId: {_is_null: true}}
          {accountLocationId: {_in: $locationIds}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const SEARCH_FORM_BY_CATEGORY = gql`
  query GetAllForms($searchString: String!, $offset: Int!, $limit: Int!, $categoryId: uuid!) {
    forms(
      offset: $offset
      limit: $limit
      where: {name: {_ilike: $searchString}, isDeleted: {_eq: false}, categoryId: {_eq: $categoryId}}
      order_by: {createdOn: desc}
    ) {
      name
      id
      categoryId
      isDefault
      statusId
      isHealthComponentIncluded
      formCategory {
        name
        code
      }
      metadata {
        metadata
      }
    }
  }
`;

export const CREATE_FORM_LOG_LINK = gql`
  mutation createFormLogLink($params: createFormLogLinkInput!) {
    createFormLogLink(params: $params) {
      formLink
      formId
      formLogId
    }
  }
`;

export const GET_CARE_PLAN_FORMS = gql`
  query GET_CARE_PLAN_FORMS {
    forms(
      where: {isDeleted: {_eq: false}, formCategory: {code: {_eq: "CARE_PLAN"}}}
      order_by: {createdOn: desc}
    ) {
      name
      id
      code
    }
  }
`;

export const GET_FORM_RESPONSES_SCORE_BY_FORM_CATEGORY = gql`
  query formsResponses($category: String!, $contactId: uuid!,) {
    forms(
      where: {
        formCategory: {code: {_eq: $category}},
        formLog: {
          isDeleted: {_eq: false}
          contactId: {_eq: $contactId}
        }
      }
    ) {
      name
      formLog(
        where: {
          contactId: {_eq: $contactId}
          isDeleted: {_eq: false}
        },
        order_by: {createdOn: asc}
      ) {
        id
        status {
          code
        }
        formResponse {
          id
          createdOn
          metadataScore(where: {isDeleted: {_eq: false}}) {
            groupId
            metadata
          }
        }
        createdOn
        formId
      }
    }
  }
`;

export const UPDATE_NOTE_SHOWN_TO_PATIENT = gql`
  mutation updateNoteShownToPatient(
    $subjectTypeId: uuid
    $subjectTypeCode: String
    $isShownToPatient: Boolean
    $tenantId: uuid
  ) {
    insert_form_contact_share_permission(
      objects: {
        subjectTypeId: $subjectTypeId
        subjectTypeCode: $subjectTypeCode
        isShownToPatient: $isShownToPatient
      }
      on_conflict: {
        constraint: form_contact_share_permission_subject_type_id_key
        update_columns: [isShownToPatient]
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_NOTE_SHARE_WITH_EHR = gql`
  mutation updateNoteShareWithEhr(
    $subjectTypeId: uuid
    $subjectTypeCode: String
    $isShareWithEHR: Boolean
    $tenantId: uuid
  ) {
    insert_form_contact_share_permission(
      objects: {
        subjectTypeId: $subjectTypeId
        subjectTypeCode: $subjectTypeCode
        isShareWithEHR: $isShareWithEHR
      }
      on_conflict: {
        constraint: form_contact_share_permission_subject_type_id_key
        update_columns: [isShareWithEHR]
      }
    ) {
      affected_rows
    }
  }
`;

export const GET_FORM_RESPONSES_SCORE_BY_FORM_ID = gql`
  query formsResponses($formId: uuid!, $contactId: uuid!) {
    forms(
      where: {
        id: {_eq: $formId},
        formLog: {
          isDeleted: {_eq: false}
          contactId: {_eq: $contactId}
        }
      }
    ) {
      name
      id
      formLog(
        where: {
          contactId: {_eq: $contactId}
          isDeleted: {_eq: false}
          status: { code: {_eq: "SUBMITTED"}}
        },
        order_by: {createdOn: desc}
        limit: 1
      ) {
        id
        status {
          code
        }
        formResponse {
          id
          createdOn
          metadataScore(where: {isDeleted: {_eq: false}}) {
            groupId
            metadata
          }
        }
        createdOn
        formId
      }
    }
  }
`;

export const GET_FORM_RESPONSE_BY_FORM_LOG_ID = gql`
  query getFormResponse($formLogId: uuid!) {
    formLogs(where: {id: {_eq: $formLogId}}) {
      id
      subjectId
      sourceId
      statusId
      formResponse {
        id
        formResponse
        foldPrefilledDataProgressPercentage
        metadataScore(where: {isDeleted: {_eq: false}}) {
          id
          groupId
          metadata
        }
      }
    }
  }
`;

export const GET_MULTIPLE_FORM_RESPONSE_BY_FORM_LOG_IDS = gql`
  query getFormResponses($formLogId: [uuid!]) {
    formLogs(where: {id: {_in: $formLogId}}) {
      id
      subjectId
      sourceId
      formResponse {
        formResponse
        foldPrefilledDataProgressPercentage
      }
    }
  }
`;

export const GET_FORM_NAME_AND_ID = gql`
  query GET_FORM_NAME_AND_ID($where: form_response_bool_exp!) {
    formResponses(
      where: $where
      order_by: {createdOn: desc}
      limit: 1
    ) {
      formId
      formLog {
        id
        statusId
      }
      form {
        name
      }
    }
  }
`;

export const SEARCH_FORMS_WITH_LOCATION = gql`
  query searchFormsWithLocationsAndOtherFilters($params: GetFormsInput!) {
    searchForms(params: $params) {
      forms {
        id
        name
        formTypeId
        subjectTypeId
        isDeleted
        createdOn
        updatedOn
        code
        categoryId
        isDefault
        statusId
        isHealthComponentIncluded
        tenantId
        formLocations {
          locationId
        }
        formCategory {
          code
          id
        }
      }
    }
  }
`;

export const GET_FORM_CATEGORY_ID_BY_CODES = gql`
  query GetFormCategory($categoryCode: [String!], $tenantId: uuid!) {
    formCategories(where: {isDeleted: {_eq: false}, code: {_in: $categoryCode}, tenantId: {_eq: $tenantId}}) {
      id
      code
      name
    }
  }
`;

export const GET_RISK_SCORE_BY_FORM_LOG_ID = gql`
  query GetRiskScoreByFormId($formId: uuid!) {
    formLogs(where: {id: {_eq: $formId}}) {
      formResponse {
        metadataScore(where: {isDeleted: {_eq: false}}) {
          metadata
        }
      }
    }
  }
`;

export const GET_FORM_LOG = gql`
  query getPreviousResponseOfTheCurrentFormInstance($where: formLog_bool_exp) {
    formLogs(where: $where, order_by: {updatedOn: desc}) {
      id
      subjectId
      sourceId
      statusId
      updatedOn
      formResponse {
        formResponse
        foldPrefilledDataProgressPercentage
      }
    }
  }
`;

export const SUBMIT_PATIENT_INFORMATION_FORM_RESPONSE = gql`
   mutation submitPatientInformationFormResponse($data: FormResponseInput!) {
    submitPatientInformationFormResponse(params: $data) {
      id
      contactId
    }
  }
`;

export default {
  GET_PENDING_FORMS_COUNT,
  GET_PENDING_FORMS_COUNT_WITH_LOCATION_IDS,
  GET_FORM_LIST_WITH_FILTERS,
  CREATE_USER_DEFAULT_NOTE_FORM,
  GET_DEFAULT_FORM,
  GET_NOTES_FORM,
  GET_ALL_FORMS,
  SEARCH_FORMS,
  ADD_NEW_FORM,
  GET_FORM_BY_ID,
  SUBMIT_FORM_RESPONSE,
  UPDATE_FORM_BY_ID,
  DELETE_FORM_BY_ID,
  GET_FORM_SUBMISSION_REPORT,
  GET_FORMS_WITH_NAMES,
  GET_FORMS_AGGREGATE_FOR_CONTACT,
  GET_FORM_RESPONSES_COUNT,
  GET_FORM_RESPONSE_BY_ID,
  GET_FORM_RESPONSE_BY_ID_AND_LOCATION_IDS,
  GET_FORM_RESPONSE,
  GET_DETAILS_FOR_CONTACTS,
  GET_FORMS_BY_NAMES_WITH_AGGREGATE,
  GET_FORM_BY_CODE,
  GET_SUBMITTED_FORM,
  GET_FORMS_COUNT_FOR_NAME,
  GET_SUBMITTED_FORM_BY_FORM_NAME_FILTER,
  GET_CONTACT_FORM_LIST_BY_NAME,
  GET_CONTACT_DIGITAL_SIGNED_FORM_RESPONSE_BY_FORM_ID,
  GET_CONTACT_PENDING_FORM_LIST,
  GET_CONTACT_SUBMITED_FORM_LIST,
  CREATE_FORM_LOG,
  CREATE_MULTIPLE_FORM_LOG,
  GET_FORM_CATEGORIES,
  GET_DEFAULT_FORMS,
  GET_FORMS_LOGS,
  GET_FORMS_LOGS_BY_SUBJECT_ID,
  GET_FORM_METADATA,
  GET_ALL_FROMS_WITHOUT_AGGREGATE,
  GET_FORMS_NAME_BY_ID,
  GET_FORMS_APPOINTMENT_TASKS_COUNT,
  GET_LAST_SUBMITTED_FORM,
  GET_DEFAULT_THEME,
  GET_CONTACT_UUID_FROM_PATIENT_ID_AND_APPOINT_TYPE_ID,
  GET_MEMBERS_SATISFACTION,
  GET_USERS_SATISFACTION,
  GET_OR_CREATE_FORMS_LOGS,
  GET_CONTACT_SUBMITTED_FORM_BY_FORM_LOG_ID,
  AUTHENTICATE_FORM_RESPONSE_WIDGET,
  GET_FORMS_WITH_FILTERS,
  GET_PENDING_FORMS,
  GET_PENDING_FORMS_CONTACT_UUIDS,
  GET_EMPLOYER_SATISFACTION,
  GET_FORMS_LOGS_BY_SUBJECT_ID_LIST,
  GET_FORM_CATEGORY_BY_NAME,
  ADD_NEW_FORM_CATEGORY,
  GET_FORM_CATEGORY_BY_CODE,
  SEARCH_FORM_BY_CATEGORY,
  CREATE_FORM_LOG_LINK,
  SUBMIT_TPA_FORM_RESPONSE,
  GET_FORMS_BY_CATEGORY,
  AUTHENTICATE_WIDGET_TOKEN,
  GET_FORM_NAME_BY_IDS,
  GET_FORM_RESPONSES_SCORE_BY_FORM_CATEGORY,
  GET_FORM_RESPONSES_SCORE_BY_FORM_ID,
  GET_FORM_RESPONSE_BY_FORM_LOG_ID,
  GET_FORM_NAME_AND_ID,
  SEARCH_FORMS_WITH_LOCATION,
  GET_FORM_CATEGORY_ID_BY_CODES,
  GET_RISK_SCORE_BY_FORM_LOG_ID,
  SUBMIT_PATIENT_INFORMATION_FORM_RESPONSE,
};
