import moment from "moment";
import {ALL_MEMBERS} from "../../../RightSideContainer/Analytics/EmployerAnalytics/EmployerUtils/EmployerConst";
import {IFormattedLocationGroup, IPageSection, ISectionDisplayConfig, ITaskAnalyticsFilterView} from "./interface";


export const computeStyles = (sectionDisplayConfig?: ISectionDisplayConfig) => {
    const styles = [];
    if (sectionDisplayConfig?.childSections) {
      styles.push({
        ...sectionDisplayConfig?.childSections,
      });
    }
    if (sectionDisplayConfig?.section) {
      styles.push({
        ...sectionDisplayConfig?.section,
      });
    }
    return styles;
};

export const fetchResourceCode = (
  pageSections: IPageSection[],
  resourceObj: string[]
): string[] => {
  pageSections?.forEach((pageSection: IPageSection) => {

    if (!pageSection?.resourceId && pageSection?.childSections?.length) {
      fetchResourceCode(pageSection.childSections, resourceObj);
    } else {
      if (pageSection?.resource?.code) {
        resourceObj.push(pageSection?.resource?.code);
      }
    }
  });
  return resourceObj;
};


export const getInfoTitle = (pageSections: IPageSection) => {
  return pageSections?.additionalAttributes?.tooltip?.info || ''
}

export const getCheckToShowClearAll = (data: ITaskAnalyticsFilterView) => {
  return data?.patientContext
  || data.taskLabelsIds?.length
  || data?.taskPoolsIds?.length
  || data?.selectedTaskPriorityList?.length
  ||  data?.employerData.id != ALL_MEMBERS
  || (data?.dateRange?.from != moment().subtract(3, 'month')
    .startOf('month').format('YYYY-MM-DDTHH:mm:ss'))
  || (data?.dateRange?.to != moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss'))
  || (data?.locationGroupIds && data?.locationGroupIds?.length > 0)
}

export const getSelectedLocationGroups = (locationGroupIds: string[] | undefined, formattedLocationGroups: IFormattedLocationGroup[]): IFormattedLocationGroup[] => {
  const selectedLocationGroups = formattedLocationGroups.filter((g) => locationGroupIds?.includes(g.id)) as IFormattedLocationGroup[];
  return selectedLocationGroups;
};