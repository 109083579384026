import {useLazyQuery, useMutation} from '@apollo/client';
import {areQueriesEqual} from '@cubejs-client/core';
import {Skeleton, Spinner} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {View} from 'react-native';
import {ANALYTICS_RESOURCE, DISPLAY_TYPE, EMPLOYER_REPORT_FILTER_CODES, SECTION_LIST} from '../../../../../constants';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {AccountDashboard, EmployerDashboard, FormsQueries} from '../../../../../services';
import {drillDownByAccountId} from '../../../../../services/AccountDashboard/AccountDashboardServices';
import {
  getAccountUUID,
  getFilterObjByCode,
  getMemberSatisfactionFormsOnly,
  getTenantForDemo,
  getUniqueObjectFromKey,
  getUserUUID,
  isEmployerRole,
  isLoginUserStrictlyBusinessOwner,
  isMasterAccount,
  removeFilterObjFromList,
  updateFilterValue,
} from '../../../../../utils/commonUtils';
import {getMlovId} from '../../../../../utils/mlovUtils';
import {GraphMemberList} from '../../../../common/AppDashboard/GraphMemberList';
import {NoDataFound} from '../../../../common/NoDataFound';
import AnalyticsSections from '../../../Analytics/EmployerAnalytics/AnalyticsSections';
import CustomerSatisfactionDrawer from '../../../Analytics/EmployerAnalytics/CustomerSatisfactionDrawer/CustomerSatisfactionDrawer';
import DrillDownMemberList from '../../../Analytics/EmployerAnalytics/DrillDownMemberList/DrillDownMemberList';
import {
  WIDGET_ACTION_CODE,
  VIEW_CODE,
} from '../../../Analytics/EmployerAnalytics/EmployerUtils/EmployerConst';
import {
  getContactDrillDownColumnDataList,
  getContactUuidsFromContactData,
  getFlatListPageSections,
  getFormattedDashboardPage,
  updatePageIdToList,
} from '../../../Analytics/EmployerAnalytics/EmployerUtils/FormateDataUtils';
import {
  ISectionListItem,
  IPageView,
  IPageSection,
  IPageResponse,
  IContactDrillDownDataList,
  IMemberSatisfactionForm,
} from '../../../Analytics/EmployerAnalytics/interface';
import {AUTOMATION_TIME_SAVED_DRILL_DOWN, PATIENT_SATISFACTION_DRILL_DOWN} from '../../../Analytics/EmployerAnalytics/tempDataFile';
import DrillDownAutomationDrawer from '../../../Analytics/EmployerAnalytics/TimeSavedAutomationDrawer/DrillDownAutomationDrawer';
import {capitalizeText} from '../../../ContentManagement/CampaignManagement/Helper';
import WorkflowGraphPopup from '../../../Workflow/Workflow/WorkflowTableView/Helper/WorkflowGraphPopup';
import {IAccountAnalytics} from '../../interface';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {getAccountConfigValue, isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES, ON_OFF_CONFIG_VALUE} from '../../../../../constants/AccountConfigConst';
declare const window: any;

// const htmlToImage = require('html-to-image');

const AccountAnalytics = (props: IAccountAnalytics) => {
  const [stateData, setStateData] = useState({
    sectionList: [] as ISectionListItem[],
    loading: false,
    pageResponse: {} as IPageView,
    reusableData: {
      employeeCount: null,
    } as any,
    employerData: {} as any,
    rawPageSections: ['a', 'c'] as IPageSection[] | any[],
    isDashboardAssign: false,
    dashboardLoading: false,
    drillDownData: {} as any,
    selectedView: '',
    selectedResourceData: null,
    masterData: [] as any[],
    columnList: [],
    selectedFilterData: {} as any,
    memberSatisfactionFormList: [] as IMemberSatisfactionForm[],
  });
  const isMasterConfig = isMasterAccount()
  const hintMembershipDisabled = isAccountConfigEnabled(CONFIG_CODES.CONTACT_MEMBERSHIP_DISABLED)
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_PAGE = 1;
  const [contactsState, setContactsState] = React.useState({
    isLoading: false,
    isOpen: false,
    membersData: [] as any,
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
    toDate: '',
    fromDate: '',
    openContactListDrawer: false,
    contactUuidList: [] as string[],
    contactDrillDownDataList: {} as IContactDrillDownDataList,
    isCustomerFilterOpen: false,
    resourceCode: '',
    name: '',
    data: {} as any,
    selectedView: '',
    dummyContactList: [] as string[],
    filterCodeValue: '',
    responseData: {} as any,
  });
  const accountUuid = getAccountUUID();
  const userUuid = getUserUUID();

  const [GetMembersSatisfaction] = useLazyQuery(
    FormsQueries.GET_MEMBERS_SATISFACTION,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const [GetDashboard] = useLazyQuery(AccountDashboard.GetDashboardByAccountId);
  const [GetDefaultPage] = useLazyQuery(
    AccountDashboard.GetDefaultPageForDashboard
  );

  const [GetContactByCount] = useLazyQuery(
    EmployerDashboard.GetContactByCount,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const getContactByCount = async () => {
    const response = await GetContactByCount({
      variables: {
        limit: 100,
        accountUuid: accountUuid,
      },
    });
    if (response?.data?.contacts?.length) {
      const contactList = response?.data?.contacts?.map(
        (item: any) => item?.uuid
      );
      setContactsState((prev) => {
        return {
          ...prev,
          dummyContactList: [...contactList],
        };
      });
    }
  };
  const [CreatePage] = useMutation(AccountDashboard.CreatePage);
  const [CreatePageSection] = useMutation(AccountDashboard.CreatePageSections);
  const [CreateDashboard] = useMutation(AccountDashboard.CreateDashboard);
  const isShowNeedsAttentionWidget = getAccountConfigValue(CONFIG_CODES.ENABLE_NEEDS_ATTENTION_WIDGET)
  const [customerSatisfactiomDrawer, setCustomerSatisfactiomDrawer] =
    useState(false);

  const mlovData = useContext(CommonDataContext);
  const isUserBusinessOwner = isLoginUserStrictlyBusinessOwner();
  const AccountTypeDashboardId = getMlovId(
    mlovData.MLOV,
    'PageType',
    'ACCOUNT_DASHBOARD'
  );
  const getDashboardByAccountId = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
        dashboardLoading: true,
      };
    });
    const dashboardRes: any = await GetDashboard({
      variables: {
        accountUuid: accountUuid,
      },
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          pageResponse: {} as IPageView,
          dashboardLoading: false,
          reusableData: {
            ...prev.reusableData,
            isDashboardAssign: false,
          },
        };
      });
    });
    let memberSatisfactionFormList = [] as any[];
    const memberSatisfactionFormResp = await GetMembersSatisfaction();
    if (memberSatisfactionFormResp?.data?.forms?.length) {
      memberSatisfactionFormList = getMemberSatisfactionFormsOnly(memberSatisfactionFormResp?.data?.forms) as any[];;
    }
    setStateData((prev) => {
      return {
        ...prev,
        isDashboardAssign: true,
        reusableData: {
          ...prev.reusableData,
          isDashboardAssign: true,
        },
        memberSatisfactionFormList: [...memberSatisfactionFormList]
      };
    });
    if (
      dashboardRes &&
      dashboardRes?.data?.dashboards[0]?.page?.pageSections?.length
    ) {
      const dashboardPage: IPageResponse =
        dashboardRes?.data?.dashboards[0]?.page;
      const formattedData = getFormattedDashboardPage(dashboardPage);
      const newFormattedData = formattedData.sectionData.map((data) => {
        if (data.sectionCategory === SECTION_LIST.SAVINGS) {
          const groupWidgetsList: ISectionListItem[] = []
          const filteredChildSectionWidget = data.childSectionWidget.filter(item => {
            if (item.resourceCode == ANALYTICS_RESOURCE.TOTAL_SAVINGS) {
              return true
            } else {
              groupWidgetsList.push(item)
              return false
            }
          })
          const singleWidgetsGroup: ISectionListItem = {
            ...groupWidgetsList[0],
            name: 'Group saving',
            childSectionWidget: groupWidgetsList,
            resourceCode: ANALYTICS_RESOURCE.GROUP_SAVING,
            currentDisplayType: DISPLAY_TYPE.SAVING_GROUP_CUSTOM
          }
          filteredChildSectionWidget.push(singleWidgetsGroup)
          data['childSectionWidget'] = filteredChildSectionWidget
        }
        return data
      })
      newFormattedData.forEach((formattedData) => {
        if (formattedData.sectionCategory === SECTION_LIST.APP_ANALYTICS_ENGAGEMENT && isShowNeedsAttentionWidget===ON_OFF_CONFIG_VALUE.ON && isUserBusinessOwner) {
          formattedData.childSectionWidget.push(
            {
              id: 'id',
              name: 'NeedsAttention',
              sectionType: 'PATIENT_ENGAGEMENT',
              resourceCode: 'NEEDS_ATTENTION',
              childSectionWidget: [],
              isVisible: true,
              sequence: 3,
              appliedFilter: '',
              currentDisplayType: 'NEEDS_ATTENTION'
            }
          )
        }
      })
      const rawPageSections = dashboardPage.pageSections;
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          pageResponse: {...formattedData, sectionData: newFormattedData},
          sectionList: newFormattedData,
          dashboardLoading: false,
          rawPageSections: rawPageSections,
          isDashboardAssign: true,
          reusableData: {
            ...prev.reusableData,
            isDashboardAssign: true,
          },
        };
      });
    } else {
      await getDefaultPageForDashboard().catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
    }
  };

  const getDefaultPageForDashboard = async () => {
    const defaultPageRes = await GetDefaultPage({
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          pageResponse: {} as IPageView,
          isDashboardAssign: false,
          dashboardLoading: false,
          reusableData: {
            ...prev.reusableData,
            isDashboardAssign: false,
          },
        };
      });
    });
    if (defaultPageRes?.data?.pages?.length) {
      const defaultPage: IPageResponse[] = defaultPageRes?.data?.pages;
      const rawPageSections = defaultPage[0].pageSections;
      onSaveOrUpdateDashboard(rawPageSections);
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          pageResponse: {} as IPageView,
          sectionList: [],
          isDashboardAssign: false,
          dashboardLoading: false,
          reusableData: {
            ...prev.reusableData,
            isDashboardAssign: false,
          },
        };
      });
    }
  };

  const onSaveOrUpdateDashboard = async (rawPageSections: IPageSection[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        dashboardLoading: true,
        loading: true,
      };
    });
    const pageResponse = await CreatePage({
      variables: {
        name: `${capitalizeText(props.accountName || '')} Page`,
        pageType: AccountTypeDashboardId,
      },
    }).catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          dashboardLoading: false,
          loading: false,
        };
      });
    });
    if (pageResponse?.data?.createPage?.id) {
      const pageId = pageResponse?.data?.createPage?.id;
      addPageSectionsToNewPage(pageId, rawPageSections);
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          dashboardLoading: false,
          loading: false,
        };
      });
    }
  };

  const addPageSectionsToNewPage = async (
    pageId: string,
    rawPageSections: IPageSection[]
  ) => {
    const rawUpdatedSection = getFlatListPageSections(
      rawPageSections,
      userUuid
    );
    const updatedPageSectionsList = updatePageIdToList(
      pageId,
      rawUpdatedSection,
      userUuid
    );
    CreatePageSection({
      variables: {
        pageSections: updatedPageSectionsList,
      },
    })
      .then((res) => {
        if (res?.data?.createPageSections?.returning?.length) {
          createDashboard(pageId);
        } else {
          setStateData((prev) => {
            return {
              ...prev,
              dashboardLoading: false,
              loading: false,
            };
          });
        }
      })
      .catch((err) => {
        setStateData((prev) => {
          return {
            ...prev,
            dashboardLoading: false,
            loading: false,
          };
        });
      });
  };
  const createDashboard = async (pageId: string) => {
    const dashboardResp = await CreateDashboard({
      variables: {
        pageId: pageId,
        accountUuid: accountUuid,
        name: `${capitalizeText(props.accountName || '')} Dashboard`,
      },
    }).catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          dashboardLoading: false,
          loading: false,
        };
      });
    });
    if (dashboardResp?.data?.createDashboard?.id) {
      const dashboardId = dashboardResp?.data?.createDashboard?.id;
      getDashboardByAccountId();
      setStateData((prev) => {
        return {
          ...prev,
          dashboardLoading: false,
          loading: false,
          isDashboardAssign: true,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          dashboardLoading: false,
          loading: false,
        };
      });
    }
  };
  const onActionPerformed = (code: string, name: string, data?: any) => {
    switch (code) {
      case ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION:
        if (getTenantForDemo()) {
          setStateData((prev) => {
            return {
              ...prev,
              drillDownData: PATIENT_SATISFACTION_DRILL_DOWN.result,
              selectedView: VIEW_CODE.CUSTOMER_SATISFACTION_DRAWER,
              isLoading: false,
            };
          });
          setContactsState((prev) => {
            return {
              ...prev,
              isLoading: false,
            };
          });
        } else {
          onDrillDownViewCall(code || '', name, data);
        }
        // setCustomerSatisfactiomDrawer(true);

        break;
      case ANALYTICS_RESOURCE.AUTOMATION_TIME_SAVED:
        if (isMasterConfig) {
          return
        }
        if (getTenantForDemo()) {
          setStateData((prev) => {
            return {
              ...prev,
              drillDownData:
                AUTOMATION_TIME_SAVED_DRILL_DOWN.data.selectedDrillDownRowData,
              columnList: [],
              selectedView: VIEW_CODE.TIME_SAVED_AUTOMATION_DRAWER,
              isLoading: false,
            };
          });
          setContactsState((prev) => {
            return {
              ...prev,
              isLoading: false,
              name: name,
            };
          });
        } else {
          onDrillDownViewCall(code || '', name, data);
        }
        break;
      case ANALYTICS_RESOURCE.COMMUNICATION_STATS:
        data.type = ANALYTICS_RESOURCE.COMMUNICATION_STATS;
        onDrillDownViewCall(code || '', name, data);
        break
      default:
        onDrillDownViewCall(code || '', name, data);
        break;
    }
  };

  const setDummyContacts = (
    resourceCode: string,
    resourceName: string,
    data: any
  ) => {
    const name = data.name ? capitalizeText(data?.name) : '';
    const count = data?.filterData?.count || data?.count;
    if (contactsState?.dummyContactList) {
      setContactsState((prev) => {
        return {
          ...prev,
          openContactListDrawer: true,
          name: name ? name : resourceName,
          resourceCode: resourceCode,
          data: data,
          selectedView: VIEW_CODE.GROUP_MEMBER_VIEW,
          isLoading: false,
        };
      });
      const mutableData = [...contactsState?.dummyContactList];
      const countTedList =
        count < 100 || count < 100
          ? mutableData.slice(0, count)
          : [...contactsState?.dummyContactList];
      setContactsState((prev) => {
        return {
          ...prev,
          contactUuidList: [...countTedList],
          total: countTedList?.length,
        };
      });
    }
  };

  const onDrillDownViewCall = async (
    resourceCode: string,
    resourceName: string,
    data: any
  ) => {
    setContactsState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    if (getTenantForDemo()) {
      setDummyContacts(resourceCode, resourceName, data);
      return;
    }
    if (isMsoEnabled) {
      data.location = {
        locationIds: props.locations?.locationIds || [],
        locationGroupIds: props.locations?.locationGroupIds || []
      }
    }
    const name = data.name ? capitalizeText(data?.name) : '';
    if (hintMembershipDisabled) {
      data.filterData.push({
        filterCode: EMPLOYER_REPORT_FILTER_CODES.MEMBERSHIP,
        filterValue:hintMembershipDisabled
      })
    }
    const response = await drillDownByAccountId(
      resourceCode,
      contactsState.page,
      contactsState.pageSize,
      data,
      props.dateRange,
      props.memberType
    ).catch((err) => {
      setContactsState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    });
    const responseData = response?.data?.data || {};
    if (resourceCode === ANALYTICS_RESOURCE.COMMUNICATION_STATS) {
      data.count = response?.data?.data?.totalCount;
    }
    if (response?.data?.data?.contactIds?.length) {
      setContactsState((prev) => {
        return {
          ...prev,
          openContactListDrawer: true,
          name: name ? name : resourceName,
          resourceCode: resourceCode,
          data: data,
          selectedView: VIEW_CODE.GROUP_MEMBER_VIEW,
          isLoading: false,
          typeName: data?.type || '',
          responseData: responseData,
          contactDrillDownDataList: {} as any
        };
      });
      const contactList = response?.data?.data?.contactIds;
      setContactsState((prev) => {
        return {
          ...prev,
          contactUuidList: [...contactList],
          total: response?.data?.data?.totalCount,
        };
      });
    } else if (response?.data?.data?.contactDrillDownDataList?.length) {
      const contactUuidList = getContactUuidsFromContactData(
        response?.data?.data?.contactDrillDownDataList
      );
      const contactDrillDownDataList = getContactDrillDownColumnDataList(response?.data?.data);
      setContactsState((prev) => {
        return {
          ...prev,
          openContactListDrawer: true,
          name: name ? name : resourceName,
          resourceCode: resourceCode,
          data: data,
          contactDrillDownDataList: contactDrillDownDataList as any,
          selectedView: VIEW_CODE.GROUP_MEMBER_VIEW,
          isLoading: false,
          contactUuidList: [...contactUuidList],
          total: response?.data?.data?.totalCount,
          typeName: data?.type || '',
          responseData: responseData,
        };
      });
    } else if (
      response?.data?.result?.queAnsList &&
      response?.data?.result?.scoreData
    ) {
      setStateData((prev) => {
        return {
          ...prev,
          drillDownData: response?.data?.result,
          selectedView: VIEW_CODE.CUSTOMER_SATISFACTION_DRAWER,
          isLoading: false,
          responseData: responseData,
          contactDrillDownDataList: {} as any
        };
      });
      setContactsState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    } else if (response?.data?.data?.selectedDrillDownRowData) {
      const selectedFilterData = data?.filterData?.find((item: any) => {
        return item?.filterCode === 'CONDITION'
      })
      const selectedDrillDownData = response?.data?.data?.selectedDrillDownRowData?.find((item: any) => {
        return (
          item?.workflowMasterId === selectedFilterData?.workflowMasterId
        );
      })
      setStateData((prev) => {
        return {
          ...prev,
          drillDownData: response?.data?.data?.selectedDrillDownRowData,
          columnList: response?.data?.data?.columnList,
          selectedView: VIEW_CODE.TIME_SAVED_AUTOMATION_DRAWER,
          isLoading: false,
          typeName: data?.type || '',
          responseData: responseData,
          selectedFilterData: selectedDrillDownData,
        };
      });
      setContactsState((prev) => {
        return {
          ...prev,
          isLoading: false,
          name: name ? name : resourceName,
        };
      });
    } else {
      setContactsState((prev) => {
        return {
          ...prev,
          contactUuidList: [],
          openContactListDrawer: true,
          name: name ? name : resourceName,
          resourceCode: resourceCode,
          data: data,
          selectedView: VIEW_CODE.GROUP_MEMBER_VIEW,
          isLoading: false,
          typeName: data?.type || '',
          total: 0,
          responseData: responseData,
          contactDrillDownDataList: {} as any
        };
      });
    }
  };

  useEffect(() => {
    if (!contactsState?.responseData?.isNonMemberData && contactsState.selectedView === VIEW_CODE.GROUP_MEMBER_VIEW) {
      onDrillDownViewCall(
        contactsState.resourceCode,
        contactsState.name,
        contactsState.data
      );
    }
  }, [contactsState.page, contactsState.pageSize]);

  const onDrillDownMemberListPaginationChange = (page: number, pageSize: number, extraData?: any) => {
    if (extraData.isDropDownCodeChange && contactsState.page === 1) {
      setContactsState(prev => {
        return {
          ...prev,
          filterCodeValue: extraData.filterValue
        }
      })
      const formattedFilterData = contactsState.data.filterData.map((filterObj: any) => {
        if (filterObj.filterCode !== 'DATE_RANGE') {
          filterObj = {...filterObj, filterValue: extraData.filterValue};
          return filterObj
        } else {
          return filterObj
        }
      })
      const formattedDrillDownData = {...contactsState.data, filterData: formattedFilterData}
      if (extraData.filterCode && extraData.filterValue) {
        formattedDrillDownData.filterData.push({
          filterCode: extraData.filterCode,
          filterValue: extraData.filterValue,
        });
      }
      setContactsState(prev => {
        return {
          ...prev,
          data: formattedDrillDownData
        }
      })
      onDrillDownViewCall(
        contactsState.resourceCode,
        contactsState.name,
        formattedDrillDownData
      );
    } else if (extraData?.isFilterApplied && contactsState.page === 1) {
      if (contactsState.data.filterData?.length) {
        if (extraData?.filterValue) {
          const uniqueFilterList = getUniqueObjectFromKey(contactsState.data.filterData, 'filterCode');
          const givenFilterData = getFilterObjByCode(uniqueFilterList, extraData?.filterCode);
          if (givenFilterData && givenFilterData?.filterValue) {
            const tempFilterDataList = updateFilterValue(uniqueFilterList, extraData?.filterCode, extraData?.filterValue);
            const formattedDrillDownData = {...contactsState.data, filterData: tempFilterDataList}
            setContactsState(prev => {
              return {
                ...prev,
                data: formattedDrillDownData
              }
            })
            onDrillDownViewCall(
              contactsState.resourceCode,
              contactsState.name,
              formattedDrillDownData
            );
          } else {
            uniqueFilterList.push({
              filterCode: extraData?.filterCode,
              filterValue: {
                value: extraData?.filterValue
              }
            });
            const formattedDrillDownData = {...contactsState.data, filterData: uniqueFilterList}
            setContactsState(prev => {
              return {
                ...prev,
                data: formattedDrillDownData
              }
            })
            onDrillDownViewCall(
              contactsState.resourceCode,
              contactsState.name,
              formattedDrillDownData
            );
          }
        } else {
          const formattedFilterData = removeFilterObjFromList(contactsState.data.filterData, extraData?.filterCode);
          const formattedDrillDownData = {...contactsState.data, filterData: formattedFilterData}
          setContactsState(prev => {
            return {
              ...prev,
              data: formattedDrillDownData
            }
          })
          onDrillDownViewCall(
            contactsState.resourceCode,
            contactsState.name,
            formattedDrillDownData
          );
        }
      }

    } else {
      setContactsState((prev) => {
        return {
          ...prev,
          page: prev.pageSize !== pageSize ? 1 : page,
          pageSize,
        };
      });
    }

  }
  useEffect(() => {
    if (getTenantForDemo()) {
      getContactByCount();
    }
    getDashboardByAccountId();
  }, [props.memberType, props.dateRange?.to, props.locations?.locationGroupIds?.length]);

  return (
    <>
      {stateData?.loading || stateData.dashboardLoading ? (
        <Skeleton.Text style={{padding: 16}} lines={5} />
      ) : stateData?.sectionList?.length ? (
        <>
          <div className="common-style">
            {contactsState.isLoading ? (
              <div
                style={{
                  margin: '0 auto',
                  position: 'fixed',
                  zIndex: 100011111,
                  backgroundColor: '#0000000f',
                  height: '100%',
                  width: '100%',
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: 0,
                  left: 0,
                }}
              >
                <Spinner size="lg" />
              </div>
            ) : (
              <></>
            )}
            {stateData.selectedView ===
              VIEW_CODE.CUSTOMER_SATISFACTION_DRAWER ? (
              <CustomerSatisfactionDrawer
                drillDownData={stateData.drillDownData}
                isVisible={customerSatisfactiomDrawer}
                onCancel={() => {
                  // setCustomerSatisfactiomDrawer(false);
                  setStateData((prev) => {
                    return {
                      ...prev,
                      drillDownData: {} as any,
                      selectedView: '',
                      selectedResourceData: null,
                    };
                  });
                }}
              />
            ) : (
              <></>
            )}
            {stateData.selectedView ===
              VIEW_CODE.TIME_SAVED_AUTOMATION_DRAWER && false? (
              <DrillDownAutomationDrawer
                selectedDrillDownRowData={stateData.drillDownData}
                onClose={() => {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      drillDownData: {} as any,
                      selectedView: '',
                      selectedResourceData: null,
                    };
                  });
                }}
                columnList={stateData.columnList}
                page={contactsState.page}
                pageSize={contactsState.pageSize}
                total={contactsState.total}
                onPaginationChange={(page: number, pageSize: number) => {
                  setContactsState((prev) => {
                    return {
                      ...prev,
                      page,
                      pageSize,
                    };
                  });
                }}
                title={contactsState.name}
              />
            ) : (
              <></>
            )}
            {stateData.selectedView ===
              VIEW_CODE.TIME_SAVED_AUTOMATION_DRAWER ? (
              <WorkflowGraphPopup
                flowType={stateData.selectedFilterData.flowType}
                isShow={stateData.selectedFilterData.flowType ? true : false}
                workflowData={stateData.selectedFilterData}
                onClose={() => {
                  setStateData(prev => {
                    return {
                      ...prev,
                      selectedView: '',
                      selectedFilterData: {}
                    }
                  })
                }}
              ></WorkflowGraphPopup>
            ) : (
              <></>
            )}
            <AnalyticsSections
              memberSatisfactionFormList={stateData.memberSatisfactionFormList}
              sectionList={stateData.sectionList}
              employerData={stateData.employerData}
              isAccountDashboard={true}
              memberType={props.memberType}
              dateRangeType={props.dateRangeType}
              isEmployerRole={isEmployerRole()}
              reusableData={{
                ...stateData.reusableData,
                isDashboardAssign: stateData.isDashboardAssign,
              }}
              locations={props.locations}
              dateRange={props.dateRange}
              onActionPerformed={(type?: any, name?: any, data?: any) => {
                onActionPerformed?.(type, name, data);
              }}
              setStateData={(type: any, data: any) => {
                if (type == WIDGET_ACTION_CODE.OPEN_SAVING_MODAL) {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      selectedView: VIEW_CODE.SAVING_DRILL_DOWN,
                      selectedResourceCode: data,
                    };
                  });
                }
              }}
            />
          </div>
          {contactsState.selectedView === VIEW_CODE.GROUP_MEMBER_VIEW ? (
            <DrillDownMemberList
              appliedFilterData={contactsState?.data?.filterData}
              resourceCode={contactsState.resourceCode}
              isNonMemberDrillDown={contactsState?.responseData?.isNonMemberData}
              responseData={contactsState?.responseData}
              page={contactsState.page}
              pageSize={contactsState.pageSize}
              total={contactsState.total}
              data={contactsState.data}
              onPaginationChange={onDrillDownMemberListPaginationChange}
              title={contactsState.name}
              isFromNewEmployer={true}
              contactUuidList={contactsState.contactUuidList}
              contactDrillDownData={contactsState?.contactDrillDownDataList}
              onCompleteAction={() => {
                setContactsState((prev) => {
                  return {
                    ...prev,
                    contactList: [],
                    page: DEFAULT_PAGE,
                    pageSize: DEFAULT_PAGE_SIZE,
                    selectedSegmentType: '',
                    isOpen: false,
                    openContactListDrawer: false,
                    contactUuidList: [],
                    name: '',
                    resourceCode: '',
                    data: {},
                    total: 0,
                    selectedView: '',
                  };
                });
              }}
              onClose={() => {
                setContactsState((prev) => {
                  return {
                    ...prev,
                    contactList: [],
                    page: DEFAULT_PAGE,
                    pageSize: DEFAULT_PAGE_SIZE,
                    selectedSegmentType: '',
                    isOpen: false,
                    openContactListDrawer: false,
                    contactUuidList: [],
                    name: '',
                    resourceCode: '',
                    data: {},
                    total: 0,
                    selectedView: '',
                  };
                });
              }}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <View>
          <NoDataFound />
        </View>
      )}
    </>
  );
};
export default React.memo(AccountAnalytics);
