import { View, Text, Pressable } from "native-base";
import { Colors } from "../../../../styles/Colors";
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useNavigate } from "react-router-dom";
import NodataViewTypeOne from "../../Analytics/EmployerAnalytics/SectionAndWidget/NoDataView/NodataViewTypeOne";
import { useContext } from "react";
import { MLOV_CATEGORY } from "../../../../constants";
import { CommonDataContext } from "../../../../context/CommonDataContext";
import { getMlovListFromCategory } from "../../../../utils/mlovUtils";

const JourneyCountStatus = (props: {
    data?: any;
    journeyId?: string;
    careJourneyDashboardDateRange?: Record<string, string>
    locationGroupIds?: string[]
    locationIds?: string[]
}) => {
    const navigate = useNavigate();
    const mlovData = useContext(CommonDataContext);
    const patientJourneyStatusList = getMlovListFromCategory(
        mlovData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.PATIENT_CARE_JOURNEY_STATUS) || [];
    const goalList = ["assigned","active","paused","stopped","completed"];
    const totalPatientAssigned = props?.data?.patientCareJourneyCountResult?.reduce((a: number, c: any) => { return (a + c.count) }, 0) || 0;
    return (
        <View style={{ flex: 1 }}>
            <Text style={{
                marginBottom: 24,
                textAlign: 'left',
                color: Colors.FoldPixel.GRAY400
            }} size={'smBold'} fontSize={18}>
                Patients Assigned
            </Text>
            <View style={{
                borderRadius: 8,
                flex: 1,
                backgroundColor: Colors.Custom.ContainerBGColor,
            }}>
                <Text style={{
                    color: Colors.Custom.Gray500
                }} size={'smSemiBold'} fontSize={14}>
                    Total Patients Assigned
                </Text>
                <Pressable disabled={!totalPatientAssigned} onPress={() => {
                    totalPatientAssigned && navigate(`/admin/commerce/journeys/${props.journeyId}/members`, {
                      state: {
                        careJourneyDashboardDateRange: props?.careJourneyDashboardDateRange,
                        locationGroupIds: props?.locationGroupIds || [],
                        locationIds: props?.locationIds || []
                      }
                    })
                }} style={{
                    paddingVertical: 8,
                    borderBottomColor: Colors.Custom.Gray200,
                    borderBottomWidth: 1
                }} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                    <Text style={{
                        marginRight: 8
                    }} size={'smBold'} fontSize={30}>
                        {totalPatientAssigned}
                    </Text>
                    {totalPatientAssigned ? <AntIcon size={16} name="right" color={Colors.Custom.PurpleColor} />:null}
                </Pressable>
                {
                    props?.data?.patientCareJourneyCountResult?.length ? goalList?.map?.((item: any) => {
                        const count = props?.data?.patientCareJourneyCountResult?.find((subitem:any)=> subitem.statusCode == item)?.count || 0;
                        return (<Pressable 
                            disabled={!count}
                            onPress={() => {
                                count && navigate(`/admin/commerce/journeys/${props.journeyId}/members?type=${item}`,{ state: { careJourneyDashboardDateRange: props?.careJourneyDashboardDateRange} })
                            }} style={{
                            paddingVertical: 16,
                            borderBottomColor: Colors.Custom.Gray200,
                            borderBottomWidth: 1
                        }} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Text style={{
                                color: Colors.Custom.Gray500,
                            }} size={'smSemiBold'} fontSize={14}>
                                {patientJourneyStatusList?.find((subitem)=> subitem.code == item)?.value || item.statusCode }
                            </Text>
                            <View flexDirection={'row'} alignItems={'center'}>
                                <Text style={{
                                    marginRight: 4
                                }} size={'smSemiBold'} fontSize={18}>
                                    { count}
                                </Text>

                               {count? <AntIcon size={16} name="right" color={Colors.Custom.PurpleColor} />: null}

                            </View>
                        </Pressable>
                        )
                    })
                        :
                        <View style={{ paddingTop: 10 }}>  <NodataViewTypeOne viewCode="TEXT_ICON" /> </View>
                }

            </View> </View>
    )
}

export default JourneyCountStatus;