import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, DatePicker, Popover, Tooltip} from 'antd';
import moment from 'moment';
import {
  Divider,
  HStack,
  Image,
  Input,
  Pressable,
  Spacer,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack,
  Tooltip as NativeTooltip,
} from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import { DATE_FORMATS, IPAD_MINI_WIDTH, IPAD_WIDTH } from '../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { LeadQueries, UserQueries } from '../../../../services';
import TaskPoolQueries from '../../../../services/TaskPool/TaskPoolQueries';
import { Colors } from '../../../../styles';
import { getAccountUUID, getUserActionObjectFromActionCode, getUserUUID, isEmployerRole, isRenderTasksGrayBackground } from '../../../../utils/commonUtils';
import { getMlovId } from '../../../../utils/mlovUtils';
import { ITaskPoolTableData } from '../../../RightSideContainer/TaskManagment/TaskInterfaces/TaskInterface';
import AddPoolModal from '../../../RightSideContainer/TaskManagment/TaskManagementModal/AddPoolModal';
import { IUserSearch } from '../../CalendarWidget/UserAutoComplete/UserAutoComplete';
import { DisplayText } from '../../DisplayText/DisplayText';
import { TableTopBar } from '../../TableTopBar';
import { IUserPoolSelect } from '../CareDashboardWidget/UserAutoComplete';
import { styles } from './CareDashboardTopBarStyles';
import CustomAddTaskButton, { addTaskButtonKeys } from './CustomAddTaskButton';
import CustomSwitchListBoard from './CustomSwitchListBoard';
import CustomTabsView from './CustomTabsView';
import FilterDrawerView from './FilterDrawerView';
import { BoardType, ITabsList } from './interfaces';
import { getTaskListTopBarButtons } from './TopBarButtons';
import { useNavigate } from 'react-router-dom';
import { FlowType } from '../../../../context/WorkflowContext';
import { AutomationSettingBtn } from '../../AutomationSettingBtn';
import { USER_ROLE_CODES } from '../../../../constants/MlovConst';
import { IMlov, IUser } from '../../../../Interfaces';
import ClearActionIcon from '../../Svg/ClearActionIcon';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
import BoardTypeSelector from './BoardTypeSelector';
import { ILabelTask } from '../CareDashboardInterfaces';
import { withMiniContactViewHOC } from '../../../MiniContactViewHOC';
import FilterRowView from './FilterRowView';
import { debounce } from 'lodash';
const { RangePicker } = DatePicker;
import { TaskPanelType } from '../../../TaskCard/TaskEnum';
import AnalyticsGraphSvg from '../../Svg/AnalyticsGraphSvg';
import { TASK_EVENTS, TASK_MODULE_CODE, TASK_TABS } from '../CareDashboardConstants';
import { EventBus } from '../../../../utils/EventBus';
import CancelButtonSvg from '../../Svg/PersonActionSvgIcons/CancelButtonSvg';
import BulkTaskSelectSvg from '../../Svg/Task/BulkTaskSelectSvg';
import { MultiSelectAction } from '../../../TaskModule/TaskInterfaces';
import BulkTaskDeselectSvg from '../../Svg/Task/BulkTaskDeselectSvg';
import Close from '../../../../assets/svg/Close';
import Stack from '../../LayoutComponents/Stack';
import { TestIdentifiers, testID } from '../../../../testUtils';
import SideCarFilterIconSvg from '../../Svg/SideCarFilterIconSvg';
import SideCarSearchIconSvg from '../../Svg/SideCarSearchIconSvg';
import CrossIconSvg from '../../Svg/CrossIconSvg';
import SearchIconSvg from '../../Svg/TimelineSvg/SearchIconSvg';
import { handleCreateTaskPoolAction } from '../../UsageLevel/UsageLevelHandlers';
import {isSettingTaskTabAllowed} from '../../../RightSideContainer/UserAccess/UserAccessPermission';
import { StyleSheet } from 'react-native';
import { InputStyle } from '../../../RightSideContainer/Contacts/Leads/LeadView/LeadViewStyle';

export interface ITopBarData {
  start: string;
  end: string;
  addTask: boolean;
  addTaskCategoryId?: string;
  addTaskCategoryName?: string;
  addTaskTeamId?: string;
  addTaskTeamName?: string;
  hideActions?: boolean;
  selectedUsers?: IUserSearch[];
  createdByIds?: IUserSearch[];
  mentionUserIds?: IUserSearch[];
  isTableView?: boolean;
  selectedStatusList?: Array<IMlov>;
  selectedPriorityList?: Array<IMlov>;
  selectedDueDateUuid?: string[];
  selectedDueDateType?: string[];
  selectedMentionsSortUuid?: string;
  selectedMentionsSortType?: string;
  selectedSortUuid?: string;
  selectedSortType?: string;
  selectedContactUuid?: string[];
  selectedPatientLabel?: any[];
  selectedPatientName?: string[];
  selectedSortValueAscending?: boolean;
  selectedMentionsSortValueAscending?: boolean;
  selectedPool?: IUserPoolSelect;
  selectedTab?: string;
  isOtherFilterApplied?: boolean;
  userPreferenceId?: string;
  selectedPools?: any[];
  selectedBoardType?: BoardType;
  labels?: ILabelTask[];
  panelType?: TaskPanelType;
  showChangeFilterMessage?: boolean;
  showFilterSameAcrossTabMessage?: boolean;
  isPreferenceDataLoading?: boolean;
  shouldNotNavigateToTabsPath?: boolean;
  taskAnalyticsIntroTooltip?: boolean;
  bulkTaskIntroCardDismissed?: boolean;
  bulkActionEnabled?: boolean;
  searchString?: string;
}

export interface ITaskFilters {
  selectedStatusIds?: Array<string>;
  selectedPriorityIds?: Array<string>;

  selectedDueDateCode?: string[];
  selectedContactUuid?: string[];

  selectedTab?: string;

  userPoolIds: string[];
  selectedUserId: (string | null)[];

  startDate?: string;
  endDate?: string;
}

const CareDashboardTopBar = (props: {
  isShowFilterView: boolean;
  handleChangeFilterView: (isShowFilterView: boolean) => void;
  header?: string;
  children?: JSX.Element;
  topBarData: ITopBarData;
  onTopBarDataChange: (topBarData: any) => void;
  selectedStatusList?: Array<IMlov>;
  selectedPriorityList?: Array<IMlov>;
  selectedPatientName?: string[];
  selectedDueDateType?: string[];
  selectedSortType?: string;
  selectedMentionsSortType?: string;
  selectedContactUuid?: any[];
  selectedPatientLabel?: any[];
  selectedDueDateUuid?: string[];
  selectedSortUuid?: string;
  selectedMentionsSortUuid?: string;
  onActionPerformed?: (tabCode: any, rawData: any) => void;
  selectedSortValueAscending?: boolean;
  selectedMentionsSortValueAscending?: boolean;
  onValueChange?: (value: boolean) => void;
  onTabClick?: (tabItem: any, index?: number, lastIndexInVisibleList?: number) => void;
  tagsTabList?: ITabsList[];
  isOtherFilterApplied?: boolean;
  isTableView?: boolean;
  isPatientView?: boolean;
  setIsUserPoolRefetch?: (newTaskPool: any) => void;
  showAutomationOrSetting?: boolean
  userPools?: any;
  selectedTab?: string;
  navigateOrOpenContactIdDrawer?:(contactId?:string, additionalData?:any) => void;
  shouldNotNavigateToTabsPath?: boolean;
  hideTaskAnalytics?: boolean;
  handleNewTaskPool?: (newPool: ITaskPoolTableData) => void | undefined;
  taskMasterUsers?: IUser[];
  searchString?: string;
}) => {
  const {selectedTab, handleNewTaskPool, isShowFilterView, handleChangeFilterView} = props;
  const toast = useToast();
  const intl = useIntl();
  const eventBus = EventBus.getEventBusInstance();
  const navigate = useNavigate();
  const basePath = '/tasks';
  const { topBarData } = props;
  const mlovData = React.useContext(CommonDataContext);
  const accountUuid = getAccountUUID();
  const userUuid = getUserUUID();
  const userActionObject =
    getUserActionObjectFromActionCode('CREATE_A_TASK_POOL');
  const level = userActionObject.level || '';
  const [openModal, setOpenModal] = useState(false);
  const [isUserDataLoading, setIsUserDataLoading] = useState(false);
  const [usersData, setUsersData] = useState<IUser[]>([]);
  const [bulkTaskIntroOpen, setBulkTaskIntroOpen] = useState(false);
  const [contactsData, setContactsData] = useState<any>([]);
  const isSidecarContext = mlovData.sidecarContext?.isSidecar;
  const isPatientSidecarContext = mlovData.sidecarContext?.isPatient;
  const isSettingTaskTabAccess = isSettingTaskTabAllowed()
  const [dashboardTopBarState, setDashboardTopBarState] = useState({
    showSearchBar: false,
    searchString: props?.searchString
  })

  const isRenderGrayBackgtound = isRenderTasksGrayBackground();

  const allDueDateUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );

  const taskPoolTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'task_user_pool'
  );

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  /**
   * Get all users
   */
  const [getUsers] = useLazyQuery(UserQueries.GET_USERS_FOR_TASK_POOL_ADD_UPDATE, {
    variables: {
      accountUUID: accountUuid,
      userRoleCodes: [USER_ROLE_CODES.EMPLOYER, USER_ROLE_CODES.CUSTOMER_SUCCESS, USER_ROLE_CODES.WORKFLOW],
    },
  });

  /**
   * Create Task Pool Query
   */
  const [AddOrUpdateTaskPool] = useMutation(TaskPoolQueries.AddOrUpdateTaskPool, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  /**
   * Fetching users Data
   */
  const getUsersData = async () => {
    if (props?.taskMasterUsers?.length) {
      setUsersData(props?.taskMasterUsers);
    } else {  
      setIsUserDataLoading(true);
      const getUsersResult = await getUsers();
      setUsersData(getUsersResult?.data?.users || []);
      setIsUserDataLoading(false);
    }
  };

  const [getContactsByUuid] = useLazyQuery(LeadQueries.getContactDetailsByUuid, {
    fetchPolicy: 'no-cache',
    onCompleted: (response: any) => {
      const arrayOfMembers: any[] = []
      if (response?.contacts?.length) {
        response?.contacts?.forEach((contact: any) => {
          arrayOfMembers.push({ key: contact?.uuid, value: contact?.uuid, label: contact?.name, contactData: contact })
        })
      }
      setContactsData(arrayOfMembers)
    },
  })

  useEffect(() => {
    eventBus.addEventListener(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, onTaskEvent, { moduleCode: TASK_MODULE_CODE });
    return () => {
      eventBus.removeEventListenerByEventName(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, onTaskEvent, { moduleCode: TASK_MODULE_CODE });
    }
  }, []);

  useEffect(() => {
    if (openModal) {
      getUsersData();
    }
  }, [openModal]);

  useEffect(() => {
    let introTimeout: any;
    if (!topBarData.isPreferenceDataLoading) {
      if (!topBarData.bulkTaskIntroCardDismissed) {
        setBulkTaskIntroOpen(true);
        introTimeout = setTimeout(() => {
          setBulkTaskIntroOpen(false);
        }, 5000);
      }
    }
    return () => {
      introTimeout && clearTimeout(introTimeout);
    }
  }, [topBarData.isPreferenceDataLoading])

  const onTaskEvent = useCallback((data: { action: MultiSelectAction }) => {
    if (data?.action) {
      switch (data.action) {
        case MultiSelectAction.disableMultiSelect:
          props.onTopBarDataChange({
            bulkActionEnabled: false,
          });
          break;

        case MultiSelectAction.enableMultiSelect:
          props.onTopBarDataChange({
            bulkActionEnabled: true,
          });
          break;
      }
    }
  }, [topBarData]);

  const getContactDetailsByUuid = async () => {
    const contactIdsList = props?.selectedContactUuid?.map((contact: any) => contact?.key)
    if (contactsData?.length === 0 && !!contactIdsList?.length) {
      await getContactsByUuid({
        variables: {
          contactUuidList: contactIdsList
        },
      })
    }
  }

  useEffect(() => {
    getContactDetailsByUuid()
  }, [props?.selectedContactUuid])

  /**
   * @description Submit action for new pool
   * @param newTaskPool ITaskPoolTableData
   */
  const handleAddTaskPool = async (newTaskPool: ITaskPoolTableData) => {
    const newUsers: any[] = newTaskPool?.newPoolUsers?.map((userItem) => ({
      userId: userItem,
      isDefault: newTaskPool?.isDefault === userItem,
    }));
    try {
      const newTaskPoolData = await AddOrUpdateTaskPool({
        variables: {
          params: {
            name: newTaskPool?.newPoolName,
            userPoolUsers: newUsers,
            poolLocations: newTaskPool?.poolLocations,
            typeId: taskPoolTypeId,
          },
        },
      });
      if (handleNewTaskPool) {
        handleNewTaskPool(newTaskPoolData?.data?.addOrUpdateTaskPool);
      }
      await handleCreateTaskPoolAction({accountUuid, userUuid, level});
      showToast(toast, `New task pool created successfully`, ToastType.success, 2000);
      props.setIsUserPoolRefetch &&
        props.setIsUserPoolRefetch(newTaskPoolData.data);
    } catch (error) {
      showToast(toast, intl.formatMessage({ id: 'apiErrorMsg' }), ToastType.error, 2000);
    }
  };

  const getFilterCount = () => {
    let filterCount = 0;
    if (props.selectedStatusList?.length) {
      filterCount++;
    }
    if (props?.topBarData?.createdByIds && props?.topBarData?.createdByIds?.length  > 1) {
      filterCount++;
    }
    if (props?.topBarData?.selectedUsers && props?.topBarData?.selectedUsers?.length > 1) {
      filterCount++;
    }
    if (props?.topBarData?.mentionUserIds && props?.topBarData?.mentionUserIds?.length > 1) {
      filterCount++;
    }

    if (props.selectedPriorityList?.length) {
      filterCount++;
    }

    if (
      props.selectedDueDateType?.length &&
      !props.selectedDueDateUuid?.includes(allDueDateUuid)
    ) {
      filterCount++;
    }

    if (props.selectedPatientName && props.selectedPatientName.length) {
      filterCount++;
    }

    if (props.topBarData.selectedPools && props.topBarData.selectedPools.length) {
      filterCount++;
    }

    if (props.topBarData?.labels && props.topBarData.labels?.length) {
      filterCount++;
    }

    return filterCount;
  };

  const onSearch = (text: string) => {
    props?.onActionPerformed?.(COMMON_ACTION_CODES.SEARCH, text?.trim());
  }

  const onAddTaskButtonClick = useCallback(
    (key: string) => {
      if (key === addTaskButtonKeys.addTask) {
        props.onTopBarDataChange({
          start: topBarData.start,
          end: topBarData.end,
          addTask: true,
          isTableView: topBarData.isTableView,
          bulkActionEnabled: topBarData.bulkActionEnabled,
        });
        eventBus.broadcastEvent(TASK_EVENTS.OPEN_TASK_DETAIL, {});
      } else if (key === addTaskButtonKeys.addTaskPool) {
        setOpenModal(true);
      }
    },
    [topBarData, props.onTopBarDataChange, setOpenModal]
  );

  return (
    <>
      <VStack
        paddingX={0}
        style={styles.vStackContainer}
      >
        <HStack
          alignItems={'center'}
          flex={1}
          space={1}
          style={StyleSheet.flatten([
            styles.hStackContainer,
            {
              minHeight: isSidecarContext? 45 : 54,
              borderBottomWidth: isRenderGrayBackgtound ? 0 : 1
            },
          ])}
        >
          {props.tagsTabList && props.isOtherFilterApplied &&
            !(isSidecarContext && dashboardTopBarState.showSearchBar) && (
            <CustomTabsView
              canNavigate={!props.shouldNotNavigateToTabsPath ? true : false}
              basePath={basePath}
              tabsList={props.tagsTabList}
              isDisabled={topBarData.bulkActionEnabled}
              minScreenSupportWidth={700}
              numberOfElements={4}
              renderSubTabUI={isSidecarContext}
              isAddMaxTab={true}
              tabListWrapperStyle={{ marginHorizontal: 0, paddingHorizontal: isSidecarContext ? 12 : undefined}}
              onTabClick={(tabItem: any, index?: number, lastIndexInVisibleList?: number) => {
                if (topBarData.bulkActionEnabled) {
                  return;
                }
                props.onTabClick?.(tabItem, index, lastIndexInVisibleList);
              }}
            />
          )}
          {isSidecarContext &&
            <View style={StyleSheet.flatten([
              styles.sidecarView,
              {
                width: dashboardTopBarState.showSearchBar ? '100%' : '18%',
              }
            ])}>
            {!dashboardTopBarState.showSearchBar && (
              <HStack
              justifyContent={'flex-end'}
              justifyItems={'center'}
              alignItems={'center'}
              alignContent={'center'}
              flex={1}
            >
              <Pressable
                onPress={handleChangeFilterView.bind(null, !isShowFilterView)}
                style={styles.filterButton}
                {...testID(TestIdentifiers.filterButton)}
              >
                <Tooltip
                  title={'Filter'}
                  overlayStyle={{whiteSpace: 'pre-line'}}
                >
                  {getFilterCount() > 0 && (
                    <View style={styles.filterCountContainer}>
                      <Text style={styles.filterCountText}>
                        {getFilterCount()}
                      </Text>
                    </View>
                  )}
                  <View>
                    <SideCarFilterIconSvg />
                  </View>
                </Tooltip>
              </Pressable>
              <View style={styles.divider} backgroundColor={Colors.Custom.Gray200}></View>
              </HStack>
            )}
              {!isPatientSidecarContext && !dashboardTopBarState.showSearchBar && (
                <>
                <Popover
                  overlayInnerStyle={{ padding: 0, borderRadius: 12, marginTop: 4 }}
                  open={bulkTaskIntroOpen}
                  content={
                    <View flexDirection={'row'} style={styles.alignItemsCenter}>
                      <Image
                        width={'60px'}
                        height={'60px'}
                        source={require('../../../../assets/gifs/bulk_task_intro.gif')}
                        alt={'lock'}
                        alignItems={'center'}
                      />
                      <View flexDirection={'row'}>
                        <View marginLeft={4}>
                          <Text>
                            {intl.formatMessage({ id: 'introducingBulkTaskUpdate' })}
                          </Text>
                          <Text width={280} color={Colors.Custom.Gray400}>
                            {intl.formatMessage({ id: 'bulkTaskIntroMsg' })}
                          </Text>
                        </View>
                        <Pressable
                          onPress={() => {
                            setBulkTaskIntroOpen(false);
                            props?.onActionPerformed?.(
                              COMMON_ACTION_CODES.DISMISS_INTRO_CARD,
                              { bulkTaskIntroCardDismissed: true }
                            )
                          }}>
                          <Close fill={Colors.Custom.Gray500} size={20} />
                        </Pressable>
                      </View>
                    </View>
                  }
                  placement="bottom"
                >
                  <Tooltip
                    overlayStyle={{whiteSpace: 'pre-line'}}
                    title={'Bulk Task Update'}
                  >
                    <Pressable
                      testID={'bulkActionBtn'}
                      style={styles.bulkTaskButton}
                      onPress={(event) => {
                        const bulkEnabled = !topBarData?.bulkActionEnabled;
                        props.onTopBarDataChange({
                          start: topBarData.start,
                          end: topBarData.end,
                          addTask: false,
                          isTableView: topBarData.isTableView,
                          bulkActionEnabled: bulkEnabled,
                        });
                        eventBus.broadcastEvent(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, { action: bulkEnabled ? MultiSelectAction.enableMultiSelect : MultiSelectAction.disableMultiSelect });
                        event.stopPropagation();
                      }}>
                      {topBarData?.bulkActionEnabled ? <BulkTaskDeselectSvg /> : <BulkTaskSelectSvg />}
                    </Pressable>
                  </Tooltip>
                </Popover>
                <View style={styles.divider} backgroundColor={Colors.Custom.Gray200}></View>
                </>
              )}
            {dashboardTopBarState.showSearchBar && 
              <Input
                placeholder={isSidecarContext ? 'Search by title or description' : 'Search tasks by title or description'}
                onChangeText={
                  debounce(onSearch, 500)
                }
                height={'36px'}
                marginTop={'6px'}
                placeholderTextColor={Colors.FoldPixel.GRAY300}
                backgroundColor={Colors.Custom.White}
                width={'100%'}
                defaultValue={dashboardTopBarState.searchString}
                leftElement={
                  <View marginLeft={2} marginRight={1}>
                    <SearchIconSvg
                      size={18}
                      customStrokeColor={Colors.FoldPixel.GRAY200}
                    />
                  </View>
                }
                rightElement={
                  <Pressable
                    width={'40px'}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onPress={()=> {
                      if (dashboardTopBarState.showSearchBar) {
                        props?.onActionPerformed?.(
                          COMMON_ACTION_CODES.SEARCH,
                          ''
                        );
                      }
                      setDashboardTopBarState(prev => {
                        return {
                          ...prev,
                          showSearchBar: !prev.showSearchBar,
                        }
                      })
                    }}>
                    <CrossIconSvg customStrokeColor={Colors.FoldPixel.GRAY300} size={20}/>
                  </Pressable>
                }
              />
            }

              {!dashboardTopBarState.showSearchBar && (<Pressable onPress={() => {
                if (dashboardTopBarState.showSearchBar) {
                  props?.onActionPerformed?.(
                    COMMON_ACTION_CODES.SEARCH,
                    ''
                  );
                }
                setDashboardTopBarState(prev => {
                  return {
                    ...prev,
                    showSearchBar: !prev.showSearchBar,
                  }
                })
              }}
              {...testID(TestIdentifiers.searchBtn)}
              >
                {!dashboardTopBarState.showSearchBar ? <SideCarSearchIconSvg />
                  : <></>}
              </Pressable>)}
          </View>
          }
          {!isSidecarContext && (
            <>
          {props.isOtherFilterApplied && !isSmallScreen && <Spacer />}

          {props.header && (
            <VStack justifyContent={'center'} style={styles.headerContainer}>
              <HStack alignItems="center">
                <View>
                  <DisplayText textLocalId={props.header} size={'lgMedium'} />
                </View>
              </HStack>
            </VStack>
          )}

          {props.header && !props.isOtherFilterApplied && <Spacer />}
          {!props.isOtherFilterApplied && props.isPatientView && (
            <HStack space={2}>
              <VStack space={1}>
                <RangePicker
                  placeholder={['Select start date', 'Select end date']}
                  allowClear={false}
                  showTime={false}
                  style={{
                    height: 44,
                    width: '100%',
                    borderRadius: 8,
                  }}
                  value={[
                    moment(
                      topBarData.start || new Date(),
                      DATE_FORMATS.DISPLAY_DATE_FORMAT
                    ),
                    moment(
                      topBarData.end || new Date(),
                      DATE_FORMATS.DISPLAY_DATE_FORMAT
                    ),
                  ]}
                  format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                  onChange={(value, dateString) => {
                    if (value) {
                      const startDate = moment(
                        value[0],
                        DATE_FORMATS.DISPLAY_DATE_FORMAT
                      ).format(DATE_FORMATS.DISPLAY_DATE_FORMAT);
                      const endDate = moment(
                        value[1],
                        DATE_FORMATS.DISPLAY_DATE_FORMAT
                      ).format(DATE_FORMATS.DISPLAY_DATE_FORMAT);

                      props.onTopBarDataChange({
                        start: startDate,
                        end: endDate,
                        addTask: false,
                        hideActions: topBarData?.hideActions,
                        bulkActionEnabled: topBarData.bulkActionEnabled,
                      });
                    }
                  }}
                />
              </VStack>
            </HStack>
          )}
           <Input
              placeholder={isSidecarContext ? 'Search by title or description' : 'Search tasks by title or description'}
              placeholderTextColor={InputStyle.placeholderTextColor}
              fontSize={InputStyle.placeHolderFontsize}
              onChangeText={debounce(onSearch, 500)}
              width={isSidecarContext ? '200' : '300'}
              defaultValue={dashboardTopBarState.searchString}
            />
          {!isSidecarContext &&
            props.onValueChange &&
            selectedTab &&
            ![TASK_TABS.TASK_POOL, TASK_TABS.CREATED, TASK_TABS.MENTIONED].includes(selectedTab) &&
            props.isOtherFilterApplied && (
              <CustomSwitchListBoard
                isTableView={props.isTableView}
                isDisabled={topBarData.bulkActionEnabled}
                onValueChange={(value) => {
                  if (topBarData.bulkActionEnabled) {
                    return;
                  }
                  props.onValueChange?.(value);
                }}
              />
            )}
          <Divider
            orientation="vertical"
            height={4}
            marginRight={2}
          />
          <Popover
            overlayInnerStyle={{padding: 0, borderRadius: 12, marginTop: 4}}
            open={bulkTaskIntroOpen}
            content={
              <View flexDirection={'row'} style={styles.alignItemsCenter}>
                <Image
                  width={'60px'}
                  height={'60px'}
                  source={require('../../../../assets/gifs/bulk_task_intro.gif')}
                  alt={'lock'}
                  alignItems={'center'}
                />
                <View flexDirection={'row'}>
                  <View marginLeft={4}>
                    <Text>
                      {intl.formatMessage({id: 'introducingBulkTaskUpdate'})}
                    </Text>
                    <Text width={280} color={Colors.Custom.Gray400}>
                      {intl.formatMessage({id: 'bulkTaskIntroMsg'})}
                    </Text>
                  </View>
                  <Pressable
                    onPress={() => {
                      setBulkTaskIntroOpen(false);
                      props?.onActionPerformed?.(
                        COMMON_ACTION_CODES.DISMISS_INTRO_CARD,
                        { bulkTaskIntroCardDismissed: true }
                      )
                    }}>
                    <Close fill={Colors.Custom.Gray500} size={20} />
                  </Pressable>
                </View>
              </View>
            }
            placement="bottom"
          >
            <NativeTooltip
                placement='bottom'
                hasArrow
                label={'Bulk Task Update'}
              >
            <Pressable
              testID={'bulkActionBtn'}
              style={styles.bulkTaskButton}
              onPress={(event) => {
                const bulkEnabled = !topBarData?.bulkActionEnabled;
                props.onTopBarDataChange({
                  start: topBarData.start,
                  end: topBarData.end,
                  addTask: false,
                  isTableView: topBarData.isTableView,
                  bulkActionEnabled: bulkEnabled,
                });
                eventBus.broadcastEvent(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, { action: bulkEnabled ? MultiSelectAction.enableMultiSelect : MultiSelectAction.disableMultiSelect });
                event.stopPropagation();
              }}>
                {topBarData?.bulkActionEnabled ? <BulkTaskDeselectSvg /> : <BulkTaskSelectSvg />}
            </Pressable>
            </NativeTooltip>
          </Popover>
          <Divider
            orientation="vertical"
            height={4}
            marginLeft={3}
            marginRight={0}
          />
          {props.onActionPerformed && props.isOtherFilterApplied && (
            <TableTopBar
              {...getTaskListTopBarButtons(
                (actionCode: any, rawData: any) => {
                  props.onActionPerformed?.(actionCode, rawData)
                },
                selectedTab === TASK_TABS.MENTIONED ? (props.selectedMentionsSortType ? props.selectedMentionsSortType : '') : (props.selectedSortType ? props.selectedSortType : ''),
                selectedTab === TASK_TABS.MENTIONED ? props.selectedMentionsSortValueAscending! : props.selectedSortValueAscending!,
                selectedTab,
                topBarData.bulkActionEnabled
              )}
              hideSearchBar={true}
              hStackMinHight={40}
              isDividerNotVisible={true}
              buttonCustomStyles={{
                paddingLeft: 0,
              }}
            />
          )}


          {((props.onActionPerformed &&
            props.isOtherFilterApplied &&
            isShowFilterView && props.isPatientView) ||
            (isShowFilterView && props.isPatientView)) && (
              <FilterDrawerView
                contactsData={contactsData}
                setContactsData={(contactsData: any) =>
                  setContactsData(contactsData)
                }
                onActionPerformed={(
                  tabCode: any,
                  rawData: any,
                ) => {
                 handleChangeFilterView(false);
                  if (!props.isPatientView) {
                    props.onActionPerformed &&
                      props.onActionPerformed(tabCode, rawData);
                  } else {
                    props.onTopBarDataChange({
                      start:
                        rawData.start ||
                        moment(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
                      end:
                        rawData.end ||
                        moment(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
                      addTask: false,
                      hideActions: topBarData?.hideActions,
                      bulkActionEnabled: topBarData.bulkActionEnabled,
                    });
                  }
                }}
                selectedStatusList={props.selectedStatusList || []}
                selectedPriorityList={props.selectedPriorityList || []}
                selectedDueDateType={
                  props.selectedDueDateType ? props.selectedDueDateType : []
                }
                selectedDueDateUuid={
                  props.selectedDueDateUuid ? props.selectedDueDateUuid : []
                }
                selectedContactUuid={
                  props.selectedContactUuid ? props.selectedContactUuid : []
                }
                selectedPatientLabel={
                  props.selectedPatientLabel ? props.selectedPatientLabel : []
                }
                selectedPatientName={
                  props.selectedPatientName ? props.selectedPatientName : []
                }
                topBarData={topBarData}
                onTopBarDataChange={props.onTopBarDataChange}
                isPatientView={props.isPatientView}
                userPools={props.userPools}
              />
            )}
            <Divider
              orientation="vertical"
              height={4}
              marginLeft={-2}
              marginRight={1}
            />
           {!props.hideTaskAnalytics && (
            <Stack direction='row' style={styles.alignItemsCenter}>
              <NativeTooltip
                placement='bottom'
                hasArrow
                label={'Task Analytics'}
              >
                <Pressable
                  testID={'taskAnalyticsBtn'}
                  onPress={() => {
                    navigate(`/tasks/analytics`, { replace: true });
                  }}
                >
                  <AnalyticsGraphSvg />
                </Pressable>
              </NativeTooltip>
              <Divider
                orientation="vertical"
                height={4}
                marginLeft={2}
                marginRight={1}
              />
              </Stack>
            )}
            <Popover
              overlayInnerStyle={{padding: 0, borderRadius: 12, marginTop: 4}}
              open={topBarData?.taskAnalyticsIntroTooltip}
              onOpenChange={() => {
                props?.onActionPerformed &&
                  props?.onActionPerformed(
                    COMMON_ACTION_CODES.CLOSE_POPUP,
                    false
                  );
              }}
              trigger='click'
              content={
                <View flexDirection={'row'}>
                  <Image
                    width={'60px'}
                    height={'60px'}
                    source={require('../../../../assets/gifs/TaskAnalytics.gif')}
                    alt={'lock'}
                    alignItems={'center'}
                  />
                  <View marginLeft={4}>
                    <Text>Introducing Task Analytics</Text>
                    <Text width={280} color={Colors.Custom.Gray400}>{intl.formatMessage({ id: 'taskAnalyticsIntroMessage' })}</Text>
                  </View>
                  <Button
                    style={{marginLeft: 8, padding: 4}}
                    type="text"
                    onClick={() =>
                      props?.onActionPerformed &&
                      props?.onActionPerformed(
                        COMMON_ACTION_CODES.CLOSE_POPUP,
                        false
                      )
                    }
                  >
                    <CancelButtonSvg />
                  </Button>
                </View>
              }
              placement="bottomRight"
            >
            </Popover>

          {!props.topBarData.hideActions && (
            <CustomAddTaskButton
              title="Add Task"
              isDisabled={topBarData.bulkActionEnabled}
              showBtnTextForSmallScreen
              onClick={onAddTaskButtonClick}
            />
          )}
          {props.showAutomationOrSetting && !isEmployerRole() && (
            <AutomationSettingBtn
              flowType={FlowType.task}
              settingsPath={ isSettingTaskTabAccess ?  '/admin/taskManagement/task_pool' : ''}
            />
          )}
          {openModal && (
            <AddPoolModal
              title={`Add Task Pool`}
              isModalOpen={openModal}
              onModalClose={() => setOpenModal(false)}
              onSave={(newPool: ITaskPoolTableData) => handleAddTaskPool(newPool)}
              usersData={usersData}
              isEdit={false}
              isUserDataLoading={isUserDataLoading}
            />
          )}
          </>
          )}
        </HStack>
        {(isShowFilterView || !isSidecarContext) && (
          <HStack
            style={[
              styles.filterHStack,
              {
                // ...(isShowFilterView || !isSidecarContext ? {} : { display: 'none', height: 0 }),
                padding: isSidecarContext ? 12 : 16,
                borderBottomWidth: isSidecarContext ? 0 : 1,
              }
            ]}>
        {(((props.onActionPerformed && props.isOtherFilterApplied) || props.isPatientView)) && (
                <HStack
                  style={[
                    styles.filterRowHStack,
                    {
                      marginBottom: isSidecarContext ? -8 : 0
                    }
                  ]}
                >
                  <FilterRowView
                    contactsData={contactsData}
                    setContactsData={(contactsData: any) =>
                      setContactsData(contactsData)
                    }
                    // getContactsData={(getContactsData:any)=>{getContactsData()}}
                    onActionPerformed={(
                      tabCode: any,
                      rawData: any,
                    ) => {
                      if (!props.isPatientView) {
                        props.onActionPerformed &&
                          props.onActionPerformed(tabCode, rawData);
                      } else {
                        props.onTopBarDataChange({
                          start:
                            rawData.start ||
                            moment(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
                          end:
                            rawData.end ||
                            moment(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
                          addTask: false,
                          hideActions: topBarData?.hideActions,
                          bulkActionEnabled: topBarData.bulkActionEnabled,
                        });
                      }
                      handleChangeFilterView(false);
                    }}
                    selectedStatusList={props.selectedStatusList || []}
                    selectedPriorityList={props.selectedPriorityList || []}
                    // selectedStatusType={
                    //   props.selectedStatusType ? props.selectedStatusType : ''
                    // }
                    // selectedStatusUuid={
                    //   props.selectedStatusUuid ? props.selectedStatusUuid : ''
                    // }
                    // selectedPriorityType={
                    //   props.selectedPriorityType ? props.selectedPriorityType : ''
                    // }
                    // selectedPriorityUuid={
                    //   props.selectedPriorityUuid ? props.selectedPriorityUuid : ''
                    // }
                    selectedDueDateType={
                      props.selectedDueDateType ? props.selectedDueDateType : []
                    }
                    selectedDueDateUuid={
                      props.selectedDueDateUuid ? props.selectedDueDateUuid : []
                    }
                    selectedContactUuid={
                      props.selectedContactUuid ? props.selectedContactUuid : []
                    }
                    selectedPatientLabel={
                      props.selectedPatientLabel ? props.selectedPatientLabel : []
                    }
                    selectedPatientName={
                      props.selectedPatientName ? props.selectedPatientName : []
                    }
                    topBarData={topBarData}
                    onTopBarDataChange={props.onTopBarDataChange}
                    isPatientView={props.isPatientView}
                    userPools={props?.userPools}
                    onCloseFilterView={() => {
                      handleChangeFilterView(false)
                    }}
                  >
                    <>
                    {!props.header && props.children && (!isSmallScreen || isSidecarContext) && (
                      <VStack justifyContent={'center'} alignItems={'flex-start'} marginBottom={isSidecarContext ? 2 : 0}>
                        <HStack alignItems="center" style={isSidecarContext ? {width: '100%'} : {}}>
                          {props.children}
                        </HStack>
                      </VStack>
                    )}
                    {selectedTab &&
                      ![TASK_TABS.TASK_POOL, TASK_TABS.CREATED, TASK_TABS.MENTIONED].includes(selectedTab) && (
                      <View marginBottom={isSidecarContext ? 2 : 0}>
                      <BoardTypeSelector
                        selectedType={topBarData.selectedBoardType || BoardType.Status}
                        handleTypeChange={(type) => {
                          props?.onActionPerformed && props?.onActionPerformed(COMMON_ACTION_CODES.SELECTED_BOARD_TYPE, {
                            selectedBoardType: type,
                          })
                        }
                        }
                      />
                      </View>
                    )}
                    </>
                  </FilterRowView>
                </HStack>
              )}
              {!isSidecarContext && (<HStack
                marginRight={2}
                marginLeft={4}>
                {getFilterCount() > 0 && (
                  <Pressable
                    onPress={() => {
                      props.onActionPerformed &&
                        props.onActionPerformed(COMMON_ACTION_CODES.CLEAR_ALL, {});
                    }}
                    style={styles.clearAllButton}
                  >
                    <ClearActionIcon />
                    <Text color={Colors.Custom.mainPrimaryPurple} style={styles.clearAllText}>Clear All</Text>
                  </Pressable>
                )}
              </HStack>)}
          </HStack>
        )}
      </VStack>
    </>
  );
};

export default withMiniContactViewHOC(CareDashboardTopBar);
