import { ColumnsType } from 'antd/lib/table';
import { HStack, Text, Tooltip, View, VStack } from 'native-base';
import { DATE_FORMATS, GROUP_MEMBER_TYPE, IS_DEACTIVATE_CONTACT_ENABLED, MEMBERSHIP_STATUS_ALL } from '../../../constants';
import { Colors } from '../../../styles';
import { getPrimaryGenderCode, getTenantForDemo, isEmployerRole, isEmptyObject } from '../../../utils/commonUtils';
import { capitalizeText } from '../../common/ContactRelationView/ContactRelationUtils';
import { DisplayCardAvatar } from '../../common/DisplayCard/DisplayCardAvatar';
import { DisplayText } from '../../common/DisplayText/DisplayText';
import { isEmptyArray } from '../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/FamilyHistory/FamilyHistoryUtils';
import { ICampaignSegmentFormState } from '../ContentManagement/CampaignManagement/interfaces';
import { DependentCount } from './DependentCount';
import {
  Campaign,
  ContactMembership,
  HintPlan,
  IMembersCampaign,
  IMembersCarrier,
  IMembersContact,
  IMembersData,
  IMembersGroupData,
  IMembersGroupTableColumnsArgs,
  IMembersJourney,
  IMembersTableColumnsArgs,
  MemberData,
  ServiceLog,
  Workflow
} from './interfaces';
import { MembersTableActions } from './MembersTableActions';
import MembersGroupTableActions from './MembersTableActions/MemberGroupActionTable';
import { EMPLOYEE_TYPE_FILTERS, MEMBERSHIP_STATUS_FILTERS } from './MembersManagementHelper';
import { DatePicker, Popover } from 'antd';
import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { getCampaignByResourceIdList } from '../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import AppUsingPatientSvg from '../../common/Svg/SideMenu/AppUsingPatientSvg';
import {getDateStrFromFormat} from '../../../utils/DateUtils';
import { getPatientCareJourneyFetchCondition } from '../Journeys/JourneysOfCare/PatientCareJourney/PatientCareJourneyHelper';
const {RangePicker} = DatePicker

const isDemoMode = getTenantForDemo()

export const patientFilterForCreateSegment = (
  campaignSegmentFormData: ICampaignSegmentFormState
) => {
  const filterObj: any = {};
  if (
    campaignSegmentFormData.patientList &&
    campaignSegmentFormData.patientList?.length
  ) {
    filterObj['contactIds'] = (campaignSegmentFormData.patientList||[]).map((contactId)=>{
      try{
      return parseInt(contactId)
      }catch {
        return contactId;
      }
    });
  } else {
    filterObj['jsonTree'] = campaignSegmentFormData.jsonTree;
  }
  return filterObj;
};

export const getPreFilterMemberStatus = (campaignSegmentFormData: ICampaignSegmentFormState) => {
  return {
    preFilter:
    {
      memberStatus: campaignSegmentFormData?.memberStatus?.code || MEMBERSHIP_STATUS_ALL
    }
  }
};

export const getFormattedEmployees = (data: any): IMembersData[] => {
  const formattedData: IMembersData[] = data?.map((singleEmployee: any) => {
    singleEmployee.contact = singleEmployee
    singleEmployee.contactMembership = singleEmployee.contactMemberships
    const employeeId = singleEmployee.contactMembership?.[0]?.employeeId
    return {
      contact: {
        email: singleEmployee?.contact?.email,
        name: singleEmployee?.contact?.name,
        id: singleEmployee?.contact?.id,
        active: singleEmployee.contact?.isActive,
        patient: singleEmployee?.contact?.patient,
        additionalAttributes: singleEmployee?.contact?.additionalAttributes,
        contactConsents: singleEmployee?.contact?.contactConsents,
        uuid: singleEmployee?.contact?.uuid,
        contactPracticeLocations: singleEmployee.contact.contactPracticeLocations
      },
      type: singleEmployee.type || 'employee',
      id: employeeId || singleEmployee?.id,
      membershipType: isDemoMode ? 'Gold' : '-',
      campaign: [{
        name: isDemoMode ? 'Summer Campaign' : '-',
        status: isDemoMode ? 'Active' : '-',
      }],
      carrier: {
        type: isDemoMode ? 'DHL' : '-',
        value: isDemoMode ? 'DHL' : '-',
      },
      consentStatus: isDemoMode ? true : undefined,
      dependent: isDemoMode ? '34' : 'None',
      journeys: isDemoMode
        ? [
            {name: 'Weight Loss Journey'},
            {name: 'Diabetes Type 2'},
            {name: 'Diabetes Type 1'},
          ]
        : [],
      contactMemberShip: getActiveMembership(singleEmployee),
      location: singleEmployee.contactPracticeLocations,
      rawData: singleEmployee
    };
  });

  return formattedData;
};

export const renderPill = (
  backgroundColor?: string,
  text?: string,
  textColor?: string
) => {
  return (
    <HStack
      style={{
        paddingHorizontal: 10,
        paddingVertical: 2,
        width: 'max-content',
        borderRadius: 16,
        backgroundColor: backgroundColor,
      }}
    >
      <Text size={'smMedium'} color={textColor}>
        {text}
      </Text>
    </HStack>
  );
};

export const getMembersColumns = (args: IMembersTableColumnsArgs) => {
  const {
    actionFn,
    openInHint,
    planFilters,
    selectedPlanNames,
    typeFilters,
    selectedStatus,
    statusFilters,
    onStartDateRangeChange,
    onEndDateRangeChange,
    isVbcTenant,
    allowToShowExternalPatientId
  } = args;

  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId={isVbcTenant ? "memberName": "employeeName"}
        />
      ),
      dataIndex: 'contact',
      key: 'contact',
      sorter: (a: any, b: any) =>
        a?.contact?.name?.localeCompare(b?.contact?.name),
      fixed: 'left',
      responsive: ['md'],
      width:  isEmployerRole() ? 200 : '20%',
      ellipsis: true,
      render: (contact: IMembersContact, selectRowData: any) => {
        const {firstName, lastName} = getMemberFirstNameAndLastName(contact);
        const genderProfileCode =  getPrimaryGenderCode(selectRowData?.rawData?.contact);
        const currentStatus = contact?.active == false ? false : true;
        const name = `${firstName} ${lastName}`
        return (
          <VStack>
          <HStack alignItems={'center'}>
            <DisplayCardAvatar
              avatarStyle={{
                avatarSize: '12',
              }}
              isLetterAvatarShow={true}
              userData={{
                userId: contact?.id,
                userType: GROUP_MEMBER_TYPE.CONTACT,
                imgSrc: contact?.profileImgSrc || '',
                genderCode: genderProfileCode as any,
                userName: name
              }}
            />
            <VStack style={{marginLeft: 12}}>
              <HStack alignItems={'center'}>
              <Tooltip
                  label={name || ''}
                  placement="top"
                >
                <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                  {name}
                </Text>
              </Tooltip>
                <AppUsingPatientSvg
                  loggedInTime={contact?.additionalAttributes?.loggedInTime}
                />
              </HStack>
              {allowToShowExternalPatientId && <Text size={'xsNormal'} color={Colors.Custom.Gray500} fontWeight={500}>#{contact?.patient?.patientId}</Text>}
              <Text size={'smNormal'} color={Colors.Custom.Gray500}>
                {contact.email}
              </Text>
            </VStack>
          </HStack>
          {IS_DEACTIVATE_CONTACT_ENABLED && (
            <>
              {currentStatus ? (
                <></>
              ) : (
                <HStack style={{marginLeft: 45}}>
                  <View justifyContent={'center'} marginLeft={2}>
                    <Text color={Colors.danger[500]} size={'xsMedium'}>
                      {`Inactive`}
                    </Text>
                  </View>
                </HStack>
              )}
            </>
          )}
          </VStack>
        );
      },
    },
  ];

  if (!isVbcTenant) {
    if (openInHint) {
      columns.push(
        {
          dataIndex: 'contactMemberShip',
          key: 'status',
          width: '8%',
          responsive: ['md'],
          ellipsis: true,
          title: (
            <DisplayText
              size={'xsMedium'}
              extraStyles={{color: Colors.Custom.Gray500}}
              textLocalId="status"
            />
          ),
          filters: MEMBERSHIP_STATUS_FILTERS,
          filteredValue: selectedStatus,
          render: (contactMemberShip: ContactMembership[]) => {
            const displayString = getEnrolmentStatusText(contactMemberShip);
            return <Text>{displayString.length > 0 ? displayString : '-'}</Text>;
          },
        },
        {
          dataIndex: 'contactMemberShip',
          key: 'planName',
          width: '8%',
          responsive: ['md'],
          ellipsis: true,
          title: (
            <DisplayText
              size={'xsMedium'}
              extraStyles={{color: Colors.Custom.Gray500}}
              textLocalId="membershipType"
            />
          ),
          filters: planFilters,
          filteredValue: selectedPlanNames,
          render: (contactMemberShip: ContactMembership[]) => {
            const displayString = getPlanNameText(contactMemberShip);
            const backgroundColor =
              displayString == 'Gold'
                ? Colors.warning[50]
                : displayString == 'Silver'
                ? Colors.Custom.Gray100
                : Colors.secondary[50];
            const textColor =
              displayString == 'Gold'
                ? Colors.warning[700]
                : displayString == 'Silver'
                ? Colors.Custom.Gray700
                : Colors.secondary[700];
            return renderPill(backgroundColor, displayString, textColor);
          },
        },
        {
          dataIndex: 'contactMemberShip',
          key: 'startDate',
          responsive: ['md'],
          width: '8%',
          ellipsis: true,
          title: (
            <DisplayText
              size={'xsMedium'}
              extraStyles={{color: Colors.Custom.Gray500}}
              textLocalId="startDate"
            />
          ),
          filterDropdown(props) {
            return (
              <RangePicker
                onChange={(value) => {
                  const startFrom = value?.[0]?.format('MM-DD-YYYY');
                  const startTo = value?.[1]?.format('MM-DD-YYYY');
                  onStartDateRangeChange(startFrom || '', startTo || '');
                }}
              />
            );
          },
          render: (contactMemberShip: ContactMembership[]) => {
            let displayString = '';
            if (contactMemberShip?.length > 0) {
              const object = contactMemberShip[0] || {};
              displayString = object?.startDate
                ? getDateStrFromFormat(
                    object?.startDate,
                    DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
                  )
                : '-';
            }
            return <Text>{displayString}</Text>;
          },
        },
        {
          dataIndex: 'contactMemberShip',
          key: 'endDate',
          responsive: ['md'],
          width: '8%',
          ellipsis: true,
          title: (
            <DisplayText
              size={'xsMedium'}
              extraStyles={{color: Colors.Custom.Gray500}}
              textLocalId="endDate"
            />
          ),
          filterDropdown(props) {
            return (
              <RangePicker
                onChange={(value) => {
                  const endFrom = value?.[0]?.format('MM-DD-YYYY');
                  const endTo = value?.[1]?.format('MM-DD-YYYY');
                  onEndDateRangeChange(endFrom || '', endTo || '');
                }}
              />
            );
          },
          render: (contactMemberShip: ContactMembership[]) => {
            let displayString = '-';
            if (contactMemberShip.length > 0) {
              const object = contactMemberShip[0] || {};
              displayString = object?.endDate
                ? getDateStrFromFormat(
                    object?.endDate,
                    DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
                  )
                : '-';
            }
            return <Text>{displayString}</Text>;
          },
        }
      );
    } else {
      columns.push({
        title: (
          <DisplayText
            size={'xsMedium'}
            extraStyles={{color: Colors.Custom.Gray500}}
            textLocalId="membershipType"
          />
        ),
        dataIndex: 'membershipType',
        key: 'membershipType',
        responsive: ['md'],
        ellipsis: true,
        width: 140,
        render: (membershipType: string) => {
          const backgroundColor =
            membershipType == 'Gold'
              ? Colors.warning[50]
              : membershipType == 'Silver'
              ? Colors.Custom.Gray100
              : Colors.error[50];
          const textColor =
            membershipType == 'Gold'
              ? Colors.warning[700]
              : membershipType == 'Silver'
              ? Colors.Custom.Gray700
              : Colors.error[50];

          return renderPill(backgroundColor, membershipType, textColor);
        },
      });
    }
  }
  if(!isEmployerRole()) {
    columns.push({
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="journeys"
        />
      ),
      dataIndex: 'journeys',
      key: 'journeys',
      responsive: ['md'],
      width: 120,
      ellipsis: true,
      render: (journeys: IMembersJourney[]) => {
        const total = (journeys || []).length;
        return (
          <HStack space={2} alignItems="center" flexWrap={'wrap'}>
            {total == 0 ? (
              <Text>--</Text>
            ) : (
              <Popover
                content={
                  <VStack>
                    {journeys.map((item, index) => {
                      return (
                        <View
                          style={{
                            marginVertical: 2,
                          }}
                        >
                          <Text
                            style={{
                              marginHorizontal: 4,
                              marginVertical: 2,
                            }}
                          >
                            {item.name || ''}
                          </Text>
                        </View>
                      );
                    })}
                  </VStack>
                }
              >
                <Text>{`${journeys.length}`}</Text>
              </Popover>
            )}
          </HStack>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="campaigns"
        />
      ),
      width: 120,
      dataIndex: 'campaign',
      key: 'activeCampaign',
      responsive: ['md'],
      ellipsis: true,
      render: (campaign: IMembersCampaign[]) => {
        const total = campaign?.length;
        return (
          <HStack space={2} alignItems="center" flexWrap={'wrap'}>
            {total == 0 ? (
              <Text>--</Text>
            ) : (
              <Popover
                content={
                  <VStack>
                    {campaign.map((item, index) => {
                      return (
                        <View
                          style={{
                            marginVertical: 2,
                          }}
                        >
                          <Text
                            style={{
                              marginHorizontal: 4,
                              marginVertical: 2,
                            }}
                          >
                            {item.name || ''}
                          </Text>
                        </View>
                      );
                    })}
                  </VStack>
                }
              >
                <Text>{`${total}`}</Text>
              </Popover>
            )}
          </HStack>
        );
      },
    },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500}}
    //       textLocalId="consentStatus"
    //     />
    //   ),
    //   width: 120,
    //   dataIndex: 'consentStatus',
    //   responsive: ['md'],

    //   ellipsis: true,
    //   key: 'consentStatus',
    //   render: (consentStatus: boolean) => {
    //     if (consentStatus == undefined) {
    //       return '-';
    //     }
    //     return renderPill(
    //       consentStatus ? Colors.success[50] : Colors.error[50],
    //       consentStatus ? 'Yes' : 'No',
    //       consentStatus ? Colors.success['700'] : Colors.error['700']
    //     );
    //   },
    // }
    )
  }
  if (!isVbcTenant) {
    columns.push({
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="type"
        />
      ),
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      responsive: ['md'],
      width: 100,
      filters: statusFilters,
      filteredValue: typeFilters,
      render: (type: string) => {
        return (
          <Text size={'smMedium'} color={Colors.Custom.Gray500}>
            {`${capitalizeText(type || '')}`}
          </Text>
        );
      },
    })
  }
  columns.push(
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500}}
    //       textLocalId="carrier"
    //     />
    //   ),
    //   dataIndex: 'carrier',
    //   key: 'carrier',
    //   width: 100,
    //   responsive: ['md'],
    //   ellipsis: true,
    //   render: (carrier: IMembersCarrier) => {
    //     return (
    //       <HStack>
    //         <VStack>
    //           <Text size={'smMedium'} color={Colors.Custom.Gray900}>
    //             {carrier.value}
    //           </Text>
    //           <Text size={'smNormal'} color={Colors.Custom.Gray500}>
    //             {carrier.type}
    //           </Text>
    //         </VStack>
    //       </HStack>
    //     );
    //   },
    // },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="dependents"
        />
      ),
      dataIndex: 'contact',
      key: 'contact',
      width: 100,
      render: (contact: IMembersContact) => {
        return <DependentCount contactId={contact.id || ''} />;
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || ''}}
          textLocalId="preferredLocation"
        />
      ),
      dataIndex: 'location',
      key: 'location',
      width: 150,
      render: (text: any): JSX.Element => {
        const result = text?.length > 0 ? text[0].practiceLocation?.name : '--';
        return (
          <View>
            <>
              <Text size={'smMedium'} color={Colors.Custom.Gray500}>
                {result}
              </Text>
            </>
          </View>
        );
      },
      responsive: ['md'],
    },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500}}
    //       textLocalId="campaignStatus"
    //     />
    //   ),
    //   dataIndex: 'campaign',
    //   key: 'campaignStatus',
    //   responsive: ['md'],
    //   ellipsis: true,
    //   width: 100,
    //   render: (campaign: IMembersCampaign[]) => {
    //     const elements = campaign.map((item) => {
    //       return renderPill(
    //         Colors.success[50],
    //         item?.status,
    //         Colors.success['700']
    //       );
    //     });
    //     return elements;
    //   },
    // },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || ''}}
          textLocalId="actions"
        />
      ),
      key: 'action',
      width: '8%',
      fixed: 'right',
      responsive: ['md'],
      render: (record: IMembersData) => {
        return (
          <MembersTableActions record={record} onActionPerformed={actionFn} isVbcTenant={isVbcTenant} />
        );
      },
    }
  );

  return columns;
};

export const getEmployeeGroupColumns = (
  args: IMembersGroupTableColumnsArgs
) => {
  const {actionFn} = args;
  const columns = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => {
        return (
          <HStack alignItems={'center'}>
            <VStack style={{marginLeft: 12}}>
              <Text
                size={'smMedium'}
                fontSize={18}
                fontWeight={500}
                color={Colors.Custom.Gray900}
              >
                {name.length < 30 ? name : name.slice(0, 30) + '...'}
              </Text>
            </VStack>
          </HStack>
        );
      },
    },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500}}
    //       textLocalId="campaignStatus"
    //     />
    //   ),
    //   dataIndex: 'campaign',
    //   key: 'campaignStatus',
    //   render: (campaign: IMembersCampaign) => {
    //     return renderPill(
    //       Colors.success[50],
    //       campaign?.status,
    //       Colors.success['700']
    //     );
    //   },
    // },
    {
      render: (record: IMembersGroupData) => {
        return (
          <MembersGroupTableActions
            record={record}
            onActionPerformed={actionFn}
          />
        );
      },
    },
  ];

  return columns;
};

export const getMemberFirstNameAndLastName = (
  contact: IMembersContact
): {
  firstName: string;
  lastName: string;
} => {
  if (isEmptyObject(contact)) {
    return {
      firstName: '',
      lastName: '',
    };
  }
  const name = (contact.name || '').trim();
  if (!name) {
    return {
      firstName: '',
      lastName: '',
    };
  }
  const nameArray = name.split(' ');
  if (nameArray.length < 2) {
    return {
      firstName: name,
      lastName: '',
    };
  }
  return {
    firstName: nameArray[0],
    lastName: nameArray.pop() || '',
  };
};

export const getExtraCountString = (count: number) => {
  if (!count || count == 0) {
    return count;
  }
  const strCount = count.toString();
  if (strCount.length > 2) {
    return strCount.slice(0, 1) + `..`;
  }
  return strCount;
};

export const getServiceLogsQueryObject = (
  campaign?: Campaign,
  workflow?: Workflow
) => {
  const _or: any[] = [];
  if (campaign?.id) {
    _or.push({
      campaignId: {
        _eq: campaign?.id,
      },
    });
  }

  if (workflow?.workflowMasterId) {
    _or.push({
      workflowMasterId: {
        _eq: workflow?.workflowMasterId,
      },
    });
  }

  return {_or};
};

export const getDifference = (args: {
  serviceLogs: ServiceLog[];
  memberList: MemberData[] | any[];
}) => {
  const {serviceLogs, memberList} = args;
  const result = memberList.filter((item) => {
    const contactId = Number.isInteger(item) ? item : item?.id?.toString();
    const id = contactId;
    let found = false;
    serviceLogs.forEach((item) => {
      const resourceLogs = item?.resourceLevelLog || [];
      if (resourceLogs.some((r) => r.resourceId == id)) {
        found = true;
      }
    });
    return !found;

  });
  return result;
}


export const getMemberTableFilter = (data: any[]) => {
  let enrollmentStatusFilters: any[] = [];
  data.forEach((d) => {
    const contactMembership = d.contactMembership || [];
    if (contactMembership.length > 0) {
      contactMembership.forEach((item: any) => {
        enrollmentStatusFilters.push({
          text: item.enrollmentStatus,
          value: item.enrollmentStatus,
        });
      });
    }
  });

  let planFilters: any[] = [];
  data.forEach((item) => {
    const contactMembership = item.contactMembership || [];
    if (contactMembership.length > 0) {
      contactMembership.forEach((membership: any) => {
        planFilters.push({
          text: membership.planName,
          value: membership.planName,
        });
      });
    }
  });

  enrollmentStatusFilters = [
    ...new Map(
      enrollmentStatusFilters.map((item) => [item['text'], item])
    ).values(),
  ];

  planFilters = [
    ...new Map(planFilters.map((item) => [item['text'], item])).values(),
  ];

  return {enrollmentStatusFilters, planFilters};
};
function filterContactMemberShip(args: {
  record: any;
  value: any;
  key: 'status' | 'planName';
}) {
  const {record, key, value} = args;
  let displayString = '';
  if (record?.contactMemberShip?.length > 0) {
    const contactMemberShip = record.contactMemberShip;
    const object = contactMemberShip[0] || {};
    displayString = object?.[key] || '-';
  }
  return displayString.indexOf(value) === 0;
}

function getEnrolmentStatusText(contactMemberShip: ContactMembership[]) {
  let displayString = '';
  if (contactMemberShip?.length > 0) {
    contactMemberShip.forEach((item, index) => {
      if (index == 0) {
        displayString = `${item.status}`;
      } else {
        displayString += `, ${item.status}`;
      }
    });
  }
  return displayString;
}

function getPlanNameText(contactMemberShip: ContactMembership[]) {
  let displayString = '';
  if (contactMemberShip?.length > 0) {
    contactMemberShip.forEach((item, index) => {
      if (index === 0) {
        displayString = `${item.planName}`;
      } else {
        displayString += `, ${item.planName}`;
      }
    });
  }
  return displayString;
}

export const getActiveMembership = (data: any): ContactMembership[] => {
  const result: ContactMembership[] = [];
  const contactMembership: ContactMembership[] = data.contactMembership;
  if (contactMembership?.length === 0) {
    return result;
  }

  const activeMembership = contactMembership.filter(
    (item) => item.status === 'active'
  ) || [];

  if(activeMembership && activeMembership.length > 0){
    return activeMembership
  }
  const unpaidMembership = contactMembership.filter(item => item.status === 'unpaid')
  if(unpaidMembership && unpaidMembership.length > 0){
    return unpaidMembership
  }

  const pendingMembership = contactMembership.filter(item => item.status == 'pending')
  if(pendingMembership && pendingMembership.length > 0){
    return pendingMembership
  }

 const latest = contactMembership.reduce((latest, current) => {
    const latestDate = new Date(latest.updatedAt).getTime();
    const currentDate = new Date(current.updatedAt).getTime();
    return currentDate > latestDate ? current : latest;
  });

  return [latest]
};

export const getFilterQuery = (filterData: {
  status: string[];
  planName: string[];
}) => {
  let {status, planName} = filterData;
  if (!planName) {
    planName = [];
  }
  if (!status) {
    status = [];
  }
  const _and = [];
  if (status.length > 0) {
    const enrollmentStatusFilter = status.map((item) => {
      return {
        status: {
          _eq: item,
        },
      };
    });
    if (enrollmentStatusFilter.length > 0) {
      _and.push({
        _or: enrollmentStatusFilter,
      });
    }
  }
  if (planName.length > 0) {
    const planNameFilters = planName.map((item) => {
      return {
        planName: {
          _eq: item,
        },
      };
    });
    if (planNameFilters.length > 0) {
      _and.push({
        _or: planNameFilters,
      });
    }
  }
  _and.push({
    isDeleted: {_eq: false},
  });
  return {_and};
};

export const getHintPlanFilters = (
  data: HintPlan[]
): {text: string; value: string}[] => {
  const result: {text: string; value: string}[] = [];
  if (isEmptyArray(data)) {
    return result;
  }
  data.forEach((item) => {
    result.push({
      text: item.plan.name,
      value: item.plan.name,
    });
  });
  return result;
};

export const getResourceLogsQueryObject = (args: {
  campaign?: Campaign;
  workflow?: Workflow;
  resourceIds: string[];
}) => {
  const where: any = {};
  where.resourceType = {
    _eq: 'CONTACT',
  };
  if (args.resourceIds.length > 0) {
    where.resourceId = {
      _in: args.resourceIds.map((item) => item?.toString()),
    };
  }
  if (args.workflow?.workflowMasterId) {
    where.serviceLog = {
      workflowMasterId: {
        _eq: args.workflow.workflowMasterId,
      },
    };
  }
  if (args.campaign?.id) {
    where.serviceLog = {
      campaignId: {
        _eq: args.campaign.id,
      },
    };
  }
  return {...where};
};

export const getDifferenceWithResourceLogs = (args: {
  resourceLevelLogs: {
    resourceId:string
  }[];
  memberList: MemberData[] | any[];
}) => {
  const {resourceLevelLogs, memberList} = args;
  const result = memberList.filter((item) => {
    const contactId = Number.isInteger(item) ? item : item?.id?.toString();
    const id = contactId;
    let found = false;
    const check = resourceLevelLogs.some(item => item.resourceId == id)
    if(check){
      found = true
    }
    return !found;
  });
  return result;
}

export const addEmployeeCareJourneys = async (args: {
  employeeData: IMembersData[],
  getAllPatientCareJourneys: LazyQueryExecFunction<any, OperationVariables>;
  isMultiTenancyEnabled?: boolean;
  accountLocationUuids?: string[];
}) => {
  const {employeeData, getAllPatientCareJourneys , isMultiTenancyEnabled, accountLocationUuids} = args;
  const patientIdList = getPatientIdListFromEmployeeData({employeeData});
  const patientCareJourneyMap: any = {};
  const contactUuids = getContactUuidsFromEmployeeData({employeeData});

  const condition: any = getPatientCareJourneyFetchCondition({
    patientIds: patientIdList,
    contactIds: contactUuids,
    isMultiTenancyEnabled: isMultiTenancyEnabled,
    accountLocationIds: accountLocationUuids,
  });
  const careJourneyResponse = await getAllPatientCareJourneys({
    variables: {condition},
  });
  const patientCareJourneys =
    careJourneyResponse.data?.patientCareJourneys || [];
  patientCareJourneys.forEach((item: any) => {
    const patientId = item.patientId;
    if (!patientCareJourneyMap[patientId]) {
      patientCareJourneyMap[patientId] = [];
    }
    patientCareJourneyMap[patientId].push(item);
  });
  const result = employeeData;
  result.forEach((item) => {
    const patientId =
      item.contact?.patient?.patientUuid ||
      item.contact?.patient?.patientId ||
      '';
    const journeys = (patientCareJourneyMap[patientId] || []).map(
      (item: any) => {
        return {
          name: item?.title || item?.careJourney?.title,
        };
      }
    );
    item.journeys = journeys;
  });
  return result;
};

export const addEmployeeCampaigns = async (args: {employeeData: IMembersData[]}) => {
  const {employeeData} = args;
  const contactIdsList =
    employeeData.map((item) => item.contact?.id?.toString()) || [];
  const campaignResponse = await getCampaignByResourceIdList(contactIdsList);
  const campaignList = campaignResponse?.campaignList || [];
  const contactCampaignMap: any = {};
  campaignList.forEach((item: any) => {
    const resourceId =
      item.resourceLevelLog.length > 0
        ? item.resourceLevelLog[0].resourceId
        : '';
    if (!contactCampaignMap[resourceId]) {
      contactCampaignMap[resourceId] = [];
    }
    contactCampaignMap[resourceId].push(item);
  });
  const result = employeeData;
  result.forEach((item) => {
    const campaign = (contactCampaignMap[item.contact?.id || ''] || []).map(
      (item: any) => {
        return {
          name: item?.campaign?.name || '',
          status: item?.campaign?.status || ''
        };
      }
    );
    item.campaign = campaign;
  });
  return result;
};

export const getPatientIdListFromEmployeeData = (args: {
  employeeData: IMembersData[],
}) => {
  const {employeeData} = args;
  const patientIdList: string[] = [];
  employeeData.forEach((item) => {
    if (
      item.contact?.patient?.patientUuid ||
      item.contact?.patient?.patientId
    ) {
      patientIdList.push(
        item.contact.patient.patientUuid || item.contact.patient.patientId
      );
    }
  });
  return patientIdList;
};


export const getContactUuidsFromEmployeeData = (args: {
  employeeData: IMembersData[],
}) => {
  const {employeeData} = args;
  const contactUuids: string[] = [];
  employeeData.forEach((item) => {
    if(item.contact?.uuid){
      contactUuids.push(item.contact?.uuid);
    }
  });
  return contactUuids;
}
