import {useLazyQuery, useMutation} from '@apollo/client';
import {Space, Table} from 'antd';
import {useToast, View} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
// import {Spinner} from 'native-base';
// import {PERSON_TYPES} from '../../../../../../../constants';
import {useNavigate} from 'react-router-dom';
import {ILeadListViewProps} from '../../..';
import {
  IS_MINICONTACT_VIEW_ON_LEADS,
  MLOV_CATEGORY,
  PATIENT_AND_LEAD_PAGE_SIZE_OPTIONS,
  PERSON_TYPES,
  RECENT_ITEM_TYPES,
  RIGHT_SIDE_CONTAINER_CODE,
} from '../../../../../../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
} from '../../../../../../../constants/ActionConst';
import {CARESTUDIO_APOLLO_CONTEXT, CLOUD_TELEPHONY_APOLLO_CONTEXT} from '../../../../../../../constants/Configs';
import {FHIR_RESOURCE} from '../../../../../../../constants/FhirConstant';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import ConversationsQueries from '../../../../../../../services/Conversations/ConversationsQueries';
import {
  CareJourneyQueries,
  CloudTelephonyQueries,
  RecentItemsQueries,
  UserQueries,
} from '../../../../../../../services/index';
import LeadQueries, {getContactDataFromAPI} from '../../../../../../../services/Lead/LeadQueries';
import {GetContactsSubscription} from '../../../../../../../services/Stripe/StripeServices';
import {checkAbilityAccess, checkAccountConfigAbilityAccess, getAccountConfigResourceAbilities, getOrgAbilites, getResourceAbilities} from '../../../../../../../utils/capabilityUtils';
import {
  getAccountId,
  getAccountUUID,
  getUserId,
  getUserUUID,
  isMasterAccount,
  IS_FEATURE_FLAG_ENABLED,
  getBooleanFeatureFlag,
  getAllowedUserAccountLocationUuids,
  checkThisEmployeeIsRestricted,
} from '../../../../../../../utils/commonUtils';
import {EventBus} from '../../../../../../../utils/EventBus';
import {getMlovId, getMlovIdFromCode, getMlovListByCategory} from '../../../../../../../utils/mlovUtils';
import {INewConversationResponse} from '../../../../../TeamInbox/Conversations/interfaces';
import MessagingContactDetailsDrawer from '../../../../../TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import {getSearchContactsFilterObject} from '../../../../ContactsUtils';
import {getLeadTableViewColumns} from '../Helper/LeadListViewColumns';
import {
  formateLeadListData,
  getContactCampaigns,
  getMemberGroupMap,
  getPatientCareJourneys,
  mapContactWithSubscriptionData,
  onMultipleSelectedItem,
  // onMultipleSelectedItem,
  onPagination,
  removeDuplicates,
} from './LeadListHelper';
import './tableViewCustomStyle.css';
import { LabelTagging } from '../../../interfaces';
import { TableWrapper } from '../../../../../../common/TableWrapper';
import {GET_CONTACTS_BY_CARE_MANAGER, GET_MY_PATIENT} from '../../../../../../../services/CareTeam/CareTeamQueries';
import { withMiniContactViewHOC } from '../../../../../../MiniContactViewHOC';
import { CARE_TEAM_MEMBER_TYPE } from '../../../../../../../constants/MlovConst';
import {getMemberGroupListByMultipleContactIds} from '../../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {CHANNEL_TYPE} from '../../../../../TeamInbox/Conversations/ConversationConst';
import InboxQueries from '../../../../../../../services/Inbox/InboxQueries';
import {IUsersResponse} from '../../../../TeamMembers/interfaces';
import {ISearchContactFilter} from './interface';
import { isAccountConfigEnabled } from '../../../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../../../constants/AccountConfigConst';
import MemberNotFoundSvg from '../../../../../../common/Svg/MemberNotFoundSvg';
import {Text} from 'native-base';
import { Colors } from '../../../../../../../styles';
import FeatureFlags from '../../../../../../../constants/FeatureFlags.enums';
import { MAIN_MENU_CODES } from '../../../../../../SideMenuBar/SideBarConst';
import { USER_ACCESS_PERMISSION } from '../../../../../UserAccess/UserAccessPermission';
import { useRestrictedEmployeeProfile } from '../../../../../../../utils/useRestrictedEmployeeProfile';
import { showToast } from '../../../../../../../utils/commonViewUtils';
import { ToastType } from '../../../../../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';


const LeadListView = (props: ILeadListViewProps): JSX.Element => {
  const toast = useToast();
  const intl = useIntl();
  const canCreatePatient = checkAccountConfigAbilityAccess(FHIR_RESOURCE.PATIENT, 'canCreate')?.isEnabled;

  const {selectedUserPracticeLocation, updateContactCount} = props;
  const mlovData = useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED) || isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const restrictedEmployeeProfileData = useRestrictedEmployeeProfile();
  const searchString = props.searchText;
  const accountId = getAccountId();
  const accountUuid = getAccountUUID();
  const userUuid = getUserUUID();
  const tagItem: any = props.tagItem;
  const status: any = props.status;
  const loggedInStatus: string | undefined = props.loggedInStatus;
  const inboxItem = props.inboxItem;
  const contactTypeId = props.personTypeUuid;
  const contactType = props.personType;
  const isMasterAccountFlag = isMasterAccount()
  const formData = props.formData;
  const [createRecentpatient] = useMutation(
    RecentItemsQueries.CREATE_RECENT_ITEM
  );
  const [getContactConsentByIds] = useLazyQuery(LeadQueries.GetConsentsByContactIds,{
    fetchPolicy: 'no-cache'//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  })
  const [getContactTags] = useLazyQuery(LeadQueries.GetContactTags, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });
  const [getMyPatient] = useLazyQuery(GET_MY_PATIENT, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });

  const [getContactsByCareManager] = useLazyQuery(GET_CONTACTS_BY_CARE_MANAGER, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });
  const [searchContacts] = useLazyQuery(LeadQueries.searchContacts,{
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  })
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);

  const recentPatientType = getMlovId(
    mlovData.MLOV,
    MLOV_CATEGORY.RECENT_ITEM_TYPES,
    RECENT_ITEM_TYPES.PATIENTS
  );
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const userUUID = getUserUUID();
  const navigate = useNavigate();
  const filter: any = {
    _or: [],
    _and: [],
    contactTagging: {},
    contactInboxes: {},
  };
  const userId = getUserId();
  const [stateData, setStateData] = useState({
    searchText: props.searchText,
    searchedColumn: '',
    filteredInfo: null,
    sortedInfo: null,
    isLoading: true,
    leadList: [],
    currentPage: props?.currentPage,
    offSet: 0,
    pageSize: 10,
    sortD: '',
    order_by: {
      updatedAt: 'desc',
    },
    selectedItems: [] as any,
    selectedRowKey: [] as any,
    customerDataList: [],
    loading: false,
    contactCount: null,
    hideConvertToPatient: false,
    inboxes: {},
    userDataList: {},
    virtualPhoneNumberAssignees: {},
  });

  const [getUsersData] = useLazyQuery<IUsersResponse>(UserQueries.GET_USERS, {
    variables: {
      searchString: '%%',
      accountId: getAccountId(),
    },
  });

  const [GET_NUMBER_CALL_SMS] = useLazyQuery(
    CloudTelephonyQueries.GET_OUTGOING_CALL_NUMBERS,{
      fetchPolicy: 'no-cache',
    }
  );

  const [getInboxesWithChannelTypeFilter] = useLazyQuery(
    InboxQueries.GetInboxesWithChannelTypeFilter,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        whereCondition: {
          accountId: {
            _eq: accountId
          },
          isDeleted: {
            _eq: false
          },
          channelType: {
            _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS
          },
          inboxMembers: {
            userId: {
              _eq: userId
            },
            isDeleted: {
              _eq: false
            }
          }
        }
      },
    }
  );

  const careTeamMemberTypesList = getMlovListByCategory(
    MLOV_CATEGORY.CARE_TEAM_MEMBER_TYPE
  );

  const careTeamMemberTypes = {
    [CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]: getMlovIdFromCode(
      careTeamMemberTypesList,
      CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
    ),
  };

  const hintCapability = getAccountConfigResourceAbilities(FHIR_RESOURCE.HINT);
  const openInHint = hintCapability?.openInHint || false;

  const [getConversationByContactId] = useLazyQuery<INewConversationResponse>(
    ConversationsQueries.GetConversationByContactId,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [getAllPatientCareJourneys] = useLazyQuery(
    CareJourneyQueries.GET_ALL_PATIENT_CARE_JOURNEYS,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const allowedUserAccountP360LocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.P360_CARE_JOURNEY
  );

  useEffect(() => {
    setStateData({...stateData, pageSize: stateData?.pageSize || 10, offSet: 0, currentPage: 1});
  }, [searchString]);

  useEffect(() => {
    setStateData({
      ...stateData,
      pageSize: stateData?.pageSize || 10,
      offSet: 0,
      currentPage: props.currentPage,
    });
  }, [props.currentPage]);

  useEffect(() => {
    setStateData({
      ...stateData,
      selectedItems: [],
      selectedRowKey: [],
      pageSize: stateData?.pageSize || 10,
      offSet: 0,
      currentPage: 1,
      customerDataList: [],
    });
  }, [props.personTypeUuid]);

  useEffect(() => {
    // checking for null as if zero count comes then if condition should work
    if (stateData.contactCount !== null) {
      updateContactCount?.(stateData.contactCount ?? 0);
    }
  }, [stateData.contactCount]);


  let {sortedInfo}: any = {...stateData};
  sortedInfo = sortedInfo || {};
  const handleChange = (pagination: any, filters: any, sorter: any) => {
    // setStateData({...stateData, pageSize: pagination.pageSize});
    setStateData((prev) => ({
      ...prev,
      filteredInfo: filters,
      sortedInfo: sorter,
      pageSize: pagination.pageSize,
      currentPage: pagination.current,
      offSet: onPagination(pagination.current, pagination.pageSize),
      // order_by: handleSorting(sorter, stateData.order_by),
    }));
    props?.onActionPerformed(
      COMMON_ACTION_CODES.CHANGE_PAGE,
      pagination.current
    );
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setStateData((prev) => ({
      ...prev,
      pageSize: pageSize,
      currentPage: page,
      offSet: onPagination(page, pageSize),
    }));

    props?.onActionPerformed(COMMON_ACTION_CODES.CHANGE_PAGE, page);
  };

  const filterContactsStripeId = (contacts: any) => {
    const contactsStripeId = contacts.map((contact: any) => {
      return contact?.contactAdditionalInfos?.stripeCustomerId || ''
    });
    return contactsStripeId
  }

  const fetchCareMangedPatients = async (careManagerId: string) => {
    const response = await  getContactsByCareManager({
      variables: {
        userId: careManagerId,
        roleId: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]
      }
    })

    const careManagedPatients = response.data.care_team_member_types?.map((careTeam:any) => careTeam.careTeam?.contactId) || [];
    return careManagedPatients;
  }

  const getContactsByRestApi = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const searchContactActionFilter = getSearchContactsFilterObject({
      filter: filter,
      searchString: searchString,
      tagItem: tagItem,
      inboxItem: inboxItem,
      contactTypeId: contactTypeId,
      selectedUserPracticeLocations: selectedUserPracticeLocation,
      status: status,
      formData: formData,
      employerId: '',
      mlovData: mlovData,
      tab: props?.tab,
      loggedInStatus: loggedInStatus,
      onlyShowLead: true,
      sortFilter: stateData.filteredInfo,
      isMultiTenancyEnabled: isMultiTenancyEnabled
    });
    let contactData: any = [];
    if (searchContactActionFilter) {
      searchContactActionFilter.limit = stateData.pageSize || 10
      searchContactActionFilter.offset = stateData.offSet;
    const response = await searchContacts({
      variables: {
        params: searchContactActionFilter
      }
    })
    if (response?.data?.searchContacts?.contacts?.length) {
      contactData = formateLeadListData(response?.data?.searchContacts, mlovData);
      setStateData((prev) => {
        return {
          ...prev,
          customerDataList: contactData,
          loading: false,
          contactCount:
            response?.data?.searchContacts?.contactAggregate?.aggregate?.count,
        };
      });
      contactData = await getContactTagsAndMerge(contactData);
      const result = await getPatientCareJourneys({
        contactData,
        getAllPatientCareJourneys,
        isMultiTenancyEnabled: isMultiTenancyEnabled,
        accountLocationUuids: allowedUserAccountP360LocationUuids
      });
      const campaignMappingResult = await getContactCampaigns(
        contactData,
        result
      );
      contactData = await fetchAndInsertConsent(campaignMappingResult);
      if (mapMembershipData) {
        const contactsStripeId = filterContactsStripeId(
          response?.data?.searchContacts?.contacts
        );
        const contactsSubscriptionInfo = await GetContactsSubscription(
          contactsStripeId
        ).catch()
        contactData = mapContactWithSubscriptionData(
          contactData,
          contactsSubscriptionInfo?.data
        );
      }
      setStateData((prev) => {
        return {
          ...prev,
          customerDataList: contactData,
          loading: false,
          contactCount:
            response?.data?.searchContacts?.contactAggregate?.aggregate?.count,
        };
      });
    } else {
      // formattedData = contactData;
      setStateData((prev) => {
        return {
          ...prev,
          customerDataList: contactData,
          loading: false,
          contactCount:
            response?.data?.searchContacts?.contactAggregate?.aggregate?.count,
        };
      });
    }
    }
    else {
      setStateData((prev) => {
        return {
          ...prev,
          customerDataList: contactData,
          loading: false,
          contactCount: contactData?.length,
        };
      });
    }
  };
  const fetchAndInsertConsent = async (campaignMappingResult: any[]) => {
    const contactIds = campaignMappingResult?.map(
      (contact: any) => contact.uuid
    );
    const intContactIds = campaignMappingResult.map(
      (contact: any) => contact.id
    );
    const commonResponse = await Promise.all([
      getContactConsentByIds({
        variables: {
          accountUuid: accountUuid,
          contactIds: {
            _in: [...contactIds],
          },
        },
      }),
      getMemberGroupListByMultipleContactIds({
        accountUuid: accountUuid,
        contactIds: [...intContactIds, -1],
      }),
    ]);
    const consentResponseObj = commonResponse[0];
    const memberGroups = commonResponse[1];
    const memberGroupMap = getMemberGroupMap(memberGroups);
    const consentResponse = consentResponseObj?.data?.contactConsents?.map(
      (item: any) => item.contactUuid
    );
    const result = campaignMappingResult?.map((contact: any, index: number) => {
      const isConsentIncludes = consentResponse.includes(contact?.uuid);
      const groups = memberGroupMap.get(contact.id.toString()) || [];
      return {
        ...contact,
        contactData: {
          ...contact.contactData,
          contactConsents: isConsentIncludes
            ? [{contactUuid: contact?.uuid}]
            : [],
          groups,
        },
        singleLeadData: {
          ...contact.singleLeadData,
          contactConsents: isConsentIncludes
            ? [{contactUuid: contact?.uuid}]
            : [],
          groups,
        },
        consentLoading: false,
      };
    });
    return result;
  };

  const getContactTagsAndMerge = async (contactData: any[]) => {
    const contactIds = contactData.map((item) => item.id);
    const response = await getContactTags({
      variables: {
        contactIds: contactIds,
      },
    });
    const labelTaggings: LabelTagging[] = response.data.labelTaggings || [];
    const result = contactData.map((item) => {
      return {
        ...item,
        tags: labelTaggings.filter((tag) => tag.taggableId === item.id) || [],
      };
    });
    return result;
  };

  const onDeleteLead = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.DELETE, rowData);
  };

  const onEditLead = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.EDIT, rowData);
  };

  const onCallAction = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.CALL, rowData);
  };
  const onSmsAction = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.SMS, rowData);
  };
  const onChatAction = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.CHAT, rowData);
  };
  const onMailAction = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.MAIL, rowData);
  };
  const onMergeContactAction = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.MERGE, rowData);
  };
  const onAddRelationAction = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.ADD_RELATION, rowData);
  };
  const onConvertTo = (rowData: any, actionCode: any) => {
    props?.onActionPerformed(actionCode, rowData);
  };
  const onSetTag = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.SET_TAG, rowData);
  };

  const onDeactivate = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.DEACTIVATE, rowData);
  };

  const onActionPerformed = (actionCode: string, rowData: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.DELETE:
        onDeleteLead(rowData);
        break;
      case COMMON_ACTION_CODES.EDIT:
        onEditLead(rowData);
        break;
      case COMMON_ACTION_CODES.EDIT_LEAD:
        onEditLead(rowData);
        break;
      case COMMON_ACTION_CODES.CALL:
        onCallAction(rowData);
        break;
      case COMMON_ACTION_CODES.SMS:
        onSmsAction(rowData);
        break;
      case COMMON_ACTION_CODES.CHAT:
        onChatAction(rowData);
        break;
      case COMMON_ACTION_CODES.MAIL:
        onMailAction(rowData);
        break;
      case COMMON_ACTION_CODES.MERGE:
        onMergeContactAction(rowData);
        break;
      case COMMON_ACTION_CODES.ADD_RELATION:
        onAddRelationAction(rowData);
        break;
      case COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER:
        onConvertTo(rowData, actionCode);
        break;
      case COMMON_ACTION_CODES.SET_TAG:
        onSetTag(rowData);
        break;
      case COMMON_ACTION_CODES.ADD_NOTE:
        props?.onActionPerformed(COMMON_ACTION_CODES.ADD_NOTE, rowData);
        break;
      case COMMON_ACTION_CODES.RESET_PASSWORD:
        props?.onActionPerformed(COMMON_ACTION_CODES.RESET_PASSWORD, rowData);
        break;
      case COMMON_ACTION_CODES.SEND_FORMS:
        props?.onActionPerformed(COMMON_ACTION_CODES.SEND_FORMS, rowData);
        break;
      case COMMON_ACTION_CODES.PRINT_CLINICAL_PROFILE:
        props?.onActionPerformed(COMMON_ACTION_CODES.PRINT_CLINICAL_PROFILE, rowData);
        break;
      case COMMON_ACTION_CODES.CREATE_TASK:
        props?.onActionPerformed(COMMON_ACTION_CODES.CREATE_TASK, rowData);
        break;
      case COMMON_ACTION_CODES.SCHEDULE_APPOINTMENT:
        props?.onActionPerformed(
          COMMON_ACTION_CODES.SCHEDULE_APPOINTMENT,
          rowData
        );
        break;
      case COMMON_ACTION_CODES.SEND_MEMBERSHIP_LINK:
        props?.onActionPerformed(
          COMMON_ACTION_CODES.SEND_MEMBERSHIP_LINK,
          rowData
        );
        break;
      case COMMON_ACTION_CODES.CREATE_AUTOMATION:
        props?.onActionPerformed(
          COMMON_ACTION_CODES.CREATE_AUTOMATION,
          rowData
        );
        break;
      case COMMON_ACTION_CODES.ON_BULK_ACTION:
        props?.onActionPerformed(COMMON_ACTION_CODES.ON_BULK_ACTION, rowData);
        break;
      case COMMON_ACTION_CODES.DEACTIVATE:
        onDeactivate(rowData);
        break;
      case 'RowClick':
        onRowClicked(rowData);
        break;
      case COMMON_ACTION_CODES.SEND_PROVIDER_REQUEST_FORM:
        props?.onActionPerformed(
          COMMON_ACTION_CODES.SEND_PROVIDER_REQUEST_FORM,
          rowData
        );
        break
      case COMMON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL:
        props?.onActionPerformed(
          COMMON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL,
          rowData
        );
        break;
    }
  };
  const handleAddRecentPateint = async (contactUUID: string) => {
    const responseData = await createRecentpatient({
      variables: {
        accountId: accountId,
        itemUUID: contactUUID,
        recentItemTypeUUID: recentPatientType,
        userUuid: userUUID,
      },
    });
    if (responseData?.data?.insert_recent_items?.id) {
    }
  };
  const onRowClicked = (rowData: any) => {
    if (rowData?.contactType?.code == PERSON_TYPES.CUSTOMER) {
      const contactUUID = rowData?.singleLeadData?.uuid;
      handleAddRecentPateint(contactUUID);
    }
    if (IS_MINICONTACT_VIEW_ON_LEADS) {
      setSelectedRow(rowData);
    } else {
      if (rowData?.contactType?.code == PERSON_TYPES.CUSTOMER) {
        props?.navigateOrOpenContactIdDrawer?.(rowData.id);
      } else if (rowData?.contactType?.code == PERSON_TYPES.LEAD) {
        navigate(`/members/prospect/${rowData.id}`);
      } else {
        navigate(`/members/supporting-memeber/${rowData.id}`);
      }
    }

    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent('ADD_RECENT_MEMEBER', rowData.singleCustomerData);
  };
  const canShowMembership = () => {
    return IS_FEATURE_FLAG_ENABLED('ENABLE_MEMBERSHIP');
  };
  const mapMembershipData = canShowMembership() || false

  const {height} = Dimensions.get('window');
  const finalHeight = height - 236;

  const fetchMyPatient = async ()=> {
    const myPatient = await getMyPatient({
      variables: {
        userId: userUuid,
        tenantId: accountUuid
      }
    });
    if (myPatient?.data?.careTeams?.length) {
      const list = myPatient?.data?.careTeams?.map((patient: any)=>{
        return patient?.contactId
      })
      return list
    }
    return []
  }

  const shouldHideConvertToPatient = () => {
    if (props?.hideConvertToPatient) {
      return props?.hideConvertToPatient;
    } else {
      canCreatePatient && stateData?.hideConvertToPatient && openInHint
    }
  }

  const getInboxData = async () => {
    try {
      const userDataList = await getUsersData();
      const inboxResp = await getInboxesWithChannelTypeFilter();
      const virtualPhoneNumberAssignees = await GET_NUMBER_CALL_SMS({
        context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
        variables: {
          accountUuid: accountUuid,
        },
      });
      setStateData((prev) => {
        return {
          ...prev,
          inboxes: inboxResp,
          userDataList: userDataList,
          virtualPhoneNumberAssignees: virtualPhoneNumberAssignees,
        }
      });
    } catch (err) {
      setStateData((prev) => {
        return {
          ...prev,
          inboxes: {},
          userDataList: {},
          virtualPhoneNumberAssignees: {},
        }
      });
    }
  }

  useEffect(() => {
    setStateData({
      ...stateData,
      selectedItems: [],
      selectedRowKey: [],
    });
    onActionPerformed(COMMON_ACTION_CODES.ON_BULK_ACTION, []);
  }, [props.clearAllEvent]);
  useEffect(() => {
    getContactsByRestApi();
  }, [
    RIGHT_SIDE_CONTAINER_CODE.ADDED_OR_UPDATED_CONTACT ===
    props.addOrUpdateEvent,
    props.searchText,
    stateData.currentPage,
    props.personTypeUuid,
    stateData.pageSize,
    props.formData,
    stateData.filteredInfo,
    props?.contactUpdated,
    props?.tab
  ]);

  useEffect(() => {
    getInboxData();
  },[])

  return (
    <>
      <View
        style={{
          backgroundColor: '#fff',
          // borderColor: Colors.Custom.BorderColor,
          // borderWidth: ,
          // borderRadius: 8,
          overflow: 'hidden',
          // margin: 12
        }}
      >
        {selectedRow && (
          <MessagingContactDetailsDrawer
            contactData={selectedRow?.contactData}
            isDrawerVisible={selectedRow ? true : false}
            contactType={selectedRow?.contactType}
            onSideBarActionPerformed={(action?: any, actionData?: any) => {
              if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
                setSelectedRow(null);
                return
              } else if(action == COMMON_ACTION_CODES.ADDED_OR_UPDATED) {
                props?.onActionPerformed(action, actionData);
                setSelectedRow(null);
                return
              }
              props?.onActionPerformed(action, actionData);
            }}
            borderLessView={true}
          />
        )}

        <TableWrapper
          pagination={{
            // position: ['bottomCenter'],
            pageSize: stateData.pageSize,
            pageSizeOptions: PATIENT_AND_LEAD_PAGE_SIZE_OPTIONS,
            total: stateData.contactCount || 0,
            current: stateData.currentPage,
            onChange: handlePaginationChange,
            showSizeChanger: true,
          }}
          hideOnSinglePage={false}
        >
          <Table
            loading={stateData.loading}
            rowClassName={(record, index) =>
              index % 2 == 0 ? 'table-row-light' : ''
            }
            rowKey={(record) => {
              return record.id;
            }}
            scroll={{x: 1200, y: finalHeight}}
            className="lead-list-table-view patient-list-view"
            locale={{
              emptyText: (
                <Space direction="vertical" align="center">
                  <MemberNotFoundSvg />
                  <Text color={Colors.FoldPixel.GRAY300}>No matches were found. Please verify details and retry.</Text>
                </Space>
              ),
            }}
            columns={getLeadTableViewColumns(
              sortedInfo,
              onActionPerformed,
              contactType,
              canShowMembership(),
              isMasterAccountFlag,
              shouldHideConvertToPatient(),
              undefined,
              true,
              stateData?.selectedItems?.length || 0,
              undefined,
              {
                inboxes: stateData?.inboxes,
                userDataList: stateData?.userDataList,
                virtualPhoneNumberAssignees: stateData?.virtualPhoneNumberAssignees,
              }
            )}
            dataSource={stateData.customerDataList}
            onChange={handleChange}
            rowSelection={ (isMasterAccountFlag || isMsoEnabled) ? undefined : {
              type: 'checkbox',
              selectedRowKeys: stateData?.selectedRowKey,
              onSelect: (item: any) => {
                onMultipleSelectedItem(
                  item,
                  stateData.selectedItems,
                  (returnItems: any) => {
                    const selectedKey: any = [];
                    returnItems.forEach((element: any) => {
                      selectedKey.push(element.id);
                    });
                    onActionPerformed(
                      COMMON_ACTION_CODES.ON_BULK_ACTION,
                      returnItems
                    );
                    setStateData((prev) => {
                      return {
                        ...prev,
                        selectedItems: returnItems,
                        selectedRowKey: selectedKey,
                      };
                    });
                  }
                );
              },
              onSelectAll: (isSelected: boolean, data: any) => {
                if (!isSelected) {
                  const arrayKey = [...stateData?.selectedRowKey];
                  const arrayItems = [...stateData?.selectedItems];
                  stateData.customerDataList?.map((item?: any) => {
                    arrayKey.map((itemId?: any) => {
                      if (item?.id == itemId) {
                        const index = arrayKey.indexOf(itemId);
                        arrayKey.splice(index, 1);
                        setStateData({
                          ...stateData,
                          //selectedItems: [],
                          selectedRowKey: arrayKey,
                        });
                      }
                    });
                    arrayItems.map((itemId?: any) => {
                      if (item?.id == itemId?.id) {
                        const index = arrayItems.indexOf(itemId);
                        arrayItems.splice(index, 1);
                        setStateData({
                          ...stateData,
                          selectedItems: arrayItems,
                          selectedRowKey: arrayKey,
                        });
                      }
                    });
                  });
                  onActionPerformed(
                    COMMON_ACTION_CODES.ON_BULK_ACTION,
                    arrayItems
                  );
                } else {
                  const arrayItems = [...stateData?.selectedItems];
                  stateData.customerDataList?.forEach((item?: any) => {
                    arrayItems.push(item);
                  });
                  //Remove duplicate from Arraylist
                  const uniqueArray = removeDuplicates(arrayItems, 'id');
                  const selectedKey: any = [];
                  uniqueArray.forEach((element: any) => {
                    selectedKey.push(element.id);
                  });

                  setStateData({
                    ...stateData,
                    selectedItems: uniqueArray,
                    selectedRowKey: selectedKey,
                  });
                  onActionPerformed(
                    COMMON_ACTION_CODES.ON_BULK_ACTION,
                    uniqueArray
                  );
                }
              },
            }}
            onRow={(_record: any) => {
              return {
                onClick: async () => {
                  const isRestrictedEmployeeProfileView = await checkThisEmployeeIsRestricted(
                    restrictedEmployeeProfileData?.isRestrictedParticularLocationEmployeeEnabled,
                    _record?.contactData?.contactPracticeLocations?.map((location: any) => location?.accountLocation?.uuid),
                    restrictedEmployeeProfileData?.restrictedLocationIds
                  );
                  if (!isRestrictedEmployeeProfileView) {
                    onRowClicked(_record);
                  } else {
                    showToast(
                      toast,
                      intl.formatMessage({
                        id: 'patientProfileAccessDenyMsg',
                      }),
                      ToastType.info,
                      2000,
                      true
                    );
                  }
                },
              };
            }}
            pagination={false}
          />
        </TableWrapper>
      </View>
    </>
  );
};
export default withMiniContactViewHOC(LeadListView);
