import {useEffect} from 'react';
import  {useLocation} from 'react-router-dom';
import {isEmployerRole} from '../../utils/commonUtils';
import LocalStorage from '../../utils/LocalStorage';

declare const window: any;

const LoginScreen = () => {
  const {state} = useLocation();
  const isEmployer = isEmployerRole();

  //on change of isFetchingLoggedInInfo
  useEffect(() => {
      LocalStorage.getItem('user').then((result) => {
        if (result !== null) {
          if (state?.logout === true) {
            LocalStorage.removeItem('consent_Obj');
            LocalStorage.removeItem('user');
            LocalStorage.removeItem('userRoles');
            LocalStorage.removeItem('userPracticeLocations');
            LocalStorage.removeItem('userAccountLocationUuids');

           // window.location.href = window.location.origin + '/#/'
            window.history.replaceState({}, null)
            window.location.reload();
          } 
          else {
            let redirectString = '/#/home';
            if (isEmployer) {
              redirectString = '/#/analytics';
            }
            window.location.href = window.location.origin + redirectString;
            //window.location.reload();
          }
        }
      });
  }, []);

  return (
    <></>
  );
};

export default LoginScreen;
