import React, {useContext, useEffect, useState} from 'react';
import {Drawer, Popover, Tooltip} from 'antd';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {Colors} from '../../../styles';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  EVENT_NAMES,
  MLOV_CATEGORY,
  PERSON_TYPES,
  TASK_TYPES,
} from '../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {useApolloClient, useLazyQuery, useQuery} from '@apollo/client';
import {LeadQueries, TaskQueries} from '../../../services';
import {ITask} from '../CareDashboard/CareDashboardInterfaces';
import {
  Badge,
  Box,
  Divider,
  FlatList,
  HStack,
  Icon,
  Pressable,
  Skeleton,
  Spinner,
  Stack,
  Text,
  VStack,
  View,
} from 'native-base';
import {
  BottomViewAction,
} from '../../TaskCard/TaskCardHelper';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {
  getMlovId,
  getMlovListByCategory,
  getMlovListFromCategory,
  getMlovObjectFromCode,
  getMlovValue,
} from '../../../utils/mlovUtils';
import {
  IPropertyDetails,
  PropertyType,
  getCompletedTaskStatusId,
} from '../CareDashboard/CareDashboardUtils/CareDashboardUtils';
import {useIntl} from 'react-intl';
import AddOrUpdateTask from '../AddTask/AddOrUpdateTask';
import {EventBus} from '../../../utils/EventBus';
import InfoSection from '../CareProgramStepOutreach/components/InfoSection';
import {ContactRelationCommunication} from '../CareProgramStepOutreach/components/Communication';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import {
  getAccountUUID,
  getBooleanFeatureFlag,
  getPatientActiveProfileLocationIds,
  getUserFullName,
  getUserUUID,
  isResourceLocationActive,
} from '../../../utils/commonUtils';
import TaskListItemV2 from './components/TaskListItemV2';
import {ToastType} from '../../../utils/commonViewUtils';
import {useToast} from '../../Toast/ToastProvider';
import useTaskActionManager from '../CareDashboard/CustomHook/useTaskActionManager';
import {ClinicalSectionFilterTabs} from '../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionFilterTabs/ClinicalSectionFilterTabs';
import {cloneDeep, debounce} from 'lodash';
import useGetBatchedAccountUsers from '../../CustomHooks/useGetBatchedAccountUsers';
import Feather from 'react-native-vector-icons/Feather';
import {getFormDataFromLeadData} from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {ParticipantType} from '../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {ILoginUserData} from '../../../Interfaces';
import WriteInfoBackBanner from '../../PersonOmniView/MiddleContainer/CareTimeline/components/WriteInfoBackBanner';
import SortByTaskSortType from '../CommonFilters/SortByTaskSortType';
import SortTaskIcon from '../../../assets/Icons/SortTask';
import {getDateStrFromFormat} from '../../../utils/DateUtils';
import {ITopBarData} from '../CareDashboard/CareDashboardTopBar/CareDashboardTopBar';
import FilterDrawer from '../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/FilterDrawer';
import {TASK_STATUS, TASK_STATUS_CODES} from '../../../constants/MlovConst';
import {getPatientTasksDefaultParams} from '../../TaskModule/TaskModuleHelper';
import {TaskPanelType} from '../../TaskCard/TaskEnum';
import {Dimensions} from 'react-native';
import NoTaskSvg from '../Svg/NoTaskSvg';
import { IFilter } from './PatientListInternalTaskInterfaces';
import AlertsView from '../../SideCar/AlertsView';
import SearchIconSvg from '../Svg/SearchMessageAllSvg/SearchIconSvg';
import FilterIconSvgForAlerts from '../Svg/SideCarSvg/FilterIconSvgForAlerts';
import { NoDataFound } from '../NoDataFound';
import { SearchBar } from '../SearchBar';
import './InternalTaskDrawer.css';
import CareGapDetailView from '../../SideCar/CareGapDetailView';
import {ITaskAlert} from '../../SideCar/SideBarCustomHooks/useFetchAlerts';
import { IDiagnosisGap } from '../../SideCar/interfaces';
import DiagnosisGapDetailView from '../../SideCar/DiagnosisGapDetailView';
import AddOrUpdateAlert from '../../SideCar/AddOrUpdateAlert';
import {GET_TASK_ALERT_DETAIL} from '../../../services/Task/TaskQueries';
import FeatureFlags from '../../../constants/FeatureFlags.enums';
import useContactProfile from '../ContactProfile/useContactProfile';
import { getContactActiveStatus, getLocationIdsParamForView, getUserActiveInTaskLocationStatus } from '../CareDashboard/CustomHook/TaskCustomHookHelper';
import { TaskViewFrom } from '../CareDashboard/CareDashboardTopBar/interfaces';
import { USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { usePermissions } from '../../CustomHooks/usePermissions';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import { useTaskManageAccess } from '../../TaskModule/CustomHooks/useTaskManageAccess';

interface IInternalTaskDrawerProps {
  onClose: (syncParent: boolean) => void;
  contactId: string;
  shouldOpenAlertsTab?: boolean;
  toShowOnlyDiagnosisGaps?: boolean;
}

export enum ActionableTab {
  TASKS = 'TASKS',
  ALERTS = 'ALERTS',
}

const InternalTaskDrawer = (props: IInternalTaskDrawerProps) => {
  // Constants and variables
  const {onClose, contactId, shouldOpenAlertsTab} = props;
  const pageSize = 20;
  const height = Dimensions.get('window').height * 0.7;
  const contextData = useContext(CommonDataContext);
  const intl = useIntl();
  const toast = useToast();
  const mlovData = useContext(CommonDataContext);
  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code, MAIN_MENU_CODES.CONSUMER);
  const primaryLocationIds = contextData.accountLocationListWithEHR?.filter((location) => {
    return permissionConfig?.allowedLocationIds?.includes(location?.uuid)
  })?.map((location) => location?.uuid) || [];

  const taskStatusMlov =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const accountUuid = getAccountUUID();
  const {
    loading: accountUserLoading,
    error: accountUsersError,
    userList: accountUserList,
    userMap: accountUserMap,
  } = useGetBatchedAccountUsers({
    useMap: true,
  });
  const client = useApolloClient();
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);
  const [contactDataLoading, setContactDataLoading] =
  React.useState<boolean>(true);
  const currentUserUUID = getUserUUID();
  const userData = mlovData.userData || ({} as ILoginUserData);
  const showManualAddGaps = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.SHOW_MANUAL_ADD_GAPS);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(contextData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const initialTaskTypes =
    getMlovListByCategory(MLOV_CATEGORY.TASK_ENTITY_TYPE) || [];
  const initialPriorityType =
    getMlovListByCategory(MLOV_CATEGORY.TASK_PRIORITY) || [];

  const { getLoggedInUserTaskAccessUsers } = useTaskManageAccess();

  const taskStatusMlovs = getMlovListByCategory(MLOV_CATEGORY.TASK_STATUS);
  const acceptedStatusMlov = getMlovObjectFromCode(
    TASK_STATUS.ACCEPTED,
    taskStatusMlovs
  );
  const missedStatusMlov = getMlovObjectFromCode(
    TASK_STATUS_CODES.MISSED,
    taskStatusMlovs
  );

  const allStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );

  const allStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );

  enum TaskTab {
    pending = 'pending',
    completed = 'completed',
  }

  // States
  const [componentState, setComponentState] = React.useState<{
    fetchLoading: boolean;
    taskListLoading: boolean;
    paginationLoading: boolean;
    taskList: ITask[];
    offset: number;
    totalTask: number;
    pendingTaskCount?: number;
    completedTaskCount?: number;
    selectedTask: ITask | null;
    isSomeThingChanged: boolean;
    currentTab: string;
    contactData: IContact | undefined;
    consentData?: any[];
    isShowAddTaskDrawer?: boolean;
    filterCount?: number | null;
    formattedContactData?: any;
    selectedTab?: string;
    showFiltersTabInAlerts?: boolean;
    showSearchBar?: boolean;
    alertSearchText?: string;
    alertFilterCount?: number;
    pendingAlertsCount?: number;
    selectedCareGapDetail?: ITaskAlert;
    selectedDiagnosisGapDetail?: IDiagnosisGap;
    alertType?: string;
    task?: ITask;
    showAddOrUpdateAlert?: boolean;
    hasContactLocationAccess?: boolean;
  }>({
    fetchLoading: false,
    taskListLoading: false,
    paginationLoading: false,
    taskList: [],
    offset: 0,
    totalTask: 0,
    pendingTaskCount: 0,
    completedTaskCount: 0,
    selectedTask: null,
    isSomeThingChanged: false,
    currentTab: TaskTab.pending,
    contactData: undefined,
    consentData: [],
    isShowAddTaskDrawer: false,
    filterCount: 0,
    formattedContactData: {},
    selectedTab: shouldOpenAlertsTab ? ActionableTab.ALERTS : ActionableTab.TASKS,
    showFiltersTabInAlerts: false,
    showSearchBar: false,
    alertSearchText: '',
    alertFilterCount: 0,
    pendingAlertsCount: 0,
    selectedCareGapDetail: {} as ITaskAlert,
    selectedDiagnosisGapDetail: {} as IDiagnosisGap,
    alertType: '',
    task: {} as ITask,
    showAddOrUpdateAlert: false,
    hasContactLocationAccess: true,
  });

  const [sortData, setSortData] = useState<ITopBarData>({
    start: getDateStrFromFormat(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
    end: getDateStrFromFormat(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
    selectedSortUuid: 'endDateTime',
    selectedSortType: 'endDateTime',
    selectedSortValueAscending: true,
    addTask: false,
    hideActions: true,
    panelType: TaskPanelType.INTERNAL,
  });
  const [showSortPopover, setShowSortPopover] = useState(false);
  const [isShowFilterDrawer, setIsShowFilterDrawer] = useState<boolean>(false);
  const [propertyDetails, setPropertyDetails] = useState<IPropertyDetails>({
    propertyCodes: TASK_STATUS_CODES,
    propertyType: PropertyType.status,
  });
  const [loadingData, setLoadingData] = React.useState<{
    loading: boolean;
    taskIdWithAction?: any;
  }>({
    loading: false,
  });

  const [filter, setFilter] = useState<IFilter>({
    priorityTypeList: initialPriorityType,
    taskTypeList: initialTaskTypes,
    selectedStatusCodes: [acceptedStatusMlov?.code || '', missedStatusMlov?.code || ''],
    formData: {
      selectedDueDate: [allStatusValue],
      start: getDateStrFromFormat(
        new Date(),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      ),
      end: getDateStrFromFormat(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
    },
  });

  // API
  const [getSingleTasks] = useLazyQuery(TaskQueries.GET_TASKS_BY_IDS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getContactConsentByIds] = useLazyQuery(
    LeadQueries.GetConsentsByContactIds,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getContactsFromIds] = useLazyQuery(
    ContactsQueries.GET_CONTACTS_BY_CONTACT_IDS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  // Life cycle methods
  useEffect(() => {
    fetchData();
  }, [contactId]);

  // Other methods
  const onShowToast = (
    message: string,
    type: ToastType,
    additionalData?: any
  ) => {
    toast({
      message,
      duration: additionalData?.duration || 3000,
      toastType: type,
    });
  };
  const {handleTaskActions} = useTaskActionManager({showToast: onShowToast});

  const getQueryObject = (params: {
    offset: number;
    filterData: any;
    sortData: ITopBarData;
    currentTab?: string;
    formattedContactData?: any;
  }) => {
    const {offset, sortData, filterData, currentTab, formattedContactData} = params;
    const formattedContactDatas= formattedContactData || componentState.formattedContactData;
    const queryParams = getPatientTasksDefaultParams({
      topBarData: sortData,
      taskStatusMlovList: taskStatusMlovs,
      filters: filterData,
      personData: formattedContactDatas,
    });

    const currentselectedTab = currentTab
      ? currentTab
      : componentState.currentTab;
    return {
      ...queryParams,
      limit: pageSize,
      offset: offset,
      statusIdsNotIn:
        currentselectedTab === TaskTab.pending ? [completedStatusId] : undefined,
      statusIds:
        currentselectedTab === TaskTab.completed ? [completedStatusId] : undefined
    };
  };

  const fetchContactData = async () => {
    setContactDataLoading(true);
    try {
      const promiseList: Promise<any>[] = [];
      promiseList.push(
        getContactsFromIds({
          variables: {
            contactIds: [contactId],
          },
        })
      );

      const variables = {
        accountUuid: accountUuid,
        contactIds: {
          _in: [contactId],
        },
      };

      promiseList.push(
        getContactConsentByIds({
          variables: variables,
        })
      );

      const [contactDataResponse, consentResponseObj] = await Promise.all(
        promiseList
      );

      const consentData = consentResponseObj?.data?.contactConsents;

      const contactInfo = contactDataResponse?.data?.contacts?.[0];

      const formattedContactData = getFormDataFromLeadData(
        contactInfo,
        mlovData
      );
      let userHasLocationAccess = true;
      if(isMultiTenancyEnabled){
        const contactActiveProfiles = getPatientActiveProfileLocationIds(contactInfo?.contactProfiles);
        userHasLocationAccess = isResourceLocationActive(primaryLocationIds, contactActiveProfiles?.[0] || '');
      }
      setComponentState((prev) => ({
        ...prev,
        contactData: contactInfo,
        hasContactLocationAccess: userHasLocationAccess,
        consentData: consentData,
        formattedContactData: formattedContactData
      }));
      return formattedContactData;
    } catch (error) {
    } finally {
      setContactDataLoading(false);
    }
  };

  const fetchTasks = async (params: {
    offset: number;
    filterData: any;
    sortData: ITopBarData;
    currentTab?: string;
    formattedContactData?: any
  }) => {
    const queryParams = getQueryObject(params);
    const [response, taskAccessUsersData] = await Promise.all([
      client.query({
        query: TaskQueries.GET_CARE_DASHBOARD_TASKS,
        variables: cloneDeep(queryParams),
        fetchPolicy: 'no-cache',
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
      }),
      getLoggedInUserTaskAccessUsers()
    ]);
    const taskList = response?.data?.getTasks?.tasks || [];
    const totalTask = response?.data?.getTasks?.aggregate?.total || 0;
    const taskAccessUsers = taskAccessUsersData?.data || [];
    const updatedTaskList = taskList.map((task: ITask) => {
      task.hasTaskAccess = taskAccessUsers?.includes(task?.assigneeId) || false;
      task.isReadOnly = !task?.hasTaskAccess;
      return task;
    });
    console.log('updatedTaskList', updatedTaskList);
    setComponentState((prev) => {
      return {
        ...prev,
        taskList: params.offset === 0 ? updatedTaskList : [...prev.taskList, ...updatedTaskList],
        paginationLoading: false,
        fetchLoading: false,
        taskListLoading: false,
        totalTask: totalTask,
      };
    });
  };

  const [getAlertsDetail, {loading: alertDetailLoading}] = useLazyQuery(
    GET_TASK_ALERT_DETAIL,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      onCompleted: (response) => {
        const task = response?.getTasks?.tasks?.[0];
        setComponentState((prev) => {
          return {
            ...prev,
            showAddOrUpdateAlert: true,
            task: task
          };
        });
      },
    }
  );
  const fetchData = async () => {
    setComponentState((prev) => ({
      ...prev,
      fetchLoading: true,
    }));
    try {
      const formattedContactData = await fetchContactData();
      const promiseList: Promise<any>[] = [];
      promiseList.push(
        fetchTasks({
          offset: 0,
          filterData: filter,
          sortData: sortData,
          formattedContactData: formattedContactData
        })
      );

      promiseList.push(
        updateTaskCounts({formattedContactData: formattedContactData})
      );
      await Promise.all(promiseList);
    } catch (error) {
      onShowToast(intl.formatMessage({id: 'fetchDataError'}), ToastType.error);
    } finally {
      setComponentState((prev) => ({
        ...prev,
        fetchLoading: false,
      }));
    }
  };

  const updateTaskCounts = async (params: {
    filterData?: any;
    sortingData?: ITopBarData;
    formattedContactData?: any;
  }) => {
    const promiseList: Promise<any>[] = [];
    const {filterData, sortingData, formattedContactData} = params || {};
    const filterparam = filterData || filter;
    const sortingparam = sortingData || sortData;
    const formattedContactDataValue =
      formattedContactData || componentState.formattedContactData;
    const pendingParam = getQueryObject({
      offset: 0,
      filterData: filterparam,
      sortData: sortingparam,
      currentTab: TaskTab.pending,
      formattedContactData: formattedContactDataValue
    });
    promiseList.push(
      client.query({
        query: TaskQueries.GET_AGGREGATE_TASK_COUNT,
        variables: {
          params: pendingParam,
        },
        fetchPolicy: 'no-cache',
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
      })
    );
    const completedParam = getQueryObject({
      offset: 0,
      filterData: filterparam,
      sortData: sortingparam,
      currentTab: TaskTab.completed,
      formattedContactData: formattedContactDataValue
    });
    promiseList.push(
      client.query({
        query: TaskQueries.GET_AGGREGATE_TASK_COUNT,
        variables: {
          params: completedParam,
        },
        fetchPolicy: 'no-cache',
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
      })
    );

    const [pendingTaskData, completedTaskData] = await Promise.all(promiseList);

    const pendingTaskCount = pendingTaskData.data?.getTasks?.aggregate?.total;
    const completedTaskCount = completedTaskData.data?.getTasks?.aggregate?.total;
    setComponentState((prev: any) => {
      return {
        ...prev,
        pendingTaskCount: pendingTaskCount,
        completedTaskCount: completedTaskCount,
      };
    });
  };

  const handleEndReached = () => {
    if (
      componentState.paginationLoading ||
      componentState.totalTask < pageSize
    ) {
      return;
    }
    if (componentState.offset < componentState.totalTask) {
      setComponentState((prev) => {
        const newOffset = componentState.offset + pageSize;
        fetchTasks({
          offset: newOffset,
          filterData: filter,
          sortData: sortData,
        });
        return {
          ...prev,
          paginationLoading: true,
          offset: newOffset,
        };
      });
    }
  };

  const handleBottomViewActions = async (
    task: ITask,
    action: BottomViewAction
  ) => {
    setLoadingData((prev) => ({
      ...prev,
      taskIdWithAction: task?.id,
      loading: true,
    }));
    switch (action) {
      case BottomViewAction.markAsComplete:
        try {
          setLoadingData({
            taskIdWithAction: task?.id,
            loading: true,
          });
          const response = await handleTaskActions(task, action) as any;
          if(response && response?.success) {
            setComponentState((prev) => ({
              ...prev,
              taskList: prev.taskList.filter((t) => t.id !== task?.id),
              isSomeThingChanged: true,
              totalTask: prev.totalTask - 1,
              selectedTask: null,
            }));
            updateTaskCounts({});
            const eventBus = EventBus.getEventBusInstance();
            const code = `${EVENT_NAMES.REFRESH_TASK}_${contactId}`;
            eventBus.broadcastEvent(code, {});
          }
        } catch (error) {
          setLoadingData({
            taskIdWithAction: task?.id,
            loading: false,
          });
          onShowToast(
            intl.formatMessage({id: 'completeTaskError'}),
            ToastType.error
          );
        }
        break;

      case BottomViewAction.markAsIncomplete:
        try {
          setLoadingData({
            taskIdWithAction: task?.id,
            loading: true,
          });
          const response = await handleTaskActions(task, action) as any;
          if (response && response?.success) {
            setComponentState((prev) => ({
              ...prev,
              taskList: prev.taskList.filter((t) => t.id !== task?.id),
              isSomeThingChanged: true,
              totalTask: prev.totalTask - 1,
              selectedTask: null,
            }));
            updateTaskCounts({});
            const eventBus = EventBus.getEventBusInstance();
            const code = `${EVENT_NAMES.REFRESH_TASK}_${contactId}`;
            eventBus.broadcastEvent(code, {});
          }
        } catch (error) {
          setLoadingData({
            taskIdWithAction: task?.id,
            loading: false,
          });
          onShowToast(
            intl.formatMessage({id: 'incompleteTaskError'}),
            ToastType.error
          );
        }
        break;

        case BottomViewAction.markAsMissed:
          try {
            setLoadingData({
              taskIdWithAction: task?.id,
              loading: true,
            });
            const response = await handleTaskActions(task, action) as any;
            if (response && response?.success) {
              setComponentState((prev) => ({
                ...prev,
                taskList: prev.taskList.filter((t) => t.id !== task?.id),
                isSomeThingChanged: true,
                totalTask: prev.totalTask - 1,
                selectedTask: null,
              }));
              updateTaskCounts({});
              const eventBus = EventBus.getEventBusInstance();
              const code = `${EVENT_NAMES.REFRESH_TASK}_${contactId}`;
              eventBus.broadcastEvent(code, {});
            }
          } catch (error) {
            setLoadingData({
              taskIdWithAction: task?.id,
              loading: false,
            });
            onShowToast(
              intl.formatMessage({id: 'missedTaskError'}),
              ToastType.error
            );
          }
        break;

      case BottomViewAction.viewTask:
        setComponentState((prev) => ({
          ...prev,
          selectedTask: {
            ...task,
            isCompleted: task?.status?.code === TASK_STATUS_CODES.COMPLETED
          },
        }));
        break;

      default:
        break;
    }
    setLoadingData({
      taskIdWithAction: undefined,
      loading: false,
    });
  };

  const fetchUpdatedTask = async (uuid?: string) => {
    if (!uuid) {
      return;
    }
    const response = await getSingleTasks({
      variables: {
        ids: [uuid],
      },
    });
    const updatedTask = response?.data?.getTasks?.tasks?.[0];
    setComponentState((prev) => ({
      ...prev,
      taskList: prev.taskList.map((task) =>
        task?.id === updatedTask?.id ? updatedTask : task
      ),
    }));
  };

  const getNewTaskMember = () => {
    return {
      value: componentState.formattedContactData?.contactUUID,
      label: componentState.formattedContactData?.name,
      key: componentState.formattedContactData?.contactUUID,
      type:
        componentState.formattedContactData.contactType?.code ===
        PERSON_TYPES.LEAD
          ? ParticipantType.leads
          : ParticipantType.patient,
      contactData: componentState.formattedContactData?.contactData,
      data: componentState.formattedContactData?.contactData,
    };
  };

  const getNewTaskAssignee = () => {
    return {
      value: currentUserUUID,
      label: getUserFullName(),
      key: currentUserUUID,
      type: ParticipantType.staff,
      details: userData,
    };
  };

  const handleReturnFilterItems = (actionCode: string, rawData: any) => {
    let sortData: any = {};
    setSortData((prev) => {
      sortData = {
        ...prev,
        selectedSortType: rawData?.sortType,
        selectedSortUuid: rawData?.sortTypeUuid,
        selectedSortValueAscending: !prev.selectedSortValueAscending,
      };
      return sortData;
    });
    setShowSortPopover(!showSortPopover);
    setComponentState((prev) => {
      return {
        ...prev,
        taskListLoading: true,
        offset: 0,
      };
    });
    fetchTasks({
      offset: 0,
      filterData: filter,
      sortData: sortData,
    });
  };

  const handleTabClick = (code: string) => {
    if (!componentState.taskListLoading) {
      setComponentState((prev) => ({
        ...prev,
        currentTab: code,
        taskListLoading: true,
        offset: 0,
      }));
      fetchTasks({
        offset: 0,
        filterData: filter,
        sortData: sortData,
        currentTab: code,
      });
      updateTaskCounts({});
    }
  };

  const getInsightTabs = () => {
    const items: {
      code: string;
      label: string;
      tabContainsCount?: boolean;
      tabCountLoading?: boolean;
      tabCount?: number;
    }[] = [];
    items.push({
      code: ActionableTab.ALERTS,
      label: intl.formatMessage({id: 'alerts'}),
      tabContainsCount: true,
      tabCountLoading: false,
      tabCount: componentState.pendingAlertsCount || 0,
    });
    items.push({
      code: ActionableTab.TASKS,
      label: intl.formatMessage({id: 'tasks'}),
      tabContainsCount: true,
      tabCountLoading: false,
      tabCount: componentState?.pendingTaskCount || 0,
    });
    return items;
  };

  const setInitialTaskFilterData = (resetFilterOnly = false) => {
    const resetFilters:IFilter = {
      formData: {
        selectedDueDate: [allStatusValue],
        selectedDueDateUuid: [allStatusUuid],
      }
    }
    setFilter(resetFilters);
    setComponentState((prev) => {
      return {
        ...prev,
        filterCount: 0,
        taskListLoading: true,
        offset: 0
      };
    });

    if(!resetFilterOnly) {
      fetchTasks({
        offset: 0,
        filterData: resetFilters,
        sortData: sortData,
      });
      updateTaskCounts({filterData: resetFilters});
    }
  }

  const getTaskView = () => {
    return (
      <>

          <View mx={4}>
            <View mb={2}>
              <ClinicalSectionFilterTabs
                tabs={[
                  {
                    code: TaskTab.pending,
                    label: 'Pending',
                    showIconToRight: true,
                    icon: (
                      <>
                        <Badge
                          fontSize={12}
                          fontWeight={500}
                          _text={{
                            color: Colors.Custom.Gray700,
                          }}
                          borderWidth={1}
                          borderColor={Colors.Custom.Gray400}
                          alignSelf="center"
                          rounded="md"
                          justifyContent={'center'}
                          paddingX={2}
                          paddingY={1}
                          ml={1}
                        >
                          {componentState.pendingTaskCount}
                        </Badge>
                      </>
                    ),
                  },
                  {
                    code: TaskTab.completed,
                    label: 'Completed',
                    showIconToRight: true,
                    icon: (
                      <>
                        <Badge
                          fontSize={12}
                          fontWeight={500}
                          _text={{
                            color: Colors.Custom.Gray700,
                          }}
                          borderWidth={1}
                          borderColor={Colors.Custom.Gray400}
                          alignSelf="center"
                          rounded="md"
                          justifyContent={'center'}
                          paddingX={2}
                          paddingY={1}
                          ml={1}
                        >
                          {componentState.completedTaskCount}
                        </Badge>
                      </>
                    ),
                  },
                ]}
                selectedTabCode={componentState.currentTab}
                onTabClick={(code: string) => {
                  handleTabClick(code);
                }}
                useEvenlyDistributedTabs
              />
            </View>
            {!componentState.taskList?.length ? (
            <NoDataFound />
           ) : (
           <>
            <HStack
              space={2}
              ml={'10px'}
              flex={1}
              alignItems={'center'}
              mr={'10px'}
              justifyContent={'space-between'}
            >
              <Box
                alignItems={'center'}
                justifyContent={'space-evenly'}
                flexDir={'row'}
              >
                <Popover
                  overlayInnerStyle={{
                    bottom: 3,
                    borderRadius: 16,
                    padding: 0,
                  }}
                  content={
                    <SortByTaskSortType
                      selectedSortType={sortData?.selectedSortType}
                      returnFilterItems={(actionCode: string, rawData: any) => {
                        handleReturnFilterItems(actionCode, rawData);
                      }}
                      selectedSortValueAscending={
                        sortData?.selectedSortValueAscending
                      }
                    />
                  }
                  title=""
                  trigger="click"
                  open={showSortPopover}
                  onOpenChange={(status: boolean) =>
                    setShowSortPopover(status)
                  }
                  placement={'bottom'}
                >
                  <Tooltip title="Sort">
                    <Pressable
                      onPress={() => {
                        setShowSortPopover(!showSortPopover);
                      }}
                    >
                      <Icon
                        color={Colors.Custom.PurpleColor}
                        as={
                          <SortTaskIcon
                            color={
                              sortData?.selectedSortType !== 'None'
                                ? Colors.Custom.PurpleColor
                                : ''
                            }
                          />
                        }
                        name={'sort'}
                        size="4"
                        onPress={() => setShowSortPopover(!showSortPopover)}
                      />
                    </Pressable>
                  </Tooltip>
                </Popover>
                <Text alignItems={'center'} flexDirection="row">
                  <Text ml={1}>
                    {intl.formatMessage({id: 'sortBywithcolon'})}
                  </Text>
                  <Text fontWeight={'bold'} pl={1}>
                    {intl.formatMessage({id: sortData.selectedSortType})}{' '}
                    {sortData.selectedSortType !== 'None' && (
                      <>
                        ({intl.formatMessage({
                          id: sortData.selectedSortValueAscending
                            ? 'ascending'
                            : 'descending',
                        })})
                      </>
                    )}
                  </Text>
                </Text>
              </Box>
            </HStack>
            <View my={2} />
            <View marginTop={1}>
              <WriteInfoBackBanner
                message={intl.formatMessage({
                  id: 'pendingTaskMsg',
                })}
              />
            </View>
            {componentState.taskListLoading ? (
              <View width={'100%'}>
                <Skeleton my={2} />
                <Skeleton my={2} />
                <Skeleton my={2} />
                <Skeleton my={2} />
              </View>
            ) : (
              <>
                <FlatList
                  data={componentState.taskList}
                  keyExtractor={(item) => item?.id}
                  ListEmptyComponent={
                    <VStack
                      justifyContent={'center'}
                      height={height - 310}
                      alignItems={'center'}
                      padding={5}
                    >
                      <NoTaskSvg width={150} height={150} />
                      <Text
                        ml={2}
                        mt={4}
                        color={Colors.Custom.Gray500}
                        fontWeight={'bold'}
                      >
                        {intl.formatMessage({id: 'noTaskFound'})}
                      </Text>
                    </VStack>
                  }
                  height={height}
                  renderItem={({item, index}) => {
                    const task = item;
                    return (
                      <Pressable
                        _hover={{
                          bg: Colors.FoldPixel.GRAY50,
                          borderRadius: 4,
                        }}
                        onPress={() =>
                          handleBottomViewActions(task, BottomViewAction.viewTask)
                        }
                        px={0.5}
                        flex={1}
                      >
                        <TaskListItemV2
                          isLoading={loadingData.taskIdWithAction === task?.id}
                          task={task}
                          onAction={handleBottomViewActions}
                          getUserName={(uuid) =>
                            accountUserMap?.[uuid]?.name || ''
                          }
                          isMultiTenancyEnabled={isMultiTenancyEnabled}
                        />
                      </Pressable>
                    );
                  }}
                  onEndReached={(data) => {
                    if (data?.distanceFromEnd < 70) {
                      handleEndReached();
                    }
                  }}
                  onEndReachedThreshold={0.1}
                  ListFooterComponent={
                    componentState.paginationLoading ? (
                      <Spinner size={'lg'} my={2} />
                    ) : (
                      <></>
                    )
                  }
                  maxHeight={'100%'}
                />
              </>
            )}
            </>)}
          </View>
        {componentState.selectedTask && (
          <AddOrUpdateTask
            task={componentState.selectedTask}
            isVisible
            onMarkAsComplete={handleBottomViewActions}
            onComplete={() => {
              fetchUpdatedTask(componentState?.selectedTask?.id);
              setComponentState((prev) => ({
                ...prev,
                selectedTask: null,
              }));
              updateTaskCounts({});
            }}
            onCancel={() => {
              setComponentState((prev) => ({
                ...prev,
                selectedTask: null,
              }));
            }}
          />
        )}
        {componentState.isShowAddTaskDrawer && (
          <AddOrUpdateTask
            member={getNewTaskMember() as any}
            assignee={getNewTaskAssignee() as any}
            isVisible={componentState.isShowAddTaskDrawer}
            onComplete={(data) => {
              setComponentState((prev) => ({
                ...prev,
                isShowAddTaskDrawer: false,
                taskListLoading: true,
                offset: 0,
              }));
              fetchTasks({
                offset: 0,
                filterData: filter,
                sortData: sortData,
              });
              const eventBus = EventBus.getEventBusInstance();
              const code = `${EVENT_NAMES}_${contactId}`;
              eventBus.broadcastEvent(code, {});
              updateTaskCounts({});
            }}
            onCancel={() => {
              setComponentState((prev) => ({
                ...prev,
                isShowAddTaskDrawer: false,
              }));
            }}
            restrictPatientOrLeadSelectionTo={getNewTaskMember() as any}
            onNoteRedirect={() => {
              setComponentState((prev) => ({
                ...prev,
                isShowAddTaskDrawer: false,
              }));
            }}
          />
        )}
        {isShowFilterDrawer && (
          <FilterDrawer
            isShowFilterDrawer={isShowFilterDrawer}
            handleShowFilterDrawer={(isClearAll = false) => {
              setIsShowFilterDrawer(false);
              if (isClearAll) {
                setInitialTaskFilterData();
              }
            }}
            handleSubmit={(filterData: any, totalCount: number | null) => {
              setFilter(filterData);
              setComponentState((prev) => ({
                ...prev,
                filterCount: totalCount,
                taskListLoading: true,
                offset: 0
              }));
              fetchTasks({
                offset: 0,
                filterData: filterData,
                sortData: sortData,
              });
              setIsShowFilterDrawer(false);
              updateTaskCounts({filterData: filterData});
            }}
            propertyDetails={propertyDetails}
            filterData={cloneDeep(filter)}
            showTaskStatus={false}
            showTaskEntityTypes={false}
          />
        )}
      </>
    )
  }
  const openCareGapDetail = (alert: ITaskAlert) => {
    if (alert?.referenceData?.executionId || !showManualAddGaps) {
      setComponentState(prev=>{
        return {
          ...prev,
          selectedCareGapDetail: alert
        }
      })
    } else  {
      editCareGapAlert(alert, TASK_TYPES.CARE_GAPS)
    }
  };

  const getAlertView = () => {
    return (
      <>
        <View
          style={{
            backgroundColor: Colors.FoldPixel.GRAY50,
            paddingHorizontal: 12,
            paddingVertical: 16,
          }}
        >
          <AlertsView
            onAlertAdd={onAlertAdd}
            toShowOnlyDiagnosisGaps={props?.toShowOnlyDiagnosisGaps}

            openCareGapDetail={openCareGapDetail}
            openDiagnosisGapDetail={(diagnosisGap)=> {
              setComponentState(prev=>{
                return {
                  ...prev,
                  selectedDiagnosisGapDetail: diagnosisGap
                }
              })
            }}
            contactId={contactId}
            contactData={componentState?.contactData}
            showFiltersTabInAlerts={componentState.showFiltersTabInAlerts || false}
            onFilterApplied={(filterCount: number, isResetFilter?: boolean) => {
              setComponentState((prev) => ({
                ...prev,
                alertFilterCount: filterCount,
              }))
            }}
            searchText={componentState.alertSearchText || ''}
            onPendingCountChange={(count) => {
              setComponentState((prev) => ({
                ...prev,
                pendingAlertsCount: count,
              }));
            }}
            isActionableDrawer={true}
            formattedContactData={componentState.formattedContactData}
          />
        </View>
      </>
    )
  }

  const getFilterCountBadge = () => {
    let filterCount = 0;
    if(componentState.selectedTab === ActionableTab.ALERTS) {
      filterCount = componentState.alertFilterCount || 0;
    } else if(componentState.selectedTab === ActionableTab.TASKS) {
      filterCount = componentState.filterCount || 0;
    }
    return (
      filterCount > 0 && (
        <>
          {
            <Badge
              mb={-2}
              position={'absolute'}
              top={-6}
              right={-12}
              paddingLeft={1}
              paddingRight={1}
              paddingTop={0}
              paddingBottom={0}
              mt={filterCount > 0 ? -2 : 0}
              zIndex={100}
              variant="solid"
              backgroundColor={
                Colors.Custom?.badgeColorOrange
              }
              alignSelf="flex-end"
              size={'smMedium'}
              style={{
                padding: 0,
              }}
              _text={{
                fontSize: 9,
                fontWeight: 'bold',
              }}
            >
              {filterCount}
            </Badge>
          }
        </>
      )
    )
  }
  const onAlertAdd = (type: string) => {
    setComponentState((prev) => {
      return {
        ...prev,
        showAddOrUpdateAlert: true,
        alertType: type
      };
    });
  }
  const editCareGapAlert = async (alert: any, taskType?: string) => {
    await getAlertsDetail({
      variables: {
        params: {
          ids: [alert?.id],
          includeAlerts: true
        }
      }
    })
    setComponentState((prev) => {
      return {
        ...prev,
        alertType: taskType === TASK_TYPES.CARE_GAPS ? TASK_TYPES.CARE_GAPS : TASK_TYPES.DIAGNOSIS_GAPS
      };
    });
  }
  const addOrUpdateAlert = () => {
    return <AddOrUpdateAlert 
    alertType={componentState.alertType} 
    formattedContactData={componentState.formattedContactData}
    task={componentState?.task}
    onBackClick={() => {
      setComponentState((prev) => {
        return {
          ...prev,
          showAddOrUpdateAlert: false,
          task: {} as ITask
        };
      });
    }} />
  }
  const careGapDetailView = () => {
    return (
      <CareGapDetailView
        editCareGapAlert={(alert: any) => editCareGapAlert(alert, TASK_TYPES.CARE_GAPS)}
        contactUuid={componentState.contactData?.uuid || componentState.formattedContactData.uuid || ''}
        onBackClick={() => {
          setComponentState((prev) => {
            return {
              ...prev,
              selectedCareGapDetail: {} as ITaskAlert,
            };
          });
        }}
        careGapDetail={componentState.selectedCareGapDetail || {} as ITaskAlert}
      />
    );
  };

  const diagnosisGapDetailView = () => {
    return (
      <DiagnosisGapDetailView
        contactData={componentState.contactData}
        onClose={() => {
          setComponentState((prev) => {
            return {
              ...prev,
              selectedDiagnosisGapDetail: {} as IDiagnosisGap,
            };
          });
        }}
        diagnosisGapDetail={componentState.selectedDiagnosisGapDetail || {} as IDiagnosisGap}
      />
    )
  }

  return (
    <Drawer
      destroyOnClose
      placement="right"
      className="custom-drawer-styles alert-task-drawer"
      open={true}
      closable
      width={'40%'}
      onClose={() => {
        onClose(true);
      }}
      title={
        <>
          <ModalActionTitle
            title={props?.toShowOnlyDiagnosisGaps ? 'Diagnosis Gaps' : 'Pending Actions'}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  if (
                    componentState.isSomeThingChanged ||
                    !!componentState.selectedTask
                  ) {
                    const eventBus = EventBus.getEventBusInstance();
                    const code = `${EVENT_NAMES.REFRESH_TASK}_${contactId}`;
                    eventBus.broadcastEvent(code, {});
                  }
                  onClose(false);
                },
              },
              {
                show: componentState?.hasContactLocationAccess && componentState.selectedTab === ActionableTab.TASKS,
                id: 3,
                btnText: 'addTask',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    isShowAddTaskDrawer: true,
                  }));
                },
              },
            ]}
          />
        </>
      }
    >
      {componentState.fetchLoading || accountUserLoading ? (
        <View mx={4}>
          <Skeleton my={2} />
          <Skeleton my={2} />
          <Skeleton my={2} />
          <Skeleton my={2} />
        </View>
      ) : (
        <View mt={1}>
          {contactDataLoading ? (
            <Skeleton w="100%" />
          ) : (
            <>
              {!props?.toShowOnlyDiagnosisGaps && <View mx={3} mt={2} mb={4}>
                <InfoSection
                  contactData={componentState.contactData}
                  infoItems={[]}
                  actionView={
                    <View>
                      <ContactRelationCommunication
                        handleActions={() => null}
                        contact={
                          {
                            ...componentState.contactData,
                            contactConsents: componentState.consentData,
                          } as IContact
                        }
                      />
                    </View>
                  }
                />
              </View>
              }
              <Divider />
            { componentState?.showAddOrUpdateAlert ? addOrUpdateAlert() : componentState.selectedCareGapDetail?.id ? careGapDetailView()
              : componentState.selectedDiagnosisGapDetail?.id ? diagnosisGapDetailView() :
              componentState.showSearchBar ? (
                <View>
                  <Stack
                    direction={'row'}
                    style={{
                      alignItems: 'center',
                      marginVertical: 4,
                      marginHorizontal: 12,
                    }}
                  >
                    <SearchBar
                      width={'100%'}
                      onChange={debounce((value: string) => {
                        setComponentState((prev) => {
                          return {
                            ...prev,
                            alertSearchText: value,
                          };
                        });
                      }, 500)}
                      rightElement={
                        <Feather
                          onPress={() =>
                            setComponentState((prev) => ({
                              ...prev,
                              showSearchBar: false,
                              alertSearchText: ''
                            }))
                          }
                          style={{
                            marginRight: 16,
                          }}
                          name="x"
                          size={15}
                          color={Colors.Custom.Gray600}
                        />
                      }
                    />
                  </Stack>
                </View>
              ) : (
                !props?.toShowOnlyDiagnosisGaps && getInsightTabs()?.length > 1 && (
                  <View>
                    <Stack
                      direction={'row'}
                      style={{
                        alignItems: 'center',
                        marginVertical: 4,
                        marginHorizontal: 12,
                      }}
                    >
                      {componentState.selectedTab === ActionableTab.ALERTS && false && (
                        <>
                          <Pressable
                          key={'searchBar'}
                            onPress={() => {
                              setComponentState((prev) => ({
                                ...prev,
                                showSearchBar: true,
                              }));
                            }}
                          >
                            <SearchIconSvg
                              customStrokeColor={Colors.FoldPixel.GRAY300}
                              strokeWidth={1}
                            />
                          </Pressable>
                          <View
                          key={'dividerForSearchBar'}
                            style={{
                              width: 1,
                              height: 20,
                              backgroundColor: Colors.Custom.Gray200,
                              marginHorizontal: 12,
                            }}
                          />
                        </>
                      )}
                      <ClinicalSectionFilterTabs
                        useNewDesign
                        tabs={getInsightTabs()}
                        selectedTabCode={componentState.selectedTab}
                        onTabClick={(code: string) => {
                          if (code === componentState.selectedTab) {
                            return;
                          }
                          if (code === ActionableTab.TASKS) {
                            fetchTasks({
                              offset: 0,
                              filterData: {
                                formData: {
                                  selectedDueDate: [allStatusValue],
                                  selectedDueDateUuid: [allStatusUuid],
                                }
                              },
                              sortData: sortData,
                            });
                          }
                          setInitialTaskFilterData(true);
                          setComponentState((prev) => {
                            return {
                              ...prev,
                              selectedTab: code,
                              alertsFilterCount: 0,
                              alertSearchText: '',
                              showFiltersTabInAlerts: false,
                            };
                          });
                        }}
                      />
                      <View style={{flex: 1}} />
                     {componentState.selectedTab === ActionableTab.TASKS &&  <HStack>
                        <Pressable
                          onPress={() => {
                            if(componentState.selectedTab === ActionableTab.TASKS) {
                              setIsShowFilterDrawer(true);
                            } else {
                              setComponentState((prev) => {
                                return {
                                  ...prev,
                                  showFiltersTabInAlerts: !componentState.showFiltersTabInAlerts,
                                };
                              });
                            }
                          }}
                        >
                          {
                            <Stack
                              direction={'column'}
                              style={{position: 'relative'}}
                            >
                              {getFilterCountBadge()}
                              <FilterIconSvgForAlerts />
                            </Stack>
                          }
                        </Pressable>
                      </HStack>}
                    </Stack>
                  </View>
                )
              )
              }
              {componentState.showFiltersTabInAlerts && <Divider mb={2} />}
            </>
          )}
        </View>
      )}
      {!accountUserLoading && !componentState.selectedCareGapDetail?.id && !componentState.selectedDiagnosisGapDetail?.id && componentState.selectedTab === ActionableTab.ALERTS && !componentState.showAddOrUpdateAlert && getAlertView()}
      {!accountUserLoading && !componentState.selectedCareGapDetail?.id && !componentState.selectedDiagnosisGapDetail?.id&& componentState.selectedTab === ActionableTab.TASKS && getTaskView()}
    </Drawer>
  );
};

export default InternalTaskDrawer;
