import { Drawer, Progress } from 'antd';
import { Center, Pressable, Skeleton, Text, VStack, View } from 'native-base';
import { useContext, useEffect, useState} from 'react';
import { BUTTON_TYPE, EMPLOYER_REPORT_FILTER_CODES, MLOV_CATEGORY } from '../../../../constants';
import { Colors } from '../../../../styles/Colors';
import { ModalActionTitle } from '../../../common/ModalActionTitle/ModalActionTitle';
import {
  getCareJourneyColor,
  getCareJourneyProgress,
  getCareJourneyStatus,
} from '../JourneysOfCare/PatientCareJourney/PatientCareJourneyHelper';

import { useLazyQuery } from '@apollo/client';
import { Dimensions, ViewStyle } from 'react-native';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { EmployerDashboard } from '../../../../services';
import { getSectionDataByResourceCode } from '../../../../services/Analytics';
import { getDateStrFromFormat } from '../../../../utils/DateUtils';
import {
  getMlovListByCategory,
  getMlovListFromCategory,
} from '../../../../utils/mlovUtils';
import { FilterWrapperComponent } from '../../../common/CareDashboard/CareDashboardTopBar/FilterWrapperComponent';
import {
  IPageLayout,
  IPageSection,
  ISectionDisplayConfig,
} from '../Analytics/types';
import { IPatientCareJourney } from '../JourneysOfCare/PatientCareJourney/PatientJourneyInterface';
import JourneyProgressAdherence from './JourneyProgressAdherence';
import JourneyProgressGoals from './JourneyProgressGoals';
import JourneyProgressOverall from './JourneyProgressOverall';
import { styles } from './style';
import VitalsAndLabTracking from './VitalsAndLabTracking';
import {isAccountConfigEnabled} from '../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../constants/AccountConfigConst';

enum RESOURCE_LIST {
  CARE_JOURNEY_OVERALL_ADHERENCE_BY_ENTITY_TYPE = 'CARE_JOURNEY_OVERALL_ADHERENCE_BY_ENTITY_TYPE',
  CARE_JOURNEY_GOALS = 'CARE_JOURNEY_GOALS',
  CARE_JOURNEY_VITAL_TRACKING = 'CARE_JOURNEY_VITAL_TRACKING',
  CARE_JOURNEY_OVERALL_ADHERENCE = 'CARE_JOURNEY_OVERALL_ADHERENCE',
}



const JourneyProgressReport = (props: {
  onCancel: () => void;
  isVisible: boolean;
  title: string;
  record: any;
  journeyId?: string;
  locationGroupIds?: string[];
  locationIds?: string[];
}) => {
  const [GetDefaultPage] = useLazyQuery(
    EmployerDashboard.GetDefaultPageForJourneyAnalytics,
    {
      variables: {
        code: 'CARE_JOURNEY_MEMBER_DEFAULT_DASHBOARD',
      },
    }
  );
  const sectionTypes = getMlovListByCategory(MLOV_CATEGORY.SectionType);
  const mlovData = useContext(CommonDataContext);
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const patientJourneyStatusList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PATIENT_CARE_JOURNEY_STATUS
    ) || [];
  const [journeyList, setJourneyList] = useState<IPatientCareJourney[]>([]);
  const [journeyText, setJourneyText] = useState<string>('');
  const [journey, setJourney] = useState<IPatientCareJourney>({} as IPatientCareJourney);

  const [resourceData, setResourceData] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);

  const {height, width} = Dimensions.get('window');
  const [pages, setPages] = useState<IPageLayout[]>([]);
  const [resourceCodeList, setResourceCodeList] = useState<string[]>([]);

  const {record} = props;

  const fetchResourceCode = (
    pageSections: IPageSection[],
    resourceObj: string[]
  ): string[] => {
    pageSections?.forEach((pageSection: IPageSection) => {
      const sectionType = sectionTypes.find(
        (type) => type.id == pageSection.type
      );

      if (sectionType?.code == 'HEADER' && pageSection?.childSections?.length) {
        fetchResourceCode(pageSection.childSections, resourceObj);
      } else {
        if (pageSection?.resource?.code) {
          resourceObj.push(pageSection?.resource?.code);
        }
      }
    });
    return resourceObj;
  };

  useEffect(() => {
    setLoading(true);

    if (props?.record && props?.journeyId) {
      getContactsJourneyAssignedList(props.record, props.journeyId);
    }

    GetDefaultPage({
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }).then((resp) => {
      if (resp?.data?.pages?.[0]) {
        setPages(resp?.data?.pages)
        const resourceCodeListRaw = fetchResourceCode(
          resp?.data?.pages?.[0]?.pageSections as IPageSection[],
          []
        );

        setResourceCodeList(resourceCodeListRaw);
        fetchDateByResourceCode(resourceCodeListRaw);
      }
    });
  }, []);

  useEffect(() => {
    fetchDateByResourceCode(resourceCodeList);
  }, [JSON.stringify(journey)]);

  const fetchDateByResourceCode = async (resourceList: string[]) => {
    if (!resourceList.length) {
      return;
    }

    !loading && setLoading(true);
    const resourceDataToReturn: Record<string, any> = {};
    for (let resourceId = 0; resourceId < resourceList.length; resourceId++) {
      const resp = await getSectionDataByResourceCode(
        resourceList[resourceId],
        {
          resourceCode: resourceList[resourceId],
          isAccountDashboard: false,
          appliedFilter: [
            {
              filterCode: 'PATIENT_CARE_JOURNEY_ID',
              filterValue: {
                value: journey?.id || record.id,
              },
            },
            {
              filterCode: 'CARE_JOURNEY_ID',
              filterValue: {
                value: record.careJourneyId,
              },
            },
            ...(isMsoEnabled ? [{
              filterCode: EMPLOYER_REPORT_FILTER_CODES.LOCATION_GROUP_IDS,
              filterValue: {
                value: props.locationGroupIds,
              },
            },{
              filterCode: EMPLOYER_REPORT_FILTER_CODES.LOCATION_IDS,
              filterValue: {
                value: props.locationIds,
              },
            }] : []),
          ],
        }
      );
      resourceDataToReturn[resourceList[resourceId]] = resp.data;
      setResourceData({...resourceDataToReturn});
    }
    setLoading(false);
  };

  const getContactsJourneyAssignedList = async (record: any, journeyId: any) => {
    const contactId = record?.contactId;

    const resp = await getSectionDataByResourceCode(
      'CARE_JOURNEY_TOTAL_ASSIGNED_JOURNEY_OF_CONTACT',
      {
        resourceCode: 'CARE_JOURNEY_TOTAL_ASSIGNED_JOURNEY_OF_CONTACT',
        isAccountDashboard: false,
        appliedFilter: [
          {
            filterCode: 'CARE_JOURNEY_ID',
            filterValue: {
              value: journeyId,
            },
          },
          {
            filterCode: 'CONTACT_ID',
            filterValue: {
              value: contactId,
            },
          },
          ...(isMsoEnabled ? [{
            filterCode: EMPLOYER_REPORT_FILTER_CODES.LOCATION_GROUP_IDS,
            filterValue: {
              value: props.locationGroupIds,
            },
          },{
            filterCode: EMPLOYER_REPORT_FILTER_CODES.LOCATION_IDS,
            filterValue: {
              value: props.locationIds,
            },
          }] : []),
        ],
      },
      'drill-down-data'
    );

    if (resp?.data?.result?.patientCareJourneys?.length) {
      // select journey which is clicked from table
      const selectedRecord: IPatientCareJourney =
        resp?.data?.result?.patientCareJourneys.find(
          (patientCareJourney: IPatientCareJourney) =>
            patientCareJourney.id === props?.record?.id
        );
      const newSortedJourneyList: IPatientCareJourney[] = [];

      newSortedJourneyList.push(
        selectedRecord,
        ...resp?.data?.result?.patientCareJourneys.filter(
          (item: IPatientCareJourney) => item.id !== selectedRecord.id
        )
      );

      const journeyText = `Journey ${1} (${getDateStrFromFormat(
        selectedRecord.startDateTime,
        'DD MMM yy'
      )} - ${getDateStrFromFormat(selectedRecord.completedDateTime || selectedRecord.endDateTime, 'DD MMM yy')})`;
      setJourneyList(newSortedJourneyList);
      setJourneyText(journeyText);
      setJourney(selectedRecord);
    }
  };

  const computeStyles = (sectionDisplayConfig?: ISectionDisplayConfig) => {
    const styles = [];
    if (sectionDisplayConfig?.childSections) {
      styles.push({
        ...sectionDisplayConfig?.childSections,
      });
    }
    if (sectionDisplayConfig?.section) {
      styles.push({
        ...sectionDisplayConfig?.section,
      });
    }

    return styles;
  };

  const renderWidgets = (pageSections: IPageSection) => {
    if (
      loading ||
      !pageSections?.resource?.code ||
      !resourceData[pageSections.resource.code]
    ) {
      return (
        <Center margin={4} flex={1}>
          <Skeleton.Text lines={5} />
        </Center>
      );
    }
    switch (pageSections?.resource?.code) {
      case RESOURCE_LIST.CARE_JOURNEY_OVERALL_ADHERENCE:
        const monthlyOverallAdherence =
          resourceData[
            pageSections.resource.code
          ]?.result?.overallAdherencePercentage?.toFixed(2) || 0;
        return <JourneyProgressOverall value={monthlyOverallAdherence} />;
      case RESOURCE_LIST.CARE_JOURNEY_GOALS:
        return <JourneyProgressGoals patientCareJourneyId={journey.id} />;
      case RESOURCE_LIST.CARE_JOURNEY_OVERALL_ADHERENCE_BY_ENTITY_TYPE:
        const dataByEntityType =
          resourceData[pageSections.resource.code]?.result
            ?.overallAdherenceByTaskEntityType || [];
        return (
          <VStack>
          <JourneyProgressAdherence
            record={journey}
            dataByEntityType={dataByEntityType}
          />
          </VStack>
        );
      case RESOURCE_LIST.CARE_JOURNEY_VITAL_TRACKING:
        const trackingResourceList =
          resourceData[pageSections.resource.code]?.result?.vitalTrackingResourceList || [];
        const patientId = resourceData[pageSections.resource.code]?.result?.patientCareJourneyInfo?.[0]?.patientId;
        const startDate = resourceData[pageSections.resource.code]?.result?.patientCareJourneyInfo?.[0]?.startDateTime?.value;
        const endDate = resourceData[pageSections.resource.code]?.result?.patientCareJourneyInfo?.[0]?.endDateTime?.value;
        return (<VitalsAndLabTracking startDate={startDate} endDate={endDate} trackingResourceList={trackingResourceList} patientId={patientId}></VitalsAndLabTracking>);
    }
  };

  const renderHeader = (heading?: string, subHeading?: string) => {
    if (heading || subHeading) {
      return (
        <View style={styles.headerContainer}>
          <View style={styles.headerRightBox}>
            <View>
              {heading ? (
                <Text
                  style={{
                    marginRight: 6,
                  }}
                  size={'smBold'}
                  fontSize={18}
                >
                  {heading}
                </Text>
              ) : null}
              {subHeading ? (
                <Text
                  style={{
                    marginRight: 6,
                  }}
                  size={'smBold'}
                  fontSize={18}
                >
                  {subHeading}
                </Text>
              ) : null}
            </View>
          </View>
        </View>
      );
    }
  };

  const renderSections = (pageSections: IPageSection[]) => {
    return (
      <>
        {pageSections?.map((pageSection: IPageSection, index: number) => {
          if (!pageSection?.isVisible) {
            return null;
          }

          const sectionType = sectionTypes.find(
            (type) => type.id == pageSection.type
          );

          if (sectionType?.code == 'HEADER') {
            return (
              <>
                {renderHeader(pageSection?.heading, pageSection?.subHeading)}
                <View
                  style={
                    computeStyles(pageSection.sectionDisplayConfig) as ViewStyle
                  }
                >
                  {pageSection.childSections
                    ? renderSections(pageSection.childSections)
                    : null}
                </View>
              </>
            );
          } else {
            return (
              <View
                padding={6}
                borderWidth={1}
                borderRadius={4}
                borderColor={Colors.Custom.Gray200}
                backgroundColor={Colors.Custom.BackgroundColor}
                margin={3}
                style={
                  computeStyles(pageSection.sectionDisplayConfig) as ViewStyle
                }
              >
                {renderWidgets(pageSection)}
              </View>
            );
          }
        })}
      </>
    );
  };

  return (
    <Drawer
      destroyOnClose
      placement="right"
      onClose={() => {
        props.onCancel();
      }}
      visible={props.isVisible}
      closable={false}
      width={'50%'}
      title={
        <ModalActionTitle
          title={props?.title}
          titleColor={'#000'}
          subtitleBlock={
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'flex-start',
                width: 200,
                marginTop: 6,
              }}
            >
              <View flexDirection={'column'} flex={1}>
                <Progress
                  percent={parseFloat(
                    getCareJourneyProgress(journey)?.toFixed(2)
                  )}
                  strokeColor={getCareJourneyColor(journey?.statusCode)}
                  strokeWidth={4}
                  showInfo={false}
                  style={{marginRight: 12, color: Colors.primary['400']}}
                />
                <Text
                  style={{
                    color: Colors.Custom.Gray400,
                    fontSize: 14,
                  }}
                >
                  {getCareJourneyStatus(journey?.statusCode, patientJourneyStatusList)}
                </Text>
              </View>
              <Text
                style={{
                  color: Colors.Custom.Gray900,
                  fontSize: 14,
                  marginLeft: 8,
                }}
              >
                {getCareJourneyProgress(record) > 100
                  ? 100
                  : parseFloat(getCareJourneyProgress(record)?.toFixed(2))}{' '}
                %
              </Text>
            </View>
          }
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'close',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                props.onCancel();
              },
            },
          ]}
        />
      }
      bodyStyle={{
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {journeyList?.length ? (
          <FilterWrapperComponent
            textWeight={400}
            popoverMaxHeight={400}
            value={[journeyText]}
            minWidthDropdown={250}
            maxWidth={240}
            dropdownMarginLeft={22}
          >
            {journeyList.map((item, index) => {
              const journeyText = `Journey ${index + 1} (${getDateStrFromFormat(
                item.startDateTime,
                'DD MMM yy'
              )} - ${getDateStrFromFormat(item.completedDateTime || item.endDateTime, 'DD MMM yy')})`;
              return (
                <Pressable
                  onPress={() => {
                    setJourneyText(journeyText);
                    setJourney(item);
                  }}
                  style={{
                    width: 250,
                    paddingVertical: 12,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderTopColor: Colors.Custom.Gray200,
                    borderTopWidth: !index ? 0 : 1,
                  }}
                >
                  <Text
                    fontWeight={item.id === journey.id ? 'bold' : 'medium'}
                    color={Colors.Custom.Gray500}
                    size={'smMedium'}
                    fontSize={14}
                  >
                    {journeyText}
                  </Text>
                </Pressable>
              );
            })}
          </FilterWrapperComponent>
        ) : null}
        {/* <FilterWrapperComponent
                    onClose={() => {
                    }}
                    textWeight={400}
                    popoverMaxHeight={400}
                    value={taskType ? [taskType.value] : ["All Task Types"]}
                    minWidthDropdown={250}
                    maxWidth={240}
                >
                    {taskEntityList.map((item, index) => {
                        return (<Pressable onPress={() => {
                            setTaskType(item);
                        }} style={{
                            width: 250,
                            paddingVertical: 12,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderTopColor: Colors.Custom.Gray200,
                            borderTopWidth: !index ? 0 : 1
                        }}>
                            <Checkbox isChecked={item.code == taskType?.code} value='' >
                                <View style={{
                                    marginLeft: 6
                                }} >
                                    <Text color={Colors.Custom.Gray500} size={'smMedium'} fontSize={14}>
                                        {item.value}
                                    </Text>
                                </View>
                            </Checkbox>
                        </Pressable>)
                    })}
                </FilterWrapperComponent> */}
      </View>
      {pages?.length ? (
        <View
          style={{
            overflow: 'scroll',
            backgroundColor: Colors.Custom.ContainerBGColor,
          }}
        >
          {renderSections(pages?.[0]?.pageSections as IPageSection[])}
        </View>
      ) : (
        <Skeleton.Text lines={15} />
      )}
    </Drawer>
  );
};





export default JourneyProgressReport;
