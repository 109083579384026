import {useMutation, useQuery} from '@apollo/client';
import {Skeleton, Table} from 'antd';
import {Box, useDisclose, useToast, View} from 'native-base';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE, MLOV_CATEGORY} from '../../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {MLOV_CODES, USER_ROLE_CODES} from '../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {IUser} from '../../../../Interfaces';
import {ScheduleEventQueries, UserQueries} from '../../../../services';
import {Colors} from '../../../../styles';
import {testID, TestIdentifiers} from '../../../../testUtils';
import {getAccountUUID, getBooleanFeatureFlag, isAppointmentTypesGroupEnable} from '../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../utils/mlovUtils';
import FHAlertDialog from '../../../common/FHAlertDialog/FHAlertDialog';
import { getEhrConfig } from '../../../PersonOmniView/MiddleContainer/CareTimeline/CareTimelineUtils';
import {getColumns, getUserIds, setUserData} from './AppointmentTypesHelper';
import AppointmentTypeTopBar, {
  AppointmentTypeAction,
} from './AppointmentTypeTopBar';
import {IAppointmentType, IAppointmentTypeAvailabilityApiResponse, IAppointmentTypesInterface} from './Interfaces';
import AddOrUpdateAppointmentTypes from './AddOrUpdateAppointmentTypes';
import { TABLE_TOP_BAR_ACTION_CODES } from '../../../common/TableTopBar';
import { formatAppointmentTypes } from '../../../common/CalendarWidget/BookingWorkflows/BookAppointment/BookAppointmentHelper';
import useLocationGroup from '../../../common/MemebersView/customHook/useLocationGroup';
import useAddRoleAndPermission from '../../UserAccess/CustomHook/useCreateRoleAndPermissions';
import {IAccountRoleResp} from '../../Contacts/TeamMembers/AssociatedAccountView/interface';
import {processRoles} from '../../Contacts/TeamMembers/AssociatedAccountView/utils';
import {IUserRoleMlov} from '../../Contacts/TeamMembers/AddEditUser/interfaces';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';

interface IScheduleEventAPIData {
  appointmentTypes: IAppointmentType[];
  aggregateAppointmentTypes: {
    aggregate: {
      count: number;
    };
  };
}

const AppointmentTypesList = (props: IAppointmentTypesInterface) => {
  const intl = useIntl();
  const toast = useToast();
  const {
    locationGroups,
    loading: locationGroupLoading,
    error: locationGroupError,
  } = useLocationGroup();
  const [action, setAction] = useState(AppointmentTypeAction.singleAppointment);
  const timeOutRefs = useRef<NodeJS.Timeout[]>([])
  const mlovData = useContext(CommonDataContext);
  const isGroupAppointmentEnabled = isAppointmentTypesGroupEnable(mlovData.userSettings);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const ehrCapabilities = mlovData.ehrCapabilities || [];
  const currentEHR = ehrCapabilities?.length ? ehrCapabilities?.[0]?.ehrName : '';
  const [updateData, setUpdateData] = useState<IAppointmentType | undefined>(
    undefined
  );
  const locationGroupList = mlovData.loggedInUserLocationDetails?.locationGroupList;
  const locationGroupIds = locationGroupList?.map(item => item.locationGroupId);
  const filteredLocationGroups = locationGroups.filter((group: any) =>
    locationGroupIds?.includes(group.code)
  );

  const ehrConfig = getEhrConfig(currentEHR);
  const appointmentTypeCategory =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_TYPE_CATEGORY
    ) || [];
  const groupAppointmentCategoryId = getMlovIdFromCode(
    appointmentTypeCategory,
    MLOV_CODES.GROUP_APPOINTMENT
  );

  const groupSessionAppointmentCategoryId = getMlovIdFromCode(
    appointmentTypeCategory,
    MLOV_CODES.GROUP_SESSION
  );

  const appointmentFieldTypeMlovs =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV || {},
      MLOV_CATEGORY.APPOINTMENT_CUSTOM_FIELD_TYPE
    ) || [];

  const durationMlovs =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV || {},
      MLOV_CATEGORY.DATE_TIME_DURATION
    ) || [];

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
    total: 0
  });

  const [appointmentListState, setAppointmentListState] = useState<{
    searchString: string;
    loading: boolean;
    users: IUser[];
    appointmentTypes: IAppointmentType[];
    deleteConfirmationOpen: boolean;
    selectedItem?: any;
    showAddOrUpdateDrawer:boolean;
    action?: AppointmentTypeAction;
    appointmentTypeAvailabilityResponse?: IAppointmentTypeAvailabilityApiResponse[];
    userRoles: IUserRoleMlov[];
    total: number;
  }>({
    searchString: '',
    loading: true,
    users: [],
    appointmentTypes: [],
    deleteConfirmationOpen: false,
    selectedItem: undefined,
    showAddOrUpdateDrawer:false,
    action: undefined,
    appointmentTypeAvailabilityResponse: [],
    userRoles: [],
    total: 0
  });
  const {getAccountRoles} = useAddRoleAndPermission({
    fetchRoleCategory: [MLOV_CATEGORY.USER_ROLES,MLOV_CATEGORY.GLOBAL_USER_ROLES]
  });
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  useEffect(() => {
    setAppointmentListState((prev) => ({
      ...prev,
      searchString: props?.searchString || '',
      loading: true,
    }));
    return () => {
      timeOutRefs.current.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
    }
  }, [props.searchString]);
  const fetchUserRoles = async ()=> {
    const accountRolesResponse = await getAccountRoles();
    setAppointmentListState(prev=> {
      return {
        ...prev,
        userRoles: processRoles(accountRolesResponse, USER_ROLE_CODES.EMPLOYER)
      }
    })
  }
  useEffect(() => {
      fetchUserRoles()
    if (
      props?.selectedActionCode ===
      TABLE_TOP_BAR_ACTION_CODES.ADD_NEW_APPOINTMENT_TYPE
    ) {
      openAddOrUpdateDrawer(false);
    }  else if (props?.selectedActionCode === TABLE_TOP_BAR_ACTION_CODES.CLOSE) {
      setAppointmentListState(prev => ({
        ...prev,
        showAddOrUpdateDrawer:false,
        selectedItem: undefined,
        action: undefined,
        appointmentTypeAvailabilityResponse: [],
      }))
    }
  }, [props.selectedActionCode]);

  const openAddOrUpdateDrawer = (
    isEdit: boolean,
    item?: IAppointmentType,
    action?: AppointmentTypeAction
  ) => {
    setAppointmentListState((prev) => ({
      ...prev,
      showAddOrUpdateDrawer: true,
      appointmentTypeAvailabilityResponse: isEdit ? item?.appointmentTypeAvailability || [] : [],
      ...(isEdit
        ? {
            selectedItem: item,
            action: action || undefined,
          }
        : undefined),
    }));
  };

  useEffect(() => {
    setAppointmentListState((prev) => ({
      ...prev,
      appointmentTypes: (prev.appointmentTypes || []).map(
        (appointmentType) => ({
          ...appointmentType,
          locationGroupName: appointmentType?.locationGroupId
            ? locationGroups?.find(
                (group: any) => group?.code === appointmentType?.locationGroupId
              )?.display || ''
            : '',
        })
      ),
    }));
  }, [locationGroupLoading]);

  const closeAddOrUpdateDrawer = () => {
    setAppointmentListState(prev => ({
      ...prev,
      showAddOrUpdateDrawer:false,
      selectedItem: undefined,
      action: undefined,
      appointmentTypeAvailabilityResponse: [],
    }))
    props?.onClose?.(TABLE_TOP_BAR_ACTION_CODES.CLOSE)
  }

  const getQueryVariables = (searchStr: string, locations: string[], paginationData: any) => {
    return {
      searchString: `%${searchStr}%`,
      categoryCodes: isGroupAppointmentEnabled
        ? [MLOV_CODES.ONE_ON_ONE_APPOINTMENT, MLOV_CODES.GROUP_SESSION]
        : [MLOV_CODES.ONE_ON_ONE_APPOINTMENT],
      ...(isMultiTenancyEnabled && {
        locationGroupIds: locations.length > 0 ? locations : locationGroupIds
      }),
      limit: paginationData.pageSize,
      offset: (paginationData.current - 1) * paginationData.pageSize
    };
  };

  const {refetch} = useQuery<IScheduleEventAPIData>(
    isMultiTenancyEnabled
      ? ScheduleEventQueries.GET_APPOINTMENT_TYPES_BY_LOCATION_GROUP
      : ScheduleEventQueries.GET_APPOINTMENT_TYPES,
    {
      variables: getQueryVariables(appointmentListState.searchString, selectedLocations, pagination),
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (data: IScheduleEventAPIData) => {
        if (data.appointmentTypes) {
          let updatedAppointmentTypes = data.appointmentTypes;       
          const findLocationGroupName = (locationGroupId: string) => {
            const group = locationGroups?.find(
              (group: any) => group.code === locationGroupId
            );
            return group ? group.display : '';
          };
          updatedAppointmentTypes = updatedAppointmentTypes.map(
            (appointmentType) => ({
              ...appointmentType,
              locationGroupName: appointmentType?.locationGroupId
                ? findLocationGroupName(appointmentType?.locationGroupId)
                : '',
            })
          );
          if (
            appointmentListState.users &&
            appointmentListState.users.length > 0
          ) {
            updatedAppointmentTypes = setUserData(
              data.appointmentTypes,
              appointmentListState.users
            );
          }
          const formattedAppointmentTypes = formatAppointmentTypes(updatedAppointmentTypes, [...durationMlovs, ...appointmentFieldTypeMlovs]);
          setAppointmentListState((prev) => ({
            ...prev,
            appointmentTypes: formattedAppointmentTypes as IAppointmentType[],
            loading: false,
            total: data?.aggregateAppointmentTypes?.aggregate?.count || 0
          }));
        } else {
          setAppointmentListState((prev) => ({
            ...prev,
            appointmentTypes: [],
            loading: false,
            total: 0
          }));
        }
      },
      onError: (error) => {

        setAppointmentListState((prev) => ({
          ...prev,
          appointmentTypes: [],
          loading: false,
        }));
      },
    }
  );

  useQuery(UserQueries.GET_USERS_WITH_IDS, {
    skip: appointmentListState.appointmentTypes.length === 0,
    fetchPolicy: 'no-cache',
    variables: {
      userIds: getUserIds(appointmentListState.appointmentTypes),
      accountId: getAccountUUID(),
    },
    onCompleted: (data: any) => {
      if (data && data.users && data.users.length > 0) {
        const updatedAppointmentTypes = setUserData(
          appointmentListState.appointmentTypes,
          data.users
        );
        setAppointmentListState((prev) => ({
          ...prev,
          appointmentTypes: [...updatedAppointmentTypes],
          users: [...data.users],
        }));
      }
      setAppointmentListState((prev) => ({...prev, loading: false}));
    },
    onError: (data: any) => {
      setAppointmentListState((prev) => ({...prev, loading: false}));
    },
  });

  const [addOrUpdateAppointmentType] = useMutation(
    ScheduleEventQueries.ADD_OR_UPDATE_APPOINTMENT_TYPE,
    { context: { service: CARESTUDIO_APOLLO_CONTEXT } }
  );

  const onEdit = (data: IAppointmentType) => {
    openAddOrUpdateDrawer(
      true,
      data,
      data.categoryId === groupAppointmentCategoryId
        ? AppointmentTypeAction.groupAppointment
        : data.categoryId === groupSessionAppointmentCategoryId
        ? AppointmentTypeAction.groupSessionAppointment
        : AppointmentTypeAction.singleAppointment
    );
  };

  const deleteItem = () => {
    if (!appointmentListState.selectedItem) {
      return;
    }
    setAppointmentListState((prev) => ({
      ...prev,
      deleteConfirmationOpen: false,
      selectedForm: undefined,
      selectedItem: undefined,
      loading: true,
    }));
    addOrUpdateAppointmentType({
      variables: {
        data: {
          id: appointmentListState.selectedItem.id,
          duration: appointmentListState?.selectedItem?.duration || 30,
          eventName: appointmentListState?.selectedItem?.eventName,
          isDeleted: true,
        }
      },
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: () => {
        const timeOutId = setTimeout(() => {
          showToast(
            toast,
            intl.formatMessage({id: 'appointmentTypeDeletedMessage'}),
            ToastType.success
          );
          !locationGroupLoading && refetch();
          setAppointmentListState((prev) => ({...prev, loading: false}));
        }, 1000);
        timeOutRefs.current.push((timeOutId))
      },
      onError: () => {
        setAppointmentListState((prev) => ({...prev, loading: false}));
      },
    });
  };

  const onDelete = (data: IAppointmentType) => {
    setAppointmentListState((prev) => ({
      ...prev,
      deleteConfirmationOpen: true,
      selectedItem: data,
    }));
  };

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
    setAppointmentListState(prev => ({...prev, loading: true}));
    refetch(getQueryVariables(appointmentListState.searchString, selectedLocations, pagination));
  };

  const handleLocationFilter = (locations: string[]) => {
    setSelectedLocations(locations);
    const newPagination = { ...pagination, current: 1 };
    setPagination(newPagination);
    setAppointmentListState(prev => ({...prev, loading: true}));
    refetch(getQueryVariables(appointmentListState.searchString, locations, newPagination));
  };

  const {height} = Dimensions.get('window');
  return (
    <View>
      <Box
        overflow={'hidden'}
        marginBottom={0}
        // maxWidth={1200}
        //style={{marginHorizontal: 16, marginTop: 16}}
        bgColor={'white'}
        borderRadius={0}
        backgroundColor={'#fff'}
      >
       {props?.showTableTopBar && <AppointmentTypeTopBar
          onAction={() => {
            openAddOrUpdateDrawer(false);
          }}
          onSearch={(text: string) => {
            setAppointmentListState((prev) => ({...prev, searchString: text, loading: true}));
          }}
        />}
        {/* {appointmentListState.appointmentTypes.length > 0 && */}
          {(!appointmentListState.loading && !locationGroupLoading) &&(
            <Table
              rowClassName={(record, index) =>
                index % 2 == 0 ? 'table-row-light' : ''
              }
              scroll={{  x: 800,y: height - 260}}
              rowKey={(row: IAppointmentType) => row.id || ''}
              columns={getColumns({
                intl,
                onEdit,
                onDelete,
                mlovData, 
                ehrConfig, 
                userRoles: appointmentListState.userRoles, 
                hiddenColumnsKeysMap: !isMultiTenancyEnabled
                ? {
                    locationGroup: true,
                  }
                : {},
                onLocationFilter: handleLocationFilter,
                selectedLocations,
                filteredLocationGroups
              })}
              dataSource={appointmentListState.appointmentTypes}
              pagination={{
                ...pagination,
                total: appointmentListState.total,
                showSizeChanger: true,
                position: ['bottomCenter']
              }}
              onChange={handleTableChange}
              onRow={(data) => ({
                onClick: () => onEdit(data),
              })}
            />
          )}
        {(appointmentListState.loading || locationGroupLoading) && (
          <View margin={4} {...testID(TestIdentifiers.pageLoading)}>
            <Skeleton active />
          </View>
        )}
      </Box>

      {appointmentListState.showAddOrUpdateDrawer && (
        <AddOrUpdateAppointmentTypes
          refetchAppointments={!locationGroupLoading ? refetch : undefined}
          isVisible={appointmentListState.showAddOrUpdateDrawer}
          //type={appointmentListState?.action || AppointmentTypeAction.singleAppointment}
          updateData={appointmentListState.selectedItem}
          onClose={() => {
            closeAddOrUpdateDrawer();
          }}
          appointmentTypeAvailabilityResponse={appointmentListState.appointmentTypeAvailabilityResponse || []}
        />
      )}

      <FHAlertDialog
        isOpen={appointmentListState.deleteConfirmationOpen}
        header={intl.formatMessage({id: 'deleteAppointmentType'})}
        message={intl.formatMessage({
          id: appointmentListState.selectedItem?.currentEhrConfig?.isAthena
            ? 'deleteAppointmentTypeAthenaAlertMessage'
            : 'deleteAppointmentTypeAlertMessage',
        })}
        buttonActions={[
          {
            textLocalId: 'Cancel',
            buttonProps: {
              variant: BUTTON_TYPE.SECONDARY
            },
            onPress: () => {
              setAppointmentListState((prev) => ({
                ...prev,
                deleteConfirmationOpen: false,
                selectedItem: undefined,
                appointmentTypeAvailabilityResponse: [],
              }));
            },
          },
          {
            textLocalId: 'Delete',
            buttonProps: {
              variant: BUTTON_TYPE.SECONDARY
            },
            onPress: () => {
              deleteItem();
            },
          },
        ]}
      />
    </View>
  );
};

export default AppointmentTypesList;
