import {useLazyQuery, useMutation} from '@apollo/client';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {BUTTON_TYPE, MLOV_CATEGORY} from '../../../../constants';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {GET_BOOKED_APPOINTMENTS_BY_CONTACT_ID} from '../../../../services/Appointment/AppointmentQueries';
import {getDocumentsReferenceWithFilters, getEncountersFromIntegration} from '../../../../services/CommonService/AidBoxService';
import {getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {
  ICareTimelineListViewState,
  ICareTimelinePatientNoteState,
  ICareTimelineViewProps,
  INotesFormattedDataProps,
  IPatientNoteCardProps,
  NoteOperation,
} from '../interfaces';
import {
  CareTimelineViewElement,
  CARE_TIMELINE_CATEGORY,
  filterTagCodes,
} from './CareTimelineConstant';

import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {addProfileStatusToNotes, allowNoteOperation, enrichNotesWithProfileIds, getAppointmentFetchParams, getFilterCount, getFilterTags, getNotesContactProfileIds, getPatientIdsFromNotes} from './CareTimelineUtils';
import {
  HStack,
  VStack,
  Text,
  Spacer,
  Divider,
  Tooltip,
  IconButton,
  View,
  Pressable,
  FlatList,
  Spinner,
  Center,
  useToast,
} from 'native-base';
import {Colors} from '../../../../styles/Colors';
import NoteTitleIcon from '../../../../assets/Icons/NoteTitleIcon';
import CustomAddNoteButton from '../PatientNotes/components/CustomAddNoteButton';
import {DocStatus, NoteActions, NoteApiResponse, NoteEntry} from '../PatientNotes/interfaces';
import Feather from 'react-native-vector-icons/Feather';
import {
  ResponsiveTagRender,
} from '../../../common/ResponsiveTagRender/ResponsiveTagRender';
import ClearActionIcon from '../../../common/Svg/ClearActionIcon';
import {useIntl} from 'react-intl';
import styles, { reactStyles } from '../PatientNotes/PatientNotesStyles';
import {FilterPatientNotesViewDrawer, IPatientNotesSelectedFilters} from '../PatientNotes/components/FilterPatientNotesDrawer';
import {FHAlertDialog} from '../../../common/FHAlertDialog';
import { getAppointmentLocation, getFormattedEncountersForCareTimeline, getFormattedNoteForCareTimeline, getLatestAppointment } from '../PatientNotes/PatientNotesHelper';
import { allowToCreateTaskForNote, getAccountId, getBooleanFeatureFlag, getDocumentReferenceSortKey, getElationNoteOperationConfig, getPatientActiveProfileLocationIds, getPatientActiveProfiles, isActiveProfileMatchAllowedLocations, isPhysicianRole } from '../../../../utils/commonUtils';
import TimeLineSkeletonLoader from '../../../common/TimeLineSkeletonLoader/TimeLineSkeletonLoader';
import LinkToLatestAppointmentBanner from '../PatientNotes/components/LinkToLatestAppointmentBanner';
import NoNotes from '../PatientNotes/components/NoNotes';
import PatientNoteCard from './PatientNoteCard';
import { CARE_TIME_LINE_VIEW, PATIENT_NOTE_OPERATION } from '../PatientNotes/constants';
import { getCareDashboardDateRange } from '../../../../utils/DateUtils';
import { SignedNoteView } from './SignedNoteView';
import AddOrUpdatePatientNote from './AddOrUpdatePatientNote';
import { getResourceAbilities } from '../../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../../constants/FhirConstant';
import ReadOnlyPatientNoteView from './ReadOnlyPatientNoteView';
import { getDocumentRefByReferenceId } from '../../../../services/CommonService/OrderService';
import { isWeb } from '../../../../utils/platformCheckUtils';
import IsFocusedView from '../../../common/IsFocusedView/IsFocusedView.native';
import { DisplayText } from '../../../common/DisplayText/DisplayText';
import { AddActionView } from '../../../common/Buttons/AddActionView';
import EncounterView from './components/EncounterView';
import { notification } from 'antd';
import { cloneDeep } from 'lodash';
import TaskQueries from '../../../../services/Task/TaskQueries';
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces';
import { validate as isValidUuid } from 'uuid';
import { IMlov } from '../../../../Interfaces/CommonInterfaces';
import { LABEL_TYPE_CODES } from '../../../../constants/MlovConst';
import { DEFAULT_TASK_LABEL_COLOR } from '../../../common/AddTask/AddEditTaskView/TaskConstant';
import { CreateLabels, CreateLabelTypes } from '../../../../services/Tags/TagQueries';
import { ILabel } from '../../../common/FilterView/interfaces';
import {  useParams } from 'react-router-dom';
import useCareTimeLineNavigate from './CareTimeLineNavigation';
import {createLabelAndLabelType} from '../../../RightSideContainer/Sales/ProductsAndServices/JourneyPackages/PackageServices';
import useContactProfileStatus from '../../../common/ContactProfile/useContactProfileStatus';
import useLoggedInUserAllowedLocationsByTab from '../../../CustomHooks/useLoggedInUserAllowedLocationsByTab';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
import { EXTENSION_URLS } from '../PatientNotes/components/AddOrUpdateTemplate/constant';
import { GetLocationGroupIdsByPatientIds } from '../../../../services/Lead/LeadQueries';
import { USER_ACCESS_PERMISSION } from '../../../RightSideContainer/UserAccess/UserAccessPermission';
import { usePermissions } from '../../../CustomHooks/usePermissions';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { getContactActiveStatus, getUserActiveInTaskLocationStatus } from '../../../common/CareDashboard/CustomHook/TaskCustomHookHelper';
import { useTaskManageAccess } from '../../../TaskModule/CustomHooks/useTaskManageAccess';

export const CareTimelineView = (props: ICareTimelineViewProps) => {
  const navigate = useCareTimeLineNavigate();
  const intl = useIntl();
  const {
    personData,
    unFormattedContactData,
    ehrConfig,
    ehrCapabilities,
    accountUserList,
    currentUserData,
    noteConfig,
    timelineCategories,
    header,
    footer,
    defaultTemplate,
    orderTimelineNoteData,
    goBackHandler,
    navigation,
    activeAndNonActiveUserList
  } = props;
  const patientId = personData?.patientId || personData?.patientUuid;
  const patientLocationId = personData?.accountLocationUuid;
  const contactUUID = unFormattedContactData?.uuid || personData?.contactData?.uuid;
  const contactId = unFormattedContactData?.id || personData?.contactData?.id;
  const commonContextData = useContext(CommonDataContext);
  const accountLocationListWithEHR = commonContextData?.accountLocationListWithEHR || [];
  const locationGroupIdListByPatientContext = accountLocationListWithEHR
  ?.filter((item) => item.uuid === patientLocationId) 
  .map((item) => item.locationGroupId) 
  .filter((locationGroupId, index, self) => locationGroupId && self.indexOf(locationGroupId) === index) || [];
  const noteDefaultFlag = noteConfig?.flag || {};
  const isPhysicianUser = isPhysicianRole();
  const listInnerRef = useRef<HTMLInputElement>(null);
  const enablePagination = ehrConfig?.isFold;
  const isMsoFlagEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const allowedLocationIdsOfUser = useLoggedInUserAllowedLocationsByTab(MAIN_MENU_CODES.CONSUMER);
  const activecontactProfiles = getPatientActiveProfiles(unFormattedContactData?.contactProfiles || []);
  const activeContactProfileLocationIds = activecontactProfiles ? getPatientActiveProfileLocationIds(activecontactProfiles) : [];
  const hasActiveProfile = isActiveProfileMatchAllowedLocations(activecontactProfiles || [], allowedLocationIdsOfUser)
  const isActionsDisabled = isMsoFlagEnabled ? !hasActiveProfile : false;
  const tooltipMessage = !isActionsDisabled ? '' :  intl.formatMessage({id :'inactiveLocationPatientActionMsg'});
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    patientLocationId,
    undefined,
    undefined,
    undefined,
    true
  );
  const accountId = getAccountId();
  const elationFormData = {
    categories: resourceAbilities?.allowedCategories,
    noteComponents: resourceAbilities?.allowedNoteComponents,
  };
  const { getLoggedInUserTaskAccessUsers } = useTaskManageAccess();
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;
  const getDefaultTimeData = () => {
    let currentView = CARE_TIME_LINE_VIEW.TIMELINE_LIST_VIEW;
    if (
      orderTimelineNoteData &&
      orderTimelineNoteData?.resourceId &&
      orderTimelineNoteData?.status
    ) {
      if (
        orderTimelineNoteData?.status === DocStatus.FINAL ||
        orderTimelineNoteData?.status === DocStatus.AMENDED
      ) {
        currentView = CARE_TIME_LINE_VIEW.SIGNED_NOTE;
      } else if (orderTimelineNoteData?.status === DocStatus.PRELIMINARY) {
        currentView = CARE_TIME_LINE_VIEW.ADD_OR_UPDATE_NOTE;
      }
      return {
        loading: true,
        currentView: currentView,
        patientNotes: [],
        selectedNoteCard: orderTimelineNoteData || ({} as IPatientNoteCardProps),
        paginationLoading: false,
      };
    } else {
      return {
        loading: true,
        currentView: currentView,
        patientNotes: [],
        selectedNoteCard: {} as IPatientNoteCardProps,
        paginationLoading: false,
      };
    }
  };
  const [isFocused,setIsFocused] = useState();
  const toast = useToast();
  const [careTimelineState, setCareTimeline] = useState<ICareTimelineListViewState>(getDefaultTimeData());
  const allowedLocationIdP360Notes = useLoggedInUserAllowedLocationsByTab(MAIN_MENU_CODES.P360_NOTES);
  const allowedLocationIds = useLoggedInUserAllowedLocationsByTab(MAIN_MENU_CODES.CONSUMER);
  const [limit] = useState(20);
  const [total, setTotal] = useState(limit + 1);
  const [offset, setOffset] = useState(0);
  const { id, noteDocumentReferenceId } = useParams();
  const [getLocationGroupIds] = useLazyQuery(GetLocationGroupIdsByPatientIds, {
    fetchPolicy: 'no-cache',
  });

  const [getLabels] = useLazyQuery(TaskQueries.GET_LABELS_BY_TITLE, {
    fetchPolicy: 'no-cache',
  });
  const [getAllLabels] = useLazyQuery(TaskQueries.GET_LABELS_BY_NAME, {
    fetchPolicy: 'no-cache',
  });
  const [createLabel] = useMutation(CreateLabels);
  const [createLabelType] = useMutation(CreateLabelTypes);

  const NOTE_STATUS_LABEL = ['Open', 'In Progress', 'Closed'];
  const NOTE_SUB_STATUS_LABEL = [
    'Prior Authorization Follow Up',
    'EMO Requested',
    'Coordinator',
    'Nurse Reassignment',
    'Transition of Care',
    'EMO Review',
    '1st Member Outreach',
    '2nd Member Outreach',
    '3rd Member Outreach',
    'Medical Records Request',
    'Infusion Setup',
    'Risk Surveillance',
    'DPC - Med Records Request',
    'Awaiting Physician Call Back',
    'Awaiting Member Call Back',
    'SCA Creation',
    'Regenerative Medicine',
    'Ventegra - TruDate Outreach',
    'Missed Opportunity',
    'Transition of Care',
    'Proactive',
    'Surgery Outcome',
    'Appt Outcome',
  ];

  const userSettings = commonContextData?.userSettings;
  const elationConfig = getElationNoteOperationConfig(userSettings);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const { fetchContactProfileStatus } = useContactProfileStatus({isMultiTenancyEnabled});
  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code, MAIN_MENU_CODES.TASKS);
  const allowedLocations = permissionConfig?.allowedLocationIds || [];
  const primaryLocationIds = commonContextData.accountLocationListWithEHR?.filter((location) => {
    return allowedLocations?.includes(location?.uuid)
  })?.map((location) => location?.uuid) || [];

  const [patientNoteState, setPatientNotes] =
    useState<ICareTimelinePatientNoteState>({
      isFilterModalOpen: false,
      isConfirmationModalOpen: false,
      isNoteOpen: false,
      isNoteLinkWithAppointment: false,
      // The `-date` flag means that the notes will be sorted by the created_at field in descending order.
      // The `date` flag means that the notes will be sorted by the created_at field in ascending order.
      selectedFilters: {
        _sort: getDocumentReferenceSortKey() ? '-date' : '',
        locationId: personData?.accountLocationUuid,
      },
      todayInprogressNote: {},
      filterCount: 0,
      filterTags: [],
      isNewNote: false,
      isAddNewLoading: false,
    });
  const allowToCreateTask: boolean = allowToCreateTaskForNote(commonContextData.userSettings);
  const appointmentStatusList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS,
    ) || [];
    const labelTypeMlovList = getMlovListFromCategory(
      commonContextData.MLOV,
      MLOV_CATEGORY.LABEL_TYPE
    );


  const [getPatientAppointments, {refetch: refetchAppointments}] = useLazyQuery(
    GET_BOOKED_APPOINTMENTS_BY_CONTACT_ID,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    },
  );

  const [getTaskLinkWithDocumentReference] = useLazyQuery(TaskQueries.GET_TASKS_LINK_WITH_NOTES, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const [getTasksByIds] = useLazyQuery(
    TaskQueries.GET_TASKS_BY_IDS,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    }
  );

  const setRequestObjectForPatientNote = (requestObjectList: any[]) => {
    requestObjectList.push(CareTimelineViewElement.PATIENT_NOTE);
    if (allowToCreateTask) {
      requestObjectList.push(CareTimelineViewElement.TASK);
    }
    if (noteDefaultFlag.isAllowToAppointmentLink) {
      requestObjectList.push(CareTimelineViewElement.APPOINTMENT_LINK);
    }
  };

  const enrichNotesWithLocationGroupIds = async (notes: NoteApiResponse, patientIds: string[]) => {
    if (!patientIds || patientIds.length === 0) {
      return notes;
    }
  
    const locationResponse = await getLocationGroupIds({
      variables: { patientIds },
    });
  
    const resourceLocationGroupIdsMapWithPatientId: { [key: string]: string[] } = {};
    locationResponse?.data?.contactPatients?.forEach((patient: any) => {
      const patientId = patient?.patientId;
      const locationGroupIds = patient.contact?.contactPracticeLocations?.map((location: any) => location.accountLocation.locationGroup.id) || [];
      resourceLocationGroupIdsMapWithPatientId[patientId] = locationGroupIds;
    });
  
    const enrichedNotes = notes.entry.map(note => {
      const patientReference = note?.resource?.subject?.reference;
      if (patientReference) {
        const patientId = patientReference.split('/')[1];
        return {
          ...note,
          locationGroupId: resourceLocationGroupIdsMapWithPatientId[patientId] || [],
        };
      }
      return note;
    });
  
    return {
      ...notes,
      entry: enrichedNotes,
    };
  };

  const enrichPatientNotesWithProfileStatus = async (notes: NoteApiResponse) => { //here
    if (isMultiTenancyEnabled) {
      const patientIds: string[] = [];
      const profileIds = getNotesContactProfileIds(notes);
      const hasMissingProfileId = notes.entry.some(note => {
        const profileIdExtension = note.resource.extension?.find(ext => ext.url === EXTENSION_URLS.profileId);
        return !profileIdExtension || !profileIdExtension.valueString;
      });

      if (hasMissingProfileId) {
        notes.entry.forEach(note => {
          const profileIdExtension = note.resource.extension?.find(ext => ext.url === 'https://foldhealth.io/structure/profile-id');
          if (!profileIdExtension) {
            const patientReference = note?.resource?.subject?.reference;
            if (patientReference) {
              const patientId = patientReference.split('/')[1]; // Extracting the ID after "Patient/"
              patientIds.push(patientId);
            }
          }
        });
      }
      const  profileStatusData  = await fetchContactProfileStatus({ profileIds: profileIds });
      const noteWithLocationGroupIds = await enrichNotesWithLocationGroupIds(notes,patientIds)
      const note = await enrichNotesWithProfileIds(noteWithLocationGroupIds)
      return addProfileStatusToNotes(note, profileStatusData, locationGroupIdListByPatientContext, allowedLocationIds, activeContactProfileLocationIds );
    }
    return notes;
  };

  const getPatientNotesWithFilter = async (limit: number, offset: number, selectedFilters: IPatientNotesSelectedFilters) => {
    try {
      let documentResponse;
      if(isMultiTenancyEnabled){
        documentResponse = await getDocumentsReferenceWithFilters ({
          contactUuid: contactId,
          isPatient360: true,
          accountLocationIds: allowedLocationIdP360Notes,
          filters: selectedFilters,
          encounterId: '',
          pagination: enablePagination
            ? {
                limit: limit,
                offset: offset,
              }
            : undefined,
          foldSource: foldVisitNoteWithEncountersEnabled,
          locationId: patientLocationId,
          isMultitenancyEnabled: isMultiTenancyEnabled,
        }
      )
      const enrichedNotes = enrichNotesWithProfileIds(documentResponse?.data);
      const enrichedNotesData = await enrichPatientNotesWithProfileStatus(enrichedNotes);
      return enrichedNotesData;
      }
      else{
       documentResponse = await getDocumentsReferenceWithFilters({
         patientId: patientId,
         filters: selectedFilters,
         encounterId: '',
         pagination: enablePagination
           ? {
               limit: limit,
               offset: offset,
             }
           : undefined,
         foldSource: foldVisitNoteWithEncountersEnabled,
         locationId: patientLocationId,
       })
       return documentResponse?.data;
      }
    } catch(error) {
      setCareTimeline(prev => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const getEncounters = async (limit: number, offset: number, selectedFilters: IPatientNotesSelectedFilters) => {
    const encounterResponse = await getEncountersFromIntegration(
      patientId,
      patientLocationId,
      true,
      selectedFilters,
      ehrConfig?.isAthena
    );
    return encounterResponse?.data;
  }

  const getResponseByRequestObject = async (
    requestObjectList: CareTimelineViewElement[],
    limit: number,
    offset: number,
    selectedFilters: IPatientNotesSelectedFilters,
  ) => {
    const responseList: any[] = [];
    const promiseList: any[] = [];
    try {
      for (const requestObject of requestObjectList) {
        switch (requestObject) {
          case CareTimelineViewElement.ENCOUNTER:
            if(foldVisitNoteWithEncountersEnabled){
              // const encounterResponse = await getEncounters(limit, offset, selectedFilters);
              // responseList.push(encounterResponse);
              promiseList.push(getEncounters(limit, offset, selectedFilters));
            }
            break;
          case CareTimelineViewElement.PATIENT_NOTE:
            // const documentRefResponse = await getPatientNotesWithFilter(limit, offset, selectedFilters);
            // responseList.push(documentRefResponse);
            promiseList.push(getPatientNotesWithFilter(limit, offset, selectedFilters));
            break;
          case CareTimelineViewElement.APPOINTMENT_LINK:
            const params: any = getAppointmentFetchParams(
              appointmentStatusList,
              contactUUID,
            );
            // const appointmentResponse = await getPatientAppointments({
            //   variables: params,
            // });
            // responseList.push(appointmentResponse?.data || []);
            promiseList.push(getPatientAppointments({
              variables: params,
            }));
            break;
          case CareTimelineViewElement.TASK:
            // const responseNoteLabels =  await getDefaultNoteLabels();
            // responseList.push(responseNoteLabels || []);
            promiseList.push(getDefaultNoteLabels());
            break;
        }
      }
      const promiseResponseList = await Promise.all(promiseList);
      responseList.push(...promiseResponseList)
    } catch (error) {
    }
    return responseList;
  };

  const getCategoryWiseRequestObjectList = () => {
    const requestObjectList: CareTimelineViewElement[] = [];
    for (const category of timelineCategories) {
      switch (category?.type) {
        case CARE_TIMELINE_CATEGORY.PATIENT_NOTE:
          setRequestObjectForPatientNote(requestObjectList);
          break;
        case CARE_TIMELINE_CATEGORY.ENCOUNTER:
          requestObjectList.push(
            CareTimelineViewElement.ENCOUNTER,
            CareTimelineViewElement.APPOINTMENT_LINK,
          );
          if (allowToCreateTask) {
            requestObjectList.push(CareTimelineViewElement.TASK);
          }
          break;
      }
    }
    return requestObjectList;
  };

  const configureCategoryWiseTimelineData = async (requestObjects: any[], responseObjects: any[]) => {
    let patientNotes: IPatientNoteCardProps[] = [];
    let maxNotesAvailable = 0;
    let appointments: any[] = [];
    let noteTaskLabels: any[] = [];
    let latestAppointment: any = undefined;
    let linkResourceResponse: { linkTasks: ITask[], resourceMapList: { sourceId: string, resourceId: string }[] };
    if (requestObjects &&
        (requestObjects?.includes(CareTimelineViewElement.ENCOUNTER) || requestObjects?.includes(CareTimelineViewElement.PATIENT_NOTE))) {
          let index = requestObjects.indexOf(CareTimelineViewElement.ENCOUNTER);
          if (index === -1) {
            index =requestObjects.indexOf(CareTimelineViewElement.PATIENT_NOTE);
          }
          const responseData = responseObjects?.[index]?.entry || [];
          linkResourceResponse = await fetchDocumentReferenceMapTask(limit, offset, responseData);
    }
    (requestObjects || []).forEach(async (requestObject, index) => {
      switch (requestObject) {
        case CareTimelineViewElement.ENCOUNTER:
          const formatEncounterResponseParam: INotesFormattedDataProps = {
            noteResponse: [],
            ehrConfig,
            loginUserId: currentUserData?.uuid,
            contextData: commonContextData,
            accountUserList:  activeAndNonActiveUserList || [],
            activeAndNonActiveUserList:  activeAndNonActiveUserList || [],
            elationFormData: elationFormData,
            additionalFlags:{
              foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled
            },
            linkTasks: linkResourceResponse?.linkTasks || [],
            resourceMapList: linkResourceResponse?.resourceMapList || [],
          };
          const formmatedEncounters =  getFormattedEncountersForCareTimeline(responseObjects[index],formatEncounterResponseParam);
          patientNotes = formmatedEncounters;
          break;
        case CareTimelineViewElement.PATIENT_NOTE:
          const noteResponse = responseObjects?.[index];
          const formatResponseParam: INotesFormattedDataProps = {
            noteResponse: noteResponse?.entry || [],
            ehrConfig,
            contextData: commonContextData,
            loginUserId: currentUserData?.uuid,
            accountUserList:  accountUserList  || [],
            activeAndNonActiveUserList:  activeAndNonActiveUserList || [],
            elationFormData: elationFormData,
            additionalFlags:{
              foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
              isMultiTenancyEnabled: isMultiTenancyEnabled,
            },
            linkTasks: linkResourceResponse?.linkTasks || [],
            resourceMapList: linkResourceResponse?.resourceMapList || [],
          };
          patientNotes = getFormattedNoteForCareTimeline(formatResponseParam);
          maxNotesAvailable = noteResponse?.total || 0;
          break;
        case CareTimelineViewElement.APPOINTMENT_LINK:
          const appointmentResponse = responseObjects?.[index];
          appointments = appointmentResponse?.data?.appointments || []
          const filterAppointments = getLatestAppointment(appointments || []);
          latestAppointment  = filterAppointments?.length ? filterAppointments[0] : undefined;
          break;
        case CareTimelineViewElement.TASK:
          noteTaskLabels = responseObjects?.[index] || [];
      }
    });
    return {patientNotes, latestAppointment, appointments, maxNotesAvailable, noteTaskLabels };
  }

  const fetchDocumentReferenceMapTask = async (limit: number, offset: number, patientNotes: NoteEntry[]): Promise<{ linkTasks: ITask[]; resourceMapList: { sourceId: string; resourceId: string; }[]; }> => {
    if (allowToCreateTask) {
      const isPaginatedCall = enablePagination && offset >= limit;
      const patientNotesList = isPaginatedCall ? [careTimelineState.patientNotes, ...patientNotes] : patientNotes;
      const resourceIds: string[] = [];
      patientNotesList.forEach(
        (note: any) => {
          if (isValidUuid(note?.resource?.id)) {
            resourceIds.push(note?.resource?.id);
          }
        }
      );
      if (resourceIds?.length) {
        const taskNoteResourceMapParam = { sourceIds: resourceIds };
        const resourceMapResponse = await getTaskLinkWithDocumentReference({ variables: taskNoteResourceMapParam });
        const resourceMappings: { sourceId: string, resourceId: string }[] = resourceMapResponse?.data?.resourceMappings || [];
        if (resourceMappings?.length) {
          const filterResourceIds = resourceMappings.map(resourceMap => {
            return resourceMap?.resourceId;
          });
          if (filterResourceIds?.length) {
            const linkTasks = await getTaskDataByIds(filterResourceIds);
            return {linkTasks: linkTasks || [], resourceMapList: resourceMappings};
          }
        }
      }
    }
    return { linkTasks: [], resourceMapList: []} ;
  }

  const getTaskDataByIds = async (taskIds: string[]) => {
    try {
      const [taskResponse, taskAccessUsersData] = await Promise.all([
        getTasksByIds({
          variables: {ids: taskIds },
        }),
        getLoggedInUserTaskAccessUsers()
      ]);
      const linkTasks = taskResponse?.data?.getTasks?.tasks || [];
      const taskAccessUsers = taskAccessUsersData?.data || [];
      const updatedLinkTasks = linkTasks.map((task: ITask) => {
        task.hasTaskAccess = taskAccessUsers?.includes(task?.assigneeId) || false;
        task.isReadOnly = !task?.hasTaskAccess;
        return task;
      });
      return updatedLinkTasks;
    } catch (error) {
      return [];
    }
  }

  const fetchTimelineData = async (limit: number, offset: number, selectedFilters: IPatientNotesSelectedFilters, isPaginatedCall: boolean) => {
    setCareTimeline(prev => {
      const isPaginatedCall = offset >= limit;
      return {
        ...prev,
        loading: isPaginatedCall ? false : true,
        paginationLoading: isPaginatedCall
      };
    });
    const noteResponse = await getPatientNotesWithFilter(limit, offset, selectedFilters);
    const enrichedNotes = enrichNotesWithProfileIds(noteResponse);
    const enrichedNotesData = await enrichPatientNotesWithProfileStatus(enrichedNotes);
    // get map resource with notes
    const {linkTasks, resourceMapList} = await fetchDocumentReferenceMapTask(limit, offset, noteResponse?.entry || []);
    const formatResponseParam: INotesFormattedDataProps = {
      noteResponse: isMultiTenancyEnabled ? enrichedNotesData?.entry || [] : noteResponse?.entry || [],
      ehrConfig,
      loginUserId: currentUserData?.uuid,
      contextData: commonContextData,
      accountUserList:  activeAndNonActiveUserList || [],
      activeAndNonActiveUserList:  activeAndNonActiveUserList || [],
      elationFormData: elationFormData,
      additionalFlags:{
        foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
        isMultiTenancyEnabled: isMultiTenancyEnabled
      },
      linkTasks: linkTasks,
      resourceMapList: resourceMapList,
    };
    const patientNotes = getFormattedNoteForCareTimeline(formatResponseParam, foldVisitNoteWithEncountersEnabled);
    setTotal(noteResponse?.total || 0);
    setCareTimeline(prev => {
      const isPaginatedCall = enablePagination ? offset >= limit : false;
      return {
        ...prev,
        loading: false,
        patientNotes: isPaginatedCall
          ? [...prev.patientNotes, ...patientNotes]
          : patientNotes,
        ...(isPaginatedCall && {
          paginationLoading: false,
        }),
      };
    });
  };

  const getInitialTimeline = async (limit: number, offset: number, selectedFilters: IPatientNotesSelectedFilters, resourceIdToCheckInList?: IPatientNoteCardProps['resourceId'], isUpdate?: boolean, isContactActive?: boolean, notesAccountLocationId?: string ) => {
    try {
      if (noteDocumentReferenceId && isValidUuid(noteDocumentReferenceId)) {
        setCareTimeline(prev => {
          return {
            ...prev,
            loading: true,
          }
        });
        setPatientNoteViewByResourceData(noteDocumentReferenceId, '', isContactActive, notesAccountLocationId );
        return;
      }
      setCareTimeline(prev => {
        const isPaginatedCall = offset >= limit;
        return {
          ...prev,
          loading: isPaginatedCall ? false : true,
          paginationLoading: isPaginatedCall
        };
      });
      const requestObjects: any[] = getCategoryWiseRequestObjectList();
      const responseObjects = await getResponseByRequestObject(requestObjects, limit, offset, selectedFilters);
      const {patientNotes, latestAppointment, appointments, maxNotesAvailable, noteTaskLabels} = await configureCategoryWiseTimelineData(requestObjects, responseObjects);
      setTotal(maxNotesAvailable);
      setCareTimeline((prev) => {
        const isPaginatedCall = enablePagination && offset >= limit;
        if (noteDefaultFlag.isAllowToFilterNote && resourceIdToCheckInList) {
          const patientNotesList = isPaginatedCall
            ? [...prev.patientNotes, ...patientNotes]
            : patientNotes;
          const noteExistsInRenderingList = patientNotesList.some(
            (note) => note?.resourceId === resourceIdToCheckInList
          );
          if (!noteExistsInRenderingList) {
            notification.destroy();
            notification.info({
              message: intl.formatMessage({
                id: isUpdate ? 'noteUpdatedButNotVisible' : 'noteCreatedButNotVisible',
              }),
              style: {width: 500},
            });
          }
        }
        return {
          ...prev,
          loading: false,
          paginationLoading: false,
          patientNotes: isPaginatedCall
            ? [...prev.patientNotes, ...patientNotes]
            : patientNotes,
          latestAppointment,
          appointments,
          noteLabels: noteTaskLabels
        };
      });
    } catch (error) {
      setCareTimeline(prev => {
        return {
          ...prev,
          loading: false,
        }
      });
    }
  };

  const getNoteConfigForLinkAppointment = () => {
    return {
      createdByUserName: currentUserData?.name,
      status: DocStatus.PRELIMINARY,
      authorUuid: currentUserData?.uuid,
      linkedAppointmentId:
        careTimelineState?.latestAppointment?.externalAppointmentId || '',
      resourceId: '',
      isAllowToDelete: false,
      isAllowToPrint: allowNoteOperation(NoteOperation.PRINT, ehrConfig, {
        contextData: commonContextData
      }),
      isAllowToUnlock: false,
      isAllowToAutoSave: allowNoteOperation(
        NoteOperation.AUTO_SAVE,
        ehrConfig,
        {
          contextData: commonContextData
        }
      ),
      isAllowToSign: true, // as creator can sign his own note at time of creation itself
      isAllowToSave: true,
      formId: defaultTemplate?.formId,
    } as IPatientNoteCardProps;
  };

  const getNoteConfigureForAddNewNote = () => {
    return {
      createdByUserName: currentUserData?.name,
      status: DocStatus.PRELIMINARY,
      authorUuid: currentUserData?.uuid,
      linkedAppointmentId: undefined,
      resourceId: '',
      isAllowToDelete: false,
      isAllowToPrint: allowNoteOperation(NoteOperation.PRINT, ehrConfig, {
        contextData: commonContextData
      }),
      isAllowToUnlock: false,
      isAllowToAutoSave: allowNoteOperation(
        NoteOperation.AUTO_SAVE,
        ehrConfig,
        {
          contextData: commonContextData
        }
      ),
      // as creator can sign his own note at time of creation itself only for athena and fold for ELATION we allow sign based on flag
      isAllowToSign:  ehrConfig.isElation && !foldVisitNoteWithEncountersEnabled  ? elationConfig.allowToSign : true,
      isAllowToSave: true,
      formId: defaultTemplate?.formId,
    } as IPatientNoteCardProps;
  };

  const setPatientNoteViewByResourceData = async (resourceId: any, view: string, isContactActive?: boolean, notesAccountLocationId?: string  ) => {
    const response = await getDocumentRefByReferenceId(`${resourceId}`, foldVisitNoteWithEncountersEnabled, personData?.accountLocationUuid);
    const noteEntry: NoteEntry = { resource: response?.data } as NoteEntry;
    const sourceIds = isValidUuid(resourceId) ? [resourceId] : [];
    let resourceMappings: { sourceId: string, resourceId: string }[] = [];
    let linkTasks: ITask[] = [];
    if (sourceIds?.length) {
      const taskNoteResourceMapParam = { sourceIds: sourceIds };
      const resourceMapResponse = await getTaskLinkWithDocumentReference({ variables: taskNoteResourceMapParam });
      resourceMappings = resourceMapResponse?.data?.resourceMappings || [];
      linkTasks = [];
      if (resourceMappings?.length) {
        const filterResourceIds = resourceMappings.map(resourceMap => {
          return resourceMap?.resourceId;
        });
        if (filterResourceIds?.length) {
          linkTasks = await getTaskDataByIds(filterResourceIds);
        }
      }
    }
    let noteView = '';
    if (!view) {
      const status =  noteEntry.resource.docStatus;
      switch(status) {
        case DocStatus.AMENDED:
        case DocStatus.FINAL:
          noteView = CARE_TIME_LINE_VIEW.SIGNED_NOTE;
        break;
        case DocStatus.PRELIMINARY:
          noteView = CARE_TIME_LINE_VIEW.ADD_OR_UPDATE_NOTE;
          break;
      }
    }
    if (isMultiTenancyEnabled) {
      noteEntry.isContactActive = isContactActive;
      noteEntry.notesAccountLocationId = notesAccountLocationId;
    }
    const formatResponseParam: INotesFormattedDataProps = {
      noteResponse: [noteEntry],
      ehrConfig,
      loginUserId: currentUserData?.uuid,
      contextData: commonContextData,
      accountUserList:  accountUserList || [],
      activeAndNonActiveUserList:  activeAndNonActiveUserList || [],
      elationFormData: elationFormData,
      additionalFlags:{
        foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
        isMultiTenancyEnabled: isMultiTenancyEnabled,
      },
      linkTasks: linkTasks || [],
      resourceMapList: resourceMappings || [],
    };
    const patientNotes = getFormattedNoteForCareTimeline(formatResponseParam); // here not detted up iscontactactive
    setCareTimeline(prev => {
      return {
        ...prev,
        currentView: view || noteView,
        selectedNoteCard: patientNotes?.[0] || {} as IPatientNoteCardProps,
        loading: false,
      }
    });
  }

  const navigateToNativeAddOrUpdatePatientNoteScreen = (noteData: IPatientNoteCardProps | undefined,isNewNote:boolean,isNoteLinkWithAppointment: boolean) => {
    navigation.navigate('AddOrUpdateNoteScreen', {
      ehrCapabilities,
      personData,
      unFormattedContactData,
      noteData: noteData,
      ehrConfig,
      isNewNote: isNewNote,
      isLinkAppointment: isNoteLinkWithAppointment,
      isPhysicianUser,
      accountUserList,
      elationFormData,
    });
  }

  const patientNoteHandler = async (operationCode: string, noteData: IPatientNoteCardProps | undefined, isRefetch?: boolean, isUpdate?: boolean) => {
      switch (operationCode) {
        case PATIENT_NOTE_OPERATION.VIEW_ENCOUNTER:
          setCareTimeline(prev => {
            return {
              ...prev,
              currentView: CARE_TIME_LINE_VIEW.ENCOUNTER_VIEW,
              selectedNoteCard: noteData || {} as IPatientNoteCardProps,
            }
          });
          break;
        case PATIENT_NOTE_OPERATION.VIEW_SIGNED_NOTE:
          setCareTimeline(prev => {
            return {
              ...prev,
              currentView: CARE_TIME_LINE_VIEW.SIGNED_NOTE,
              selectedNoteCard: noteData || {} as IPatientNoteCardProps,
            }
          });
          break;
        case PATIENT_NOTE_OPERATION.CREATE_NOTE:
            setPatientNotes(prev => {
              return {
                 ...prev,
                 isNewNote: true,
                 isNoteLinkWithAppointment: false,
              }
            });
          if (isWeb()) {
            setCareTimeline((prev) => {
              return {
                ...prev,
                currentView: CARE_TIME_LINE_VIEW.ADD_OR_UPDATE_NOTE,
                selectedNoteCard: getNoteConfigureForAddNewNote(),
              };
            });
          } else {
            const newNoteData = getNoteConfigureForAddNewNote();
            navigateToNativeAddOrUpdatePatientNoteScreen(newNoteData,true,false);
          }
          break;
        case PATIENT_NOTE_OPERATION.CREATE_NOTE_WITH_LINK_APPOINTMENT:
          setPatientNotes(prev => {
            return {
               ...prev,
               isNewNote: true,
               isNoteLinkWithAppointment: true,
            }
          });
          setCareTimeline(prev => {
            return {
              ...prev,
              currentView: CARE_TIME_LINE_VIEW.ADD_OR_UPDATE_NOTE,
              selectedNoteCard: getNoteConfigForLinkAppointment(),
            }
          });
          break;
        case PATIENT_NOTE_OPERATION.EDIT_INPROGRESS_NOTE:
          setPatientNotes(prev => {
            return {
               ...prev,
               isNewNote: false,
               isNoteLinkWithAppointment: noteData?.linkedAppointmentId ? true : false,
            }
          });
        if (isWeb()) {
          setCareTimeline((prev) => {
            return {
              ...prev,
              currentView: CARE_TIME_LINE_VIEW.ADD_OR_UPDATE_NOTE,
              selectedNoteCard: noteData || ({} as IPatientNoteCardProps),
            };
          });
        } else {
          navigateToNativeAddOrUpdatePatientNoteScreen(
            noteData,
            false,
            noteData?.linkedAppointmentId ? true : false
          );
        }
          break;
        case PATIENT_NOTE_OPERATION.UNLOCK:
          setCareTimeline(prev => {
            return {
              ...prev,
              loading: true,
            }
          });
          if (noteData?.resourceId) {
            setPatientNoteViewByResourceData(noteData?.resourceId, CARE_TIME_LINE_VIEW.ADD_OR_UPDATE_NOTE, noteData?.isContactActive, noteData?.notesAccountLocationId);
          } else {
            setCareTimeline(prev => {
              return {
                ...prev,
                currentView: CARE_TIME_LINE_VIEW.TIMELINE_LIST_VIEW,
                loading: false,
              }
            });
          }
          break;
        case PATIENT_NOTE_OPERATION.VIEW_CARE_TIMELINE:
          setCareTimeline(prev => {
            return {
              ...prev,
              currentView: CARE_TIME_LINE_VIEW.TIMELINE_LIST_VIEW,
              selectedNoteCard: {} as IPatientNoteCardProps,
            }
          });
          break;
        case PATIENT_NOTE_OPERATION.EDIT_NOTE:
            setPatientNotes((prev) => {
              return {
                ...prev,
                isNewNote: false,
                isNoteLinkWithAppointment: noteData?.linkedAppointmentId
                  ? true
                  : false,
              };
            });
          if (isWeb()) {
            setCareTimeline((prev) => {
              return {
                ...prev,
                currentView: CARE_TIME_LINE_VIEW.ADD_OR_UPDATE_NOTE,
                selectedNoteCard: noteData || ({} as IPatientNoteCardProps),
              };
            });
          } else {
            navigateToNativeAddOrUpdatePatientNoteScreen(
              noteData,
              false,
              noteData?.linkedAppointmentId ? true : false
            );
          }
          break;
        case PATIENT_NOTE_OPERATION.REFETCH:
        case PATIENT_NOTE_OPERATION.DELETE:
          setOffset(0);
          if (offset === 0) {
            if (offset === 0) {
              getInitialTimeline(
                limit,
                offset,
                patientNoteState?.selectedFilters || {},
                noteData?.resourceId,
                isUpdate,
                noteData?.isContactActive,
                noteData?.notesAccountLocationId
              );
            }          }
          setCareTimeline(prev => {
            return {
              ...prev,
              currentView: CARE_TIME_LINE_VIEW.TIMELINE_LIST_VIEW,
              selectedNoteCard: {} as IPatientNoteCardProps,
            };
          });
          break;
        case PATIENT_NOTE_OPERATION.READ_ONLY_NOTE:
          if (isWeb()) {
            setCareTimeline(prev => {
              return {
                ...prev,
                currentView: CARE_TIME_LINE_VIEW.READ_ONLY_NOTE,
                selectedNoteCard: noteData || {} as IPatientNoteCardProps,
              }
            });
          } else {
            navigation.navigate('ReadOnlyNoteScreen',{
              cardData: noteData,
              ehrCapabilities: ehrCapabilities,
              locationId: personData?.accountLocationUuid
            });
          }
          break;
      }
  };

   function onRemoveFilter(code: string) {
    setOffset(0);
    setTotal(0);
     switch (code) {
       case filterTagCodes.CREATED_BY_USER_IDS:
         setPatientNotes((prev) => {
           return {
             ...prev,
             selectedFilters: {
               ...prev.selectedFilters,
               createdByUserIds: undefined,
               createdByUsers: undefined,
             },
           };
         });
         break;
       case filterTagCodes.SIGNED_BY_USER_IDS:
         setPatientNotes((prev) => {
           return {
             ...prev,
             selectedFilters: {
               ...prev.selectedFilters,
               signedByUserIds: undefined,
               signedByUsers: undefined,
             },
           };
         });
         break;
        case filterTagCodes.LAST_AMENDED_AND_SIGNED_BY_USER_IDS:
        setPatientNotes((prev) => {
          return {
            ...prev,
            selectedFilters: {
              ...prev.selectedFilters,
              lastAmendedAndSignedByUserIds: undefined,
              lastAmendedAndSignedByUsers: undefined,
            },
          };
        });
      break;
       case filterTagCodes.CREATED_ON_DATE:
         setPatientNotes((prev) => {
           return {
             ...prev,
             selectedFilters: {
               ...prev.selectedFilters,
               createdOnStartDate: undefined,
               createdOnEndDate: undefined,
             },
           };
         });
         break;
       case filterTagCodes.SIGNED_ON_DATE:
         setPatientNotes((prev) => {
           return {
             ...prev,
             selectedFilters: {
               ...prev.selectedFilters,
               signedOnStartDate: undefined,
               signedOnEndDate: undefined,
             },
           };
         });
         break;
      case filterTagCodes.LAST_AMENDED_AND_SIGNED_ON_DATE:
        setPatientNotes((prev) => {
          return {
            ...prev,
            selectedFilters: {
              ...prev.selectedFilters,
              lastAmendedAndSignedOnStartDate: undefined,
              lastAmendedAndSignedOnEndDate: undefined,
            },
          };
        });
        break;
       case filterTagCodes.CHIEF_COMPLAINT:
         setPatientNotes((prev) => {
           return {
             ...prev,
             selectedFilters: {
               ...prev.selectedFilters,
               chiefComplaint: undefined,
             },
           };
         });
         break;
       case filterTagCodes.DIAGNOSIS:
         setPatientNotes((prev) => {
           return {
             ...prev,
             selectedFilters: {
               ...prev.selectedFilters,
               diagnosis: undefined,
             },
           };
         });
         break;
       case filterTagCodes.FORM:
         setPatientNotes((prev) => {
           return {
             ...prev,
             selectedFilters: {
               ...prev.selectedFilters,
               form: undefined,
             },
           };
         });
         break;
        case filterTagCodes.STATUS:
        setPatientNotes((prev) => {
          return {
            ...prev,
            selectedFilters: {
              ...prev.selectedFilters,
              selectedStatusCodes: undefined,
              selectedStatusCodeValue: undefined,
            },
          };
        });
        break;
     }
   }

  const renderPatientNoteFilter = (): JSX.Element => {
    return (
      <HStack
        paddingY={2}
        color={'#667085'}
        borderRadius={'6px'}
        alignItems={'center'}>
        <Tooltip label="Filter Notes">
          <IconButton
            icon={<Feather color={'#667085'} name={'filter'} size={18} />}
            _hover={{background: 'none'}}
            onPress={() => {
              setPatientNotes(prev => ({
                ...prev,
                isFilterModalOpen: !prev.isFilterModalOpen,
              }));
            }}
          />
        </Tooltip>
        {getFilterCount(patientNoteState.selectedFilters) > 0 && (
          <Text marginLeft={-2} color={'#667085'}>
            {getFilterCount(patientNoteState.selectedFilters)}
          </Text>
        )}
      </HStack>
    );
  };
  const renderPatientNoteFilterTag = (): JSX.Element => {
    return (
      <>
        {getFilterCount(patientNoteState.selectedFilters) > 0 &&
          getFilterTags(patientNoteState.selectedFilters).length > 0 && (
            <HStack alignItems={'center'} width={'full'}>
              <View alignItems={'stretch'} flex={7}>
                <ResponsiveTagRender
                  filterTags={getFilterTags(patientNoteState.selectedFilters)}
                  onRemoveFilter={onRemoveFilter}
                />
              </View>
              <View
                width={'100px'}
                height={'full'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Pressable
                  onPress={() => {
                    setPatientNotes((prev) => ({
                      ...prev,
                      selectedFilters: undefined,
                      filterCount: 0,
                      filterTags: [],
                    }));
                  }}
                  style={styles.clearAllButton}
                >
                  <ClearActionIcon />
                  <Text
                    color={Colors.Custom.mainPrimaryPurple}
                    style={styles.clearAllButtonText}
                  >
                    Clear All
                  </Text>
                </Pressable>
              </View>
            </HStack>
          )}
        {getFilterCount(patientNoteState.selectedFilters) > 0 &&
          getFilterTags(patientNoteState.selectedFilters).length > 0 && (
            <Divider thickness={3} />
          )}
      </>
    );
  };

  const defaultNoteCreationHandler = async () => {
    setPatientNotes(prev => ({...prev,isAddNewLoading: true}));
    try {
      const dateRange = getCareDashboardDateRange();
      const documentResponse = await getDocumentsReferenceWithFilters({
        patientId: patientId,
        filters: {
          createdOnStartDate: dateRange.startDate,
          _sort: patientNoteState?.selectedFilters?._sort,
          locationId: personData?.accountLocationUuid,
        },
        encounterId: '',
        pagination: undefined,
        foldSource: foldVisitNoteWithEncountersEnabled,
        locationId: patientLocationId
      })
      const formatResponseParam: INotesFormattedDataProps = {
        noteResponse: documentResponse.data?.entry || [],
        ehrConfig,
        loginUserId: currentUserData?.uuid,
        contextData: commonContextData,
        accountUserList:  accountUserList || [],
        activeAndNonActiveUserList:  activeAndNonActiveUserList || [],
        elationFormData: elationFormData,
        additionalFlags:{
          foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
          isMultiTenancyEnabled: isMultiTenancyEnabled,
        }
      };
      const patientNotes = getFormattedNoteForCareTimeline(formatResponseParam);
      const todayPendingNote = (patientNotes || []).find(note => {
        return note?.status === DocStatus.PRELIMINARY;
      });
      setPatientNotes(prev => ({...prev,isAddNewLoading: false}));
      if (todayPendingNote?.resourceId) {
        setPatientNotes(prev => {
          return {
            ...prev,
            todayInprogressNote: todayPendingNote,
            isConfirmationModalOpen: true,
          }
        });
      } else {
        patientNoteHandler(PATIENT_NOTE_OPERATION.CREATE_NOTE, {} as IPatientNoteCardProps);
      }
    } catch(err) {
      setPatientNotes(prev => ({...prev,isAddNewLoading: false}));
      patientNoteHandler(PATIENT_NOTE_OPERATION.CREATE_NOTE, {} as IPatientNoteCardProps);
    }
  };

  const renderHeader = (): JSX.Element => {
    if(isWeb()){
      return (
        <VStack>
          <HStack
            alignItems="center"
            paddingX={5}
            paddingY={4}
            backgroundColor={Colors.Custom.White}
            style={styles.header}
          >
            <NoteTitleIcon key={'note-title-icon'} />
            <Text
              size={'smMedium'}
              style={styles.headerText}
            >
              {intl.formatMessage({id: 'notes'})}
            </Text>
            <Spacer />
            {
              <HStack height={8} alignItems={'center'}>
                {noteDefaultFlag.isAllowToCreate && (
                  <CustomAddNoteButton
                    key="add-note-button"
                    onPress={(string: NoteActions) => {
                      defaultNoteCreationHandler();
                    }}
                    disabled={isActionsDisabled}
                    tooltipMessage={tooltipMessage}
                  />
                )}
                {noteDefaultFlag.isAllowToFilterNote &&
                  noteDefaultFlag.isAllowToCreate && (
                    <Divider
                      bg={Colors.Custom.Gray300}
                      marginX={2}
                      orientation={'vertical'}
                    />
                  )}
                {noteDefaultFlag.isAllowToFilterNote &&
                  renderPatientNoteFilter()}
              </HStack>
            }
          </HStack>
          {noteDefaultFlag.isAllowToFilterNote && renderPatientNoteFilterTag()}
        </VStack>
      );
    } else {
      return (
        <View mb={-5} style={styles.header3} backgroundColor={Colors.Custom.Gray50}>
          <View
            style={styles.header3Container}
          >
            <View style={styles.header3IconContainer}>
              <NoteTitleIcon key={'note-title-icon'} />
            </View>
            <VStack>
              <DisplayText size={'xlBold'} textLocalId={'notes'} />
            </VStack>
            <Spacer />
            <Pressable
              isDisabled={patientNoteState.isAddNewLoading }
              onPress={() => isActionsDisabled ?  showToast(
                toast,
                tooltipMessage,
                ToastType.info
              ) : defaultNoteCreationHandler()}
            >
              {patientNoteState.isAddNewLoading ? (
                <Spinner size='lg'/>
              ) : (
                 <AddActionView disabled={isActionsDisabled}/>
              )}
            </Pressable>
          </View>
        </View>
      );
    }
  };

  const renderPatientNoteCard = (noteData: IPatientNoteCardProps): JSX.Element => {
    return (
      <PatientNoteCard
        contextData={commonContextData}
        navigation={navigation}
        appointmentStatusList={appointmentStatusList}
        ehrCapabilities={ehrCapabilities}
        unFormattedContactData={unFormattedContactData}
        personData={personData}
        cardProps={noteData}
        printProcessId={undefined}
        header={header}
        footer={footer}
        onActionPerformed={(operationCode: string, selectedNote: IPatientNoteCardProps) => {
          const refetchPatientNote = operationCode === PATIENT_NOTE_OPERATION.REFETCH || operationCode === PATIENT_NOTE_OPERATION.DELETE;
          if (refetchPatientNote) {
            setOffset(0);
            setTotal(0);
            setPatientNotes(prev => ({
              ...prev,
              isFilterModalOpen: false,
            }));
          }
          patientNoteHandler(operationCode, selectedNote, refetchPatientNote);
        }}
        ehrConfig={ehrConfig}
        isPhysicianUser={isPhysicianUser}
        accountUserList={accountUserList}
        elationFormData={elationFormData}
      />
    );
  };

  const renderReadOnlyView = () => {
    return (
      <>
        {careTimelineState.currentView ===
          CARE_TIME_LINE_VIEW.READ_ONLY_NOTE && (
          <ReadOnlyPatientNoteView
            ehrCapabilities={ehrCapabilities}
            cardData={careTimelineState.selectedNoteCard}
            handleGoBack={() => {
              setCareTimeline(prev => {
                return {
                  ...prev,
                  currentView: CARE_TIME_LINE_VIEW.TIMELINE_LIST_VIEW
                }
              });
            }}
            locationId={personData?.accountLocationUuid}
          />
        )}
      </>
    );
  }

  const renderEncounterView = () => {
    return (
      <>
        {careTimelineState.currentView ===
          CARE_TIME_LINE_VIEW.ENCOUNTER_VIEW && (
          <EncounterView
            patientId={patientId}
            encounterDetails={careTimelineState.selectedNoteCard}
            handleGoBack={() => {
              setCareTimeline((prev) => {
                return {
                  ...prev,
                  currentView: CARE_TIME_LINE_VIEW.TIMELINE_LIST_VIEW,
                };
              });
            }}
            locationId={personData?.accountLocationUuid}
          />
        )}
      </>
    );
  }
  const renderTimelineListView = () => {
    return (
      <>
        {careTimelineState.currentView ===
          CARE_TIME_LINE_VIEW.TIMELINE_LIST_VIEW && (
          <View h={'100%'} flexDir={'column'}>
            {careTimelineState?.latestAppointment &&
              noteDefaultFlag.isAllowToAppointmentLink && (
                <>
                  <LinkToLatestAppointmentBanner
                    onClick={() => {
                      patientNoteHandler(
                        PATIENT_NOTE_OPERATION.CREATE_NOTE_WITH_LINK_APPOINTMENT,
                        undefined
                      );
                    }}
                    name={careTimelineState?.latestAppointment?.name}
                    statusId={careTimelineState?.latestAppointment?.statusId}
                    date={careTimelineState?.latestAppointment?.startDateTime}
                    reasonForVisit={
                      careTimelineState?.latestAppointment?.reasonForVisit
                        ?.displayName
                    }
                    location={
                      careTimelineState?.latestAppointment &&
                      getAppointmentLocation(
                        careTimelineState?.latestAppointment,
                        accountUserList
                      )
                    }
                  />
                  <Divider mb={1} />
                </>
              )}
            {!careTimelineState?.patientNotes?.length && (
              <VStack justifyContent={isWeb() ? undefined : 'center'}>
                {patientNoteState.filterCount > 0 ? (
                  <NoNotes title="noFilteredNotesMsg" />
                ) : (
                  <NoNotes title="noPatientNotesMsg" />
                )}
              </VStack>
            )}
            {careTimelineState?.patientNotes?.length > 0 && (
              <FlatList
                style={styles.flatList}
                nestedScrollEnabled
                keyExtractor={(item) => item?.resourceId}
                ListFooterComponent={
                  careTimelineState.paginationLoading ? (
                    <Center py={2}>
                      <Spinner />
                    </Center>
                  ) : (
                    <></>
                  )
                }
                initialNumToRender={25}
                onEndReached={({distanceFromEnd}) => {
                  if (enablePagination) {
                    setOffset((prev) => {
                      const newOffset = prev + limit;
                      if (total > newOffset) {
                        return newOffset;
                      } else {
                        return prev;
                      }
                    });
                  }
                }}
                contentContainerStyle={{flexGrow: 1, overflow: 'scroll'}}
                data={careTimelineState?.patientNotes}
                renderItem={({item}) => renderPatientNoteCard(item)}
              />
            )}
          </View>
        )}
      </>
    );
  };

  const renderSignedNoteView = () => {
    return (
      <>
        {careTimelineState.currentView === CARE_TIME_LINE_VIEW.SIGNED_NOTE && (
          <SignedNoteView
            personData={personData}
            unFormattedContactData={unFormattedContactData}
            cardProps={careTimelineState.selectedNoteCard}
            ehrConfig={ehrConfig}
            onActionPerformed={(operationCode: string, selectedNote: IPatientNoteCardProps) => {
              if (!goBackHandler && noteDocumentReferenceId && id) {
                if (navigate && typeof navigate === 'function') {
                  navigate(`/members/patient/${id}/timeline`);
                }
                return;
              }
              if (operationCode === PATIENT_NOTE_OPERATION.WITHOUT_REFETCH_LIST_VIEW) {
                setCareTimeline(prev => {
                  return {
                    ...prev,
                    currentView: CARE_TIME_LINE_VIEW.TIMELINE_LIST_VIEW,
                  };
                });
                return;
              }
              patientNoteHandler(operationCode, selectedNote);
            }}
            header={header}
            footer={footer}
            ehrCapabilities={ehrCapabilities}
            accountUserList={accountUserList}
            elationFormData={elationFormData}
            goBackHandler={() => {
              if (!goBackHandler && noteDocumentReferenceId && id) {
                if (navigate && typeof navigate === 'function') {
                  navigate(`/members/patient/${id}/timeline`);
                }
                return;
              }
              if (goBackHandler && typeof goBackHandler === 'function') {
                goBackHandler();
              }
              patientNoteHandler(PATIENT_NOTE_OPERATION.REFETCH, undefined, undefined, true);
            }}
            activeAndNonActiveUserList={activeAndNonActiveUserList}
          />
        )}
      </>
    );
  };

  const renderPatientNoteEditView = () => {
    return (
      <>
        {careTimelineState.currentView === CARE_TIME_LINE_VIEW.ADD_OR_UPDATE_NOTE && (
          <AddOrUpdatePatientNote
            ehrCapabilities={ehrCapabilities}
            personData={personData}
            unFormattedContactData={unFormattedContactData}
            noteData={careTimelineState.selectedNoteCard}
            ehrConfig={ehrConfig}
            onActionPerformed={(operationCode: string, selectedNote: IPatientNoteCardProps, isUpdate?: boolean) => {
              if (!goBackHandler && noteDocumentReferenceId && id) {
                if (navigate && typeof navigate === 'function') {
                  navigate(`/members/patient/${id}/timeline`);
                }
                return;
              }
              patientNoteHandler(operationCode, selectedNote, undefined, isUpdate);
            }}
            isNewNote={patientNoteState.isNewNote}
            isLinkAppointment={patientNoteState.isNoteLinkWithAppointment}
            isPhysicianUser={isPhysicianUser}
            header={header}
            footer={footer}
            defaultTemplate={defaultTemplate}
            accountUserList={accountUserList || []}
            elationFormData={elationFormData}
            goBackHandler={() => {
              if (!goBackHandler && noteDocumentReferenceId) {
                if (navigate && typeof navigate === 'function') {
                  navigate(`/members/patient/${id}/timeline`);
                }
                return;
              }
              if (goBackHandler && typeof goBackHandler === 'function') {
                goBackHandler();
              }
              patientNoteHandler(PATIENT_NOTE_OPERATION.REFETCH, undefined, undefined, true);
            }}
            disableDefaultTemplateChange={false}
            noteLabels={careTimelineState.noteLabels}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    const alwaysFetchInitialTimeline = ehrConfig.isAthena;
    if (!alwaysFetchInitialTimeline && patientNoteState?.selectedFilters && typeof patientNoteState?.selectedFilters == 'object' && Object.keys(patientNoteState?.selectedFilters)?.length) {
      fetchTimelineData(limit, offset, patientNoteState?.selectedFilters, false);
    } else {
      getInitialTimeline(limit, offset, patientNoteState?.selectedFilters || {});
    }
  }, [limit, offset, patientNoteState?.selectedFilters, isFocused]);


  const createNewNotesLabel = async (labelList: string[]): Promise<ILabel[]> => {
    if (!labelList?.length) {
      return [];
    }
    let noteLabels: any[] = [];
    const taskMlovType = (labelTypeMlovList || []).find((labelType) => {
      return labelType?.code === LABEL_TYPE_CODES.TASK;
    });
    const labels: any[] = [];
    labelList.forEach(label => {
      labels.push({
        accountId: accountId,
        color: DEFAULT_TASK_LABEL_COLOR,
        description: '',
        title: label,
      });
    })
    if (taskMlovType?.id) {
      const labelResponse = await getAllLabels({
        variables: {
          names: labelList
        }
      });
      const updatedLabels: ILabel[] = labelResponse?.data?.getLabelsByTitle?.labels || [];
      const labelTypes: any[] = [];
      if (updatedLabels && updatedLabels?.length) {
        noteLabels = updatedLabels;
        (updatedLabels || []).forEach((label: ILabel) => {
          labelTypes.push({
            accountId: accountId,
            labelId: label.id,
            labelTypeId: taskMlovType.id,
            isDeleted: false
          });
        });
      }
      if (labelTypes?.length === labelList?.length) {
        await createLabelType({
          variables: {
            labelTypeList: labelTypes
          }
        });
        return noteLabels as ILabel[];
      } else {
        const filterLabels = (labels || []).filter(labelName => {
          const isExist = (updatedLabels || []).some(label => {
            return label?.title === labelName;
          });
          return isExist ? false : true;
        })
        try {
          const createLabelBody = filterLabels.map((label) => {
            return {
              ...label,
              labelTypeId: taskMlovType?.id
            }
          });
          const response = await createLabelAndLabelType(createLabelBody);
          if (response?.data?.length) {
            const labelsData = response?.data;
            (labelsData || []).forEach((label: ILabel) => {
              noteLabels.push({
                id: label?.id,
                uuid: label?.uuid,
                title: label?.title
              });
            });
            }
        } catch (error: any) {
          const message = error?.response?.data?.message || error?.networkError?.result?.message
          notification.error({
            message: message || intl.formatMessage({id: 'apiErrorMsg'}),
            duration: 5.0,
            placement: 'topRight'
          });
        }
      }
    }
    return noteLabels;
  }


  const getDefaultNoteLabels = async () => {
    const labelTypes: IMlov[] = getMlovListFromCategory(commonContextData.MLOV, MLOV_CATEGORY.LABEL_TYPE) || [];
    const taskLabelType: IMlov | undefined = labelTypes?.find((item) => {
      return item.code === LABEL_TYPE_CODES.TASK;
    });
    let labelList: ILabel[] = [];
    if (taskLabelType) {
      const inputLabelList = [...NOTE_STATUS_LABEL, ...NOTE_SUB_STATUS_LABEL];
      const labelResponse = await getLabels({
        variables: {
          names: inputLabelList,
          labelTypeId: taskLabelType.id
        },
      });
      labelList = labelResponse?.data?.labels || [];
      if (!labelList || !labelList?.length) {
        labelList = await createNewNotesLabel(inputLabelList)
      } else {
        const existLabel: string[] = [];
        (labelList || []).forEach((taskLabel: any) => {
          if (taskLabel?.title) {
            existLabel.push(taskLabel.title);
          }
        });
        if (existLabel?.length) {
          const notExistLabels: string[] = [];
          inputLabelList.forEach(labelName => {
            if (!existLabel.includes(labelName)) {
              notExistLabels.push(labelName);
            }
          });
          if (notExistLabels?.length) {
            labelList = await createNewNotesLabel(notExistLabels);
          }
        }
      }
    }
    return labelList;
  }

  return (
    <>
      {!isWeb() && <IsFocusedView onChange={(value) => setIsFocused(value)} />}
      {careTimelineState.currentView ===
        CARE_TIME_LINE_VIEW.TIMELINE_LIST_VIEW && !isWeb() && renderHeader()}
      <VStack style={styles.container} w={'100%'} mx={isWeb() ? undefined : 5} marginBottom={isWeb() ? 0 : 6}>
      {careTimelineState.currentView ===
        CARE_TIME_LINE_VIEW.TIMELINE_LIST_VIEW && isWeb() && renderHeader()}
        {careTimelineState.loading && <TimeLineSkeletonLoader />}
        {!careTimelineState.loading &&
          (isWeb() ? (
            <div
              ref={listInnerRef}
              style={reactStyles.webContainer}
            >
              {renderTimelineListView()}
              {renderSignedNoteView()}
              {renderPatientNoteEditView()}
              {renderReadOnlyView()}
              {renderEncounterView()}
            </div>
          ) : (
            <VStack flex={1} h="100%">
              {renderTimelineListView()}
              {renderSignedNoteView()}
              {renderPatientNoteEditView()}
              {renderReadOnlyView()}
            </VStack>
          ))}
      </VStack>

      {patientNoteState.isFilterModalOpen && (
        <FilterPatientNotesViewDrawer
          isVisible={patientNoteState.isFilterModalOpen}
          selectedFilters={cloneDeep(patientNoteState.selectedFilters)}
          onClose={() => {
            setPatientNotes((prev) => ({...prev, isFilterModalOpen: false}));
          }}
          onApply={(data) => {
            setOffset(0);
            setTotal(0);
            setPatientNotes((prev) => ({
              ...prev,
              selectedFilters: data,
              isFilterModalOpen: false,
              filterTags: getFilterTags(data),
              filterCount: getFilterCount(data),
            }));
          }}
          locationId={personData?.contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid}
        />
      )}

      {patientNoteState.isConfirmationModalOpen && (
        <FHAlertDialog
          showVerticalButtons={!isWeb()}
          isOpen={patientNoteState.isConfirmationModalOpen}
          header={'Create new note?'}
          message={intl.formatMessage({id: 'todayInprogressNoteMsg'})}
          buttonActions={[
            {
              textLocalId: 'cancel',
              buttonProps: {
                variant: BUTTON_TYPE.SECONDARY,
                flex: 1,
              },
              onPress: () => {
                setPatientNotes((prev) => ({
                  ...prev,
                  isConfirmationModalOpen: false,
                }));
              },
            },
            {
              textLocalId: 'createNewButtonLabel',
              buttonProps: {
                variant: BUTTON_TYPE.PRIMARY,
              },
              onPress: () => {
                setPatientNotes((prev) => ({
                  ...prev,
                  isConfirmationModalOpen: false,
                }));
                patientNoteHandler(
                  PATIENT_NOTE_OPERATION.CREATE_NOTE,
                  undefined
                );
              },
            },
            {
              textLocalId: 'openProgressNoteLabel',
              buttonProps: {
                variant: BUTTON_TYPE.PRIMARY,
              },
              onPress: () => {
                setPatientNotes((prev) => ({
                  ...prev,
                  isConfirmationModalOpen: false,
                }));
                patientNoteHandler(
                  PATIENT_NOTE_OPERATION.EDIT_INPROGRESS_NOTE,
                  patientNoteState.todayInprogressNote
                );
              },
            },
          ]}
        />
      )}
    </>
  );
};
