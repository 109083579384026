import { IPowerBiAccountReports, IPowerBiWorkspaceReport} from '../../../../../services/PowerBI/interface';
import {LazyQueryExecFunction} from '@apollo/client';
import {IUserRoleCode} from '../../../../../Interfaces';
import {IPayer} from '../../../../../services/MarketCost/interface';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {getPowerBITokenInfoForReport, getPowerBIWorkspaces} from '../../../../../services/PowerBI/PowerBIService';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {DEFAULT_EMPLOYER_REPORTING_CODE} from '../../../../../constants';
import {EMPLOYER_DASHBOARD_TABS} from '../../../Analytics/EmployerAnalytics/EmployerUtils/EmployerConst';

export interface AnalyticsState {
  list: Employer[];
  loading: boolean;
  selected: Employer;
  selectedPowerBiAccountReport: IPowerBiWorkspaceReport;
  dateRangeType?: string;
  saveDashboardView?: JSX.Element;
  dashboardAction?: boolean;
  employerCount?: any
  sectionUpdateAction?: boolean;
  allPayerList?: IPayer[];
  powerBiAccountReports?: IPowerBiAccountReports[],
  powerBiReportMode?: PowerBiReportMode
}

export enum PowerBiReportMode {
  VIEW = "view",
  EDIT = "edit"
}

export interface AnalyticsControllerArgs {
  tenantId: string;
  toast: any;
  state: AnalyticsState;
  userEmployerId: string;
  getEmployers: LazyQueryExecFunction<
    any,
    {
      accountUuid: string;
      query: string;
    }
  >;
  getAllPayersFromApi?: LazyQueryExecFunction<any, any>;
  userRoles: IUserRoleCode[];
  setState: React.Dispatch<React.SetStateAction<AnalyticsState>>;
  id: string;
  isFromEmployerListing: boolean;
  isEnablePowerBiAnalytics: boolean;
  isLoggedInUserWorkFlowUser: boolean;
  restrictedEmployeeProfileData?: any;
  logedInUserUuid?: string;
}

export interface Employer {
  id: string;
  name: string;
  email?: string;
  phoneNumber?: string;
  isDeleted?: boolean;
  address?: any[];
  employerDeal?: any[];
  employee_aggregate?: {
    aggregate: {
      count: number
    }
  };
  employerPriceSourceData?: {
    id: string;
    payerId: string;
    carrierId: string;
    priceSource: string;
  }
}

export class AnalyticsController {
  private state: AnalyticsState;
  private tenantId: string;
  private toast: any;
  private userRoles: IUserRoleCode[];
  private userEmployerId: string;
  private setState: React.Dispatch<React.SetStateAction<AnalyticsState>>;
  private isFromEmployerListing: boolean;
  private isEnablePowerBiAnalytics: boolean;
  private isLoggedInUserWorkFlowUser: boolean;
  private restrictedEmployeeProfileData?: any;
  private logedInUserUuid?: string;
  getEmployers: LazyQueryExecFunction<
    any,
    {
      accountUuid: string;
      query: string
    }
  >;
  getAllPayersFromApi: any;
  showSkeleton: boolean;
  private id: string;

  constructor(args: AnalyticsControllerArgs) {
    this.state = args.state;
    this.tenantId = args.tenantId;
    this.toast = args.toast;
    this.userRoles = args.userRoles;
    this.userEmployerId = args.userEmployerId;
    this.showSkeleton = this.state.loading;
    this.setState = args.setState;
    this.getEmployers = args.getEmployers;
    if (args.getAllPayersFromApi) {
      this.getAllPayersFromApi = args.getAllPayersFromApi;
    } else {
      this.getAllPayersFromApi = null;
    }
    this.id = args.id;
    this.isFromEmployerListing = args.isFromEmployerListing;
    this.isEnablePowerBiAnalytics = args.isEnablePowerBiAnalytics;
    this.isLoggedInUserWorkFlowUser = args.isLoggedInUserWorkFlowUser;
    this.restrictedEmployeeProfileData = args?.restrictedEmployeeProfileData;
    this.logedInUserUuid = args?.logedInUserUuid;
    // binding
    this.onMount = this.onMount.bind(this);
    this.getEmployerList = this.getEmployerList.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.isEmployerRole = this.isEmployerRole.bind(this);
    this.getSelectedEmployerOnMount =
      this.getSelectedEmployerOnMount.bind(this);
  }

  onMount() {
    this.getEmployerList();
  }

  getDefaultEmployerReportDummyWorkSpace = () => {
  const dateStr = new Date().toISOString();
    return {
      id: '',
      additionalAttributes: {},
      accountUuid: this.tenantId,
      powerBiWorkspacesReports: [
        {
            id: 'eee8ae3e-f3a6-4aed-8157-cc8f4ce95eee',
            name: 'Employer Report',
            code: DEFAULT_EMPLOYER_REPORTING_CODE,
            description: DEFAULT_EMPLOYER_REPORTING_CODE,
            isDefault: false,
            version: 'V1',
            workspaceId: '',
            powerBiWorkspaceId: '',
            powerBiReportId: '',
            additionalAttributes: {},
            isDeleted: false,
            createdAt: dateStr,
            updatedAt: dateStr
        }
    ]
  };
  }
  getDefaultEmployerTabS = () => {
  const dateStr = new Date().toISOString();
    return {
      id: '',
      additionalAttributes: {},
      accountUuid: this.tenantId,
      powerBiWorkspacesReports: EMPLOYER_DASHBOARD_TABS.map((tab) => ({
        id: tab.key,
        name: tab.title,
        code: DEFAULT_EMPLOYER_REPORTING_CODE,
        description: DEFAULT_EMPLOYER_REPORTING_CODE,
        isDefault: false,
        version: 'V1',
        workspaceId: '',
        powerBiWorkspaceId: '',
        powerBiReportId: '',
        additionalAttributes: {},
        isDeleted: false,
        createdAt: dateStr,
        updatedAt: dateStr,
        childSection: tab.childSection
      }))
    };
  }

  private async getEmployerList() {
    this.setLoading(true);
    try {
      const finalPowerBiWorkspaces: any[] = [];
      let powerBiWorkspaces: IPowerBiAccountReports[] = [];
      const employerReportingDefaultDummyWorkspace = this.getDefaultEmployerReportDummyWorkSpace();
      const employerDashboardTabs = this.getDefaultEmployerTabS().powerBiWorkspacesReports
      const response = await this.getEmployers();
      const employersList: Employer[] = response?.data?.employers || [];
      let employers: Employer[] = employersList?.sort((a: any, b: any) => {
        return (a.name || '')?.localeCompare(b.name || '')
      })
      // if(getTenantForDemo()) {
      //   employers.unshift(TEMP_EMP_TWO)
      //   // employers.unshift(TEMP_EMP_ONE)
      // }
      let allPayerList: any[] = [];
      if (this.getAllPayersFromApi) {
        const payerResponse = await this.getAllPayersFromApi();
        if (payerResponse?.data?.payers) {
          allPayerList = payerResponse?.data?.payers;
        }
      }
      if (this.isEnablePowerBiAnalytics && !this.isFromEmployerListing) {
        const powerBIWorkspacesResp = await getPowerBIWorkspaces();
        if (powerBIWorkspacesResp?.data?.powerBiAccountReports?.length) {

          powerBIWorkspacesResp?.data?.powerBiAccountReports.forEach((singleData)=> {
              if (singleData?.id) {
              finalPowerBiWorkspaces.push(singleData?.powerBiWorkspacesReports);
            }
          });
          if (employersList?.length > 0) {
            powerBiWorkspaces = [employerReportingDefaultDummyWorkspace?.powerBiWorkspacesReports[0], ...employerDashboardTabs , ...finalPowerBiWorkspaces];
          } else {
            powerBiWorkspaces = [...finalPowerBiWorkspaces];
          }
          if(!this.isLoggedInUserWorkFlowUser) {
            powerBiWorkspaces = powerBiWorkspaces.filter((singleReport) => {
              if (!singleReport?.additionalAttributes?.isHide) {
                return true;
              }
            });
          }
        }
      } else {
        powerBiWorkspaces = [employerReportingDefaultDummyWorkspace?.powerBiWorkspacesReports[0], ...employerDashboardTabs, ...finalPowerBiWorkspaces];
      }
      if (
        this.restrictedEmployeeProfileData
          ?.isRestrictedParticularLocationEmployeeEnabled
      ) {
        // Split the comma-separated string into an array
        const allowedUserUuids =
          this.restrictedEmployeeProfileData?.loggedInUserUuidForShowAllEmployer?.flatMap(
            (id: any) => (id.includes(',') ? id.split(',') : id)
          );
        const restrictedEmployerIds =
          this.restrictedEmployeeProfileData?.restrictedEmployerIds?.[0]?.split(
            ','
          ) || [];
        if (
          restrictedEmployerIds?.length &&
          allowedUserUuids?.length &&
          !allowedUserUuids?.includes(
            this.logedInUserUuid
          )
        ) {
          // Filter out restricted employers
          employers = employers?.filter(
            (singleEmployer) =>
              !restrictedEmployerIds?.includes(singleEmployer?.id)
          );
        }
      }
      const selected = this.getSelectedEmployerOnMount(
        employers,
        this.id || this.userEmployerId
      );
      if (this.isEnablePowerBiAnalytics) {
        const selectAll = {
          id: 'SELECT_ALL',
          name: 'All Employers',
        } as any;
        employers.unshift(selectAll);
      }
      if (finalPowerBiWorkspaces?.length) {
        const tokenPromises = finalPowerBiWorkspaces.map(workspace =>
          getPowerBITokenInfoForReport({
            reportId: workspace.powerBiReportId,
            workspaceId: workspace.powerBiWorkspaceId
          }).catch(() => null)
        );

        const tokenResults = await Promise.all(tokenPromises);
        powerBiWorkspaces = powerBiWorkspaces?.map((report: any, index) => {
          if (index === 0) return report; // Skip first index

          // Find matching token result by reportId
          const matchingToken = tokenResults.find(token =>
            token?.data?.reportId === report.powerBiReportId
          );

          if (matchingToken) {
            return {
              ...report,
              tokenData: matchingToken.data
            };
          }
          return report;
        });
      }

      this.setState((prev) => {
        return {
          ...prev,
          list: employers,
          loading: false,
          selected: selected,
          allPayerList: allPayerList,
          powerBiAccountReports: powerBiWorkspaces && powerBiWorkspaces.length > 0 ? powerBiWorkspaces : prev.powerBiAccountReports,
          selectedPowerBiAccountReport: powerBiWorkspaces &&  powerBiWorkspaces.length > 0 ? powerBiWorkspaces[0] as any : prev.selectedPowerBiAccountReport
        };
      });


    } catch (error) {

      this.setLoading(false);
      showToast(this.toast, 'Error in getting employers', ToastType.error);
    }
  }

  private setLoading(value: boolean) {
    this.setState((prev) => {
      return {
        ...prev,
        loading: value,
      };
    });
  }

  setSelected(value: string) {
    const selected = this.state.list.find((item) => item.id == value);
    this.setState((prev) => {
      return {
        ...prev,
        selected: selected || ({} as Employer),
      };
    });
  }

  private getSelectedEmployerOnMount(list: Employer[], id: string): Employer {

    const isSelectedId = this.isEmployerRole() || this.isFromEmployerListing

    if (isSelectedId && (this.userEmployerId.length > 0 || id.length > 0)) {
      const selected = list.find((item) => {
        return item.id == id || item.id == this.userEmployerId
      }
      );
      return selected || ({} as Employer);
    }
    return list[0];


  }

  isEmployerRole() {
    return this.userRoles.some((item) => item.code == 'EMPLOYER');
    // return this.userRoles.some((item) => true);
  }
}
