import * as React from 'react';
export interface IDashboardContextData {
  fromDate: any;
  toDate: any;
  dateRangeType: string
  employeeCount?: number
  uiFromDate?: string,
  uiToDate?: string;
  employerName?: string;
  accountPracticeLocations?: any;
  locations?: {
    locationGroupIds: string[];
    locationIds: string[];
  };
}

export const defaultDashboardDataContext: IDashboardContextData = {
  fromDate: '' as any,
  toDate: '' as any,
  dateRangeType: '',
  locations: {
    locationGroupIds: [],
    locationIds: []
  },
  // employeeCount: 0
};

export const DashboardDataContext = React.createContext(
  defaultDashboardDataContext
);
