import {ANALYTICS_RESOURCE, EMPLOYER_REPORT_FILTER_CODES} from './../../../constants/StringConst';
import moment from "moment";
import {IDateRangeEmp} from "../../../components/RightSideContainer/Analytics/EmployerAnalytics/interface";
import {IFile} from "../../../components/RightSideContainer/TeamInbox/Conversations/interfaces";
import {DATE_FORMATS} from "../../../constants";
import {isWeb} from "../../../utils/platformCheckUtils";
import BaseService from "../../CommonService/BaseService";
import {EMPLOYER_BASE_PATHS} from "./baseUrlPath";
import {SAVING_WIDGET_LIST} from '../../../components/RightSideContainer/Analytics/EmployerAnalytics/EmployerUtils/EmployerConst';


export const getSectionDataByResourceCode = async (
  employerId?: string,
  resourceCode?: string,
  filterData?: any[],
  formId?: string,
  isAccountDashboard?: boolean,
  memberType?: string,
  sectionId?: string,
  isNewCostSaving?: boolean
) => {
  let body: any = {
    resourceCode: resourceCode,
    employerId: employerId,
    isAccountDashboard: isAccountDashboard ? true : false,
    memberType: memberType,
    sectionId: sectionId
  }
  if (formId) {
    body.formId = formId;
  }
  if (filterData) {
    body = {...body, appliedFilter: filterData}
  }
  const baseService = BaseService.getSharedInstance().axios;
  const isSavingWidget = !isAccountDashboard && isNewCostSaving && SAVING_WIDGET_LIST.includes(resourceCode as string)
  return await baseService.post(
    `${EMPLOYER_BASE_PATHS.SECTION_URL_PATH}/section-data${isSavingWidget ? '/v2' : ''}?${resourceCode}`,
    {...body}
  );
};


export const uploadEmployerLogo = async (file: any, employerId: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('employerId', employerId)

  return await baseService.post(
    `/crm-nest/api/attachment/employer-logo`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
}


export const drillDownByEmployerId = async (
  resourceCode: string,
  employerId: string,
  pageNo: number,
  pageSize: number,
  data: any,
  dateRange?: IDateRangeEmp
) => {
  const baseService = BaseService.getSharedInstance().axios;
  let appliedFilter: any[] = [];
  const filterData = {
    filterCode: 'DATE_RANGE',
    filterValue: {
      fromDate: moment(dateRange?.uiFromDate).format(
        `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`
      ),
      toDate: moment(dateRange?.uiToDate).format(
        `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`
      ),
    },
  };
  if (data?.location?.locationIds?.length) {
    appliedFilter.push({
      filterCode: EMPLOYER_REPORT_FILTER_CODES.LOCATION_IDS,
      filterValue: {
        value: data?.location?.locationIds
      }
    })
    appliedFilter.push({
      filterCode: EMPLOYER_REPORT_FILTER_CODES.LOCATION_GROUP_IDS,
      filterValue: {
        value: data?.location?.locationGroupIds
      }
    })
  }
  if (data?.filterData?.length) {
    appliedFilter = [...appliedFilter, ...data?.filterData];
  } else {
    appliedFilter.push(filterData)
    if (data?.filterData) {
      appliedFilter.push(data?.filterData);
    }
  }

  const body = {
    resourceCode: resourceCode,
    employerId: employerId,
    paging: {
      pageNo: pageNo,
      pageSize: pageSize,
    },
    appliedFilter: [...appliedFilter],
  };
  return baseService.post(`/crm-nest/account/api/employer/drill-down-data`, {
    ...body
  });
};

export const updateSectionViewAndPosition = (data: any[]) => {
  const baseService = BaseService.getSharedInstance().axios;
  const body = {
    updateSectionArray: [...data]
  }
  return baseService.put(`/crm-nest/account/api/employer/update-section`, {
    ...body
  })
}

export const getCptOrRevenueCode = (
  valueType: string,
  searchString: string
) => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.get(
    `/crm-nest/account/api/employer/primary-value?valueType=${valueType}&codeValue=${searchString}`
  );
};

export const getChronicConditionList = (
  searchString: string
) => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.get(
    `/crm-nest/account/api/employer/chronic-conditions?searchString=${searchString}`
  );
};
