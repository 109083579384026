import { useLazyQuery, useMutation } from '@apollo/client';
import { Drawer, notification, Table } from 'antd';
import { HStack, Icon, Pressable, Text, VStack, View, useToast } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import { useParams } from 'react-router-dom';
import '../../../../../src/components/RightSideContainer/Contacts/Leads/LeadView/LeadTableView/LeadListView/tableViewCustomStyle.css';
import { PARENT_CODE, PERSON_TYPES, RIGHT_SIDE_CONTAINER_CODE } from '../../../../constants';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES
} from '../../../../constants/ActionConst';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { FHIR_RESOURCE } from '../../../../constants/FhirConstant';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { CareJourneyQueries, LeadQueries } from '../../../../services';
import { getHintPlansByEmployerId } from '../../../../services/CommonService/AidBoxService';
import EmployeeQueries from '../../../../services/Employee/EmployeeQueries';
import { Colors } from '../../../../styles';
import { getAccountConfigResourceAbilities, getResourceAbilities } from '../../../../utils/capabilityUtils';
import { checkThisEmployeeIsRestricted, findIsAllowToRunAutomation, getAccountUUID, getAllowedUserAccountLocationUuids, getBooleanFeatureFlag, getFeatureFlag, getUserActionObjectFromActionCode, getUserUUID, isAllowToShowExternalPatientId, isEmployerRole, isMasterAccount } from '../../../../utils/commonUtils';
import { getContactTypeId } from '../../../../utils/mlovUtils';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import DeactivateContactView from '../../../common/DeactivateContactView/DeactivateContactView';
import { DependentList } from '../../../common/DependentList';
import { DisplayText } from '../../../common/DisplayText/DisplayText';
import EmailDrawerCommon from '../../../common/EmailPopupView/EmailDrawerCommon';
import PageBodyContainer from '../../../common/PageBodyContainer/PageBodyContainer';
import { SMSDrawer } from '../../../common/SMSDrawer';
import {
  TABLE_TOP_BAR_ACTION_CODES,
  TableTopBar
} from '../../../common/TableTopBar';
import { AddOrUpdateLead } from '../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import { removeDuplicates } from '../../Contacts/Leads/LeadView/LeadTableView/LeadListView/LeadListHelper';
import AddOrUpdateCampaign from '../../ContentManagement/CampaignManagement/AddOrUpdateCampaign/AddOrUpdateCampaign';
import MessagingContactDetailsDrawer from '../../TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import { USER_ACCESS_PERMISSION, isAutomationAllowed } from '../../UserAccess/UserAccessPermission';
import { createCampaignSegment, getCampaignSegmentRules } from '../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { AutomationWorkflowEmployee } from '../AutomationWorkflowEmployee';
import { BulkImportEmployee } from '../BulkImportEmployee';
import { CreateGroupModal } from '../CreateGroup';
import { DeleteEmployeeModal } from '../DeleteEmployeeModal';
import {
  addEmployeeCampaigns,
  addEmployeeCareJourneys,
  getFormattedEmployees,
  getHintPlanFilters,
  getMembersColumns,
  getPreFilterMemberStatus,
  patientFilterForCreateSegment
} from '../Helper';
import { TYPE_STATUS_FILTERS, getContactSMSDataFromRawData, getSearchContactActionFilter, getWhereConditionForEmployees, hasClickedOnDependentCount } from '../MembersManagementHelper';
import { IFilter, IMembersData, IMembersTableColumnsArgs } from '../interfaces';
import { styles } from './MembersTableStyles';
import { EmailDrawerCommonV2 } from '../../../common/EmailDrawerCommonV2';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
import {CampaignSegmentForm} from '../../ContentManagement/CampaignManagement/CampaignSegmentForm';
import {CampaignSegmentTableContext, ICampaignSegmentFormState} from '../../ContentManagement/CampaignManagement/interfaces';
import {IContact} from '../../TeamInbox/Conversations/interfaces';
import {getFrequencyValue} from '../../ContentManagement/CampaignManagement/utils';
import {handleCreateMemberGroupAction} from '../../../common/UsageLevel/UsageLevelHandlers';
import { showToast } from '../../../../utils/commonViewUtils';
import { ToastType } from '../../../../utils/commonViewUtils';
import { useRestrictedEmployeeProfile } from '../../../../utils/useRestrictedEmployeeProfile';

const {height, width} = Dimensions.get('window');

const MembersManagementMainView = (props: any) => {

  const {id} = useParams();
  const isMasterAccountFlag = isMasterAccount()
  const [showAddOrUpdateMember, setShowAddOrUpdateMember] = useState(false);
  const DEFAULT_PAGE_SIZE = 8;
  const DEFAULT_PAGE = 1;
  const [membersTableState, setMembersTableState] = useState({
    isLoading: true,
    selectedItems: [] as any,
    selectedRowKey: [] as any,
    membersTableData: [] as IMembersData[],
    membersTableDataRaw: [] as IMembersData[],
    total: DEFAULT_PAGE_SIZE,
    sort: 'desc',
    selectedViewCode: '',
    isDependentListOpen: false,
    contactId: 0,
    planFilters: [] as IFilter[],

    // filters
    selectedPlans: [] as string[],
    selectedStatus: [] as string[],
    type: [] as string[],
    searchString: '',
    startFrom: '',
    startTo: '',
    endFrom: '',
    endTo: '',
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const intl = useIntl()
  const toast = useToast();


  const finalWidth = width / 2.8;
  const isUserWithEmployerRole = isEmployerRole()

  // const contactTypeUuid = getContactTypeId('CUSTOMER');
  const contactTypeUuid = getContactTypeId('CUSTOMER');
  const hintCapability = getAccountConfigResourceAbilities(FHIR_RESOURCE.HINT);
  const commonData = useContext(CommonDataContext);
  const userSettings = commonData.userSettings;
  const allowToShowExternalPatientId = isAllowToShowExternalPatientId(userSettings);
  const isVbcTenant = getFeatureFlag(CONFIG_CODES.IS_VBC_TENANT, commonData);
  const restrictedEmployeeProfileData = useRestrictedEmployeeProfile();
  const openInHint = hintCapability?.openInHint || false;
  const isMultiTenancyEnabled = getBooleanFeatureFlag(
    commonData.userSettings,
    FeatureFlags.IS_MULTI_TENANCY_ENABLED
  );
  const userActionObject = getUserActionObjectFromActionCode(
    'CREATE_A_MEMBER_GROUP'
  );
  const allowedUserAccountP360LocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.P360_CARE_JOURNEY
  );
  const level = userActionObject.level || '';
  const userUuid = getUserUUID()
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openConsentModal, setOpenConsentModal] = useState(false);
  const [openGroupModal, setOpenGroupModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [campaignDrawerState, setCampaignDrawerState] = useState<any>({
    isVisible: false,
    segmentId: null,
  });
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedGroupRowContactData, setSelectedGroupRowContactData] =
    useState<any[]>();
  const [topbarBtnConfig, setTopbarBtnConfig] = useState({
    hideAddButton: false,
  });
  const [selectedRowData, setSelectedRowData]: any = useState({} as any);
  const [selectedRowEmailData, setSelectedRowEmailData]: any = useState(
    [] as any
  );
  const locationId = selectedRowData?.contactPracticeLocations?.[0]?.accountLocation?.uuid;
  const tenantId = getAccountUUID();

  const [getEmployee] = useLazyQuery(EmployeeQueries.getEmployee, {
    fetchPolicy: 'no-cache',
  });
  const [getEmployeeBySearchContactActions] = useLazyQuery(EmployeeQueries.getEmployeeBySearchContactAction,{
    fetchPolicy: 'no-cache',
  })
  const handleDeleteEmployee = () => {
    deleteEmployees({
      variables: {employeeIdList: membersTableState.selectedRowKey},
    }).then(() => {
      setTimeout(() => {
        loadMembers();
      }, 2000);
    });
  };

  const [deleteEmployees] = useMutation(EmployeeQueries.deleteEmployees, {
    fetchPolicy: 'no-cache',
  });

  const [deleteEmployee] = useMutation(EmployeeQueries.deleteEmployee, {
    fetchPolicy: 'no-cache',
  });

  const [getContact] = useLazyQuery(LeadQueries.GetContact, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });

  const [getAllPatientCareJourneys] = useLazyQuery(
    CareJourneyQueries.GET_ALL_PATIENT_CARE_JOURNEYS,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const filterAndMapIdsAndLocations = () => {
    const seenLocationIds = new Set<string>();
    const ids = [];
    const locationIds = [];
    const uuidSet = new Set(membersTableState.selectedRowKey);
    const memberList = membersTableState.membersTableData;
    for (const member of memberList) {
      const employeeId = member?.id;
      if (employeeId && uuidSet.has(employeeId)) {
        const locationId =
          member.contact?.contactPracticeLocations?.[0]?.accountLocation
            ?.uuid || null;
        if (locationId && !seenLocationIds.has(locationId)) {
          seenLocationIds.add(locationId);
          ids.push(member.contact?.id);
          locationIds.push(locationId);
        }
      }
    }
    return {ids, locationIds};
  };
  
  const getHintConfig = async () => {
    setTopbarBtnConfig((prev) => {
      return {
        ...prev,
        hideAddButton: openInHint
      }
    })
  }

  useEffect(() => {
    if(locationId) {
      getHintPlansByEmployerId(id || '', locationId).then((response) => {
        const planFilters = getHintPlanFilters(response.data);
        setMembersTableState((prev) => {
          return {
            ...prev,
            planFilters: planFilters,
          };
        });
      });
      loadMembers();
    }
  }, [locationId]);
  useEffect(()=> {
    if (isVbcTenant) {
      setTopbarBtnConfig((prev) => {
        return {
          ...prev,
          hideAddButton: true
        }
      })
    } else {
      getHintConfig();
    }
  },[])
  useEffect(() => {
    loadMembers();
  }, [
    membersTableState.searchString,
    membersTableState.page,
    membersTableState.pageSize,
    membersTableState.type,
    membersTableState.startFrom,
    membersTableState.startTo,
    membersTableState.endFrom,
    membersTableState.endTo,
    membersTableState.selectedPlans.length,
    membersTableState.selectedStatus.length
  ]);

  const [openModal, setOpenModal] = useState({
    type: '',
    data: null as any,
  });
  const fecthEmployeeBySearchContactActions = async () => {
    setMembersTableState((prev) => ({
      ...prev,
      isLoading: true,
    }));
    const searchEmplyeeFilter = getSearchContactActionFilter({
      employerId: id || '',
      searchString: `${membersTableState.searchString}`,
      type: membersTableState.type,
      startFrom: membersTableState.startFrom,
      startTo: membersTableState.startTo,
      endFrom: membersTableState.endFrom,
      endTo: membersTableState.endTo,
      selectedPlans: membersTableState.selectedPlans || [],
      selectedStatus: membersTableState.selectedStatus || [],
    });
    searchEmplyeeFilter.limit = membersTableState.pageSize,
    searchEmplyeeFilter.offset = membersTableState.pageSize * (membersTableState.page - 1)
    const employeeResponse = await getEmployeeBySearchContactActions({
      variables: {
        params: searchEmplyeeFilter,
      },
    });
    let rawData: IMembersData[] =
      getFormattedEmployees(employeeResponse?.data?.searchContacts?.contacts) || [];
    rawData = await addEmployeeCareJourneys({
      employeeData: rawData,
      getAllPatientCareJourneys,
      isMultiTenancyEnabled: isMultiTenancyEnabled,
      accountLocationUuids: allowedUserAccountP360LocationUuids,
    });
    rawData = await addEmployeeCampaigns({employeeData: rawData});
    const total = employeeResponse?.data?.searchContacts?.contactAggregate?.aggregate?.count;
    setMembersTableState((prev) => ({
      ...prev,
      total: total,
      membersTableData: rawData,
      membersTableDataRaw: rawData,
    }));
    setMembersTableState((prev) => ({
      ...prev,
      isLoading: false,
    }));
  };
  const loadMembers = (): void => {
    fecthEmployeeBySearchContactActions()
  };

  const onRowClicked = async (rowData: IMembersData) => {
    const patientListRespData = await getContact({
      variables: {
        id: rowData?.contact?.id,
      },
    });
    setSelectedRowData(patientListRespData.data.contact);
    setOpenDetailsModal(true);
  };

  const getGroupFilter = () => {
    const contactIdList: any = [];
    (membersTableState?.selectedItems || []).map((data: any) => {
      const contactIds = data?.contact?.id;
      contactIdList.push(contactIds);
    });
    const selectedContact = {
      patientFilter: {
        contactIds: contactIdList,
      },
    };

    return selectedContact;
  };

  const onMembersTableActionPerformed = async (
    actionCode: string,
    actionData: IMembersData,
    status?: boolean
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.DEACTIVATE:
        setSelectedRowData(actionData);
        setMembersTableState(prev => {
          return {
            ...prev,
            selectedViewCode: RIGHT_SIDE_CONTAINER_CODE.CONTACT_DEACTIVATE_VIEW
          }
        })
        break;
      case COMMON_ACTION_CODES.ROW_CLICKED:
        onRowClicked(actionData);
        break;
      case COMMON_ACTION_CODES.DELETE:
        const id = actionData.id;
        deleteEmployee({
          variables: {
            employeeId: id,
          },
        })
          .then((resp: any) => {
            loadMembers();
          })
          .catch((e) => {
            //
          });
        break;
      case COMMON_ACTION_CODES.EDIT_MEMBER:
        // onRowClicked(actionData);
        const patientListRespData = await getContact({
          variables: {
            id: actionData?.contact?.id,
          },
        });
        setSelectedRowData(patientListRespData.data.contact);
        setShowAddOrUpdateMember(true);
        break;
      default:
        setSelectedRowData({});
        setMembersTableState(prev => {
          return {
            ...prev,
            selectedViewCode: ''
          }
        })
    }
  };

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.BULK_IMPORT:
        setOpenModal({
          type: 'bulkImportEmployee',
          data: null,
        });
        break;
      case COMMON_ACTION_CODES.DELETE:
        if (membersTableState.selectedItems?.length) {
          setOpenDeleteModal(true);
        }
        break;
      case COMMON_ACTION_CODES.REQUEST_CONSENT:
        if (membersTableState.selectedItems?.length) {
          setOpenModal({
            type: 'automationWorkflow',
            data: null,
          });
        }
        break;
      case COMMON_ACTION_CODES.ADD_MANUALLY:
        setSelectedRowData(null);
        setShowAddOrUpdateMember(true);
        break;
      case COMMON_ACTION_CODES.CREATE_CAMPAIGN:
        if (membersTableState.selectedItems?.length) {
          const contactIds = (membersTableState?.selectedItems || []).map(
            (data: any) => {
              return data?.contact?.id;
            }
          );
          createCampaignSegment({
            name: '',
            tenantId: tenantId,
            metadata: {
              patientFilter: {
                employerIds: [id],
                contactIds: contactIds,
              },
            },
          }).then((resp) => {
            if (resp?.id) {
              setCampaignDrawerState({
                isVisible: true,
                segmentId: resp?.id,
              });
            }
          });
        }
        break;
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setMembersTableState((prev) => {
          return {
            ...prev,
            page: DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
            searchString: actionData?.searchString || '',
          };
        });
        break;
      case COMMON_ACTION_CODES.CREATE_GROUP:
        setOpenGroupModal(!openGroupModal);
        break;
      case COMMON_ACTION_CODES.MAIL:
        bulkEmailAction();
        break;
      case COMMON_ACTION_CODES.SMS:
        setMembersTableState((prev) => {
          return {
            ...prev,
            selectedViewCode: RIGHT_SIDE_CONTAINER_CODE.SEND_SMS_DRAWER,
          };
        });
        break;
    }
  };

  const bulkEmailAction = () => {
    const tempMemebersArray = membersTableState.selectedItems;
    const emailDataArray: any = [];
    const contactDataArray: any[] = [];
    tempMemebersArray.forEach((member: any) => {
      emailDataArray.push(member?.contact?.email);
      contactDataArray.push(member?.contact);
    });
    if (emailDataArray?.length) {
      setOpenEmailModal(true);
      setSelectedRowEmailData(emailDataArray);
      setSelectedGroupRowContactData(contactDataArray);
    }
  };

  const MembersTableColumnArgs: IMembersTableColumnsArgs = {
    actionFn: onMembersTableActionPerformed,
    planFilters: membersTableState.planFilters,
    openInHint: openInHint,
    selectedStatus: membersTableState.selectedStatus,
    selectedPlanNames: membersTableState.selectedPlans,
    typeFilters: membersTableState.type,
    statusFilters: TYPE_STATUS_FILTERS,
    onStartDateRangeChange(startFrom, startTo) {
      onStartDateRangeChange(startFrom, startTo);
    },
    onEndDateRangeChange(endFrom, endTo) {
      onEndDateRangeChange(endFrom, endTo);
    },
    isVbcTenant: isVbcTenant || false,
    allowToShowExternalPatientId: allowToShowExternalPatientId
  };

  const onStartDateRangeChange = (startFrom: string, startTo: string) => {
    setMembersTableState(prev => {
      return {
        ...prev,
        startFrom,
        startTo
      }
    })
  }

  const onEndDateRangeChange = (endFrom: string, endTo: string) => {
    setMembersTableState(prev => {
      return {
        ...prev,
        endFrom,
        endTo
      }
    })
  }

  const importMembersQuickActionList = [
    {
      displayString: 'bulkImportText',
      actionCode: COMMON_ACTION_CODES.BULK_IMPORT,
    },
    {
      displayString: 'addManually',
      actionCode: COMMON_ACTION_CODES.ADD_MANUALLY,
    },
    {
      displayString: 'connectToHint',
      actionCode: COMMON_ACTION_CODES.CONNECT_TO_HINT,
    },
  ];

  const bulkImportContent = (
    <VStack style={{padding: 0, borderRadius: 16}}>
      {importMembersQuickActionList.map((action: any, index: any) => {
        return (
          <Pressable
            key={index}
            onPress={() => {
              onTableTopBarActionPerformed(action.actionCode, null);
            }}
          >
            <HStack style={[styles.mainMenuContent, styles.firstMenu]}>
              <Text style={styles.mainMenuTxt}>
                <DisplayText
                  size={'smMedium'}
                  textLocalId={action.displayString}
                />
              </Text>
            </HStack>
          </Pressable>
        );
      })}
    </VStack>
  );

  const showAutomationTab = isAutomationAllowed();

  const bulkActionQuickActionList = [
    ...(showAutomationTab && findIsAllowToRunAutomation()
      ? [
        {
          displayString: 'runAutomation',
          actionCode: COMMON_ACTION_CODES.REQUEST_CONSENT,
        },
      ]
      : []),
    {
      displayString: 'createCampaign',
      actionCode: COMMON_ACTION_CODES.CREATE_CAMPAIGN,
    },
    {
      displayString: 'createGroup',
      actionCode: COMMON_ACTION_CODES.CREATE_GROUP,
    },
    {
      displayString: 'sendEmail',
      actionCode: COMMON_ACTION_CODES.MAIL,
    },
    {
      displayString: 'sendSms',
      actionCode: COMMON_ACTION_CODES.SMS,
    },
    {
      displayString: 'delete',
      actionCode: COMMON_ACTION_CODES.DELETE,
    },
  ];

  const bulkActionContent = (
    <VStack style={{padding: 0, borderRadius: 16}}>
      {bulkActionQuickActionList.map((action: any, index: any) => {
        return (
          <Pressable
            key={index}
            onPress={() => {
              onTableTopBarActionPerformed(action.actionCode, null);
            }}
          >
            <HStack style={[styles.mainMenuContent, styles.firstMenu]}>
              <Text style={styles.mainMenuTxt}>
                <DisplayText
                  size={'smMedium'}
                  textLocalId={action.displayString}
                />
              </Text>
            </HStack>
          </Pressable>
        );
      })}
    </VStack>
  );

  const onDrawerContent = (type?: string): JSX.Element => {
    let element: JSX.Element = <></>;
    switch (type) {
      case 'bulkImportEmployee':
        element = (
          <>
            <BulkImportEmployee
              type={'employee'}
              onModalClose={() => {
                setOpenModal({
                  type: '',
                  data: null,
                });
                setTimeout(() => {
                  loadMembers();
                }, 2000);
              }}
              employerId={id}
            />
          </>
        );
        break;
      case 'automationWorkflow':
        element = (
          <>
            <AutomationWorkflowEmployee
              initData={{selectedItems: membersTableState.selectedItems}}
              onModalClose={(shouldTriggerModal?: boolean) => {
                setOpenModal({
                  type: '',
                  data: null,
                });
              }}
              employerId={id}
            />
          </>
        );
        break;
      default:
        break;
    }
    return element;
  };

  const clearAllEmail = () => {
    setMembersTableState((prev) => {
      return {
        ...prev,
        selectedItems: [] as any,
        selectedRowKey: [] as any,
      };
    });
  };

  const topButtonList = (buttonClickFnList: (() => void)[]) => {
    let btn: any = [];
    if (membersTableState?.selectedRowKey?.length) {
      btn = [
        {
          btnText: 'clearAll',
          borderColor: Colors.secondary['200'],
          size: 'sm',
          colorScheme: '',
          textColor: Colors.secondary['800'],
          backgroundColor: Colors.secondary['100'],
          variant: '',
          isTransBtn: true,
          leftIcon: (
            <Icon
              color={Colors.secondary['800']}
              as={AntIcon}
              name={'close'}
              size="4"
            />
          ),
          btnClick: () => {
            buttonClickFnList[0]();
          },
        },
        !(openInHint && topbarBtnConfig.hideAddButton) && {
          btnText: 'newMembers',
          borderColor: Colors.secondary['200'],
          size: 'sm',
          colorScheme: '',
          textColor: Colors.secondary['800'],
          backgroundColor: Colors.secondary['100'],
          variant: '',
          isTransBtn: true,
          btnClick() {
            //
          },
          id: 3,
          leftIcon: (
            <AntIcon name="plus" color={Colors.secondary['800']} size={16} />
          ),
          content: bulkImportContent,
        },
        {
          btnText: 'bulkActions',
          borderColor: Colors.secondary['200'],
          size: 'sm',
          colorScheme: '',
          textColor: Colors.secondary['800'],
          backgroundColor: Colors.secondary['100'],
          variant: '',
          isTransBtn: true,
          btnClick() {
            //
          },
          id: 4,
          leftIcon: (
            <Feather
              name="more-vertical"
              color={Colors.secondary['800']}
              size={16}
            />
          ),
          content: bulkActionContent,
        },
      ];
    } else {
      btn = [
        !(openInHint && topbarBtnConfig.hideAddButton) && {
          btnText: isVbcTenant? 'newMember': 'newEmployee',
          borderColor: Colors.secondary['200'],
          size: 'sm',
          colorScheme: '',
          textColor: Colors.secondary['800'],
          backgroundColor: Colors.secondary['100'],
          variant: '',
          isTransBtn: true,
          btnClick() {
            //
          },
          id: 3,
          leftIcon: (
            <AntIcon name="plus" color={Colors.secondary['800']} size={16} />
          ),
          content: bulkImportContent,
        },
      ];
    }
    return btn;
  };

  const tableTopBar = (
    <TableTopBar
      title=""
      onActionPerformed={(actionCode: string, actionData: any) => {
        onTableTopBarActionPerformed(actionCode, actionData);
      }}
      buttonList={topButtonList([clearAllEmail])}
      alignSearchBarFlexEnd={true}
    />
  );

  useEffect(() => {
    props.callBackToParent({tableTopBar: tableTopBar})
  }, [])

  useEffect(() => {
    props.callBackToParent({tableTopBar: tableTopBar})
  }, [membersTableState?.selectedRowKey?.length, topbarBtnConfig.hideAddButton])


  const handleDependentCountClick = (data: any) => {
    const contactId = data?.contact?.id;
    if (!contactId) {
      return;
    }
    setMembersTableState((prev) => {
      return {
        ...prev,
        isDependentListOpen: true,
        contactId,
      };
    });
  }
  const createOrUpdateGroupPromise = createCampaignSegment;
  const createSegmentGroup = (
    campaignSegmentFormData: ICampaignSegmentFormState,
    initialLocationIdsList?: string[]
  ) => {
    const selectedAccountLocations =
      campaignSegmentFormData?.selectedAccountLocations || [];
    let locationIdList = selectedAccountLocations.map((location) => {
      return {locationId: location?.uuid};
    });
    const selectedLocationIds = new Set(
      selectedAccountLocations.map((location) => location?.uuid)
    );
    const deletedLocations = initialLocationIdsList
      ?.filter((locationId) => !selectedLocationIds.has(locationId))
      .map((locationId) => ({locationId, isDeleted: true}));
    locationIdList = locationIdList.concat(deletedLocations || []);
    createOrUpdateGroupPromise({
      id: campaignSegmentFormData?.id || '',
      name: campaignSegmentFormData?.audienceName || '',
      tenantId: tenantId,
      description: campaignSegmentFormData?.description || '',
      metadata: {
        patientFilter: patientFilterForCreateSegment(campaignSegmentFormData),
      },
      executionFrequency: campaignSegmentFormData.executionFrequency,
      popupationGroupId: campaignSegmentFormData.populationGroupId,
      ...(!campaignSegmentFormData.patientList?.length &&
        getPreFilterMemberStatus(campaignSegmentFormData)),
      locationIdList: locationIdList,
      automationToUpdate: campaignSegmentFormData?.automationToUpdate || [],
    })
      .then(async (resp: any) => {
        if (resp?.id) {
          let popGroupResponse = resp;
          if (resp?.inputRuleId) {
            popGroupResponse = await getCampaignSegmentRules(resp?.inputRuleId);
          }
          notification.success({
            message:
              resp.type == 'DYNAMIC' || resp.type === 'DYNAMIC_LEAD'
                ? `${intl.formatMessage({
                    id: 'popGroupFrequencyMessage',
                  })} ${getFrequencyValue(popGroupResponse)}.`
                : intl.formatMessage({id: 'createGroupSuccessMessage'}),
          });
          membersTableState.selectedItems = [];
          membersTableState.selectedRowKey = [];
          setOpenGroupModal(false);
          handleCreateMemberGroupAction({
            accountUuid: tenantId,
            userUuid: userUuid,
            level,
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message || 'Something went wrong',
        });
      });
  };
  return (
    <View>
      <Drawer
        visible={campaignDrawerState?.isVisible}
        width={Dimensions.get('window').width * 0.7}
        onClose={() => {
          setCampaignDrawerState({
            isVisible: false,
            segmentId: null,
          });
        }}
        headerStyle={{
          display: 'none',
        }}
      >
        {campaignDrawerState?.isVisible ? (
          <AddOrUpdateCampaign
            shouldShowEdit={true}
            initData={undefined}
            segmentId={campaignDrawerState?.segmentId}
            close={() => {
              setCampaignDrawerState({
                isVisible: false,
                segmentId: null,
              });
            }}
          />
        ) : null}
      </Drawer>
      {props.activeTab === 'employees' && (
        <PageBodyContainer>
          <Drawer
            width={finalWidth}
            visible={openModal?.type ? true : false}
            closeIcon={null}
            closable={true}
            //title={onDrawerHeader(openModal?.type)}
            maskClosable={true}
            onClose={() => {
              setOpenModal({
                type: '',
                data: null,
              });
            }}
          >
            {onDrawerContent(openModal?.type)}
          </Drawer>
          {isUserWithEmployerRole ? tableTopBar : <></>}
          <Table
            rowClassName={(record, index) => {
              return index % 2 == 0 ? 'table-row-light' : '';
            }}
            // style={{maxHeight: height - 277}}
            scroll={{x: 2000, y: height - 290}}
            loading={membersTableState.isLoading}
            className="lead-list-table-view patient-list-view"
            dataSource={membersTableState?.membersTableData || []}
            columns={getMembersColumns(MembersTableColumnArgs)}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: membersTableState.selectedRowKey,
              onSelect: (item: any, selected: boolean) => {
                const selectedKey: any = [...membersTableState?.selectedRowKey];
                const selectedItems: any = [
                  ...membersTableState?.selectedItems,
                ];

                membersTableState?.membersTableData.forEach((element: any) => {
                  if (item?.id == element.id) {
                    if (!selectedKey?.includes(element.id) && selected) {
                      selectedKey.push(element.id);
                      selectedItems.push(element);
                    } else if (selectedKey?.includes(element.id) && !selected) {
                      const index = selectedKey.indexOf(element.id);
                      if (index > -1) {
                        // only splice array when item is found
                        selectedKey.splice(index, 1); // 2nd parameter means remove one item only
                        selectedItems.splice(index, 1);
                      }
                    }
                  }
                });

                setMembersTableState((prev) => {
                  return {
                    ...prev,
                    selectedItems: selectedItems,
                    selectedRowKey: selectedKey,
                  };
                });
              },
              onSelectAll: (isSelected: boolean, data: any) => {
                if (!isSelected) {
                  // setMembersTableState({
                  //   ...membersTableState,
                  //   selectedItems: [],
                  //   selectedRowKey: [],
                  // });

                  const arrayKey = [...membersTableState?.selectedRowKey];
                  const arrayItems = [...membersTableState?.selectedItems];
                  membersTableState?.membersTableData?.map((item?: any) => {
                    arrayKey.map((itemId?: any) => {
                      if (item?.id == itemId) {
                        const index = arrayKey.indexOf(itemId);
                        arrayKey.splice(index, 1);
                        setMembersTableState({
                          ...membersTableState,
                          //selectedItems: [],
                          selectedRowKey: arrayKey,
                        });
                      }
                    });
                    arrayItems.map((itemId?: any) => {
                      if (item?.id == itemId?.id) {
                        const index = arrayItems.indexOf(itemId);
                        arrayItems.splice(index, 1);
                        setMembersTableState({
                          ...membersTableState,
                          selectedItems: arrayItems,
                          selectedRowKey: arrayKey,
                        });
                      }
                    });
                  });
                } else {
                  const arrayItems = [...membersTableState?.selectedItems];
                  membersTableState?.membersTableData?.forEach((item?: any) => {
                    arrayItems.push(item);
                  });
                  //Remove duplicate from Arraylist
                  const uniqueArray = removeDuplicates(arrayItems, 'id');
                  const selectedKey: any = [];
                  uniqueArray.forEach((element: any) => {
                    selectedKey.push(element.id);
                  });

                  setMembersTableState({
                    ...membersTableState,
                    selectedItems: uniqueArray,
                    selectedRowKey: selectedKey,
                  });
                }
              },
              hideSelectAll: isUserWithEmployerRole,
              renderCell(value, record, index, originNode) {
              return isUserWithEmployerRole ? <></> : originNode
              },
            }}
            onRow={(data) => {
              return {
                onClick: async (event: any) => {
                  const isRestrictedEmployeeProfileView = await checkThisEmployeeIsRestricted(
                    restrictedEmployeeProfileData?.isRestrictedParticularLocationEmployeeEnabled,
                    data?.contact?.contactPracticeLocations?.map((location: any) => location?.accountLocation?.uuid),
                    restrictedEmployeeProfileData?.restrictedLocationIds
                  );
                  
                  if (isRestrictedEmployeeProfileView) {
                    showToast(
                      toast,
                      intl.formatMessage({
                        id: 'patientProfileAccessDenyMsg',
                      }),
                      ToastType.info,
                      2000,
                      true
                    );
                    return;
                  }
                  if (isMasterAccountFlag) {
                    return
                  }
                  if (hasClickedOnDependentCount({clickEvent: event})) {
                    handleDependentCountClick(data);
                    return;
                  }
                  onMembersTableActionPerformed(
                    COMMON_ACTION_CODES.ROW_CLICKED,
                    data
                  );
                },
              };
            }}
            rowKey={(row) => row.id as string}
            onChange={(_, filters) => {
              const type = filters.type as string[];
              const selectedStatus = filters.status as string[];
              const selectedPlans = filters.planName as string[];
              setMembersTableState((prev) => {
                return {
                  ...prev,
                  selectedPlans: selectedPlans || [],
                  selectedStatus: selectedStatus || [],
                  type: type || [],
                };
              });
            }}
            pagination={{
              current: membersTableState.page,
              pageSize: membersTableState.pageSize,
              position: ['bottomCenter'],
              total: membersTableState.total,
              onChange: (currentPage, currentPageSize) => {
                setMembersTableState((prev) => {
                  return {
                    ...prev,
                    page: currentPage,
                    pageSize: currentPageSize,
                  };
                });
              },
            }}
          />
          {showAddOrUpdateMember ? (
            <AddOrUpdateLead
              headerName={
                selectedRowData?.employees?.length &&
                selectedRowData?.employees[0]?.contactId
                  ? 'Update Employee'
                  : 'Add Employee'
              }
              singleLeadData={selectedRowData}
              employerId={id}
              isEmployeeView={true}
              onFormCompleteAction={(actionCode: string) => {
                if (actionCode == COMMON_ACTION_CODES.ADDED_OR_UPDATED) {
                  setTimeout(() => {
                    setSelectedRowData(null);
                    loadMembers();
                  }, 2000);
                }
                setShowAddOrUpdateMember(false);
              }}
              personTypeUuid={contactTypeUuid}
              personType={PERSON_TYPES.CUSTOMER}
            />
          ) : null}
        </PageBodyContainer>
      )}

      <DeleteEmployeeModal
        isRequestConsent={true}
        isOpen={openConsentModal ? true : false}
        onConfirm={() => {
          setOpenConsentModal(false);
        }}
        onClose={() => setOpenConsentModal(false)}
      />
      {openDeleteModal ? <DeleteEmployeeModal
        isOpen={openDeleteModal}
        onConfirm={() => {
          handleDeleteEmployee();
          setOpenDeleteModal(false);
        }}
        onClose={() => setOpenDeleteModal(false)}
      /> : <></> }
      {openGroupModal ? isMultiTenancyEnabled ? (
          <Drawer
            headerStyle={{
              display: 'none',
            }}
            width={finalWidth}
            open={openGroupModal ? true : false}
            closeIcon={null}
            closable
            maskClosable={true}
            destroyOnClose
            onClose={() => {
              membersTableState.selectedItems = [];
              membersTableState.selectedRowKey = [];
              setOpenGroupModal(false);
            }}
          >
            <View style={{marginHorizontal: 0}}>
              <CampaignSegmentForm
                context={CampaignSegmentTableContext.POPULATION_GROUP}
                parentCode={PARENT_CODE.PATIENT_LIST}
                isFromLeads={false}
                contactTypeUuid={contactTypeUuid}
                onModalClose={() => {
                  membersTableState.selectedItems = [];
                  membersTableState.selectedRowKey = [];
                  setOpenGroupModal(false);
                }}
                initData={{
                  audienceName: '',
                  patientList: filterAndMapIdsAndLocations()?.ids|| [],
                  description: '',
                  ruleLocations: filterAndMapIdsAndLocations()?.locationIds.map((location: string) => {
                    return {
                      locationId: location,
                    };
                  }),
                }}
                onSubmitForm={(
                  campaignSegmentFormData: ICampaignSegmentFormState,
                  callback?: any,
                  initialLocationIdsList?: string[]
                ) => {
                  createSegmentGroup(
                    campaignSegmentFormData,
                    initialLocationIdsList
                  );
                }}
              />
            </View>
          </Drawer>
        ) : <CreateGroupModal
        isOpen={openGroupModal}
        metadata={getGroupFilter()}
        externalId={id}
        onConfirm={() => {
          membersTableState.selectedItems = [];
          membersTableState.selectedRowKey = [];
          setOpenGroupModal(false);
        }}
        contactTypeUuid={contactTypeUuid}
        onClose={() => setOpenGroupModal(false)}
      /> : <></>}
      {openEmailModal && (
        <EmailDrawerCommonV2
          onClose={() => {
            setOpenEmailModal(false);
          }}
          onEmailSent={() => {
            setOpenEmailModal(false);
          }}
          isOpen={openEmailModal}
          contactIds={
            selectedGroupRowContactData &&
            selectedGroupRowContactData.length > 0
              ? selectedGroupRowContactData.map(item => item.id)
              : []
          }
        />
      )}
      {openDetailsModal && (
        <MessagingContactDetailsDrawer
          contactData={selectedRowData}
          isDrawerVisible={openDetailsModal ? true : false}
          contactType={PERSON_TYPES.CUSTOMER}
          onSideBarActionPerformed={(action?: any) => {
            if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
              setSelectedRowData(null);
              setOpenDetailsModal(false);
            }
          }}
          borderLessView={true}
        />
      )}
      {membersTableState.selectedViewCode ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_DEACTIVATE_VIEW && (
        <DeactivateContactView
          contactId={selectedRowData.rawData?.contact?.uuid}
          onDeleteCompleteAction={(action: string, data: any) => {
            loadMembers();
            onMembersTableActionPerformed(action, data);
          }}
          isMemberDeactivate={true}
          record={selectedRowData}
        />
      )}

      {membersTableState.selectedViewCode ===
        RIGHT_SIDE_CONTAINER_CODE.SEND_SMS_DRAWER && (
        <SMSDrawer
          isOpen={
            membersTableState.selectedViewCode ===
            RIGHT_SIDE_CONTAINER_CODE.SEND_SMS_DRAWER
          }
          data={getContactSMSDataFromRawData({
            data: membersTableState.selectedItems,
          })}
          onCompleteAction={() => {
            setMembersTableState((prev) => {
              return {
                ...prev,
                selectedViewCode: '',
              };
            });
          }}
        />
      )}
      {membersTableState.isDependentListOpen && (
        <DependentList
          contactId={membersTableState.contactId}
          isOpen={membersTableState.isDependentListOpen}
          onClose={() => {
            setMembersTableState((prev) => {
              return {
                ...prev,
                isDependentListOpen: false,
                contactId: 0,
              };
            });
          }}
        />
      )}
    </View>
  );
};

export default MembersManagementMainView;
