import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { Skeleton, Tabs } from 'antd';
import {
  Box,
  FlatList,
  HStack,
  Icon,
  Pressable,
  Text,
  View,
  VStack,
  Divider,
  Spacer,
  Center,
} from 'native-base';
import React, { memo, useContext, useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { BUTTON_TYPE, GROUP_MEMBER_TYPE } from '../../../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { FormsQueries } from '../../../../../services';
import { Colors } from '../../../../../styles';
import {
  getAccountUUID,
  getFormPrintURL,
  getPrimaryGenderCode,
  getUserUUID,
  splitArrayIntoChunks,
} from '../../../../../utils/commonUtils';
//import ContactsQueries from '../../../../../services/Contacts/ContactsQueries';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDateStrFromFormat } from '../../../../../utils/DateUtils';
import { ITableTopBarProps, TableTopBar } from '../../../../common/TableTopBar';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import PublicFormView from '../../../../PublicPages/PublicForm/PublicFormView';
import { IFormResponseData } from '../../interfaces';
import { forEachExtensiveFormComponent } from '../AddOrUpdateForm/AddOrUpdateFormHelper';
import RiskScoreDisplay from '../RiskScoreDisplay/RiskScoreDisplay';
import MemberInfoListItem from '../../../../common/MemberInfoListItem/MemberInfoListItem';
import { SearchBar } from '../../../../common/SearchBar';
import LoadingSpinner from '../../../../common/Loader/LoadingSpinner';
import { DisplayCardAvatar } from '../../../../common/DisplayCard/DisplayCardAvatar';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { FormReportScreen } from '../../Analytics';
import { NoDataFound } from '../../../../common/NoDataFound';
import { checkNoteCategoryForm } from '../FormList/FormListUtils';
import {ModuleCodes, useModuleSpecificUserLocations} from '../../../../CustomHooks/useModuleSpecificUserLocations';
import {usePermissions} from '../../../../CustomHooks/usePermissions';
import {USER_ACCESS_PERMISSION} from '../../../UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../../../SideMenuBar/SideBarConst';
import useEHRCapabilities from '../../../../../screens/BusinessStudio/useEHRCapabilities';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';

export interface IFormScore {
  operation: string;
  score: number;
  state: string;
  name: string;
  groupId?: string;
}

const getContactIds = (responses: any[]): string[] => {
  const contactIds: string[] = [];
  responses.forEach((response) => {
    if (response.contactId) {
      contactIds.push(response.contactId);
    }
  });
  return contactIds;
};

const getSubmittedDataFromComponents = (formComponents: any): object => {
  const submittedData: any = {};
  const data: any = {};
  if (formComponents && formComponents.length) {
    forEachExtensiveFormComponent(formComponents, (component: any) => {
      data[component.key] = component.selectedValue;
    });
  }
  submittedData.data = data;
  return submittedData;
};

const FormResponsesList = (props: {
  selectedForm?: any;
  onTabClick?: (type: string) => void;
  tabArray?: {
    type: FormReportScreen;
    label: string;
  }[],
  setResponseCount?:(count:number, type:FormReportScreen.RESPONDED|FormReportScreen.PENDING)=> void;
  locationIds?: string[]
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const userUUID = getUserUUID();
  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code, MAIN_MENU_CODES.FORMS);
  const currentUserAllowedLocations = permissionConfig?.allowedLocationIds || [];

  if (!location?.state && !props?.selectedForm?.id) {
    navigate('/admin/contentManagement/forms', { replace: true });
  }

  const onFormResponseSelected = (response: any) => {
    setFormComponents([]);
    setFormScore([]);
    getFormResponse({
      variables: {
        id: response.id,
      },
    });
  };
  const [responsesListState, setResponsesListState] = useState({
    pageCount: 1,
    statusCount: {},
    selectedForm: {} as IFormResponseData,
    formResponse: {} as IFormResponseData,
    formResponseList: [] as IFormResponseData[],
  });

  const [formName, setFormName] = useState('');
  const [formID, setFormID] = useState('');
  const [formScore, setFormScore] = useState<Array<{
    metadata: IFormScore,
  }>>([]);
  const [formComponents, setFormComponents] = useState<any[]>([]);
  const [formResponses, setFormResponses] = useState<any[]>([]);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [previousFormResponses, setPreviousFormResponses] = useState<any[]>([]);
  const accountUUID = getAccountUUID();
  const contextData = useContext(CommonDataContext);
  const ehrCapabilities = useEHRCapabilities({
    locationId: props?.selectedForm?.formLocations?.[0]?.locationId,
  });
  const isLocationEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_LOCATION_HANDLING);
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED) && isLocationEnabled;
  const getBgColorForResponse = (response: any): string => {
    let bgColor = '';
    if (response.id === formID) {
      bgColor = Colors.Custom.Gray100 || '';
    }
    return bgColor;
  };

  const getLocationStateVars = () => {
    const state = location?.state as any;
    return {
      updateFormId: state?.updateFormId || props?.selectedForm?.id || '',
      selectedForm: state?.selectedForm || props?.selectedForm,
    };
  };

  const { selectedForm, updateFormId } = getLocationStateVars();
  const [contactDataLoading, setContactDataLoading] = useState(false);
  // Temporary using this as useLazyQuery is not working with Promise.all.
  // It's fixed in latest apollo client version but we have added fix version in package.json
  const client = useApolloClient();
  const isNoteCategoryForm = checkNoteCategoryForm(selectedForm?.formCategory);

  const { loading, refetch } = useQuery(isMsoEnabled ? FormsQueries.GET_FORM_RESPONSE_BY_ID_AND_LOCATION_IDS : FormsQueries.GET_FORM_RESPONSE_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      formId: updateFormId,
      ...(isMsoEnabled && {locationIds: currentUserAllowedLocations || []})
    },
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    onCompleted: async (data: any) => {
      if (data && data.formResponses?.length) {
        const formResponses = data.formResponses;
        (formResponses || []).forEach((formResponse: any) => {
          if (formResponse.isFormDigitallySignedByContact) {
            formResponse.isFormSignedByContactText =
              'Digitally signed by contact';
          } else if (formResponse.isContactSignComponentPresent) {
            formResponse.isFormSignedByContactText = 'Not signed by contact';
          }
        });
        props.setResponseCount?.(formResponses?.length,FormReportScreen.RESPONDED)

        const contactIds = getContactIds(formResponses);
        if (contactIds.length > 0) {
          await fetchContactDetails(contactIds, formResponses);
        }
        getFormResponse({
          variables: {
            id: formResponses[0].id,
          },
        });
        setFormName(formResponses[0].form?.name);
        setFormID(formResponses[0].id);
        setFormResponses(formResponses);
        setPreviousFormResponses([...formResponses]);
        setResponsesListState((prev) => {
          return {
            ...prev,
            selectedForm: formResponses[0],
          };
        });
      }
    },
  });

  const fetchContactDetails = async (contactIds: string[], formResponseList: any[]) => {
    setContactDataLoading(true);
    try {
      // This is a temporary solution, we need to create action for form responses and then add filters and pagination there
      const batchedList = splitArrayIntoChunks(contactIds, 100);
      const promiseList: Promise<any>[] = [];
      batchedList.forEach((ids) => {
        promiseList.push(client.query({
          query: FormsQueries.GET_DETAILS_FOR_CONTACTS,
          variables: {
            where: {
              uuid: { _in: ids },
            }
          },
          fetchPolicy: 'no-cache',
        }));
      })
      const contactData = await Promise.all(promiseList);
      let contacts: any[] = [];
      contactData.forEach((data) => {
        if (data?.data?.contacts?.length) {
          contacts = [...contacts, ...data.data.contacts];
        }
      });
      if (contacts.length && formResponseList) {
        for (let i = 0; i < formResponseList.length; i++) {
          const contactIdInForm = formResponseList[i].contactId;
          if (contactIdInForm)
            for (let j = 0; j < contacts.length; j++) {
              if (contacts[j].uuid === contactIdInForm) {
                // responseClone[i].contactName = contacts[j].name;
                formResponseList[i].contactData = contacts[j];
              }
            }
        }
      }
      setContactDataLoading(false);
    } catch (error) {

      setContactDataLoading(false);
    }
  }

  const [getFormResponse, formResponseStatus] = useLazyQuery(
    FormsQueries.GET_FORM_RESPONSE,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        if (data && data.formResponse) {
          data = data && data.formResponse;
          setFormID(data.id);
          setFormScore(data.metadataScore);
          if (data.formResponse?.components.length > 0) {
            let deepclone = cloneDeep(data.formResponse.components);
            deepclone = deepclone.filter(function (components: any) {
              return components.key !== 'submit';
            });
            setFormComponents(deepclone);
          } else {
            setFormComponents([]);
          }
        } else {
          setFormComponents([]);
        }
      },
      onError: (error: any) => {

      },
    }
  );

  // const [getContactDetails] = useLazyQuery(
  //   FormsQueries.GET_DETAILS_FOR_CONTACTS,
  // {
  //   fetchPolicy: 'no-cache',
  //   onCompleted: (data: any) => {
  //     if (data && data.contacts) {
  //       addContactName(data.contacts);
  //     }
  //   },
  //   onError: (error: any) => {
  //
  //   },
  // }
  // );
  const { height } = Dimensions.get('window');
  const finalListHeight = height - 185;

  const onPrint = (form: any) => {
    const data = {
      response: getSubmittedDataFromComponents(formComponents),
      ehrCapabilities: ehrCapabilities,
    };
    const printUrl = `${getFormPrintURL(form, accountUUID)}
      &name=${form?.name}&formComponents=${encodeURIComponent(JSON.stringify(formComponents))}
      &isPreviewMode=true&formData=${encodeURIComponent(JSON.stringify(data))}`;
    window.open(printUrl, '_blank', 'noopener,noreferrer');
  };

  const onCompletion = () => {
    navigate('/admin/contentManagement/forms');
  };

  const getFormResponseTopBarButtons = (): ITableTopBarProps => {
    return {
      title:
        previousFormResponses.length > 1
          ? `${previousFormResponses.length} Responses received for ${formName}`
          : `${previousFormResponses.length} Response received for ${formName}`,
      buttonList: [
        {
          btnText: 'print',
          size: 'sm',
          colorScheme: 'primary',
          textColor: Colors.primary['800'],
          backgroundColor: Colors.primary['100'],
          borderColor: Colors.primary[200],
          variant: '',
          leftIcon: (
            <Icon
              as={AntIcon}
              name={'printer'}
              size="4"
              color={Colors.primary['800']}
            />
          ),
          btnClick: () => {
            onPrint(selectedForm);
          },
        },
        {
          btnText: 'cancel',
          size: 'sm',
          colorScheme: 'secondary',
          textColor: Colors.Custom.mainSecondaryBrown,
          backgroundColor: Colors.danger[100],
          variant: BUTTON_TYPE.SECONDARY,
          btnClick: () => {
            onCompletion();
          },
        },
      ].filter(item => props?.tabArray?.length ? item.btnText !== 'cancel' : true),
      hideSearchBar: true,
    };
  };

  const addContactName = (contacts: any[]) => {
    let responseClone: any = [];
    if (contacts && formResponses) {
      responseClone = cloneDeep(formResponses);
      for (let i = 0; i < responseClone.length; i++) {
        const contactIdInForm = responseClone[i].contactId;
        if (contactIdInForm)
          for (let j = 0; j < contacts.length; j++) {
            if (contacts[j].uuid === contactIdInForm) {
              // responseClone[i].contactName = contacts[j].name;
              responseClone[i].contactData = contacts[j];
            }
          }
      }
      setFormResponses(responseClone);
      setPreviousFormResponses(responseClone);
      setResponsesListState((prev) => {
        return {
          ...prev,
          selectedForm: responseClone[0],
        };
      });
    }
  };

  const componentLoading = loading || contactDataLoading;
  const submittedFinaldata = {
    response: getSubmittedDataFromComponents(formComponents),
    ehrCapabilities: ehrCapabilities
  }

  const handleSearchTextChange = (searchText: string) => {
    if (searchText) {
      setFormLoading(true);
      const updatedFormResponses = previousFormResponses.filter((item: any) => {
        return String(item?.contactData?.name)
          .toLocaleLowerCase()
          .includes(String(searchText).toLocaleLowerCase());
      });
      setFormResponses(updatedFormResponses);
      setFormLoading(false);
    } else {
      setFormResponses(previousFormResponses);
      setFormLoading(false);
    }
  };
  return (
    <>
      {/* <View backgroundColor={'white'}>
        <TitleSubtitleView titleLabelId="formResponses" />
      </View> */}
      <View style={{ height: finalListHeight }}>
        {!componentLoading && (
          <Box
            borderColor={Colors.Custom.BorderColor}
            borderWidth={1}
            overflow="hidden"
            rounded="lg"
            marginBottom={2}
            height={height - 185}
          >
            {!props?.tabArray?.length && formResponses.length !== 0 &&
            <View background={Colors.Custom.BackgroundColor} marginY={2}>
              <TableTopBar {...getFormResponseTopBarButtons()} />
            </View>
            }
            <HStack flex={1}>
              <View
                overflow="scroll"
                width="300"
                background={Colors.Custom.BackgroundColor}
                marginTop={4}
              >
                <View paddingLeft={2} paddingRight={1.5}>
                  <SearchBar
                    onChange={debounce((value: string) => {
                      handleSearchTextChange(value || '')
                    }, 300)}
                    isNotDebounce={true}
                  />
                </View>
                {props?.tabArray?.length ? <Tabs
                  defaultActiveKey={FormReportScreen.RESPONDED}
                  onTabClick={(activeKey) => {
                    props?.onTabClick?.(activeKey);
                  }}
                >
                  {props?.tabArray?.map((tabObj) => {
                    return (
                      <Tabs.TabPane tab={tabObj.label} key={tabObj.type}>
                      {/* <Tabs.TabPane tab={tabObj.label+`(${formResponses.length})`} key={tabObj.type}> */}
                      </Tabs.TabPane>)
                  })}
                </Tabs> : null}
                {formLoading ? (
                  <View>
                    <LoadingSpinner />
                  </View>
                ) : (
                  formResponses.length === 0 ?
                  <View marginY={4}>
                    <NoDataFound displayString="No results" />
                  </View>
                  :
                  <FlatList
                    data={formResponses}
                    renderItem={({ item }) => {
                      const sendDate = item?.formLog?.createdOn;
                      const receviedDate = item?.updatedOn || item?.createdOn;
                      const itemElem = (
                        <Pressable
                          _hover={{
                            backgroundColor:
                              responsesListState.selectedForm.id === item.id
                                ? Colors.Custom.Gray100
                                : Colors.Custom.Gray100 + '7a',
                          }}
                          onPress={() => {
                            onFormResponseSelected(item),
                              setResponsesListState((prev) => {
                                return {
                                  ...prev,
                                  selectedForm: item,
                                };
                              });
                          }}
                          key={item.id}
                        >
                          <HStack
                            padding={2}
                            background={getBgColorForResponse(item)}
                            borderBottomWidth={1}
                            borderColor="gray.200"
                            alignItems="center"
                            flex={1}
                          >
                            <VStack flex={1}>
                              <MemberInfoListItem
                                contactData={item.contactData}
                                isFormResponse={true}
                                isSelected={item.id === formID}
                                signature={item.isFormSignedByContactText}
                                sendDate={`${getDateStrFromFormat(
                                  sendDate,
                                  'MM/DD/YYYY, hh:mm a'
                                )}`}
                                receviedDate={
                                  !!receviedDate
                                    ? `${getDateStrFromFormat(
                                      receviedDate,
                                      'MM/DD/YYYY, hh:mm a'

                                    )}`
                                    : undefined
                                }
                                sendDateText={
                                  isNoteCategoryForm
                                    ? intl.formatMessage({id: 'created'})
                                    : undefined
                                }
                                receivedDateText={
                                  isNoteCategoryForm
                                    ? intl.formatMessage({
                                        id: 'signedOrAmmended',
                                      })
                                    : undefined
                                }
                                metadataScore={item?.metadataScore}
                                contactNameMaxWidth='75%'
                                showDateOfBirth={true}
                              />
                            </VStack>

                            <View>
                              <Icon
                                as={FeatherIcon}
                                name="chevron-right"
                                size="4"
                                color={Colors.Custom.Gray500}
                              />
                            </View>
                          </HStack>
                        </Pressable>
                        // <MemberInfoListItem contactData={item.contactData} />
                      );
                      return itemElem;
                    }}
                    keyExtractor={(item: any) => {
                      return 'FormResponsesList_' + item.id;
                    }}
                  />
                )}
              </View>
              <View width={0.2} backgroundColor="gray.200" />
              <View flex={1}>
                {!formResponseStatus.loading && (
                  <>
                    <VStack
                      // space={2}
                      height={finalListHeight}
                      overflow="scroll"
                    >
                      {!!props?.tabArray?.length && !!responsesListState?.selectedForm?.id &&
                      <HStack background={Colors.Custom.BackgroundColor} marginY={2} marginX={2}>
                        <Spacer />
                        <TableTopBar
                          title=''
                          buttonList={[
                            {
                              btnText: 'print',
                              size: 'sm',
                              colorScheme: 'primary',
                              textColor: Colors.primary['800'],
                              backgroundColor: Colors.primary['100'],
                              borderColor: Colors.primary[200],
                              variant: '',
                              leftIcon: (
                                <Icon
                                  as={AntIcon}
                                  name={'printer'}
                                  size="4"
                                  color={Colors.primary['800']}
                                />
                              ),
                              btnClick: () => {
                                onPrint(selectedForm);
                              },
                            }
                          ]}
                          hideSearchBar={true} />
                      </HStack>
                      }
                      {!!formScore?.length && (
                        <VStack backgroundColor={'#fff'} marginX={1}>
                          {formScore.map?.((score, index) => {
                            if (!score?.metadata?.state) {
                              return <React.Fragment key={index} />;
                            }
                            return (
                              <RiskScoreDisplay
                                key={index}
                                name={score.metadata.name}
                                interpretation={score.metadata.state}
                                score={score.metadata.score || 0}
                                customMarginBottom={3}
                              />
                            );
                          })}
                        </VStack>
                      )}
                      {!!responsesListState?.selectedForm?.id && <View>
                        <PublicFormView
                          readOnly
                          optionData={{
                            accountUUID,
                            ehrCapabilities,
                            accountLocationUuid:
                            props?.selectedForm?.formLocations?.[0]
                              ?.locationId,
                          }}
                          formData={{
                            name: formName,
                            components: formComponents,
                            noData: false,
                            id: formID,
                          }}
                          submittedData={submittedFinaldata}
                          extraStyles={{
                            marginX: 4,
                            marginY: 0,
                          }}
                          isPreviewMode={true}
                        />
                      </View>}
                      {!responsesListState?.selectedForm?.id &&
                        <Center marginY={8} height={'80%'}>
                          <NoDataFound displayString="No form response selected" />
                        </Center>
                      }
                    </VStack>
                  </>
                )}
                {formResponseStatus.loading && (
                  <View margin={4}>
                    <Skeleton active />
                  </View>
                )}
              </View>
            </HStack>
          </Box>
        )}
        {componentLoading && (
          <View margin={2} paddingX={4} paddingTop={4}>
            <Skeleton active />
          </View>
        )}
      </View>
    </>
  );
};

export default memo(FormResponsesList);
