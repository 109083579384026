import { useState, useEffect } from 'react';
import { Select as AntSelect } from 'antd';
import CommonService from '../../../services/CommonService/CommonService';

const { Option } = AntSelect;






const LLMTemplateSearchSelect = (props:{onSelect:(templateId:any) => void, templateId?: string}) => {
  const [templates, setTemplates] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const commonService = CommonService.getCommonServiceInstance();
  const llmflowService = commonService.llmService;

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = async (query:string) => {
    setLoading(true);
    setSearchQuery(query);
    const url = `/summary/template/name?searchQuery=${searchQuery}&page=1&pageSize=10&templateCategory=summarizationPrompt`;
    const response = await llmflowService.get(url);
    setTemplates(response.data?.templateList || []);
    setLoading(false);
  };


  const getTemplateById = async (templateId:string) => {
    setLoading(true);
    const url = `/summary/template/${templateId}`;
    const response = await llmflowService.get(url);
    setTemplates((old:any[])=>{
      const template = response.data?.template;
      if (!old.some(t => t.id === template.id)) {
        return [template, ...old];
      }
      return old;
    });
    setLoading(false);
  };

  useEffect(()=>{
    handleSearch(searchQuery);
  },[searchQuery])

  useEffect(()=>{
    if(props.templateId && !templates.some(template => template.id === props.templateId)){
      getTemplateById(props.templateId)
    }
  },[])
  let debounceTimer: any;
  return (
    <AntSelect
      showSearch
      placeholder="Select a template"
      optionFilterProp="children"
      loading={loading}
      allowClear={true}
      value={loading ? undefined : props.templateId}
      onChange={(value)=>{
        props.onSelect(value)
      }}
      onSearch={(query)=>{
        if(debounceTimer){
          clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => {
          setSearchQuery(query);
        }, 300);
      }}
    >
      {templates.map((template) => (
        <Option key={template.id} value={template.id}>
          {template.templateName}
        </Option>
      ))}
    </AntSelect>
  );
};

export default LLMTemplateSearchSelect;





