import {View, Text, Button, useMediaQuery, useToast} from "native-base";
import {useState, useEffect, useContext} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {IPAD_MINI_WIDTH, IPAD_WIDTH, PARENT_CODE, QUALITY_MEASURES_PATIENT_TYPE, RIGHT_SIDE_CONTAINER_CODE, SMALL_WINDOW_1500} from "../../../../../../constants";
import {getAccountId, getAccountUUID, getUserActionObjectFromActionCode, getUserUUID, isEmployerRole, isEnableCareProgram} from "../../../../../../utils/commonUtils";
import CustomTabsView from "../../../../../common/CustomTabsView/CustomTabsView";
import {ITabsList} from "../../../../../common/CustomTabsView/interfaces";
import {TABLE_TOP_BAR_ACTION_CODES, TableTopBar} from "../../../../../common/TableTopBar";
import TitleSubtitleView from "../../../../../common/TitleSubtitleView/TitleSubtitleView";
import {getPathFromHash} from "../../../../ContentManagement/ContentManagementUtils";
import PatientView from "./PatientView/PatientView";
import {useLazyQuery} from "@apollo/client";
import QualityMeasuresService from "../../../../../../services/Contracts/QualityMeasures.service";
import {CARESTUDIO_APOLLO_CONTEXT} from "../../../../../../constants/Configs";
import {Colors} from "../../../../../../styles";
import AntIcon from 'react-native-vector-icons/AntDesign';
import {getProgressBarColor} from "../../../HelperFiles/TablesColumnsView";
import {extractNumber, getPopulationCodes} from "../../../HelperFiles/utils";
import LoopSvg from "../../../../../common/Svg/LoopSvg";
import {Drawer, notification, Tooltip} from "antd";
import InfoSvg from "../../../../../common/Svg/InfoSvg";
import {useIntl} from "react-intl";
import LocalStorage from "../../../../../../utils/LocalStorage";
import PerformanceSvg from "../../../../../common/Svg/PerformanceSvg";
import {MEASURE_CRITERIA_TYPE, NAME_MAX_LENGTH, QUALITY_MEASURE_STORAGE_KEY} from "../../../HelperFiles/const";
import {IMeasureDetails} from "../../../HelperFiles/interface";
import ProgressBar from "../../../HelperFiles/ProgressBar";
import {getMeasureData} from "../../../../../../services/CommonService/AidBoxService";
import {getBulkActionButton, getBulkActionTopBarButton, getPatientsExtraActions} from "../../../../Contacts/Leads/LeadView/LeadTableView/Helper/TopBarButtons";
import MembersMultiSelectView from "../../../../../common/MemebersView/MembersMultiSelectView/MembersMultiSelectView";
import {CommonDataContext} from "../../../../../../context/CommonDataContext";
import {isAutomationAllowed} from "../../../../UserAccess/UserAccessPermission";
import {COMMON_ACTION_CODES} from "../../../../../../constants/ActionConst";
import {IContact} from "../../../../TeamInbox/Conversations/interfaces";
import {EmailDrawerCommonV2} from "../../../../../common/EmailDrawerCommonV2";
import {ToastType} from "../../../../../../utils/commonViewUtils";
import {showToast} from "../../../../../../utils/commonViewUtils";
import {getContactSMSData} from "../../../../../common/SMSDrawer/SMSDrawerUtils";
import SMSDrawer from "../../../../../common/SMSDrawer/SMSDrawer";
import {createCampaignSegment, getCampaignSegmentRules} from "../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi";
import {Dimensions} from "react-native";
import AddOrUpdateCampaign from "../../../../ContentManagement/CampaignManagement/AddOrUpdateCampaign/AddOrUpdateCampaign";
import {BulkAssignCareManagerView} from "../../../../../PersonOmniView/RightContainer/CareTeamView/components/BulkAssignCareManagerView/BulkAssignCareManagerView";
import {AutomationWorkflowEmployee} from "../../../../MembersManagement/AutomationWorkflowEmployee";
import {CONFIG_CODES} from "../../../../../../constants/AccountConfigConst";
import {isAccountConfigEnabled} from "../../../../../../utils/configUtils";
import {CampaignSegmentForm} from "../../../../ContentManagement/CampaignManagement/CampaignSegmentForm";
import {CampaignSegmentTableContext, ICampaignSegmentFormState} from "../../../../ContentManagement/CampaignManagement/interfaces";
import {CreateGroupModal} from "../../../../MembersManagement/CreateGroup";
import {getContactTypeId} from "../../../../../../utils/mlovUtils";
import {CONTACT_TYPE_CODES} from "../../../../TeamInbox/Conversations/ConversationContainer/ConversationConst";
import {getFrequencyValue} from "../../../../ContentManagement/CampaignManagement/utils";
import {getPreFilterMemberStatus} from "../../../../MembersManagement/Helper";
import {patientFilterForCreateSegment} from "../../../../MembersManagement/Helper";
import {handleCreateMemberGroupAction} from "../../../../../common/UsageLevel/UsageLevelHandlers";
import {Member_Screen_Tab_Code} from "../../../../../../screens/MembersScreens/MemberScreenConst";

const QualityMeasuresDetails = () => {
  const {contractId,qmId} = useParams();
  const finalWidth = Dimensions.get('window').width;
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const accountUuid = getAccountUUID();
  const userUuid = getUserUUID();
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const navigate = useNavigate();
  const mlovData = useContext(CommonDataContext);
  const userSettings = mlovData.userSettings;
  const enableCareProgram = isEnableCareProgram(userSettings);
  const showAutomationTab = isAutomationAllowed();
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const toast = useToast();
  const contactTypeUuid = getContactTypeId(
    Member_Screen_Tab_Code.PATIENTS
  );
  const userActionObject = getUserActionObjectFromActionCode(
    'CREATE_A_MEMBER_GROUP'
  );
  const level = userActionObject.level || '';
  const [stateData, setStateData] = useState({
    selectedMembers: [] as any,
    selectedActionCode: '',
    selectedRawData: {} as any,
    measureMetCount: 0,
    measureNotMetCount: 0,
    denominatorExclusion: 0,
    numeratorExclusion: 0,
    denominatorException: 0,
    numeratorException: 0,
    measureDetails: {} as IMeasureDetails,
    populationCodesLoading: true,
    populationCodes: [] as string[],
    enableBulkAction: false,
    selectedViewCode: '',
    campaignDrawerState: {
      isVisible: false,
      segmentId: null,
    },
    openGroupModal: false,
    resetPatientSelection: false,
  })
  const [isSmallWindow] = useMediaQuery([
    { maxWidth: SMALL_WINDOW_1500 },
  ]);

  const fetchMeasureData = async (fhirResourceId: string)=> {
    try {
      const resourceDetails = await getMeasureData(fhirResourceId);
      const population = resourceDetails?.data?.entry?.[0]?.resource?.group[0]?.population;
      const populationCodes = getPopulationCodes(population);
      setStateData((prev)=> {
        return {
          ...prev,
          populationCodes: populationCodes,
          populationCodesLoading: false,
        }
      })
    } catch (err) {
      setStateData((prev)=> {
        return {
          ...prev,
          populationCodesLoading: false,
        }
      })
    }
  }

  useEffect(() => {
    LocalStorage.getItem(QUALITY_MEASURE_STORAGE_KEY.MEASURE_DETAILS).then((res) => {
      const measureDetails = JSON.parse(res);
      let data = {} as IMeasureDetails;
      if (measureDetails?.isGroup) {
        data = {
          measureGoal: extractNumber(measureDetails?.goal || ''),
          measurePerformanceScore: extractNumber(measureDetails?.measureGroup?.performanceScore || '0'),
          measureInverse: measureDetails?.isInverse,
          measureName: measureDetails?.measureName,
          groupName: measureDetails?.measureGroup?.name || measureDetails?.measureGroup?.description,
          contractName: measureDetails?.contractName,
          measureGroup: measureDetails?.measureGroup,
          isStratification: measureDetails?.isStratification,
          fhirResourceId: measureDetails?.fhirResourceId
        }
      } else {
        data = {
          measureGoal: extractNumber(measureDetails?.goal || ''),
          measurePerformanceScore: extractNumber(measureDetails?.performanceScore || '0'),
          measureInverse: measureDetails?.isInverse,
          measureName: measureDetails?.measureName,
          contractName: measureDetails?.contractName,
          groupName: measureDetails?.measureGroup?.name || measureDetails?.measureGroup?.description,
          measureGroup: measureDetails?.measureGroup,
          isStratification: measureDetails?.isStratification,
          fhirResourceId: measureDetails?.fhirResourceId
        }
      }
      setStateData((prev) => {
        return {
          ...prev,
          measureDetails: data
        }
      })
      if (data?.fhirResourceId) {
        fetchMeasureData(data?.fhirResourceId);
      }
      const measureGroupId = measureDetails?.isStratification ? measureDetails?.measureGroup?.stratificationId : measureDetails?.measureGroup?.groupId;
      getCountData(measureGroupId, measureDetails?.isStratification);
    });
  },[])
  const intl = useIntl();
  const tabList = (): ITabsList[] => {
    const tabs: ITabsList[] = [
      {
        key: 'patients-unqualified',
        title: 'Measure Not Met',
        path: 'patients-unqualified',
        count: stateData.measureNotMetCount ?? 0,
        tabContainerElem: () => {
          return (
            <PatientView 
              resetPatientSelection={stateData.resetPatientSelection}
              onActionPerformed={onTableTopBarActionPerformed}
              updateContactCount={(contactCount: number) => {
                setStateData(prev=> {
                  return {
                    ...prev,
                    measureNotMetCount: contactCount
                  }
                })
              }}
              searchString={stateData.selectedRawData?.searchString || ''}
              patientType={QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED}
              measureGroup={stateData?.measureDetails?.measureGroup}
              isStratification={stateData?.measureDetails?.isStratification}
            />
          )
        }
      },
      {
        key: 'patients-qualified',
        title: 'Measure Met',
        path: 'patients-qualified',
        count: stateData.measureMetCount ?? 0,
        tabContainerElem: () => {
          return (
            <PatientView
              resetPatientSelection={stateData.resetPatientSelection}
              onActionPerformed={onTableTopBarActionPerformed}
              updateContactCount={(contactCount: number) => {
                setStateData(prev=> {
                  return {
                    ...prev,
                    measureMetCount: contactCount
                  }
                })
              }}
              searchString={stateData.selectedRawData?.searchString || ''}
              patientType={QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED}
              measureGroup={stateData?.measureDetails?.measureGroup}
              isStratification={stateData?.measureDetails?.isStratification}
            />
          )
        }
      },
    ];
    if (stateData?.populationCodes?.includes(MEASURE_CRITERIA_TYPE.DENOMINATOR_EXCLUSION)) {
      tabs.push({
        key: 'denominator-exclusion',
        title: 'Denominator Exclusion',
        path: 'denominator-exclusion',
        count: stateData.denominatorExclusion ?? 0,
        tabContainerElem: () => {
          return (
            <PatientView 
              updateContactCount={(contactCount: number) => {
                setStateData(prev=> {
                  return {
                    ...prev,
                    denominatorExclusion: contactCount
                  }
                })
              }}
              searchString={stateData.selectedRawData?.searchString || ''}
              patientType={QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCLUSION}
              measureGroup={stateData?.measureDetails?.measureGroup}
              isStratification={stateData?.measureDetails?.isStratification}
            />
          )
        }
      })
    }
    if (stateData?.populationCodes?.includes(MEASURE_CRITERIA_TYPE.NUMERATOR_EXCLUSION)) {
      tabs.push({
        key: 'numerator-exclusion',
        title: 'Numerator Exclusion',
        path: 'numerator-exclusion',
        count: stateData.numeratorExclusion ?? 0,
        tabContainerElem: () => {
          return (
            <PatientView 
              updateContactCount={(contactCount: number) => {
                setStateData(prev=> {
                  return {
                    ...prev,
                    numeratorExclusion: contactCount
                  }
                })
              }}
              searchString={stateData.selectedRawData?.searchString || ''}
              patientType={QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCLUSION}
              measureGroup={stateData?.measureDetails?.measureGroup}
              isStratification={stateData?.measureDetails?.isStratification}
            />
          )
        }
      })
    }
    if (stateData?.populationCodes?.includes(MEASURE_CRITERIA_TYPE.DENOMINATOR_EXCEPTION)) {
      tabs.push({
        key: 'denominator-exception',
        title: 'Denominator Exception',
        path: 'denominator-exception',
        count: stateData.denominatorException ?? 0,
        tabContainerElem: () => {
          return (
            <PatientView 
              updateContactCount={(contactCount: number) => {
                setStateData(prev=> {
                  return {
                    ...prev,
                    denominatorException: contactCount
                  }
                })
              }}
              searchString={stateData.selectedRawData?.searchString || ''}
              patientType={QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCEPTION}
              measureGroup={stateData?.measureDetails?.measureGroup}
              isStratification={stateData?.measureDetails?.isStratification}
            />
          )
        }
      })
    }
    if (stateData?.populationCodes?.includes(MEASURE_CRITERIA_TYPE.NUMERATOR_EXCEPTION)) {
      tabs.push({
        key: 'numerator-exception',
        title: 'Numerator Exception',
        path: 'numerator-exception',
        count: stateData.numeratorException ?? 0,
        tabContainerElem: () => {
          return (
            <PatientView 
              updateContactCount={(contactCount: number) => {
                setStateData(prev=> {
                  return {
                    ...prev,
                    numeratorException: contactCount
                  }
                })
              }}
              searchString={stateData.selectedRawData?.searchString || ''}
              patientType={QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCEPTION}
              measureGroup={stateData?.measureDetails?.measureGroup}
              isStratification={stateData?.measureDetails?.isStratification}
            />
          )
        }
      })
    }
    return tabs
  }

  const [qualityMeasureReportAggregate] = useLazyQuery(
    QualityMeasuresService.qualityMeasureReportAggregate,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );
  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData?: any,
    extraData?: any,
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: actionCode,
            selectedRawData: actionData,
          };
        });
        break;
      case COMMON_ACTION_CODES.ON_BULK_ACTION:
        const selectedMembers = actionData?.map((memberData: {contactData: IContact})=> {
          return {...memberData?.contactData, contactData: memberData?.contactData}
        });
        setStateData((prev) => {
          return {
            ...prev,
            enableBulkAction: selectedMembers.length > 0,
            selectedMembers: selectedMembers,
            resetPatientSelection: false,
          };
        });
        break;
      case COMMON_ACTION_CODES.RESET:
        setStateData((prev) => {
          return {
            ...prev,
            selectedViewCode: '',
            campaignDrawerState: {
              isVisible: false,
              segmentId: null,
            },
            selectedMembers: [],
            enableBulkAction: false,
            selectedRawData: {},
            selectedActionCode: '',
            openGroupModal: false,
            resetPatientSelection: true,
          };
        });
        break;
      case COMMON_ACTION_CODES.CLOSE:
        setStateData((prev) => {
          return {
            ...prev,
            selectedViewCode: '',
            campaignDrawerState: {
              isVisible: false,
              segmentId: null,
            },
            openGroupModal: false,
          };
        });
        break;
      default:
        break;
    }
  };

  const onBulkEmailClicked = () => {
    const filterIfEmailExists = stateData.selectedMembers.filter(
      (member: {email: string}) => member?.email ?? false
    );

    if (filterIfEmailExists?.length > 0) {
      setStateData((prev) => ({
        ...prev,
        selectedViewCode: RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP,
        filterIfEmailExists: filterIfEmailExists,
        resetPatientSelection: false,
      }));
    } else {
      showToast(
        toast,
        intl.formatMessage({id: 'selectedContactDoesNotHaveEmail'}),
        ToastType.info
      );
    }
  };

  const onBulkEmailSms = () => {
    const array = getContactSMSData([...stateData.selectedMembers]);
    setStateData((prev) => {
      return {
        ...prev,
        selectedViewCode: RIGHT_SIDE_CONTAINER_CODE.SEND_SMS_DRAWER,
        selectedRawData: array,
        resetPatientSelection: false,
      };
    });
  }

  const onCLearAllMail = () => {
    onTableTopBarActionPerformed(COMMON_ACTION_CODES.RESET, {});
  }

  const onCreateCampaign = () => {
    const contactIds = (stateData.selectedMembers || []).map((contact: any) => {
      return contact?.id;
    });
    createCampaignSegment({
      name: '',
      tenantId: accountUuid,
      metadata: {
        patientFilter: {
          contactIds: contactIds,
        },
      },
    }).then((resp) => {
      if (resp?.id) {
        setStateData((prev) => {
          return {
            ...prev,
            campaignDrawerState: {
              isVisible: true,
              segmentId: resp?.id,
            },
            resetPatientSelection: false,
          };
        });
      }
    });
  }

  const onCreateAutomation = () => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedViewCode: RIGHT_SIDE_CONTAINER_CODE.AUTOMATION_VIEW,
        resetPatientSelection: false,
      };
    });
  }

  const onCreateGroup = () => {
    setStateData((prev) => {
      return {
        ...prev,
        openGroupModal: true,
        resetPatientSelection: false,
      };
    });
  }

  const onAssignCareManager = () => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedViewCode: RIGHT_SIDE_CONTAINER_CODE.ASSIGN_CARE_MANAGER_VIEW,
        resetPatientSelection: false,
      };
    });
  }

  const getGroupFilter = () => {
    const metadata = {
      patientFilter: {
        contactIds: (stateData.selectedMembers || []).map((contact: any) => {
          return contact?.id;
        }),
      },
    };
    return metadata;
  };

  const getSelectedTopBar = (selectedTab: ITabsList) => {
    if ( selectedTab.key === 'programs') {
      return false
    }
    return  <TableTopBar
    selectedTab={selectedTab.key}
    title=""
    alignSearchBarFlexEnd={true}
    hStackMinHight={70}
    isDividerNotVisible={true}
    onActionPerformed={(actionCode: string, actionData: any) => {
      onTableTopBarActionPerformed(actionCode, actionData);
    }}
  />
  }
  const onTabClick = (tab: ITabsList) => {
    onTableTopBarActionPerformed(COMMON_ACTION_CODES.RESET, {});
    setSelectedTab(tab)
  }
  const createSegmentGroup = (
    campaignSegmentFormData: ICampaignSegmentFormState,
    initialLocationIdsList?: string[],
  ) => {
    const selectedAccountLocations = campaignSegmentFormData?.selectedAccountLocations || [];
    let locationIdList = selectedAccountLocations.map((location) => {
      return { locationId: location?.uuid };
    });
    const selectedLocationIds = new Set(selectedAccountLocations.map(location => location?.uuid));
    const deletedLocations = initialLocationIdsList
      ?.filter(locationId => !selectedLocationIds.has(locationId))
      .map(locationId => ({ locationId, isDeleted: true }));
    locationIdList = locationIdList.concat(deletedLocations || []);

    createCampaignSegment({
      id: campaignSegmentFormData?.id || '',
      name: campaignSegmentFormData?.audienceName || '',
      tenantId: accountUuid,
      description: campaignSegmentFormData?.description || '',
      metadata: {
        patientFilter: patientFilterForCreateSegment(campaignSegmentFormData),
      },
      executionFrequency: campaignSegmentFormData.executionFrequency,
      popupationGroupId: campaignSegmentFormData.populationGroupId,
      ...(!campaignSegmentFormData.patientList?.length) && (getPreFilterMemberStatus(campaignSegmentFormData)),
      locationIdList: locationIdList,
      automationToUpdate: campaignSegmentFormData?.automationToUpdate || [],
    }).then(async (resp: any) => {
      if (resp?.id) {
        let popGroupResponse = resp;
        if (resp?.inputRuleId) {
          popGroupResponse = await getCampaignSegmentRules(resp?.inputRuleId);
        }
        notification.success({
          message:
            resp.type == 'DYNAMIC' || resp.type === 'DYNAMIC_LEAD'
              ? `${intl.formatMessage({id: 'popGroupFrequencyMessage'})} ${getFrequencyValue(popGroupResponse)}.`
              : intl.formatMessage({id: 'createGroupSuccessMessage'}),
        });
        onTableTopBarActionPerformed(COMMON_ACTION_CODES.RESET, {});
        handleCreateMemberGroupAction({
          accountUuid: accountUuid,
          userUuid: userUuid,
          level,
        });
      }
    }).catch((error) => {
      notification.error({
        message: error?.response?.data?.message || 'Something went wrong',
      });
    });
  };
  const qualityMeasuresCountByType = async (patientType: string, isStratification: boolean, measureGroupId: string) => {
    const filterObject = {
      isLatest: { _eq: true },
      contractId: { _eq: contractId || '' },
      measureId: { _eq: qmId || '' },
      tenantId: { _eq: accountUuid },
      ...(patientType === QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED && {
        numeratorFlag: { _eq: true },
      }),
      ...(patientType === QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED && {
        numeratorFlag: { _eq: false },
        denominatorFlag: { _eq: true },
      }),
      ...(patientType === QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCLUSION && {
        denominatorExclusionFlag: { _eq: true },
      }),
      ...(patientType === QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCLUSION && {
        numeratorExclusionFlag: { _eq: true },
      }),
      ...(patientType === QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCEPTION && {
        denominatorExceptionFlag: { _eq: true },
      }),
      ...(patientType === QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCEPTION && {
        numeratorExceptionFlag: { _eq: true },
      }),
      ...(measureGroupId && {
        ...(isStratification
          ? { stratification: { _eq: measureGroupId } }
          : { groupId: { _eq: measureGroupId } }),
      }),
    };
  
    try {
      const { data } = await qualityMeasureReportAggregate({
        variables: {
          where: filterObject,
        },
      });
      return data?.aggregateQmMeasureReport?.aggregate?.count || 0;
    } catch (error) {
      return 0;
    }
  };
  
  const getCountData = async (measureGroupId: string, isStratification: boolean) => {
    try {
      const measureMetCount = await qualityMeasuresCountByType(QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED, isStratification, measureGroupId);
      const measureNotMetCount = await qualityMeasuresCountByType(QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED, isStratification, measureGroupId);
      const denominatorExclusion = await qualityMeasuresCountByType(QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCLUSION, isStratification, measureGroupId);
      const numeratorExclusion = await qualityMeasuresCountByType(QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCLUSION, isStratification, measureGroupId);
      const denominatorException = await qualityMeasuresCountByType(QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCEPTION, isStratification, measureGroupId);
      const numeratorException = await qualityMeasuresCountByType(QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCEPTION, isStratification, measureGroupId);
  
      setStateData(prev => {
        return {
          ...prev,
          measureMetCount,
          measureNotMetCount,
          denominatorExclusion,
          numeratorExclusion,
          denominatorException,
          numeratorException
        };
      });
    } catch (error) {
      setStateData(prev => ({
        ...prev,
        measureMetCount: 0,
        measureNotMetCount: 0,
        error: "Error fetching data",
      }));
    }
  };
  const showBackButton= isEmployerRole() ? false : true;
  const getMaxLength = ()=> {
    return isSmallWindow ? NAME_MAX_LENGTH.GROUP_NAME_SMALL_SCREEN : NAME_MAX_LENGTH.GROUP_NAME;
  }
  useEffect(() => {
    const path = getPathFromHash(window.location.hash);
    const tabIndex = tabList().findIndex((item) => item.path === path);
    setSelectedTab(tabList()[tabIndex]);
  }, [])
  return (
    <View flex={1}>
      <View>
        <TitleSubtitleView
          title={'contracts'}
        />
      </View>
      <View padding={2} flexDirection={'row'} borderBottomWidth={1} borderBottomColor={Colors.FoldPixel.GRAY150}>
        <View alignItems={'center'} flexDirection={'row'} width={'50%'}>
          <View>
            {showBackButton? (
              <View>
                <Button
                  backgroundColor={'white'}
                  _hover={{bg: Colors.primary['100'] + '7a' || ''}}
                  onPress={() => {
                    LocalStorage.removeItem(QUALITY_MEASURE_STORAGE_KEY.MEASURE_DETAILS);
                    navigate(`/members/contracts/${contractId}/qualityMeasures`)
                  }}
                >
                  <AntIcon color={Colors.FoldPixel.GRAY300} name="arrowleft" size={18} />
                </Button>
              </View>
            ):(
              <></>
            )}
          </View>
          <View flex={1} marginRight={4}>
            <Text style={{
              color: Colors.FoldPixel.GRAY300,
              fontSize: 12,
              fontWeight: '400',
              lineHeight: 16.8,
              overflow: 'hidden'
            }}>
              {stateData?.measureDetails?.contractName}
            </Text>
            <Tooltip title={stateData?.measureDetails?.measureName?.length > getMaxLength() ? stateData?.measureDetails?.measureName : ''} placement={'top'}>
              <Text noOfLines={2} isTruncated style={{
                color: Colors.FoldPixel.GRAY400,
                fontSize: 14,
                fontWeight: '500',
                lineHeight: 16.8,
                fontFamily: 'Manrope-Bold',
                width: 'fit-content'
              }}>
                {stateData?.measureDetails?.measureName}
              </Text>
            </Tooltip>
            <Tooltip title={stateData?.measureDetails?.groupName?.length > getMaxLength() ? stateData?.measureDetails?.groupName : ''} placement={'top'}>
              <Text noOfLines={2} isTruncated style={{
                color: Colors.FoldPixel.GRAY300,
                fontSize: 12,
                fontWeight: '400',
                lineHeight: 16.8,
              }}>
                {stateData?.measureDetails?.groupName}
              </Text>
            </Tooltip>
          </View>
        </View>
        <View alignItems={'flex-end'} justifyContent={'center'} width={'50%'}>
          <View width={'100%'} padding={2} flexDirection={'row'} alignItems={'flex-start'}>
          <Text style ={{
                color: Colors.FoldPixel.GRAY400,
                fontSize: 16,
                fontWeight: '500',
                lineHeight: 19.2,
                marginBottom: 2,
                marginRight: 10
              }}>{'Performance rate: '}</Text>
            <View style={{
              flexDirection: 'column',
              width: '72%',
              marginTop: 6
            }}>
              <View style={{
                width: '90%'
              }}>
                <ProgressBar percentage={stateData?.measureDetails?.measurePerformanceScore || 0} goal={stateData?.measureDetails?.measureGoal || 0} inInverse={stateData?.measureDetails?.measureInverse}/>
              </View>
              <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%'
              }}>
                <Text style={{
                  color: Colors.FoldPixel.GRAY400,
                  fontSize: 12,
                  fontWeight: '500',
                }}>{intl.formatMessage({id: 'currentScore'})} </Text>
                <Text style={{
                  color: getProgressBarColor(stateData?.measureDetails?.measurePerformanceScore || 0, stateData?.measureDetails?.measureGoal || 0, stateData?.measureDetails?.measureInverse),
                  fontSize: 12,
                  fontWeight: '500',
                }}>{stateData?.measureDetails?.measurePerformanceScore || 0}%</Text>
              </View>
            </View>
            <View style={{
                flexDirection: 'column',
                width: '10%',  
            }}>
            <Text style ={{
                color: Colors.FoldPixel.GRAY400,
                fontSize: 16,
                fontWeight: '500',
                lineHeight: 19.2,
                marginBottom: 10,
              }}>{'Goal: '}</Text>
              <Text style ={{
                color: Colors.FoldPixel.GRAY300,
                fontSize: 14,
                fontWeight: '400',
                lineHeight: 16.8,
              }}>
                {stateData?.measureDetails?.measureGoal || 0}%
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View>
        <CustomTabsView
          contentTopMargin={0}
          showHeading={true}
          backButtonRouteFunc={()=> {
            navigate(`/members/contracts/${contractId}/qualityMeasures`);
          }}
          basePath={`/members/contracts/${contractId}/qualityMeasures/${qmId}`}
          tabsList={tabList()}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            backgroundColor: '#fff',
          }}
          canNavigate={true}
          onTabClick={(tabItem) => {
            onTabClick(tabItem);
          }}
          tabsLoading={stateData?.populationCodesLoading}
        />
      </View>
      <MembersMultiSelectView
        onClose={() => {
          onCLearAllMail();
        }}
        isVisible={stateData.enableBulkAction}
        selectedMembers={stateData.selectedMembers}
        buttonList={getBulkActionTopBarButton(
          [
            onBulkEmailClicked,
            onBulkEmailSms,
            onCLearAllMail,
            onCreateCampaign,
            onCreateAutomation,
            onCreateGroup,
            onAssignCareManager
          ],
          onTableTopBarActionPerformed,
          '',
          showAutomationTab,
          getPatientsExtraActions(enableCareProgram)
        ).buttonList}
      />
      {stateData.selectedViewCode === RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
        <EmailDrawerCommonV2
          contactIds={stateData.selectedMembers.map((member: {id: number}) => member.id)}
          isOpen={stateData.selectedViewCode === RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP}
          onClose={() => {
            onTableTopBarActionPerformed(COMMON_ACTION_CODES.CLOSE);
          }}
          onEmailSent={({msgData}) => {
            onTableTopBarActionPerformed(COMMON_ACTION_CODES.RESET);
          }}
        />
      )}
      {stateData.selectedViewCode === RIGHT_SIDE_CONTAINER_CODE.SEND_SMS_DRAWER && (
        <SMSDrawer
          data={stateData.selectedRawData}
          isOpen={stateData.selectedViewCode === RIGHT_SIDE_CONTAINER_CODE.SEND_SMS_DRAWER}
          onCompleteAction={(isClose) => {
            if(isClose) {
              onTableTopBarActionPerformed(COMMON_ACTION_CODES.CLOSE);
            } else {
              onTableTopBarActionPerformed(COMMON_ACTION_CODES.RESET);
            }
          }}
        />
      )}
      {stateData.campaignDrawerState?.isVisible && (
        <Drawer
          visible={stateData.campaignDrawerState?.isVisible}
          width={finalWidth * 0.7}
          onClose={() => {
            setStateData((prev) => ({
              ...prev,
              campaignDrawerState: {
                isVisible: false,
                segmentId: null,
              },
            }));
          }}
          headerStyle={{
            display: 'none',
          }}
        >
          <AddOrUpdateCampaign
            shouldShowEdit={true}
            initData={undefined}
            segmentId={stateData.campaignDrawerState?.segmentId}
            close={(code) => {
              if (code === 'created') {
                onTableTopBarActionPerformed(COMMON_ACTION_CODES.RESET, {});
                showToast(
                  toast,
                  intl.formatMessage({id: 'campaignCreateSuccess'}),
                  ToastType.success,
                );
              } else {
                onTableTopBarActionPerformed(COMMON_ACTION_CODES.CLOSE, {});
              }
            }}
          />
        </Drawer>
      )}
      {stateData.selectedViewCode === RIGHT_SIDE_CONTAINER_CODE.ASSIGN_CARE_MANAGER_VIEW && (
        <BulkAssignCareManagerView
          selectedContacts={stateData.selectedMembers}
          visible={true}
          onClose={() => {
            onTableTopBarActionPerformed(COMMON_ACTION_CODES.CLOSE);
          }}
          onSuccess={() => {
            onTableTopBarActionPerformed(COMMON_ACTION_CODES.RESET);
          }}
        />
      )}
      {stateData.selectedViewCode === RIGHT_SIDE_CONTAINER_CODE.AUTOMATION_VIEW && (
        <Drawer
          width={isIPadScreen || isIPadMiniScreen ? finalWidth / 1.5 : finalWidth / 2.5}
          visible={stateData.selectedViewCode === RIGHT_SIDE_CONTAINER_CODE.AUTOMATION_VIEW}
          closeIcon={null}
          closable
          onClose={() => {
            onTableTopBarActionPerformed(COMMON_ACTION_CODES.CLOSE, {});
          }}
          maskClosable={true}
        >
          <AutomationWorkflowEmployee
            type="patient"
            initData={{selectedItems: stateData.selectedMembers}}
            onModalClose={() => {
              onTableTopBarActionPerformed(COMMON_ACTION_CODES.RESET, {});
            }}
            onClose={() => {
              onTableTopBarActionPerformed(COMMON_ACTION_CODES.CLOSE, {});
            }}
          />
        </Drawer>
      )}
      {stateData.openGroupModal ? (
        isMsoEnabled ? (
          <Drawer
            headerStyle={{
              display: 'none',
            }}
            width={finalWidth}
            open={stateData.openGroupModal ? true : false}
            closeIcon={null}
            closable
            maskClosable={true}
            destroyOnClose
            onClose={() => {
              onTableTopBarActionPerformed(COMMON_ACTION_CODES.CLOSE, {});
            }}
          >
            <View style={{marginHorizontal: 0}}>
              <CampaignSegmentForm
                context={CampaignSegmentTableContext.POPULATION_GROUP}
                parentCode={PARENT_CODE.PATIENT_LIST}
                isFromLeads={false}
                contactTypeUuid={contactTypeUuid}
                onModalClose={() => {
                  onTableTopBarActionPerformed(COMMON_ACTION_CODES.CLOSE, {});
                }}
                initData={{
                  audienceName: '',
                  patientList:
                    stateData?.selectedMembers?.map(
                      (contact: {id: number}) => contact.id
                    ) || [],
                  description: '',
                  ruleLocations: [
                    ...Array.from(
                      new Set(
                        stateData?.selectedMembers?.map(
                          (contact: {contactData: IContact}) =>
                            contact.contactData?.contactPracticeLocations?.[0]
                              ?.accountLocation?.uuid ?? ''
                        ).filter((location: string) => location !== '')
                      ) as Set<string>
                    ),
                  ].map((location: string) => {
                    return {
                      locationId: location,
                    };
                  }),
                }}
                onSubmitForm={(
                  campaignSegmentFormData: ICampaignSegmentFormState,
                  callback?: any,
                  initialLocationIdsList?: string[]
                ) => {
                  createSegmentGroup(
                    campaignSegmentFormData,
                    initialLocationIdsList
                  );
                }}
              />
            </View>
          </Drawer>
        ) : (
          <CreateGroupModal
            isOpen={stateData.openGroupModal}
            contactTypeUuid={contactTypeUuid}
            metadata={getGroupFilter()}
            onConfirm={() => {
              onTableTopBarActionPerformed(COMMON_ACTION_CODES.RESET, {});
            }}
            onClose={() => {
              onTableTopBarActionPerformed(COMMON_ACTION_CODES.CLOSE, {});
            }}
          />
        )
      ) : (
        <></>
      )}
    </View>
  )
}

export default QualityMeasuresDetails;