import { DATE_FORMATS } from "../../../constants";
import { APPOINTMENT_STATUS_CODES, CARE_PROGRAM_STEP_STATUS_CODES, CARE_PROGRAM_TYPE_CODES, CareProgramComplexityCode } from "../../../constants/MlovConst";
import { IContactCareProgramResponse, IContactCareProgramSteps, IStepsLog } from "../../../services/ContactCareProgram/interface";
import { Colors } from "../../../styles";
import { ToastType } from "../../../utils/commonViewUtils";
import { getDateStrFromFormat, getDiffInDays, getDiffInDaysRound, getDiffInHours, getMomentObj } from "../../../utils/DateUtils";
import { CareProgramStepCustomCode } from "../../RightSideContainer/ContactCareProgram/ContactCareProgramView/consts";
import { CareProgramStatus } from "../../RightSideContainer/ContactCareProgram/ContactCareProgramView/hooks/useCareProgramStatus";
import { AttributeKey, IContactCareProgramState } from "../../RightSideContainer/ContactCareProgram/interface";
import { CARE_PROGRAM_STEP_TYPE } from "../MemebersView/constant";


export const getAttributeByKey = (attributeKey: string, contactCarePrograms: IContactCareProgramResponse): string | undefined => {
  if (!contactCarePrograms?.additionalAttributes?.length) {
    return undefined;
  }
  const findAttribute = (contactCarePrograms.additionalAttributes).find(additionalAttribute => {
    return additionalAttribute?.attributeKey === attributeKey;
  });
  if (!findAttribute) {
    return undefined;
  }
  const attributeDateTypeCode = findAttribute.attributeValueDataTypeCode;
  let attributeValue: any = undefined;
  switch (attributeDateTypeCode) {
    case 'string':
    case 'dateTime':
      attributeValue = findAttribute?.attributeValue?.valueString || '';
      break;
    case 'date':
      attributeValue = getDateStrFromFormat(findAttribute?.attributeValue?.value, DATE_FORMATS.CARE_DASHBOARD_DATE_FORMAT);
      break;
  }
  return attributeValue;
};

const getEnrollmentStepId = (contactCarePrograms: IContactCareProgramResponse): string | undefined => {
  const findContactCareProgramStep: IContactCareProgramSteps | undefined = (contactCarePrograms?.contactCareProgramSteps || []).find(contactCareProgramStep => {
    return contactCareProgramStep?.careProgramStepType?.code === CARE_PROGRAM_STEP_TYPE.ENROLLMENT;
  });
  return findContactCareProgramStep?.id;
}


export const getProgramEnrollmentDateAndStatus = (contactCarePrograms: IContactCareProgramResponse, fetchStatus?: boolean) => {
  let resultString = ``;
  if (contactCarePrograms?.enrollmentLog?.enrollmentDateTime) {
    resultString = getDateStrFromFormat(contactCarePrograms.enrollmentLog.enrollmentDateTime, DATE_FORMATS.CARE_DASHBOARD_DATE_FORMAT);
  }
  if (!fetchStatus) {
    return resultString;
  }
  const enrollmentStepId: string | undefined = getEnrollmentStepId(contactCarePrograms);
  if (fetchStatus && enrollmentStepId) {
    const findStepLog: IStepsLog | undefined = (contactCarePrograms?.stepsLog || []).find(currentStepsLog => {
      return currentStepsLog?.careProgramStepId === enrollmentStepId;
    });
    if (!findStepLog?.careProgramStepId && findStepLog?.subStep?.careProgramSubStepType?.value) {
      resultString = `${findStepLog.subStep.careProgramSubStepType.value}`;
    }
  }
  return resultString;
}

export const getDayLapse = (dischargeDate: string) => {
  let diffDayHourMessage = '';
  if (dischargeDate) {
    const diffDays = getDiffInDays(
      getMomentObj(dischargeDate).startOf('day'),
      getMomentObj(new Date()).startOf('day')
    );
    if (diffDays > 0) {
      diffDayHourMessage = `${diffDays} ${diffDays === 1 ? 'day' : 'days'}`;
    } else {
      const diffHours = getDiffInHours(
        getMomentObj(dischargeDate),
        getMomentObj(new Date()),
        true
      );
      diffDayHourMessage =
        diffHours > 0
          ? `${diffHours} ${diffHours === 1 ? 'hour' : 'hours'}`
          : '';
    }
  }
  return diffDayHourMessage;
};

export const getDayLapseColorCode = (
  dischargeDate: string,
  pcpAppointmentDate?: string,
  programComplexity?: string,
  pcpAppointmentStatus?: string
) => {
  if (!dischargeDate) {
    return Colors.Custom.Gray500;
  }
  const diffDays = getDiffInDays(
    getMomentObj(dischargeDate).startOf('day'),
    getMomentObj(new Date()).startOf('day')
  );
  const careProgramComplexity =
    programComplexity || CareProgramComplexityCode.SIMPLE;

  if (careProgramComplexity === CareProgramComplexityCode.COMPLEX) {
    if (
      diffDays > 7 &&
      pcpAppointmentStatus !== APPOINTMENT_STATUS_CODES.CHECKED_OUT
    ) {
      return Colors.Custom.DeceasedTagColor;
    } else if (diffDays > 7) {
      return Colors.Custom.crossIconColor;
    } else {
      return Colors.Custom.Gray500;
    }
  } else {
    if (
      diffDays > 14 &&
      pcpAppointmentStatus !== APPOINTMENT_STATUS_CODES.CHECKED_OUT
    ) {
      return Colors.Custom.DeceasedTagColor;
    } else if (diffDays > 14) {
      return Colors.Custom.crossIconColor;
    } else {
      return Colors.Custom.Gray500;
    }
  }
};

export const getCompletedDayLapse = (endDateTime: string, dischargeDate?: string, careProgramStatus?: CareProgramStatus, careProgramStatusId?: string,) => {
  let diffDayHourMessage = '';
  let completedText= '';
  if (careProgramStatusId === careProgramStatus?.autoClosed) {
    completedText = ' Auto Closed in: ';
  } else if (careProgramStatusId === careProgramStatus?.closed) {
    completedText = ' Closed in: ';
  } else {
    completedText = ' Completed in: ';
  }
  if (dischargeDate) {
    const diffDays = getDiffInDaysRound(
    getMomentObj(dischargeDate),
    getMomentObj(endDateTime),
  );
  if (diffDays > 0) {
    diffDayHourMessage = `${completedText} ${diffDays} ${diffDays === 1 ? 'day' : 'days'}`;
  } else if (dischargeDate) {
    const diffHours = getDiffInHours(
      getMomentObj(dischargeDate),
      getMomentObj(endDateTime)
    );
    const finalDiffHours = Math.round(diffHours);
    diffDayHourMessage =
      finalDiffHours > 0
        ? `${completedText} ${finalDiffHours} ${finalDiffHours === 1 ? 'hour' : 'hours'}`
        : '';
  }
}
return diffDayHourMessage;
}

export   const getStepStatus = (completionStatus:{total: number, completed: number, failed: number}, isOutreachStep: boolean, statusMlov?: {code: string}, isECMCareProgram?: boolean): string => {
  if (!isOutreachStep) {
    return statusMlov?.code || CARE_PROGRAM_STEP_STATUS_CODES.TO_DO;
  }
  const { total, completed, failed } = completionStatus;

  if (total === 0) {
    return CARE_PROGRAM_STEP_STATUS_CODES.TO_DO;
  }

  if (completed >= (isECMCareProgram ? 6 : 1)) {
    return CARE_PROGRAM_STEP_STATUS_CODES.DONE;
  }

  if (failed === total) {
    return CARE_PROGRAM_STEP_STATUS_CODES.FAIL;
  }

  return CARE_PROGRAM_STEP_STATUS_CODES.TO_DO;
};

export const canChangeStep = async (
  state: IContactCareProgramState,
  toast: (params: {message: string; toastType: ToastType}) => void
) => {
  const {contactCareProgramDetails} = state;
  if (
    contactCareProgramDetails?.payerCareProgram?.careProgramType?.code === CARE_PROGRAM_TYPE_CODES.TCM
  ) {
    const currentStep = contactCareProgramDetails?.contactCareProgramSteps.find(
      (step) => step.id === state.selectedStepId
    );
    const customCode = currentStep?.careProgramStepAdditionalAttributes?.find(
      (attribute) => attribute.attributeKey === AttributeKey.CUSTOM_CODE
    )?.attributeValue?.valueString;
    if (customCode === CareProgramStepCustomCode.TCM_CLINICAL_PARAMETERS) {
      const validation = await state.formRef?.current?.validateForm();
      const isValid = state.formRef?.current ? validation?.isValid : true;
      if (!isValid) {
        const message = validation.results?.[0]?.errorMessage || 'Please enter admission and discharge date & time to proceed';
        toast({
          message,
          toastType: ToastType.error,
        });
        return false;
      }
    }
  }
  return true;
};
