import { EHR } from "../../../../constants/FhirConstant";
import { IAppointmentTask } from "../../../../services/Appointment/AppointmentInterface";
import { IParticipantData } from "../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingIntefaces";

export enum NoteActions {
  CREATE_DEFAULT_NOTE = "CREATE_NOTE",
  USE_TEMPLATE = "USE_TEMPLATE",
}
export enum ScreenContext {
  TEMPLATE_SELECTION = 'TEMPLATE_SELECTION',
  ADD_OR_UPDATE_NOTE = 'ADD_OR_UPDATE_NOTE',
  READ_ONLY_DOCUMENT = 'READ_ONLY_DOCUMENT',
}
export enum DocStatus {
  PRELIMINARY = 'preliminary',
  FINAL = 'final',
  AMENDED = 'amended'
}

export enum EncounterStatus {
  FINAL = 'finished',
  IN_PROGGRESS = 'in-progress',
}

export enum EHRName {
  ELATION = 'ELATION_CALIBER',
  FOLD = 'FHIR_STORE',
  ATHENA = 'ATHENA_CALIBER',
  CANVAS = 'CANVAS',
  CERNER = 'CERNER',
  ECW = 'ECW'
}
export interface ISelectedAppointment {
  id?: string;
  name?: string;
  reasonForVisit?: {
    code: string;
    displayName: string;
  };
  statusId?: string;
  appointmentType?: {
    id: string;
    eventName: string;
    locationType: {
      value: string;
      code: string;
    };
    category: {
      code: string;
    };
  };
  startDateTime?: string;
  externalAppointmentId?: string;
  tasks?: IAppointmentTask[];
}

export interface IAppointmentDetail {
  id: string;
  name?: string;
  startDateTime: string;
  endDateTime: string;
  participants: IParticipantData[];
  reasonForVisit: {
    code: string;
    displayName: string;
  };
  notes: any[];
  isBlockedInterval: boolean;
  statusId: string;
  reference?: any;
  declinedReason?: string;
  proposedTime?: string;
  userPracticeLocationId?: string;
  accountLocationId?: string;
  locationTypeId?: string;
  isRsvpEnabled?: boolean;
  appointmentType: {
    id: string;
    eventName: string;
    locationType: {
      value: string;
      code: string;
    };
    category: {
      code: string;
    };
  };
  externalAppointmentId?: string;
}

export interface NoteApiResponse {
  resourceType: string;
  type: string;
  total: number;
  entry: NoteEntry[];
}

export interface NoteEntry {
  resource: NotesResource;
  profileId?: string;
  isContactActive?: boolean;
  locationGroupId?: string[];
  notesAccountLocationId?: string;
}

export interface NotesResource {
  resourceType: string;
  id?: number;
  status: string;
  docStatus: string;
  type: Type;
  category: Category[];
  date: string;
  description: string;
  subject: Subject;
  author: Author[];
  securityLabel: SecurityLabel[];
  extension: Extension[];
  content: Content[];
  context: Context;
}

export interface Category {
  coding: Coding2[];
}

export interface Coding2 {
  system: string;
  code: string;
  display: string;
}

export interface Subject {
  reference: string;
}

export interface Author {
  reference: string;
  identifier: Identifier;
}

export interface Identifier {
  id: string;
  value: number;
}

export interface SecurityLabel {
  coding: Coding3[];
}

export interface Coding3 {
  system: string;
  code: string;
  display: string;
}

export interface Extension {
  url: string;
  valueString: any;
}

export interface Content {
  attachment: Attachment;
  format?: Format;
  extension?: Extension[];
}

export interface Attachment {
  contentType: string;
  data: string;
  title: string;
  hash: string;
  extension?: Extension[];
}

export interface Format {
  system: string;
  code: string;
  display: string;
}

export interface Context {

  encounter: Encounter[];
  period: Period;
  related?: Reference[];
}

export interface Reference {
  reference: string;
}
export interface Encounter {
  reference: string;
}

export interface Period {
  start: string;
}

export interface IPatientNote {
  status: string;
  docStatus: string;
  type: string;
  category: string;
  date: string;
  description: string;
  practitionerId: string;
  content: INoteContent[];
  id: string;
}
export interface INoteContent {
  data: string;
  title: string;
  formatCode?: string;
  formatDisplay?: string;
}

export interface DocumentReferenceProps {
  patientNote: IPatientNote;
}

export interface DRCollapseWrapperProps {
  header: string | JSX.Element;
  children: JSX.Element;
}

export interface IEncounter {
  id: string | number;
  status: string;
  type: string;
  appointmentId: string | number;
  date: string;
  locationId: string | number;
}

export interface EncounterApiResponse {
  resourceType: string;
  type: string;
  total: number;
  entry: EncounterEntry[];
}

export interface EncounterEntry {
  resource: EncounterResource;
}

export interface EncounterResource {
  resourceType: string;
  id: number;
  extension: Extension[];
  status: string;
  type: Type[];
  participant: Participant[];
  appointment: Appointment[];
  period: Period;
  location: Location[];
}

export interface Type {
  coding: Coding[];
}

export interface Coding {
  system: string;
  code: string;
  display: string;
}

export interface Participant {
  individual: Individual;
}

export interface Individual {
  reference: string;
}

export interface Appointment {
  reference: string;
}

export interface Period {
  start: string;
}

export interface Location {
  location: Location2;
}

export interface Location2 {
  reference: string;
}

export interface EncounterNotesProps {
  encounter: IEncounter;
  patientId: string;
  accountLocationId?: string;
  contactId?: number | string;
}

export interface EncounterNotesDrawerProps extends EncounterNotesProps {
  isOpen: boolean;
  onClose: () => void;
}


export interface IFormComponent {
  day?: string;
  key: string;
  data: any;
  title: string;
  orderType?: string;
}

export enum NoteSource {
  FOLD = 'FOLD',
}

export enum PatientNotesStatusValue {
  PRELIMINARY = 'In-Progress',
  FINAL = 'Signed',
  AMENDED = 'Amended',
}
