import {
  Box,
  Divider,
  HStack,
  Pressable,
  Spinner,
  Text,
  useToast,
  VStack,
  Stack,
  View,
  Icon,
} from 'native-base';
import React, {useContext, useEffect, useRef, useState} from 'react';

import Feather from 'react-native-vector-icons/Feather';
import {useIntl} from 'react-intl';
import {Popover} from 'antd';
import styles, { reactStyles } from '../PatientNotes/PatientNotesStyles';
import {
  INotesFormattedDataProps,
  IPatientNoteCardProps,
  IPatientNotesCard,
} from '../interfaces';
import {Colors} from '../../../../styles/Colors';
import {ICondition} from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/interfaces';
import {getDocumentStatusLabel, getEncounterStatus} from '../PatientNotes/components/DocumentationViewHelper';
import { getCurrentTimeZoneAbbr, getDateStrFromFormat, isSameDay} from '../../../../utils/DateUtils';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  DISPLAY_DATE_FORMAT,
  HTTP_ERROR_CODE,
  PATIENT_NOTE_API_ERROR_CODE,
} from '../../../../constants';
import {DocStatus, EHRName, EncounterStatus, NoteEntry} from '../PatientNotes/interfaces';
import {deleteDocumentReference} from '../../../../services/CommonService/AidBoxService';
import AppointmentLinkedIcon from '../../../../assets/Icons/isAppointment';
import FHAlertDialog from '../../../common/FHAlertDialog/FHAlertDialog';
import {PATIENT_NOTE_OPERATION} from '../PatientNotes/constants';
import ReactToPrint from 'react-to-print';
import {ComponentToPrint} from '../../../common/PrintComponent/ComponentToPrint';
import {PatientNotePrintPreview} from './PatientNotePrintPreview';
import {allowToShareForNoteWithPatient, getAccountUUID, getUserUUID, isLoggedInUserHealthCoach, getSanitizeString, getNoteConfigurationKey, getBooleanFeatureFlag, replaceHashValueToString} from '../../../../utils/commonUtils';
import {FormContext, IFormCommonData} from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {
  getDocumentRefByReferenceId,
  unlockPatientNoteById,
} from '../../../../services/CommonService/OrderService';
import {
  findUserById,
  getCurrentEHR,
  getFormattedNoteForCareTimeline,
} from '../PatientNotes/PatientNotesHelper';
import {getExtensionValue} from '../Orders/OrdersAndReports/OrderUtils';
import {EXTENSION_URLS} from '../PatientNotes/components/AddOrUpdateTemplate/constant';
import { GET_BOOKED_APPOINTMENT_BY_EXTERNAL_ID } from '../../../../services/Appointment/AppointmentQueries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { isWeb } from '../../../../utils/platformCheckUtils';
import { getResourceAbilities } from '../../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../../constants/FhirConstant';
import { usePrintVistHistory } from '../../../CustomHooks/usePrintVisitHistory';
import { DocumentViewer } from '../../../common/DocumentViewer/DocumentViewer';
import { MIME_TYPES } from '../../../common/DocumentViewer/DocumentViewerHelper';
import NoteRiskScoreDetails from './components/NoteRiskScoreDetails';
import { NOTE_ACTION_ERROR_CODES } from './CareTimelineConstant';
import { getPriorityIcon } from '../../../TaskCard/TaskCardHelper';
import { MLOV_CATEGORY, TASK_STATUS_CODES } from '../../../../constants/MlovConst';
import FeatherIcon from 'react-native-vector-icons/Feather';
import TaskQueries from '../../../../services/Task/TaskQueries';
import AddOrUpdateTask from '../../../common/AddTask/AddOrUpdateTask';
import { getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { getCompletedTaskStatusId, getDefaultTaskStatusId } from '../../../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { testID, TestIdentifiers } from '../../../../testUtils';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
import LockKeyHoleSvg from '../../../common/Svg/LockKeyHoleSvg';

const ExtraComponents = {
  CHIEF_COMPLAINT: 'chiefComplaint',
  DIAGNOSIS: 'diagnosis',
};

const PatientNoteCard = (props: IPatientNotesCard) => {
  const {
    ehrConfig,
    isPhysicianUser,
    elationFormData,
    accountUserList,
    personData,
    cardProps,
    ehrCapabilities,
    unFormattedContactData,
    header,
    footer,
    navigation,
    contextData,
  } = props;
  const {
    noteRiskScore,
    providerName,
    visitName,
    isEncounterNote,
    encounterType,
    resourceId,
    status,
    authorUuid,
    linkedAppointmentId,
    createdDate,
    signedByUserId,
    signedDate,
    signedByUserName,
    amendedDate,
    amendedByUserName,
    amendedByUserId,
    createdByUserName,
    formContent,
    isAllowToDelete,
    isAllowToPrint,
    isAllowToUnlock,
    isReadOnly,
    linkTasks
  } = cardProps;
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    personData?.accountLocationUuid
  );
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;
  const currentEHR = getCurrentEHR(personData?.accountLocationUuid, '');
  const isFold = currentEHR === EHRName.FOLD;
  const isAppointmentLinked = !!linkedAppointmentId && isFold;
  const contactUUID = unFormattedContactData?.uuid || personData?.contactData?.uuid;
  const patientId = personData?.patientId || personData?.patientUuid || '';
  const intl = useIntl();
  const toast = useToast();
  const accountUUID = getAccountUUID();
  const currentUserId = getUserUUID();
  const context = useContext(CommonDataContext) as IFormCommonData;
  const userSettings = context.userSettings;
  const allowToSharePatientHtml = allowToShareForNoteWithPatient(userSettings);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const noteConfigurationKey = getNoteConfigurationKey(userSettings);
  const isNoteConfigurationKey = !!noteConfigurationKey;
  const isNoteLocked = isMultiTenancyEnabled && !cardProps?.isContactActive;
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState({
    modalVisible: false,
    linkTaskDeleteMessageModalVisible: false,
    loading: false,
  });
  const [isActionPopoverVisible, setIsActionPopoverVisible] = useState(false);
  const [showMore, setShowMore] = useState<{
    [key: string]: boolean;
  }>({
    [ExtraComponents.DIAGNOSIS]: false,
    [ExtraComponents.CHIEF_COMPLAINT]: false,
  });
  const formOptions = {
    userSettings: context.userSettings,
    accountUUID,
    ehrCapabilities,
    formContext: FormContext.patientNotes,
    ehrActivePatient: unFormattedContactData?.contactProfile || personData?.contactProfile,
  };
  const [patientNoteCardState, setPatientNoteState] = useState<{
    startPrinting: boolean;
    isProcessing: boolean;
    isConfirmationModalOpen: boolean;
    alreadySignByUserName: string;
    updatedSignedNote: any;
    deleteNoteErrorCode: string;
    appointmentData: any;
    showPDFViewer: boolean;
    pdfBlobUrl: string;
    showTaskDrawer: boolean;
    printShareable: boolean;
  }>({
    startPrinting: false,
    isProcessing: false,
    updatedSignedNote: undefined,
    alreadySignByUserName: '',
    isConfirmationModalOpen: false,
    deleteNoteErrorCode: '',
    appointmentData: {},
    showPDFViewer: false,
    pdfBlobUrl: '',
    showTaskDrawer: false,
    printShareable: false,
  });
  const [customBorderColor, setCustomBorderColor] = useState('');
  const [updateTask] = useMutation(TaskQueries.UPDATE_TASK);
  const [updateTaskStatus] = useMutation(TaskQueries.UPDATE_TASK_STATUS);
  const [getLinkedAppointmentData,{loading:linkedAppointmentDataLoading}] = useLazyQuery(
    GET_BOOKED_APPOINTMENT_BY_EXTERNAL_ID,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    },
  );
  const noDiagnosisLabel = 'No diagnosis available';

  const componentRef = useRef(null);
  const printRef: any = useRef(null);

  const taskStatusMlov = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_STATUS) || [];
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);
  const defaultStatusId = getDefaultTaskStatusId(taskStatusMlov);

  (linkTasks || []).forEach(linkTask => {
    linkTask.isCompleted = linkTask?.statusId === completedStatusId;
  })

  const handleVistHistoryPrintSuccess = (url:string) => {
    setPatientNoteState(prev => {
      return {
        ...prev,
        isProcessing: false,
        showPDFViewer: true,
        pdfBlobUrl: url,
      };
    });
  }
  const handleVistHistoryPrintError = (error: string) => {
    setPatientNoteState(prev => {
      return {
        ...prev,
        isProcessing: false,
      };
    });
    showToast(toast, error, ToastType.error);
  };
  const {triggerPrint} = usePrintVistHistory(
    patientId,
    handleVistHistoryPrintSuccess,
    handleVistHistoryPrintError,
    personData?.accountLocationUuid,
  );

  const handlePrintClick = async (printShareable?: boolean) => {
    if(isEncounterNote){
      setPatientNoteState(prev => {
        return {
          ...prev,
          isProcessing: true,
        };
      });
      triggerPrint(cardProps?.resourceId as string);
      return;
    }
    if (!!linkedAppointmentId) {
      const params = {
        externalAppointmentId: linkedAppointmentId,
      }
      const appointmentResponse = await getLinkedAppointmentData({
        variables: params,
        onError: (error) => {
          setPatientNoteState(prev => {
            return {
              ...prev,
              startPrinting: false,
              printShareable: false,
            };
          });
          showToast(
            toast,
            'Facing issue while printing. Please check and try again.',
            ToastType.error,
          );
          return;
        },
      });
     const linkedAppointmentData =
       appointmentResponse?.data?.appointments[0] || {};
     setPatientNoteState((prev) => ({
       ...prev,
       appointmentData: linkedAppointmentData,
     }));
    }
    setPatientNoteState((prev) => ({
      ...prev,
      startPrinting: true,
      ...(printShareable && {printShareable: true}),
    }));
  };

  const actionsMenu: {
    icon: JSX.Element;
    label: any;
    onPress: () => void;
  }[] = [];

  if (isAllowToPrint) {
    actionsMenu.push({
      icon: <Feather name="printer" size={20} color={Colors.Custom.Gray500} />,
      label: intl.formatMessage({id: 'print'}),
      onPress: () => {
        handlePrintClick();
      },
    });
    if (allowToSharePatientHtml) {
      actionsMenu.push({
        icon: (
          <Feather name="printer" size={20} color={Colors.Custom.Gray500} />
        ),
        label: intl.formatMessage({id: 'printShareable'}),
        onPress: () => {
          handlePrintClick(true);
        },
      });
    }
  }
  if (isAllowToDelete && !isNoteLocked) {
    actionsMenu.push({
      icon: <Feather name="trash-2" size={20} color={Colors.Custom.Gray500} />,
      label: intl.formatMessage({id: 'delete'}),
      onPress: () => {
        const isTaskLink = linkTasks && linkTasks?.length > 0 ? true : false;
        const modalVisible = !isTaskLink;
        setShowDeleteConfirmation({modalVisible: modalVisible, loading: false, linkTaskDeleteMessageModalVisible: isTaskLink});
      }
    });
  }
  if (isAllowToUnlock && !isNoteLocked) {
    actionsMenu.push({
      icon: <Feather name="lock" size={20} color={Colors.Custom.Gray500} />,
      label: intl.formatMessage({id: 'addendum'}),
      onPress: () => {
        unlockPatientNote();
      },
    });
  }
  const isActionAvailable = actionsMenu.length > 0;
  const isExpanded = (key: string): boolean => showMore[key];
  const handleShowMoreClick = (key: string) => {
    setShowMore(prev => ({...prev, [key]: !prev[key]}));
  };

  const isOnlyChiefComplaintExist = () => {
    if (!formContent || !formContent?.length) {
      return false;
    }
    const chiefComplaint = formContent?.find(
      (component: any) => component.key === ExtraComponents.CHIEF_COMPLAINT,
    );
    const diagnosis = formContent?.find(
      (component: any) => component.key === ExtraComponents.DIAGNOSIS,
    );
    const sanitizeData = getSanitizeString(diagnosis?.data)
    const diagnosisData = !!diagnosis && JSON.parse(sanitizeData || `{}`);
    return chiefComplaint && !diagnosisData?.conditions?.length;
  };

  const unlockPatientNote = () => {
    setPatientNoteState(prev => ({...prev, isProcessing: true}));
    unlockPatientNoteById(`${cardProps.resourceId}`, personData?.accountLocationUuid, cardProps.isFoldDrivenNote)
      .then(response => {
        setPatientNoteState(prev => ({...prev, isProcessing: false}));
        // showToast(toast, 'Unlock note successful', ToastType.info, 2000);
        if (
          props?.onActionPerformed &&
          typeof props?.onActionPerformed === 'function'
        ) {
          props?.onActionPerformed(PATIENT_NOTE_OPERATION.UNLOCK, cardProps);
        }

        if (linkTasks && linkTasks?.length && linkTasks?.[0]?.id) {
          const linkTask = linkTasks[0];
          updateTask({
            context: {service: CARESTUDIO_APOLLO_CONTEXT},
            variables: {
              params: {
                id: linkTask?.id,
                data: {
                  statusId: defaultStatusId
                }
              },
            }
          });
        }
      })
      .catch(error => {
        if(error?.response?.data?.errorCode === NOTE_ACTION_ERROR_CODES.UNAUTH_USER){
          showToast(
            toast,
            'User is not authorized to unlock this note.',
            ToastType.error,
            2000,
          );
        }
        setPatientNoteState(prev => ({...prev, isProcessing: false}));
        showToast(
          toast,
          'Facing issue while unlock note. Please check and try again.',
          ToastType.error,
          2000,
        );
      });
  };

  const renderActionMenu = () => {
    return (
      <VStack>
        {actionsMenu.map((action, index) => (
          <>
            <Pressable
              key={index}
              onPress={() => {
                setIsActionPopoverVisible(false);
                action.onPress();
              }}
              disabled={patientNoteCardState.startPrinting}
              padding={2}
              flexDirection="row"
              alignItems="center">
              <Box mr={4}>{action.icon}</Box>
              <Text color={Colors.Custom.Gray500} fontSize={12} {...testID(`${action?.label}Btn`)}>
                {action.label}
              </Text>
            </Pressable>
            {index !== actionsMenu.length - 1 && <Divider />}
          </>
        ))}
      </VStack>
    );
  };

  const renderChiefComplaint = (): JSX.Element => {
    if (!formContent || !formContent?.length) {
      return <></>;
    }
    const component = formContent?.find(
      (component: any) => component.key === ExtraComponents.CHIEF_COMPLAINT,
    );
    if (component) {
      const sanitizeData = getSanitizeString(component.data)
      let value = "";
      try {
        value = JSON.parse(sanitizeData || `{}`)?.chiefComplaint?.displayName;
      }catch(error) {
      }
      return !!value ? (
        <Text my={0.5} color={Colors.Custom.Gray500} fontSize={12}>
          <Text>Chief Complaint: </Text>
          {isExpanded(ExtraComponents.CHIEF_COMPLAINT)
            ? value
            : value.substring(0, 90)}
          <Text
            color={Colors.Custom.PurpleColor}
            onPress={() =>
              handleShowMoreClick(ExtraComponents.CHIEF_COMPLAINT)
            }>
            {value.length > 90 &&
              (isExpanded(ExtraComponents.CHIEF_COMPLAINT)
                ? ' - Show less'
                : ' + Show more')}
          </Text>
        </Text>
      ) : (
        <></>
      );
    }
    return <></>;
  };

  // TODO: need to refactor this
  const renderDiagnosis = (): string => {
    if (!formContent || !formContent?.length) {
      return noDiagnosisLabel;
    }
    const component = formContent?.find(
      (component: any) => component.key?.split('_')[0] === ExtraComponents.DIAGNOSIS
    );
    if (component) {
      const sanitizeData = getSanitizeString(component.data)
      const value: string = JSON.parse(sanitizeData || `{}`)
        ?.conditions.map(
          (item: ICondition) => item?.condition?.text || item?.name
        )
        .join(', ');
      return value.length > 0 ? value : noDiagnosisLabel;
    }
    return noDiagnosisLabel;
  };


  const renderCardHeadLine = (text:JSX.Element): JSX.Element => {
    return (
      <HStack my={0.5} alignItems='center'>
        <Text color={Colors.FoldPixel.GRAY400} size={'smMedium'}>
         {text}
        </Text>
      </HStack>
    );
  };



  const renderDateAndStatus = () => {
    const isUnlockDocumentRef = signedDate && signedByUserId ? true : false;
    let statusId = DocStatus.PRELIMINARY;
    if (status == DocStatus.AMENDED) {
      statusId = DocStatus.AMENDED;
    } else if (status === DocStatus.FINAL) {
      statusId = DocStatus.FINAL;
    }

    const statusIntlId = isEncounterNote
      ? getEncounterStatus(status as EncounterStatus)
      : getDocumentStatusLabel(statusId, isUnlockDocumentRef);
    const displayDate  =  getDateStrFromFormat(createdDate, DISPLAY_DATE_FORMAT)
    return (
      <HStack my={0.5} alignItems="center">
        <Text color={Colors.FoldPixel.GRAY300} size={'xsMedium'}>
          {`${displayDate}`}
        </Text>
        {!!cardProps.description &&
          <>
            <Text color={Colors.Custom.Gray500}> • </Text>
            <Text color={Colors.FoldPixel.GRAY300} size={'xsMedium'}>
              {`${cardProps.formName}`}
            </Text>
          </>
        }
        {displayDate?.length > 0 && statusIntlId?.length > 0 && (
          <Text color={Colors.Custom.Gray500}> • </Text>
        )}
        {statusIntlId.length > 0 ? (
          <Text
            color={
              status === DocStatus.PRELIMINARY || status === EncounterStatus.IN_PROGGRESS
                ? Colors.Custom.ErrorColor
                : Colors.FoldPixel.GRAY250
            }
            size={'xsMedium'}>
            {intl.formatMessage({id: statusIntlId})}
          </Text>
        ) : null}
        {statusIntlId?.length > 0 && isNoteLocked && (
          <Text color={Colors.Custom.Gray500}> • </Text>
        )}
        {isNoteLocked && (
          <Popover
            overlayClassName='raf-score-popover'
            overlayInnerStyle={{padding: 10, borderRadius: 8}}
            placement={'bottom'}
            content={
              <View width={'170px'} borderRadius={8}>
                <Text 
                  style={{
                    fontSize: 12,
                    fontWeight: '400',
                    color: Colors.FoldPixel.GRAY400,
                    textAlign: 'center',
                  }}
                >
                  {replaceHashValueToString(
                    {name: 'Patient Note'},
                    intl.formatMessage({id: 'onlyViewAccessMsg2'})
                  )}
                </Text>
              </View>
            }
          >
            <View>
              <LockKeyHoleSvg size={14} />
            </View>
          </Popover>
        )}
      </HStack>
    );
  };

  // TODO : need to refactor this function through @abhishek
  const renderAuthoredAndSignedBy = () => {
    const isAlreadySignedNote = signedDate && signedByUserId;
    const isAuthorAndSignedBySame = authorUuid === signedByUserId;
    const isDateSame =
      signedDate && createdDate ? isSameDay(signedDate, createdDate) : false;
    const isAmendedDateSame = amendedDate && createdDate ? isSameDay(amendedDate, createdDate) : false;
    const isAuthorAndAmendedBySame = authorUuid === amendedByUserId;
    const authoredDateStr = createdDate
      ? getDateStrFromFormat(createdDate || '', DISPLAY_DATE_FORMAT,)
      : '';
    const signedDateStr = signedDate ? getDateStrFromFormat(signedDate, DISPLAY_DATE_FORMAT) : '';
    const amendedDateStr = amendedDate ? getDateStrFromFormat(amendedDate, DISPLAY_DATE_FORMAT) : '';
    const timezoneAbbr = getCurrentTimeZoneAbbr();
    if (!isAlreadySignedNote && status === DocStatus.PRELIMINARY) {
      return (
        <Text size={'xsMedium'} my={0.5} color={Colors.FoldPixel.GRAY400}>
          {createdByUserName && authoredDateStr
            ? `Created by: ${createdByUserName} ${
                createdDate ? `at ${authoredDateStr} (${timezoneAbbr})` : ''
              }`
            : createdByUserName
            ? `Created by: ${createdByUserName}`
            : authoredDateStr
            ? `Created at: ${authoredDateStr} (${timezoneAbbr})`
            : ''}
        </Text>
      );
    }
    else if (
      status === DocStatus.FINAL ||
      isAlreadySignedNote ||
      status === DocStatus.AMENDED
    ) {
      const combineDisplayString = [];
      if (createdByUserName && authoredDateStr) {
        combineDisplayString.push(
          `Created by: ${createdByUserName} ${
            createdDate ? `at ${authoredDateStr} (${timezoneAbbr})` : ''
          }`
        );
      }
      if (status === DocStatus.AMENDED && amendedByUserName && amendedDateStr) {
        combineDisplayString.push(
          `Signed and Amended by: ${amendedByUserName} ${`at ${amendedDateStr} (${timezoneAbbr})`}`
        );
      }
      else if (signedByUserName && signedDateStr) {
        combineDisplayString.push(
          `Signed by: ${signedByUserName} ${
            signedDate ? `at ${signedDateStr} (${timezoneAbbr})` : ''
          }`
        );
      }
      if (isAuthorAndAmendedBySame) {
        return isAmendedDateSame ? (
          <Text my={0.5} fontSize={12} color={Colors.Custom.Gray500}>
            {`Created and Amended by: ${createdByUserName} ${
              createdDate ? `at ${authoredDateStr} (${timezoneAbbr})` : ''
            }`}
          </Text>
        ) : (
          <HStack alignItems="center">
            <Text my={0.5} fontSize={12} color={Colors.Custom.Gray500}>
              {combineDisplayString.join(' • ')}
            </Text>
          </HStack>
        );
      }
      else if (isAuthorAndSignedBySame && status !== DocStatus.AMENDED) {
        return isDateSame ? (
          <Text my={0.5} fontSize={12} color={Colors.Custom.Gray500}>
            {`Created and Signed by: ${createdByUserName} ${
              createdDate ? `at ${authoredDateStr} (${timezoneAbbr})` : ''
            }`}
          </Text>
        ) : (
          <HStack alignItems="center">
            <Text my={0.5} fontSize={12} color={Colors.Custom.Gray500}>
              {combineDisplayString.join(' • ')}
            </Text>
          </HStack>
        );
      } else {
        return (
          <HStack alignItems="center">
            <Text my={0.5} fontSize={12} color={Colors.Custom.Gray500}>
              {combineDisplayString.join(' • ')}
            </Text>
          </HStack>
        );
      }
    }
  };

  const handleAlreadySignNoteError = async (errorCode?: string) => {
    const documentReference = await getDocumentRefByReferenceId(
      `${cardProps?.resourceId}`,
      foldVisitNoteWithEncountersEnabled,
      personData?.accountLocationUuid
    );
    const resource = documentReference?.data;
    const signByUserId = getExtensionValue(resource, EXTENSION_URLS.signedBy);
    const selectedUser = findUserById(signByUserId, accountUserList);
    const noteEntry: NoteEntry = {resource} as NoteEntry;
    const formatResponseParam: INotesFormattedDataProps = {
      noteResponse: [noteEntry],
      ehrConfig,
      loginUserId: currentUserId,
      contextData,
      accountUserList: accountUserList || [],
      elationFormData: elationFormData,
      additionalFlags:{
        foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
        isMultiTenancyEnabled: isMultiTenancyEnabled,
      }
    };
    const patientNotes = getFormattedNoteForCareTimeline(formatResponseParam);
    setPatientNoteState(prev => {
      return {
        ...prev,
        isConfirmationModalOpen: true,
        alreadySignByUserName: selectedUser?.name,
        updatedSignedNote: patientNotes?.length ? patientNotes[0] : undefined,
        deleteNoteErrorCode: errorCode || '',
      };
    });
  };


  const renderTextInfo = (): JSX.Element => {
    if (isEncounterNote) {
      return renderEncounterInfo();
    }
    return (
      <>
        {renderDateAndStatus()}
        {renderCardHeadLine(
          <>
            {`${cardProps.description || cardProps.formName}`}
            {isFold && (
              <Text
                fontWeight="normal"
                color={Colors.Custom.Gray500}
                fontSize={14}
              >{` • ${renderDiagnosis()}`}</Text>
            )}
          </>
        )}
        {isOnlyChiefComplaintExist() && renderChiefComplaint()}
        {renderAuthoredAndSignedBy()}
      </>
    );
  };

  const deleteNote = () => {
    if (resourceId) {
      setShowDeleteConfirmation(prev => ({
        ...prev,
        loading: true,
      }));
      deleteDocumentReference(`${resourceId}`, foldVisitNoteWithEncountersEnabled, personData?.accountLocationUuid)
        .then(async res => {
          if (res.data.success) {
            if (linkTasks?.length) {
              const linkTask = linkTasks[0];
              await updateTask({
                context: {service: CARESTUDIO_APOLLO_CONTEXT},
                variables: {
                  params: { id: linkTask?.id, data:{ isDeleted:true } },
                }
              });
            }
            showToast(
              toast,
              intl.formatMessage({id: 'noteDeletedSuccessfully'}),
              ToastType.success,
              2000
            );
            if (
              props?.onActionPerformed &&
              typeof props?.onActionPerformed === 'function'
            ) {
              props.onActionPerformed(
                PATIENT_NOTE_OPERATION.DELETE,
                props.cardProps,
              );
            }
          }
        })
        .catch(error => {
          if (error && error?.response?.status === HTTP_ERROR_CODE.RESOURCE_NOT_FOUND && !error?.response?.data?.errorCode) {
            showToast(
              toast,
              `This note has already deleted`,
              ToastType.error,
              2000
            );
            if (props?.onActionPerformed && typeof props?.onActionPerformed === 'function') {
              props?.onActionPerformed(PATIENT_NOTE_OPERATION.REFETCH, {} as IPatientNoteCardProps);
            }
            return;
          }
          if (error && error?.response?.status === HTTP_ERROR_CODE.INVALID_INPUT) {
            setShowDeleteConfirmation(prev => ({
              ...prev,
              loading: false,
              modalVisible: false,
            }));
            handleAlreadySignNoteError(error?.response?.data?.errorCode);
            return;
          }
          showToast(
            toast,
            intl.formatMessage({id: 'apiErrorMsg'}),
            ToastType.error,
            2000
          );
        })
        .finally(() => {
          setShowDeleteConfirmation(prev => ({
            ...prev,
            loading: false,
            modalVisible: false,
          }));
        });
    }
  };

  const handleNoteCardPress = () => {
    let operationCode = '';

    if (status === DocStatus.PRELIMINARY) {
      operationCode = PATIENT_NOTE_OPERATION.EDIT_NOTE;
    } else if (status === DocStatus.FINAL || status === DocStatus.AMENDED) {
      operationCode = PATIENT_NOTE_OPERATION.VIEW_SIGNED_NOTE;
    }
    if (
      props?.onActionPerformed &&
      typeof props?.onActionPerformed === 'function'
    ) {
      if (isEncounterNote) {
        props?.onActionPerformed(PATIENT_NOTE_OPERATION.VIEW_ENCOUNTER, props.cardProps);
        return
      }

      if ((ehrConfig.isElation && !cardProps.isFoldDrivenNote) || isNoteLocked) {
        if (isReadOnly) {
          props?.onActionPerformed(PATIENT_NOTE_OPERATION.READ_ONLY_NOTE, props.cardProps);
          return;
        } else {
          props?.onActionPerformed(PATIENT_NOTE_OPERATION.EDIT_NOTE, props.cardProps);
          return;
        }
      }
      props?.onActionPerformed(operationCode, props.cardProps);
    }
  };

  const renderPrintComponent = () => {
    return (
      <>
        {patientNoteCardState.startPrinting && (
          <PatientNotePrintPreview
            ehrConfig={ehrConfig}
            printShareable={patientNoteCardState?.printShareable}
            appointmentData={patientNoteCardState.appointmentData}
            personData={personData}
            isDefaultDataAvailable={false}
            notesData={cardProps}
            formOptions={formOptions}
            onRenderComponent={(error?: any) => {
              if (error) {
                setPatientNoteState(prev => {
                  return {
                    ...prev,
                    startPrinting: false,
                    printShareable: false,
                  };
                });
                showToast(
                  toast,
                  'Facing issue while printing. Please check and try again.',
                  ToastType.error,
                );
                return;
              }
              printPatientNotes();
            }}
          />
        )}
      </>
    );
  };

  const printPatientNotes = () => {
    if (printRef?.current && printRef?.current?.handleClick) {
      printRef.current.handleClick();
    }
  };

  const getConfirmationModalMessage = () => {
    if (patientNoteCardState.deleteNoteErrorCode == PATIENT_NOTE_API_ERROR_CODE.SIGNED_NOTE_DELETE) {
      return `This note has already been signed by ${patientNoteCardState.alreadySignByUserName}`;
    } else {
      return `This note includes completed orders. Hence, this note cannot be deleted.`;
    }
  };

  const getConfirmationModalAction = () => {
    if (patientNoteCardState.deleteNoteErrorCode == PATIENT_NOTE_API_ERROR_CODE.SIGNED_NOTE_DELETE) {
      return [
        {
          textLocalId: 'refresh',
          buttonProps: {
            variant: BUTTON_TYPE.PRIMARY,
          },
          onPress: () => {
            if (props?.onActionPerformed && typeof props?.onActionPerformed === 'function') {
              props?.onActionPerformed(PATIENT_NOTE_OPERATION.REFETCH, {} as IPatientNoteCardProps);
            }
          },
        },
        {
          textLocalId: 'viewSignedMessage',
          buttonProps: {
            variant: BUTTON_TYPE.PRIMARY,
          },
          onPress: async () => {
            if (props?.onActionPerformed && typeof props?.onActionPerformed === 'function') {
              props?.onActionPerformed(PATIENT_NOTE_OPERATION.VIEW_SIGNED_NOTE, patientNoteCardState.updatedSignedNote ||{} as IPatientNoteCardProps);
            }
          },
        },
      ]
    } else {
      return [
        {
          textLocalId: 'Ok',
          buttonProps: {
            variant: BUTTON_TYPE.PRIMARY,
          },
          onPress: () => {
            setPatientNoteState(prev => ({ ...prev, isConfirmationModalOpen: false}));
          }
        }];
    }
  }

  const getDocumentTitle = () => {
    return `${personData.name}`;
  };

  const renderEncounterProviderName = () => {
    return (
      <Text my={0.5} color={Colors.Custom.Gray500} fontSize={12}>
        {`Provider: ${providerName}`}
      </Text>
    );
  }

  const renderEncounterInfo = () => {
    return (
      <>
        {renderDateAndStatus()}
        {renderCardHeadLine(<>{visitName}</>)}
        {renderEncounterProviderName()}
      </>
    );
  };

  const getTaskStatusWiseBackgroundColor = (statusCode: string) => {
    let statusColor = Colors.primary[700];
    switch (statusCode) {
      case TASK_STATUS_CODES.ACCEPTED:
        statusColor = Colors.Custom.PendingTaskBackgroundColor;
        break;
      case TASK_STATUS_CODES.COMPLETED:
        statusColor = Colors.Custom.CompletedTaskBackgroundColor;
        break;
      case TASK_STATUS_CODES.MISSED:
        statusColor = Colors.Custom.MissedTaskBackgroundColor
        break;
    }
    return statusColor;
  }

  const getTaskStatusWiseFontColor = (statusCode: string) => {
    let statusColor = Colors.primary[700];
    switch (statusCode) {
      case TASK_STATUS_CODES.ACCEPTED:
        statusColor = Colors.Custom.PendingTaskFontColor;
        break;
      case TASK_STATUS_CODES.COMPLETED:
        statusColor = Colors.Custom.CompletedTaskFontColor;
        break;
      case TASK_STATUS_CODES.MISSED:
        statusColor = Colors.Custom.MissedTaskFontColor;
        break;
    }
    return statusColor;
  }

  const renderNoteLinkTask = () => {
    const linkTask = linkTasks?.[0];
    if (linkTask?.id) {
      const backgroundColor = getTaskStatusWiseBackgroundColor(linkTask?.status?.code || '');
      const fontColor = getTaskStatusWiseFontColor(linkTask?.status?.code || '')
      return (
        <Stack
          justifyContent={'center'}
          direction={'column'}
          key={`task_detail_${resourceId}`}
          style={styles.outerNoteTaskCard}
        >
          <Stack space={2} padding={1} direction={'row'} flex={1} alignItems={'center'}>
            <View flex={0.03} flexDirection="row" justifyContent={'flex-start'}>
              {linkTask?.priority && (
                <View paddingLeft={2}>
                  {getPriorityIcon(linkTask?.priority, true)}
                </View>
              )}
            </View>
            <View flex={0.95} flexDirection="row" alignItems={'flex-start'}>
              {isNoteConfigurationKey ? (
                <Text>
                  {`${
                    cardProps.description ||
                    cardProps.formName ||
                    linkTask?.title
                  }`}
                </Text>
              ) : (
                linkTask?.title && (
                  <Text
                    marginLeft={2}
                    numberOfLines={1}
                    ellipsizeMode={'clip'}
                  >{`${linkTask?.title}`}</Text>
                )
              )}
            </View>
            {linkTask?.status && linkTask?.status?.value && (
              <View
                flex={0.15}
                flexDirection="row"
                justifyContent={'center'}
                backgroundColor={backgroundColor}
                borderRadius={4}
              >
                <Text size={'smRegular'} color={fontColor}>
                  {linkTask.status.value}
                </Text>
              </View>
            )}
            <Pressable
              onHoverIn={() => {
                setCustomBorderColor(Colors.FoldPixel.GRAY50);
              }}
              onHoverOut={() => {
                setCustomBorderColor('');
              }}
              onPress={() => {
                setPatientNoteState((prev) => {
                  return {
                    ...prev,
                    showTaskDrawer: true,
                  };
                });
              }}
              alignItems={'flex-end'}
            >
              <Icon
                size="5"
                as={FeatherIcon}
                name="arrow-up-right"
                backgroundColor={customBorderColor}
                color={Colors.Custom.Gray300}
              />
            </Pressable>
          </Stack>
        </Stack>
      );
    }
    return <></>;
  };

  return (
    <>
      <VStack
        key={resourceId}
        style={styles.noteCard}
        my={1}
        mx={!isWeb() ? 3 : undefined}
        mt={!isWeb() ? 3 : undefined}
      >
        <HStack flex={1}>
          <HStack flex={9} alignItems="center">
            <VStack flex={1}>
              <>{renderTextInfo()}</>
            </VStack>
          </HStack>
          <HStack alignItems="center">
            {isAppointmentLinked && <AppointmentLinkedIcon />}
            <Pressable
              onPress={() => {
                handleNoteCardPress();
              }}
              style={styles.viewPressable}
            >
              <Text color={Colors.Custom.PurpleColor}>
                {intl.formatMessage({id: 'view'})}
              </Text>
            </Pressable>
            {isActionAvailable && (
              <>
                <Divider
                  orientation={'vertical'}
                  style={styles.divider}
                />
                {patientNoteCardState.startPrinting ||
                patientNoteCardState.isProcessing ||
                linkedAppointmentDataLoading ? (
                  <Spinner
                    style={styles.spinner}
                    color={Colors.Custom.Gray500}
                  />
                ) : (
                  <Popover
                    placement="bottom"
                    content={renderActionMenu}
                    trigger="click"
                    open={isActionPopoverVisible}
                    onOpenChange={(isOpen) => {
                      setIsActionPopoverVisible(isOpen);
                    }}
                    overlayInnerStyle={{
                      borderRadius: 16,
                      minWidth: 120,
                    }}
                  >
                    <Pressable onPress={() => setIsActionPopoverVisible(true)} {...testID(TestIdentifiers.threeDots)}>
                      <Feather
                        name={'more-horizontal'}
                        size={20}
                        color={Colors.Custom.Gray500}
                        style={styles.viewPressable2}
                      />
                    </Pressable>
                  </Popover>
                )}
              </>
            )}
          </HStack>
        </HStack>
        {cardProps?.isFoldDrivenNote && (
          <HStack w="100%">
            <NoteRiskScoreDetails formLogId={cardProps.formLogId}/>
          </HStack>
        )}
        {linkTasks && linkTasks?.length > 0 && (
          renderNoteLinkTask()
        )}
      </VStack>
      {showDeleteConfirmation.modalVisible && (
        <FHAlertDialog
          isOpen={showDeleteConfirmation.modalVisible}
          header={'Delete Note'}
          message={intl.formatMessage({
            id: 'deleteNoteConfirmation',
          })}
          buttonActions={[
            {
              textLocalId: 'no',
              buttonProps: {
                variant: BUTTON_TYPE.SECONDARY,
              },
              onPress: () => {
                setShowDeleteConfirmation((prev) => ({
                  ...prev,
                  modalVisible: false,
                }));
              },
            },
            {
              textLocalId: 'yes',
              textColor: 'white',
              buttonProps: {
                variant: BUTTON_TYPE.PRIMARY,
                isLoading: showDeleteConfirmation.loading,
              },
              onPress: () => {
                deleteNote();
              },
            },
          ]}
        />
      )}
      {showDeleteConfirmation.linkTaskDeleteMessageModalVisible && (
        <FHAlertDialog
          isOpen={showDeleteConfirmation.linkTaskDeleteMessageModalVisible}
          header={'Delete Link Note Task'}
          message={intl.formatMessage({
            id: 'deleteLinkTaskNote',
          })}
          buttonActions={[
            {
              textLocalId: 'no',
              buttonProps: {
                variant: BUTTON_TYPE.SECONDARY,
              },
              onPress: () => {
                setShowDeleteConfirmation((prev) => ({
                  ...prev,
                  modalVisible: false,
                  linkTaskDeleteMessageModalVisible: false
                }));
              },
            },
            {
              textLocalId: 'yes',
              textColor: 'white',
              buttonProps: {
                variant: BUTTON_TYPE.PRIMARY,
                isLoading: showDeleteConfirmation.loading,
              },
              onPress: () => {
                setShowDeleteConfirmation((prev) => ({
                  ...prev,
                  modalVisible: true,
                  linkTaskDeleteMessageModalVisible: false
                }));
              },
            },
          ]}
        />
      )}
      {isWeb() && (
        <>
          <ReactToPrint
            trigger={() => <></>}
            content={() => componentRef.current}
            ref={printRef}
            onAfterPrint={() => {
              setPatientNoteState((prev) => {
                return {
                  ...prev,
                  startPrinting: false,
                  printShareable: false,
                };
              });
            }}
            print={(target) => {
              return new Promise<any>((resolve, reject) => {
                if (target.contentDocument) {
                  target.contentDocument.title = getDocumentTitle();
                }
                target?.contentWindow?.print?.();
                resolve(true);
              });
            }}
            removeAfterPrint={true}
          />
          <div style={reactStyles.displayNone} key={resourceId}>
            <ComponentToPrint
              ref={componentRef}
              header={header}
              footer={footer}
            >
              {renderPrintComponent()}
            </ComponentToPrint>
          </div>
        </>
      )}
      {patientNoteCardState.showPDFViewer && (
        <DocumentViewer
          fileName={visitName || ''}
          fileType={MIME_TYPES.PDF}
          onClose={() => {
            setPatientNoteState((prev) => ({
              ...prev,
              showPDFViewer: false,
              pdfBlobUrl: '',
            }));
          }}
          fileUrl={patientNoteCardState.pdfBlobUrl}
          isOpen={true}
        />
      )}
      {patientNoteCardState.isConfirmationModalOpen && (
        <FHAlertDialog
          isOpen={patientNoteCardState.isConfirmationModalOpen}
          header={'Delete note'}
          message={getConfirmationModalMessage()}
          buttonActions={getConfirmationModalAction()}
        />
      )}
      {linkTasks && linkTasks?.length > 0 && patientNoteCardState.showTaskDrawer && (
        <AddOrUpdateTask
          task={linkTasks[0]}
          isVisible={patientNoteCardState.showTaskDrawer}
          onCancel={() => {
            setPatientNoteState(prev => {
              return {
                ...prev,
                showTaskDrawer: false
              }
            });
            if (props?.onActionPerformed && typeof props?.onActionPerformed === 'function') {
              props?.onActionPerformed(PATIENT_NOTE_OPERATION.REFETCH, {} as IPatientNoteCardProps);
            }
          }}
          onComplete={(data) => {
            setPatientNoteState(prev => {
              return {
                ...prev,
                showTaskDrawer: false
              }
            });
            if (props?.onActionPerformed && typeof props?.onActionPerformed === 'function') {
              props?.onActionPerformed(PATIENT_NOTE_OPERATION.REFETCH, {} as IPatientNoteCardProps);
            }
          }}
          onMarkAsComplete={async (task) => {
            if (status === DocStatus.PRELIMINARY) {
              showToast(
                toast,
                intl.formatMessage({
                  id: 'completeTaskLinkNoteMessage',
                }),
                ToastType.info,
                2000,
              );
              return;
            }
            await updateTaskStatus({
              context: {service: CARESTUDIO_APOLLO_CONTEXT},
              variables: {
                params: {
                  id: task.id,
                  data: {
                    statusId: completedStatusId,
                  },
                }
              },
            });
            if (props?.onActionPerformed && typeof props?.onActionPerformed === 'function') {
              props?.onActionPerformed(PATIENT_NOTE_OPERATION.REFETCH, {} as IPatientNoteCardProps);
            }
          }}
          onNoteRedirect={() => {
            handleNoteCardPress();
          }}
        />
      )}
    </>
  );
};

export default PatientNoteCard;
