import {View} from "react-native";
import {TELEPHONY_VIEW} from "../../../constants/TelephonyConst";
import {AddCallExtensionDrawer} from "./AddCallExtensionDrawer";
import {useEffect, useState} from "react";
import {CallExtensionNoDataView} from "./CallExtensionNoDataView";
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst";
import {CallExtensionTable} from "./CallExtensionTable";
import {getFormattedCallExtensionList} from "./CallExtensionUtils";
import {deleteCallExtension, getCallExtensionList, updateCallExtensionStatus} from "./AddCallExtensionService";
import {ICustomToast} from "../Contacts/CustomField/interface";
import {useToast} from "../../Toast/ToastProvider";
import {CUSTOM_FIELD_TOAST_ERROR_DURATION, CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG} from "../Contacts/CustomField/CustomFieldConst";
import {ToastType} from "../../../utils/commonViewUtils";
import {useIntl} from "react-intl";
import {ICallExtensionConfigurationTable, ICallExtensionConfigurationTableState, IExtensionList} from "./interface";
import {FHAlertDialog} from "../../common/FHAlertDialog";
import {BUTTON_TYPE} from "../../../constants";
import {useLazyQuery} from "@apollo/client";
import UserPracticeLocationQueries from "../../../services/Location/UserPracticeLocationQueries";
import {getAccountUUID, getAllowedUserAccountLocationUuids, getAllowedUserPracticeLocationUuids, getUserId, getUserUUID, isLoggedInUserWorkFlowOrCustomerSuccess, isLoginUserBusinessOwner} from "../../../utils/commonUtils";
import {getLocationUuidFromPracticeLocations} from "../../common/CustomUserSearch/CustomUserSearchUtils";
import {ExtensionDeleteConfirmationModal} from "./ExtensionDeleteConfirmationModal";
import {isAccountConfigEnabled} from "../../../utils/configUtils";
import {CONFIG_CODES} from "../../../constants/AccountConfigConst";
import { MAIN_MENU_CODES } from "../../SideMenuBar/SideBarConst";
import { USER_ACCESS_PERMISSION } from "../UserAccess/UserAccessPermission";
import { SEARCH_USERS_BY_ROLE_CODES } from "../../../services/User/UserQueries";
import { getUserSearchByBoRoleWhereCondition } from "../../common/OutboundCallSmsView/utils";
import {CALL_CONFIGURATION_TRANSFER_TO_CODE} from "./CallExtensionConst";
import {IInbox} from "../../../services/Inbox/interfaces";
import InboxQueries from "../../../services/Inbox/InboxQueries";
import {CHANNEL_TYPE} from "../TeamInbox/Conversations/ConversationConst";

export const ExtensionConfigurationTable = (props: ICallExtensionConfigurationTable) => {
  const [stateData, setStateData] = useState<ICallExtensionConfigurationTableState>({
    loading: true,
    locationLoading: false,
    extensionList: [],
    total: 0,
    limit: 10,
    pageNo: 1,
    selectedCallExtension: {} as IExtensionList,
    showDeleteModal: false,
    locationUuids: [],
    userPracticeLocation: [],
    userWithMatchingLocation: [],
    numberList: [],
    isFirstFetch: true,
  })
  const toast = useToast();
  const intl = useIntl();
  const currentUserUUID = getUserUUID();
  const accountUuid = getAccountUUID();
  const userId = getUserId();
  const allowedUserAccountLocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CALL
  );
  const isCSAndWorkFlowUserEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_CS_AND_WORKFLOW_USER_ENABLED);
  const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CALL
  );
  const isAllowedAllLocation = isLoginUserBusinessOwner() || isLoggedInUserWorkFlowOrCustomerSuccess();
  const isEnableCommunicationLocationHandling = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const isCommunicationLocationHandlingEnabled = !isAllowedAllLocation && isEnableCommunicationLocationHandling;
  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }
  const [getUserPracticeLocation] = useLazyQuery(
    UserPracticeLocationQueries.GetMyPracticeLocations,
    {
      fetchPolicy: 'no-cache',
      variables: {
        userUuid: currentUserUUID,
      },
    }
  );

  const [GetUsersByUserPracticeLocations] = useLazyQuery(
    UserPracticeLocationQueries.GetUsersByUserPracticeLocations, {
      fetchPolicy: 'no-cache',
    }
  );

  const [getUsersByRoles] = useLazyQuery(SEARCH_USERS_BY_ROLE_CODES, {
    fetchPolicy: 'no-cache',
  });

  const [getTwilioSmsInboxesDataForUser] = useLazyQuery<{inboxes: IInbox[]}>(
    InboxQueries.GetInboxesData,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getFinalData = (formattedData: IExtensionList[], matchingLocation: string[], matchingNumber: string[]) => {
    if (isCommunicationLocationHandlingEnabled) {
      return formattedData?.map((extension)=> {
        if (extension?.transferToType === CALL_CONFIGURATION_TRANSFER_TO_CODE.USER) {
          if (!matchingLocation?.includes(extension?.selectedUser?.id)) {
            return {
              ...extension,
              isDisabled: true,
            }
          }
          return extension;
        } else if (extension?.transferToType === CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER) {
          if (!matchingNumber?.includes(extension?.selectedNumber?.title) && extension?.selectedNumber?.assigneeId !== currentUserUUID) {
            return {
              ...extension,
              isDisabled: true,
            }
          }
          return extension;
        } else if (extension?.transferToType === CALL_CONFIGURATION_TRANSFER_TO_CODE.DESK_PHONE){
          if (!matchingNumber?.includes(extension?.selectedDeskPhone?.virtualPhoneNumberAssignees?.virtualPhoneNumber) && extension?.selectedDeskPhone?.virtualPhoneNumberAssignees?.assigneeId !== currentUserUUID) {
            return {
              ...extension,
              isDisabled: true,
            }
          }
          return extension;
        }
        return extension;
      })
    }
    return formattedData;
  }

  const fetchCallExtensionList = async (searchString?: string,userWithMatchingLocation?: string[], numberList?: string[]) => {
    setStateData((prev)=> {
      return {
        ...prev,
        loading: true
      }
    })
    const params = {
      pageNo: stateData?.pageNo,
      pageSize: stateData?.limit,
      searchString: searchString,
    }
    try {
      const response = await getCallExtensionList(params);
      if (response?.data?.data?.length) {
        const callExtensions = response?.data?.data;
        const formattedData = getFormattedCallExtensionList(callExtensions);
        const matchingLocation = userWithMatchingLocation || stateData?.userWithMatchingLocation;
        const matchingNumber = numberList || stateData?.numberList;
        const finalData = getFinalData(formattedData, matchingLocation, matchingNumber);
        setStateData((prev)=> {
          return {
            ...prev,
            extensionList: finalData,
            loading: false,
            total: response?.data?.count,
          }
        })
      } else {
        setStateData((prev)=> {
          return {
            ...prev,
            loading: false,
            extensionList: []
          }
        })
      }
    } catch (error: any) {
      setStateData((prev)=> {
        return {
          ...prev,
          loading: false
        }
      })
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      })
    }
  }

  const getUserLocation =  async () => {
    try {
      setStateData((prev) => {
        return {
          ...prev,
          locationLoading: true,
          isFirstFetch: false
        };
      });
      let usersWithMatchingLocations:string[] = [];
      const [userPracticeLocationsResp, userResponse] = await Promise.all([
        GetUsersByUserPracticeLocations({
          variables: {
            locationUuids: allowedUserAccountLocationUuids,
            accountUuid: accountUuid
          },
        }),
        getUsersByRoles({
          variables: {
            where: getUserSearchByBoRoleWhereCondition(isCSAndWorkFlowUserEnabled),
          }
        })
      ]);

      if (userPracticeLocationsResp?.data?.userPracticeLocations) {
        usersWithMatchingLocations =
        userPracticeLocationsResp?.data?.userPracticeLocations?.map(
          (value: {uuid: string; userUuid: string}) => {
            return value?.userUuid;
          }
        );
      }
      let boUsers:string[] = [];
      if (userResponse.data?.users?.length) {
        boUsers = userResponse.data?.users?.map(
          (value: {uuid: string}) => {
            return value?.uuid;
          }
        );
      }
      const usersWithMatchingLocationsAndBo = Array.from(new Set([...usersWithMatchingLocations, ...boUsers]));

      const twilioSmsInboxesDataForUser = await getTwilioSmsInboxesDataForUser({
        variables: {
          whereCondition: {
            channelType: {
              _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS
            },
            inboxMembers: {
              userId: {
                _eq: userId
              },
              isDeleted: {_eq: false}
            },
            isDeleted: {_eq: false}
          }
        }
      });

      const phoneNumberList = twilioSmsInboxesDataForUser?.data?.inboxes?.map((inbox) => {
        return inbox?.channelTwilioSms?.phoneNumber
      }) || [];

      setStateData((prev) => {
        return {
          ...prev,
          locationUuids: allowedUserPracticeLocationUuids,
          userPracticeLocation: usersWithMatchingLocationsAndBo,
          userWithMatchingLocation: [...usersWithMatchingLocations, currentUserUUID],
          locationLoading: false,
          numberList: phoneNumberList
        }
      });
      return {
        userWithMatchingLocation: [...usersWithMatchingLocations, currentUserUUID],
        phoneList: phoneNumberList,
      }
    } catch (error) {
      setStateData((prev) => {
        return {
          ...prev,
          locationLoading: false
        }
      });
    }
  }

  const onExtensionUpdate = (callExtension: IExtensionList) => {
    if (callExtension?.id) {
      const updatedList = stateData?.extensionList?.map((item: any) => {
        return item?.id === callExtension?.id
        ? callExtension : item
      });
      setStateData((prev) => ({
        ...prev,
        extensionList: updatedList,
        loading: false,
      }));
      showToast({
        toastType: ToastType.success,
        message: intl.formatMessage({id: 'statusUpdatedSuccessfully'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
        closeAllPrevToast: true,
      })
    }
  }

  const onStatusChange = async (callExtension: IExtensionList) => {
    setStateData((prev) => ({
      ...prev,
      loading: true,
    }));
    const payload = {
      id: callExtension?.id,
      isActive: !callExtension?.isActive
    }
    try {
      const response = await updateCallExtensionStatus(payload);
      if (response?.data?.id) {
        const updatedList = stateData?.extensionList?.map((item: IExtensionList) =>
          item?.id === response?.data?.id
            ? {...item, isActive: response?.data?.isActive}
            : item
        );
        setStateData((prev) => ({
          ...prev,
          extensionList: updatedList,
          loading: false,
        }));
        showToast({
          toastType: ToastType.success,
          message: intl.formatMessage({id: 'statusUpdatedSuccessfully'}),
          duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
          closeAllPrevToast: true,
        })
      } else {
        setStateData((prev) => ({
          ...prev,
          loading: false
        }));
      }
    } catch (error: any) {
      setStateData((prev) => ({
        ...prev,
        loading: false,
      }));
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      })
    }
  }

  const deleteExtension = async (extension: IExtensionList) => {
    if (extension?.id) {
      setStateData((prev)=> {
        return {
          ...prev,
          loading: true
        }
      })
      try {
        const params = {
          id: extension?.id
        }
        const response = await deleteCallExtension(params);
        if (response?.data?.id) {
          setStateData((prev)=> {
            return {
              ...prev,
              loading: false,
              showDeleteModal: false,
              selectedCallExtension: {} as IExtensionList
            }
          })
          fetchCallExtensionList();
          showToast({
            toastType: ToastType.success,
            message: intl.formatMessage({id: 'deletedSuccessfully'}),
            duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
            closeAllPrevToast: true,
          })
        }
      } catch (error: any) {
        setStateData((prev)=> {
          return {
            ...prev,
            loading: false,
            selectedCallExtension: {} as IExtensionList
          }
        })
        showToast({
          toastType: ToastType.error,
          message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
          duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
          closeAllPrevToast: true,
        })
      }
    }
  }

  const onActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        setStateData((prev) => {
          return {
            ...prev,
            selectedCallExtension: actionData
          }
        })
        return props?.onActionPerformed(COMMON_ACTION_CODES.OPEN);
      case COMMON_ACTION_CODES.CLOSE:
        setStateData((prev) => {
          return {
            ...prev,
            selectedCallExtension: {} as IExtensionList
          }
        })
        return props?.onActionPerformed(COMMON_ACTION_CODES.CLOSE);
      case COMMON_ACTION_CODES.SUCCESS:
        props?.onActionPerformed(COMMON_ACTION_CODES.CLOSE);
        if (stateData?.selectedCallExtension?.id) {
          // onExtensionUpdate(actionData);
          fetchCallExtensionList();
        } else {
          fetchCallExtensionList();
        }
        setStateData((prev) => {
          return {
            ...prev,
            selectedCallExtension: {} as IExtensionList
          }
        })
        return;

      case COMMON_ACTION_CODES.DELETE:
        return setStateData((prev) => {
          return {
            ...prev,
            showDeleteModal:  true,
            selectedCallExtension: actionData
          }
        })
      case COMMON_ACTION_CODES.STATUS_CHANGED:
        return onStatusChange(actionData);

      case COMMON_ACTION_CODES.CONFIRM:
        return deleteExtension(stateData?.selectedCallExtension);

      case COMMON_ACTION_CODES.CANCEL:
        return setStateData((prev) => {
          return {
            ...prev,
            showDeleteModal:  false,
            selectedCallExtension: {} as IExtensionList,
          }
        })

      default:
        return;
    }
  }

  const onPagination = (page: number, pageSize: number) => {
    const pageNumber = page;
    const pageLimit = pageSize;
    const offset = pageNumber * pageLimit - pageLimit;
    setStateData((prev) => {
      return {
        ...prev,
        offSet: offset,
        pageNo: page,
        limit: pageLimit,
      }
    })
  };

  const fetchData = async () => {
    if (isCommunicationLocationHandlingEnabled && stateData?.isFirstFetch) {
      const userAndNumberData = await getUserLocation();
      fetchCallExtensionList(props?.searchString, userAndNumberData?.userWithMatchingLocation, userAndNumberData?.phoneList);
    } else {
      fetchCallExtensionList(props?.searchString);
    }
  }

  useEffect(() => {
    fetchData();
  }, [stateData?.pageNo, stateData?.limit, props?.searchString]);

  return (
    <>
      <View>
        {!stateData?.loading && stateData?.extensionList?.length === 0 && !props?.searchString ? (
          <View style={{
            height: 600,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <CallExtensionNoDataView
              title={'createCallExtension'}
              buttonMessage={'createFirstCallExtension'}
              onCreateFirstCallExtension={()=> {
                props?.onActionPerformed(COMMON_ACTION_CODES.OPEN);
              }}
            />
          </View>) : <CallExtensionTable
            onPagination={onPagination}
            pageNo={stateData?.pageNo}
            pageSize={stateData?.limit}
            total={stateData?.total}
            data={stateData?.extensionList}
            onActionPerformed={onActionPerformed}
            loading={stateData?.loading}
          />
        }
      </View>
      {props?.selectedCode === TELEPHONY_VIEW.ADD_CONFIGURATION && (
        <AddCallExtensionDrawer
          onActionPerformed={onActionPerformed}
          selectedCallExtension={stateData?.selectedCallExtension}
          locationsUuid={stateData?.locationUuids}
          userPracticeLocation={stateData?.userPracticeLocation}
          inboxNumberList={stateData?.numberList}
        />
      )}
      {stateData?.showDeleteModal && <ExtensionDeleteConfirmationModal
        onActionPerformed={onActionPerformed}
      />}
    </>
  )
}