import {Popover} from 'antd';
import {
  Button,
  Divider,
  HStack,
  Image,
  Pressable,
  Text,
  Tooltip,
  View,
  VStack,
} from 'native-base';
import React, { useContext, useMemo } from 'react';
import Icon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {getInActiveContactError, PERSON_TYPES, PREFERRED_CHANNEL_CODE} from '../../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import {Colors} from '../../../../../../../styles';
import {canCommunicateWithInactivePatient, getCurrentUserRoleCodes, getPatientLocationUuidFromPracticeLocations, isActiveProfileMatchAllowedLocations, isActiveContact, isAnyPatientProfileActive, isConsentRequiredAndGiven, isMasterAccount, getPatientActiveProfiles, isLoginUserBusinessOwner} from '../../../../../../../utils/commonUtils';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import ChatActionSvg from '../../../../../../common/Svg/PersonActionSvgIcons/ChatActionSvg';
import EmailActionSvg from '../../../../../../common/Svg/PersonActionSvgIcons/EmailActionSvg';
import SmsActionSvg from '../../../../../../common/Svg/PersonActionSvgIcons/SmsActionSvg';
import StartPreferredicon from '../../../../../../common/Svg/PersonActionSvgIcons/StartPreferredIcon';
import {isCurrentMenuLast} from '../../../../../../PersonOmniView/LeftContainer/PamiDetail/PamiDetailUtils';
import {PERSON_ACTION_CODES} from '../../../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {getActionObjListByPersonType} from '../../../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/PersonActionUtils';
import { MAIN_MENU_CODES, USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE } from '../../../../../../SideMenuBar/SideBarConst';
import {patientNotLoggedInError} from '../../../../../TeamInbox/Conversations/ConversationContainer/ConversationConst';
import { isAutomationAllowed, USER_ACCESS_PERMISSION} from '../../../../../UserAccess/UserAccessPermission';
import {CONTACT_ACTION_CODES} from '../../../../ContactsConst';
import {getActionEventAndResourceCodeByOption, getQuickActionObjListByPersonType, isAffiliatePatient} from '../../../../ContactsUtils';
// import { DisplayText } from '../../../../../../common/DisplayText/DisplayText';
import {styles} from './ActionsViewStyles';
import {getActionTypeImgSrc} from './ActionViewConst';
import { checkAbilityAccess, checkAccountConfigAbilityAccess, getOrgAbilites } from '../../../../../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../../../../../constants/FhirConstant';
import {isAccountConfigEnabled} from '../../../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../../../constants/AccountConfigConst';
import { TEAM_MEMBERS_ROLES_CODE } from '../../../../TeamMembers/AddEditUser/TeamMemberConst';
import { Context } from 'react-intl/src/components/injectIntl';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';
import { isSendProviderRequestFormAllowed } from '../../../../../Forms/FormsUtils';

import FoldPermitCan from '../../../../../../CommonComponents/FoldPermitCan/FoldPermitCan';
import {ImageSourcePropType} from 'react-native';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import { testID, TestIdentifiers } from '../../../../../../../testUtils';
import useLoggedInUserAllowedLocationsByTab from '../../../../../../CustomHooks/useLoggedInUserAllowedLocationsByTab';
import { useIntl } from 'react-intl';
interface IActionsViewProps {
  onActionClick: (actionCode: string, data?: any) => void;
  record?: any;
  hideConvertToPatient?: boolean;
  hideCommounicationAction?: boolean;
}
const ActionsView = (props: IActionsViewProps) => {
  // const initialFocusRef = useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const appContext = useContext(Context);
  const context = useContext(CommonDataContext);
  const intl = useIntl();
  const contactType = props?.record?.contactType?.code;
  const contactActiveStatus = isActiveContact(props?.record?.singleLeadData);
  const loggedInUserRole = getCurrentUserRoleCodes();
  const isMasterAccountFlag = isMasterAccount();
  const userSettings = context.userSettings;
  const accountLocationUuid = getPatientLocationUuidFromPracticeLocations(props?.record?.contact?.contactPracticeLocations);
  const canUpdatePatient = checkAccountConfigAbilityAccess(FHIR_RESOURCE.PATIENT, 'canUpdate')?.isEnabled;    
  const canCreatePatient = checkAccountConfigAbilityAccess(FHIR_RESOURCE.PATIENT, 'canCreate')?.isEnabled; 
  const isMsoFlagEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const allowedLocationIds = useLoggedInUserAllowedLocationsByTab(MAIN_MENU_CODES.CONSUMER);
  const activecontactProfiles = getPatientActiveProfiles(props?.record?.contactData?.contactProfiles || []);
  const hasActiveProfile = isActiveProfileMatchAllowedLocations(activecontactProfiles || [], allowedLocationIds)
  const isActionsDisabled = !isLoginUserBusinessOwner(context) && isMsoFlagEnabled ? !hasActiveProfile : false;
  const tooltipMessage = !isActionsDisabled ? '' :  intl.formatMessage({id :'inactiveLocationPatientActionMsg'});

  let quickActionList = getQuickActionObjListByPersonType(contactType, canCreatePatient, canUpdatePatient);
  quickActionList = quickActionList.filter((list) => {
    return list.actionCode !== 'EDIT';
  });
  const isHideChat = contactType !== PERSON_TYPES.CUSTOMER;
  const isPatientMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.PATIENT_MESSAGING_ENABLED) &&
                                        !loggedInUserRole?.includes(TEAM_MEMBERS_ROLES_CODE.WORKFLOW) &&
                                        !loggedInUserRole?.includes(TEAM_MEMBERS_ROLES_CODE.CUSTOMER_SUCCESS);;
  const shouldShowChat = () => {
    if (!isPatientMessagingEnabled) {
      return false
    } else if (!isHideChat && USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.USER.includes(
      MAIN_MENU_CODES.INBOX
    )) {
      return true;
    } else {
      return false;
    }
  }

  const showAutomationTab = isAutomationAllowed()

  if (props.hideConvertToPatient) {
    quickActionList = quickActionList.filter(
      (item) => item.actionCode !== PERSON_ACTION_CODES.CONVERT_TO_CUSTOMER
    );
  }
  const sendProviderRequestFormAllowed = !props?.record?.contactType?.isDefault && isSendProviderRequestFormAllowed(userSettings);


  let additionalList = getActionObjListByPersonType(contactType, canUpdatePatient, [], sendProviderRequestFormAllowed);
  additionalList = additionalList.filter((list) => {
    return (
      list?.actionCode !== PERSON_ACTION_CODES.RESET_PASSWORD &&
      list?.actionCode !== PERSON_ACTION_CODES.CONVERT_TO_CUSTOMER
    );
  });
  quickActionList = quickActionList.concat(additionalList);


  if (!showAutomationTab) {
    quickActionList = quickActionList.filter(
      (item) => item.actionCode !== COMMON_ACTION_CODES.CREATE_AUTOMATION
    );
  }
  if (!props?.record?.contactData?.isActive) {
    quickActionList = quickActionList.filter(
      (item) => item.actionCode !== COMMON_ACTION_CODES.SCHEDULE_APPOINTMENT
    );
  }
  if (isMasterAccountFlag) {
    quickActionList = quickActionList.filter((list) => {
      return ![
        COMMON_ACTION_CODES.CREATE_TASK,
        COMMON_ACTION_CODES.SCHEDULE_APPOINTMENT,
      ].includes(list?.actionCode);
    });
  }
  const isPatientLoggedIn = props?.record?.contactData?.additionalAttributes?.loggedInTime ?  true : false
  const consentCheck = isConsentRequiredAndGiven(props?.record?.contactData)

  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };

  const isEmailBtnDisabled = () => {
    return !props.record?.contactData?.email;
  };

  const isNumberBtnDisabled = () => {
    return !props.record?.contactData?.phoneNumber;
  };
  const getPreferredChannel = () => {
    return props.record?.contactData?.contactPreferredCommunicationChannel?.[0]
      ?.preferredCommunicationModeMlov?.code;
  };
  // eslint-disable-next-line @typescript-eslint/no-var-requires

  const currentStatus = props?.record?.contactData?.isActive;

  const popoverOptionView = (action: any , iconType: ImageSourcePropType, index: number, displayString: string) => {
    return (
      <Pressable
        key={index}
        onPress={() => {
          props.onActionClick(action.actionCode);
          setIsOpen(!isOpen);
        }}
      >
        <HStack style={[styles.mainMenuContent, styles.firstMenu]}>

          {action?.actionCode === COMMON_ACTION_CODES.PRINT_CLINICAL_PROFILE && (
            <Feather name="printer" size={18} color={Colors.FoldPixel.GRAY300} />
          )}
          {action.actionCode !== COMMON_ACTION_CODES.PRINT_CLINICAL_PROFILE && (
            <Image
              mt={1}
              resizeMode="cover"
              style={{width: 16, height: 16}}
              source={iconType}
              tintColor={Colors.FoldPixel.GRAY400}
              alt='image'
            />
          )}
          <Text style={styles.mainMenuTxt} size={'smRegular'} color={Colors.FoldPixel.GRAY400}>
            <DisplayText size={'smRegular'} textLocalId={displayString} testID={displayString} />
          </Text>
        </HStack>
        {isCurrentMenuLast(index, quickActionList) && (
          <Divider my={1} style={styles.dividerStyle} />
        )}
      </Pressable>
    )
  }
  const content = (
    <VStack style={{padding: 0, borderRadius: 16}}>
      {quickActionList.map((action ,index: number) => {
        let actionCode = action?.actionCode;
        if (action.actionCode == CONTACT_ACTION_CODES.DEACTIVATE) {
          actionCode = currentStatus
            ? CONTACT_ACTION_CODES.DEACTIVATE
            : CONTACT_ACTION_CODES.ACTIVATE;
        }
        const iconType = getActionTypeImgSrc(actionCode);
        let displayString = action.displayString;
        if (action.actionCode == CONTACT_ACTION_CODES.DEACTIVATE) {
          displayString = currentStatus ? 'Set Inactive' : 'Activate';
        }
        const eventAndResource = getActionEventAndResourceCodeByOption(action.actionCode)

        if (
          isAffiliatePatient(props.record?.contactData?.additionalAttributes) &&
          action.actionCode === 'EDIT'
        ) {
          return <></>;
        }
        return (
          <>
          {eventAndResource?.resourceCode &&  
            <FoldPermitCan 
            key={index}
            resource={eventAndResource.resourceCode}
            action={eventAndResource.actionEvent}
            component={popoverOptionView(action, iconType, index , displayString)}
            />
          }
          {
            !eventAndResource?.resourceCode && popoverOptionView(action, iconType, index , displayString)
          }
          </>
        );
      })}
    </VStack>
  );

  const chatBtnTooltipLabel = useMemo(() => {
    if (!!isActionsDisabled) {
      return intl.formatMessage({ id: 'inactiveLocationPatientActionMsg' });
    }
    return !contactActiveStatus
      ? getInActiveContactError(props?.record)
      : isPatientLoggedIn
        ? 'Chat'
        : patientNotLoggedInError;
  }, [
    contactActiveStatus,
    props?.record,
    isPatientLoggedIn,
    isActionsDisabled,
  ]);
  

  const smsBtnTooltipLabel = useMemo(() => {
    return !canCommunicateWithInactivePatient(props?.record?.contactData)
      ? getInActiveContactError(props?.record)
      : isNumberBtnDisabled()
        ? 'Please Add Number'
        : 'Send SMS';
  }, [
    contactActiveStatus,
    props?.record,
    isPatientLoggedIn,
  ]);

  return (
    <View style={styles.iconView}>
      {props?.hideCommounicationAction ? (
        <Stack direction='row' style={styles.careProgramAction}>
          <Pressable>
            <Text color={Colors.Custom.Primary300}>
            {'Initiate TCM'}
            </Text>
          </Pressable>
        </Stack>
      ) :(
      <FoldPermitCan 
      resource={MAIN_MENU_CODES.INBOX}
      action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
      component={<>
      <Tooltip
        label={
          !canCommunicateWithInactivePatient(props?.record?.contactData) ? getInActiveContactError(props?.record)
          :
          isEmailBtnDisabled() ? 'Please Add Email Address' : 'Send Email'
        }
        placement="top"
      >
        <Button
          _hover={{bg: Colors.Custom.HoverBgColor}}
          _focus={{
            borderColor: 'black',
            backgroundColor: 'white'
          }}
          {...testID(TestIdentifiers.sendEmailBtn)}
          testID={TestIdentifiers.sendEmailBtn}
          onPress={() => {
            if(canCommunicateWithInactivePatient(props?.record?.contactData)){
              // done this change intentionally on prod for palmetto consent warning issue
              // time: 1st March 2023

            if (consentCheck) {
              if (isEmailBtnDisabled()) {
                props.onActionClick('EDIT');
              } else {
                props.onActionClick('MAIL');
              }
            } else {
              if(contactType === PERSON_TYPES.CUSTOMER && !canUpdatePatient){
                return;
              }
              props.onActionClick('EDIT', COMMON_ACTION_CODES.CONSENT_WARNING);
            }
           }
          }}
          // disabled={isEmailBtnDisabled()}
          leftIcon={
            <View>
              <View style={[styles.svgIcons]}>
                <EmailActionSvg
                  isActive={isEmailBtnDisabled() ? false : true}
                />
              </View>
              {getPreferredChannel() === PREFERRED_CHANNEL_CODE.EMAIL && (
                <View style={[styles.preferredIcon]}>
                  <StartPreferredicon />
                </View>
              )}
            </View>
          }
          style={styles.btn}
        />
      </Tooltip>
      {/* <Button
        onPress={() => {
          props.onActionClick('DELETE');
        }}
        leftIcon={<Icon name="delete" size={17} style={[styles.icons]} />}
        style={styles.btn}
      /> */}

      {shouldShowChat() && <Tooltip label= {chatBtnTooltipLabel} placement="top">
        <Button
          onPress={() => {
            if (contactActiveStatus && !isActionsDisabled) {
              if (isPatientLoggedIn) {
                props.onActionClick('CHAT');
              }
            }
          }}
          {...testID(TestIdentifiers.chatBtn)}
          testID={TestIdentifiers.chatBtn}
          leftIcon={
            <View>
              <View style={[styles.svgIcons]}>
                <ChatActionSvg isActive={isPatientLoggedIn && !isActionsDisabled} />
              </View>
              {/* {getPreferredChannel() === PREFERRED_CHANNEL_CODE. && (
                <View style={[styles.preferredIcon]}>
                  <StartPreferredicon />
                </View>
              )} */}
            </View>
        }
          style={styles.btn}
        />
      </Tooltip>}

      <Tooltip
        label={smsBtnTooltipLabel}
        placement="top"
      >
        <Button
          onPress={() => {
            if(canCommunicateWithInactivePatient(props?.record?.contactData)){
              // done this change intentionally on prod for palmetto consent warning issue
              // time: 1st March 2023
            if (consentCheck) {
              if (isNumberBtnDisabled()) {
                props.onActionClick('EDIT');
              } else {
                props.onActionClick('SMS');
              }
            } else {
              if(contactType === PERSON_TYPES.CUSTOMER && !canUpdatePatient){
                return;
              }
              props.onActionClick('EDIT', COMMON_ACTION_CODES.CONSENT_WARNING);
            }
           }
          }}
          // disabled={isNumberBtnDisabled()}
          {...testID(TestIdentifiers.smsBtn)}
          testID={TestIdentifiers.smsBtn}
          leftIcon={
            <View>
              <View style={[styles.svgIcons]}>
              <SmsActionSvg
                  customStrokeColor={
                    isNumberBtnDisabled()
                      ? Colors.Custom.GrayDisable
                      : Colors.Custom.foldIconColor
                  }
                  isActive={isNumberBtnDisabled() ? false : true}
                />
              </View>
              {getPreferredChannel() === PREFERRED_CHANNEL_CODE.SMS && (
                <View style={[styles.preferredIcon]}>
                  <StartPreferredicon />
                </View>
              )}
            </View>
          }
          style={styles.btn}
          _focus={{
            backgroundColor: 'white',
            borderColor: 'black',
          }}
        />
      </Tooltip></>}
      />
      )}
      <Popover
        key={props?.record?.id}
        overlayInnerStyle={{padding: 0, borderRadius: 16}}
        overlayStyle={{padding: 0}}
        overlay={{padding: 0}}
        style={{padding: 0, borderRadius: 16}}
        placement={'left'}
        trigger='hover'
        visible={isOpen}
        onVisibleChange={handleVisibleChange}
        content={!isActionsDisabled ? content : tooltipMessage}
      >
        <Button
          onPress={() => {
            setIsOpen(true);
          }}
          style={styles.btn}
          _focus={{
            backgroundColor: 'white',
            borderColor: 'black',
          }}
          testID={TestIdentifiers.threeDots}
        >
          <Feather
            name={props?.hideCommounicationAction ? "more-horizontal" : "more-vertical"}
            style={[
             isActionsDisabled ? styles.disabledIcons : styles.icons,
              // {color: Colors.Custom.PrimaryBtnColor}
            ]}
            {...testID(TestIdentifiers.threeDots)}
          />
        </Button>
      </Popover>
    </View>
  );
};

export default ActionsView;
