import {Button, Card, Checkbox, Spin} from 'antd';
import React, {useEffect, useState} from 'react';

export default function OpportunitySelection() {
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState<{
    totalMembers?: number;
    total?: number;
    results?: any[];
  }>({
    results: [],
    total: 0,
    totalMembers: 0,
  });

  const fetchSelection = async () => {
    try {
      setLoading(true);
      const req = await fetch(
        `https://dev.foldhealth.io/opportunities/api/fhir`
      );
      const res = await req.json();

      if (res.status) {
        setSelection(res.diagnostics.map((d: any) => ({...d, selected: true})));
      }
    } catch (err: any) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSelection();
  }, []);

  if (loading) {
    return <Spin />;
  }

  return (
    <Card
      title={
        <span className="inline-flex items-center justify-start ">
          Total{' '}
          <Button className="btnTagDefault" shape="round" size="small">
            {selection.totalMembers} Members
          </Button>
        </span>
      }
    >
      <div className="flex flex-col items-start justify-start p-4 w-full">
        <div className="flex flex-col items-start justify-start p-4 w-full">
          {(selection.results as any).map((result: any) => (
            <div className="flex items-center justify-start w-full gap-6">
              <Checkbox defaultChecked={result.selected} />
              <span className="font-medium text-lg">{result.label}</span>
              <span className="text-gray-400">{result.description}</span>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
}
