import {Icon, View} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {Colors} from '../../../../../styles';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {NoteActions} from '../interfaces';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import { BUTTON_TYPE } from '../../../../../constants';
import { StyleSheet } from 'react-native';

interface IDropdownButtonProps {
  onPress: (string: NoteActions) => void;
  disabled?: boolean;
  tooltipMessage?: string;
}

const CustomAddNoteButton = (props: IDropdownButtonProps) => {
  const intl = useIntl();
  return (
    <View
      margin={1}
      style={styles.infoOutlineIcon}
    >
      <FoldButton
        customProps={{
          btnText: intl.formatMessage({id: 'addNote'}),
          tooltipText: props?.tooltipMessage || '',
        }}
        nativeProps={{
          variant: BUTTON_TYPE.PRIMARY,
          backgroundColor: Colors.Custom.BackgroundColor,
          color: props.disabled ? Colors.Custom.Gray300 : Colors.Custom.mainPrimaryPurple,
          borderColor: props.disabled ? Colors.Custom.Gray300 : Colors.Custom.mainPrimaryPurple,
          _text: {
            color: props.disabled ? Colors.Custom.Gray300 : Colors.Custom.mainPrimaryPurple,
          },
          disabled: props.disabled,
          leftIcon: (
            <Icon
              as={AntIcon}
              name={'plus'}
              size="4"
              color={props.disabled ? Colors.Custom.Gray300 : Colors.Custom.mainPrimaryPurple}
            />
          ),
          onPress: () => {
            if(props?.disabled ) return true;
            props.onPress(NoteActions.CREATE_DEFAULT_NOTE);
          },
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  infoOutlineIcon: {
    height: 42,
    alignItems: 'center',
    justifyContent: 'center',
  }
});

export default CustomAddNoteButton;
