import {StyleSheet} from 'react-native';

export const locationGroupMultiSelectStyles = StyleSheet.create({
  checkBoxDropDownContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 12,
    borderWidth: 1,
    borderColor: '#e4e7ec',
    borderRadius: 8,
    backgroundColor: '#fff',
    overflow: 'hidden',
    height: 40,
    padding: 16,
    marginTop: 12
  }
});
