import {useLazyQuery, useMutation} from '@apollo/client';
import {cloneDeep} from 'lodash';
import {Checkbox, HStack, Text, View, VStack} from 'native-base';
import {useState} from 'react';
import {Dimensions} from 'react-native';
import {GLOBAL_ROLE_CODES, MLOV_CATEGORY, USER_ROLE_CODES} from '../../../constants/MlovConst';
import {getCurrentSubdomain} from '../../../screens/MainScreen/MainScreenHelper';
import {addOrUpdateUsersRolePermissions} from '../../../services/User/UserPermission';
import {Colors} from '../../../styles';
import {getAccountUUID, isMasterAccount} from '../../../utils/commonUtils';
import {getMlovListByCategory} from '../../../utils/mlovUtils';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import PageBodyContainer from '../../common/PageBodyContainer/PageBodyContainer';
import {
  MAIN_MENU_CODES,
} from '../../SideMenuBar/SideBarConst';
import permissionQueries from './permissionQueries';
import {styles} from './Styles';
import {
  getAllPermissionCode,
  getDefaultPermissionCode,
  getNonePermissionCode,
  isAllPermission,
  USER_ACCESS_PERMISSION
} from './UserAccessPermission';
import {updateUserPermission} from './utils';

const AppTabPermissionsTable = (props: {
  parentTabCode: string;
  userRoles: Array<any>;
  currentPermissions: Array<any>;
  onSave: any;
  showToast: any;
}) => {
  const subdomain = getCurrentSubdomain();
  const {height} = Dimensions.get('window');
  const isMasterAccountConfig = isMasterAccount()
  const [currentPermissions, setCurrentPermissions] = useState(
    props.currentPermissions
  );
  const dashboardPermissionList = getMlovListByCategory(MLOV_CATEGORY.DASHBOARD);
  const settingPermissionList = getMlovListByCategory(MLOV_CATEGORY.SETTING);

  const [createNewPermission] = useMutation(
    permissionQueries.CREATE_NEW_PERMISSION
  );

  const [getPermissionByEntityCodeAndActionCode] = useLazyQuery(
    permissionQueries.GET_PERMISSION_BY_ENTITY_CODE_AND_ACTION_CODE
  );
  const [userRoles, setUserRoles] = useState(props?.userRoles as {id: string,code: string, value: string}[]);
  const accountUUID = getAccountUUID();
  const [columns, setColumns] = useState<any>([]);
  const [isInit, setIsInit] = useState(true);

  const init = () => {
    const columns: any = [];
    const tabCodeList = props.parentTabCode == USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code
    ? dashboardPermissionList : settingPermissionList
    tabCodeList.forEach((permission) => {
      const tabData = {...permission, id: null , actionId: permission.id};
      userRoles.forEach((userData: any) => {
        if (!userData.tabData) {
          userData.tabData = {};
        }
        userData.tabData[permission.code] = cloneDeep(tabData)
        userData.tabData[permission.code].permissionCode = getDefaultPermissionCode()
      });

      const permissionColumns = {
        code: tabData.code,
        title: (
          <View alignItems={'center'} justifyContent={'center'}>
            <Text color={Colors.FoldPixel.GRAY300}>{tabData.value}</Text>
          </View>
        ),
        // width: '20%',
        render: (record: any) => {
          return (
            <View alignItems={'center'} justifyContent="center" key={tabData.code + record}>
              <Checkbox
                isDisabled={
                  (record.code === USER_ROLE_CODES.BUSINESS_OWNER ||
                  record.code === GLOBAL_ROLE_CODES.GLOBAL_ADMIN) && (
                    permission.code === MAIN_MENU_CODES.USER_ACCESS ||
                    permission.code === MAIN_MENU_CODES.DESIGNER
                  )
                }
                defaultIsChecked={isAllPermission(
                  record.tabData[permission.code].permissionCode
                )}
                marginTop={2}
                value={'true' + '_' + record.code + '_' + permission.code}
                onChange={async(isChecked: boolean) => {
                  const permissionCode = isChecked
                    ? getAllPermissionCode()
                    : getNonePermissionCode();
                  currentUserRoleMap[record.code].tabData[permission.code].permissionCode =
                    permissionCode;
                  if (isMasterAccountConfig) {
                    await addOrUpdateUsersRolePermissions({
                      actionCode: tabData.code,
                      permissionCode: permissionCode,
                      userRoleCode: record.code,
                      entityCode: props.parentTabCode
                    }).then((err)=> {
                      props.showToast();
                    })
                    return
                  }
                  const currentId =
                    currentUserRoleMap[record.code].tabData[permission.code].id;
                  record.tabData[permission.code].permissionCode = permissionCode;
                  const dateStr = new Date().toISOString();
                  const actionId = record.tabData[permission.code].actionId

                  if (currentId) {
                    await updateUserPermission({
                      permissions: [
                        {
                          id: currentId,
                          permissionCode: permissionCode,
                          actionId: actionId,
                          accountRoleId: record.id,
                        },
                      ],
                    }).then((response) => {
                      if (response.length > 0) {
                        props.showToast();
                      }
                    });
                  } else {
                    getPermissionByEntityCodeAndActionCode({
                      variables: {
                        userRoleCode: record.code,
                        entityCode: props.parentTabCode,
                        actionCode: permission.code,
                        accountUUID: accountUUID,
                        accountRoleId: record.id
                      },
                    }).then(async (response: any) => {
                      const id = response.userPermissions?.length
                        ? response.userPermissions[0].id
                        : undefined;
                      if (id) {
                        await updateUserPermission({
                          permissions: [
                            {
                              id: id,
                              permissionCode: permissionCode,
                              actionId: actionId,
                              accountRoleId: record.id,
                            },
                          ],
                        }).then((response) => {
                          if (response.length > 0) {
                            props.showToast();
                          }
                        });
                      } else {
                        const variables = {
                          userRoleCode: record.code,
                          entityCode: props.parentTabCode,
                          actionCode: permission.code,
                          permissionCode: permissionCode,
                          createdAt: dateStr,
                          updatedAt: dateStr,
                          accountUUID: accountUUID,
                          actionId: actionId,
                          accountRoleId: record.id
                        };
                        createNewPermission({
                          variables: variables,
                          onCompleted: (response) => {
                            const newCurrentPermission = [
                              ...currentPermissions,
                              {
                                ...variables,
                                id: response.createUserPermission.id,
                              },
                            ];
                            record.tabData[permission.code].id =
                              response.createUserPermission.id;
                            currentUserRoleMap[record.code].tabData[permission.code].id =
                              response.createUserPermission.id;
                            setCurrentPermissions(newCurrentPermission);
                            props.showToast();
                          },
                        });
                      }
                    });
                  }
                }}
              />
            </View>
          );
        },
      }
      columns.push(permissionColumns);
    });
    const currentUserRoleMap: any = {};
    userRoles.forEach((userRoleData: any) => {
      currentUserRoleMap[userRoleData.code] = userRoleData;
    });
  
    currentPermissions.forEach((data: any) => {
      if (!currentUserRoleMap[data.userRoleCode]) {
        currentUserRoleMap[data.userRoleCode] = {
          tabData: {
            [data.actionCode]: {},
          },
        };
      }
      if (!currentUserRoleMap[data.userRoleCode].tabData[data.actionCode]) {
        currentUserRoleMap[data.userRoleCode].tabData[data.actionCode] = {};
      }
      currentUserRoleMap[data.userRoleCode].tabData[
        data.actionCode
      ].permissionCode = data.permissionCode;
      currentUserRoleMap[data.userRoleCode].tabData[data.actionCode].id =
        data.id;
    });


    setColumns(columns);
    setUserRoles(userRoles);
  };

  if (isInit) {
    init();
    setIsInit(false);
  }
  return (
    <View borderRadius={0} >
      <View style={[styles.shadowBoxUserAccessMainTable,{height:height-125}]}>
        <PageBodyContainer style={styles.marginHorizontal0}>
          <HStack  flexWrap="wrap" justifyItems={'center'}>
            {userRoles.map((role: any, index: number) => {
              return (
                <View
                  key={role.code + index}
                  style={styles.cardView}
                  backgroundColor={'#ffffff'}
                >
                  <View style={styles.view2}>
                  <DisplayText
                    textLocalId={role.value || ''}
                    extraStyles={{color: Colors.FoldPixel.GRAY400}}
                    size={'mdMedium'}
                  />
                  </View>

                  <VStack>
                  {columns.map((record: any, index: number) => {
                    return (
                      <HStack key={record?.title + index} alignItems={"center"} justifyContent="space-between" style={styles.view3}>
                        {record?.title}
                        {record?.render(role)}
                      </HStack>
                    );
                  })}
                  </VStack>
                </View>
              );
            })}
          </HStack>
        </PageBodyContainer>
      </View>
    </View>
  );
};

export default AppTabPermissionsTable;
