import {useLazyQuery, useQuery} from '@apollo/client';
import {Table} from 'antd';
import {Skeleton, View, VStack} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {useNavigate} from 'react-router-dom';
import {PARENT_CODE} from '../../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../../constants/ActionConst';
import { USER_ROLE_CODES } from '../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {ILoginUserData} from '../../../../../Interfaces/CommonInterfaces';
import InboxQueries from '../../../../../services/Inbox/InboxQueries';
import UserQueries, { SEARCH_USERS_BY_ROLE_CODES } from '../../../../../services/User/UserQueries';
import {
  getAccountUUID,
  getAllowedUserAccountLocationUuids,
  getUserId,
  getUserUUID,
  isLoggedInUserWorkFlowOrCustomerSuccess,
  isLoginUserBusinessOwner,
} from '../../../../../utils/commonUtils';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
import {TableTopBar} from '../../../../common/TableTopBar';
import PurchaseNumberView from '../../../CloudTelephony/PurchaseNumberView/PurchaseNumberView';
import {CHANNEL_TYPE} from '../../Conversations/ConversationConst';
import {INTEGRATION_CONSTANTS} from '../constants';
import {formatIntegrationsData} from '../Helper/formatIntegrationsData';
import {addInboxButton} from '../Helper/IntegrationButtons';
import EmailInboxDrawer from '../IntegrationCreate/EmailInboxCreate/EmailInboxDrawer';
import {SMS_INBOX_ACTION_CODES} from '../IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import SmsInboxDrawer from '../IntegrationCreate/SmsInboxCreate/SmsInboxDrawer';
import {IGetInboxesForSetting, IIntegrationsDataProps} from '../interfaces';
import {getcolumns} from './IntegrationColumns';
import { EMAIL_INBOX_ACTION_CODES } from '../IntegrationCreate/EmailInboxCreate/EmailInboxConst';
import CloudTelephonyQueries from '../../../../../services/CloudTelephony/CloudTelephonyQueries';
import { CLOUD_TELEPHONY_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { IVirtualPhoneNumberResp } from '../../../CloudTelephony/interfaces';
import UserPracticeLocationQueries from '../../../../../services/Location/UserPracticeLocationQueries';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import { USER_ACCESS_PERMISSION } from '../../../UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../../SideMenuBar/SideBarConst';
import { getUserSearchByBoRoleWhereCondition } from '../../../../common/OutboundCallSmsView/utils';

export const IntegrationLanding = (props: any) => {
  const {height} = Dimensions.get('window');
  const accountUuid = getAccountUUID();
  const userUuid = getUserUUID();
  const allowedCallUserAccountLocationUuids =
    getAllowedUserAccountLocationUuids(
      USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
      MAIN_MENU_CODES.CALL
    );
  const isAllowedAllLocation = isLoginUserBusinessOwner() || isLoggedInUserWorkFlowOrCustomerSuccess();
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const isCSAndWorkFlowUserEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_CS_AND_WORKFLOW_USER_ENABLED);
  const [searchString, setSearchString] = useState('');
  const [stateData, setStateData] = useState<IIntegrationsDataProps[]>([]);
  const [inboxMainPageState, setInboxMainPageState] = useState<{
    selectedInboxData: IIntegrationsDataProps;
    selectedInboxChannelType: string;
    selectedInboxFilter: string[];
    inboxLoading: boolean;
    virtualPhoneNumbers: string[];
  }>({
    selectedInboxData: {} as any,
    selectedInboxChannelType: '',
    selectedInboxFilter: [],
    inboxLoading: false,
    virtualPhoneNumbers: [],
  });
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const navigate = useNavigate();
  const channelType = [CHANNEL_TYPE.CHANNEL_EMAIL, CHANNEL_TYPE.CHANNEL_WEB_WIDGET]
  const userId = getUserId();

  const [getInboxes] = useLazyQuery(
    InboxQueries.GetUserInboxesForSettings,
    {
      fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      notifyOnNetworkStatusChange: true,
      variables: {
        searchString: `%${searchString}%`,
        channelType: channelType,
        accountId: userData.account_id,
        currentUserId: userId,
        phoneNumbers: [] as string[],
      },
    }
  );

  const [GET_VIRTUAL_PHONE_NUMBER_BY_USER_LOCATIONS] = useLazyQuery<IVirtualPhoneNumberResp>(
    CloudTelephonyQueries.GET_VIRTUAL_PHONE_NUMBER_BY_USER_LOCATIONS,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [GET_VIRTUAL_PHONE_NUMBER] = useLazyQuery<IVirtualPhoneNumberResp>(
    CloudTelephonyQueries.GET_VIRTUAL_PHONE_NUMBER_BY_ACCOUNT_UUID,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [GetUsersByUserPracticeLocations] = useLazyQuery(
    UserPracticeLocationQueries.GetUsersByUserPracticeLocations, {
      fetchPolicy: 'no-cache',
    }
  );

  const [getUsersByRoles] = useLazyQuery(SEARCH_USERS_BY_ROLE_CODES, {
    fetchPolicy: 'no-cache',
  });

  const {loading: agentsLoading, data: accountUserData} = useQuery(
    InboxQueries.GetAgents,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      variables: {
        accountId: userData.account_id,
        roleCode: USER_ROLE_CODES.EMPLOYER,
      },
    }
  );

  const getSearch = (actionCode: string, actionData: any) => {
    setSearchString(actionData.searchString);
    getInboxesForSetting({searchString: actionData?.searchString})
  };

  const onAddInbox = (): any => {
    navigate('create');
  };

  const isSmsInboxSelected = () => {
    return (
      inboxMainPageState.selectedInboxChannelType ===
      CHANNEL_TYPE.CHANNEL_TWILIO_SMS
    );
  };

  const isEmailInboxSelected = () => {
    return (
      inboxMainPageState.selectedInboxChannelType === CHANNEL_TYPE.CHANNEL_EMAIL
    );
  };

  const isPurchaseNewNumberSelected = () => {
    return (
      inboxMainPageState.selectedInboxChannelType === SMS_INBOX_ACTION_CODES.VIRTUAL_NUMBER_DRAWER_OPEN
    );
  };

  const getAction = (
    actionCode: string,
    recordData: IIntegrationsDataProps
  ): any => {
    if (actionCode == INTEGRATION_CONSTANTS.EDIT) {
      const channelTypeCode = recordData.singleInboxData.channelType;
      switch (channelTypeCode) {
        case CHANNEL_TYPE.CHANNEL_WEB_WIDGET:
          navigate(`/admin/communication/inbox/edit/${recordData.channel_id}/${recordData.key}`);
          break;
        case CHANNEL_TYPE.CHANNEL_TWILIO_SMS:
          setInboxMainPageState((prev) => {
            return {
              ...prev,
              selectedInboxData: recordData,
              selectedInboxChannelType: CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
            };
          });
          break;
        case CHANNEL_TYPE.CHANNEL_EMAIL:
          setInboxMainPageState((prev) => {
            return {
              ...prev,
              selectedInboxData: recordData,
              selectedInboxChannelType: CHANNEL_TYPE.CHANNEL_EMAIL,
            };
          });
          break;
      }
    } else {
      getInboxesForSetting({searchString});
    }
  };

  const onSmsInboxActionPerformed = (actionCode: string) => {
    switch (actionCode) {
      case SMS_INBOX_ACTION_CODES.DRAWER_CLOSE:
        setInboxMainPageState((prev) => {
          return {
            ...prev,
            selectedInboxData: {} as any,
            selectedInboxChannelType: '',
          };
        });
        break;
      case COMMON_ACTION_CODES.CANCEL:
        setInboxMainPageState((prev) => {
          return {
            ...prev,
            selectedInboxData: {} as any,
            selectedInboxChannelType: '',
          };
        });
        break;
      case SMS_INBOX_ACTION_CODES.ON_INBOX_SAVE:
        setInboxMainPageState((prev) => {
          return {
            ...prev,
            selectedInboxData: {} as any,
            selectedInboxChannelType: '',
          };
        });
        getInboxesForSetting({searchString});
        break;
      case SMS_INBOX_ACTION_CODES.VIRTUAL_NUMBER_DRAWER_OPEN:
          setInboxMainPageState((prev) => {
            return {
              ...prev,
              selectedInboxChannelType: SMS_INBOX_ACTION_CODES.VIRTUAL_NUMBER_DRAWER_OPEN,
            };
          });
        break;
    }
  };


  const {loading: userListLoading, data: userListResp} = useQuery(
    UserQueries.Get_Users_IVR,
    {
      fetchPolicy: 'no-cache',
      variables: {
        accountUUID: accountUuid,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
    }
  );

  const getVirtualNumbers = async () => {
    let usersWithMatchingLocations: string[] = [];
    if (!isAllowedAllLocation && isCommunicationLocationHandlingEnabled) {
      const [userPracticeLocationsResp, userResponse] = await Promise.all([
        GetUsersByUserPracticeLocations({
          variables: {
            locationUuids: allowedCallUserAccountLocationUuids,
            accountUuid: accountUuid
          },
        }),
        getUsersByRoles({
          variables: {
            where: getUserSearchByBoRoleWhereCondition(isCSAndWorkFlowUserEnabled),
          }
        })
      ]);

      if (userPracticeLocationsResp?.data?.userPracticeLocations) {
        usersWithMatchingLocations =
          userPracticeLocationsResp?.data?.userPracticeLocations?.map(
            (value: {uuid: string; userUuid: string}) => {
              return value?.userUuid;
            }
          );
      }
      if (userResponse.data?.users?.length) {
        const boUsers = userResponse.data?.users?.map(
          (value: {uuid: string}) => {
            return value?.uuid;
          }
        );
        usersWithMatchingLocations.push(...boUsers);
      }
      
      usersWithMatchingLocations.push(...allowedCallUserAccountLocationUuids);
      usersWithMatchingLocations.push(userUuid);
      usersWithMatchingLocations = Array.from(new Set(usersWithMatchingLocations));

      const responses = await GET_VIRTUAL_PHONE_NUMBER_BY_USER_LOCATIONS({
        context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
        variables: {
          accountUuid: accountUuid,
          searchString: `%${searchString}%`,
          assigneeIds: usersWithMatchingLocations,
        },
      });
      const phoneNumbers =
        responses?.data?.virtualPhoneNumberAssignees?.map((assignee) => {
          const phoneNumber = assignee?.virtualPhoneNumber;
          return phoneNumber;
        }) || [];
      setInboxMainPageState((prev) => {
        return {
          ...prev,
          virtualPhoneNumbers: phoneNumbers,
        };
      });
      return phoneNumbers;
    } else {
      const responses = await GET_VIRTUAL_PHONE_NUMBER({
        context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
        variables: {
          accountUuid: accountUuid,
          searchString: `%${searchString}%`,
        },
      });
      const phoneNumbers =
        responses?.data?.virtualPhoneNumberAssignees?.map((assignee) => {
          const phoneNumber = assignee?.virtualPhoneNumber;
          return phoneNumber;
        }) || [];
      setInboxMainPageState((prev) => {
        return {
          ...prev,
          virtualPhoneNumbers: phoneNumbers,
        };
      });
      return phoneNumbers;
    }
  };

  const getInboxesForSetting = async (data: IGetInboxesForSetting) => {
    const {searchString, phoneNumbers} = data
    try {
      setInboxMainPageState((prev) => {
        return {
          ...prev,
          inboxLoading: true,
        };
      });
      const inboxData = await getInboxes({
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
        notifyOnNetworkStatusChange: true,
        variables: {
          searchString: `%${searchString || ''}%`,
          channelType: channelType,
          accountId: userData.account_id,
          currentUserId: userId,
          phoneNumbers:
            phoneNumbers && phoneNumbers?.length
              ? phoneNumbers
              : inboxMainPageState.virtualPhoneNumbers,
        },
      });
      if (inboxData?.data) {
        setStateData(formatIntegrationsData(inboxData?.data));
      }
      setInboxMainPageState((prev) => {
        return {
          ...prev,
          inboxLoading: false,
        };
      });
    } catch {
      setInboxMainPageState((prev) => {
        return {
          ...prev,
          inboxLoading: false,
        };
      });
    }
  };

  const getInitialInboxData = async () => {
    const phoneNumbers = await getVirtualNumbers();
    await getInboxesForSetting({phoneNumbers});
  };
  useEffect(() => {
    getInitialInboxData();
  }, []);

  useEffect(() => {
    setSearchString(props.searchString);
    getInboxesForSetting({searchString: props.searchString})
  }, [props.searchString]);

  useEffect(() => {
    if (
      props.inboxViewCode &&
      props.inboxViewCode === EMAIL_INBOX_ACTION_CODES.ON_INBOX_SAVE
    ) {
      getInboxesForSetting({});
    }
  }, [props.inboxViewCode]);

  return (
    <VStack height={height - 200}>
      <PageBodyContainer>
        {props.showTableTopBar && (
          <VStack padding={1}>
            <TableTopBar
              title=""
              onActionPerformed={getSearch}
              buttonList={addInboxButton(onAddInbox)}
            />
          </VStack>
        )}
        {inboxMainPageState.inboxLoading || agentsLoading ? (
          <View padding={6}>
            <Skeleton.Text lines={4} />
          </View>
        ) : (
          <Table
              rowClassName={(record, index) =>
                index % 2 == 0 ? 'table-row-light' : ''
              }
              dataSource={stateData}
              columns={getcolumns(getAction, inboxMainPageState?.selectedInboxFilter) as any}
              pagination={false}
              scroll={{x: 700, y: height - 210}}
              onRow={(data) => {
                return {
                  onClick: () => getAction(INTEGRATION_CONSTANTS.EDIT, data),
                };
              }}
              onChange={(_, filters) => {
                const selectedInboxFilterData = filters?.inbox_type as string[];
                setInboxMainPageState((prev) => {
                  return {
                    ...prev,
                    selectedInboxFilter: selectedInboxFilterData
                  };
                });
              }}
            />
        )}
      </PageBodyContainer>
      {isSmsInboxSelected() && (
        <SmsInboxDrawer
          isDrawerVisible={true}
          allUserList={accountUserData?.accountUsers || []}
          selectedPhoneNumber={inboxMainPageState.selectedInboxData?.phoneNumber}
          selectedInboxData={
            inboxMainPageState.selectedInboxData || ({} as any)
          }
          onSmsInboxActionPerformed={(actionCode: string) => {
            onSmsInboxActionPerformed(actionCode);
          }}
        />
      )}
      {isEmailInboxSelected() && (
        <EmailInboxDrawer
          isDrawerVisible={true}
          selectedInboxData={
            inboxMainPageState.selectedInboxData || ({} as any)
          }
          onEmailInboxActionPerformed={(actionCode: string) => {
            onSmsInboxActionPerformed(actionCode);
          }}
          parentCode={PARENT_CODE.INTEGRATION_LANDING}
        />
      )}

      {isPurchaseNewNumberSelected() && (
        <PurchaseNumberView
          screenName={'INBOX_SCREEN'}
          onFormCompleteAction={(actionCode: string) => {
            setTimeout(() => {
              onSmsInboxActionPerformed(actionCode);
            }, 500);
          }}
          userList={userListResp?.users || []}
        />
      )}
    </VStack>
  );
};
