import { CALL_STATUS_LABELS } from "../../../../constants";
import { Colors } from "../../../../styles";
import {ICallTrail} from "../../CallTrail/interfaces";
import { CALL_STATUS, CALL_TRANSCRIPTION_STATUS, CallDirections, TWILIO_CALL_STATUS } from "./CallLogsConst";
import { ICallLogEntry, ITwilioSidData } from "./interface";

export const getCallStatus = (contactData: ICallLogEntry) => {
  const isDeclinedCall =
    contactData?.direction === CallDirections.Incoming && contactData?.status === TWILIO_CALL_STATUS.BUSY;
  let isAbandonedCall;
  if (contactData?.statusLabel === CALL_STATUS_LABELS.ABANDONED_CALL) {
    isAbandonedCall = true;
  } else if (!contactData?.statusLabel // This is the case when patient disconnect the call in between
    && (contactData?.direction === CallDirections.Incoming && contactData?.status === TWILIO_CALL_STATUS.NO_ANSWER && contactData?.callTrail)) {
      isAbandonedCall = true;
  }
  const isVoiceMailCall =
    contactData?.statusLabel === CALL_STATUS_LABELS.VOICE_MAIL;
  if (isVoiceMailCall) {
    return 'Voice mail';
  }
  if (isDeclinedCall) {
    return 'Missed Call (Declined)';
  }
  if (isAbandonedCall) {
    return 'Missed Call (Abandoned)';
  }
  if (contactData?.direction == CallDirections.Incoming) {
    if (contactData?.status == TWILIO_CALL_STATUS.COMPLETED) {
      return 'Answered Call';
    } else if (
      contactData?.status == TWILIO_CALL_STATUS.BUSY ||
      contactData?.status == TWILIO_CALL_STATUS.NO_ANSWER ||
      contactData?.status == TWILIO_CALL_STATUS.FAILED
    ) {
      return 'Missed Call';
    } else {
      return 'Incoming';
    }
  } else if (contactData?.workflowEventId && contactData?.direction == 'Outgoing Dial') {
    const phoneTreeName = getPhoneTreeNameFromCallTrail(contactData?.callTrail || []);
    return phoneTreeName ? `Automated Call (${phoneTreeName} IVR)` : 'Automated Call';
  } else if (contactData?.direction == 'Outgoing Dial') {
    return 'You Called';
  }
  return '';
};

const getPhoneTreeNameFromCallTrail = (callTrail: ICallTrail[]) => {
  let phoneTreeName = '';
  for (const singleTrail of callTrail || []) {
    if (singleTrail.phoneTreeName) {
      phoneTreeName = singleTrail.phoneTreeName;
      break;
    }
  }
  return phoneTreeName;
}

export const getCallStatusForTranscription = (contactData: ICallLogEntry) => {
  if (contactData?.direction == CallDirections.Incoming) {
    if (contactData.status == TWILIO_CALL_STATUS.COMPLETED) {
      return CALL_STATUS.ANSWERED_CALL;
    } else if (
      contactData.status == TWILIO_CALL_STATUS.BUSY ||
      contactData.status == TWILIO_CALL_STATUS.NO_ANSWER ||
      contactData.status == TWILIO_CALL_STATUS.FAILED
    ) {
      return CALL_STATUS.MISSED_CALL;
    } else {
      return CALL_STATUS.INCOMING_CALL;
    }
  }
  return '';
};

export const isShowTranscription = (callLog: ICallLogEntry, isCallAndTranscriptionEnable: boolean) => {
  const callStatus = getCallStatusForTranscription(callLog);
  let isShowTranscription = false;
  if (callStatus === CALL_STATUS.MISSED_CALL && !callLog?.recording?.length) {
    isShowTranscription = false;
  } else if (
    callStatus === CALL_STATUS.MISSED_CALL &&
    callLog?.recording?.length &&
    callLog?.transcription &&
    callLog?.transcription !== CALL_TRANSCRIPTION_STATUS.NO_TRANSCRIPTION
  ) {
    isShowTranscription = true;
  } else if (
    isCallAndTranscriptionEnable &&
    callLog?.callrecord &&
    callLog?.transcription &&
    callLog?.transcription !== CALL_TRANSCRIPTION_STATUS.NO_TRANSCRIPTION
  ) {
    isShowTranscription = true;
  }
  return isShowTranscription;
};

export const getCallStatusForEmployerReporting = (contactData: ICallLogEntry) => {
  if (contactData?.direction == 'inbound') {
    if (contactData.status == 'completed') {
      return 'Incoming Call';
    } else if (contactData.status == 'busy' || contactData.status == 'no-answer') {
      return 'Missed Call';
    } else {
      return 'Incoming Call'
    }
  } else if (contactData?.direction == 'Outgoing Dial') {
    return 'Outgoing Call';
  }
  return '';
};

export const getCallIcons = (contactData: ICallLogEntry) => {
  if (contactData?.direction == 'inbound') {
    if (contactData.status == 'completed') {
      return 'phone-incoming';
    } else if (contactData.status == 'busy' || contactData.status == 'no-answer') {
      return 'phone-incoming';
    } else {
      return 'phone-incoming';
    }
  } else if (contactData?.direction == 'Outgoing Dial') {
    return 'phone-outgoing';
  }
  return 'phone-incoming';
};

export const getCallIconsColors = (contactData: ICallLogEntry) => {
  if (contactData?.direction == 'inbound') {
    if (contactData.status == 'completed') {
      return 'green';
    } else if (contactData.status == 'busy' || contactData.status == 'no-answer') {
      return 'red';
    } else {
      return 'green';
    }
  } else if (contactData?.direction == 'Outgoing Dial') {
    return Colors.Custom.Gray500;
  }
  return Colors.Custom.Gray400;
};

export const getCallLogsParams = (
  page: number,
  pageSize: number,
  conversationFilterQueryDateRange: any,
  conversationFilterQueryFilter: any,
  searchString: any,
  contactUuid?: string,
  userVirtualMobileNo?: any,
  isCommunicationLocationHandlingEnabled?: boolean
) => {
  const params: any = {
    page: page,
    perPage: pageSize,
    //searchQuery: searchString,
  };
  const dateRangeObject = conversationFilterQueryDateRange;
  if (dateRangeObject?.length) {
    if (dateRangeObject?.[0]?.updatedAt?._lt) {
      const toDate = dateRangeObject?.[0]?.updatedAt?._lt;
      params.toDate = `${toDate}`;
    }
    if (dateRangeObject?.[1]?.updatedAt?._gt) {
      const fromDate = dateRangeObject?.[1]?.updatedAt?._gt;
      params.fromDate = `${fromDate}`;
    }

    if (dateRangeObject?.[0]?.lastActivityAt?._lte) {
      const toDate = dateRangeObject?.[0]?.lastActivityAt?._lte;
      params.toDate = `${toDate}`;
    }
    if (dateRangeObject?.[1]?.lastActivityAt?._gte) {
      const fromDate = dateRangeObject?.[1]?.lastActivityAt?._gte;
      params.fromDate = `${fromDate}`;
    }
  }

  const callLogFilters = conversationFilterQueryFilter;
  if (callLogFilters?.direction) {
    params.direction = callLogFilters?.direction;
    if (params.direction === 'inbound') {
      params.status = 'completed';
    }
  }

  if (callLogFilters?.isOutOfOfficeHours) {
    params.isOutOfOfficeHours = callLogFilters?.isOutOfOfficeHours;
  }
  if (callLogFilters?.status) {
    params.direction = 'inbound';
    params.status = callLogFilters?.status;
    //params.status = 'busy';
  }
  // if (callLogFilters?.toNumber?.length) {
  //   params.toNumber = callLogFilters?.toNumber;
  // }
  if (callLogFilters?.toNumber?.length) {
    params.locationNumber = callLogFilters?.toNumber;
  }
  if (userVirtualMobileNo?.length && isCommunicationLocationHandlingEnabled && !callLogFilters?.toNumber?.length) {
    params.locationNumbers = userVirtualMobileNo?.map((number: any) => number?.virtualPhoneNumber)
  }
  if (callLogFilters?.searchQuery) {
    params.searchQuery = callLogFilters?.searchQuery;
  }
  if (searchString.length) {
    params.searchQuery = searchString;
  }

  if (callLogFilters?.webPhoneToUserId) {
    params.webPhoneToUserId = callLogFilters?.webPhoneToUserId;
  }

  if (contactUuid) {
    params.contactUuid = contactUuid
  }

  return params;
};

export function parseTwilioRecordingUrl(twilioUrl: string): ITwilioSidData {
  const urlResourceArray = twilioUrl.split('/');
  let recordingSid = '';
  let accountSid = '';

  for (let index = 0; index < urlResourceArray.length; index++) {
    if (urlResourceArray[index] === 'Accounts') {
      accountSid = urlResourceArray[index + 1];
    }

    if (urlResourceArray[index] === 'Recordings') {
      recordingSid = urlResourceArray[index + 1];
    }
  }

  if (!recordingSid && !accountSid) {
    const parts = twilioUrl.split('/');
    if (parts?.length >= 2) {
      recordingSid = parts[parts.length - 1];
      accountSid = parts[parts.length - 2];
    }
  }

  return {
    accountSid: accountSid,
    recordingSid: recordingSid,
  };
}

export const isShowCallTrail = (callLogEntry: ICallLogEntry) => {
  return !!(callLogEntry?.callTrail && callLogEntry?.callTrail?.length > 0);
};
