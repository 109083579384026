import React from 'react';
import {Path, Svg} from 'react-native-svg';
import {Colors} from '../../styles/Colors';
import {ICommonSvgProps} from '../../components/common/Svg/interfaces';

function ElationHealthIcon(props: ICommonSvgProps) {
  const height = props?.height || '24';
  const width = props?.width || '24';
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="#CBEEFA"
      />
      <Path
        d="M11.4478 6.29552C11.4478 6.48657 11.3045 6.62985 11.1134 6.62985H10.5403C10.3493 6.62985 10.206 6.48657 10.206 6.29552V3.33433C10.206 3.14328 10.3493 3 10.5403 3H11.1134C11.3045 3 11.4478 3.14328 11.4478 3.33433V6.29552Z"
        fill="#1D94BC"
      />
      <Path
        d="M18.2776 19H10.2537C10.2537 15.4179 10.2537 11.7881 10.2537 8.20597H18.2776V10.1642H12.2597V12.6478H18.0866V14.5582H12.2597V16.994H18.2776V19Z"
        fill="#1D94BC"
      />
      <Path
        d="M5.33433 9.44776C5.14328 9.44776 5 9.30448 5 9.11343V8.5403C5 8.34925 5.14328 8.20597 5.33433 8.20597H8.29552C8.48657 8.20597 8.62985 8.34925 8.62985 8.5403V9.11343C8.62985 9.30448 8.48657 9.44776 8.29552 9.44776H5.33433Z"
        fill="#1D94BC"
      />
      <Path
        d="M13.0239 7.48955C12.8806 7.63284 12.6896 7.63284 12.5463 7.48955L12.1164 7.0597C11.9731 6.91642 11.9731 6.72537 12.1164 6.62985L14.2179 4.52836C14.3612 4.38507 14.5522 4.38507 14.6955 4.52836L15.1254 4.95821C15.2687 5.10149 15.2687 5.29254 15.1254 5.43582L13.0239 7.48955Z"
        fill="#1D94BC"
      />
      <Path
        d="M7.38806 13.1254C7.24478 13.2687 7.05373 13.2687 6.91045 13.1254L6.4806 12.6955C6.33731 12.5522 6.33731 12.3612 6.4806 12.2657L8.62985 10.1642C8.77313 10.0209 8.96418 10.0209 9.10746 10.1642L9.53731 10.594C9.6806 10.7373 9.6806 10.9284 9.53731 11.0716L7.38806 13.1254Z"
        fill="#1D94BC"
      />
      <Path
        d="M6.52836 5.38806C6.38507 5.24478 6.38507 5.05373 6.52836 4.91045L6.95821 4.4806C7.10149 4.33731 7.29254 4.33731 7.38806 4.4806L9.48955 6.58209C9.63284 6.72537 9.63284 6.91642 9.48955 7.0597L9.0597 7.48955C8.91642 7.63284 8.72537 7.63284 8.58209 7.48955L6.52836 5.38806Z"
        fill="#1D94BC"
      />
    </Svg>
  );
}

export default React.memo(ElationHealthIcon);
