import LocalStorage from "../../../../utils/LocalStorage";
import CommonService from "../../../../services/CommonService/CommonService";
import { SingleSummarizationPromptTemplate } from "./interfaces";

const commonService = CommonService.getCommonServiceInstance();
const llmflowService = commonService.llmService;

export const getHeaders = async () => {
  const userDataString = await LocalStorage.getItem('user');
  const userData = JSON.parse(userDataString);
  const accountUUID =
    userData?.data.accounts.length > 0
      ? userData?.data.accounts[0].uuid || ''
      : '';
  const userId = userData?.data.id;
  const responseAccessToken = userData?.accessToken || '';
  const fold_access_token = userData?.fold_access_token;
  const headersObj: any = {
    'access-token': responseAccessToken,
    fold_access_token,
    'x-hasura-account-uuid': accountUUID,
    'x-hasura-user-id': userId,
    'content-type': 'application/json'

  };
  return headersObj;
};

export const deleteSummarizationPromptTemplate = async (templateId: string) => {
  try {
    const responseData =  await llmflowService.delete(
      `/summary/template/${templateId}`
    )
    return responseData;

  } catch (err: any) {

    return
  }
};

export const updateTemplateStatus = (templateId: string, isActive: boolean, templateData: any) => {
  try {
    llmflowService.put(
      `/summary/template/${templateId}`,
      {
        ...templateData,
        isActive
      }
    ).then((responseData: any) => {
    })
  } catch (error: any) {
  }
};

export const createNewSummarizationPromptTemplate = (templateBody: SingleSummarizationPromptTemplate) => {
  try {
    return llmflowService.post(
      `/summary/template`,
      {
        ...templateBody,
        templateCategory: 'summarizationPrompt'
      }
    ).then((responseData: any) => {
    })
  } catch (error: any) {
  }
}


export const updateSummarizationPromptTemplate = (templateBody: SingleSummarizationPromptTemplate, templateId: string) => {
  try{
    return llmflowService.put(
      `/summary/template/${templateId}`,
      {
        ...templateBody,
        templateCategory: 'summarizationPrompt'
      }
    ).then((responseData: any)=>{
    })
  } catch(error:any){
  }
}

export const getSummarizationPromptTemplates = async (searchQuery: string, page: number, pageSize: number) => {
  return llmflowService.get(`/summary/template?searchQuery=${searchQuery}&page=${page}&pageSize=${pageSize}&templateCategory=summarizationPrompt`).then((responseData: any) => {
    return responseData;
  })
}