import React from 'react';
import { Defs, G, Path, Svg, Rect } from 'react-native-svg';
import { ICommonSvgProps } from '../../components/common/Svg/interfaces';

function LockKeyholeIcon(props: ICommonSvgProps) {
  const customBgColor = props?.customBgColor || "white";
  const customStrokeColor = props?.customStrokeColor || "#A7AFBE";
  const width = props?.width || "12";
  const height = props?.height || "12";
  return (
    <Svg width={width} height={height} viewBox="0 0 12 12" fill="none">
      <G clipPath="url(#clip0_1576_7493)">
        <Path 
          d="M3 5V4C3 2.34315 4.34315 1 6 1C7.65685 1 9 2.34315 9 4V5M4 11H8C9.41421 11 10.1213 11 10.5607 10.5607C11 10.1213 11 9.41421 11 8C11 6.58579 11 5.87868 10.5607 5.43934C10.1213 5 9.41421 5 8 5H4C2.58579 5 1.87868 5 1.43934 5.43934C1 5.87868 1 6.58579 1 8C1 9.41421 1 10.1213 1.43934 10.5607C1.87868 11 2.58579 11 4 11ZM7 8C7 8.55228 6.55228 9 6 9C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7C6.55228 7 7 7.44772 7 8Z" 
          stroke={customStrokeColor}
          strokeLinecap="round"
        />
      </G>
      <Defs>
        <clipPath id="clip0_1576_7493">
          <Rect width="12" height="12" fill={customBgColor} />
        </clipPath>
      </Defs>
    </Svg>

  );
}

export default LockKeyholeIcon;