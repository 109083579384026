
import React, { useContext, useEffect, useState } from "react";
import { getPatientClaim } from "../../../services/CommonService/AidBoxService";
import { Claim } from 'fhir/r4';
import { HStack, Pressable, Skeleton, Spinner, Text, useToast } from "native-base";
import { AWV_TYPE_LIST, AWV_VISIT_CODE } from "./constant";
import { DATE_FORMATS, EVENT_NAMES } from "../../../constants";
import { getDateStrFromFormat, isDateBetweenFutureDays, isDateBetweenLastDays } from "../../../utils/DateUtils";
import { Colors } from "../../../styles/Colors";
import { getEhrConfig } from "../../../utils/capabilityUtils";
import { IAppointmentResponse } from "../../../services/ContactCareProgram/interface";
import AppointmentDetail from "../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentDetail";
import { ToastType, showToast } from "../../../utils/commonViewUtils";
import { useLazyQuery } from "@apollo/client";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../constants/Configs";
import { AppointmentQueries } from "../../../services";
import { IAppointmentData } from "../../../services/Appointment/AppointmentInterface";
import FutureApointmentIconSvg from "../Svg/AwvSvgs/FutureApointmentIconSvg";
import AwvScheduledIconSvg from "../Svg/AwvSvgs/AwvScheduledIconSvg";
import AwvNotScheduledIconSvg from "../Svg/AwvSvgs/AwvNotScheduledIconSvg";
import AwvCompletedIconSvg from "../Svg/AwvSvgs/AwvCompletedIconSvg";
import AppointmentBooking from "../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBooking";
import { APPOINTMENT_TYPE_VISIT_TYPE, MLOV_CATEGORY } from "../../../constants/MlovConst";
import { EventBus } from "../../../utils/EventBus";
import { GET_FUTURE_BOOKED_APPOINTMENT_BY_ID } from "../../../services/Appointment/AppointmentQueries";
import { getMlovCodeFromId, getMlovListFromCategory } from "../../../utils/mlovUtils";
import { CommonDataContext } from "../../../context/CommonDataContext";
import { getAppointmentDateDisplayText } from "../../PublicPages/AppointmentRSVPWidget/AppointmentRsvpUtils";


export const MemberAWVStatusView = (props: { patientId: string, appointmentData: IAppointmentResponse, patientLocationUuid: string , contactId: string }) => {
  const { appointmentData, patientLocationUuid } = props;
  const ehrConfig = getEhrConfig(patientLocationUuid, '');
  const [loading, setLoading] = useState<boolean>(false);
  const [bookAppointmentLoading, setBookAppointmentLoading] = useState<boolean>(false);

  const [appointmentTasksModalOpen, setAppointmentTasksModalOpen] = useState(false);
  const [appointmentScheduleModalOpen, setAppointmentScheduleModalOpen] = useState(false);
  const [awvProgramDate, setAwvDate] = useState<string>('Not scheduled');
  const [lastAwvProgramDate, setLastAwvDate] = useState<string>('');
  const [appointmentDetails, setAppointmentDetails] = useState({} as any)
  const toast = useToast();
  const eventBus = EventBus.getEventBusInstance();
  const mlovData = useContext(CommonDataContext);
  const appointmentStatusList =  getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.APPOINTMENT_STATUS
  );

  const [getBookedAppointments] = useLazyQuery<IAppointmentData>(
    AppointmentQueries.GET_BOOKED_APPOINTMENTS_BY_ID,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
    }
  );

  const [getFutureBookedAppointmentById ] = useLazyQuery(
    GET_FUTURE_BOOKED_APPOINTMENT_BY_ID,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    },
  );

  const getBookAppointmentDetails = async (appointmentId: string) => {
    setBookAppointmentLoading(true)
    const appointmentRespData = await getBookedAppointments({
      variables: {
        id: appointmentId
      }
    });
    setBookAppointmentLoading(false)
    if (appointmentRespData?.data?.appointments?.[0]) {
      setAppointmentDetails(appointmentRespData?.data?.appointments[0])
      setAppointmentTasksModalOpen(true)
    } else {
      showToast(toast, "Appointment Details not found", ToastType.error)
    }
  }

  const getAwvTypeICon = (awvType: string) => {
    switch (awvType) {
      case AWV_TYPE_LIST.FUTURE_APPOINTMENT:
        return <FutureApointmentIconSvg />
      case AWV_TYPE_LIST.AWV_SCHEDULED:
        return <AwvScheduledIconSvg />
      case AWV_TYPE_LIST.NOT_SCHEDULED:
        return <AwvNotScheduledIconSvg />
      case AWV_TYPE_LIST.AWV_COMPLETED:
        return <AwvCompletedIconSvg />
    }
  }

  const getAppointmentDetails = () => {
    return { detail: appointmentDetails };
  };

  const getAwvFromClaimData = async () => {
    setLoading(true);
    try {
      if (!ehrConfig?.isAthena && !ehrConfig?.isElation) {
        setAwvDate('');
        setLoading(false);
      }
      const claimResponse = await getPatientClaim(props?.patientId, props?.patientLocationUuid);
      if (claimResponse?.data?.entry?.length) {
        const claimEntries: any[] = claimResponse?.data?.entry;
        let initialVisitDate: string | undefined = '';
        // let awvVisitDate: string | undefined = '';  code kept for future use
        let subSequenceAWV: string | undefined = '';
        let currentYearAwvVisitDate: string | undefined = '';
        let currentYearPastAwvVisitDate: string | undefined = '';
        let currentYearSubSequenceAWV: string | undefined = '';
        let currentYearInitialVisit: string | undefined = '';
        const awvVisitDates: string[] = [];

        const subSequentAwvList: string[] = [];
        for (const claimEntry of claimEntries) {
          const resource: Claim = claimEntry?.resource || {};
          (resource?.procedure || []).forEach(patientProcedure => {
            if (patientProcedure?.procedureCodeableConcept?.coding?.length) {
              (patientProcedure.procedureCodeableConcept.coding || []).forEach(procedureCode => {
                if (procedureCode?.code === AWV_VISIT_CODE.INITIAL_VISIT) {
                  initialVisitDate = resource.billablePeriod?.end;
                  if(initialVisitDate){
                    awvVisitDates.push(initialVisitDate);
                  }
                  if (initialVisitDate && isDateBetweenLastDays(initialVisitDate, 366)) {
                    currentYearInitialVisit = initialVisitDate;
                  }
                }
                if (procedureCode?.code === AWV_VISIT_CODE.AWV_VISIT) {
                  const visitDate = resource.billablePeriod?.end;
                  if(visitDate){
                    awvVisitDates.push(visitDate);
                  }

                  if (visitDate && isDateBetweenLastDays(visitDate, 366)) {
                    currentYearAwvVisitDate = visitDate;
                    setLastAwvDate(visitDate)
                  }
                  // code kept for future use
                  // if (visitDate && !isDateBetweenLastDays(visitDate, 366)) {
                  //   awvVisitDate = visitDate;
                  // }
                }
                if (procedureCode?.code === AWV_VISIT_CODE.SUB_SEQUENT_VISIT) {
                  subSequenceAWV = resource.billablePeriod?.end;
                  subSequenceAWV && subSequentAwvList.push(subSequenceAWV);
                  if(subSequenceAWV){
                    awvVisitDates.push(subSequenceAWV);
                  }
                  if (subSequenceAWV && isDateBetweenLastDays(subSequenceAWV, 366)) {
                    currentYearPastAwvVisitDate = subSequenceAWV;
                  } else if (subSequenceAWV && isDateBetweenFutureDays(subSequenceAWV, 90)) {
                    currentYearSubSequenceAWV = subSequenceAWV
                  }
                }
              })
            }
          });
        }

        if (awvVisitDates?.length) {
          awvVisitDates.sort((date1: string, date2: string) => {
            return new Date(date2).getTime() - new Date(date1).getTime();
          });
          setLastAwvDate(awvVisitDates?.[0]);
        }

        if (subSequentAwvList?.length) {
          subSequentAwvList.sort((date1: string, date2: string) => {
            return new Date(date2).getTime() - new Date(date1).getTime();
          });
        }
        const isCurrentYearAWVVisit = initialVisitDate && currentYearAwvVisitDate;
        const isCurrentYearSubSequentVisit = currentYearSubSequenceAWV ? true : false;
        if (!initialVisitDate) {
          setAwvDate('IPPE Pending');
        } else {
          if (isCurrentYearAWVVisit) {
            if (isCurrentYearSubSequentVisit) {
              setAwvDate(`Scheduled on ${getDateStrFromFormat(currentYearSubSequenceAWV, DATE_FORMATS.CALENDAR_LIB_FORMAT)}`);
            }
          } else if (currentYearAwvVisitDate) {
            setAwvDate(`Completed on ${getDateStrFromFormat(currentYearPastAwvVisitDate, DATE_FORMATS.CALENDAR_LIB_FORMAT)}`)
            //  code kept for Future use
            // const isAWVNotDoneYet = initialVisitDate && !awvVisitDate;
            // const isSubSequentNotDoneYet = initialVisitDate && awvVisitDate && !subSequenceAWV;
            // // if current AWV is done yet then show AWV due with initial visit date
            // if (isAWVNotDoneYet) {
            //   setAwvDate(`Initial AWV Due`);
            // } else if (isSubSequentNotDoneYet) {
            //   // if current AWV is done but sub sequent not done yet then show last AWV date
            //   setAwvDate(`Subsequent AWV Pending`);
            // } else if (!isAWVNotDoneYet && subSequentAwvList?.length) {
            //   // if current AWV is done and current year sub sequent not done then show last year sub sequent
            //   setAwvDate(`Subsequent AWV Pending`);
            // }
          }
        }
      }
      setLoading(false);
    } catch (error) {

      setLoading(false);
    }
  }

  useEffect(() => {
    if (!loading) {
      getAwvFromClaimData();
    }
  }, []);

  const getFoldAppointmentText = (appointmentData: IAppointmentResponse) => {
    const appointmentStatus = getMlovCodeFromId(
      appointmentStatusList,
      appointmentData?.statusId
    )
    if(isAwvAppointment()) {
      return `${getAppointmentDateDisplayText(appointmentStatus)} ${getDateStrFromFormat(
        appointmentData?.startDateTime,
        DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
      )}`;
    }
    else {
      return `Next Appt : ${getDateStrFromFormat(
        appointmentData?.startDateTime,
        DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
      )}`;
    }
  }

  const isAwvAppointment = () => {
    return (
      appointmentData.appointmentType?.visitType?.code ===
      APPOINTMENT_TYPE_VISIT_TYPE.AWV
    );
  }

  const refreshAppointmentData = async () => {
    setBookAppointmentLoading(true);
    const appointmentResponse = await getFutureBookedAppointmentById({
      variables: {
        id: appointmentData?.id
      },
    });
    setBookAppointmentLoading(false);
    const appointment = appointmentResponse?.data?.appointments?.[0];

    if (appointment) {
      eventBus.broadcastEvent(EVENT_NAMES.UPDATE_PATIENT_LIST_APPOINTMENT_DATA, appointment);
    }
  }

  return (
    <>
      {loading && (
        <Skeleton.Text lines={1}></Skeleton.Text>
      )}
      {!loading && (appointmentData?.id ?
        <Pressable onPress={() => { getBookAppointmentDetails(appointmentData?.id) }}>
          <HStack alignItems={'center'} justifyContent={'flex-start'}>
              {bookAppointmentLoading ? (
                <Spinner mr={2} />
              ) : (
                getAwvTypeICon(
                  isAwvAppointment()
                    ? AWV_TYPE_LIST.AWV_SCHEDULED
                    : AWV_TYPE_LIST.FUTURE_APPOINTMENT
                )
              )}
              <Text
                wordBreak={'break-word'}
                color={isAwvAppointment() ? Colors.Custom.SuccessColor : Colors.Custom.Gray500}
                size={'smLight'}
              >
                {getFoldAppointmentText(appointmentData)}
            </Text>
          </HStack>
        </Pressable> :
        <Pressable
          onPress={() => {
            if(awvProgramDate.includes('Not scheduled')){
              setAppointmentScheduleModalOpen(true);
            }
          }}>
          <HStack alignItems={'center'}>
            {awvProgramDate.includes('Scheduled')
              ? getAwvTypeICon(AWV_TYPE_LIST.AWV_SCHEDULED)
              : awvProgramDate.includes('Completed')
              ? getAwvTypeICon(AWV_TYPE_LIST.AWV_COMPLETED)
              : getAwvTypeICon(AWV_TYPE_LIST.NOT_SCHEDULED)}
            <Text
              wordBreak={'break-word'}
              color={
                awvProgramDate.includes('Scheduled')
                  ? Colors.success[500]
                  : Colors.Custom.Gray300
              }
              size={'smRegular'}
            >
              {awvProgramDate}
            </Text>
          </HStack>
        </Pressable>

      )}
      {
        appointmentTasksModalOpen &&
          <AppointmentDetail
            reasonsForVisitList={[appointmentDetails.reasonForVisit]}
            event={getAppointmentDetails()}
            isVisible={true}
            showAwvCard={!isAwvAppointment()}
            onClose={() => {
              setAppointmentTasksModalOpen(false);
              refreshAppointmentData();
            }}
            lastAwvProgramDate={lastAwvProgramDate}
          />
      }
      {appointmentScheduleModalOpen && (
        <AppointmentBooking
          isVisible={true}
          onComplete={() => {
            setAppointmentScheduleModalOpen(false);
          }}
          onCancel={() => {
            setAppointmentScheduleModalOpen(false);
          }}
        />
      )}
    </>
  )
}
