import {isEmptyArray} from '../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import { IAvailabilityCalendarData, IUserAvailability } from '../../../AccountSettings/PracticeAvailabilityNew/PracticeAvailabilityInterfaces';
import {IUserRoleMap, UserPracticeLocation} from '../interfaces';
import {LocationApiResponse, LocationData, LocationObject} from './interfaces';

export const getLocationsToDelete = (
  previousLocations: UserPracticeLocation[] | undefined,
  selectedLocations: string[],
  locationUUid: any
): string[] => {
  const result: string[] = [];
  previousLocations?.forEach((item) => {
    if (item.accountLocations.length) {
      item.accountLocations.forEach((accountLocation) => {
        const checkCondition = selectedLocations.some(
          (id) => id === accountLocation.uuid
        );
        if (!checkCondition) {
          result.push(item.uuid);
        }
      });
    }
  });

  return result;
};

export const createPracticeLocationArray = (
  locationIds: string[],
  userUuid: string,
  accountUuid: string
) => {
  return locationIds.map((item) => ({
    accountLocationUuid: item,
    accountUuid: accountUuid,
    userUuid: userUuid,
    isDeleted: false,
  }));
};

export const getAccountLocationsBySelectedLocationUUids = (
  locationUuids: string[],
  practiceLocations: any[],
): string[] => {
  const accountLocationsUuids: string[] = [];
  locationUuids?.forEach((item) => {
    practiceLocations?.forEach((accountLocation) => {
      const isAccountLocationId =
        accountLocation.practiceLocation?.uuid === item;
      if (isAccountLocationId && accountLocation.uuid) {
        accountLocationsUuids.push(accountLocation.uuid);
      }
    });
  });
  return accountLocationsUuids;
};

export const getLocationsToAddOrUpdate = (
  previousLocations: UserPracticeLocation[] | undefined,
  selectedLocations: string[]
): string[] => {
  const result: string[] = [];
  selectedLocations.forEach((id) => {
    const checkCondition = previousLocations?.some(
      (item) => item.practiceLocation?.uuid === id
    );
    if (!checkCondition) {
      result.push(id);
    }
  });

  return result;
};

export const getUserRoleIdsToDelete = (
  previousUserRoles: IUserRoleMap[],
  selectedUserRoleIds: string[]
) => {
  const result: string[] = [];
  if (previousUserRoles) {
    previousUserRoles?.forEach((role) => {
      const checkCondition = selectedUserRoleIds?.some(
        (id) => id === role?.userRole?.userRole?.id
      );
      if (!checkCondition) {
        result.push(role?.userRole?.userRole?.id);
      }
    });
  }
  return result;
};

export const getUserRoleIdsToAddOrUpdate = (
  previousUserRoles: IUserRoleMap[],
  selectedUserRoleIds: string[]
) => {
  const result: string[] = [];
  selectedUserRoleIds?.forEach((id) => {
    const checkCondition = previousUserRoles?.some(
      (role) => role.userRole?.userRole?.id === id
    );
    if (!checkCondition) {
      result.push(id);
    }
  });
  return result;
};
export const getRoleIdsToAddOrUpdate = (
  roles: string[],
  selectedRoles?: string[]
) => {
  const result: string[] = [];
  selectedRoles?.forEach((id) => {
    if (id) {
      const checkCondition = roles?.includes(id)
      if (!checkCondition) {
        result.push(id);
      }
    }
  });
  return result;
};

export const getRoleIdsToDelete = (
  roles: string[],
  selectedRoles?: string[]
) => {
  const result: string[] = [];
  if (roles) {
    roles?.forEach((id) => {
      const checkCondition = selectedRoles?.includes(id)
      if (!checkCondition) {
        result.push(id);
      }
    });
  }
  return result;
};
export const getFormattedLocationData = (
  data: LocationApiResponse
): LocationData[] => {
  const result: LocationData[] = [];
  const entry = data.entry;
  if (isEmptyArray(entry)) {
    return result;
  }
  entry?.forEach((item) => {
    const resource = item.resource;
    const address = resource.address;
    result?.push({
      id: resource.id,
      name: resource.name,
      city: address.city || '',
      line1:
        (address?.line && address.line?.length > 0 && address.line[0]) || '',
      postalCode: getPostalCode(address.postalCode),
      postalCodeId: '',
      state: address.state || '',
      countryId: '',
      timeZoneId: '',
    });
  });
  return result;
};

export const getPostLocationObject = (data: LocationData): LocationObject => {
  const result: LocationObject = {
    id: (data.id || '').toString(),
    address: {
      line: [data.line1],
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
    },
    name: data.name,
    reference: {
      zipcodeId: data.postalCodeId,
      countryId: data.countryId,
      timeZoneId: data.timeZoneId,
      stateId: data.stateId,
      cityId: data.cityId,
    },
    resourceType: 'Location',
  };

  return result;
};

export const getPostalCode = (postalCode: string) => {
  const separator = postalCode.includes('-') ? '-' : '';
  const result =
    separator && postalCode.length > 0
      ? postalCode.split(separator)[0]
      : postalCode;
  return result;
};

export const getZipCodeData = (
  zipCodeData: any
): {
  postalCodeId: string;
  countryId: string;
  stateId: string;
  cityId: string;
} => {
  const zipCodes = zipCodeData.data.zipcodes;
  if (isEmptyArray(zipCodes)) {
    return {
      postalCodeId: '',
      countryId: '',
      stateId: '',
      cityId: '',
    };
  }
  const zipCodeObject = zipCodes[0];
  return {
    postalCodeId: zipCodeObject.id,
    stateId: zipCodeObject.stateId,
    countryId: zipCodeObject.zipcodeState.countryId,
    cityId: zipCodeObject.cityId,
  };
};

export const createLocationErrorHandler = (args: {toast: any; error: any}) => {
  const {toast, error} = args;
  const errorCode = error?.response?.status || 500;
  if (errorCode == 500) {
    showToast(
      toast,
      'Something went wrong while mapping location',
      ToastType.error
    );
    return;
  }
  if (errorCode == 400) {
    const message  = error?.response?.data?.message || 'Something went wrong. Please try again later'
    showToast(
      toast,
      message,
      ToastType.error
    );
    return;
  }
};



export const updatePracticeLocationArray = (locationIds: string[]) => {
  return locationIds.map((item) => {
      return {
          uuid: {
              _eq: item,
          },
      };
  });
};

export const checkAvailabilities = (
  availabilityCalendarData: IAvailabilityCalendarData[]
) => {
 const availabilities = availabilityCalendarData?.reduce((prevValue, data) => {
    if (data.availabilities?.length) {
      prevValue = prevValue.concat(data.availabilities);
    }
    return prevValue;
  }, [] as IUserAvailability[]);
  return availabilities;
}
