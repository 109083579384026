import {gql} from '@apollo/client';

export const ADD_CARE_TEAM = gql`
  mutation CreateCareTeam($params: careTeam_insert_input!) {
    createCareTeam(
      object: $params
      on_conflict: {
        constraint: care_team_pkey
        update_columns: [isDeleted, primaryCarePhysicianUserId]
      }
    ) {
      id
      userPoolId
    }
  }
`;

export const ADD_CARE_TEAM_V2 = gql`
 mutation addOrUpdateContactCareTeam($params: AddOrUpdateContactCareTeamInput!) {
    addOrUpdateContactCareTeam(params: $params) {
      success
    }
  }
`;

export const GET_CARE_TEAM_V2 = gql`query getContactCareTeam($params: GetContactCareTeamInput!) {
  getContactCareTeam(params: $params){
      id
      userPool
      careMemberTypes
      careTeamTypeId
  }
}`

export const GET_CARE_TEAM = gql`
  query GetCareTeam($contactId: uuid) {
    careTeams(
      where: {
        contactId: {_eq: $contactId}
        isDeleted: {_eq: false}
        userPool: {isDeleted: {_eq: false}}
      }
    ) {
      id
      careTeamType {
        code
      }
      contactId
      primaryCarePhysicianUserId
      userPool {
        id
        typeId
        userPoolUsers(where: {isDeleted: {_eq: false}}) {
          id
          userId
        }
      }
    }
  }
`;

export const GET_MY_PATIENT = gql`
  query GetMyPatient($userId: uuid!, $tenantId: uuid!) {
    careTeams(
      where: {
        userPool: {
          userPoolUsers: {
            userId: {
              _eq: $userId},
              isDeleted: {_eq: false},
              tenantId: {_eq: $tenantId}
            }
          }
        }
    ) {
      contactId
    }
  }
`;

export const GET_CONTACTS_BY_CARE_MANAGER = gql`
  query getContactsByCareManager($roleId: uuid!, $userId: uuid) {
    care_team_member_types(
      where: {
        careTeamMemberTypeId: {_eq: $roleId}
        userId: {_eq: $userId}
        isDeleted: {_eq: false}
      }
    ) {
      careTeam {
        contactId
      }
    }
  }
`;

export const UPDATE_PRIMARY_CARE_PHYSICIAN = gql`
  mutation UpdatePrimaryCarePhysicianUser(
    $careTeamId: uuid!
    $primaryCarePhysicianUserId: uuid
  ) {
    updateCareTeam(
      pk_columns: {id: $careTeamId}
      _set: {primaryCarePhysicianUserId: $primaryCarePhysicianUserId}
    ) {
      id
    }
  }
`;

export const DELETE_CARE_TEAM_USER = gql`
  mutation DeleteCareTeamUser(
    $userId: uuid!
    $careTeamId: uuid!
    $userUuid: uuid!
  ) {
    updateUserPoolUser(pk_columns: {id: $userId}, _set: {isDeleted: true}) {
      id
    }
    updateCareTeams(
      where: {
        primaryCarePhysicianUserId: {_eq: $userUuid}
        id: {_eq: $careTeamId}
      }
      _set: {primaryCarePhysicianUserId: null}
    ) {
      affected_rows
    }
  }
`;

export const GET_UNDER_MY_CARE_COUNT = gql`
  query GetUnderMyCarePatientCount($userId: uuid!, $usePoolTypeId: uuid!) {
  aggregateUserPoolUsers(where: {
    isDeleted: {_eq: false},
    userId: {_eq: $userId},
    userPool: {
      typeId: {_eq: $usePoolTypeId}
    }
  }) {
    aggregate {
      count
    }
  }
}`;

export const GET_UNDER_MY_CARE_CONTACT_IDS = gql`
  query GetUnderMyCareContactIds($userId: uuid!) {
    careTeams(
      where: {
        userPool: {
          userPoolUsers: {
            userId: {
              _eq: $userId},
              isDeleted: {_eq: false},
            }
          }
        }
    ) {
      contactId
    }
  }
`;

export const GET_UNDER_MY_CARE_CONTACT_WITH_MATCH_CARE_MEMBER_TYPE_USER = gql`
  query GetUnderMyCareContactIds($userId: uuid!, $memberTypeCode: String, $memberTypeUserId: uuid!) {
    careTeams(
      where: {
        userPool: {
          userPoolUsers: {
            userId: {
              _eq: $userId},
              isDeleted: {_eq: false},
            }
          }
          careTeamMemberType: {
            memberType: {
              code: {_eq: $memberTypeCode}
            },
            userId: {_eq: $memberTypeUserId}, isDeleted: {_eq: false}
          }
        }
    ) {
      contactId
    }
  }
`;

export const GET_CONTACT_ID_BY_MATCH_CARE_MEMBER_TYPE_USER = gql`
  query GetMatchCareTeamMemberTypeContactIds($memberTypeCode: String, $memberTypeUserId: uuid!) {
    careTeams(
      where: {
          careTeamMemberType: {
            memberType: {
              code: {_eq: $memberTypeCode}
            },
            userId: {_eq: $memberTypeUserId}, isDeleted: {_eq: false}
          }
        }
    ) {
      contactId
    }
  }
`;

export const ADD_OR_UPDATE_CARE_TEAM_MEMBER_TYPES = gql`
  mutation addOrUpdateCareTeamMemberTypes($params: AddOrUpdateCareTeamMemberTypeInput) {
    addOrUpdateCareTeamMemberTypes(params: $params) {
      id
    }
  }
`;


export const GET_CARE_TEAM_MEMBER_TYPES_BY_TYPE_ID = gql`
  query getCareTeamMemberTypes($careTeamId: uuid!, $careTeamMemberTypeId: uuid!) {
    care_team_member_types(
      where: {careTeamId: {_eq: $careTeamId}, careTeamMemberTypeId: {_eq: $careTeamMemberTypeId}, isDeleted: {_eq: false}}
      ) {
      careTeamId
      careTeamMemberTypeId
      id
      userId
    }
  }
`;

export const GET_CARE_TEAM_MEMBER_TYPES = gql`
  query getCareTeamMemberTypes($careTeamId: uuid!) {
    care_team_member_types(
      where: {careTeamId: {_eq: $careTeamId}, isDeleted: {_eq: false}}
    ) {
      careTeamId
      careTeamMemberTypeId
      id
      userId
    }
  }
  `;

  export const GET_CARE_TEAM_MEMBER_TYPE = gql` 
  query getContactCareTeamMemberType($contactIds:[uuid!]!, $careTeamTypeId:uuid!) {
    careTeams(where: {
      contactId: {_in: $contactIds},
      careTeamTypeId: {_eq: $careTeamTypeId}
    }) {
        contactId
        id
        userPoolId
        careTeamMemberType(where: {isDeleted: {_eq: false}}) {
          userId
          id
          careTeamMemberTypeId
          memberType {
            code
            value
          }
        }
    }
  }
  `;

  export const GET_CARE_TEAM_MEMBER_TYPE_BY_CONTACT_IDS = gql`
    query getContactCareTeamByContactIds($params: GetContactCareTeamByContactIdsInput!) {
      getContactCareTeamByContactIds(params: $params) {
        id
        userPool
        careTeamMemberType
        careTeamTypeId
        contactId
      }
    }
  `;

export const GET_CARE_TEAM_MEMBER_PCP_TYPE = gql`
  query getCareTeamMemberPcpType($contactIds:[uuid!]!, $careTeamTypeId:uuid!) {
    careTeams(where: {
      contactId: {_in: $contactIds},
      careTeamTypeId: {_eq: $careTeamTypeId}
    }) {
        contactId
        id
        userPoolId
        careTeamMemberType(where: {
          isDeleted: {_eq: false},
          memberType: {
            code: {_eq: "PCP"}
          }
        }) {
          userId
          id
          careTeamMemberTypeId
          memberType {
            code
            value
          }
        }
    }
  }
  `;

export const GET_CARE_PROGRAM_ENROLLMENT_DATE = gql`
  query getCareProgramEnrollmentDate($typeId: uuid, $contactId: uuid) {
    contactCarePrograms(
      where: {
        isDeleted: {_eq: false}
        contactId: {_eq: $contactId}
        payerCareProgram: {careProgramTypeId: {_eq: $typeId}}
      }
    ) {
      enrollmentLog {
        enrollmentDateTime
      }
    }
  }
`;

export const GET_USER_POOL_BY_ID = gql`
  query getPoolByNameAndType($id: uuid!) {
    userPools(where: {isDeleted: {_eq: false}, id: {_eq: $id}}) {
      id
      userPoolUsers(where: {isDeleted: {_eq: false}}) {
        id
        userId
        isDefault
        isDeleted
      }
    }
  }
`;

export const BULK_CARE_TEAM_UPDATE = gql`
  mutation addOrUpdateBulkCareTeamUser($params: AddOrUpdateBulkCareTeamUserInput) {
    addOrUpdateBulkCareTeamUser(params: $params) {
      processId
    }
  }
`;

export const BULK_CARE_TEAM_OPERATION_STATUS = gql`
  query GetBulkCareTeamOperationStatus($transactionId: uuid!, $processedStatusIds: [uuid!]) {
    processed: message_execution_log_aggregate(
      where: {transactionId: {_eq: $transactionId}, statusId: {_in: $processedStatusIds}}
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const VALIDATE_BULK_CONTACTS = gql`
  query ValidateBulkContacts($data: AddOrUpdateBulkCareTeamUserInput!) {
    validateAddOrUpdateBulkCareTeamOperation(params: $data) {
      contacts {
        id
        conflictCode
      }
    }
  }
`;

export const GET_CONTACT_CARE_TEAM_MEMBER_TYPE_BY_MEMBER_TYPE_ID = gql`
  query getContactCareTeamMemberTypeByMemberTypeId(
    $contactId: uuid!
    $careTeamTypeId: uuid!
    $careTeamMemberTypeId: [uuid!]
  ) {
    careTeams(
      where: {
        contactId: {_eq: $contactId}
        careTeamTypeId: {_eq: $careTeamTypeId}
        isDeleted: {_eq: false}
        careTeamMemberType: {careTeamMemberTypeId: {_in: $careTeamMemberTypeId}, isDeleted: {_eq: false}}
      }
    ) {
      contactId
      id
      userPoolId
      careTeamMemberType(
        where: {
          isDeleted: {_eq: false}
          careTeamMemberTypeId: {_in: $careTeamMemberTypeId}
        }
      ) {
        userId
        id
        careTeamMemberTypeId
        memberType {
          code
          value
        }
      }
    }
  }
`;


export const GET_CARE_TEAM_MEMBERS_BY_MEMBER_TYPE = gql`
  query getCareTeamMembersByMemberType($contactId: uuid, $memberType: String) {
    careTeams(
      where: {
        contactId: {_eq: $contactId}
        isDeleted: {_eq: false}
        careTeamMemberType: {
          isDeleted: {_eq: false}
          memberType: {code: {_eq: $memberType}}
        }
      }
    ) {
      careTeamMemberType(
        where: {isDeleted: {_eq: false}, memberType: {code: {_eq: $memberType}}}
      ) {
        userId
        id
        memberType {
          id
          code
        }
      }
    }
  }
`;