import {Pressable, Skeleton, Text, View} from 'native-base';
import {memo, useContext, useEffect, useState} from 'react';
import {StyleSheet} from 'react-native';
import {getSectionDataByResourceCode} from '../../../../services/Analytics';
import {
  getCurrentTimeZone,
  getDateStrFromFormat,
} from '../../../../utils/DateUtils';
import {ALL_MEMBERS} from '../../../RightSideContainer/Analytics/EmployerAnalytics/EmployerUtils/EmployerConst';
import NodataViewTypeOne from '../../../RightSideContainer/Analytics/EmployerAnalytics/SectionAndWidget/NoDataView/NodataViewTypeOne';
import Chevron from '../../Svg/Chevron';
import WidgetsInfoSvg from '../../Svg/WidgetsInfoSvg';
import {TASK_ASSIGNEE_TYPE_CODES} from '../CareDashboardTable/CareDashboardTableHelper';
import {getInfoTitle} from './commonUtils';
import {IPageSection, ITaskFilterState, IUserData, RESOURCE_LIST} from './interface';
import AverageTimeResolution from './Widgets/AverageTimeResolution';
import TaskAnalyticsDrillDown from './Widgets/Drilldowns/TaskAnalyticsDrillDown';
import OnTimeVsDueDateTask from './Widgets/OnTimeVsDueDateTask';
import TaskCompletedLineChart from './Widgets/TaskCompletedLineChart';
import TaskDistributionWidget from './Widgets/TaskDistributionWidget';
import TaskOverViewWidget from './Widgets/TaskOverViewWidget';
import {DRILL_DOWN_ENABLE_WIDGET} from './Widgets/UtilsConst';
import {isAccountConfigEnabled} from '../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../constants/AccountConfigConst';
import {DASHBOARD_PERMISSION_ACTION, EMPLOYER_REPORT_FILTER_CODES} from '../../../../constants';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {usePermissions} from '../../../CustomHooks/usePermissions';
import {USER_ACCESS_PERMISSION} from '../../../RightSideContainer/UserAccess/UserAccessPermission';

const RenderWidgets = (props: {
  pageSection: IPageSection;
  taskAnalyticState: ITaskFilterState;
  userData: IUserData
}) => {
  const {pageSection, taskAnalyticState, userData} = props;
  const controller = new AbortController();
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const context = useContext(CommonDataContext);
  const {check} = usePermissions(context);
  const permissionObject = check(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    DASHBOARD_PERMISSION_ACTION.TASKS
  );
  const allowedLocationGroupIds = permissionObject?.allowedLocationGroupIds;
  const allowedLocationIds = permissionObject?.allowedLocationIds;
  const [renderWidgetState, setRenderWidgetState] = useState({
    widgetData: {} as any,
    loading: false,
    selectedCode: '',
  });
  const infoTitle = getInfoTitle(pageSection);
  const taskAssigneeIdFilter = !userData?.isBusinessOwner
    ? {
        filterCode: 'TASK_ASSIGNEE_IDS',
        filterValue: {
          value: [userData?.uuid],
        },
      }
    : {};
  const getWidgetByResourceCode = (pageSection: IPageSection) => {
    let ele = <></>;
    switch (pageSection.resource.code) {
      case RESOURCE_LIST.TASK_ANALYTICS_OVERALL_VIEW:
        ele = <TaskOverViewWidget widgetData={renderWidgetState.widgetData} />;
        break;
      case RESOURCE_LIST.TASK_ANALYTICS_AVERAGE_TIME_TO_RESOLUTION_BY_ASSIGNEE:
      case RESOURCE_LIST.TASK_ANALYTICS_AVERAGE_TIME_TO_RESOLUTION_BY_TASK_POOL:
        ele = (
          <AverageTimeResolution
            pageSection={pageSection}
            widgetData={renderWidgetState.widgetData}
          />
        );
        break;
      case RESOURCE_LIST.TASK_ANALYTICS_TASK_DELAY_PERCENTAGE_OVERVIEW_BY_ASSIGNEE:
      case RESOURCE_LIST.TASK_ANALYTICS_TASK_DELAY_PERCENTAGE_OVERVIEW_BY_TASK_POOL:
        ele = (
          <OnTimeVsDueDateTask
            widgetData={renderWidgetState.widgetData}
            pageSection={pageSection}
          />
        );
        break;
      case RESOURCE_LIST.TASK_ANALYTICS_TASK_COMPLETED_BY_MONTH:
        ele = (
          <TaskCompletedLineChart
            widgetData={renderWidgetState.widgetData}
            dateRange={{
              to: taskAnalyticState?.dateRange?.to,
              from: taskAnalyticState?.dateRange?.from,
            }}
            pageSection={pageSection}
          />
        );
        break;
      case RESOURCE_LIST.TASK_ANALYTICS_TASK_DISTRIBUTION_BY_ASSIGNEE:
      case RESOURCE_LIST.TASK_ANALYTICS_TASK_DISTRIBUTION_BY_TASK_POOL:
        ele = (
          <TaskDistributionWidget widgetData={renderWidgetState.widgetData} />
        );
        break;
      default:
        break;
    }
    return ele;
  };
  const getSectionData = async () => {
    setRenderWidgetState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const patientContextFilter = taskAnalyticState.patientContext?.toLowerCase();
    const body: any = {
      resourceCode: pageSection.resource.code,
      isAccountDashboard: false,
      appliedFilter: [
        {
          filterCode: 'DATE_RANGE',
          filterValue: {
            fromDate: taskAnalyticState.dateRange?.from,
            toDate: taskAnalyticState.dateRange?.to,
            timezone: getCurrentTimeZone(),
          },
        },
        {
          filterCode: 'IS_TASK_WITH_PATIENT_CONTEXT',
          filterValue: {
            value:
              patientContextFilter == 'yes'
                ? true
                : patientContextFilter == 'no'
                ? false
                : undefined,
          },
        },
        {
          filterCode: 'TASK_ASSIGNEE_TYPE_CODE',
          filterValue: {
            value: TASK_ASSIGNEE_TYPE_CODES.User,
          },
        },
        {
          filterCode: 'TASK_PRIORITY_IDS',
          filterValue: {
            value: taskAnalyticState?.selectedTaskPriorityList || [],
          },
        },
        {
          filterCode: 'TASK_POOL_IDS',
          filterValue: {
            value: taskAnalyticState?.taskPoolsIds || [],
          },
        },
        {
          filterCode: 'LABEL_IDS',
          filterValue: {
            value: taskAnalyticState?.taskLabelsIds || [],
          },
        },
        ...(isMsoEnabled ? [
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.LOCATION_IDS,
            filterValue: {
              value: taskAnalyticState?.locationIds?.length === 0 ? allowedLocationIds || [] : taskAnalyticState?.locationIds || [],
            },
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.LOCATION_GROUP_IDS,
            filterValue: {
              value: taskAnalyticState?.locationGroupIds?.length === 0 ? allowedLocationGroupIds || [] : taskAnalyticState?.locationGroupIds || [],
            },
          }
        ] : []),
        taskAssigneeIdFilter,
      ],
    };
    if (taskAnalyticState?.employerData?.id != ALL_MEMBERS) {
      body.employerId = taskAnalyticState?.employerData?.id
    }
    const resp = await getSectionDataByResourceCode(pageSection.resource.code, {...body} );
    const responseData = resp?.data?.result;
    if (
      responseData?.totalTaskCount ||
      responseData?.total ||
      responseData?.totalRows ||
      responseData?.rowsByPriority?.totalCompletedTasksCount
    ) {
      setRenderWidgetState((prev) => {
        return {
          ...prev,
          widgetData: resp.data?.result,
          loading: false,
        };
      });
    } else {
      setRenderWidgetState((prev) => {
        return {
          ...prev,
          widgetData: {},
          loading: false,
        };
      });
    }
  };
  useEffect(() => {
    getSectionData();
    return () => controller?.abort();
  }, [pageSection.id,
    taskAnalyticState?.employerData?.id,
    taskAnalyticState?.dateRange?.from,
    taskAnalyticState?.dateRange?.to,
    taskAnalyticState?.selectedTaskPriorityList?.length,
    taskAnalyticState?.patientContext,
    taskAnalyticState?.taskPoolsIds?.length,
    taskAnalyticState?.taskLabelsIds?.length,
    taskAnalyticState?.locationGroupIds?.length
  ]);
  return (
    <View height={'100%'}>
      {renderWidgetState.loading ? (
        <Skeleton.Text />
      ) : (
        <>
          <View>
            <View style={styles.headerRow}>
              <Text size={'mdSemibold'} color={'#3A485F'}>
                {pageSection.heading}
              </Text>
              <View style={styles.infoContainer}>
                <WidgetsInfoSvg
                  width={16}
                  height={16}
                  isToolTip={true}
                  resourceCode={pageSection.resource.code}
                  subTitle={pageSection.subHeading}
                  info={infoTitle}
                />
                <View style={styles.chevronContainer}>
                  {DRILL_DOWN_ENABLE_WIDGET.includes(
                    pageSection.resource.code
                  ) &&
                  Object.keys(renderWidgetState?.widgetData).length !== 0 ? (
                    <Pressable
                      onPress={() => {
                        setRenderWidgetState((prev) => {
                          return {
                            ...prev,
                            selectedCode: pageSection.resource.code,
                          };
                        });
                      }}
                    >
                      <Chevron />
                    </Pressable>
                  ) : (
                    <></>
                  )}
                </View>
              </View>
            </View>
            <View>
              <Text size={'xsMedium'} color={'#A7AFBE'}>
                {getDateStrFromFormat(taskAnalyticState.dateRange.from || '', 'MMM YYYY')} -{' '}
                {getDateStrFromFormat(taskAnalyticState.dateRange.to || '', 'MMM YYYY')}
              </Text>
            </View>
          </View>
          {Object.keys(renderWidgetState?.widgetData).length !== 0 ? (
            <>{getWidgetByResourceCode(pageSection)}</>
          ) : (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80%',
            }}>
              <NodataViewTypeOne viewCode={'TEXT_ICON'} />
            </div>
          )}
        </>
      )}
      {DRILL_DOWN_ENABLE_WIDGET.includes(renderWidgetState.selectedCode) ? (
        <TaskAnalyticsDrillDown
          taskAnalyticState={taskAnalyticState}
          pageSection={pageSection}
          onActionPerformed={(code) => {
            setRenderWidgetState((prev) => {
              return {
                ...prev,
                selectedCode: '',
              };
            });
          }}
          userData={userData}
        />
      ) : (
        <></>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  headerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoContainer: {
    marginLeft: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  chevronContainer: {
    marginLeft: 4,
  },
  noDataContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
  },
});

export default RenderWidgets;
