import {Column, ColumnConfig, Gauge, GaugeConfig} from '@ant-design/charts';
import {useLazyQuery} from '@apollo/client';
import {DatePicker, DatePickerProps, Tooltip} from 'antd';
import {RangePickerProps} from 'antd/lib/date-picker';
import moment from 'moment';
import {Button, Center, HStack, Skeleton, Text, VStack, View} from 'native-base';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions, ViewStyle} from 'react-native';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {DATE_FORMATS, EMPLOYER_REPORT_FILTER_CODES, MLOV_CATEGORY, SETTING_PERMISSION_ACTION} from '../../../../constants';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {EmployerDashboard} from '../../../../services';
import {getSectionDataByResourceCode} from '../../../../services/Analytics';
import {Colors} from '../../../../styles/Colors';
import {getMlovListByCategory} from '../../../../utils/mlovUtils';
import JourneyLineChart from './JourneyLineChart';
import {styles} from './style';
import {IPageLayout, IPageSection, ISectionDisplayConfig} from './types';
import {data} from './raw';
import AntIcon from 'react-native-vector-icons/AntDesign';
import JourneyGauge from './JourneyGauge';
import JourneyOverview from './JourneyOverview';
import JourneyColumn from './JourneyColumn';
import JourneyCountStatus from './JourneyCountStatus';
import JourneyByEntityType from './JourneyByEntityType';
import Feather from 'react-native-vector-icons/Feather';
import {getOnFocusButtonStyles} from '../../../../utils/commonUtils';
import {
  getCurrentMonthEnd,
  getCurrentTimeZone,
  getDateSixMonthAgo,
  getDateStrFromFormat,
  getDateYearAgo,
  getMomentObj,
  isCurrentDateInFutureComparedToOther,
} from '../../../../utils/DateUtils';
import {FILTER_TYPE_CODES} from '../../../common/CareDashboard/TaskAnalytics/constants';
import LocationGroupMultiSelect from '../../../common/LocationGroupMultiSelect/LocationGroupMultiSelect';
import {isAccountConfigEnabled} from '../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../constants/AccountConfigConst';
import {USER_ACCESS_PERMISSION} from '../../UserAccess/UserAccessPermission';

enum RESOURCE_LIST {
  CARE_JOURNEY_MONTHLY_OVERALL_ADHERENCE = 'CARE_JOURNEY_MONTHLY_OVERALL_ADHERENCE',
  CARE_JOURNEY_OVERALL_ADHERENCE = 'CARE_JOURNEY_OVERALL_ADHERENCE',
  CARE_JOURNEY_TOTAL_ASSIGNED_COUNT_MONTHLY = 'CARE_JOURNEY_TOTAL_ASSIGNED_COUNT_MONTHLY',
  CARE_JOURNEY_TOTAL_ASSIGNED_COUNT_BY_STATUS = 'CARE_JOURNEY_TOTAL_ASSIGNED_COUNT_BY_STATUS',
  CARE_JOURNEY_DETAILS = 'CARE_JOURNEY_DETAILS',
  CARE_JOURNEY_OVERALL_ADHERENCE_BY_ENTITY_TYPE = 'CARE_JOURNEY_OVERALL_ADHERENCE_BY_ENTITY_TYPE',
}

const JourneyAnalytics = (props: {
  dateRange?: Record<string, string>;
  setDateRange?: (range: Record<string, string>) => void;
}) => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [GetDefaultPage] = useLazyQuery(
    EmployerDashboard.GetDefaultPageForJourneyAnalytics,{
      variables:{
        code: 'CARE_JOURNEY_DEFAULT_DASHBOARD'
      }
    }
  );
  const {journeyId} = useParams();
  const context = useContext(CommonDataContext);
  const height = Dimensions.get('window').height * 0.85;
  const width = Dimensions.get('window').width * 0.9;
  const navigate = useNavigate();
  const [pages, setPages] = useState<IPageLayout[]>([]);
  const intl = useIntl();
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const [resourceData, setResourceData] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const careJourneyDashboardDateRange = location?.state?.careJourneyDashboardDateRange;
  const locationGroupIds = location?.state?.locationGroupIds || [];
  const locationIds = location?.state?.locationIds || [];
  const [filterState, setFilterState] = useState({
    locationGroupIds: locationGroupIds?.length ? locationGroupIds : [] as string[],
    locationIds: locationIds?.length ? locationIds : [] as string[],
    isDefaultLocation: true
  });
  
  const [dateRange, setDateRange] = useState<Record<string, string>>({
    from: careJourneyDashboardDateRange?.from || props?.dateRange?.['from'] || getDateSixMonthAgo(),
    to: careJourneyDashboardDateRange?.to || props?.dateRange?.['to'] || getCurrentMonthEnd(),
  });
  const [resourceCodeList, setResourceCodeList] = useState<string[]>([]);

  if (!journeyId) {
    navigate('/admin/commerce/journeys/journeys', {replace: true});
    return null;
  }

  useEffect(() => {
    fetchDateByResourceCode(resourceCodeList);
  }, [dateRange]);

  useEffect(() => {
    setLoading(true);
    GetDefaultPage({
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }).then((resp) => {
      if (resp?.data?.pages?.[0]) {
        setPages(resp?.data?.pages);
        const resourceCodeListRaw = fetchResourceCode(
          resp?.data?.pages?.[0]?.pageSections,
          []
        );

        setResourceCodeList(resourceCodeListRaw);
        if (isMsoEnabled && !filterState.locationGroupIds?.length) {
          return
        }
        fetchDateByResourceCode(resourceCodeListRaw);
      }
    });
  }, [filterState.locationGroupIds?.length]);
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);
  
  const sectionTypes = getMlovListByCategory(MLOV_CATEGORY.SectionType);

  const fetchResourceCode = (
    pageSections: IPageSection[],
    resourceObj: string[]
  ): string[] => {
    pageSections?.forEach((pageSection: IPageSection) => {
      const sectionType = sectionTypes.find(
        (type) => type.id == pageSection.type
      );

      if (sectionType?.code == 'HEADER' && pageSection?.childSections?.length) {
        fetchResourceCode(pageSection.childSections, resourceObj);
      } else {
        if (pageSection?.resource?.code) {
          resourceObj.push(pageSection?.resource?.code);
        }
      }
    });
    return resourceObj;
  };

  const fetchDateByResourceCode = async (resourceList: string[]) => {
    if (!resourceList.length) {
      return;
    }
    !loading && setLoading(true);
     if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    const resourceDataToReturn: Record<string, any> = {};
    for (let resourceId = 0; resourceId < resourceList.length; resourceId++) {
      const resp = await getSectionDataByResourceCode(
        resourceList[resourceId],
        {
          resourceCode: resourceList[resourceId],
          isAccountDashboard: false,
          appliedFilter: [
            {
                'filterCode': 'DATE_RANGE',
                'filterValue': {
                    'fromDate': dateRange.from,
                    'toDate': dateRange.to,
                    timezone: getCurrentTimeZone(),
                }
            },
            {
              filterCode: 'CARE_JOURNEY_ID',
              filterValue: {
                value: journeyId,
              },
            },
            ...(isMsoEnabled ? [{
              filterCode: EMPLOYER_REPORT_FILTER_CODES.LOCATION_GROUP_IDS,
              filterValue: {
                value: filterState.locationGroupIds,
              },
            },{
              filterCode: EMPLOYER_REPORT_FILTER_CODES.LOCATION_IDS,
              filterValue: {
                value: filterState.locationIds,
              },
            }] : []),
          ],
        },
        undefined,
        abortControllerRef.current.signal
      );
      resourceDataToReturn[resourceList[resourceId]] = resp.data;
      setResourceData({...resourceDataToReturn});
    }
    setLoading(false);
  };

  const computeStyles = (sectionDisplayConfig?: ISectionDisplayConfig) => {
    const styles = [];
    if (sectionDisplayConfig?.childSections) {
      styles.push({
        ...sectionDisplayConfig?.childSections,
      });
    }
    if (sectionDisplayConfig?.section) {
      styles.push({
        ...sectionDisplayConfig?.section,
      });
    }

    return styles;
  };
  const renderWidgets = (pageSections: IPageSection) => {
    if (
      loading &&
      pageSections?.resource?.code &&
      !resourceData[pageSections.resource.code]
    ) {
      return (
        <Center margin={4} flex={1}>
          <Skeleton.Text lines={5} />
        </Center>
      );
    }

    switch (pageSections?.resource?.code) {
      case RESOURCE_LIST.CARE_JOURNEY_MONTHLY_OVERALL_ADHERENCE:
        const monthlyOverallAdherence =
          resourceData[
            pageSections.resource.code
          ]?.result?.overallAdherenceByMonth
            .sort(
              (a: any, b: any) =>
                getMomentObj(a?.month).valueOf() -
                getMomentObj(b?.month).valueOf()
            )
            ?.map((item: any) => {
              return {
                type: 'overallAdherencePercentage',
                value: parseFloat(item?.overallAdherencePercentage?.toFixed(1)),
                month: getDateStrFromFormat(item?.month, 'MMM YY'),
              };
            }) || [];
        return (
          <View>
            <Text
              style={{
                marginBottom: 8,
                textAlign: 'center',
                color: Colors.FoldPixel.GRAY400
              }}
              size={'lgMedium'}
              color={Colors.FoldPixel.GRAY400}
            >
              Monthly Average Adherence
            </Text>
            <JourneyLineChart
              tooltipText="Average score"
              xLabel=""
              yLabel=""
              data={monthlyOverallAdherence}
              height={pageSections?.sectionDisplayConfig?.widgetStyle?.height}
            />
          </View>
        );
      case RESOURCE_LIST.CARE_JOURNEY_OVERALL_ADHERENCE:
        const overallAdherence =
          resourceData[
            pageSections.resource.code
          ]?.result?.overallAdherencePercentage?.toFixed(2) || 0;
        return (
          <View>
            <HStack alignItems={'center'} mb={4} justifyContent={'center'}>
              <Text
                mr={2}
                style={{
                  textAlign: 'center',
                  color: Colors.FoldPixel.GRAY400
                }}
                size={'smBold'}
                fontSize={18}
              >
                Overall Adherence
              </Text>
              <Tooltip title="This score measures how well all patients are following their Care Journeys. It calculates the percentage of completed tasks compared to the total tasks assigned so far.">
                <Feather name="info" size={16} />
              </Tooltip>
            </HStack>
            <View style={{padding: 12}}>
              <JourneyGauge value={parseFloat(overallAdherence)} />
            </View>
          </View>
        );
      case RESOURCE_LIST.CARE_JOURNEY_OVERALL_ADHERENCE_BY_ENTITY_TYPE:
        return (
          <JourneyByEntityType
            data={resourceData[pageSections.resource.code]?.result}
          />
        );
      case RESOURCE_LIST.CARE_JOURNEY_DETAILS:
        return <JourneyOverview carejourneyId={journeyId} />;
      case RESOURCE_LIST.CARE_JOURNEY_TOTAL_ASSIGNED_COUNT_BY_STATUS:
        return (
          <JourneyCountStatus
            journeyId={journeyId}
            data={resourceData[pageSections.resource.code]?.result}
            careJourneyDashboardDateRange={dateRange}
            // When isDefaultLocation is true, we pass empty arrays to handle the default state
            // Otherwise, we pass the selected location IDs from filterState
            locationGroupIds={!filterState.isDefaultLocation ? filterState.locationGroupIds : []} 
            locationIds={!filterState.isDefaultLocation ? filterState.locationIds : []}
          />
        );
      case RESOURCE_LIST.CARE_JOURNEY_TOTAL_ASSIGNED_COUNT_MONTHLY:
        const sortedData = resourceData?.[
          pageSections.resource.code
        ]?.result?.patientCareJourneyCountResult?.sort(
          (a: any, b: any) =>
            getMomentObj(a?.month).valueOf() - getMomentObj(b?.month).valueOf()
        );
        return (
          <View flexDirection={'column'}>
            <HStack alignItems={'center'} mb={6}>
              <Text
                mr={2}
                style={{
                  textAlign: 'center',
                  color: Colors.FoldPixel.GRAY400
                }}
                size={'smBold'}
                fontSize={18}
              >
                Monthly Assigned Patients
              </Text>
              <Tooltip title="This chart reflects the average task adherence of patients to this Care Journey on a monthly basis.">
                <Feather name="info" size={16} />
              </Tooltip>
            </HStack>
            <JourneyColumn data={sortedData} />
          </View>
        );
    }
  };

  const renderHeader = (
    heading?: string,
    subHeading?: string,
    shouldShowDateFilter?: boolean,
    shouldShowBackArrow?: boolean,
    showEdit?: boolean
  ) => {
    if (heading || subHeading) {
      return (
        <View style={styles.headerContainer}>
          <VStack>
            <View style={styles.headerRightBox}>
              {shouldShowBackArrow && (
                <Feather
                  onPress={() => {
                    navigate('/admin/commerce/journeys/journeys');
                  }}
                  style={{
                    marginRight: 10,
                  }}
                  size={25}
                  name="arrow-left"
                  color={Colors.Custom.Gray500}
                />
              )}
              <View>
                {heading ? (
                  <Text
                    style={{
                      marginRight: 6,
                    }}
                    size={'lgMedium'}
                    color={Colors.FoldPixel.GRAY400}
                  >
                    {heading}
                  </Text>
                ) : null}
                {subHeading ? (
                  <Text
                    style={{
                      marginRight: 6,
                    }}
                    size={'smBold'}
                    fontSize={18}
                  >
                    {subHeading}
                  </Text>
                ) : null}
              </View>
            </View>
            <Text
              style={{
                color: Colors.Custom.Gray400,
                padding: 4,
                paddingTop: 0,
                fontSize: 12,
                marginLeft: 20,
              }}
            >
              • {intl.formatMessage({id: 'analyticsSyncMessage'})}
            </Text>
          </VStack>

          <HStack alignItems={'center'}>
            {isMsoEnabled && (
              <LocationGroupMultiSelect
                permissionAttribute={{
                  resourceCode: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
                  action: SETTING_PERMISSION_ACTION.CARE_JOURNEY
                }}
                locationGroupIds={filterState.locationGroupIds}
                style={{marginRight: 0, marginTop:0}}
                onLocationGroupChange={(locationGroupIds: string[], locationIds: string[], isDefaultLocation: boolean) => {
                  setFilterState(prev => ({...prev, locationGroupIds, locationIds, isDefaultLocation}))
                }}
              />
            )}
            {shouldShowDateFilter ? (
              <View style={styles.datePickerContainer}>
                <DatePicker
                  clearIcon={false}
                  style={{
                    borderWidth: 0,
                  }}
                  suffixIcon={null}
                  format={'yyyy-MMM-DD'}
                  onChange={(
                    value: DatePickerProps['value'] | RangePickerProps['value'],
                    dateString: string
                  ) => {
                    props?.setDateRange?.({
                      from: getDateStrFromFormat(
                        value?.toString(),
                        DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                      ),
                      to: dateRange.to,
                    });

                    setDateRange({
                      from: getDateStrFromFormat(
                        value?.toString(),
                        DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                      ),
                      to: dateRange.to,
                    });
                  }}
                  value={moment(dateRange.from || '')}
                />
                <DatePicker
                  clearIcon={false}
                  style={{
                    borderWidth: 0,
                  }}
                  format={'YYYY-MMM-DD'}
                  onChange={(
                    value: DatePickerProps['value'] | RangePickerProps['value'],
                    dateString: string
                  ) => {
                    props?.setDateRange?.({
                      to: getDateStrFromFormat(
                        value?.toString(),
                        DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                      ),
                      from: dateRange.from,
                    });
                    setDateRange({
                      to: getDateStrFromFormat(
                        value?.toString(),
                        DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                      ),
                      from: dateRange.from,
                    });
                  }}
                  value={moment(dateRange.to || '')}
                />
              </View>
            ) : (
              <View />
            )}
            {showEdit ? (
              <Tooltip title='Edit'>
                <Button
                onPress={() => {
                  navigate(
                    `/admin/commerce/journeys/product/-1/journey/${journeyId}`
                  );
                }}
                leftIcon={
                  <Feather name="edit" size={22} color={Colors.Custom.Gray500} />
                }
                style={{
                  backgroundColor: 'transparent',
                }}
                _focus={{
                  ...getOnFocusButtonStyles(),
                }}
              />
              </Tooltip>
            ) : null}
          </HStack>
        </View>
      );
    }
  };

  const renderSections = (pageSections: IPageSection[]) => {
    return (
      <>
        {pageSections?.map((pageSection: IPageSection, index: number) => {
          if (!pageSection?.isVisible) {
            return null;
          }
          const sectionType = sectionTypes.find(
            (type) => type.id == pageSection.type
          );

          if (sectionType?.code == 'HEADER') {
            return (
              <>
                {renderHeader(pageSection?.heading, pageSection?.subHeading)}
                <View
                  style={
                    computeStyles(pageSection.sectionDisplayConfig) as ViewStyle
                  }
                >
                  {pageSection.childSections
                    ? renderSections(pageSection.childSections)
                    : null}
                </View>
              </>
            );
          } else {
            return (
              <View
                overflowX={'scroll'}
                padding={6}
                borderWidth={1}
                borderRadius={4}
                borderColor={Colors.Custom.Gray200}
                backgroundColor={Colors.Custom.BackgroundColor}
                margin={3}
                style={
                  computeStyles(pageSection.sectionDisplayConfig) as ViewStyle
                }
              >
                {renderWidgets(pageSection)}
              </View>
            );
          }
        })}
      </>
    );
  };
  return (
    <>
      {pages?.length ? (
        <>
          {renderHeader(pages?.[0]?.name, '', true, true, true)}{' '}
          <View
            style={{
              height: height,
              overflow: 'scroll',
              backgroundColor: Colors.Custom.ContainerBGColor,
            }}
          >
            {renderSections(pages?.[0]?.pageSections as IPageSection[])}
          </View>
        </>
      ) : (
        <Skeleton.Text lines={15} />
      )}
    </>
  );
};

export default React.memo(JourneyAnalytics);
