import {gql} from '@apollo/client';
import BaseService from '../CommonService/BaseService';
import {contractBaseUrl} from './contractServiceConst';

const axios = BaseService.getSharedInstance().axios;

export const getQualityMeasures = (body: {
  searchString: string;
  pageSize: number;
  pageNo: number;
  contractId: string;
}) => {
  const {searchString, pageNo, pageSize, contractId} = body;
  const url = `${contractBaseUrl}/${contractId}/QualityMeasures?page_size=${pageSize}&page_number=${pageNo}&name=${searchString}`;
  return axios.get(url);
};

export const getTopPrograms = (body: {
  searchString: string;
  contractId: string;
}) => {
  const {searchString, contractId} = body;
  const url = `${contractBaseUrl}/${contractId}/Programs`;
  return axios.get(url);
};

export const getQualityMeasuresPatients = (body: {
  searchString: string;
  pageSize: number;
  pageNo: number;
  contractId: string;
  qualityMeasureId: string;
}) => {
  const {searchString, pageNo, pageSize, contractId, qualityMeasureId} = body;
  const url = `${contractBaseUrl}/${contractId}/QualityMeasures/${qualityMeasureId}/patient?page_size=${pageSize}&page_number=${pageNo}&name=${searchString}`;
  return axios.get(url);
};

export const QualityMeasuresQuery = gql`
  query QualityMeasuresQuery(
    $contractId: uuid!
    $name: String!
    $limit: Int!
    $offset: Int!
    $order_by: [qmContractQualityMeasure_order_by!]
  ) {
    qmContractQualityMeasures(
      where: {
        contractId: {_eq: $contractId}
        qualityMeasure: {
          name: {_ilike: $name},
          isDeleted: {_eq: false}
        }
      },
      order_by: $order_by ,
      limit: $limit,
      offset: $offset
    ) {
      id
      goal
      qualityMeasure {
        name
        id
        improvementNotation
        fhirResourceId
        qmMeasureGroup(order_by: {name: asc}) {
          groupId
          name
        }
        hasSinglePerformance
        qmMeasureStratification(order_by: {name: asc}) {
          stratificationId
          name
          description
        }
      }
      qualityMeasureId
      contractId
      contract {
        name
      }
    }
    qggregateQmContractQualityMeasures(
      where: {contractId: {_eq: $contractId}, qualityMeasure: {name: {_ilike: $name}, isDeleted: {_eq: false}}}
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getContactByUuids = gql`
  query getContactByUuids($uuids: [uuid!]) {
    contacts(where: {uuid: {_in: $uuids}}) {
      uuid
      name
      id
      email
      phoneNumber
      isActive
      additionalAttributes
      person {
        firstName
        lastName
        chosenName
        pronounId
        gender {
          value
          code
          id
        }
        sexAtBirth {
          value
          code
          id
        }
        birthDate
        bloodGroup {
          value
          code
          id
        }
        birthSexId
      }
      contactType {
        contactType {
          value
          code
          id
        }
      }
      patient {
        patientUuid
        patientId
      }
      contactConsents {
        id
        consentId
      }
      relationshipWith {
        isDeleted
        contactId
      }
      contactDeactivationInfo {
        isActive
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        accountLocation {
          uuid
        }
        practiceLocation {
          name
          practiceCity {
            name
            id
          }
        }
      }
      contactTagging {
        labels {
          color
          id
          title
          description
        }
      }
    }
  }
`
export const qualityMeasureReportAggregate = gql`
  query qualityMeasureReportAggregate(
    $where: qmMeasureReport_bool_exp
  ) {
    aggregateQmMeasureReport(
      where: $where
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const qualityMeasureReport = gql`
  query qualityMeasureReport(
    $limit: Int = 10
    $offset: Int = 0
    $where: qmMeasureReport_bool_exp
    $populationCriteria: String
  ) {
    qmMeasureReports(
      where: $where
      limit: $limit
      offset: $offset
      order_by: {updatedOn: desc}
    ) {
      id
      contactId
      contractId
      initialPopulationFlag
      denominatorExceptionFlag
      denominatorExclusionFlag
      denominatorFlag
      measureId
      measureScore
      isLatest
      disqualificationReason
      numeratorExclusionFlag
      numeratorFlag
      numeratorInclusionEvidenceDate
      numeratorInclusionEvidenceValue
      qualificationReason
      runTimestamp
      tenantId
      qmQualityMeasure {
        id
        name
        improvementNotation
        fhirResourceId
      }
      qmContract {
        id
        name
        contractPayer {
          id
          name
        }
        payerId
      }
      qmMeasureEvidence (
        where: { 
          populationCriteria: { 
            _eq: $populationCriteria
          }
          isLeafNode: {
            _eq: true
          }
        }) {
        id
        expressionName
        expectedResult
        actualResult
        populationCriteria
        qualifyingResources
        isLeafNode
      }
    }
    aggregateQmMeasureReport(
      where: $where
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const qualityMeasureReportById = gql`
  query qualityMeasureReport(
    $filterObjectTotal: qmMeasureReport_bool_exp
    $filterObjectMeasureNotMeet: qmMeasureReport_bool_exp
  ) {
    qmMeasureReports(
      where: $filterObjectTotal
      order_by: {updatedOn: desc}
    ) {
      id
      contactId
      contractId
      initialPopulationFlag
      denominatorExceptionFlag
      denominatorExclusionFlag
      denominatorFlag
      measureId
      measureScore
      isLatest
      disqualificationReason
      numeratorExclusionFlag
      numeratorFlag
      numeratorInclusionEvidenceDate
      numeratorInclusionEvidenceValue
      qualificationReason
      runTimestamp
      tenantId
      qmQualityMeasure {
        id
        name
        improvementNotation
        fhirResourceId
        qmMeasureGroup(order_by: {name: asc}) {
          groupId
        }
        hasSinglePerformance
        qmMeasureStratification(order_by: {name: asc}) {
          stratificationId
          name
          description
        }
      }
      qmContract {
        id
        name
        contractPayer {
          id
          name
        }
        payerId
      }
      groupId
    }

    aggregateQmMeasureReportTotal: aggregateQmMeasureReport(
      where: $filterObjectTotal
    ) {
      aggregate {
        count
      }
    }

    aggregateQmMeasureReportMeasureNotMeet: aggregateQmMeasureReport(
      where: $filterObjectMeasureNotMeet
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const qualityMeasureReportCount = gql`
  query qualityMeasureReportCount(
    $where: qmMeasureReport_bool_exp
  ) {
    aggregateQmMeasureReport(
      where: $where
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getQualityMeasuresIdByName = gql`
  query QualityMeasuresIdByName($name: String!) {
    qmQualityMeasures(where: {name: {_eq: $name}, isDeleted: {_eq: false}}) {
      id
      improvementNotation
    }
  }
`;

export const getMesaureReportCriteraReasons = gql`
  query getMesaureReportCriteraReasons(
    $measureId: uuid!
    $contactId: uuid!
    $denominatorFlag: Boolean
    $numeratorFlag: Boolean
  ) {
    qmMeasureReports(
      where: {
        measureId: {_eq: $measureId}
        isLatest: {_eq: true}
        contactId: {_eq: $contactId}
        denominatorFlag: {_eq: $denominatorFlag}
        numeratorFlag: {_eq: $numeratorFlag}
      }
    ) {
      disqualificationReason
      qualificationReason
      evidence
      id
    }
  }
`;

export const getEvidenceList = gql`
  query qualityMeasureReport(
    $evaluationId: uuid!
    $populationCriteria: String!
  ) {
    qmMeasureEvidences(
      where: {
        evaluationId: {_eq: $evaluationId}
        populationCriteria: {_eq: $populationCriteria}
        isLeafNode: {_eq: true}
      }
    ) {
      id
      expressionName
      expectedResult
      actualResult
      populationCriteria
      qualifyingResources
      isLeafNode
    }
  }
`;

export const qualityMeasureReportRunTimestamp = gql`
  query qualityMeasureReport($filterObjectTotal: qmMeasureReport_bool_exp) {
  qmMeasureReports(where: $filterObjectTotal, order_by: {updatedOn: desc}, limit:1) {
    id
    runTimestamp
    tenantId
  }
}
`;

export default {QualityMeasuresQuery,qualityMeasureReport,getContactByUuids,getQualityMeasuresIdByName,getMesaureReportCriteraReasons,qualityMeasureReportById, getEvidenceList, qualityMeasureReportRunTimestamp, qualityMeasureReportAggregate};
