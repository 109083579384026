import {Drawer, notification} from 'antd';
import {
  FormControl,
  HStack,
  Input,
  Pressable,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack,
  Icon,
} from 'native-base';
import { useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {
  IAccountPracticeLocationList,
  ICreatePackageInterface,
  IPackageFormData,
  IPackageFormDataErrors,
  IProductBodyData,
  IProductPackageTag,
} from './interfaces';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  INVALID_DATE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {styles} from './styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionInput} from '../../../../common/ModalActionCommonComponent/ModalActionInput';
import DollorIcon from '../../../../common/Svg/DollorIocn';
import {MediaPreview} from '../../../ContentManagement/MediaLibrary/MediaPreview';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {
  MEDIA_VIEW_CODES,
  mediaFilter,
} from '../../../ContentManagement/MediaLibrary/MediaLibraryUtils';
import {IMediaLibraryData} from '../../../ContentManagement/MediaLibrary/interfaces';
import ImagePlaceholderSvgSvg from '../../../../common/Svg/ImagePlaceholderSvg';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {SingleCareJourney} from '../../../../common/CarePlanSearch/interfaces';
import {
  filterLocationObject,
  formatFileSize,
  formattingCreateVariables,
  formattingUpdateVariables,
  getAllJourneyLocationName,
  validatePackageForm,
} from './PackagesUtils';
import {ModalActionDatePicker} from '../../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import {
  getDateObjectFromAPIFormatToMyFormat,
  getDateStrFromFormat,
  getMomentObj,
} from '../../../../../utils/DateUtils';
import moment from 'moment';
import {MediaSelectionModal} from '../../../ContentManagement/PatientEducation/MediaSelectionModal';
import {
  createStripePackageApi,
  updateStripePackageApi,
} from './PackageServices';
import {TABLE_TOP_BAR_ACTION_CODES} from '../../../../common/TableTopBar';
import {useLazyQuery} from '@apollo/client';
import UserPracticeLocationQueries from '../../../../../services/Location/UserPracticeLocationQueries';
import { getAccountUUID } from '../../../../../utils/commonUtils';
import Feather from 'react-native-vector-icons/Feather';
import CareJourneySearch from '../../../../common/CareJourneySearch/CareJourneySearch';
import { GET_ACTIVE_CARE_JOURNEY_BY_ID } from '../../../../../services/CareJourney/CareJourneyQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { ICareJourneyStatusIDs } from '../Products/ProductTableView/ProductTable/interfaces';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { CARE_PROGRAM_STATUS_CODES, MLOV_CATEGORY } from '../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import {JourneyPackagesAddTagsView} from './JourneyPackagesAddTagsView';
import {ITagsTableData} from '../../../Contacts';

const AddOrUpdatePackages = (props: ICreatePackageInterface) => {
  const toast = useToast();
  const intl = useIntl();
  const accountUUID = getAccountUUID();
  const mlovData = useContext(CommonDataContext);
  const careJourneyStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_STATUS
    ) || [];

  const careJourneyStatusIds: ICareJourneyStatusIDs = {
    draft: getMlovIdFromCode(
      careJourneyStatusMlov,
      CARE_PROGRAM_STATUS_CODES.DRAFT
    ),
    published: getMlovIdFromCode(
      careJourneyStatusMlov,
      CARE_PROGRAM_STATUS_CODES.ACTIVE
    ),
  };

  const [stateData, setStateData] = useState<IPackageFormData>({
    showModal: props.visibleDrawer,
    loading: false,
    onDraftLoading: false,
    onPublishedLoading: false,
    packageName: '',
    description: '',
    price: '',
    selectedMedia: {} as IMediaLibraryData,
    selectedJourney: {} as SingleCareJourney,
    practiceLocations: [] as IAccountPracticeLocationList[],
    expiryDate: '',
    viewCode: '',
    errors: {} as IPackageFormDataErrors,
    selectedLabels: [],
    productPackageTag: [],
  });

  const [accountPracticeLocationsQuery] = useLazyQuery<any>(
    UserPracticeLocationQueries.GetAccountPracticeLocationsByTenantId,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [careJourneyById] = useLazyQuery(
    GET_ACTIVE_CARE_JOURNEY_BY_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const getAccountLocations = async () => {
    const accountLocationsRes = await accountPracticeLocationsQuery({
      variables: {
        tenantId: accountUUID
      }
    });
    if (accountLocationsRes?.data?.accountLocations?.length) {
      setStateData((prev) => {
        return {
          ...prev,
          practiceLocations: filterLocationObject(accountLocationsRes.data?.accountLocations || []),
        };
      });
    }
  };

  useEffect(() => {
    getAccountLocations();
  }, []);

  useEffect(() => {
    if (!props.selectedPackageData?.id) {
      return
    }
    if (props.selectedPackageData?.productPackage?.journeyId) {
      getSelectedJourneyById(props.selectedPackageData?.productPackage?.journeyId);
    }
    setSelectedPackageData();
  }, [props.selectedPackageData?.id]);

  const getSelectedJourneyById = async (id: string) => {
    const careJourneyRes = await careJourneyById({
      variables: {
        statusId: careJourneyStatusIds.published,
        id: id,
      },
    });

    if (careJourneyRes?.data?.careJourneys?.length) {
      setStateData((prev) => {
        return {
          ...prev,
          selectedJourney: careJourneyRes?.data?.careJourneys?.[0],
        };
      });
    }
  };

  const setSelectedPackageData = () => {
    setStateData((prev: any) => {
      return {
        ...prev,
        packageName: props.selectedPackageData?.name,
        description: props.selectedPackageData?.description,
        price:
          (props.selectedPackageData?.stripeProductData?.prices?.[0]
            ?.unit_amount / 100) + '' || '',
        selectedMedia: props.selectedPackageData?.media,
        expiryDate: props.selectedPackageData?.expiryDate || '',
        productPackageTag: props.selectedPackageData?.productPackageTag,
      };
    });
  };

  const onCancel = () => {
    setStateData((prev: any) => {
      return {
        ...prev,
        showModal: false,
      };
    });
    props?.onFormCompleteAction(COMMON_ACTION_CODES.CANCEL);
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  const {width, height} = Dimensions.get('window');
  const finalWidth = isSmallScreen ? width / 2 : width / 2 - 50;

  const onSubmit = async (isPublished: boolean) => {
    const {isValid, errors} = validatePackageForm(stateData);
    setStateData((prev) => {
      return {
        ...prev,
        errors: errors,
      };
    });
    if (isValid) {
      setStateData((prev) => {
        return {
          ...prev,
          onDraftLoading: !isPublished,
          onPublishedLoading: isPublished,
        };
      });

      let packageVariables = {} as IProductBodyData;
      let createPackageRes;
      if (props.selectedPackageData?.id) {
        packageVariables = formattingUpdateVariables(
          stateData,
          isPublished,
          props?.selectedPackageData
        );
        createPackageRes = await updateStripePackageApi(packageVariables).catch(
          (err) => {
            notification.error({
              message: intl.formatMessage({id: 'apiErrorMsg'}),
            });
            setStateData((prev) => {
              return {
                ...prev,
                onSavingLoading: false,
              };
            });
          }
        );
      } else {
        packageVariables = formattingCreateVariables(stateData, isPublished);
        createPackageRes = await createStripePackageApi(packageVariables).catch(
          (err) => {
            notification.error({
              message: intl.formatMessage({id: 'apiErrorMsg'}),
            });
            setStateData((prev) => {
              return {
                ...prev,
                onSavingLoading: false,
              };
            });
          }
        );
      }
      if (createPackageRes?.data) {
        showToast(
          toast,
          props.selectedPackageData?.id ? 'Package updated successfully' : 'Package created successfully',
          ToastType.success
        );
        props.onFormCompleteAction(
          TABLE_TOP_BAR_ACTION_CODES.CLOSE,
          createPackageRes?.data
        );
        setStateData((prev) => {
          return {
            ...prev,
            onSavingLoading: false,
          };
        });
      }
    }
  };


  return (
    <Drawer
      visible={stateData.showModal}
      width={finalWidth}
      closable={false}
      title={
        <HStack
         alignItems={'center'}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ModalActionTitle
            title={
              props.selectedPackageData?.id ? 'updatePackage' : 'createPackage'
            }
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 2,
                btnText: 'draft',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                isLoading: stateData.onDraftLoading,
                onClick: () => {
                  if (stateData?.onPublishedLoading || stateData?.onDraftLoading) {
                    return;
                  }
                  onSubmit(false);
                },
              },
              {
                show: true,
                id: 3,
                btnText: 'publish',
                isLoading: stateData.onPublishedLoading,
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  if (stateData?.onPublishedLoading || stateData?.onDraftLoading) {
                    return;
                  }
                  onSubmit(true);
                },
              },
            ]}
          />
          <HStack mt={-2} space={2} justifyContent={'center'} alignItems={'center'}>
            <View height={'28px'} width={.5} backgroundColor={Colors.Custom.Gray200} />
            <Feather
              name="x"
              style={{fontSize: 24}}
              color={Colors.Custom.Gray500}
              onPress={() => {
                onCancel();
              }}
            />
          </HStack>
        </HStack>
      }
    >
      {stateData.loading ? (
        <Spinner size="lg" style={styles.spinnerStyle} />
      ) : (
        <></>
      )}
      <VStack flex={1}>
        <ModalActionInput
          fieldIs={'input'}
          label={'packageName'}
          placeholder={'Enter package name'}
          isRequired={true}
          changeBackground={true}
          isInvalid={stateData.errors.packageName}
          value={stateData.packageName}
          errors={stateData.errors.packageName}
          placeholderTextColor={Colors.Custom.Gray300}
          errorText={stateData.errors.packageName}
          onChangeText={(value: any) => {
            setStateData((prev: any) => {
              return {
                ...prev,
                packageName: value,
              };
            });
          }}
          extraStyle={{
            flex: 1.0,
          }}
        />
        <ModalActionInput
          marginTop={4}
          fieldIs={'textArea'}
          label={'description'}
          placeholder={'Enter Text Here'}
          isRequired={true}
          borderColor={Colors.Custom.Gray200}
          isInvalid={stateData.errors.description}
          value={stateData.description}
          errors={stateData.errors.description}
          placeholderTextColor={Colors.Custom.Gray300}
          errorText={stateData.errors.description}
          onChangeText={(value: any) => {
            setStateData((prev: any) => {
              return {
                ...prev,
                description: value,
              };
            });
          }}
          extraStyle={{
            flex: 1.0,
            Height: 90,
          }}
        />

        <FormControl marginTop={4} style={styles.formElement}>
          <FormControl.Label style={styles.formLabel}>
            <DisplayText
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray500, fontWeight: 400}}
              textLocalId={'packagePrice'}
            />
          </FormControl.Label>
          <View
            flex={1}
            flexDirection={'row'}
            alignItems={'center'}
            width={'100%'}
            borderColor={Colors.Custom.Gray200}
            borderRadius={8}
            borderWidth={1}
          >
            <Input
              flex={1}
              isDisabled={props.selectedPackageData?.id ? true : false}
              keyboardType="numeric"
              borderWidth={0}
              placeholderTextColor={Colors.Custom.Gray300}
              color={'black'}
              bgColor={'white'}
              borderRadius={8}
              height={44}
              placeholder={'Enter package price'}
              fontWeight={400}
              fontSize={14}
              value={stateData.price}
              onChangeText={(value) => {
                const numericValue = value.replace(/[^0-9.]/g, '');
                const decimalCount = numericValue.split('.').length - 1;
                if (decimalCount <= 1) {
                  setStateData((prev: any) => {
                    return {
                      ...prev,
                      price: numericValue + '',
                    };
                  });
                }
              }}
              InputLeftElement={
                <View marginLeft={1}>
                  <DollorIcon />
                </View>
              }
            />
          </View>
        </FormControl>

        <FormControl
          marginTop={4}
          style={styles.formElement}
          isInvalid={stateData.errors?.thumbnail?.length ? true : false}
          isRequired
        >
          <FormControl.Label style={styles.formLabel}>
            <DisplayText
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray500, fontWeight: 400}}
              textLocalId={'setThumbnail'}
            />
          </FormControl.Label>
          <VStack>
            <View>
              {!stateData.selectedMedia?.url ? (
                <VStack
                  style={{
                    padding: 24,
                    borderRadius: 8,
                    borderColor: Colors.Custom.Gray200,
                    borderWidth: 2,
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderStyle: 'dotted',
                    flex: 1,
                    width: width * 0.44,
                  }}
                >
                  <Pressable
                    onPress={() => {
                      setStateData((prev: any) => {
                        return {
                          ...prev,
                          viewCode: MEDIA_VIEW_CODES.MEDIA_ADD_VIEW,
                        };
                      });
                    }}
                  >
                    <VStack justifyContent={'center'} alignItems={'center'}>
                      <FeatherIcon
                        color={Colors.Custom.Gray400}
                        name="upload-cloud"
                        size={20}
                      />

                      <Text style={{color: Colors.Custom.mainPrimaryPurple}}>
                        {' Choose File'}
                      </Text>
                    </VStack>
                  </Pressable>
                </VStack>
              ) : (
                <View
                  borderColor={Colors.Custom.Gray200}
                  borderWidth={1}
                  borderRadius={8}
                  backgroundColor={Colors.Custom.Gray50}
                  padding={2}
                >
                  <HStack>
                    <ImagePlaceholderSvgSvg />
                    <VStack ml={2} flex={1}>
                      <Text fontSize={16} fontWeight={500} color={'black'}>
                        {stateData.selectedMedia.name}
                      </Text>
                      <Text
                        fontSize={16}
                        fontWeight={500}
                        color={Colors.Custom.Gray400}
                      >
                        {formatFileSize(stateData.selectedMedia.size)}
                      </Text>
                    </VStack>
                    <HStack
                      space={2}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Pressable
                        onPress={() => {
                          setStateData((prev: any) => {
                            return {
                              ...prev,
                              viewCode: MEDIA_VIEW_CODES.MEDIA_ADD_VIEW,
                            };
                          });
                        }}
                      >
                        <Text
                          fontSize={14}
                          fontWeight={600}
                          color={Colors.Custom.PrimaryColor}
                        >
                          {'Change file'}
                        </Text>
                      </Pressable>
                      <Pressable
                        onPress={() => {
                          setStateData((prev: any) => {
                            return {
                              ...prev,
                              selectedMedia: {},
                            };
                          });
                        }}
                      >
                        <Icon
                          color={Colors.Custom.Gray500}
                          as={AntIcon}
                          name={'close'}
                          size="4"
                        />
                      </Pressable>
                    </HStack>
                  </HStack>
                </View>
              )}

              <View style={[styles.previewContainerView, {marginTop: 5}]}>
                {stateData.selectedMedia?.type &&
                  stateData.selectedMedia?.url && (
                    <MediaPreview
                      mediaType={stateData.selectedMedia?.type}
                      mediaUrl={stateData.selectedMedia?.url}
                    />
                  )}
              </View>
            </View>
          </VStack>
          {stateData.errors?.thumbnail && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {stateData.errors?.thumbnail}
            </FormControl.ErrorMessage>
          )}
        </FormControl>

        <FormControl
          marginTop={4}
          style={styles.formElement}
          isInvalid={stateData.errors?.careJourney?.length ? true : false}
          isRequired
        >
          <FormControl.Label style={styles.formLabel}>
            <DisplayText
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray500, fontWeight: 400}}
              textLocalId={'careJourney'}
            />
          </FormControl.Label>
          <CareJourneySearch
            isShowError={stateData.errors?.careJourney?.length ? true : false}
            value={stateData.selectedJourney}
            isDisabled={
              props?.selectedPackageData?.productPurchaseDetails?.length
                ? true
                : false
            }
            onChange={(result: any) => {
              setStateData((prev: any) => {
                return {
                  ...prev,
                  selectedJourney: result,
                };
              });
            }}
          />
          {stateData.errors?.careJourney && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {stateData.errors?.careJourney}
            </FormControl.ErrorMessage>
          )}
          <HStack mt={1} ml={1}>
            {stateData.selectedJourney?.startDateTime?.length ? (
              <Text fontWeight={400} fontSize={12} color={'black'}>
                {`Start date : `}
                <Text
                  fontWeight={400}
                  fontSize={12}
                  color={Colors.Custom.Gray500}
                >
                  {`${
                    getDateObjectFromAPIFormatToMyFormat(
                      stateData.selectedJourney?.startDateTime,
                      DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
                    ) + ', '
                  }`}
                </Text>
              </Text>
            ) : (
              <></>
            )}
            {stateData.selectedJourney?.careJourneyLocations?.length ? (
              <Text fontWeight={400} fontSize={12} color={'black'}>
                {` Location: `}
                <Text
                  fontWeight={400}
                  fontSize={12}
                  color={Colors.Custom.Gray500}
                >
                  {`${getAllJourneyLocationName(
                    stateData?.practiceLocations,
                    stateData.selectedJourney?.careJourneyLocations || []
                  )?.toString()}`}
                </Text>
              </Text>
            ) : (
              <></>
            )}
          </HStack>
        </FormControl>

        <FormControl
          marginTop={4}
          style={styles.formElement}
        >
          <FormControl.Label style={styles.formLabel}>
            <DisplayText
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray500, fontWeight: 400}}
              textLocalId={'tags'}
            />
          </FormControl.Label>
          <JourneyPackagesAddTagsView
            onDetailChange={(selectedLabels: ITagsTableData[])=> {
              setStateData((prev) => {
                return {
                  ...prev,
                  selectedLabels: selectedLabels,
                }
              })
            }}
            productPackageTag={stateData?.productPackageTag}
          />
        </FormControl>

        <FormControl
          marginTop={4}
          style={styles.formElement}
          isInvalid={stateData.errors?.expiryDate?.length ? true : false}
          isRequired
        >
          <HStack flex={1}>
            <ModalActionDatePicker
              disabledDate={(current: any) => current.isBefore(moment())}
              isRequired={true}
              label={'expiryDate'}
              isInvalid={stateData.errors?.expiryDate?.length}
              errors={stateData.errors?.expiryDate?.length ? true : false}
              errorsText={stateData.errors?.expiryDate}
              format={DATE_FORMATS.CALENDAR_LIB_FORMAT}
              onChange={(data: any, dateString) => {
                dateString = getDateStrFromFormat(data, DATE_FORMATS.DISPLAY_DATE_FORMAT);
                setStateData((prev) => ({
                  ...prev,
                  expiryDate: dateString?.length ? dateString !== INVALID_DATE ? new Date(dateString).toISOString() : '' : '',
                }));
              }}
              value={
                stateData.expiryDate?.length
                  ? getMomentObj(stateData.expiryDate)?.isValid()
                    ? moment(stateData.expiryDate)
                    : undefined
                  : undefined
              }
              extraStyle={{flex: 1}}
            />
          </HStack>
        </FormControl>
      </VStack>

      {stateData.viewCode === MEDIA_VIEW_CODES.MEDIA_ADD_VIEW && (
        <MediaSelectionModal
          isOpen={true}
          acceptedFiles={'image/*'}
          mediaFilter={mediaFilter.filter((item) => item.value === 'image')}
          filterKey={'image'}
          onClose={() => {
            setStateData((prev: any) => {
              return {
                ...prev,
                viewCode: '',
              };
            });
          }}
          onSelect={(mediaData) => {
            const isImage = mediaData?.mime?.startsWith('image/');
            if (mediaData.url && isImage) {
              setStateData((prev: any) => {
                return {
                  ...prev,
                  viewCode: '',
                  selectedMedia: mediaData,
                };
              });
            } else {
              notification.error({
                message: 'Selected file format not supported for thumbnail',
              });
            }
          }}
          supportedFormatString={intl.formatMessage({id: 'supportImageText'})}
        />
      )}
    </Drawer>
  );
};

export default AddOrUpdatePackages;
