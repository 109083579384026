import {gql} from '@apollo/client';

export const GetVirtualPhoneNumber = gql`
  query GET_DEFAULT_CALL_NUMBER(
    $uuid: uuid!
  ) {
    virtualPhoneNumberAssignees(
      where: {
        assigneeId: {_eq: $uuid}
      }
    ) {
      assignee
      virtualPhoneNumber
      id
    }
  }
`;

export const GetVirtualPhoneNumberForUserAndLocation = gql`
  query GET_DEFAULT_CALL_NUMBER(
    $whereCondition: virtual_phone_number_assignees_bool_exp
  ) {
    virtualPhoneNumberAssignees(
      where: $whereCondition
    ) {
      assignee
      virtualPhoneNumber
      id
    }
  }
`;

export const GetAllVirtualPhoneNumber = gql`
  query GET_VIRTUAL_PHONE_NUMBER($accountUuid: uuid!) {
    virtualPhoneNumberAssignees(
      order_by: {updatedAt: desc}
      where: {
        accountUuid: {_eq: $accountUuid} 
        isDeleted: {_eq: false}
    }
    ) {
      id
      assignee
      assigneeId
      assigneeTypeId
      assigneeType {
        code
      }
      virtualPhoneNumber
      isAnnouncementEnabled
    }
  }
`;

export const GetVirtualPhoneNumberForAssigneeAndNumber = gql`
  query GET_VIRTUAL_PHONE_NUMBER($whereCondition: virtual_phone_number_assignees_bool_exp!) {
    virtualPhoneNumberAssignees(
      order_by: {updatedAt: desc}
      where: $whereCondition
    ) {
      id
      assignee
      assigneeId
      assigneeTypeId
      assigneeType {
        code
        value
      }
      virtualPhoneNumber
      disableOutgoingCalls
      isAnnouncementEnabled
    }
  }
`;

export const GetVirtualPhoneNumberForAssigneeAndNumberWithSearch = gql`
  query GET_VIRTUAL_PHONE_NUMBER(
    $whereCondition: virtual_phone_number_assignees_bool_exp!,
    $limit: Int
    $offset: Int

    ) {
    virtualPhoneNumberAssignees(
      order_by: {updatedAt: desc}
      where: $whereCondition,
      limit: $limit
      offset: $offset
    ) {
      id
      assignee
      assigneeId
      assigneeTypeId
      flowType
      flowTypeId
      flowTypeData {
        value
        code
      }
      virtualPhoneNumber
      updatedBy
      createdBy
      unavailabilityFlowId
      holidayPhoneTreeId
      isAnnouncementEnabled
    }
    virtualPhoneNumberAssigneeAggregate(where: $whereCondition) {
      aggregate {
        count
      }
    }
  }
`;

export const GetVirtualPhoneNumberAssigneeNames = gql`
  query GetVirtualPhoneNumberAssigneeNames($userUuids: [uuid!], $locationUuids: [uuid!]) {
    users(
      where: {
        uuid: {
          _in: $userUuids
        }
      }
    ) {
      name
      uuid
    }
    accountLocations(
      where: {
        uuid: {
          _in: $locationUuids
        }
      }
    ) {
      uuid
      practiceLocation{
        name
      }
    }
  }
`;


export default {
  GetVirtualPhoneNumber,
  GetVirtualPhoneNumberAssigneeNames,
  GetVirtualPhoneNumberForAssigneeAndNumber
};
