import {Badge, HStack, Text, VStack} from 'native-base';
import { DATE_FORMATS, GOAL_STATUS_CODES } from '../../../../../constants';
import { CARE_PROGRAM_STATUS_CODES, MLOV_CATEGORY, PATIENT_CARE_JOURNEY_REASON_CODE, PATIENT_CARE_JOURNEY_STATUS_CODE } from '../../../../../constants/MlovConst';
import { IMlov } from '../../../../../Interfaces';
import { Colors } from '../../../../../styles/Colors';
import { getDateStrFromFormat, getDiffInDays, getDisplayDateFormatted } from '../../../../../utils/DateUtils';
import { getMlovIdFromCode, getMlovListFromCategory, getMlovValueFromId } from '../../../../../utils/mlovUtils';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import { getUSLocaleDate } from '../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/DetailTableUtils';
import { PATIENT_JOURNEY_ACTION_CODES, PATIENT_JOURNEY_ACTION_LIST } from '../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import { IPatientJourneyAction } from '../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/interfaces';
import { PatientCareJourneyTitleCell } from './PatientCareJourneyTitleCell';
import PatientJourneyActionsView from './PatientJourneyActionView';
import { IPatientCareJourney, IPatientJourneyColProps } from './PatientJourneyInterface';
import { useIntl } from 'react-intl';
import { IJourney } from '../Table/Interfaces';
import { IProductDetailForm } from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/interfaces';
import { JOURNEY_START_TYPES, JOURNEY_START_TYPE_VALUES } from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/FormConst';
import { ICareJourneyStatusIDs } from '../../../Sales/ProductsAndServices/Products/ProductTableView/ProductTable/interfaces';
import { View } from 'react-native';
import { checkLocationAccess } from '../../../../common/ContactProfile/commonUtils';
import { getAllowedUserAccountLocationUuids, getBooleanFeatureFlag, isLoggedInUserWorkFlowOrCustomerSuccess, isLoginUserBusinessOwner } from '../../../../../utils/commonUtils';
import { USER_ACCESS_PERMISSION } from '../../../UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../../SideMenuBar/SideBarConst';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import FeatureFlags from '../../../../../constants/FeatureFlags.enums';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import LockTooltipIcon from '../../../../common/LockTooltipIcon';

export const JOURNEYS_STATUS = {
  ACTIVE: 'Active',
  STOP: 'Stop',
  PAUSE: 'Pause',
  DELETE: 'Delete',
  COMPLETED: 'Complete',
  ASSIGNED: 'Assigned',
  PENDING: 'Pending',
}

export const JOURNEYS_STATUS_CODE = {
  ACTIVE: 'active',
  ASSIGNED: 'assigned',
}

export const STOP_JOURNEY_ACTION = {
  IMMEDIATELY_STOP: 'IMMEDIATELY_STOP',
  STOP_DATE: 'STOP_DATE',
}

export const STOP_JOURNEY_BUTTONS= [
  {
    text: 'Stop Immediately',
    Code: STOP_JOURNEY_ACTION.IMMEDIATELY_STOP,
  },
  {
    text: 'Stop Date',
    Code: STOP_JOURNEY_ACTION.STOP_DATE,
  },
];

export const getCareJourneyColor = (statusCode?: string) => {
  switch (statusCode) {
    case PATIENT_CARE_JOURNEY_STATUS_CODE.ACTIVE:
      return Colors.Custom.activeJourneyColor;
    case PATIENT_CARE_JOURNEY_STATUS_CODE.ASSIGNED:
      return Colors.Custom.activeJourneyColor;
    case PATIENT_CARE_JOURNEY_STATUS_CODE.PAUSED:
      return Colors.Custom.pausedCareJourneyColor;
    case PATIENT_CARE_JOURNEY_STATUS_CODE.STOPPED:
      return Colors.Custom.MissedCallIconColor;
    case PATIENT_CARE_JOURNEY_STATUS_CODE.COMPLETE:
      return Colors.Custom.SuccessColor;
    default:
      return Colors.primary['300'];
  }
}

export const getCareJourneyStatusTextColor = (statusCode?: string) => {
  switch (statusCode) {
    case PATIENT_CARE_JOURNEY_STATUS_CODE.PAUSED:
      return Colors.Custom.pausedCareJourneyColor;
    case PATIENT_CARE_JOURNEY_STATUS_CODE.STOPPED:
      return Colors.Custom.MissedCallIconColor;
    default:
      return Colors.Custom.Gray900;
  }
}

export const getJourneyDaysText = (
  journey?: IPatientCareJourney,
  returnProgress?: boolean
) => {
  const startDateTime = journey?.startDateTime;
  const statusCode = journey?.statusCode;
  const endDateTime: string | undefined = journey?.endDateTime;
  let journeyProgressEndDate: Date | string | undefined = new Date();
  let journeyCompletedDateTime: string | undefined =
    journey?.completedDateTime;

  if (
    statusCode !== PATIENT_CARE_JOURNEY_STATUS_CODE.ASSIGNED &&
    (!startDateTime || !endDateTime)
  ) {
    return returnProgress ? 0 : '';
  }

  switch (statusCode) {
    case PATIENT_CARE_JOURNEY_STATUS_CODE.ASSIGNED:
      return returnProgress ? 0 : '';
    case PATIENT_CARE_JOURNEY_STATUS_CODE.ACTIVE:
      if (endDateTime && new Date(endDateTime) > new Date()) {
        journeyProgressEndDate = new Date();
      } else {
        journeyProgressEndDate = endDateTime;
      }
      break;
    case PATIENT_CARE_JOURNEY_STATUS_CODE.PAUSED:
      journeyProgressEndDate = journey?.pauseDateTime;
      break;
    case PATIENT_CARE_JOURNEY_STATUS_CODE.STOPPED:
      journeyProgressEndDate = journey?.stopDateTime;
      break;
    case PATIENT_CARE_JOURNEY_STATUS_CODE.COMPLETE:
      if (
        journeyCompletedDateTime &&
        endDateTime &&
        new Date(journeyCompletedDateTime) > new Date(endDateTime)
      ) {
        journeyCompletedDateTime = endDateTime;
      }
      journeyProgressEndDate = journeyCompletedDateTime;
      break;
  }

  if (!journeyProgressEndDate || !endDateTime) {
    return returnProgress ? 0 : '';
  }
  const totalDaysForJourney = getDiffInDays(startDateTime, endDateTime);
  const journeyProgressDays = getDiffInDays(startDateTime, journeyProgressEndDate);
  if (returnProgress) {
    if (
      totalDaysForJourney === 0 ||
      Number(journeyProgressDays) < 0 ||
      Number(totalDaysForJourney) < 0
    ) {
      return 0;
    }
    return (Number(journeyProgressDays) * 100) / Number(totalDaysForJourney);
  }
  return `${journeyProgressDays}/${totalDaysForJourney} Days`;
};

export const getCareJourneyProgress = (journey?: IPatientCareJourney) => {
  const journeyProgress = getJourneyDaysText(journey, true) as number
  return journeyProgress
}

export const getCareJourneyStatus = (statusCode: string | undefined, statusList:IMlov[]) => {
  if (!statusCode) {
    return '-';
  }
 const status = statusList.find(statusItem => statusItem.code === statusCode);
 return status?.value || '-'
}


export const getStatusBackground = (statusCode?: string) => {
  switch (statusCode) {
    case PATIENT_CARE_JOURNEY_STATUS_CODE.ACTIVE:
      return Colors.success[100];
    case PATIENT_CARE_JOURNEY_STATUS_CODE.ASSIGNED:
      return Colors.FoldPixel.PRIMARY100;
    case PATIENT_CARE_JOURNEY_STATUS_CODE.PAUSED:
      return Colors.warning[100];
    case PATIENT_CARE_JOURNEY_STATUS_CODE.STOPPED:
      return Colors.error[200];
    case PATIENT_CARE_JOURNEY_STATUS_CODE.COMPLETE:
      return Colors.Custom.Gray100;
  }
}

export const getStatusFontColor = (statusCode?: string) => {
  switch (statusCode) {
    case PATIENT_CARE_JOURNEY_STATUS_CODE.ACTIVE:
      return Colors.success[800]
    case PATIENT_CARE_JOURNEY_STATUS_CODE.ASSIGNED:
      return Colors.FoldPixel.PRIMARY300;
    case PATIENT_CARE_JOURNEY_STATUS_CODE.PAUSED:
      return Colors.warning[700];
    case PATIENT_CARE_JOURNEY_STATUS_CODE.STOPPED:
      return Colors.error[800];
    case PATIENT_CARE_JOURNEY_STATUS_CODE.COMPLETE:
      return Colors.Custom.Gray700;
  }
}


export const getRecordStatus = (record: any) => {
  const intl = useIntl()
  if(record.statusCode === PATIENT_CARE_JOURNEY_STATUS_CODE.PAUSED && record.statusReasonCode === PATIENT_CARE_JOURNEY_REASON_CODE.PATIENT_OPTED_OUT){
    return intl.formatMessage({id: 'optedOut'})
  }
  return record.status
}

export const getPatientCareJourneyTableColumns = (
  journeyProps: IPatientJourneyColProps
): any[] => {
  const isSidecarContext = journeyProps.isSidecarContext;
  const isCompactView = isSidecarContext || journeyProps.isCompactView;
  const allowedUserAccountLocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CARE_JOURNEY
  );  
  const intl = useIntl()
  const isAllowedAllLocation = isLoginUserBusinessOwner() || isLoggedInUserWorkFlowOrCustomerSuccess();

  return [
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300 || '', marginLeft: isCompactView ? 8 : 0}}
          textLocalId={'journeyName'}
        />
      ),
      textWrap: 'word-break',
      ellipsis: true,
      width: isCompactView ? '30%' : '35%',
      render: (record: IPatientCareJourney, selectedRecord: IPatientCareJourney) => {
        const isAccessToLocation = checkLocationAccess({
          locationUuids: [selectedRecord?.accountLocationId || ""],
          allowedLocationUuids: allowedUserAccountLocationUuids.find(location => location == journeyProps?.activePatientAccountLocationId) ? [journeyProps?.activePatientAccountLocationId || ""] : [],
          isMultiTenancyEnabled: journeyProps?.isMsoEnabled || false,
        });
        return (
          <View style={{marginLeft: isCompactView ? 8 : 0}}>
            <PatientCareJourneyTitleCell
              record={selectedRecord}
              onActionClick={(actionCode: string): any => {
                journeyProps.onActionPerformed(actionCode, selectedRecord);
              }}
              isAccessToLocation={isAllowedAllLocation ? true : isAccessToLocation}
            />
          </View>
        );
      },
      responsive: isCompactView ? undefined : ['md'],
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300 || '',}}
          textLocalId={'startDate'}
        />
      ),
      width: isCompactView ? '15%' : '18%',
      render: (record: IPatientCareJourney): JSX.Element => {
        return <Text size={'smRegular'} numberOfLines={1} color={Colors.FoldPixel.GRAY250}> {record.startDateTime ? getDateStrFromFormat(record.startDateTime, DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT) : 'Not started yet'}</Text>;
      },
      responsive: isCompactView ? undefined : ['md'],
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300 || ''}}
          textLocalId={'duration'}
        />
      ),
      width: isCompactView ? '12%' : '15%',
      render: (record: IPatientCareJourney): JSX.Element => {
        return <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}> {`${record.duration || record.careJourney.duration} ${getMlovValueFromId(journeyProps.durationMlovList, record.durationUnitId || record.careJourney.durationUnitId)}`}</Text>;
      },
      responsive: isCompactView ? undefined : ['md'],
    },
    ...!isCompactView ? [{
      title: (
        <HStack justifyContent={"center"}>
          <DisplayText
            size={'smMedium'}
            extraStyles={{color: Colors.FoldPixel.GRAY300 || ''}}
            textLocalId={'status'}
          />
        </HStack>
      ),
      width: '18%',
      render: (record: IPatientCareJourney): JSX.Element => {
        return (
          <VStack>
          <Badge
            width={20}
            backgroundColor={getStatusBackground(record.statusCode)}
            alignSelf="center"
            variant="solid"
            style={{
              paddingHorizontal: 8,
              paddingVertical: 2,
              borderRadius: 16,
              minHeight: 22,
              marginVertical: 4,
              marginHorizontal: 4,
            }}>
            <Text color={getStatusFontColor(record.statusCode)} fontWeight={400} size={'smRegular'}>
              {getRecordStatus(record)}
            </Text>
          </Badge>
          </VStack>
        )
      },
      responsive: isCompactView ? undefined : ['md'],
    }] : [],
    {
      title: (
        <HStack justifyContent={"center"} flex={1}>
          <DisplayText
            size={'smMedium'}
            extraStyles={{color: Colors.FoldPixel.GRAY300 || ''}}
            textLocalId="smartActions"
          />
        </HStack>
      ),
      key: 'smartActions',
      width: '15%',
      onCell: () => {
        return {
          onClick: (e: Event) => {
            e.stopPropagation();
          },
        };
      },
      render: (record: IPatientCareJourney, selectedRecord: IPatientCareJourney) => {
        return (
          <View style={{marginLeft: isCompactView ? 20 : 0}}>
            <PatientJourneyActionsView
              record={selectedRecord}
              onActionClick={(actionCode: string): any => {
                journeyProps.onActionPerformed(actionCode, selectedRecord);
              }}
              isCompactView={journeyProps.isCompactView}
              activePatientAccountLocationId={journeyProps.activePatientAccountLocationId || ''}
            />
          </View>
        );
      },
      responsive: isCompactView ? undefined : ['md'],
    },
  ];
};

const getActiveJourneyFilterOption = (actionCode: string, workflowId?: string, startType?: string) => {
  return (
    actionCode === PATIENT_JOURNEY_ACTION_CODES.SHOW_CARE_TEAM ||
    actionCode === PATIENT_JOURNEY_ACTION_CODES.SHOW_JOURNEY ||
    (actionCode === PATIENT_JOURNEY_ACTION_CODES.EDIT && workflowId && startType !== JOURNEY_START_TYPES.FIXED) ||
    (actionCode === PATIENT_JOURNEY_ACTION_CODES.PAUSE && workflowId) ||
    (actionCode === PATIENT_JOURNEY_ACTION_CODES.STOP && workflowId) ||
    (actionCode === PATIENT_JOURNEY_ACTION_CODES.COMPLETE && workflowId)
  );
}

const getAssignedJourneyFilterOption = (actionCode: string, workflowId?: string, startType?: string) => {
  return (
    actionCode === PATIENT_JOURNEY_ACTION_CODES.SHOW_CARE_TEAM ||
    actionCode === PATIENT_JOURNEY_ACTION_CODES.SHOW_JOURNEY ||
    (actionCode === PATIENT_JOURNEY_ACTION_CODES.EDIT && workflowId && startType !== JOURNEY_START_TYPES.FIXED) ||
    (actionCode === PATIENT_JOURNEY_ACTION_CODES.DELETE && workflowId) ||
    actionCode === PATIENT_JOURNEY_ACTION_CODES.START && startType !== JOURNEY_START_TYPES.FIXED
  );
}

const getPauseJourneyFilterOption = (actionCode: string, workflowId?: string, startType?: string) => {
  return (
    actionCode === PATIENT_JOURNEY_ACTION_CODES.SHOW_CARE_TEAM ||
    actionCode === PATIENT_JOURNEY_ACTION_CODES.SHOW_JOURNEY ||
    (actionCode === PATIENT_JOURNEY_ACTION_CODES.EDIT && workflowId && startType !== JOURNEY_START_TYPES.FIXED) ||
    (actionCode === PATIENT_JOURNEY_ACTION_CODES.RESTART && workflowId) ||
    (actionCode === PATIENT_JOURNEY_ACTION_CODES.STOP && workflowId)
  );
}

const getCompletedJourneyFilterOption = (actionCode: string, workflowId?: string, goalStatusCode?: string) => {
  return (
    actionCode === PATIENT_JOURNEY_ACTION_CODES.SHOW_CARE_TEAM ||
    actionCode === PATIENT_JOURNEY_ACTION_CODES.SHOW_JOURNEY ||
    goalStatusCode && (goalStatusCode === GOAL_STATUS_CODES.NOT_ACHIEVED ? actionCode === PATIENT_JOURNEY_ACTION_CODES.CHANGE_GOAL_STATUS_TO_ACHIEVED : actionCode === PATIENT_JOURNEY_ACTION_CODES.CHANGE_GOAL_STATUS_TO_NOT_ACHIEVED)
  );
}

const getStoppedJourneyFilterOption = (actionCode: string, workflowId?: string, goalStatusCode?: string) => {
  return (
    actionCode === PATIENT_JOURNEY_ACTION_CODES.SHOW_CARE_TEAM ||
    actionCode === PATIENT_JOURNEY_ACTION_CODES.SHOW_JOURNEY ||
    (goalStatusCode && (goalStatusCode === GOAL_STATUS_CODES.NOT_ACHIEVED ? actionCode === PATIENT_JOURNEY_ACTION_CODES.CHANGE_GOAL_STATUS_TO_ACHIEVED : actionCode === PATIENT_JOURNEY_ACTION_CODES.CHANGE_GOAL_STATUS_TO_NOT_ACHIEVED)) ||
    (!goalStatusCode && actionCode === PATIENT_JOURNEY_ACTION_CODES.CHANGE_GOAL_STATUS_TO_NOT_ACHIEVED)
  );
}

export const getQuickActionList = (patientCareJourney?: IPatientCareJourney) => {
  const status = patientCareJourney?.statusCode;
  const startType = patientCareJourney?.startType?.code;
  let actionList:IPatientJourneyAction[] = [];
  switch (status) {
    case PATIENT_CARE_JOURNEY_STATUS_CODE.ACTIVE:
      actionList = PATIENT_JOURNEY_ACTION_LIST.filter((journeyStatus: IPatientJourneyAction) => {
          return getActiveJourneyFilterOption(journeyStatus.actionCode, patientCareJourney?.workflowId, startType);
      });
      break;
    case PATIENT_CARE_JOURNEY_STATUS_CODE.ASSIGNED:
      actionList = PATIENT_JOURNEY_ACTION_LIST.filter((journeyStatus: IPatientJourneyAction) => {
        return getAssignedJourneyFilterOption(journeyStatus.actionCode, patientCareJourney?.workflowId, startType);
      });
    break;
    case PATIENT_CARE_JOURNEY_STATUS_CODE.PAUSED:
      actionList = PATIENT_JOURNEY_ACTION_LIST.filter((journeyStatus: IPatientJourneyAction) => {
        return getPauseJourneyFilterOption(journeyStatus.actionCode, patientCareJourney?.workflowId, startType);
      });
    break;
    case JOURNEYS_STATUS.COMPLETED:
      actionList = PATIENT_JOURNEY_ACTION_LIST.filter((journeyStatus: IPatientJourneyAction) => {
        return getCompletedJourneyFilterOption(journeyStatus.actionCode, patientCareJourney?.workflowId);
      });
    break;
    case PATIENT_CARE_JOURNEY_STATUS_CODE.STOPPED:
      actionList = PATIENT_JOURNEY_ACTION_LIST.filter((journeyStatus: IPatientJourneyAction) => {
        return getStoppedJourneyFilterOption(journeyStatus.actionCode, patientCareJourney?.workflowId, patientCareJourney?.goalStatusCode);
      });
    break;
    case PATIENT_CARE_JOURNEY_STATUS_CODE.COMPLETE:
      actionList = PATIENT_JOURNEY_ACTION_LIST.filter((journeyStatus: IPatientJourneyAction) => {
        return getCompletedJourneyFilterOption(journeyStatus.actionCode, patientCareJourney?.workflowId, patientCareJourney?.goalStatusCode);
      });
    break;
  }
  return actionList;
}


export const isCurrentMenuLast = (
  action: IPatientJourneyAction
): boolean => {
  return action.actionCode === PATIENT_JOURNEY_ACTION_CODES.SHOW_JOURNEY;
};

export const getNoteStringToSave = (note: string) => {
  const strippedValue = note.replace(/<[^>]+>/g, '');
  const hasMeaningfulContent = strippedValue.trim().length > 0;
  return hasMeaningfulContent ? note : '';
}

export const getFilteredLocation = (accountLocations:any[],selectedLocations:any[])=> {
  const tst = accountLocations.filter((location:any)=>{
    if (selectedLocations.includes(location.value)) {
      return true;
    }
    return false
  })
  return tst
}

export const getSelectedCareJourney = (careJourney:any[],selectedCareJourneys:any[])=> {
  const filterOnlySelectedJourney = careJourney.filter((journey:any)=>{
    if (selectedCareJourneys.includes(journey.id)) {
      return true;
    }
    return false
  })
  return filterOnlySelectedJourney
}

export const isContactInSelectedLocation = (accountLocations:any,contactData:any)=> {
  let isContactInLocation = false
  const accountPracticeLocationUuids: string[] = accountLocations?.map((location:any)=>location?.practiceLocation) || [];
  const contactPracticeLocationsUuid: string[] = contactData?.contactPracticeLocations?.map((location:any)=>location.practiceLocationUuid) || [];
  const accountPracticeStateIds: string[] = accountLocations?.map((location:any)=>location?.practiceLocation) || [];
  const contactStateUuid = contactData?.contactStateId || '';
  const matchingPracticeLocation = accountPracticeLocationUuids?.filter(uuid => contactPracticeLocationsUuid?.includes(uuid)) || [];
  const matchingStateLocation = accountPracticeStateIds?.includes(contactStateUuid);
  if (contactPracticeLocationsUuid?.length) {
    isContactInLocation = matchingPracticeLocation?.length ? true : false
  } else {
    isContactInLocation = matchingStateLocation
  }
  return isContactInLocation;
}

export const getJourneyStartDate = (journeyDetail: IProductDetailForm) => {
  if(journeyDetail.startDate){
    return journeyDetail.startDate
  }
  else if(journeyDetail.startDateTime && journeyDetail.startType === JOURNEY_START_TYPES.FIXED){
    return journeyDetail.startDateTime
  }
  return undefined
}

export const getCareJourneyStatusIds = (mlovData: any) => {
  const careJourneyStatusMlov = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_PROGRAM_STATUS
  ) || [];

  const careJourneyStatusIds: ICareJourneyStatusIDs = {
    draft: getMlovIdFromCode(
      careJourneyStatusMlov,
      CARE_PROGRAM_STATUS_CODES.DRAFT
    ),
    published: getMlovIdFromCode(
      careJourneyStatusMlov,
      CARE_PROGRAM_STATUS_CODES.ACTIVE
    ),
  };

  return careJourneyStatusIds
}

export const getStartTypeIdByCode = (mlovData: any, code: string) => {
  const careJourneyStartTypeMlov = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_JOURNEY_START_TYPE
  ) || [];

  return careJourneyStartTypeMlov.find((startTypeMlov)=> startTypeMlov.code === code)?.id
}

export const getPatientCareJourneyFetchCondition = (props: {
  patientIds: string[];
  contactIds?: string[];
  isMultiTenancyEnabled?: boolean;
  accountLocationIds?: string[];
}) => {
  const condition: any = {
    _or: [
      {
        patientId: {
          _in: props.patientIds,
        },
      },
    ],
  };
  if (props.contactIds) {
    condition._or.push({
      contactId: {
        _in: props.contactIds,
      },
    });
  }
  if (props.isMultiTenancyEnabled) {
    condition._and = {
      accountLocationId: {
        _in: props.accountLocationIds,
      },
    };
  }
  return condition;
};
