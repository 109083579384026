import {AxiosInstance} from 'axios';
import BaseService from '../../../../../services/CommonService/BaseService';
import {isEmptyArray, isValidEmail} from '../../../../../utils/commonUtils';
import {IAccountRole} from '../AssociatedAccountView/interface';
import {
  AccountUserResponse,
  FHIRPractitionerResponse,
  PractitionerData,
  Telecom,
  UserData,
} from './interfaces';

export const validateForm = (
  userData: UserData,
  isEmailDuplicate?: boolean,
  arg?: {
    isEmployerSelected?: boolean
  }
) => {
  const {firstName, lastName, email, role, associatedAccounts, selectedGlobalRoles} = userData;

  const errors: any = {};
  let isValid = true;
  let isEmpty = true;

  errors.firstName = !firstName ? 'First Name is required' : '';
  errors.lastName = !lastName ? 'Last Name is required' : '';
  errors.email = !email ? 'Email is required' : '';
  // errors.role = !role ? 'Role is required' : '';
  errors.duplicateEmail =
    isEmailDuplicate && isValidEmail(email) ? 'Email already exist' : '';
  errors.invalidEmail = !isValidEmail(email)
    ? 'Please enter a valid email'
    : '';
  if (!firstName && !lastName && !email && !role) {
    isEmpty = true;
  } else {
    isEmpty = false;
  }
  errors.employerId =
  arg?.isEmployerSelected && !userData.employerId
      ? 'Please select an employer'
      : '';
  if (!selectedGlobalRoles?.length) {
    errors.accountRole = !associatedAccounts?.length ? 'Practice selection and roles selection is mandatory' : ''
    if (associatedAccounts?.length) {
      associatedAccounts.forEach((account: any)=> {
        if (!account.locationUuid) {
          account.locationError = 'Practice Location is mandatory'
          errors.locationAndRole = 'Invalid'
          errors.locationRequired = 'Practice Location is mandatory'
        } 
        if (!account.rolesToAdd?.length) {
          account.roleError = 'Global roles or user roles in mandatory'
          errors.locationAndRole = 'Invalid'
          errors.userRoleRequiered = 'Roles is mandatory'
        }
      })
    }
  } else {
    associatedAccounts?.forEach((account: any)=> {
      if (account.rolesToAdd?.length && !account.locationUuid) {
        account.locationError = 'Practice Location is mandatory if user roles selected'
        errors.locationAndRole = 'Invalid'
        errors.locationRequired = 'Practice Location is mandatory if user roles selected'
      } else {
        account.locationError = ''
        account.roleError = ''
      }
    })
    errors.userRoleRequiered = ''
  }
  if (
    errors.firstName ||
    errors.lastName ||
    errors.email ||
    errors.duplicateEmail ||
    errors.invalidEmail ||
    errors.employerId ||
    errors.accountRole || 
    errors.locationAndRole ||
    errors.userRoleRequiered
  ) {
    isValid = false;
  }
  userData.associatedAccounts = associatedAccounts
  return {
    isValid,
    errors,
    isEmpty,
    updatedUseData: userData
  };
};

export const inviteUser = (args: {
  baseService: AxiosInstance;
  body: UserData;
}) => {
  const {baseService, body} = args;
  const url = `/crm-nest/inviteUser`;
  return baseService.post(url, body);
};

export const getTeamCount = () => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `/crm-nest/platform/team_count`;
  return baseService.get(url);
};

export const updateUserRolesAndAccount = (args: {
  body: {associatedAccounts: IAccountRole[]};
}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const {body} = args;
  const url = `/crm-nest/platform/update_user_roles`;
  return baseService.post(url, body);
};

export const getPostRequestBody = (data: UserData[]) => {
  const formattedData: any = [];
  data.forEach((data) => {
    const {email, firstName, lastName, role, externalUserId, employerId, associatedAccounts} = data;
    if (associatedAccounts?.length) {
      formattedData.push(data)
    } else if (externalUserId) {
      const userData = {
        role: JSON.stringify({role: role, externalUserId: externalUserId}),
        email,
        firstName,
        lastName,
      };
      formattedData.push(userData);
    } else if (employerId) {
      const userData = {
        role: JSON.stringify({role: role, employerId: employerId}),
        email: email,
        firstName: firstName,
        lastName: lastName,
        templateCategory: 'EMPLOYER_INVITATION',
        employerId: employerId,
        associatedAccounts: associatedAccounts
      };
      formattedData.push(userData);
    } else {
      formattedData.push(data)
    }
  });
  return formattedData
};

export const getErrorMessage = (error: any) => {
  return (
    error?.response?.data?.message ||
    'Something went wrong. Please try again later'
  );
};

export const getFormattedPractitionerData = (
  data: FHIRPractitionerResponse
): PractitionerData[] => {
  const result: PractitionerData[] = [];
  const entry = data.entry;
  if (!entry || entry.length === 0) {
    return result;
  }
  entry.forEach((item) => {
    const resource = item.resource;
    const email =
      getTelecomObject({telecom: resource.telecom, system: 'email'})?.value ||
      '';
    const nameArray = resource.name;
    result.push({
      id: (resource.id || '').toString(),
      email: email,
      firstName:
        (nameArray?.length > 0 &&
          nameArray[0]?.given?.length > 0 &&
          nameArray[0].given[0]) ||
        '',
      lastName: (nameArray?.length > 0 && nameArray[0]?.family) || '',
      active: resource?.active,
      displayName:
        (nameArray?.length > 0 &&
          nameArray[0]?.given?.length > 0 &&
          nameArray[0].given[0]) +
        ' ' +
        (nameArray?.length > 0 && nameArray[0]?.family),
    });
  });
  return result.filter((item) => item?.active ?? true);
};

export const getTelecomObject = (args: {
  telecom: Telecom[];
  system: 'email' | 'phone';
}) => {
  const {telecom, system} = args;
  if (isEmptyArray(telecom)) {
    return {value: '', system: ''};
  }
  const result = telecom.find((item) => item.system === system);
  return result;
};

export const getFinalPractitionerData = (
  practitionerData: PractitionerData[],
  accountUsersResponse: AccountUserResponse
): PractitionerData[] => {
  if (isEmptyArray(practitionerData)) {
    return [];
  }
  const accountUsersData = accountUsersResponse?.data?.externalUserMaps;
  return practitionerData.filter((item) => {
    const condition = accountUsersData?.some(
      (user) => user.externalUserId == item.id
    );
    return !condition;
  });
};
