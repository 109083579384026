import { Text } from 'native-base';
import { Location } from 'react-router-dom';
import { URLSearchParams } from 'url';
import {
  AppointmentBookingWidget,
  OrderSuccess,
  PublicForm,
} from '../../components/PublicPages';
import { AppointmentRSVPWidget } from '../../components/PublicPages/AppointmentRSVPWidget/AppointmentRSVPWidget';
import { WIDGET_TYPES } from '../../components/RightSideContainer/Forms/FormsConstants';
import { evnName } from '../../constants/BaseUrlConst';
import LocalStorage from '../../utils/LocalStorage';
import {getQueryObjectFromURL} from '../../utils/commonUtils';
import {NETWORK_ERROR_CONST} from './MainScreenConst';
import SwitchAccount from './SwitchAccount/SwitchAccount';
import ErrorPage from '../../components/PublicPages/ErrorPage/ErrorPage';

export const getCurrentSubdomain = () => {
  if (window.location.host.includes('localhost') || window.location.host.includes('192.168.')) {
    return `astrana.${evnName()}foldhealth.io`;
  } else if (window.location.host.includes('.staging.foldhealth.io')) {
    const subdomain = window.location.host.replace('.staging', '');
    return subdomain;
  }
  return window.location.host;
};

export function getPublicPage(
  location: Location,
  searchParams: URLSearchParams,
  numberAccountId?: string
): JSX.Element | undefined {
  const queryParams: any = getQueryObjectFromURL(searchParams as any);
  queryParams.numberAccountId = numberAccountId;
  if (location.pathname.includes('/form')) {
    if ((queryParams.formId || queryParams.formCode) && queryParams.accountId) {
      return (
        <div className="form-widget-parent">
          <PublicForm params={queryParams} />
        </div>
      );
    } else if (
      (queryParams.widgetType === WIDGET_TYPES.TPA_CARE_ADVOCATE ||
        queryParams.widgetType === WIDGET_TYPES.PATIENT_INFORMATION_FORM) &&
      queryParams.token
    ) {
      return (
        <div className="form-widget-parent">
          <PublicForm params={queryParams} />
        </div>
      );
    }
  } else if (location.pathname.includes('/appointment/booking/rsvp')) {
    if (
      queryParams.appointmentId &&
      queryParams.participantId &&
      queryParams.accountId
    ) {
      return (
        <div className="appointment-widget-parent">
          <AppointmentRSVPWidget params={queryParams} />
        </div>
      );
    }
  } else if (location.pathname.includes('/appointment/booking')) {
    if (queryParams.accountId) {
      return (
        <div className="appointment-widget-parent">
          <AppointmentBookingWidget params={queryParams} />
        </div>
      );
    }
  } else if (
    window.location.pathname.includes('widget.html') &&
    window.location.search.includes('responseCode=') &&
    window.location.search.includes('state=')
  ) {
    return (
      <div className="order-submission-parent">
        <OrderSuccess query={window.location.search} />
      </div>
    );
  } else if (location.pathname.includes('switch_account')) {
   return <SwitchAccount />
  } else if (location.pathname.includes('error_page')) {
    return <ErrorPage />
  }
  return <ErrorPage />;
}

// This colors are used in css specifically for bootstrap
export function setThemeCSSVariablesGlobally(themeData: any) {
  if (themeData && themeData.colorConfig) {
    Object.keys(themeData.colorConfig).map(function (colorKey) {
      const colorPallet = themeData.colorConfig[colorKey];
      if (colorPallet) {
        Object.keys(colorPallet).map(function (colorWeight) {
          const colorValue = colorPallet[colorWeight];
          if (colorValue) {
            document.documentElement.style.setProperty(
              `--${colorKey}-${colorWeight}`,
              colorValue
            );
          }
        });
      }
    });
  }
}

export const handleAPIErrors = (error: any) => {
  const statusCode = error?.response?.status;
  if (statusCode === 401) {
    LocalStorage.removeItem('user').then(() => {
      window.location.href = window.location.origin + '/#/home';
      window.location.reload();
    });
  }
};

export const checkIfOffline = (error: any) => {
  return (
    error.message === NETWORK_ERROR_CONST.NETWORK_ERROR ||
    error.request._response === NETWORK_ERROR_CONST.OFFLINE
  );
};
