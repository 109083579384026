import {gql} from '@apollo/client';

export const ADD_OR_UPDATE_CARE_PLAN = gql`
  mutation addOrUpdateCarePlan($params: AddOrUpdateContactCarePlanInput!) {
    addOrUpdateContactCarePlan(params: $params) {
      carePlanId
    }
  }
`;

export const GET_CARE_PLANS_BY_CONTACT_ID = gql`
query GetContactCarePlans($contactId: uuid!, $contactCareProgramId: [uuid!]) {
  getContactCarePlans(
    params: {contactId: $contactId, contactCareProgramIds:$contactCareProgramId}
  ) {
      carePlans {
        id
        contactId
        statusId
        formLogId
        formId
        selectedFormIds
        title
        startDateTime
        sourceTypeId
        sourceId
        ehr
        createdBy
        createdOn
        contactCareProgramId
        goals {
          id
          categoryId
          status {
            code
          }
          statusId
          title
          duration
          durationUnitId
          goalTargets {
            title
            entityCode
            entityTypeId
            entityType {
              code
            }
            entityValue
            formId
            measure
            status {
              code
            }
          }
          goalType
          goalProgress
        }
        barriers {
          categoryId
          status {
            code
          }
          statusId
          id
          title
        }
        interventions {
          id
          startDateTime
          endDateTime
          statusId
          status {
            code
            value
          }
          comments {
            id
            comment
          }
          intervention {
            id
            trigger
            type
            title
            duration
            frequency
            metadata
            emailTemplate
            isDeleted
          }
        }
      }
    }
  }
`;

export const GET_CARE_PLAN_GOAL_PROGRESSES_BY_CONTACT_ID = gql`
  query GetContactCarePlans($contactId: uuid!){
    getContactCarePlans(params: {contactId: $contactId }) {
      carePlans {
        goals {
          id
          goalType
          goalProgress
        }
      }
    }
  }
`;

export const GET_CARE_PLAN_GOALS_BY_CONTACT_ID = gql`
  query GetContactCarePlans($contactId: uuid!){
    getContactCarePlans(params: {contactId: $contactId }) {
      carePlans {
        goals {
          id
          categoryId
          status {
            code
          }
          statusId
          title
          goalTargets {
            title
            entityCode
            entityTypeId
            entityType {
              code
            }
            entityValue
            formId
            measure
            status {
              code
            }
          }
        }
      }
    }
  }
`;
export const GET_CARE_PLAN_BY_ID = gql`
  query getCarePlanById($id: uuid!) {
    contactCarePlans(where: {id: {_eq: $id}, isDeleted: {_eq: false}}) {
      id
      title
      statusId
      contactId
      createdBy
    }
  }
`;


export const GET_CARE_PLAN_REVIEW_TASK = gql`
query getCarePlanReviewTask($carePlanId: uuid) {
  resourceMappings(where: {sourceId: {_eq: $carePlanId}, sourceTypeCode: {_eq: "CarePlan"}}) {
    resourceId
  }
}

`

export const GET_CARE_PLAN_AUDIT = gql`
  query GetCarePlanAudit($carePlanId: uuid!) {
    activityLogs(where: { isDeleted: { _eq: false }, resourceId: { _eq: $carePlanId }, resourceTypeCode: { _eq: "CARE_PLAN" }}, order_by: { timestamp: desc }) {
      id
      data
      actionCode
      performedById
      source
      performedByTypeCode
      timestamp
    }
  }
`;

export const GET_CARE_PROGRAM_BY_CARE_PLAN_ID = gql`
  query getCareProgramByCarePlanId($id: uuid!, $stepType: String!) {
    contactCarePlans(where: {id: {_eq: $id}, isDeleted: {_eq: false}}) {
      contactCareProgramId
      contactCarePrograms {
        id
        contactCareProgramSteps(where: {
          careProgramStepType: {
          code: {
            _eq: $stepType
          }
        }}) {
          id
        }
      }
    }
  }
`;

export const GET_CARE_PLAN_LAST_PUBLISH_DATA = gql`
  query GetCarePlanAudit($carePlanId: uuid!) {
    activityLogs(where: { isDeleted: { _eq: false }, resourceId: { _eq: $carePlanId }, resourceTypeCode: { _eq: "CARE_PLAN" }}, order_by: { timestamp: desc }, limit: 1) {
      id
      performedById
      performedByTypeCode
      timestamp
    }
  }
`;
