import { CodeableConcept, Coding, DiagnosticReport, Extension } from 'fhir/r4';
import {FHIR_RESOURCE, FHIR_SYSTEM_CODE_TYPES} from '../../../constants/FhirConstant';
import { EXTENSION_URLS } from '../../PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant';
import { getImagingReportLabels } from '../../../services/CommonService/IntegrationService';

export class FhirUtils {
  static filterExtensions(resource: any, filterExtensions: string[] = []) {
    if (!resource.extension) {
      return resource;
    }
    const extensions: any = [];
    for (const extension of resource.extension) {
      if (!filterExtensions.includes(extension.url)) {
        extensions.push(extension);
      }
    }
    resource.extension = extensions;
    return resource;
  }

  static getMrnNumber(resource: any) {
    if (resource && resource.resourceType === FHIR_RESOURCE.PATIENT) {
      for (const identifier of resource.identifier || []) {
        const type = identifier?.type?.coding?.[0]?.code;
        if (type === 'MR') {
          return identifier.value;
        }
      }
    }
    return '';
  }

  static getTelecom(resource: any, telecomType: string) {
    if (resource && resource.resourceType === FHIR_RESOURCE.PATIENT) {
      for (const telecom of resource.telecom || []) {
        if (telecom?.system == telecomType) {
          return telecom.value;
        }
      }
    }
  }

  static getNamesFromResource(resource: any): any {
    if (resource && resource.resourceType === FHIR_RESOURCE.PATIENT) {
      for (const name of resource.name || []) {
        if (name?.use === 'official') {
          return {
            firstName: name.given?.[0],
            middleName: name.given?.[1],
            lastName: name.family,
          };
        }
      }
    }
  }

  static getAddressFromResource(resource: any) {
    if (resource && resource.address?.length > 0) {
      const address = resource?.address?.[0];
      return {
        line1: address.line?.[0],
        line2: address.line?.[1],
        city: address.city,
        state: address.state,
        postalCode: address.postalCode,
      };
    }
    return {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postalCode: '',
    };
  }

  private static isPatientResource(resource: any) {
    if (resource.resourceType === FHIR_RESOURCE.PATIENT) return true;
    return this.splitReference(resource.reference).includes(
      FHIR_RESOURCE.PATIENT
    );
  }

  private static splitReference = (reference: string): string[] => {
    const separator = reference.includes(':') ? ':' : '/';
    return reference.split(separator);
  };

  private static getResourceId = (resource: any) => {
    if (!resource) return;
    if (resource.reference) {
      return this.splitReference(resource.reference).pop();
    }
    return resource.id;
  };

  static getPatientIdFromParticipants = (participants: any) => {
    for (const participant of participants || []) {
      if (this.isPatientResource(participant.actor)) {
        return this.getResourceId(participant.actor);
      }
    }
  };

  static setPatientIdToParticipants(participants: any, patientUuid: string) {
    const finalParticipants: any = [];
    for (const participant of participants) {
      if (this.isPatientResource(participant.actor)) {
        finalParticipants.push({
          ...participant,
          actor: {
            resourceType: FHIR_RESOURCE.PATIENT,
            id: patientUuid,
          },
        });
        continue;
      }
      finalParticipants.push(participant);
    }
    return finalParticipants;
  }

  static getFoldId(resource: any) {
    const FOLD_CODE_SYSTEM = 'http://fold.health';
    for (const identifier of resource.identifier || []) {
      if (identifier.system === FOLD_CODE_SYSTEM) {
        return identifier.value;
      }
    }
  }

  static getReasonForVisit(payload: any) {
    const reasonForVisit = payload?.reasonCode;
    if (reasonForVisit) {
      const text: string = reasonForVisit?.[0]?.text;
      return text;
    }
  }

  static getPath(resourceName: string, query: any) {
    let path = `/${resourceName}`;

    // Adding resource identifier
    if ('id' in query) {
      path += `/${query.id}`;
      delete query.id;
    }

    // Appending queries
    if (Object.keys(query).length > 0) {
      let queryPath = '?';
      for (const key in query || {}) {
        queryPath += `${key}=${query[key]}&`;
      }
      path += queryPath;
    }
    return path;
  }
}


export const getFhirCodeBySystem = (system: string | undefined) => {
  return FHIR_SYSTEM_CODE_TYPES.find((codeObj) => codeObj.system?.toLowerCase() === system?.toLowerCase())
    ?.code;
};

export const getValidCoding = (
  code: CodeableConcept | undefined,
  allowedCodeSystems?: string[],
  coding?: Coding[]
) => {
  if (!allowedCodeSystems || allowedCodeSystems?.length === 0) {
    return code?.coding || coding;
  }

  const convertedAllowedCodeSystems = allowedCodeSystems?.map((codeSystem)=> codeSystem?.toLowerCase());  

  return (code?.coding || coding)?.filter((codeObj) => {
    const code = getFhirCodeBySystem(codeObj.system);
    if (code && convertedAllowedCodeSystems?.includes(code?.toLowerCase())) {
      return true;
    }

    return false;
  });
};

export const getTextFromCoding = (coding: Coding[] | undefined) => {
  let display = '';
  coding?.forEach((code) => {
    if (code.display) {
      display = code.display;
    }
  });
  return display;
};

export const getEffectivePeriodFromTime = (effectiveTime: string | undefined) => {
  if (effectiveTime) {
    return {
      start: effectiveTime,
    };
  }
}

export const getDocumentTypeIdFromExtension = (
  extension: Extension[] | undefined
) => {
  if (!extension) return;
  const documentTypeId = extension.find(
    (ext) => ext.url === EXTENSION_URLS.documentTypeId
  );
  return (
    documentTypeId?.valueString || documentTypeId?.valueInteger?.toString()
  );
};

export const getImagingReportLabelsFromExtension = (extension: Extension[] | undefined) => {
  if (!extension) return;
  const imagingReportLabel = extension.find(
    (ext) => ext.url === EXTENSION_URLS.imagingReportLabel
  );
  return imagingReportLabel?.valueString;
}

export const addImagingReportLabelToDiagnosticReport = async (
  report: DiagnosticReport
) => {
  const documentTypeId = getDocumentTypeIdFromExtension(report.extension);
  if (!documentTypeId) return report;
  const valueSet = await getImagingReportLabels({searchString: documentTypeId});
  if (!valueSet) return report;
  const labelObj = (valueSet?.expansion?.contains ?? []).find(
    (item) => item.code === documentTypeId
  );
  if (!labelObj?.display) return report;
  const extension = report.extension ?? [];
  extension.push({
    url: EXTENSION_URLS.imagingReportLabel,
    valueString: labelObj.display,
  });
  report.extension = extension;
  return report;
};
