import React, {useContext, useEffect, useState} from 'react';
import {Text, View} from 'native-base';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {locationGroupMultiSelectStyles} from './styles';
import {Checkbox, Popover} from 'antd';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../styles';
import {ViewStyle} from 'react-native';
import {StyleProp} from 'react-native';
import {EHRWiseLocations} from '../../../Interfaces';
import {usePermissions} from '../../CustomHooks/usePermissions';
import {IFormattedLocationGroup, ILocationGroupMultiSelectProps} from '../CareDashboard/TaskAnalytics/interface';
import {getSelectedLocationGroups} from '../CareDashboard/TaskAnalytics/commonUtils';

const LocationGroupMultiSelect: React.FC<ILocationGroupMultiSelectProps> = ({
  onLocationGroupChange,
  style,
  locationGroupIds,
  permissionAttribute,
}) => {
  const context = useContext(CommonDataContext);
  const {check} = usePermissions(context);
  const permissionObject = check(
    permissionAttribute?.resourceCode ?? '',
    permissionAttribute?.action ?? ''
  );
  const allowedLocationGroupIds = permissionObject?.allowedLocationGroupIds;
  const allowedLocationIds = permissionObject?.allowedLocationIds;
  const userAllowedLocationList =
    allowedLocationGroupIds &&
    (context?.accountLocationListWithEHR?.filter(
      (location: EHRWiseLocations) => {
        return (
          location?.locationGroupId &&
          allowedLocationGroupIds?.includes(location?.locationGroupId)
        );
      }
    ) as EHRWiseLocations[]);

  const getFormattedLocationGroups = (): IFormattedLocationGroup[] => {
    const formattedGroups: IFormattedLocationGroup[] = [];

    (userAllowedLocationList ?? []).forEach((location) => {
      const existingGroup = formattedGroups.find(
        (group) => group.id === location.locationGroupId
      );

      if (existingGroup && location.uuid) {
        existingGroup.locationIds.push(location.uuid);
      } else {
        if (location.locationGroup?.groupName) {
          formattedGroups.push({
            id: location.locationGroupId ?? '',
            groupName: location.locationGroup?.groupName || '',
            locationIds: location.uuid ? [location.uuid] : [],
          });
        }
      }
    });

    return formattedGroups;
  };

  const formattedLocationGroups = getFormattedLocationGroups();

  const [locationGroup, setLocationGroup] = useState({
    isOpen: false,
    selectedLocationGroups: getSelectedLocationGroups(locationGroupIds, formattedLocationGroups)
  });

  const handleOpenChange = (newOpen: boolean) => {
    setLocationGroup((prev) => ({
      ...prev,
      isOpen: newOpen,
    }));
  };
  
  const passDefaultLocation = () => {
    const selectedLocationGroups = locationGroupIds?.length 
      ? formattedLocationGroups.filter((g) => locationGroupIds?.includes(g.id)) as IFormattedLocationGroup[]
      : formattedLocationGroups;
    const locationIds = selectedLocationGroups.flatMap((g) => g.locationIds.filter(id => allowedLocationIds?.includes(id)));
    const allGroupIds = selectedLocationGroups.map((g) => g.id);
    const isDefaultLocation = locationGroupIds && locationGroupIds?.length > 0 ? false : true;
    onLocationGroupChange(allGroupIds, locationIds,  isDefaultLocation);
  };
  useEffect(() => {
    passDefaultLocation();
  }, []);
  useEffect(() => {
    if (locationGroupIds && locationGroupIds?.length < 1) {
      setLocationGroup((prev) => ({
        ...prev,
        selectedLocationGroups: [],
      }));
    }
  }, [locationGroupIds]);
  return (
    <View
      style={[locationGroupMultiSelectStyles.checkBoxDropDownContainer, style]}
    >
      <Popover
        content={
          <View>
            <Checkbox.Group
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              value={locationGroup.selectedLocationGroups.map((g) => g.id)}
              onChange={(values: any) => {
                const selectedGroups = values?.length
                  ? formattedLocationGroups?.filter((g) =>
                      values.includes(g.id)
                    )
                  : formattedLocationGroups;

                setLocationGroup((prev) => ({
                  ...prev,
                  selectedLocationGroups: values?.length ? selectedGroups : [],
                }));

                const locationIds = selectedGroups.flatMap(
                  (g) => g.locationIds.filter(id => allowedLocationIds?.includes(id))
                );
                const allGroupIds = formattedLocationGroups.map((g) => g.id);
                onLocationGroupChange(
                  values.length ? values : allGroupIds,
                  locationIds,
                  values.length ? false : true
                );
              }}
            >
              {formattedLocationGroups.map((group) => (
                <Checkbox
                  style={{
                    margin: 5,
                    fontWeight: 500,
                    color: '#3A485F',
                    fontSize: 15,
                  }}
                  key={group.id}
                  value={group.id}
                >
                  {group.groupName}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </View>
        }
        placement="bottom"
        trigger="click"
        open={locationGroup.isOpen}
        onOpenChange={handleOpenChange}
      >
        <Text cursor={'pointer'}>
          Location Groups
          <Text fontWeight={'bold'}>
            {locationGroup.selectedLocationGroups.length > 0
              ? `: ${locationGroup.selectedLocationGroups[0].groupName}${
                  locationGroup.selectedLocationGroups.length > 1
                    ? ` • +${locationGroup.selectedLocationGroups.length - 1}`
                    : ''
                }`
              : ''}
          </Text>
        </Text>
        <AntIcon
          name="down"
          color={Colors.Custom.Gray600}
          size={12}
          style={{marginLeft: 4}}
        />
      </Popover>
    </View>
  );
};

export default LocationGroupMultiSelect;
