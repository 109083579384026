import {ApolloProvider, ServerError} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {ConfigProvider} from 'antd';
import {
  HStack,
  Image,
  Modal,
  NativeBaseProvider,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import {createContext, useEffect, useState} from 'react';
import {useWindowDimensions} from 'react-native';
import {useLocation, useSearchParams} from 'react-router-dom';
import {BusinessStudio} from '..';
import {DisplayText} from '../../components/common/DisplayText/DisplayText';
import LoadingSpinner from '../../components/common/Loader/LoadingSpinner';
import {SmallScreenAlert} from '../../components/common/SmallScreenAlert';
// import CommonService from '../../services/CommonService/UploadService';
import {ForgotPassword} from '../../components/ForgotPassword';
import {Login} from '../../components/Login/Login';
import {SetPassword} from '../../components/SetPassword';
import {evnName, SERVICES_BASE_URL} from '../../constants/BaseUrlConst';
import {CRM_PUBLIC_BASE_URL} from '../../constants/Configs';
import {CommonDataContext, ICommonData} from '../../context/CommonDataContext';
import {ILoginUserData, IUserMainDataObj} from '../../Interfaces';
import {ApolloClient} from '../../services';
import BaseService from '../../services/CommonService/BaseService';
import CMSService from '../../services/CommonService/CMSService';
import CommerceCommonService from '../../services/CommonService/CommerceCommonService';
import CommonService from '../../services/CommonService/CommonService';
import Icons from '../../styles/Icons';
import {getAntdBaseTheme} from '../../theme/AntdBaseTheme';
import {Amplify, Auth, Hub} from 'aws-amplify';

import {
  getNativeBaseConfig,
  getNativeBaseTheme,
} from '../../theme/NativeBaseTheme';
import '../../theme/ThemeVariables.scss';
import {getCurrentUserRoleCodes, getQueryObjectFromURL, isFoldAccessTokenExpired, isString, isValidObject, sendMemoryToSentryPeriodically} from '../../utils/commonUtils';
import LocalStorage from '../../utils/LocalStorage';
import {LOCAL_STORAGE_CONST} from './MainScreenConst';
import {
  getCurrentSubdomain,
  getPublicPage,
  handleAPIErrors,
  setThemeCSSVariablesGlobally,
} from './MainScreenHelper';
import LoginScreen from './LoginScreen';
import {useOnlineStatus} from '../../components/CustomHooks/useOnlineStatus';
import {styles} from '../BusinessStudio/Styles';
import NoInternet from '../NoInternet/NoInternet.web';
import {SsoErrorPage} from './SSOLogin/SsoErrorPage';
import {SsoAccessErrorPage} from './SSOLogin/SsoAccessErrorPage';
import { checkWorkflowUserByUrl } from './SSOLogin/SsoUtils';
import { RoleCategory } from '../../components/RightSideContainer/Contacts/TeamMembers/AddEditUser/interfaces';
import { showToast, ToastType } from '../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
import { TEAM_MEMBERS_ROLES_CODE } from '../../components/RightSideContainer/Contacts/TeamMembers/AddEditUser/TeamMemberConst';
import { postOutlookCodeToParentWindow } from '../../components/RightSideContainer/TeamInbox/Integrations/IntegrationCreate/EmailInboxCreate/EmailInboxUtils';
import { GlobalDebug } from './RemoveConsole';
import {EventBus} from '../../utils/EventBus';
import {getAccountDataByAccountId} from '../../services/CommonService/AidBoxService';
import { HeaderAppName } from '../../utils/APIUtils';
import * as Sentry from "@sentry/react";
import { MAIN_MENU_CODES } from '../../components/SideMenuBar/SideBarConst';
import {IPermitCaslAbility} from './interface';
import {isAccountConfigEnabled} from '../../utils/configUtils';
import {CONFIG_CODES} from '../../constants/AccountConfigConst';
import {getAccountLocationUuidFromRolesResp, getNotDeleteRoles, getResorceCode} from './utils';
import {DASHBOARD_PERMISSION_ACTION, SETTING_PERMISSION_ACTION} from '../../constants';
import {USER_ACCESS_PERMISSION} from '../../components/RightSideContainer/UserAccess/UserAccessPermission';
import { isStagingEnvLogin } from '../../sidecar/common/SideCardCommonUtils';
import { Permit } from '../../lib/permitio-wrapper';


declare const window: any;

const MainScreen = () => {
  const toast = useToast();
  const intl = useIntl();
  const isOnline = useOnlineStatus();
  const location = useLocation();
  const [showNoInternet, setShowNoInternet] = useState(false);
  const [searchParams] = useSearchParams();
  const isPublicURL = (location.pathname.includes('/public/') && !location.pathname.includes('/extension')) || location.pathname.includes('/switch_account');
  const isExtensionLogin = window.location.href.includes('/extension');
  const currentUserRolesList = getCurrentUserRoleCodes();
  const isWorkflowUser = window.location.href.includes('/cs');
  const queryParams = getQueryObjectFromURL(searchParams);
  const [ability, setAbility] = useState<IPermitCaslAbility | undefined>(undefined);

  const isStagingEnv = !!(window.location.host.includes('.staging.foldhealth.io'));

  const [allCommonData, setAllCommonData] = useState<ICommonData>({
    userSettings: {},
    currentUserRoles: [],
    userPermissions: [],
    MLOV: {},
    CARE_STUDIO_MLOV: {},
    CLOUD_TELEPHONY_MLOV: {},
    themeConfig: {},
    accountThemeConfig: {},
    userData: {} as ILoginUserData,
    userRoleCategories: [],
    userRoles: [],
    userMainDataObj: {} as IUserMainDataObj,
    accountConfigData: {} as any,
    userAccountLocationUuids: [] as string[],
    mobileAppDimension: {
      height: 0,
      width: 0,
    }
  });
  const [defaultTheme, setDefaultTheme] = useState({});
  const [appDownloadModal, showAppDownloadModal] = useState(true);
  const [isLocalStorageFetched, setIsLocalStorageFetched] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [isAbiltyLoad, setIsAbiltyLoad] = useState(false);

  const [isFetchingLoggedInInfo, setIsFetchingLoggedInInfo] = useState(true);
  const [isFetchingSubdomainInfo, setIsFetchingSubdomainInfo] = useState(false);

  const [accountSubdomainInfo, setAccountSubdomainInfo] = useState({} as any);
  const [showLoader, setShowLoader] = useState(true);
  const [isLoginAPIInprogress, setIsLoginAPIInprogress] = useState(false);
  const [isSsoLoginInprogress, setSSOLoginInProgress] = useState(false);
  const [resetPasswordData, setResetPasswordData] = useState({} as any);

  const [token, setToken] = useState('');
  const [errorStateData, setErrorStateData] = useState({
    showSSOError: false,
    showUnauthorizedAccessError: false,
  });
  const [customState, setCustomState] = useState<string | null>(null);
  const [forgotPasswordData, setForgotPasswordData] = useState({
    email: '',
    isBtnClicked: false,
  });

  useEffect(() => {
    if (queryParams?.extensionId) {
      LocalStorage.setItem('extensionId', queryParams?.extensionId);
    }
    setShowNoInternet(!isOnline);
    if (isLoginSuccess && window.userpilot) {
      window.userpilot?.reload();
    }
  }, [location]);

  useEffect(() => {
    if (isOnline) {
      setShowNoInternet(false);
    }
  }, [isOnline]);

  /**
   * @REMOVE_CONSOLES
   * // remove the working of console logs
   * // remove any accidental use of console logs
   */
  useEffect(() => {
    process.env.NODE_ENV === 'production' && GlobalDebug(false, true);
  }, []);
  useEffect(() => {
    postOutlookCodeToParentWindow();
  }, []);

  useEffect(() => {
    LocalStorage.getItem('isWorkflowLogin').then((result) => {
      Hub.listen('auth', ({payload: {event, data}}) => {


        switch (event) {
          case 'signIn':
          case 'cognitoHostedUI':
            setToken('grating...');
            getToken().then((userToken) => {
              const token = userToken?.getIdToken()?.getJwtToken();
              setToken(token!);
              ssoService(token);

            });
            break;
          case 'signOut':
            setToken('');
            LocalStorage.removeItem('loginDone');
            break;
          case 'signIn_failure':
          case 'cognitoHostedUI_failure':
            onLoginSuccess(false);
            setIsLoginAPIInprogress(false);
            setShowLoader(false);
            LocalStorage.removeItem('loginDone');
            setErrorStateData((prev) => {
              return {
                ...prev,
                showSSOError: true,
              };
            });
            break;
          case "customOAuthState":
            setCustomState(data);
            break;
          default:
            break;
        }
      });
    })
  }, []);
  const getAbilityFromPermit = async (accountUuid: string, userUuid: string, accountLocationUuids: string[]) => {
    const url = `${SERVICES_BASE_URL.COMMERCE_BASE_URL}/crm-nest/permitio/check/${accountUuid}`
      const permit = Permit({
        loggedInUser: userUuid,
        backendUrl: url,
      });
      const locations = [...accountLocationUuids, 'null']
      const permitPerms:{
        resource: string;
        action: string;
      }[] = []
      const dashboardActions: string[] = Object.values(DASHBOARD_PERMISSION_ACTION);
      const settingPermissionCodes: string[] = Object.values(SETTING_PERMISSION_ACTION);

      dashboardActions.forEach(action => {
        locations.forEach(location => {
          permitPerms.push({
            resource: getResorceCode({
              actionCode: action,
              accountLocationId: location,
              sectionCode: USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code
            }),
            action: action,
          });
        });
      });

      settingPermissionCodes.forEach(action => {
        locations.forEach(location => {
          permitPerms.push({
            resource: getResorceCode({
              actionCode: action,
              accountLocationId: location,
              sectionCode: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code
            }),
            action: action,
          });
        });
      });

      await permit.loadLocalStateBulk(permitPerms);

    };
    const getAbility = async (accountUuid: string, userUuid: string, accountLocationUuids: string[]) => {
    setIsAbiltyLoad(false)
    await getAbilityFromPermit(accountUuid,userUuid,accountLocationUuids);
    setIsAbiltyLoad(true)
  }

  function getToken() {
    return Auth.currentSession()
      .then((session) => session)
      .catch(()=>{});
  }

  const redirectToExtension = async () => {
    const extensionId = queryParams?.extensionId || (await LocalStorage.getItem('extensionId'));
    const userResponseMap = getUserDetailsMap(allCommonData.userMainDataObj);
    const isStagingEnv = await isStagingEnvLogin();

    const extensionData = {
      userData: allCommonData?.userData as ILoginUserData,
      userRoles: allCommonData.userRoles,
      userMainDataObj: userResponseMap,
      accountSubdomain: allCommonData.accountSubdomain || JSON.parse((await LocalStorage.getItem('account')) || '{}'),
      isStagingEnv: isStagingEnv,
    };
    const base64Data = btoa(JSON.stringify(extensionData));
    window.location.assign(`chrome-extension://${extensionId}/options.html?token=${base64Data}`);
  }

  useEffect(() => {
    if (isExtensionLogin && isLoginSuccess && allCommonData?.userData?.account_id) {
      redirectToExtension();
    }

    if (isLoginSuccess && allCommonData?.userData?.account_id && allCommonData?.userData?.email) {
      Sentry.setUser({ email: allCommonData?.userData?.email });

      const accountName = getAccountName();
      Sentry.setTag('accountName', accountName);

      sendMemoryToSentryPeriodically();

      if (allCommonData?.userData?.uuid && window.userpilot) {
        window.userpilot?.identify(allCommonData?.userData?.uuid,{
          name: allCommonData?.userData?.name,
          email: allCommonData?.userData?.email,
          created_at: new Date().toISOString(),
        });
      }
    }

  }, [ isLoginSuccess, allCommonData?.userData?.account_id ]);

  useEffect(() => {
    if (isLoginSuccess && (window?.location?.host == 'fold.sandbox.foldhealth.io' ||
   window?.location?.host == 'test5.foldhealth.io')) {
      const jhdScript = document.createElement('script') as any;
      jhdScript.setAttribute('data-jsd-embedded', '');
      jhdScript.setAttribute(
        'data-key',
        '56ce82a0-63ff-46cf-b5dd-7a4ff1da5d0d'
      );
      jhdScript.setAttribute('data-base-url', "window.location.host");
      jhdScript.src = 'embed-1.js';
      document.body.appendChild(jhdScript);
      jhdScript.addEventListener('load', (event: any) => {
        window.document.dispatchEvent(
          new Event('DOMContentLoaded', {
            bubbles: true,
            cancelable: true,
          })
        );
      });

      return () => {
        // clean up the script when the component in unmounted
        document.body.removeChild(jhdScript);
      };
    } else if (isLoginSuccess && (window?.location?.host == 'test2.qa.foldhealth.io')) {
      const fwSettingsScript = document.createElement("script");
    fwSettingsScript.innerHTML = `
      window.fwSettings = {
        'widget_id': 156000000617
      };
      !function() {
        if ("function" != typeof window.FreshworksWidget) {
          var n = function() { n.q.push(arguments) };
          n.q = [], window.FreshworksWidget = n
        }
      }();
    `;
    document.body.appendChild(fwSettingsScript);

    // Inject the second script
    const widgetScript = document.createElement("script");
    widgetScript.src = "https://widget.freshworks.com/widgets/156000000617.js";
    widgetScript.async = true;
    widgetScript.defer = true;
    document.body.appendChild(widgetScript);

    return () => {
      document.body.removeChild(fwSettingsScript);
      document.body.removeChild(widgetScript);
    };
    }
  }, [isLoginSuccess]);

  const onLoginSuccess = (isLoginSuccess: boolean) => {
    setIsLoginSuccess(isLoginSuccess);
    if (isLoginSuccess) {
      LocalStorage.setItem('loginDone', 'true');
    }
  };

  const getAccountName = () => {
    return (
      accountSubdomainInfo?.data?.accountSubdomainMaps[0]?.accountToSubdomain
        ?.name || ''
    );
  };

  const {height, width, scale, fontScale} = useWindowDimensions();

  const getThemeConfig = (accountId: number) => {
    return new Promise((resolve, reject) => {
      const axios = BaseService.getSharedInstance().axios;
      axios
        .post(
          CRM_PUBLIC_BASE_URL + `/getAccountThemes`,
          JSON.stringify({subDomain: getCurrentSubdomain()}),
          {
            headers: {
              'x-hasura-account-id': accountId,
            },
          }
        )
        .then((result) => {
          if (result.data?.accountThemes?.[0]) {
            const accountTheme = result.data?.accountThemes?.[0];
            const tempDefaultTheme = accountTheme?.theme;
            // Bootstrap
            setAllCommonData((prev) => {
              return {
                ...prev,
                accountThemeConfig: accountTheme,
              };
            });

            setThemeCSSVariablesGlobally(tempDefaultTheme);
            const customThemeColors = getAntdBaseTheme(tempDefaultTheme);
            ConfigProvider.config({
              theme: customThemeColors,
            });
            setDefaultTheme(tempDefaultTheme);
            LocalStorage.setItem(
              'accountTheme',
              JSON.stringify(tempDefaultTheme)
            );
            resolve(tempDefaultTheme);
          }
        })
        .catch((err) => {
          handleAPIErrors(err);
          reject(err);
        });
    });
  };

  const hideLoaderOnUserDataFoundWithLoginSuccess = () => {
    setIsLoginSuccess(true);
    setIsFetchingSubdomainInfo(false);
    setShowLoader(false);
  };

  const movedToLoginScreen = () => {
    LocalStorage.getItem('user').then((result) => {
      if (result !== null) {
        LocalStorage.removeItem('consent_Obj');
        LocalStorage.removeItem('loginDone');
        LocalStorage.removeItem('user').then(() => {
          showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
          setIsLoginSuccess(false);
        });
      }
      window.location.reload();
    });
  };

  const getAccountConfigurations = async (data?: any) => {
    const axios = BaseService.getSharedInstance().axios;
    const account_id =
      accountSubdomainInfo?.data?.accountSubdomainMaps[0]?.accountId;
    const account_uuid =
      accountSubdomainInfo?.data?.accountSubdomainMaps[0]?.accountToSubdomain
        ?.uuid;
    if (account_id && account_uuid) {
      const accountConfigData = await axios.get(
        CRM_PUBLIC_BASE_URL + `/account_all_configuration`,
        {
          headers: {
            'account-id':
              accountSubdomainInfo?.data?.accountSubdomainMaps[0]?.accountId,
            'account-uuid':
              accountSubdomainInfo?.data?.accountSubdomainMaps[0]
                ?.accountToSubdomain?.uuid,
          },
        }
      );

      if (accountConfigData?.data?.length) {
        const accountConfigObj = {} as any;
        accountConfigData?.data.forEach((singleConfig: any) => {
          if (singleConfig.code) {
            accountConfigObj[singleConfig.code] = singleConfig;
          }
        });
        setAllCommonData((prev) => {
          return {
            ...prev,
            accountConfigData: accountConfigObj,
          };
        });
      }
    } else {
      LocalStorage.getItem('user').then(async (result) => {
        const userData = JSON.parse(result);
        const account_id = userData?.data?.accounts[0]?.id;
        const account_uuid = userData?.data?.accounts[0]?.uuid;
        const accountConfigData = await axios.get(
          CRM_PUBLIC_BASE_URL + `/account_all_configuration`,
          {
            headers: {
              'account-id': account_id,
              'account-uuid': account_uuid,
            },
          }
        );

        if (accountConfigData?.data?.length) {
          const accountConfigObj = {} as any;
          accountConfigData?.data.forEach((singleConfig: any) => {
            if (singleConfig.code) {
              accountConfigObj[singleConfig.code] = singleConfig;
            }
          });
          setAllCommonData((prev) => {
            return {
              ...prev,
              accountConfigData: accountConfigObj,
            };
          });
        }
      });
    }
  };
  const contentSecurityEnable = () => {
    const baseUri = "base-uri 'self';";
    const scriptSrc = "script-src 'self';";
    const upgradeInsecureRequests = 'upgrade-insecure-requests;';
    const finalContent = baseUri + '\n' + scriptSrc + upgradeInsecureRequests;
    const getMetaTag: any = document.querySelector(
      'meta[name="fold-content-security"]'
    );
    getMetaTag.content = finalContent;
  };

  const getUserPracticeLocations = async () : Promise<string[]> => {
    const axios = BaseService.getSharedInstance().axios;
    const response = await axios.get(CRM_PUBLIC_BASE_URL + `/getUserPracticeLocations`);
    if (response?.data?.userPracticeLocations) {
      const userPracticeLocations = response.data.userPracticeLocations;
      return userPracticeLocations;
    }
    return []
  }

  //initial load
  useEffect(() => {
    if (showNoInternet) {
      return;
    }
    if (isPublicURL) {
      setIsFetchingLoggedInInfo(false);
    } else if (!isLocalStorageFetched) {
      setIsLocalStorageFetched(true);

      LocalStorage.getItem('user')
        .then(async (result) => {
          if (isValidObject(result)) {
            const userData = JSON.parse(result);
            const userRolesString = await LocalStorage.getItem('userRoles');
            const userRoles = JSON.parse(userRolesString);
            const associatedAccountsString = await LocalStorage.getItem('associatedAccounts');
            const userAccountsString = await LocalStorage.getItem('userAccounts');
            const associatedAccounts = JSON.parse(associatedAccountsString);
            const userAccounts = JSON.parse(userAccountsString);
            const accountId = userData?.data?.account_id;
            const accountUuid = userData?.data?.accounts?.[0]?.uuid;
            const userUuid = userData?.data?.uuid;
            const accountLocationUuids = await getUserPracticeLocations();
            if (queryParams?.username) {
              const existingUserSessionEmail = userData?.data?.email;
              const foldAccessToken = userData?.fold_access_token;
              const isTokenExpired = foldAccessToken ? isFoldAccessTokenExpired(foldAccessToken) : false;
              if (existingUserSessionEmail && (queryParams?.username !== existingUserSessionEmail || isTokenExpired)) {
                await LocalStorage.removeItem('user');
                await LocalStorage.removeItem('loginDone');
                await LocalStorage.removeItem('isWorkflowLogin');
                await LocalStorage.removeItem('consent_Obj');
                window.location.reload();
                return;
              }
            }

            setAccountSubdomainInfo({
              data: {
                accountSubdomainMaps: [accountId],
              },
            });
            getAccountConfigurations({...userData});
            setAllCommonData((prev) => {
              return {
                ...prev,
                userData: {
                  ...userData.data,
                  fold_access_token: userData.fold_access_token,
                },
                userMainDataObj: userData,
                userRoles: userRoles,
                associatedAccounts: associatedAccounts,
                userAccounts: userAccounts,
                userAccountLocationUuids: accountLocationUuids
              };
            });
            await getAbility(accountUuid,userUuid,accountLocationUuids)
            accountSubdomainInfo?.data?.accountSubdomainMaps[0];
            if (isValidObject(accountId)) {
              getThemeConfig(accountId)
                .then(() => {
                  hideLoaderOnUserDataFoundWithLoginSuccess();
                })
                .catch(() => {
                  hideLoaderOnUserDataFoundWithLoginSuccess();
                });
              getAccountConfigurations();
            } else {
            }
          }
          setIsFetchingLoggedInInfo(false);
        })
        .catch(() => {
          setIsFetchingLoggedInInfo(false);
        });
    }
  }, []);

  //on change of isFetchingLoggedInInfo/
  useEffect(() => {
    if (showNoInternet) {
      return;
    }
    if (!isFetchingLoggedInInfo && !isLoginSuccess) {
      setIsFetchingSubdomainInfo(true);
      const axios = BaseService.getSharedInstance().axios;
      axios
        .post(
          CRM_PUBLIC_BASE_URL + `/getAccountIdForSubdomain`,
          JSON.stringify({subDomain: getCurrentSubdomain()})
        )
        .then((result) => {
          const accountInfo = result?.data?.accountSubdomainMaps[0];

          if (accountInfo) {
            setAllCommonData((prev) => {
              return {
                ...prev,
                accountSubdomain: accountInfo,
              };
            });
            LocalStorage.setItem('account', JSON.stringify(accountInfo)).then(
              () => {
                setAccountSubdomainInfo(result);
                setIsFetchingSubdomainInfo(false);
              }
            );
          } else {
            showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
          }
        })
        .catch((error) => {
          setIsFetchingSubdomainInfo(false);
          setShowLoader(false);
          handleAPIErrors(error);
        });
    }
  }, [isFetchingLoggedInInfo]);

  //on isFetchingSubdomainInfo change
  useEffect(() => {
    if (showNoInternet) {
      return;
    }
    if (
      !isFetchingLoggedInInfo &&
      !isFetchingSubdomainInfo &&
      accountSubdomainInfo?.data?.accountSubdomainMaps &&
      (accountSubdomainInfo?.data?.accountSubdomainMaps[0]?.accountId ||
        accountSubdomainInfo?.data?.accountSubdomainMaps[0])
    ) {
      getThemeConfig(
        accountSubdomainInfo?.data?.accountSubdomainMaps[0]?.accountId
      )
        .then(() => {
          setShowLoader(false);
        })
        .catch(() => {
          setShowLoader(false);
        });
      getAccountConfigurations();
    } else {
    }
  }, [isFetchingSubdomainInfo]);

  const getUserDetailsMap = (response: any) => {
    const userResponseMap = {
      accessToken: response.accessToken,
      client: response.client,
      data: response.data,
      userRoles: response.userRole || [],
      fold_access_token: response.fold_access_token,
      isMasterAccount: response.accountData?.masterAccountId ? false : true
    };
    return userResponseMap;
  };

  useEffect(() => {
    LocalStorage.getItem('isWorkflowLogin').then((result) => {
      const urlParams = new URLSearchParams(window.location.search);
      if (!result && !isWorkflowUser) {
          const ssoConfig =
            allCommonData.accountConfigData?.SSO_CONFIG?.accountConfigurations;
          if (ssoConfig && ssoConfig.length > 0) {
            setSSOLoginInProgress(true);
            let ssoValue = ssoConfig[0].value;
            if (window.location.host.includes('localhost')) {
              const ssoValueData = JSON.parse(ssoValue);
              ssoValueData.Auth.oauth.redirectSignIn = 'http://localhost:3000';
              ssoValueData.Auth.oauth.redirectSignOut = 'http://localhost:3000';
              ssoValue = JSON.stringify(ssoValueData);
            }
            if (isExtensionLogin) {
              const ssoValueData = JSON.parse(ssoValue);
              ssoValueData.Auth.oauth.redirectSignIn = `${ssoValueData.Auth.oauth.redirectSignIn}/extension`;
              ssoValue = JSON.stringify(ssoValueData);
            }
            if (isStagingEnv) {
              const ssoValueData = JSON.parse(ssoValue);
              ssoValueData.Auth.oauth.redirectSignIn = ssoValueData.Auth.oauth.redirectSignIn.replace('.foldhealth.io', '.staging.foldhealth.io');
              ssoValueData.Auth.oauth.redirectSignOut = ssoValueData.Auth.oauth.redirectSignOut.replace('.foldhealth.io', '.staging.foldhealth.io');
              ssoValue = JSON.stringify(ssoValueData);
            }
            Amplify.configure({
              Auth: JSON.parse(ssoValue)?.Auth,
            });

            LocalStorage.getItem('loginDone').then((result) => {
              if (result === null && !urlParams.get('code')) {
                // @ts-ignore
                Auth.federatedSignIn({provider: JSON.parse(ssoValue).provider});
              } else if (result?.length && result === 'true') {
                getToken().then((userToken) => {
                  const token = userToken?.getIdToken()?.getJwtToken();
                  if (!token?.length) {
                    LocalStorage.removeItem('loginDone');
                    window.location.reload();
                  }
                });
              }
            });
          }
        } else if (isWorkflowUser){
          LocalStorage.setItem('isWorkflowLogin', 'true').then(() => {
            let ssoValue = allCommonData.accountConfigData?.WORKFLOW_SSO_CONFIG?.defaultValue;
            if (ssoValue) {
               setSSOLoginInProgress(true);
              if (window.location.host.includes('localhost')) {
                const ssoValueData = JSON.parse(ssoValue);
                ssoValueData.Auth.oauth.redirectSignIn = 'http://localhost:3000/cs';
                ssoValueData.Auth.oauth.redirectSignOut = 'http://localhost:3000/cs';
                ssoValue = JSON.stringify(ssoValueData);
              } else {
                const ssoValueData = JSON.parse(ssoValue);
                ssoValueData.Auth.oauth.redirectSignIn = `https://${getCurrentSubdomain()}/cs`;
                ssoValueData.Auth.oauth.redirectSignOut = `https://${getCurrentSubdomain()}/cs`;
                ssoValue = JSON.stringify(ssoValueData);
              }
              if (isExtensionLogin) {
                const ssoValueData = JSON.parse(ssoValue);
                ssoValueData.Auth.oauth.redirectSignIn = `${ssoValueData.Auth.oauth.redirectSignIn}/extension`;
                ssoValueData.Auth.oauth.redirectSignOut = `${ssoValueData.Auth.oauth.redirectSignIn}/extension`;
                ssoValue = JSON.stringify(ssoValueData);
              }
              if (isStagingEnv) {
                const ssoValueData = JSON.parse(ssoValue);
                ssoValueData.Auth.oauth.redirectSignIn = ssoValueData.Auth.oauth.redirectSignIn.replace('.foldhealth.io', '.staging.foldhealth.io');
                ssoValueData.Auth.oauth.redirectSignOut = ssoValueData.Auth.oauth.redirectSignOut.replace('.foldhealth.io', '.staging.foldhealth.io');
                ssoValue = JSON.stringify(ssoValueData);
              }
              Amplify.configure({
                Auth: JSON.parse(ssoValue)?.Auth,
              });
              LocalStorage.getItem('loginDone').then((result) => {
                if (result === null && !urlParams.get('code')) {
                  // @ts-ignore
                  Auth.federatedSignIn({provider: JSON.parse(ssoValue).provider, customState: 'workflow'});
                } else if (result?.length && result === 'true') {
                  getToken().then((userToken) => {
                    const token = userToken?.getIdToken()?.getJwtToken();
                    if (!token?.length) {
                      LocalStorage.removeItem('loginDone');
                      window.location.reload();
                    }
                  });
                }
              });
            }
          });
        }

    })
  }, [allCommonData.accountConfigData?.SSO_CONFIG?.accountConfigurations,
      allCommonData.accountConfigData?.WORKFLOW_SSO_CONFIG?.defaultValue]);

  const ssoService = async (token: any) => {
    setSSOLoginInProgress(true);
    // if (!window.location.href.includes('/login')) {
    //   window.location.href = window.location.origin + '/#/';
    // }
    LocalStorage.removeItem(LOCAL_STORAGE_CONST?.lockScreen);
    LocalStorage.getItem('account').then((result) => {
      setIsLoginAPIInprogress(true);
      const axios = BaseService.getSharedInstance().axios;
      const body = {token: token, user: isWorkflowUser? 'workflow': 'user'}
      axios
        .post(
          `/crm-nest/platform/sso/sign_in`,
          JSON.stringify(body),
          {
            headers: {
              accountId: JSON.parse(result)?.accountId,
              fold_access_token: '',
              ...(isExtensionLogin && { 'x-app-name': HeaderAppName.foldGrowSidecar } ),
            },
          }
        )
        .then(async (response) => {
          setIsLoginAPIInprogress(false);
          if (response.status == 200 && isValidObject(response.data)) {
            if (response.data.hashedOtp) {
              setIsLoginSuccess(false);
              setIsLoginAPIInprogress(false);
              setShowLoader(false);
              LocalStorage.removeItem('loginDone');
              //show don't have access page
              setErrorStateData((prev) => {
                return {
                  ...prev,
                  showUnauthorizedAccessError: true,
                };
              });
            } else {
              const spreeResponseMap = {
                access_token: response.data.spreeAccessToken,
                token_type: 'Bearer',
                x_spree_token: response.data.spreeApiKey,
              };
              const associatedAccounts = JSON.parse(response?.data?.data?.associatedAccounts || "[]")
              const userAccounts = JSON.parse(response?.data?.data?.userAccounts || "[]")
              const associatedAccountsString = response?.data?.data?.associatedAccounts
              const userAccountsString  = response?.data?.data?.userAccounts
              response.data.userRole = getNotDeleteRoles(response.data.userRole)
              setAllCommonData((prev) => {
                return {
                  ...prev,
                  userData: {
                    ...response.data.data,
                    fold_access_token: response.data.fold_access_token,
                  },
                  userMainDataObj: {
                    ...response.data,
                    isMasterAccount: response.data.accountData?.masterAccountId ? false : true
                  },
                  userRoles: response.data.userRole || [],
                  associatedAccounts: associatedAccounts,
                  userAccounts: userAccounts,
                };
              });
              delete response.data.data.associatedAccounts
              delete response.data.data.userAccounts
              const userResponseMap = getUserDetailsMap(response.data);
              const accountUuid = response.data?.accountData?.uuid
              const userUuid = response.data?.data?.uuid;
              const encryptionKey = response?.data?.data?.encryptionKey;
              const userRoles = userResponseMap.userRoles || [];
              delete userResponseMap.userRoles;
              const userRoleString = JSON.stringify(userRoles);
              const resString = JSON.stringify(userResponseMap);
              const spreeResString = JSON.stringify(spreeResponseMap);
              const accountLocationUuids = getAccountLocationUuidFromRolesResp(userRoles);
              setAllCommonData((prev) => {
                return {
                  ...prev,
                  userAccountLocationUuids: accountLocationUuids
                };
              });
              const accountLocationUuidsString = JSON.stringify(accountLocationUuids)
              await getAbility(accountUuid,userUuid, accountLocationUuids)
              await LocalStorage.setItem('user', resString);
              onLoginSuccess(true);
              await LocalStorage.setItem('userRoles', userRoleString);
              await LocalStorage.setItem('userAccountLocationUuids', accountLocationUuidsString);
              await LocalStorage.setItem('associatedAccounts', associatedAccountsString || "[]");
              await LocalStorage.setItem('userAccounts', userAccountsString || "[]");
              if (!isExtensionLogin) {
                window.location.href = window.location.origin + '/#/';
              }

              LocalStorage.setItem('commerceUserToken', spreeResString).then(
                () => {
                  onLoginSuccess(true);
                }
              );
              // window.freshpaint.identify(userResponseMap?.data?.email);
            }
          } else {
            onLoginSuccess(true);
            showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
          }
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            //unauthorized user
            setErrorStateData((prev) => {
              return {
                ...prev,
                showUnauthorizedAccessError: true,
              };
            });
          } else {
            setErrorStateData((prev) => {
              return {
                ...prev,
                showSSOError: true,
              };
            });
          }
          onLoginSuccess(false);
          setIsLoginAPIInprogress(false);
          setShowLoader(false);
          LocalStorage.removeItem('loginDone');
        });
    });
    setTimeout(() => {
      setSSOLoginInProgress(false);
    }, 1000);
  };

  const loginService = async (username: string, password: string) => {
    LocalStorage.removeItem(LOCAL_STORAGE_CONST?.lockScreen);
    setIsLoginAPIInprogress(true);
    const axios = BaseService.getSharedInstance().axios;
    axios
      .post(
        `/crm-nest/platform/sign_in`,
        JSON.stringify({email: username, password: password}),
        {
          headers: {
            accountId:
              accountSubdomainInfo?.data?.accountSubdomainMaps?.[0].accountId,
            fold_access_token: '',
            ...(isExtensionLogin && { 'x-app-name': HeaderAppName.foldGrowSidecar } ),
          },
        }
      )
      .then(async (response) => {
        setIsLoginAPIInprogress(false);
        if (response.status == 200 && isValidObject(response.data)) {
          if (response.data.hashedOtp) {
            setResetPasswordData(response.data);
            setIsLoginSuccess(false);
          } else {
            LocalStorage.setItem('isWorkflowLogin', 'true');
            const spreeResponseMap = {
              access_token: response.data.spreeAccessToken,
              token_type: 'Bearer',
              x_spree_token: response.data.spreeApiKey,
            };
            const associatedAccounts = JSON.parse(response?.data?.data?.associatedAccounts || "[]")
            const userAccounts = JSON.parse(response?.data?.data?.userAccounts || "[]")
            const associatedAccountsString = response?.data?.data?.associatedAccounts
            const userAccountsString  = response?.data?.data?.userAccounts
            response.data.userRole = getNotDeleteRoles(response.data.userRole)
            setAllCommonData((prev) => {
              return {
                ...prev,
                userData: {
                  ...response.data.data,
                  fold_access_token: response.data.fold_access_token,
                },
                userMainDataObj: {
                  ...response.data,
                  isMasterAccount: response.data.accountData?.masterAccountId ? false : true
                },
                userRoles: response.data.userRole || [],
                associatedAccounts: associatedAccounts,
                userAccounts: userAccounts
              };
            });
            delete response.data.data.associatedAccounts
            delete response.data.data.userAccounts
            // const commonService = CommonService.getCommonServiceInstance();
            // const axiosService = commonService.axiosService;
            const userResponseMap = getUserDetailsMap(response.data);
            const accountUuid = response.data?.accountData?.uuid
            const userUuid = response.data?.data?.uuid;
            const encryptionKey = response?.data?.data?.encryptionKey;
            const email = response.data?.data?.email;
            const userRoles = userResponseMap.userRoles || [];
            delete userResponseMap.userRoles;
            const userRoleString = JSON.stringify(userRoles);
            const resString = JSON.stringify(userResponseMap);
            const spreeResString = JSON.stringify(spreeResponseMap);
            const accountLocationUuids = getAccountLocationUuidFromRolesResp(userRoles)
            setAllCommonData((prev) => {
              return {
                ...prev,
                userAccountLocationUuids: accountLocationUuids
              };
            });
            const accountLocationUuidsString = JSON.stringify(accountLocationUuids)
            LocalStorage.setItem('user', resString).then(async () => {
              onLoginSuccess(true);
            });
            await getAbility(accountUuid,userUuid, accountLocationUuids)
            if (!isExtensionLogin) {
              window.location.href = window.location.origin + '/#/'
            }
            await LocalStorage.setItem('userRoles', userRoleString);
            await LocalStorage.setItem('userAccountLocationUuids', accountLocationUuidsString);
            await LocalStorage.setItem('associatedAccounts', associatedAccountsString || "[]");
            await LocalStorage.setItem('userAccounts', userAccountsString || "[]");
            LocalStorage.setItem('commerceUserToken', spreeResString).then(
              () => {
                onLoginSuccess(true);
              }
            );
            // window.freshpaint.identify(userResponseMap?.data?.email);
          }
        } else {
          onLoginSuccess(true);
          showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
        }
      })
      .catch((err) => {
        const errMessage = isString(err.response.data) ? err.response.data : '';
        const toastTitle = errMessage || `Invalid credentials!`;
        showToast(toast, toastTitle, ToastType.error);
        setShowLoader(false);
        onLoginSuccess(false);
        setIsLoginAPIInprogress(false);
      });
  };

  const logoutFromAmplifyAndRelogin = () => {
    const ssoConfig =
      allCommonData.accountConfigData?.SSO_CONFIG?.accountConfigurations;
      const isCsUser = currentUserRolesList.includes(TEAM_MEMBERS_ROLES_CODE.CUSTOMER_SUCCESS);
    if (ssoConfig && ssoConfig.length > 0) {
      const ssoValue = ssoConfig[0].value;
      const ssoValueData = JSON.parse(ssoValue);
      const redirectSignOutUrl = window.location.host.includes('localhost')
        ? 'http://localhost:3000'
        : ssoValueData.Auth.oauth.redirectSignOut;
      const domain = ssoValueData.Auth.oauth.domain;
      const clientId = ssoValueData.Auth.userPoolWebClientId;
      const providerId = JSON.parse(ssoValue).provider;
      const ssoCognitoLogoutUrl = `https://${domain}/logout?response_type=code&client_id=${clientId}&redirect_uri=${redirectSignOutUrl}&logout_uri=${redirectSignOutUrl}&scope=openid+profile+email&identity_provider=${providerId}`;
      window.location.href = ssoCognitoLogoutUrl;
      Auth.signOut();
      LocalStorage.removeItem('loginDone');
      LocalStorage.removeItem('isWorkflowLogin');
      LocalStorage.removeItem('consent_Obj');
      LocalStorage.removeItem('user');
    } else if(isCsUser){
          const ssoValue = allCommonData.accountConfigData?.WORKFLOW_SSO_CONFIG?.defaultValue;
          const ssoValueData = JSON.parse(ssoValue!);
          const domain = ssoValueData.Auth.oauth.domain;
          const clientId = ssoValueData.Auth.userPoolWebClientId;
          const providerId = ssoValueData.provider;

          const redirectSignOutUrl = window.location.host.includes('localhost')
          ? 'http://localhost:3000/cs'
          : `https://${getCurrentSubdomain()}/cs`;


          const ssoCognitoLogoutUrl = `https://${domain}/logout?response_type=code&client_id=${clientId}&identity_provider=${providerId}&redirect_uri=${redirectSignOutUrl}&logout_uri=${redirectSignOutUrl}`;
          const ssoAzureLogoutUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURIComponent(ssoCognitoLogoutUrl)}`
          window.location.href = ssoAzureLogoutUrl;

          LocalStorage.removeItem('loginDone');
          LocalStorage.removeItem('isWorkflowLogin');
          LocalStorage.removeItem('consent_Obj');
          LocalStorage.removeItem('user');
      }
  };

  const getMainPage = (): JSX.Element => {
    if (isPublicURL) {
      const page = getPublicPage(location, searchParams);
      if (page) {
        return (
          <CommonDataContext.Provider value={allCommonData}>
            {page}
          </CommonDataContext.Provider>
        );
      }
    }

    if (errorStateData.showSSOError) {
      return (
        <SsoErrorPage
          currentSession={token}
          ssoLoginData={{
            account: {
              accountId: -1,
              accountConfigurations: {
                configuration: {} as RoleCategory,
                value: allCommonData.accountConfigData?.SSO_CONFIG
                ?.accountConfigurations?.[0]?.value || '',
              }
            },
          }}
          onSsoLogin={logoutFromAmplifyAndRelogin}
        />
      );
    }

    if (errorStateData.showUnauthorizedAccessError) {
      return (
        <SsoAccessErrorPage
          currentSession={token}
          ssoLoginData={{
            account: {
              accountId: -1,
              accountConfigurations: {
                configuration: {} as RoleCategory,
                value: !isWorkflowUser && allCommonData.accountConfigData?.SSO_CONFIG
                ?.accountConfigurations?.[0]?.value || allCommonData.accountConfigData?.WORKFLOW_SSO_CONFIG?.defaultValue || '',
              }
            },
          }}
          onSsoLogin={logoutFromAmplifyAndRelogin}
        />
      );
    }

    if (!isLoginSuccess && resetPasswordData.hashedOtp) {
      return (
        <SetPassword
          data={resetPasswordData}
          onSuccess={() => {
            setIsLoginSuccess(true);
            setResetPasswordData({});
          }}
        />
      );
    }

    if (forgotPasswordData.isBtnClicked) {
      return (
        <ForgotPassword
          email={forgotPasswordData.email}
          onLoginClick={() => {
            setForgotPasswordData((prev) => {
              return {
                ...prev,
                email: '',
                isBtnClicked: false,
              };
            });
          }}
          onSuccess={() => {
            setForgotPasswordData((prev) => {
              return {
                ...prev,
                isBtnClicked: false,
              }
            })
            setIsLoginSuccess(true);
          }}
        />
      );
    }

    if (isLoginSuccess && allCommonData?.userData?.account_id && isAbiltyLoad) {
      return (
        <CommonDataContext.Provider value={allCommonData}>
            <BusinessStudio />
        </CommonDataContext.Provider>
      );
    } else if (!isSsoLoginInprogress) {
      return (
        <Login
          defaultUserName={queryParams?.username || ''}
          onForgotPasswordBtnClick={(userEmail) => {
            setForgotPasswordData((prev) => {
              return {
                ...prev,
                isBtnClicked: true,
                email: userEmail || '',
              };
            });
          }}
          onLoginAction={loginService}
          getAccountName={getAccountName()}
        />
      );
    } else {
      return <></>;
    }
  };

  const errorLink = onError((data) => {
    const {graphQLErrors, networkError} = data;
    if (graphQLErrors)
      graphQLErrors.forEach(({message, locations, path}) =>{});

    if (networkError) {
      const serverError = networkError as ServerError;
      const networkAnyObject: any = networkError;
      if (
        serverError?.result?.extensions?.statusCode === 401 ||
        networkAnyObject?.response?.status === 401
      ) {
        //Added this apolloClient handling
        movedToLoginScreen();
      }
    }
  });

  if (showNoInternet) {
    return (
      <View style={styles.bodyContainer}>
        <NoInternet />
      </View>
    );
  }
  return (
    <ApolloProvider client={ApolloClient.getClient(errorLink)}>
      <>
        <style type="text/css">{Icons.iconsStyle}</style>
        <ConfigProvider>
          <NativeBaseProvider
            theme={getNativeBaseTheme(defaultTheme)}
            config={getNativeBaseConfig}
          >
            <View style={{height: '100vh'}}>
              <SmallScreenAlert />
              {showLoader ? (
                <LoadingSpinner />
              ) : (
                <View>
                  {getMainPage()}
                  {isLoginAPIInprogress && <LoadingSpinner />}
                </View>
              )}
            </View>
          </NativeBaseProvider>
        </ConfigProvider>
      </>
    </ApolloProvider>
  );
};

export default MainScreen;
