import {ColumnsType} from 'antd/lib/table';
import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
  View,
  VStack,
} from 'native-base';
import { Select } from 'antd';
import {IntlShape} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {v4 as uuidv4} from 'uuid';
import {ALL_LOCATION_CODE, DATE_FORMATS} from '../../../../constants';
import {
  APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES,
  DATE_TIME_DURATION_CODES,
  LOCATION_TYPE_CODES,
  MLOV_CATEGORY,
  MLOV_CODES,
} from '../../../../constants/MlovConst';
import {IAbility, IEhrCapability, IMlov, IUser} from '../../../../Interfaces';
import {Colors} from '../../../../styles';
import {testID, TestIdentifiers} from '../../../../testUtils';
import {convertAvailabilityTimeToStringTime, convertDate, getDateStrFromFormat, isCurrentDateInFutureComparedToOther, isSameDate} from '../../../../utils/DateUtils';
import {
  getMlovIdCodeObj,
  getMlovIdFromCode,
  getMlovIdFromCodeAndCategory,
  getMlovListFromCategory,
  getMlovObjectFromCode,
  getMlovValueFromIdAndCategory,
} from '../../../../utils/mlovUtils';
import { APPOINTMENT_COLOR_INDICATOR_SIZE, DefaultFallbackAppointmentTypeColor, MAX_WITHIN_DAYS_LIMIT } from './AppointmentTypeConst';
import { IAvailableEhr } from '../../../PersonOmniView/MiddleContainer/interfaces';
import {AppointmentTypeAction} from './AppointmentTypeTopBar';
import {
  CombineType,
  IAppointmentCardProperties,
  IAppointmentEmployer,
  IAppointmentFormType,
  IAppointmentPopulationGroups,
  IAppointmentType,
  IAppointmentTypeAvailability,
  IAppointmentTypeAvailabilityApiResponse,
  IAppointmentTypeAvailabilitySlot,
  IAppointmentTypePoolUsers,
  IAppointmentTypeUserPool,
  ICustomAppointmentType,
  ILocalAppointmentTypeRoles,
  IPrevRoleData,
  IRoleData,
  IUserGroupData,
} from './Interfaces';
import { getLighterShadeWithoutOpacity } from '../../../../utils/ColorUtils';
import { ICommonData } from '../../../../context/CommonDataContext';
import { generateUUID, isVirtualLocationDisabled, isVirtualLocationEnabledInAvailability } from '../../../../utils/commonUtils';
import { IUserSettingsByCode } from '../../../../services/UserSettings/interfaces';
import { VIRTUAL_LOCATION_CODE } from '../PracticeAvailabilityNew/PracticeAvailability';
import { cloneDeep } from 'lodash';
import DoubleUserSvg from '../../../common/Svg/DoubleUserSvg';
import { memberLimitData } from './AppointmentTypeUtils';
import { APPOINTMENT_CUSTOM_FIELD_TYPES, APPOINTMENT_TYPE_INTERVAL_TYPES, AppointmentApplicableBy, AppointmentAvailabilityCode, SelectMembersBy, VALUE_TYPE_CODES } from './constants';
import Feather from 'react-native-vector-icons/Feather';
import { formatAppointmentTypes } from '../../../common/CalendarWidget/BookingWorkflows/BookAppointment/BookAppointmentHelper';
import {IUserRoleMlov} from '../../Contacts/TeamMembers/AddEditUser/interfaces';
import {getEhrConfig} from '../../../../utils/capabilityUtils';
import { DisplayText } from '../../../common/DisplayText/DisplayText';

export const DEFAULT_APPOINTMENT_USER_POOL_NAME = 'Appointment Type Pool';
export const DEFAULT_APPOINTMENT_RESTRICATED_USER_POOL_NAME = 'Appointment Type Restricted User Pool';

export const generateUniqueKey = (): string => {
  return uuidv4();
};

export const isDateError = (formData: IAppointmentFormType): boolean => {
  return (
    formData.intervalType === 'dateRange' &&
    !formData.beginDate &&
    !formData.endDate
  );
};

export const isParticipantError = (formData: IAppointmentFormType): boolean => {
  let isError = formData.appointmentTypeGroup.length === 0;

  (formData?.appointmentTypeGroup || []).forEach((participant: IUserGroupData) => {
    isError =
      isError ||
      !(
        participant.duration &&
        participant.duration > 0 &&
        participant.user &&
        participant.user.key
      );
  });

  return isError;
};

const getShortNameError = (shortName: string | undefined) => {
  if (!shortName || (shortName?.length && shortName?.length > 4)) {
    if (!shortName) {
      return 'Short name is required';
    } else {
      return 'Short name length should be maximum 4 character';
    }
  }
  return '';
};

export const getUpdatedErrorMessages = (
  formData: IAppointmentFormType,
  type: AppointmentTypeAction,
  apptTypeGroupNames?: ILocalAppointmentTypeRoles[],
  appointmentCapability?: IAbility,
  ehrConfig?: IAvailableEhr,
  mlovs?: IMlov[],
  extraConfigs?: {
    isMultiTenancyEnabled: boolean;
  }
): any => {
  const {isMultiTenancyEnabled} = extraConfigs || {};
  let eventNameError = !formData.eventName ? 'Event name is required' : '';
  const maxCharLimit = appointmentCapability?.keyAllowedOperations?.appointmentType?.maxCharLimit;
  const locationGroupError = isMultiTenancyEnabled && !formData.locationGroupId ? 'EHR Instance is required': '';
  if (type !== AppointmentTypeAction.groupSessionAppointment && maxCharLimit && formData.eventName.length > maxCharLimit) {
    eventNameError = `Maximum ${maxCharLimit} characters are allowed`
  }
  let roleError = '';
  if (
    type === AppointmentTypeAction.singleAppointment &&
    apptTypeGroupNames &&
    apptTypeGroupNames.length === 0 &&
    [
      AppointmentAvailabilityCode.ROLE,
      AppointmentAvailabilityCode.CARE_TEAM,
    ].includes(formData.availabilityTypeCode as AppointmentAvailabilityCode)
  ) {
    roleError =
      formData.availabilityTypeCode == AppointmentAvailabilityCode.ROLE
        ? 'User role is required'
        : 'Care team primary role is required';
  } else if (
    type === AppointmentTypeAction.groupSessionAppointment &&
    apptTypeGroupNames &&
    apptTypeGroupNames.length === 0 &&
    [AppointmentAvailabilityCode.ROLE].includes(
      formData.availabilityTypeCode as AppointmentAvailabilityCode
    )
  ) {
    roleError =
      formData.availabilityTypeCode == AppointmentAvailabilityCode.ROLE
        ? 'User role is required' : '';
  }
  let providerError = '';
  if (
    (type === AppointmentTypeAction.singleAppointment || type === AppointmentTypeAction.groupSessionAppointment) &&
    [
      AppointmentAvailabilityCode.PROVIDER
    ].includes(formData.availabilityTypeCode as AppointmentAvailabilityCode) &&
    !formData.providers.primary.length
  ) {
    providerError = 'Primary provider is required';
  }

  let slotOffsetLimitError = '';

  if(formData?.intervalType !== APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME && mlovs){
    const isMaxWithinDays = validateMaxWithinLimit(formData, mlovs);
    if(isMaxWithinDays){
      slotOffsetLimitError = `Slot within limit should not be greater than ${MAX_WITHIN_DAYS_LIMIT} days.`
    }

    const isSlotOffsetLimitInvalid = validateAppointmentSlotFields(formData, mlovs);
    if(isSlotOffsetLimitInvalid){
      slotOffsetLimitError = 'Slot advance scheduling limit should be less than the scheduling window.'
    }
  }

  return {
    type: !type?.length ? 'Appointment category is required' : '',
    showErrors: true,
    eventName: eventNameError,
    locationGroupError: isMultiTenancyEnabled ? locationGroupError : '',
    duration:
      type === AppointmentTypeAction.singleAppointment && !formData.duration
        ? 'Duration is required'
        : '',
    locationTypeId: !formData.locationTypeId.length
      ? 'Location is required'
      : '',
    memberLimit:
      type === AppointmentTypeAction.groupSessionAppointment && !formData.memberLimit
    ? 'Member Limit is required'
    : '',
    date: isDateError(formData) ? 'Start date and end date is required' : '',
    participants:
      isParticipantError(formData) &&
      type === AppointmentTypeAction.groupAppointment
        ? 'Please fill valid data for all participants'
        : '',
    roleId: roleError,
    provider: providerError,
    shortName: ehrConfig?.isAthena && type !== AppointmentTypeAction.groupSessionAppointment ? getShortNameError(formData?.shortName) : '',
    slotOffsetLimitError: slotOffsetLimitError
  };
};

export const isInValidForm = (
  formData: IAppointmentFormType,
  apptTypeGroupNames: ILocalAppointmentTypeRoles[],
  type: AppointmentTypeAction,
  ehrConfig: any,
  mlovs: IMlov[],
  appointmentCapability?: IAbility,
): boolean => {
  const maxCharLimit = appointmentCapability?.keyAllowedOperations?.appointmentType?.maxCharLimit;
  let isInvalidTasks = false;
  let isInvalidShortName = false;
  if (formData.currentTasks?.length) {
    for (const task of formData.currentTasks) {
      if (task.isDeleted) {
        continue;
      }
      if (!task.title?.trim?.()) {
        isInvalidTasks = true;
        break;
      }
    }
  }
  if (ehrConfig?.isAthena && type !== AppointmentTypeAction.groupSessionAppointment) {
    isInvalidShortName = !formData.shortName || !formData.shortName?.trim()?.length || formData.shortName?.trim()?.length > 4;
  }
  let isRoleInvalid =
    type === AppointmentTypeAction.singleAppointment &&
    [
      AppointmentAvailabilityCode.ROLE,
      AppointmentAvailabilityCode.CARE_TEAM,
    ].includes(formData.availabilityTypeCode as AppointmentAvailabilityCode) &&
    apptTypeGroupNames &&
    apptTypeGroupNames.length === 0;

  if (type === AppointmentTypeAction.groupSessionAppointment) {
    isRoleInvalid =
      [AppointmentAvailabilityCode.ROLE].includes(
        formData.availabilityTypeCode as AppointmentAvailabilityCode
      ) &&
      apptTypeGroupNames &&
      apptTypeGroupNames.length === 0;
  }

  const isProviderInvalid =
    (type === AppointmentTypeAction.singleAppointment ||
      type === AppointmentTypeAction.groupSessionAppointment) &&
    [AppointmentAvailabilityCode.PROVIDER].includes(
      formData.availabilityTypeCode as AppointmentAvailabilityCode
    ) &&
    !formData.providers.primary.length;

  const isMemberLimitInvalid =  type === AppointmentTypeAction.groupSessionAppointment && !formData.memberLimit;
  const isDurationInvalid =  type === AppointmentTypeAction.groupSessionAppointment && !formData.duration;

  let isSlotOffsetLimitInvalid = false;

  if(formData?.intervalType !== APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME){
    isSlotOffsetLimitInvalid = validateMaxWithinLimit(formData, mlovs);
    isSlotOffsetLimitInvalid = validateAppointmentSlotFields(formData, mlovs);
  }

  return (
    isInvalidShortName ||
    isInvalidTasks ||
    !formData.eventName ||
    (maxCharLimit && type === AppointmentTypeAction.singleAppointment && formData.eventName.length > maxCharLimit) ||
    (type === AppointmentTypeAction.singleAppointment && !formData.duration) ||
    !formData.locationTypeId.length ||
    isDateError(formData) ||
    (type === AppointmentTypeAction.groupAppointment &&
      isParticipantError(formData)) ||
    isRoleInvalid ||
    isProviderInvalid ||
    isMemberLimitInvalid ||
    isDurationInvalid ||
    isSlotOffsetLimitInvalid
  );
};

export const getColumns = (args:{
  intl: IntlShape,
  onEdit: (data: IAppointmentType) => void,
  onDelete: (data: IAppointmentType) => void,
  mlovData: ICommonData,
  ehrConfig?: IAvailableEhr,
  userRoles?: IUserRoleMlov[],
  hiddenColumnsKeysMap?: {
    [key: string]: boolean;
  },
  onLocationFilter?: (selectedLocations: string[]) => void,
  selectedLocations?: string[],
  filteredLocationGroups?: any[],
}): ColumnsType<any> => {
  const {mlovData,onEdit,intl,onDelete,ehrConfig, userRoles, selectedLocations, filteredLocationGroups} = args;
  const locationTypes = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.SCHEDULE_LOCATION_TYPE
  );

  const columns:any[] = [
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='name'
        />
      ),
      key: 'eventName',
      width: '26%',
      fixed: 'left',
      render: (data: IAppointmentType) => {
        const apppointmentColor = data?.appointmentCardProperties?.bgColorPrimary || DefaultFallbackAppointmentTypeColor;
        const isGroupAppointment = !!Object.keys(data?.memberLimit || {})?.length;
        return (
          <HStack alignItems="center" flex={1}>
            <Box
              bg={apppointmentColor}
              rounded="full"
              h={APPOINTMENT_COLOR_INDICATOR_SIZE}
              w={APPOINTMENT_COLOR_INDICATOR_SIZE}
            />
            <Feather
              style={{marginHorizontal: 20}}
              name={isGroupAppointment ? 'users' : 'user'}
              size={20}
              color={Colors.Custom.Gray500}
            />
            <VStack flex={0.9} mt={-1}>
              <HStack alignItems="center" flexWrap={'wrap'} flex={1}>
                <Text color={Colors.FoldPixel.GRAY400}>{data.eventName}</Text>
              </HStack>
              {isGroupAppointment && (
                <HStack>
                  <Text fontWeight={200} fontSize="xs" color="gray.300">
                    {memberLimitData(data.memberLimit)}
                  </Text>
                </HStack>
              )}

              <Text
                numberOfLines={4}
                fontWeight={200}
                fontSize="xs"
                color="gray.500"
              >
                {data.description}
              </Text>
            </VStack>
          </HStack>
        );
      },
      // get render() {
      //   return this._render;
      // },
      // set render(value) {
      //   this._render = value;
      // },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='duration'
        />
      ),
      key: 'duration',
      width: '10%',
      align: 'center',
      render: (data: IAppointmentType) => {
        const isGroupAppointment =
          data.categoryId ===
          getMlovIdFromCodeAndCategory(
            MLOV_CODES.GROUP_APPOINTMENT,
            MLOV_CATEGORY.APPOINTMENT_TYPE_CATEGORY,
            true
          );

        if (data?.appointmentTypeGroup && isGroupAppointment) {
          return (
            <View>
              {(data?.appointmentTypeGroup || []).map((group: IUserGroupData, index) => {
                return <Text>{group.duration} min</Text>;
              })}
            </View>
          );
        } else {
          if (
            data?.appointmentTypeGroup &&
            data?.appointmentTypeGroup.length > 0 &&
            data?.appointmentTypeGroup?.[0].roleId
          ) {
            return <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>{data.duration} min</Text>;
          }
          return <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>{data.duration} min</Text>;
        }
      },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='roles'
        />
      ),
      key: 'roles',
      width: '14%',
      render: (data: IAppointmentType) => {
        if (
          data?.appointmentTypeGroup &&
          data?.appointmentTypeGroup.length > 0
        ) {
          const roles = data.appointmentTypeGroup.map(
            (group: IUserGroupData) => {
              return userRoles?.find((userRole)=> userRole.id === group.accountRoleId)?.value
            }
          );

          return roles.length > 1 ? (
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
              {roles[0]}
              <Tooltip
                label={roles.slice(1).join(', ')}
                placement="top"
                w={200}
                backgroundColor="white"
                _text={{
                  color: 'gray.500',
                }}
              >
                <Text color={Colors.Custom.PrimaryColor}>
                  {` + ${roles.length - 1} more`}
                </Text>
              </Tooltip>
            </Text>
          ) : (
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>{roles[0]}</Text>
          );
        }
      },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='locationType'
        />
      ),
      key: 'locationType',
      width: '20%',
      render: (data: IAppointmentType) => {
        const locationData = getSelectedLocationTypeIds(
          data.locationTypeId,
          data.isPatientFacingLocationType || false,
          locationTypes
        );
        const locationNameList = locationData.map(id => locationTypes.find(location => location.id === id)?.value)
        return locationNameList.length > 1 ? (
          <Tooltip
            label={locationNameList.slice(1).join(', ')}
            placement="top"
            w={200}
            backgroundColor="white"
            _text={{
              color: 'gray.500',
            }}
          >
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
              {locationNameList[0]}
              <Text color={Colors.Custom.PrimaryColor}>
                {` + ${locationNameList.length - 1} more`}
              </Text>
            </Text>
          </Tooltip>
        ) : (
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>{locationNameList[0]}</Text>
        );
      }
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='bookingSpan'
        />
      ),
      key: 'bookingSpan',
      width: '15%',
      render: (data: any) => {
        let bookingSpan = intl.formatMessage({id: 'indefinitelyIntoTheFuture'});
        if (data.beginDate && data.endDate) {
          const beginDate = getDateStrFromFormat(
            data.beginDate,
            DATE_FORMATS.DISPLAY_DATE_FORMAT
          );
          const endDate = getDateStrFromFormat(
            data.endDate,
            DATE_FORMATS.DISPLAY_DATE_FORMAT
          );
          bookingSpan =
            intl.formatMessage({id: 'from'}) +
            '  ' +
            beginDate +
            '  ' +
            intl.formatMessage({id: 'to'}) +
            '  ' +
            endDate;
        } else if (data.bookWithinDays || data.bookFromDays) {
          if (data.bookWithinDays) {
            bookingSpan = `${data.bookWithinSpanText} ${intl?.formatMessage({id: 'intoTheFuture'})}`;
          }
          if (data.bookFromDays) {
            bookingSpan = `${bookingSpan} ${intl.formatMessage({id: 'and'})} ${
              data.bookFromSpanText
            } ${intl?.formatMessage({id: 'inAdvance'})}`;
          }
        }
        return <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>{bookingSpan}</Text>;
      },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='eHRInstance'
        />
      ),
      key: 'locationGroup',
      width: '10%',
      filterDropdown: ({ 
        setSelectedKeys, 
        selectedKeys, 
        confirm, 
        clearFilters 
      }: {
        setSelectedKeys: (keys: string[]) => void;
        selectedKeys: string[];
        confirm: () => void;
        clearFilters: () => void;
      }) => (
        <Box p={3} minWidth={200}>
          <Select
            mode="multiple"
            placeholder={intl.formatMessage({id: 'selectEhrInstances'})}
            value={selectedKeys}
            onChange={(values: string[]) => {
              setSelectedKeys(values);
              confirm();
              args.onLocationFilter?.(values);
            }}
            style={{ width: '100%', marginBottom: 8 }}
            optionFilterProp="children"
          >
            {filteredLocationGroups?.map((location: any) => (
              <Select.Option key={location.code} value={location.code}>
                {location.display}
              </Select.Option>
            ))}
          </Select>
          <HStack space={2} justifyContent="flex-end">
            <Button
              size="sm"
              variant="ghost"
              onPress={() => {
                clearFilters?.();
                args.onLocationFilter?.([]);
              }}
            >
              {intl.formatMessage({id: 'reset'})}
            </Button>
          </HStack>
        </Box>
      ),
      filteredValue: selectedLocations,
      render: (data: IAppointmentType) => {
        return (
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
           {data?.locationGroupName || '-'}
          </Text>
        );
      }
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='patientFacing'
        />
      ),
      key: 'isShownToPatient',
      width: '10%',
      align: 'center',
      render: (data: any) => (
        <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
          {data.isShownToPatient
            ? intl.formatMessage({id: 'yes'})
            : intl.formatMessage({id: 'no'})}
        </Text>
      ),
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='rsvpEnabled'
        />
      ),
      key: 'isRsvpEnabled',
      width: '10%',
      align: 'center',
      render: (data: any) => (
        <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
          {data.isRsvpEnabled
            ? intl.formatMessage({id: 'yes'})
            : intl.formatMessage({id: 'no'})}
        </Text>
      ),
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='actions'
        />
      ),
      key: 'action',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (data: IAppointmentType) => {
        const currentEhrConfig = getEhrConfig('', data?.locationGroupId);
        return (
          <View flexDirection="row" justifyContent={'center'}>
            <IconButton
              colorScheme="gray"
              variant="ghost"
              onPress={() => onEdit(data)}
              {...testID(TestIdentifiers.editBtn)}
              icon={<Icon as={AntIcon} name="edit" size="4" color="gray.400" />}
            />
            <IconButton
              colorScheme="gray"
              tintColor="gray.500"
              variant="ghost"
              onPress={() => onDelete({...data, currentEhrConfig: currentEhrConfig})}
              {...testID(TestIdentifiers.deleteBtn)}
              icon={
                <Icon as={AntIcon} name="delete" size="4" color="gray.400" />
              }
            />
          </View>
        );
      },
    },
  ];
  if (Object.keys(args?.hiddenColumnsKeysMap || {})?.length > 0) {
    return columns?.filter(column => {
      return !args?.hiddenColumnsKeysMap?.[column.key];
    });
  };

  return columns;

};

export const getUserIds = (appointmentTypes: IAppointmentType[]): string[] => {
  const userIds: string[] = [];

  appointmentTypes.forEach((event: IAppointmentType) => {
    if (event?.appointmentTypeGroup) {
      (event?.appointmentTypeGroup || []).forEach((user: IUserGroupData) => {
        if (user.userId && !userIds.includes(user.userId)) {
          userIds.push(user.userId);
        }
      });
    }
  });

  return userIds;
};

export const setUserData = (
  appointmentTypes: IAppointmentType[],
  userData: IUser[]
): IAppointmentType[] => {
  const userMap: any = {};
  appointmentTypes.forEach((event: IAppointmentType) => {
    if (event?.appointmentTypeGroup) {
      (event?.appointmentTypeGroup || []).forEach((user: IUserGroupData) => {
        if (user.userId && userMap[`${user.userId}`]) {
          user.user = userMap[`${user.userId}`];
        } else {
          const matchedUser = userData.filter((userObj: IUser) => {
            return userObj.uuid === user.userId;
          });

          if (matchedUser && matchedUser.length > 0) {
            const userCopy = {
              label: matchedUser[0].name,
              value: matchedUser[0].uuid,
              key: matchedUser[0].uuid,
            };
            user.user = userCopy;
            userMap[matchedUser[0].uuid] = userCopy;
          }
        }
      });
    }
  });
  return appointmentTypes;
};

export const isAtClinicLocationType = (
  locationTypeId: string[],
  scheduleLocationTypeList: IMlov[]
) => {
  if (locationTypeId) {
    return scheduleLocationTypeList.some((locationType) => {
      // return (
      //   locationType.id === locationTypeId &&
      //   locationType.code === LOCATION_TYPE_CODES.AT_CLINIC
      // );
      return (
        locationTypeId.some((idItem: string) => idItem === locationType.id) &&
        locationType.code === LOCATION_TYPE_CODES.AT_CLINIC
      );
    });
  }
  return false;
};

export const getSubmitDataForAddUpdate = (
  formData: IAppointmentFormType,
  scheduleLocationTypeList: IMlov[],
  previousData: IUserGroupData[],
  currentSelectedData: ILocalAppointmentTypeRoles[] | undefined,
  appointmentType: IAppointmentType | undefined,
  mlovs: IMlov[],
  additionalData?: {
    appointmentTypeUserPoolId: string;
    appointmentTypeRestrictedUserPoolId: string
  }
) => {

  const customFields = getDeletedCustomFieldsData(formData, mlovs);

  const submitData: any = {
    ...(appointmentType?.id && { id: appointmentType?.id} ),
    duration: formData.duration,
    description: formData.description || null,
    eventName: formData.eventName,
    locationGroupId: formData.locationGroupId,
    endDate: formData.endDate || null,
    beginDate: formData.beginDate || null,
    isShownToPatient: formData.isShownToPatient,
    isRsvpEnabled: formData.isRsvpEnabled,
    categoryId: formData.categoryId,
    shortName: formData?.shortName || null,
    appointmentCardProperties: {
      ...(appointmentType?.appointmentCardProperties?.id && {
        id: appointmentType?.appointmentCardProperties?.id,
      }),
      bgColorPrimary: formData?.colorCode,
      bgColorSecondary: getLighterShadeWithoutOpacity(formData?.colorCode || '', 0.1),
      bgColorPrimaryDisabled: getLighterShadeWithoutOpacity(
        formData?.colorCode || '',
        0.5
      ),
      bgColorSecondaryDisabled: formData?.colorCode || '',
    } as IAppointmentCardProperties,
    availabilityTypeCode: formData.availabilityTypeCode,
    customFields: customFields,
    visitTypeId: formData?.visitTypeId || null,
  };

  if (formData.locationTypeId.length > 1) {
    // MultiSelect
    submitData.isPatientFacingLocationType = true;
    const filterAtClinicData = scheduleLocationTypeList.filter(
      (locationType) =>
        formData.locationTypeId.some(
          (idItem: string) => idItem === locationType.id
        ) && locationType.code === LOCATION_TYPE_CODES.AT_CLINIC
    );

    submitData.locationTypeId =
      filterAtClinicData[0]?.id || formData.locationTypeId[0];
  } else {
    // Single Select
    submitData.locationTypeId = formData.locationTypeId[0];
    submitData.isPatientFacingLocationType = false;
  }

    submitData.employers = getApplicableForDataForUpdate(
      formData?.employers || [],
      (appointmentType?.appointmentTypeEmployer as IAppointmentEmployer[]) || [],
      'EMPLOYERS'
    );
    submitData.popGroups = getApplicableForDataForUpdate(
      formData?.popGroups || [],
      (appointmentType?.appointmentTypePopulationGroup as IAppointmentPopulationGroups[]) || [],
      'POP_GROUPS'
    );

  // Roles (user roles or primary care team role)
  if (appointmentType?.id) {
    submitData.appointmentTypeRoles = getRoleDataForUpdate(previousData, currentSelectedData || [], appointmentType);
  } else {
    submitData.appointmentTypeRoles = (currentSelectedData || []).map((data: ILocalAppointmentTypeRoles, index: number) => {
      return { roleId: data.roleId, sequence: index, accountRoleId: data.accountRoleId };
    });
  }
  // Add restricted user
  submitData.restrictedUserPool = getRestricedUserPoolDataForUpdate(
    formData.restrictedUsers,
    formData?.restrictedUserPool || {} as IAppointmentTypeUserPool,
    DEFAULT_APPOINTMENT_RESTRICATED_USER_POOL_NAME,
    additionalData?.appointmentTypeRestrictedUserPoolId || ''
    )
  // User pool (primary and secondary provider)
  if (appointmentType?.id && formData.appointmentTypeUserPool) {
    submitData.appointmentTypeUserPool = getUserPoolDataForUpdate(
      formData.providers,
      formData.appointmentTypeUserPool
    );
  } else if (formData.providers.primary.length) {
    submitData.appointmentTypeUserPool = {
      typeId: additionalData?.appointmentTypeUserPoolId,
      name: DEFAULT_APPOINTMENT_USER_POOL_NAME,
      userPoolUsers: [
        ...formData.providers.primary.map((userId) => {
          return {
            userId: userId,
            isDeleted: false,
            isDefault: true,
          }
        }),
        ...formData.providers.secondary.map((userId) => {
          return {
            userId: userId,
            isDeleted: false,
            isDefault: false,
          }
        })
      ]
    };
  }
  // push tasks
  const currentTasks = formData?.currentTasks || [];
  currentTasks.forEach((currentTask) => {
    currentTask.appointmentTypeId = appointmentType?.id || undefined;
    if (!currentTask.isDeleted) {
      currentTask.isDeleted = false;
    }
    delete currentTask.isCompleted;
  });
  if (formData?.categoryType === AppointmentTypeAction.groupSessionAppointment) {
    submitData.memberLimit = getMemberLimitFormat(formData?.memberLimit || 1, formData.locationTypeId, scheduleLocationTypeList)
  }
  submitData.appointmentTypeTasks = currentTasks;
  return submitData;
};

const getMemberLimitFormat = (memberLimit: number, locationIds: string[], locationType: IMlov[]) => {
  let memberLimitData = {} as any;
  locationIds.map((qitem) => {
    locationType.map((location) => {
      if (qitem === location?.id) {
        memberLimitData = {
          ...memberLimitData,
          [location.code] : memberLimit
        }
      }
    });
  });
  return memberLimitData;
};

export const getParticipantsDataForUpdate = (
  previousData: IUserGroupData[],
  updatedData: IUserGroupData[],
  appointmentType: IAppointmentType
): any[] => {
  const participantsData: any[] = [];
  const groupIds: string[] = [];

  updatedData.forEach((participant: IUserGroupData, index: number) => {
    const data: any = {
      userId: participant.user?.value || participant.userId,
      duration: participant.duration,
      appointmentTypeId: appointmentType.id,
      sequence: index,
    };

    if (participant.id) {
      data.id = participant.id;
      groupIds.push(data.id);
    }
    participantsData.push(data);
  });

  previousData.forEach((participant: IUserGroupData) => {
    const data = {
      id: participant.id,
      userId: participant.userId,
      duration: participant.duration,
      appointmentTypeId: appointmentType.id,
      isDeleted: true,
    };

    if (participant.id && !groupIds.includes(participant.id)) {
      participantsData.push(data);
    }
  });

  return participantsData;
};

export const getRoleDataForUpdate = (
  previousData: IUserGroupData[],
  updatedData: ILocalAppointmentTypeRoles[],
  appointmentType: IAppointmentType
): any[] => {
  const roleDataList: IRoleData[] = [];
  updatedData.forEach((role: ILocalAppointmentTypeRoles, index: number) => {
    const data: any = {
      roleId: role.roleId,
      appointmentTypeId: appointmentType.id,
      sequence: index,
      accountRoleId: role.accountRoleId
    };
    // add ids for existing roles from previous data
    const matchedRole = previousData.filter((roleData: IUserGroupData) => {
      return (roleData?.accountRoleId === role.accountRoleId);
    });
    if (matchedRole && matchedRole.length > 0) {
      data.id = matchedRole[0].id;
    }
    roleDataList.push(data);
  });
  //add roles which are deleted from previous data to roleDataList with isDeleted flag
  previousData.forEach((role: IUserGroupData) => {
    const data:IRoleData = {
      id: role.id,
      roleId: role.roleId || '',
      appointmentTypeId: appointmentType.id || '',
      isDeleted: true,
      accountRoleId: role.accountRoleId
    };
    //NEED_TO_VERYFY_ONCE_BACKEND_DEPLOYED
    const matchedRole = updatedData.filter((roleData: ILocalAppointmentTypeRoles) => {
      return (roleData.accountRoleId === role.accountRoleId);
    });

    if (matchedRole && matchedRole.length === 0) {
      roleDataList.push(data);
    }
  });
  return roleDataList.filter(role => role.roleId !== '');
};

export const getUserPoolDataForUpdate = (
  updatedData: {
    primary: string[],
    secondary: string[],
  },
  previousData: IAppointmentTypeUserPool,
): IAppointmentTypeUserPool => {
  const userPoolUsers: IAppointmentTypePoolUsers[] = [];
  updatedData.primary.forEach((primaryUserId) => {
    const data: IAppointmentTypePoolUsers = {
      userId: primaryUserId,
      isDefault: true,
      isDeleted: false,
    };
    data.id = previousData.userPoolUsers?.find(item => item.userId === primaryUserId)?.id;
    userPoolUsers.push(data);
  });
  updatedData.secondary.forEach((secondaryUserId) => {
    const data: IAppointmentTypePoolUsers = {
      userId: secondaryUserId,
      isDefault: false,
      isDeleted: false,
    };
    data.id = previousData.userPoolUsers?.find(item => item.userId === secondaryUserId)?.id;
    userPoolUsers.push(data);
  });

  //add users which are deleted from previous data to roleDataList with isDeleted flag
  previousData.userPoolUsers?.forEach((userPoolUser: IAppointmentTypePoolUsers) => {
    const data: IAppointmentTypePoolUsers = {
      id: userPoolUser.id,
      userId: userPoolUser.userId,
      isDefault: false,
      isDeleted: true,
    };
    const matchedUser = userPoolUsers.filter(item => item.userId === userPoolUser.userId);

    if (matchedUser?.length === 0) {
      userPoolUsers.push(data);
    }
  });
  return {
    id: previousData.id,
    typeId: previousData.typeId,
    name: previousData.name,
    userPoolUsers: userPoolUsers,
  };
};

export const getRestricedUserPoolDataForUpdate = (
  userList: string[],
  previousData: IAppointmentTypeUserPool,
  name: string,
  appointmentTypeRestrictedUserPoolId: string,
): IAppointmentTypeUserPool => {
  const userPoolUsers: IAppointmentTypePoolUsers[] = [];

  userList.forEach(userId => {
    const data: IAppointmentTypePoolUsers = {
      userId: userId,
      isDefault: false,
      isDeleted: false,
    };
    data.id = previousData.userPoolUsers?.find(item => item.userId === userId)?.id;
    userPoolUsers.push(data);
  });

  // Add users which are deleted from previous data to roleDataList with isDeleted flag
  previousData?.userPoolUsers?.forEach(userPoolUser => {
    const data: IAppointmentTypePoolUsers = {
      id: userPoolUser.id,
      userId: userPoolUser.userId,
      isDefault: false,
      isDeleted: true,
    };
    const matchedUser = userPoolUsers.filter(item => item.userId === userPoolUser.userId);

    if (matchedUser?.length === 0) {
      userPoolUsers.push(data);
    }
  });

  return {
    id: previousData?.id,
    typeId: appointmentTypeRestrictedUserPoolId,
    name: name,
    userPoolUsers: userPoolUsers,
  };
};


export const getApplicableForDataForUpdate = (
  updatedData: string[],
  previousData: any[],
  key: 'EMPLOYERS' | 'POP_GROUPS'
): CombineType[] => {
  const list: CombineType[] = [];
  const objKey: keyof (IAppointmentEmployer & IAppointmentPopulationGroups) = key === 'EMPLOYERS' ? 'employerId' : 'groupId'
  updatedData.forEach(id => {
    const item :any = {
      [objKey]: id,
      isDeleted: false,
    };
    item.id = previousData.find(prevData => prevData?.[objKey] === item?.[objKey])?.id
    list.push(item);
  });

  previousData.forEach(item => {
    const data:any = {
      id: item.id,
      isDeleted: true,
      [objKey as keyof CombineType]: item[objKey]
    };
    const matchedData = list.filter((listItem:any) => listItem[objKey] === item[objKey]);
    if(matchedData.length === 0){
      list.push(data);
    }
  })
  return list;
};

export const setUserDetailsToAppointmentGroup = (
  appointmentTypeGroup: IUserGroupData[],
  users: any[]
): IUserGroupData[] => {
  return (appointmentTypeGroup || []).map((user: any) => {
    const matchedData = users.filter((data: any) => {
      return data.uuid === user.userId;
    });
    if (matchedData && matchedData?.length > 0) {
      const currentUser = matchedData[0];
      user.user = {
        label: currentUser.name,
        value: currentUser.uuid,
        key: currentUser.uuid,
      };
    }
    return user;
  });
};

export const getSelectedLocationTypeIds = (
  locationTypeId: string,
  isPatientFacingLocationType: boolean,
  locationTypeList: IMlov[]
): string[] => {
  if (locationTypeId) {
    const selectedLocationTypeIds = [locationTypeId];
    if (isPatientFacingLocationType) {
      const virtualLocationTypeId =
        locationTypeList?.find((locationType) => {
          return locationType.code === LOCATION_TYPE_CODES.VIRTUAL;
        })?.id || '';
      if (
        virtualLocationTypeId &&
        !selectedLocationTypeIds.includes(virtualLocationTypeId)
      ) {
        selectedLocationTypeIds.push(virtualLocationTypeId);
      }
    }
    return selectedLocationTypeIds;
  }
  return [];
};
export const getPrevAppointmentGroupData = (
  users: any[],
  appointmentTypeGroup?: IUserGroupData[]
) => {
  if (appointmentTypeGroup) {
    const userRoleIds = appointmentTypeGroup.map((item) => item.roleId);
    const data = users.filter((user) => userRoleIds.includes(user.id));
    return data;
  }
  return [];
};

export const getMultiSelectData = (
  userRoles: any[],
  appointmentTypeGroup: IUserGroupData[]
) => {
  const data: ILocalAppointmentTypeRoles[] = [];
  appointmentTypeGroup?.length > 0 &&
    appointmentTypeGroup.forEach((apptRole: IUserGroupData) => {
      const role = userRoles.find((item) => (item.id === apptRole.accountRoleId));
      if (role) {
        data.push({value: role.value, id: apptRole.id || '', roleId: role.roleId, accountRoleId: role.id });
      }
    });
  return data;
};

export const slotMatchWithApiResponse = (
  availabilityResponse: IAppointmentTypeAvailabilityApiResponse[],
  slotId: string,
) => {
  return (availabilityResponse || []).find(
    availability => availability?.id === slotId,
  );
};

export const getApiInputFormatSlotData = (
  locationId: string | undefined,
  availability: IAppointmentTypeAvailability,
  isDeleted: boolean,
  slot: IAppointmentTypeAvailabilitySlot,
) => {
  const startTime = convertAvailabilityTimeToStringTime(slot.startTime);
  const endTime = convertAvailabilityTimeToStringTime(slot.endTime);
  const daysOfWeek = availability?.weekDays?.length
    ? JSON.stringify(availability.weekDays)
    : '';
  return {
    startTime,
    endTime,
    daysOfWeek,
    isDeleted,
    locationId: locationId !== VIRTUAL_LOCATION_CODE ? locationId : undefined,
    isVirtualLocation: locationId === VIRTUAL_LOCATION_CODE,
    id: slot?.id || undefined,
  };
};

export const getUpdatedAvailabilitySlot = (
  slot: IAppointmentTypeAvailabilitySlot,
  existingSlot: IAppointmentTypeAvailabilityApiResponse,
  availability: IAppointmentTypeAvailability,
) => {
  const isDeletedExistingLocation =
    existingSlot?.locationId &&
    !(availability.locationIds || []).includes(existingSlot?.locationId);
  const isDeletedVirtualLocation =
    existingSlot?.isVirtualLocation &&
    !(availability.locationIds || []).includes(VIRTUAL_LOCATION_CODE);
  if (slot.isDeleted || isDeletedExistingLocation || isDeletedVirtualLocation) {
    return getApiInputFormatSlotData(undefined, availability, true, slot);
  }
  return getApiInputFormatSlotData(
    existingSlot?.locationId,
    availability,
    false,
    slot,
  );
};

export const prepareAvailabilitySlots = (
  availability: IAppointmentTypeAvailability,
  locationId: string,
  availabilityResponse: IAppointmentTypeAvailabilityApiResponse[],
) => {
  const apiInputFormatAvailability: any[] = [];
  const isVirtualLocation = locationId === VIRTUAL_LOCATION_CODE;
  (availability.slots || []).forEach(slot => {
    const cloneSlot = cloneDeep(slot);
    const isSlotDeleted = slot?.isDeleted;
    let isSlotMatchWithLocation = false;
    let notMatchSlotCount = 0;
    (slot?.slotIds || []).forEach(id => {
      const updatedSlot = cloneDeep(slot);
      // update existing slot which match with location or removed by user
      const existingSlot = slotMatchWithApiResponse(availabilityResponse, id);
      if (existingSlot?.id) {
        const prevLocation = existingSlot?.locationId || undefined;
        const prevLocationVirtual = existingSlot?.isVirtualLocation || false;
        updatedSlot.id = existingSlot?.id;
        if (isSlotDeleted) {
          apiInputFormatAvailability.push(
            getApiInputFormatSlotData(
              prevLocation,
              availability,
              isSlotDeleted,
              updatedSlot,
            ),
          );
        } else {
          if (
            prevLocation === locationId ||
            (prevLocationVirtual && prevLocationVirtual === isVirtualLocation)
          ) {
            // update existing slot which match with location
            const selectedLocationId = prevLocation || VIRTUAL_LOCATION_CODE;
            apiInputFormatAvailability.push(
              getApiInputFormatSlotData(
                selectedLocationId,
                availability,
                false,
                updatedSlot,
              ),
            );
          } else {
            // isSlotMatchWithLocation set true when all slots not match with location
            notMatchSlotCount += 1;
            isSlotMatchWithLocation =
              notMatchSlotCount === slot?.slotIds?.length;
          }
        }
      }
    });
    // add new slots
    if (
      !isSlotDeleted &&
      (!slot.slotIds?.length ||
        (slot.slotIds?.length && isSlotMatchWithLocation))
    ) {
      cloneSlot.id = '';
      apiInputFormatAvailability.push(
        getApiInputFormatSlotData(locationId, availability, false, cloneSlot),
      );
    }
  });
  return apiInputFormatAvailability;
};

export const updateDeletedLocationAvailabilitySlots = (
  availability: IAppointmentTypeAvailability,
  availabilityResponse: IAppointmentTypeAvailabilityApiResponse[],
  apiInputFormatAvailability: any[],
): any[] => {
  const deletedAvailability: any[] = [];
  (availability.slots || []).forEach(slot => {
    const cloneSlot = cloneDeep(slot);
    if (slot.slotIds?.length) {
      (slot.slotIds || []).forEach(id => {
        const existingSlot = slotMatchWithApiResponse(availabilityResponse, id);
        const prevLocationId = existingSlot?.locationId || undefined;
        const prevLocationVirtual = existingSlot?.isVirtualLocation || false;
        const prevSlotId = existingSlot?.id || undefined;
        // check existing slots location deleted by user or not
        if (
          prevSlotId &&
          ((prevLocationId &&
            !availability.locationIds?.includes(prevLocationId)) ||
            (prevLocationVirtual &&
              !availability.locationIds?.includes(VIRTUAL_LOCATION_CODE)))
        ) {
          cloneSlot.id = prevSlotId;
          deletedAvailability.push(
            getApiInputFormatSlotData(
              prevLocationId,
              availability,
              true,
              cloneSlot,
            ),
          );
        }
      });
    }
  });
  return deletedAvailability;
};

export const prepareAppointmentTypeAvailabilityData = (
  timezoneId: string | undefined,
  typeId: string,
  availabilityResponse: IAppointmentTypeAvailabilityApiResponse[],
  availability: IAppointmentTypeAvailability[],
) => {
  let apiInputFormatAvailability: any[] = [];
  (availability || []).forEach(selectedAvailability => {
    if (selectedAvailability?.isDeleted && availabilityResponse?.length) {
      // fetch api slot result and set it as isDeleted true
      (selectedAvailability.slots || []).forEach(slot => {
        const cloneSlot = cloneDeep(slot);
        (slot?.slotIds || []).forEach(id => {
          const existingSlot = slotMatchWithApiResponse(
            availabilityResponse,
            id,
          );
          if (existingSlot?.id) {
            cloneSlot.id = existingSlot?.id;
            apiInputFormatAvailability.push(
              getApiInputFormatSlotData(
                undefined,
                selectedAvailability,
                true,
                cloneSlot,
              ),
            );
          }
        });
      });
    } else if (!selectedAvailability?.isDeleted) {
      (selectedAvailability?.locationIds || []).forEach(locationId => {
        const updatedSlots = prepareAvailabilitySlots(
          selectedAvailability,
          locationId,
          availabilityResponse,
        );
        apiInputFormatAvailability =
          apiInputFormatAvailability.concat(updatedSlots);
      });
    }
    const deletedSlots = updateDeletedLocationAvailabilitySlots(
      selectedAvailability,
      availabilityResponse,
      apiInputFormatAvailability,
    );
    apiInputFormatAvailability =
      apiInputFormatAvailability.concat(deletedSlots);
  });
  (apiInputFormatAvailability || []).forEach(availabilityInput => {
    availabilityInput.timezoneId = timezoneId;
    availabilityInput.typeId = typeId;
  });
  return apiInputFormatAvailability;
};

export const isInValidAvailabilitySlot = (
  slot: IAppointmentTypeAvailabilitySlot,
) => {
  if (!slot.startTime || !slot.endTime) {
    return true;
  } else if (
    isCurrentDateInFutureComparedToOther(slot.startTime, slot.endTime)
  ) {
    return true;
  }
  return false;
};

export const isInValidAppointmentTypeAvailability = (
  appointmentTypeAvailability: IAppointmentTypeAvailability[],
) => {
  const nonDeletedAvailability = (appointmentTypeAvailability || []).filter(
    availability => !availability.isDeleted,
  );
  if (!nonDeletedAvailability?.length) {
    return false;
  }
  return (nonDeletedAvailability || []).some(availability => {
    if (!availability?.locationIds?.length || !availability?.weekDays?.length || !availability?.slots?.length) {
      return true;
    } else {
      const nonDeletedSlots = (availability.slots || []).filter(
        slot => !slot.isDeleted,
      );
      if (nonDeletedSlots?.length) {
        return nonDeletedSlots.some(slot => {
          return isInValidAvailabilitySlot(slot);
        });
      } else if(nonDeletedSlots && nonDeletedSlots.length === 0) {
        return true
      }
      return false;
    }
  });
};

export const getLocationsForAppointmentTypeAvailability = (
  userSettings: IUserSettingsByCode,
  ehrCapabilities: IEhrCapability[],
  accountLocations: any[],
) => {
  const disAllowVirtualLocation = isVirtualLocationEnabledInAvailability(
    userSettings,
  )
    ? false
    : isVirtualLocationDisabled(userSettings, ehrCapabilities);
  const locationList: any[] = [];
  accountLocations?.forEach((location: any) => {
    if (location?.practiceLocation && location?.practiceLocation?.name) {
      locationList.push({
        key: location?.uuid,
        value: location?.uuid,
        label: location?.practiceLocation?.name,
        locationGroupId: location?.locationGroupId,
      });
    }
  });
  if (!disAllowVirtualLocation) {
    locationList.unshift({
      label: 'Virtual appointment',
      value: VIRTUAL_LOCATION_CODE,
      key: VIRTUAL_LOCATION_CODE,
    });
  }
  if (locationList?.length > 1) {
    locationList.unshift({
      key: ALL_LOCATION_CODE,
      value: ALL_LOCATION_CODE,
      label: 'All Locations',
    });
  }
  return locationList;
};

export const getDefaultAvailabilitySlot =
  (): IAppointmentTypeAvailabilitySlot => {
    return {
      startTime: '',
      endTime: '',
      isDeleted: false,
      id: generateUUID(),
    };
  };

export const getDefaultAvailabilityCard = (
  canAddDefaultSlot?: boolean,
): IAppointmentTypeAvailability => {
  return {
    cardId: generateUUID(),
    isDeleted: false,
    defaultWeekDays: [],
    weekDays: [],
    locationIds: [ALL_LOCATION_CODE],
    isVirtualLocation: false,
    slots: canAddDefaultSlot ? [getDefaultAvailabilitySlot()] : [],
  };
};

export const getProcessAvailabilityMap = (
  availabilityResponse: IAppointmentTypeAvailabilityApiResponse[],
) => {
  const availabilityWeekDayWiseMap: Map<
    string,
    IAppointmentTypeAvailabilityApiResponse[]
  > = new Map<string, []>();
  availabilityResponse.forEach(response => {
    let addedAvailabilities: IAppointmentTypeAvailabilityApiResponse[] = [];
    const daysOfWeek = response.daysOfWeek;
    if (availabilityWeekDayWiseMap.has(daysOfWeek)) {
      addedAvailabilities = availabilityWeekDayWiseMap.get(daysOfWeek) || [];
    }
    addedAvailabilities.push(response);
    availabilityWeekDayWiseMap.set(daysOfWeek, addedAvailabilities);
  });
  return availabilityWeekDayWiseMap;
};

export const convertApiResponseMapToAvailability = (
  availabilityWeekDayWiseMap: Map<
    string,
    IAppointmentTypeAvailabilityApiResponse[]
  >,
): IAppointmentTypeAvailability[] => {
  const appointmentAvailabilityList: IAppointmentTypeAvailability[] = [];
  availabilityWeekDayWiseMap.forEach(
    (
      responseList: IAppointmentTypeAvailabilityApiResponse[],
      dayWiseKey: string,
    ) => {
      const availabilityCard = getDefaultAvailabilityCard();
      const parseWeekDays = JSON.parse(dayWiseKey) || [];
      const locationIds: any[] = [];
      const availabilitySlots: IAppointmentTypeAvailabilitySlot[] = [];
      const slotTimeWiseMap: Map<
        string,
        IAppointmentTypeAvailabilityApiResponse[]
      > = new Map<string, IAppointmentTypeAvailabilityApiResponse[]>();
      let isVirtualLocation = false;

      (responseList || []).forEach(response => {
        if (
          response?.locationId &&
          !locationIds.includes(response?.locationId)
        ) {
          locationIds.push(response.locationId);
        }
        if (response?.isVirtualLocation) {
          isVirtualLocation = true;
        }

        // create map of same from and to time slots
        const slotTimeKey = `${response?.startTime || ''}-${
          response?.endTime || ''
        }`;
        let slotList: IAppointmentTypeAvailabilityApiResponse[] = [];
        if (slotTimeWiseMap.has(slotTimeKey)) {
          slotList = slotTimeWiseMap.get(slotTimeKey) || [];
        }
        slotList.push(response);
        slotTimeWiseMap.set(slotTimeKey, slotList);
      });

      (slotTimeWiseMap || []).forEach(
        (
          slots: IAppointmentTypeAvailabilityApiResponse[],
          slotTimeKey: string,
        ) => {
          const slotIds: any[] = (slots || []).map(slot => slot?.id) || [];
          const singleSlot =
            slots?.[0] || ({} as IAppointmentTypeAvailabilitySlot);
          availabilitySlots.push({
            startTime: convertDate(
              singleSlot.startTime,
              DATE_FORMATS.API_TIME_FORMAT,
              DATE_FORMATS.MESSAGE_DATE_FORMAT,
            ),
            endTime: convertDate(
              singleSlot.endTime,
              DATE_FORMATS.API_TIME_FORMAT,
              DATE_FORMATS.MESSAGE_DATE_FORMAT,
            ),
            isDeleted: false,
            id: generateUUID(),
            slotIds: slotIds?.length ? slotIds : [],
          });
        },
      );

      availabilityCard.weekDays = parseWeekDays;
      availabilityCard.defaultWeekDays = parseWeekDays;
      availabilityCard.locationIds = locationIds;
      availabilityCard.isVirtualLocation = isVirtualLocation;
      if (availabilityCard.isVirtualLocation && !availabilityCard.locationIds.includes(VIRTUAL_LOCATION_CODE)) {
        availabilityCard.locationIds.push(VIRTUAL_LOCATION_CODE);
      }
      availabilityCard.slots = availabilitySlots;
      appointmentAvailabilityList.push(availabilityCard);
    },
  );
  return appointmentAvailabilityList;
};

export const processAvailabilityApiResponse = (
  availabilityResponse: IAppointmentTypeAvailabilityApiResponse[],
): IAppointmentTypeAvailability[] => {
  if (!availabilityResponse?.length) {
    return [];
  }
  const availabilityWeekDayWiseMap: Map<
    string,
    IAppointmentTypeAvailabilityApiResponse[]
  > = getProcessAvailabilityMap(availabilityResponse);
  return (availabilityWeekDayWiseMap || new Map())?.size
    ? convertApiResponseMapToAvailability(availabilityWeekDayWiseMap)
    : [];
};

export const isInValidStartTimeOfSlot = (
  slot: IAppointmentTypeAvailabilitySlot,
  returnTypeBoolean?: boolean,
) => {
  if (!slot.startTime) {
    return returnTypeBoolean ? true : 'Please select start time';
  } else if (
    isCurrentDateInFutureComparedToOther(slot.startTime, slot.endTime)
  ) {
    return returnTypeBoolean
      ? true
      : 'Start time cannot be greater than end time';
  } else if (isSameDate(slot.startTime, slot.endTime)) {
    return returnTypeBoolean ? true : 'Start time and end time can not be same';
  }
  return returnTypeBoolean ? false : '';
};

export const isInValidEndTimeOfSlot = (
  slot: IAppointmentTypeAvailabilitySlot,
  returnTypeBoolean?: boolean,
) => {
  if (!slot.endTime) {
    return returnTypeBoolean ? true : 'Please select end time';
  } else if (
    isCurrentDateInFutureComparedToOther(slot.startTime, slot.endTime)
  ) {
    return returnTypeBoolean ? true : 'End time cannot be less than start time';
  } else if (isSameDate(slot.startTime, slot.endTime)) {
    return returnTypeBoolean ? true : 'End time and start time can not be same';
  }
  return returnTypeBoolean ? false : '';
};

export const getAvailableToDisplayValues = (code: AppointmentAvailabilityCode) => {
  switch (code) {
    case AppointmentAvailabilityCode.ROLE: return 'User Roles';
    case AppointmentAvailabilityCode.PROVIDER: return 'Providers';
    case AppointmentAvailabilityCode.CARE_TEAM: return 'Care Team';
  }
}

export const getNestedUserRoles = (userRoles: any[]) => {
  if (userRoles?.length) {
    const data = userRoles.map((role) => {
      return role.userRole?.userRole?.id;
    });
    return data;
  }
  return [];
};

export const getAppointmentTypeNamesByRestrictedUserQueryVariables = (
  userIdList: string[],
  id?: string
) => {
  return {
    ...(!!id
      ? {
          id: {
            _neq: id,
          },
        }
      : {}),
    isDeleted: {
      _eq: false,
    },
    restrictedUserPool: {
      userPoolUsers: {
        userId: {_in: userIdList},
        isDeleted: {_eq: false},
      },
    },
  };
};

export const getApplicableByDisplayValues = (code: AppointmentApplicableBy) => {
  switch (code) {
    case AppointmentApplicableBy.EMPLOYER: return 'Employers';
    case AppointmentApplicableBy.POP_GROUPS: return 'Population Groups';
  }
}

export const getSelectMembersByDisplayValues = (code: SelectMembersBy) => {
  switch (code) {
    case SelectMembersBy.ALL_MEMBERS:
      return 'All Members';
    case SelectMembersBy.SELECTED_MEMBERS:
      return 'Selected Members';
  }
}


export const getAppointmentCustomFieldByCode = (
  customFields: ICustomAppointmentType[],
  appointmentTypeCustomFieldsMlov: IMlov[],
  code: string
) => {
  const slotWithinLimitFieldId = getMlovObjectFromCode(
    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_LIMIT,
    appointmentTypeCustomFieldsMlov
  )?.id;
  const slotWithinOffsetFieldId = getMlovObjectFromCode(
    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_OFFSET,
    appointmentTypeCustomFieldsMlov
  )?.id;

  const cancelLimitFieldId = getMlovObjectFromCode(
    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.CANCEL_LIMIT,
    appointmentTypeCustomFieldsMlov
  )?.id;

  const rescheduleLimitFieldId = getMlovObjectFromCode(
    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.RESCHEDULE_LIMIT,
    appointmentTypeCustomFieldsMlov
  )?.id;

  return customFields.find(
    (customField) => {
      if (code === APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_LIMIT) {
       return customField.fieldTypeId === slotWithinLimitFieldId;
      }

      if(code === APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_OFFSET){
       return customField.fieldTypeId === slotWithinOffsetFieldId;
      }

      if(code === APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.CANCEL_LIMIT){
        return customField.fieldTypeId === cancelLimitFieldId;
      }

      if(code === APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.RESCHEDULE_LIMIT){
        return customField.fieldTypeId === rescheduleLimitFieldId;
      }
    }
  );
};

export const getDefaultCustomFieldByCode = (
  appointmentTypeCustomFieldsMlov: IMlov[],
  durationMlovs: IMlov[],
  code: string
) : ICustomAppointmentType | undefined => {
  if (code === APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_LIMIT) {
    const slotWithinLimitFieldId = getMlovObjectFromCode(
      APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_LIMIT,
      appointmentTypeCustomFieldsMlov
    )?.id;

    const unitId = getMlovObjectFromCode(
      DATE_TIME_DURATION_CODES.DAYS,
      durationMlovs
    )?.id;

    return {
      valueDataTypeCode: VALUE_TYPE_CODES.DURATION,
      fieldTypeId: slotWithinLimitFieldId,
      isDeleted: false,
      subjectTypeCode: APPOINTMENT_CUSTOM_FIELD_TYPES.APPOINTMENT_TYPE,
      value: {
        value: '7',
        unitId: unitId || '',
      },
    };
  }

  if (code === APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_OFFSET) {
    const slotWithinOffsetFieldId = getMlovObjectFromCode(
      APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_OFFSET,
      appointmentTypeCustomFieldsMlov
    )?.id;

    const unitId = getMlovObjectFromCode(
      DATE_TIME_DURATION_CODES.MINUTES,
      durationMlovs
    )?.id;

    return {
      valueDataTypeCode: VALUE_TYPE_CODES.DURATION,
      fieldTypeId: slotWithinOffsetFieldId,
      isDeleted: false,
      subjectTypeCode: APPOINTMENT_CUSTOM_FIELD_TYPES.APPOINTMENT_TYPE,
      value: {
        value: '30',
        unitId: unitId || '',
      },
    };
  }


  if (code === APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.CANCEL_LIMIT) {
    const cancelLimitFieldId = getMlovObjectFromCode(
      APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.CANCEL_LIMIT,
      appointmentTypeCustomFieldsMlov
    )?.id;

    const unitId = getMlovObjectFromCode(
      DATE_TIME_DURATION_CODES.HOURS,
      durationMlovs
    )?.id;

    return {
      valueDataTypeCode: VALUE_TYPE_CODES.DURATION,
      fieldTypeId: cancelLimitFieldId,
      isDeleted: false,
      subjectTypeCode: APPOINTMENT_CUSTOM_FIELD_TYPES.APPOINTMENT_TYPE,
      value: {
        value: '2',
        unitId: unitId || '',
      },
    };
  }

  if (code === APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.RESCHEDULE_LIMIT) {
    const rescheduleLimitFieldId = getMlovObjectFromCode(
      APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.RESCHEDULE_LIMIT,
      appointmentTypeCustomFieldsMlov
    )?.id;

    const unitId = getMlovObjectFromCode(
      DATE_TIME_DURATION_CODES.HOURS,
      durationMlovs
    )?.id;

    return {
      valueDataTypeCode: VALUE_TYPE_CODES.DURATION,
      fieldTypeId: rescheduleLimitFieldId,
      isDeleted: false,
      subjectTypeCode: APPOINTMENT_CUSTOM_FIELD_TYPES.APPOINTMENT_TYPE,
      value: {
        value: '2',
        unitId: unitId || '',
      },
    };
  }
};

export const checkSlotFieldsExistInCustomFields = (
  customFields: ICustomAppointmentType[],
  appointmentTypeCustomFieldsMlov: IMlov[],
) => {
  const slotWithinLimitFieldId = getMlovObjectFromCode(
    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_LIMIT,
    appointmentTypeCustomFieldsMlov
  )?.id;
  const slotWithinOffsetFieldId = getMlovObjectFromCode(
    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_OFFSET,
    appointmentTypeCustomFieldsMlov
  )?.id;

  return customFields.some(
    (customField) =>
      customField.fieldTypeId === slotWithinLimitFieldId ||
      customField.fieldTypeId === slotWithinOffsetFieldId
  );
};

export const checkFieldExistInCustomFields = (
  customFields: ICustomAppointmentType[],
  appointmentTypeCustomFieldsMlov: IMlov[],
  fieldCode: string
) => {
  const customFieldId = getMlovObjectFromCode(
    fieldCode,
    appointmentTypeCustomFieldsMlov
  )?.id;

  return customFields.some(
    (customField) =>
      customField.fieldTypeId === customFieldId
  );
};


export const getUpdatedAppointmentCustomFields = (
  customFields: ICustomAppointmentType[],
  updatedCustomField: ICustomAppointmentType | undefined
): ICustomAppointmentType[] => {

  if(!updatedCustomField){
    return customFields;
  }

  const isCustomFieldFound = customFields.some(
    (customField) => updatedCustomField?.fieldTypeId === customField.fieldTypeId
  );

  if(!isCustomFieldFound){
    customFields.push(updatedCustomField);
  }

  return customFields.map((customField) => {
    if (updatedCustomField?.fieldTypeId === customField.fieldTypeId) {
      return updatedCustomField;
    }
    return customField;
  });
};

export const validateAppointmentSlotFields = (formData: IAppointmentFormType, mlovs: IMlov[]) => {
  const appointmentTypes: IAppointmentType[] = [{
    customFields: formData.customFields,
    duration: formData.duration,
    eventName: formData.eventName,
    locationTypeId: '',
    isShownToPatient: formData.isShownToPatient,
    appointmentTypeGroup: formData.appointmentTypeGroup,
    restrictedUsers: []
  }]
  const formattedAppointmentTypes = formatAppointmentTypes(appointmentTypes, mlovs, true);

  const formattedAppointmentType = formattedAppointmentTypes?.[0];

  if(formattedAppointmentType?.slotStartTime && formattedAppointmentType?.slotEndTime){
    return new Date(formattedAppointmentType.slotStartTime) >= new Date(formattedAppointmentType?.slotEndTime);
  }

  return false;
}

export const validateMaxWithinLimit = (formData: IAppointmentFormType, mlovs: IMlov[]) => {
  const appointmentTypes: IAppointmentType[] = [{
    customFields: formData.customFields,
    duration: formData.duration,
    eventName: formData.eventName,
    locationTypeId: '',
    isShownToPatient: formData.isShownToPatient,
    appointmentTypeGroup: formData.appointmentTypeGroup,
    restrictedUsers: []
  }]
  const formattedAppointmentTypes = formatAppointmentTypes(appointmentTypes, mlovs, true);

  const formattedAppointmentType = formattedAppointmentTypes?.[0];

  if (
    formattedAppointmentType.bookWithinDays &&
    formattedAppointmentType.bookWithinDays > MAX_WITHIN_DAYS_LIMIT
  ) {
    return true;
  }

  return false;
}

export const getDeletedCustomFieldsData = (formData: IAppointmentFormType, mlovs: IMlov[]) => {
  const slotWithinLimitFieldTypeId = getMlovIdFromCode(
    mlovs,
    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_LIMIT
  );
  const slotWithinOffsetFieldTypeId = getMlovIdFromCode(
    mlovs,
    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_OFFSET
  );
  const cancelLimitFieldTypeId = getMlovIdFromCode(
    mlovs,
    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.CANCEL_LIMIT
  );
  const rescheduleLimitFieldTypeId = getMlovIdFromCode(
    mlovs,
    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.RESCHEDULE_LIMIT
  );

  return formData.customFields?.filter(customField => {
    if (formData.intervalType === APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME) {
      if (customField.fieldTypeId === slotWithinLimitFieldTypeId) {
        return customField.id;
      }

      if (customField.fieldTypeId === slotWithinOffsetFieldTypeId) {
        return customField.id;
      }
    }

    if (
      formData.cancelIntervalType ===
        APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME &&
      customField.fieldTypeId === cancelLimitFieldTypeId
    ) {
      return customField.id;
    }

    if (
      formData.rescheduleIntervalType ===
        APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME &&
      customField.fieldTypeId === rescheduleLimitFieldTypeId
    ) {
      return customField.id;
    }
    return true;
  }).map((customField) => {
    if (formData.intervalType === APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME) {
      if (
        customField.id &&
        customField.fieldTypeId === slotWithinLimitFieldTypeId
      ) {
        customField.isDeleted = true;
      }

      if (
        customField.id &&
        customField.fieldTypeId === slotWithinOffsetFieldTypeId
      ) {
        customField.isDeleted = true;
      }
    }

    if (
      formData.cancelIntervalType ===
        APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME &&
      customField.id &&
      customField.fieldTypeId === cancelLimitFieldTypeId
    ) {
      customField.isDeleted = true;
    }

    if (
      formData.rescheduleIntervalType ===
        APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME &&
      customField.id &&
      customField.fieldTypeId === rescheduleLimitFieldTypeId
    ) {
      customField.isDeleted = true;
    }
    return customField;
  });
}

