import {useContext} from 'react';
import {NavigateFunction} from 'react-router-dom';
import {CARE_STUDIO_NEST_URL} from '../../../constants/Configs';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {ILoginUserData} from '../../../Interfaces';
import CMSService from '../../../services/CommonService/CMSService';
import LocalStorage from '../../../utils/LocalStorage';
import {IThemeAttachments} from '../../BodyContainer/interface';
import {ITemplateCategory} from './EmailTemplates/interfaces';
import {
  getCustomFieldData,
  getCustomFieldsForEntity,
} from '../Contacts/CustomField/CustomFieldService';

const cmsInstance = CMSService.getCMSServiceInstance();
const cmsService = cmsInstance.cmsAxiosService;
const fileUploadService = cmsInstance.fileUploadService;

export const uploadFile = async (data: any) => {
  const response = await fileUploadService.post('', data);
  return response.data || {};
};

export const uploadZipFile = async (data: any) => {
  const response = await fileUploadService.post('/zip', data);
  return response || {};
};

export async function dataUrlToFile(
  dataUrl: string,
  fileName: string
): Promise<File> {
  const res: Response = await fetch(`data:image/png;base64,${dataUrl}`);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, {type: 'image/png'});
}

export interface IMediaContentQueryParams {
  name?: string;
  page?: number;
  pageSize?: number;
  limit?: number;
  sort?: string;
  type?: 'image' | 'audio' | 'video' | string;
}

export const getMediaContent = async (
  path: string,
  queryParams?: IMediaContentQueryParams
) => {
  const url = `/api/${path}`;
  const response = await cmsService.get(url, {
    params: queryParams,
  });
  return response;
};

export const searchMediaContent = async (path: string) => {
  const response = await cmsService.get(`/api/${path}`);
  return response.data || {};
};

export const deleteMediaContent = async (
  path: string,
  id: string | undefined | number
) => {
  const url = `${path}/${id}`;
  const response = await cmsService.delete(`/api/${url}`);
  return response.data;
};

export const updateMediaContent = async (
  path: string,
  id: string | undefined,
  mediaContent: any
) => {
  const url = `${path}/${id}`;
  const response = await cmsService.put(url, mediaContent, {
    headers: {
      'content-type': 'application/json',
    },
  });

  return response.data || {};
};

export interface ITemplateQueryParams {
  name?: string;
  title?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
  isDefault?: boolean;
  category?: string;
}

export const getTemplates = async (
  path: string,
  queryParams?: ITemplateQueryParams,
  signal?: any,
  useProxy?: boolean,
  proxyHeaders?: any
) => {
  const url = useProxy ? `/proxy/cms/api/${path}` : `/api/${path}`;
  const options = {
    params: queryParams,
    signal,
  };

  if (useProxy) {
    const formWidgetDataString = await LocalStorage.getItem('formWidgetData');
    const formWidgetData = formWidgetDataString
      ? JSON.parse(formWidgetDataString)
      : '';
    const formWidgetAccessToken = formWidgetData?.formWidgetAccessToken;

    const response = await cmsService.get(url, {
      ...options,
      baseURL: CARE_STUDIO_NEST_URL,
      headers: {
        'form-widget-access-token': formWidgetAccessToken,
        ...(proxyHeaders || {}),
      },
    });

    return response.data || [];
  } else {
    const response = await cmsService.get(url, options);
    return response.data || [];
  }
};

export const getTemplateById = async (path: string, id: string | undefined) => {
  const url = `/api/${path}/${id}`;
  const response = await cmsService.get(url);
  return response.data || {};
};

export const deleteTemplate = async (path: string, id: string | undefined) => {
  const url = `/api/${path}/${id}`;
  const response = await cmsService.delete(url);
  return response.data || {};
};

export const updateTemplate = async (
  path: string,
  id: string | undefined,
  templateData: any
) => {
  if (id) {
    const url = `/api/${path}/${id}`;
    const response = await cmsService.put(url, templateData, {
      headers: {
        'content-type': 'application/json',
      },
    });
    return response.data || {};
  } else {
    return await createTemplate(path, templateData);
  }
};

export const createTemplate = async (path: string, templateData: any) => {
  const url = `/api/${path}`;
  const response = await cmsService.post(url, templateData);
  return response || {};
};

export const getTemplateCategories = async () => {
  const response = await cmsService.get('/api/categories');
  return response.data;
};

export const getTemplateCategoryList = (
  categoryData: any,
  isAddDefaultValue?: any
): ITemplateCategory[] => {
  const categoryList: ITemplateCategory[] = [];
  const data = categoryData.data;
  data.forEach((item: any) =>
    categoryList.push({
      id: item.id,
      name: item.attributes.name,
      code: item.attributes.code,
      mergeTags: item.attributes.mergeTags,
    })
  );
  const sortedCategoryList = categoryList.sort((a, b) => {
    return a?.name?.localeCompare(b?.name);
  });
  if (isAddDefaultValue) {
    sortedCategoryList.unshift({
      id: 0,
      name: 'All',
      code: 'All',
      mergeTags: {},
    });
  }
  return sortedCategoryList;
};

export const getCategoryListForFilters = (
  categoryList: ITemplateCategory[]
) => {
  const templateCategories: {text: string; value: string}[] = [];
  categoryList.map((category) => {
    templateCategories.push({
      text: category.name,
      value: category.name,
    });
  });
  return templateCategories;
};

export const getCategoryId = (
  category: string,
  categories: ITemplateCategory[]
) => {
  const singleCategoryObj = categories.find((c) => c.name === category);
  return singleCategoryObj?.id;
};

export const getPatientAppUrl = (webAppUrl?: string) => {
  let patientAppUrl = '';
  const patientAppVar = 'patientapp';
  if (webAppUrl) {
    const urlArray = webAppUrl.split('.');
    urlArray.splice(1, 0, patientAppVar);
    patientAppUrl = urlArray.join('.');
  }
  return patientAppUrl;
};

export const getAdminAppUrl = (webAppUrl: string) => {
  return webAppUrl ? `${webAppUrl}/#/admin` : '';
};

export const getAppLinkMergeTagData = (commonData: any) => {
  return {
    patientApp: commonData?.accountSubdomain?.additionalAttributes?.patientApp,
    patientAppStoreLink:
      commonData?.accountSubdomain?.additionalAttributes?.patientAppStoreLink,
    patientPlayStoreLink:
      commonData?.accountSubdomain?.additionalAttributes?.patientPlayStoreLink,
  };
};

export const getAccountMergeTagData = (args?: {hideLogo?: boolean}) => {
  const commonData = useContext(CommonDataContext);
  const accountThemeConfig: any = commonData.accountThemeConfig || {};
  const userData = commonData.userData || ({} as ILoginUserData);
  const accountData = userData.accounts && userData.accounts[0];
  const tempThemeAdditionalData: string =
    accountThemeConfig.additionalAttributes || '{}';
  const finalThemeAdditionalAttributes: IThemeAttachments = JSON.parse(
    tempThemeAdditionalData
  );
  const subdomainMap: any = commonData?.accountSubdomain || {};
  const subdomainName: string = subdomainMap?.subDomain;
  const logo = finalThemeAdditionalAttributes?.attachment?.logo?.url;
  const accountName = accountData?.name;

  return {
    ...(args && args.hideLogo ? {} : {accountLogo: logo}),
    accountName,
    accountPatientAppUrl: getPatientAppUrl(subdomainName),
    clinicName: accountName,
    accountAdminUrl: getAdminAppUrl(subdomainName),
  };
};

export const getSortString = (order: string) => {
  let sortOrderString = '';
  switch (order) {
    case 'ascend':
      sortOrderString = 'asc';
      break;
    case 'descend':
      sortOrderString = 'desc';
      break;
  }

  return sortOrderString;
};

export const getTableSortOrderString = (sort: string): 'ascend' | 'descend' => {
  let order: 'ascend' | 'descend' = 'descend';
  switch (sort) {
    case 'asc':
      order = 'ascend';
      break;
    case 'desc':
      order = 'descend';
      break;
  }

  return order;
};

export const isObject = (val: any) =>
  val && typeof val === 'object' && !Array.isArray(val);

export const getPathFromHash = (locationHash: string) => {
  return locationHash.split('/').pop();
};

export const navigateToAutomationScreen = (args: {
  record: any;
  type: 'email' | 'sms' | 'notification';
  navigate: NavigateFunction;
}) => {
  const {record, type, navigate} = args;
  const nodeType = getNodeDataFromCMSType(type);
  navigate(
    `/admin/patients/automation/create?currentTab=TRIGGER&flowType=PATIENTS&DEFAULT_TEMPLATE_ID=${
      record.id
    }&DEFAULT_CATEGORY_CODE=${
      record?.category?.code || record.templateCategoryCode
    }&DEFAULT_NODE_TYPE=${nodeType}&contentType=${type}`
  );
};

export const getNodeDataFromCMSType = (cmsType: string) => {
  let nodeType = '';
  switch (cmsType) {
    case 'sms':
      nodeType = 'InformPatientNodeViaSMS';
      break;

    case 'notification':
      nodeType = 'InformViaPushNotification';
      break;

    case 'email':
      nodeType = 'InformPatientNodeV2';
      break;
  }
  return nodeType;
};

export const blobToBase64 = (blob: Blob | any) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const getCustomAttributeMergeTags = async () => {
  const customAttribute: Record<string, string> = {};
  const response = await getCustomFieldData({
    pageNo: 1,
    pageSize: 100,
    entityType: 'CONTACT',
    showDisabled: 'no',
    searchString: '',
  });
  const data = response.data.customAttributes;
  data.forEach((item) => {
    customAttribute[item.key] = item.label;
  });
  return customAttribute;
};

export const getContactCustomAttributeMergeTagValues = async (
  contactUuid: string
) => {
  const response = await getCustomFieldsForEntity({entityId: contactUuid});
  const customAttributeValues: Record<string, string> = {};
  const data = response.data;
  data.forEach((item) => {
    if (item.customAttribute.key) {
      customAttributeValues[item.customAttribute.key] = item.value;
    }
  });
  return customAttributeValues;
};
