import { v4 as uuidv4 } from 'uuid';
import { DATE_FORMATS } from '../../../../constants';
import { FlowType } from '../../../../context/WorkflowContext';
import { addDaysInDate, getDateStrFromFormat } from '../../../../utils/DateUtils';
import { IProductDetailForm } from '../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/interfaces';
import { MetaDataFieldType } from '../../Workflow/Workflow/AddOrUpdateWorkflow';
import {
  getWorkflowByMasterId,
  getWorkflowNodeMasterData,
  getWorkflowNodeMetaData,
  saveWorkflow
} from '../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { IJourneyDetailForm } from './AddOrUpdateJourney';
import {
  JOURNEY_PRESCRIBE_NODE,
  JOURNEY_PRESCRIBE_TRIGGER_EVENT,
  JOURNEY_PURCHASE_NODE,
  JOURNEY_PURCHASE_TRIGGER_EVENT
} from './JourneyTabs';

export interface INodeDataResponse {
  nodeMasterData: any;
  nodeMetaData: any;
}

export const getNodeMetadata = (
  flowType: FlowType,
  successBlock: (data: INodeDataResponse) => void,
  errorBlock: () => void,
  commonData?: any,
  ehrConfig?: any
) => {
  const combinedResponse: INodeDataResponse = {
    nodeMasterData: undefined,
    nodeMetaData: undefined,
  };
  getWorkflowNodeMasterData(
    flowType,
    (nodeMasterData: any) => {
      combinedResponse.nodeMasterData = nodeMasterData;
      if (combinedResponse.nodeMetaData) {
        successBlock(combinedResponse);
      }
    },
    (error: any) => {

      errorBlock();
    },
    commonData,
    ehrConfig
  );
  getWorkflowNodeMetaData(
    flowType,
    MetaDataFieldType.metaData,
    '',
    (data: any) => {
      combinedResponse.nodeMetaData = data;
      if (combinedResponse.nodeMasterData) {
        successBlock(combinedResponse);
      }
    },
    (error: any) => {

      errorBlock();
    }
  );
};

export const getWorkflowSaveJourneyData = (details: IJourneyDetailForm, isMsoEnabled?: boolean) => {
  const nodes = details.nodes || [];
  const nodesCopyWithRequiredField = nodes.map((node: any) => {
    const elementCopy = JSON.parse(JSON.stringify(node));
    return elementCopy;
  });
  const edges = details.edges || [];
  const edgesCopyWithRequiredField = edges.map((node: any) => {
    const elementCopy = JSON.parse(JSON.stringify(node));
    return elementCopy;
  });

  const data: any = {
    flowType: FlowType.careJourney,
    workflowName: details.name,
    tagList: details.triggerEvents.map((event) => event),
    triggerInfo: {
      triggerCondition:
        details.triggerEvents.length > 0
          ? details.triggerEvents[0] === JOURNEY_PRESCRIBE_TRIGGER_EVENT
            ? JOURNEY_PRESCRIBE_NODE
            : JOURNEY_PURCHASE_NODE
          : '',
    },
    nodes: nodesCopyWithRequiredField,
    edges: edgesCopyWithRequiredField,
    workflowCompletedElementList: [],
    isSendSurveyForm: false,
    secondaryMasterId: details?.secondaryMasterId,
  };
  if (details.careJourneyWorkflowId) {
    data.id = details.careJourneyWorkflowId;
  }
  if (isMsoEnabled) {
    data.locationIds = details?.careJourneyLocations?.locations
  }

  const body: any = {
    id: data?.id,
    flowType: data?.flowType,
    description: data?.description,
    userId: data?.userId,
    tenantId: data?.tenantId,
    workflowName: data?.workflowName,
    tagList: data?.tagList,
    triggerInfo: data?.triggerInfo,
    graph: {nodes: data?.nodes, edges: data?.edges},
    surveyForm: data?.surveyForm,
    isSendSurveyForm: data?.isSendSurveyForm,
    secondaryMasterId: data?.secondaryMasterId
  };

  if (data.isEnabled !== undefined) {
    body.isEnabled = data.isEnabled;
  }
  if (isMsoEnabled) {
    body.locationIds = data.locationIds
  }
  
  return body;
}

export const changeWorkflowIdForImport = (newWorkflowData: any) => {
  delete newWorkflowData?.graph?.id;
  delete newWorkflowData?.triggerInfo?.id;
  delete newWorkflowData?.graph?.userId;
  delete newWorkflowData?.graph?.createdOn;
  delete newWorkflowData?.graph?.tenantId;
  delete newWorkflowData?.workflow?.tenantId;
  delete newWorkflowData?.workflow?.id;
  delete newWorkflowData?.workflow?.workflowMasterId;
  delete newWorkflowData?.workflow?.secondaryMasterId;
  delete newWorkflowData?.secondaryMasterId;
  delete newWorkflowData?.workflow?.resourceExecutionId;
  delete newWorkflowData?.workflow?.userId;
  delete newWorkflowData?.workflow?.graphId;
  delete newWorkflowData?.workflow?.workflowArnList;
  delete newWorkflowData?.workflow?.asyncNodeList;
  delete newWorkflowData?.secondaryMasterId;
  newWorkflowData['secondaryMasterId'] = `${uuidv4()}`;
  (newWorkflowData.graph?.nodes || []).forEach((node: any) => {
    const oldNodeId = node?.id;
    const newNodeId = `${uuidv4()}`;
    if (node?.data?.metaData?.optionMap) {
      Object.keys(node?.data?.metaData?.optionMap).forEach((key) => {
        node.data.metaData.optionMap[key].id = `${uuidv4()}`;
      });
    }
    node.id = newNodeId;
    (newWorkflowData.graph?.edges || []).forEach((edge: any) => {
      const newEdgeId = `${uuidv4()}`;
      delete edge.data;
      edge.id = newEdgeId;
      if (edge?.source === oldNodeId) {
        edge.source = newNodeId;
      }
      if (edge?.target === oldNodeId) {
        edge.target = newNodeId;
      }
    });
  });

  return newWorkflowData;
};


export const saveJourneyToWorkflow = (
  details: IJourneyDetailForm,
  successBlock: (response: any) => void,
  errorBlock: () => void,
  abortSignal?: AbortSignal,
) => {
  const data = getWorkflowSaveJourneyData(details);
  saveWorkflow(
    data,
    (response: any) => {
      successBlock(response);
    },
    (error: any) => {

      errorBlock();
    },
    abortSignal
  );
};

export const getSpreeProductSaveJourneyData = (details: IProductDetailForm, isFromAutoSave = false) => {
  if (!details?.id) {
    details.shipping_category_id = '1';
  }

  let availableOn = getDateStrFromFormat(addDaysInDate(new Date(), -1), DATE_FORMATS.API_DATE_FORMAT);
  if (isFromAutoSave && !details.id) {
    availableOn = '';
  }
  const data = {
    id: details?.id,
    name: details.name,
    description: details.description,
    shipping_category_id: '1',
    available_on: availableOn,
    price: details.price,
    cost_currency: details.cost_currency,
    compare_at_price: details.compare_at_price,
    taxon_ids: details.taxon_ids,
  };

  return {
    product: data,
  };
};

export const getWorkFlowDataById = (
  careJourneyWorkflowId: string,
  accountUUID: string,
  successBlock: (response: any) => void,
  errorBlock: () => void,
  isClone?: boolean
) => {
  getWorkflowByMasterId(
    careJourneyWorkflowId,
    accountUUID,
    (workflowData: any) => {
      if (workflowData?.graph && workflowData?.triggerInfo?.triggerCondition) {
        const graph = workflowData.graph;
        let nodes = graph.nodes.map((element: any) => {
          element.data = element.data || {};
          element.data.setData = function (key: any, value: any) {
            if (this.metaData) {
              this.metaData[key] = value;
            }
          };
          return element;
        });
        let edges = graph.edges.map((element: any) => {
          element.data = element.data || {};
          element.data.setData = function (key: any, value: any) {
            if (this.metaData) {
              this.metaData[key] = value;
            }
          };
          return element;
        });
        const triggerEvent = getTriggerEventFromCondition(
          workflowData.triggerInfo.triggerCondition
        );
        if (isClone) {
          const changedWorkflowId = changeWorkflowIdForImport({nodes: nodes,edges: edges})
          nodes = changedWorkflowId.nodes;
          edges = changedWorkflowId.edges;
        }
        successBlock({
          nodes: nodes,
          edges: edges,
          triggerEvent: triggerEvent,
          secondaryMasterId: workflowData?.workflow?.secondaryMasterId,
          workflowData: workflowData?.workflow,
        });
      } else {
        errorBlock();
      }
    },
    (error: any) => {

      errorBlock();
    }
  );
};

const getTriggerEventFromCondition = (condition: string) => {
  if (condition === JOURNEY_PURCHASE_NODE) {
    return JOURNEY_PURCHASE_TRIGGER_EVENT;
  }
  return JOURNEY_PRESCRIBE_TRIGGER_EVENT;
};
