import {Employer} from '../../../RightSideContainer/BrazilCodeMR/pages/analytics/AnalyticsController';
import {
  DateRange,
  ITaskLabels,
  ITaskParams,
  PatientContextValues,
} from '../../../RightSideContainer/Analytics/CommonComponents/Interfaces';
import {IMlov} from '../../../RightSideContainer/Journeys/JourneysOfCare/Table/Interfaces';
import {ViewStyle} from 'react-native';
import {StyleProp} from 'react-native';

export const RESOURCE_LIST = {
  TASK_ANALYTICS_OVERALL_VIEW: 'TASK_ANALYTICS_OVERALL_VIEW',
  TASK_ANALYTICS_TASK_DISTRIBUTION_BY_ASSIGNEE:
    'TASK_ANALYTICS_TASK_DISTRIBUTION_BY_ASSIGNEE',
  TASK_ANALYTICS_TASK_DISTRIBUTION_BY_TASK_POOL:
    'TASK_ANALYTICS_TASK_DISTRIBUTION_BY_TASK_POOL',
  TASK_ANALYTICS_AVERAGE_TIME_TO_RESOLUTION_BY_ASSIGNEE:
    'TASK_ANALYTICS_AVERAGE_TIME_TO_RESOLUTION_BY_ASSIGNEE',
  TASK_ANALYTICS_AVERAGE_TIME_TO_RESOLUTION_BY_TASK_POOL:
    'TASK_ANALYTICS_AVERAGE_TIME_TO_RESOLUTION_BY_TASK_POOL',
  TASK_ANALYTICS_TASK_DELAY_PERCENTAGE_OVERVIEW_BY_ASSIGNEE:
    'TASK_ANALYTICS_TASK_DELAY_PERCENTAGE_OVERVIEW_BY_ASSIGNEE',
  TASK_ANALYTICS_TASK_DELAY_PERCENTAGE_OVERVIEW_BY_TASK_POOL:
    'TASK_ANALYTICS_TASK_DELAY_PERCENTAGE_OVERVIEW_BY_TASK_POOL',
  TASK_ANALYTICS_TASK_COMPLETED_BY_MONTH:
    'TASK_ANALYTICS_TASK_COMPLETED_BY_MONTH',
};
export interface ITaskAnalyticsFilterView {
  code: string;
  employerData: Employer;
  dateRange: DateRange;
  selectedTaskPriorityList: string[];
  patientContext: string;
  taskLabelsIds: string[];
  taskPoolsIds: string[];
  onFilterChange: (code: string, data: any) => void;
  isDrillDown?: boolean;
  locationGroupIds?: string[];
  onLayout?: (event: any) => void;
}

export interface ITaskAnalyticsPage {
  code: string;
  pageData: IPage;
  taskAnalyticState: ITaskFilterState;
  userData: IUserData;
  taskAnalyticsHeight: number;
}

export interface IPageResponse {
  data: IPageData;
}

export interface IPageData {
  pages: IPage[];
}

export interface IPage {
  id: string;
  pageType: string;
  name: string;
  pageSections: IPageSection[];
}

export interface IPageSection {
  id: string;
  heading: string;
  sequence: number;
  additionalAttributes: {
    tooltip?: {
      info?: string
    }
  };
  parentSectionId?: any;
  subHeading: string;
  sectionCategory: ISectionCategory;
  sectionDisplayConfig: ISectionDisplayConfig;
  pageId: string;
  isVisible: boolean;
  sectionCategoryId: string;
  resourceId?: any;
  type: string;
  childSections: IPageSection[];
  resource: IResource;
}

export interface ISectionDisplayConfig2 {
  section: ISection;
  widgetStyle: IWidgetStyle;
}

export interface IWidgetStyle {
  height: number;
}

export interface IResource {
  code: string;
  allowedGroupBy: JSON;
  allowedFilters: JSON;
}

export interface ISectionDisplayConfig {
  section: ISection;
  childSections: IChildSections;
  widgetStyle: IWidgetStyle;
}

export interface IChildSections {
  flexDirection: string;
}

export interface ISection {
  height: number;
  padding: number;
  flex?: number;
  width?: string;
}

export interface ISectionCategory {
  code: string;
}

export interface ITaskOverViewWidgetData {
  completionRate: number;
  completedTaskCount: number;
  totalTaskCount: number;
  taskCountResult: ITaskCountResult[];
}

export interface ITaskCountResult {
  count: number;
  statusCode: string;
  priorityCode: string;
}

export interface IAverageTimeResolutionWidgetData {
  total: number;
  rowsByPriority: IRowsByPriority;
  rowsByAssignee: IRowsByAssignee[];
}

export interface IRowsByAssignee {
  assigneeId: string;
  assigneeName: string;
  userPoolName: string;
  userPoolId: string;
  resolutionTimeInSecondsAverage: number;
  lowPriorityResolutionTimeInSecondsAverage?: any;
  mediumPriorityResolutionTimeInSecondsAverage?: any;
  highPriorityResolutionTimeInSecondsAverage: number;
}

export interface IRowsByPriority {
  totalResolutionTimeInSecondsAverage: number;
  lowPriorityResolutionTimeInSecondsAverage: number;
  mediumPriorityResolutionTimeInSecondsAverage: number;
  highPriorityResolutionTimeInSecondsAverage: number;
}

export interface IOnTimeVsDueDateTask {
  total: number;
  rowsByPriority: IOnTimeVsDueDateTaskRowsByPriority;
}

export interface IOnTimeVsDueDateTaskRowsByPriority {
  totalCompletedTasksCount: number;
  afterDueDateCompletedTasksCount: number;
  completionTimeDelayInSecondsAverage: number;
  lowCompletionTimeDelayInSecondsAverage: number;
  mediumCompletionTimeDelayInSecondsAverage: number;
  highCompletionTimeDelayInSecondsAverage: number;
  totalLowCompletedTasksCount: number;
  totalMediumCompletedTasksCount: number;
  totalHighCompletedTasksCount: number;
  totalLowAfterDueDateCompletedTasksCount: number;
  totalMediumAfterDueDateCompletedTasksCount: number;
  totalHighAfterDueDateCompletedTasksCount: number;
}

export interface ITaskCompletedLineChart {
  totalRows: number;
  rows: IRow[];
}

export interface IRow {
  totalTasksCount: number;
  completedTasksCount: number;
  month: string;
}

export interface ITaskDistributionWidget {
  total: number;
  rows: IRow[];
}

export interface IRow {
  assigneeId: string;
  assigneeName: string;
  userPoolId: string;
  userPoolName: string;
  completionRate: number;
  totalTasksCount: number;
  pendingTasksCount: number;
  missedTasksCount: number;
  completedTasksCount: number;
  afterDueDateCompletedTasksCount: number;
  completionTimeDelayInSecondsAverage: number;
}

export interface ITaskDistributionTable {
  name: string;
  taskCompletedPercentage: string;
  totalTask: number;
  pendingTask: number;
  missedTask: number;
  completedTask: number;
  completedTaskTime: number;
  afterDueDateCompletedTask: number;
  onTimeCompletedTask: number;
  id: string;
}


export interface ITaskDelayOverViewDrillDown  {
  assigneeId: string;
  assigneeName: string;
  totalTasksCount: number;
  completedTasksCount: number;
  afterDueDateCompletedTasksCount: number;
  completionTimeDelayInSecondsAverage: number;
  userPoolId: string;
  userPoolName: string;
}

export interface ITaskFilterState {
  employerData: Employer;
  dateRange: {
    from: string;
    to: string;
  };
  taskLabelsIds: any[];
  taskPoolsIds: any[];
  selectedTaskPriorityList: string[];
  patientContext: string;
  locationGroupIds: string[];
  locationIds: string[];
  isDefaultLocation?: boolean;
}

export interface IUserData {
  isBusinessOwner: boolean;
  uuid: string;
}

export interface ILocationGroupMultiSelectProps {
  onLocationGroupChange: (
    locationGroupIds: string[],
    locationIds: string[],
    isDefaultLocation: boolean
  ) => void;
  style?: StyleProp<ViewStyle>;
  locationGroupIds?: string[];
  permissionAttribute?: {
    resourceCode: string;
    action: string;
  };
}

export interface IFormattedLocationGroup {
  id: string;
  groupName: string;
  locationIds: string[];
}