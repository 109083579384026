import {Button, Spinner, Tooltip} from 'native-base';
import {getOnFocusButtonStyles, isLeadGroupsPage} from '../../../utils/commonUtils';
import {CSVLink} from 'react-csv';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {Colors} from '../../../styles/Colors';
import {IFormattedContact, IRuleContact} from '../../../Interfaces/CommonInterfaces';
import {useRef, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {GET_CONTACTS_BY_RULE_ID} from '../../../services/Lead/LeadQueries';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
import { useToast } from '../../Toast/ToastProvider';
import { getContactsWithinGroup } from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { GET_PATIENT_ID_BY_CONTACT_IDS } from '../../../services/Contacts/ContactsQueries';
import { getCurrentTimeStamp, getDateStrFromFormat } from '../../../utils/DateUtils';
import { RULES_EXECUTION_STATUSES } from '../../RightSideContainer/ContentManagement/CampaignManagement/constants';
import { DATE_FORMATS } from '../../../constants';

export const RuleContactsDownload = (props: {
  ruleId: string;
  popGroupName: string;
  contactsCount: number;
  ruleExecutionStatus?: string
}) => {
  const {ruleId, popGroupName, contactsCount, ruleExecutionStatus} = props;
  const [ruleContactState, setRuleContactState] = useState<{
    contacts: IRuleContact[];
    isLoading: boolean;
    isCsvDataAvailable: boolean;
    csvHeaders: string[];
    csvData: any[];
  }>({
    contacts: [],
    isLoading: false,
    isCsvDataAvailable: false,
    csvHeaders: [],
    csvData: [],
  });
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement>(null);
  const toast = useToast();

  const [fetchContactsByRuleId] = useLazyQuery(GET_CONTACTS_BY_RULE_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      params: {
        ruleId: ruleId,
        limit: contactsCount + 1000, // add 1000 limit, if contact added in background
      }
    }
  });

  const [fetchContactsByContactIds] = useLazyQuery(GET_PATIENT_ID_BY_CONTACT_IDS, {
    fetchPolicy: 'no-cache'
  });

  const getColumnNames = () => {
    const baseColumns = ['ehrId', 'firstName', 'middleName', 'lastName', 'email', 'phone', 'birthDate', 'gender', 'sexAtBirth', 'location', 'address', 'zipcode'];
    if (!isLeadGroupsPage()) {
      baseColumns.push('foldStatus');
    }
    return baseColumns;
  };

  const formatContactResponse = (contacts: IRuleContact[]) => {
    return contacts.map((contact: IRuleContact) => {
      let address = '';
      let zipCode = '';
      if (contact?.personAddress?.length > 0) {
        const personAddress = contact?.personAddress[0];
        if (personAddress?.line1) {
          address = personAddress?.line1;
        }
        if (personAddress?.line2) {
          address = address + ', ' + personAddress?.line2;
        }
        if (personAddress?.cities?.name) {
          address = address + ', ' + personAddress?.cities?.name;
        }
        if (personAddress?.zipcodes?.code) {
          address = address + ', ' + personAddress?.zipcodes?.code;
          zipCode = personAddress?.zipcodes?.code;
        }
        if (personAddress?.states?.name) {
          address = address + ', ' + personAddress?.states?.name;
        }
      }
      const formattedContact: IFormattedContact = {
        ehrId: contact?.patient?.patientId,
        firstName: contact.person?.firstName,
        middleName: contact.person?.middleName,
        lastName: contact.person?.lastName,
        email: contact?.email,
        phone: contact?.phoneNumber,
        birthDate: getDateStrFromFormat(contact?.person?.birthDate, DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT),
        gender: contact?.person?.gender?.value,
        sexAtBirth: contact?.person?.sexAtBirth?.value,
        location: contact?.contactPracticeLocations?.[0]?.practiceLocation?.name || '',
        address: address,
        zipcode: zipCode,
      };
      if (!isLeadGroupsPage()) {
        formattedContact.foldStatus = contact?.isActive ? 'Active' : 'Inactive';
      }
      return formattedContact;
    });
  };

  const getContactsByRuleId = async () => {
    setRuleContactState(prev => ({
      ...prev,
      isLoading: true,
    }));

    try {
      const contactIds: string[] = [];
      const filterContacts: IRuleContact[] = [];
      // This code for location based rule is not working
      // const contactResponseData = await fetchContactsByRuleId();
      // const contacts: IRuleContact[] = contactResponseData?.data.searchContacts.contacts || [];

      const ruleResponse = await getContactsWithinGroup(ruleId, undefined);
      if (!ruleResponse?.data?.data?.length) {
        setRuleContactState(prev => ({
          ...prev,
          isLoading: false,
          isCsvDataAvailable: false,
          csvData: [],
          csvHeaders: [],
        }));
        showToast(toast, `No member found for ${props.popGroupName} pop group`, ToastType.info, undefined, true);
        return;
      }
      const batchSize = 100;
      const ruleContactsResponse = ruleResponse?.data?.data || [];
      const popGroupContactLength = (ruleResponse?.data?.data || []).length;
      const promises: any[] = [];
      for (let batchIndex = 0; batchIndex < popGroupContactLength; batchIndex += batchSize) {
        promises.push(await fetchContactsByContactIds({
          variables: {
            contactIdList: ruleContactsResponse.slice(batchIndex, batchIndex + batchSize)
          }
        }));
      }
      const promisesResponse = await Promise.all(promises);
      let contacts: IRuleContact[] = [];
      promisesResponse.forEach((response: any) => {
        contacts = contacts.concat(response?.data?.contacts || []);
      });
      (contacts || []).forEach((contact: IRuleContact) => {
        // filter duplicate contacts
        if (contact?.id && !contactIds.includes(contact.id)) {
          contactIds.push(contact.id);
          filterContacts.push(contact);
        }
      });
      const formatContacts = formatContactResponse(filterContacts);
      if (formatContacts.length > 0) {
        setRuleContactState((prev: any) => {
          return {
            ...prev,
            contacts: formatContacts,
            isLoading: false,
            csvHeaders: getColumnNames(),
            csvData: formatContacts,
          };
        });
        const timeoutId = setTimeout(() => {
          setRuleContactState((prev: any) => {
            return {
              ...prev,
              isCsvDataAvailable: true,
            };
          });
        }, 500);
        return () => {
          clearTimeout(timeoutId);
        };
      } else {
        setRuleContactState(prev => ({
          ...prev,
          isLoading: false,
          isCsvDataAvailable: false,
          csvData: [],
          csvHeaders: [],
        }));
        showToast(toast, `No member found for ${props.popGroupName} pop group`, ToastType.info, undefined, true);
      }
    } catch (error) {
      setRuleContactState(prev => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const handleDownload = () => {
    // programmatically click on the csv link
    if (
      (csvLinkRef?.current as any)?.link &&
      typeof (csvLinkRef?.current as any)?.link.click === 'function'
    ) {
      (csvLinkRef?.current as any)?.link.click();
      setRuleContactState((prev: any) => {
        return {
          ...prev,
          isLoading: false,
          isCsvDataAvailable: false,
          csvData: [],
        };
      });
    }
  };

  const isAllowToShowDownloadButton =
    !ruleContactState.isLoading && !ruleContactState.isCsvDataAvailable ? true : false;

  const isDisabledExportButton = ruleContactState.isLoading ||
    contactsCount === 0 ||
    ruleExecutionStatus === RULES_EXECUTION_STATUSES.QUEUED ||
    ruleExecutionStatus === RULES_EXECUTION_STATUSES.START;

  const getCSVFileName = () => {
    return `${popGroupName}-${getCurrentTimeStamp()}.csv`;
  };

  return (
    <Tooltip label="Export Member List" placement="top">
      <Button
        style={{
          backgroundColor: 'transparent',
          marginBottom: '5px',
        }}
        _focus={{
          ...getOnFocusButtonStyles(),
        }}
        disabled={isDisabledExportButton}
        onPress={() => {
          if (!ruleContactState.csvData?.length && !ruleContactState.isLoading) {
            getContactsByRuleId();
          }
        }}>
        {ruleContactState.isLoading && <Spinner />}
        {isAllowToShowDownloadButton && (
          <FeatherIcon
            color={isDisabledExportButton ? Colors.Custom.Gray300 : Colors.Custom.Gray600}
            name="download"
            size={17}
          />
        )}
        {ruleContactState.isCsvDataAvailable && handleDownload()}
        <CSVLink
          filename={getCSVFileName()}
          data={ruleContactState?.csvData}
          headers={ruleContactState?.csvHeaders}
          ref={csvLinkRef}
          style={{display: 'none'}}
        />
      </Button>
    </Tooltip>
  );
};
