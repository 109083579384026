import BaseService from "../CommonService/BaseService";
import { EMPLOYER_BASE_PATHS } from "../Employer/EmployerServices/baseUrlPath";


export const getSectionDataByResourceCode = async (
    resourceCode:string,
    body?: any,
    endpoint?:string,
    signal?: AbortSignal,
  ) => {
   
    const baseService = BaseService.getSharedInstance().axios;
    return await baseService.post(
      `${EMPLOYER_BASE_PATHS.SECTION_URL_PATH}/${endpoint || 'section-data'}?${resourceCode}`,
      {...body},
      { signal } 
    );
  };

  export const getDrillDownDataByResourceCode = async (
    resourceCode:string,
    body?: any,
    endpoint?:string
  ) => {

    const baseService = BaseService.getSharedInstance().axios;
    return await baseService.post(
      `${EMPLOYER_BASE_PATHS.SECTION_URL_PATH}/${endpoint || 'drill-down-data'}?${resourceCode}`,
      {...body}
    );
  };
