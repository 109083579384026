export interface SingleLLMTemplate {
  id?: string;
  templateName?: string;
  isDefault?: boolean;
  accessRole?: string;
  createdBy?: string;
  isActive?: boolean;
  createdAt?: string;
  updatedAt?: string
  templateMapList?:{
    title: string;
    description: string
  }[];
  userIds?:string[];
  userRoleIds?:string[];
  languageModel?:string;
  summarizationTemplateId?:string;
}

export enum CONTEXT_PROMPT_TYPE {
  SYSTEM = 'SYSTEM',
  USER = 'USER'
}