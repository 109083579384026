import {useQuery} from '@apollo/client';
import {Skeleton, View, VStack} from 'native-base';
import {useContext, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { USER_ROLE_CODES } from '../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {ILoginUserData} from '../../../../../Interfaces/CommonInterfaces';
import InboxQueries from '../../../../../services/Inbox/InboxQueries';
import {commonBodyContentContainer} from '../../../../../styles/CommonBodyContentStyle';
import CustomTabsView from '../../../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../../../common/CustomTabsView/interfaces';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import {PracticeSchedule} from '../../../AccountSettings';
import { PracticeAvailability } from '../../../AccountSettings/PracticeAvailabilityNew/PracticeAvailability';
import {
  getAgentRecord,
  getAssignedAgents,
} from '../Helper/formatIntegrationsData';
import {styles} from '../IntegrationsStyles';
import {IntegrationEditCommonBusinessHours} from './IntegrationEditCommonBusinessHours';
// import {IntegrationEditCommonBusinessHours} from './IntegrationEditCommonBusinessHours';
import {IntegrationEditCommonCollaborators} from './IntegrationEditCommonCollaborators';
import {IntegrationEditWidgetConfig} from './IntegrationEditWidgetConfig';
import {IntegrationEditWidgetPreChat} from './IntegrationEditWidgetPreChat';
import {IntegrationEditWidgetSettings} from './IntegrationEditWidgetSettings';
import './Styles.css';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';

export const IntegrationEditWidget = () => {
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const {id, channel_id} = useParams();
  const navigate = useNavigate();
  const isCSAndWorkFlowUserEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_CS_AND_WORKFLOW_USER_ENABLED);
  const [channelData, setChannelData] = useState<any>({});
  const [inboxData, setInboxData] = useState<any>({});

  const {loading: inboxLoading} = useQuery(InboxQueries.GetInboxFromId, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {id: id},
    onCompleted: (inboxData) => {
      setInboxData(inboxData);
    },
  });

  const channelId = inboxData?.inbox?.channelId;

  const {loading: channelLoading} = useQuery(InboxQueries.GetWidgetFromId, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {id: channel_id},
    onCompleted: (channelData) => {
      setChannelData(channelData);
    },
  });

  const {loading: agentsLoading, data: agentsData} = useQuery(
    !isCSAndWorkFlowUserEnabled ? InboxQueries.GetAgentsWithFilterRoleCodes : InboxQueries.GetAgents ,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      variables: {
        accountId: userData.account_id,
        ...(!isCSAndWorkFlowUserEnabled ? {roleCodes: [USER_ROLE_CODES.EMPLOYER, USER_ROLE_CODES.WORKFLOW, USER_ROLE_CODES.WORFLOW_USER, USER_ROLE_CODES.CUSTOMER_SUCCESS]} : {roleCode: USER_ROLE_CODES.EMPLOYER})
      },
    }
  );

  const {
    loading: assignedAgentsLoading,
    data: assignedAgentsData,
    refetch,
  } = useQuery(InboxQueries.GetAssignedAgents, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {inboxId: id},
  });

  const allAgentList = getAgentRecord(agentsData);
  const assignedAgentList = getAssignedAgents(allAgentList, assignedAgentsData);
  const onCancelClick = () => {
    navigate(-1);
  };

  const tagsTabList = (data: {
    tempChannelData: any;
    tempInboxData: any;
  }): ITabsList[] => {
    const array: ITabsList[] = [
      {
        key: 'setting',
        title: 'Setting',
        tabContainerElem: () => {
          return (
            <PageBodyContainer>
              <IntegrationEditWidgetSettings
              // currentDetails={{
              //   ...channelData,
              //   ...inboxData,
              //   channel_id: channel_id,
              //   id: id,
              // }}
              />
            </PageBodyContainer>
          );
        },
      },
      {
        key: 'collaborators',
        title: 'Collaborators',
        path: 'mediaLibrary',
        tabContainerElem: () => {
          return (
            <PageBodyContainer>
              <IntegrationEditCommonCollaborators
                agentList={allAgentList}
                assignedAgentList={assignedAgentList}
                inboxId={Number(id)}
                handleUpdateAgentCallback={() => {
                  refetch();
                }}
              />
            </PageBodyContainer>
          );
        },
      },
      {
        key: 'availability',
        title: 'Business Hours',
        path: 'availability',
        tabContainerElem: () => {
          return <PracticeAvailability isInboxHours={true} inboxData={inboxData} isUserSchedule={false} />;
        },
      },
      {
        key: 'preChatForm',
        title: 'Pre Chat Form',
        tabContainerElem: () => {
          return (
            <PageBodyContainer>
              <IntegrationEditWidgetPreChat
                currentDetails={{
                  ...data.tempChannelData,
                  channel_id: channel_id,
                }}
              />
            </PageBodyContainer>
          );
        },
      },
      {
        key: 'configuration',
        title: 'Configuration',
        tabContainerElem: () => {
          return (
            <PageBodyContainer>
              <IntegrationEditWidgetConfig
                currentDetails={{
                  ...data.tempChannelData,
                  channel_id: channel_id,
                }}
              />
            </PageBodyContainer>
          );
        },
      },
    ];
    return array;
  };

  return (
    <VStack>
      <View>
        {/* <View style={styles.titleWrapper}>
          <TitleSubtitleView
            titleLabelId="editInboxTitle"
            subtitleLabelId="createInboxSubtitle"
            showBackButton={true}
            onBackClick={onCancelClick}
          />
        </View> */}
        {channelLoading &&
        inboxLoading &&
        agentsLoading &&
        assignedAgentsLoading ? (
          <View padding={6}>
            <Skeleton.Text lines={4} />
          </View>
        ) : (
          <View
            style={[
              styles.tabsWrapper,
              commonBodyContentContainer.pageContentContainer,
            ]}
          >
            <CustomTabsView
              tabsList={tagsTabList({
                tempChannelData: channelData,
                tempInboxData: inboxData,
              })}
              tabListWrapperStyle={{marginHorizontal: 8}}
            />
          </View>
        )}
      </View>
    </VStack>
  );
};
