import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  Select as MultiSelect,
  Skeleton,
  Radio,
  Space,
  InputNumber,
  Tooltip,
  Drawer,
  Checkbox,
  RadioChangeEvent,
  notification,
  Spin
} from 'antd';
import {
  Box,
  Button,
  Divider,
  FlatList,
  FormControl,
  HStack,
  Icon,
  Input,
  Pressable,
  Spacer,
  Skeleton as NativeBaseSkeleton,
  Text,
  TextArea,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useContext, useEffect, useRef, useState} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {BUTTON_TYPE, MLOV_CATEGORY} from '../../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {
  APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES,
  APPOINTMENT_TYPE_VISIT_TYPE,
  AVAILABILITY_TYPE_CODES,
  GLOBAL_ROLE_CODES,
  LOCATION_TYPE_CODES,
  MLOV_CODES,
  USER_POOL_CODES,
  USER_ROLE_CODES,
} from '../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {ScheduleEventQueries, UserQueries} from '../../../../services';
import {IAppointmentTask} from '../../../../services/Appointment/AppointmentInterface';
import {Colors} from '../../../../styles';
import {
  filterWorkflowUser,
  generateUUID,
  getAccountId,
  getAccountUUID,
  getAgentsList,
  getBooleanFeatureFlag,
  getUserFullName,
  getUserUUID,
  isAppointmentTypesGroupEnable,
} from '../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {
  getMlovId,
  getMlovIdFromCode,
  getMlovIdFromCodeAndCategory,
  getMlovListFromCategory,
} from '../../../../utils/mlovUtils';
import {TaskCheckList} from '../../../common/AppointmentTaskCheckList/TaskCheckList';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {TableTopBar} from '../../../common/TableTopBar';
import {
  getMultiSelectData,
  getParticipantsDataForUpdate,
  getPrevAppointmentGroupData,
  getRoleDataForUpdate,
  getSelectedLocationTypeIds,
  getSubmitDataForAddUpdate,
  getUpdatedErrorMessages,
  isAtClinicLocationType,
  isInValidForm,
  setUserDetailsToAppointmentGroup,
  getLocationsForAppointmentTypeAvailability,
  processAvailabilityApiResponse,
  getDefaultAvailabilityCard,
  getDefaultAvailabilitySlot,
  isInValidAppointmentTypeAvailability,
  prepareAppointmentTypeAvailabilityData,
  getAvailableToDisplayValues,
  getNestedUserRoles,
  getAppointmentTypeNamesByRestrictedUserQueryVariables,
  getApplicableByDisplayValues,
  getSelectMembersByDisplayValues,
  getAppointmentCustomFieldByCode,
  getUpdatedAppointmentCustomFields,
  checkSlotFieldsExistInCustomFields,
  getDefaultCustomFieldByCode,
  checkFieldExistInCustomFields,
} from './AppointmentTypesHelper';
import {AppointmentTypeAction} from './AppointmentTypeTopBar';
import {
  IAppointmentFormType,
  IAppointmentType,
  IAppointmentTypeAvailability,
  IAppointmentTypeAvailabilityApiResponse,
  IAppointmentTypeAvailabilityComponentState,
  IAppointmentTypeAvailabilitySlot,
  ILocalAppointmentTypeRoles,
  IUserGroupData,
} from './Interfaces';
import ParticipantGroup from './ParticipantGroup';
import './AddOrUpdateAppointmentTypes.css';
import {getEhrConfig} from '../../../PersonOmniView/MiddleContainer/CareTimeline/CareTimelineUtils';
import {useIntl} from 'react-intl';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import './AddOrUpdateAppointmentTypes.css'
import { ColorSelector } from '../../Contacts/Tags/TagsModal/ColorSelector';
import { COLOR_SELECTOR_VIEW_CODES } from '../../Contacts/Tags/TagsModal/ColorSelector/ColorSelector';
import { tagColorList } from '../../Contacts/Tags/TagsModal';
import { APPOINTMENT_COLOR_INDICATOR_SIZE, APPOINTMENT_TYPE_AVAILABILITY_ACTION, APPOINTMENT_TYPE_CATEGORY, APPOINTMENT_TYPE_COLORS, DefaultFallbackAppointmentTypeColor } from './AppointmentTypeConst';
import { getResourceAbilities } from '../../../../utils/capabilityUtils';
import { CapabilityResource } from '../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import './AddOrUpdateAppointmentTypes.css';
import { ITimezone } from '../../../../services/Location/interfaces';
import UserPracticeLocationQueries from '../../../../services/Location/UserPracticeLocationQueries';
import Feather from 'react-native-vector-icons/Feather';
import { TimezoneSelect } from '../../../common/TimezoneSelect/TimezoneSelect';
import { AppointmentTypeAvailabilityCard } from './AppointmentTypeAvailabilityCard';
import { PracticeAvailability, VIRTUAL_LOCATION_CODE } from '../PracticeAvailabilityNew/PracticeAvailability';
import { APPOINTMENT_TYPE_INTERVAL_TYPES, AppointmentApplicableBy, AppointmentAvailabilityCode, SelectMembersBy } from './constants';
import { GET_USERS_BY_RESOURCE_CODES, GET_USER_FOR_SCHEDULE_ACCESS } from '../../../../services/User/UserQueries';
import { IUser } from '../../../../Interfaces';
import { getAllAgentsList } from '../../../../services/ProfileImage/ProfileImage';
import { AppointmentUserSelect } from './AppointmentUserSelect/AppointmentUserSelect';
import { getUserAccountRoles, memberLimitCount, memberLimitData } from './AppointmentTypeUtils';
import { PopulationGroupSearchAndSelect } from './components/PopulationGroupSearchAndSelect/PopulationGroupSearchAndSelect';
import EmployerSearch from '../../../common/LabSearch/EmployerSearch';
import { isArray } from 'lodash';
import './styles.css';
import { DateTimeDurationInput } from '../../../common/DateTimeDurationInput/DateTimeDurationInput';
import { ModalActionAntSelect } from '../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import useLocationGroup from '../../../common/MemebersView/customHook/useLocationGroup';
import { EHRName } from '../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';

import permissionQueries from '../../UserAccess/permissionQueries';
import {IUserRoleMlov} from '../../Contacts/TeamMembers/AddEditUser/interfaces';
import useEHRCapabilities from '../../../../screens/BusinessStudio/useEHRCapabilities';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
import { USER_ACCESS_PERMISSION } from '../../UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import { usePermissions } from '../../../CustomHooks/usePermissions';
interface IComponentState {
  userUuids: string[];
  isAthenaLocationGroup: boolean;
}
const AddOrUpdateAppointmentTypes = (props: {
  isVisible: boolean;
  //type: AppointmentTypeAction;
  onClose: () => void;
  updateData?: IAppointmentType;
  refetchAppointments?: () => void;
  appointmentTypeAvailabilityResponse: IAppointmentTypeAvailabilityApiResponse[];
}) => {
  const { appointmentTypeAvailabilityResponse } = props;
  const intl = useIntl()
  const isUpdate = props?.updateData?.id;
  const toast = useToast();
  const loggedInUserId = getUserUUID();
  const loggedInUserFullName = getUserFullName();
  const accountUuid = getAccountUUID();
  const accountId = getAccountId();
  const [selectData, setSelectData] = useState<ILocalAppointmentTypeRoles[]>();
  const selectedRoleList = selectData?.map((item) => item?.roleId) as string[];
  const [loadingData, setLoadingData] = useState(true);
  const [uniqueNameLoading, setUniqueNameLoading] = useState(false);
  const {
    locationGroups,
    loading: locationGroupLoading,
    error: locationGroupError,
  } = useLocationGroup();
  const [userRoles, setUserRoles] = useState<IUserRoleMlov[]>();
  const [errors, setErrors] = useState<any>({});
  const timeOutRefs = useRef<NodeJS.Timeout[]>([])
  const defaultUser = {
    user: {
      label: loggedInUserFullName,
      key: loggedInUserId,
      value: loggedInUserId,
    },
    duration: 30,
  };
  const commonData = useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const accountLocationListWithEHR = commonData?.accountLocationListWithEHR || [];
  const locationGroupCodes = locationGroups?.map((group: any) => group?.code);
  const accountLocationListWithLocationGroup = accountLocationListWithEHR?.filter(athenaItem =>
    locationGroupCodes?.includes(athenaItem?.locationGroupId)
  );
  const locationGroupList = commonData.loggedInUserLocationDetails?.locationGroupList;
  const locationGroupIds = locationGroupList?.map(item => item.locationGroupId);
  const filteredLocationGroups = locationGroups.filter((group: any) =>
    locationGroupIds?.includes(group.code)
  );
  const [getUserUuidFromPracticeLocationUuid] = useLazyQuery(
    GET_USERS_BY_RESOURCE_CODES, {
      fetchPolicy: 'no-cache',
    }
  );
  const typeId = getMlovIdFromCodeAndCategory(
    MLOV_CATEGORY.AVAILABILITY_TYPE,
    AVAILABILITY_TYPE_CODES.APPOINTMENT_TYPE,
    true
  );
  const visitTypeList =  getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.APPOINTMENT_VISIT_TYPE
  );

  const defaultVisitType = (visitTypeList || []).find(visitType => {
    return visitType?.code == APPOINTMENT_TYPE_VISIT_TYPE.ROUTINE;
  });

  const ehrCapabilities = useEHRCapabilities({
    locationGroupId: props?.updateData?.locationGroupId,
  });
  const currentEHR: any = ehrCapabilities?.length
    ? ehrCapabilities?.[0]?.ehrName
    : '';
  const ehrConfig = getEhrConfig(currentEHR);
  const isAthenaEHR = ehrConfig.isAthena;
  const userSettings = commonData.userSettings;
  const isAtClinicScheduleDisabled =
    userSettings['is_at_clinic_schedule_disabled']?.value === 'True' || false;
  const allowAppointmentTypesGroup = isAppointmentTypesGroupEnable(userSettings);
    let locationType = getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.SCHEDULE_LOCATION_TYPE
  );

  const durationMlovs = getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.DATE_TIME_DURATION
  );

  const appointmentTypeCustomFieldMlovs = getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.APPOINTMENT_CUSTOM_FIELD_TYPE
  );

  const appointmentTypeCustomFieldAndDurationMlovs = [...appointmentTypeCustomFieldMlovs, ...durationMlovs];

  locationType = isAtClinicScheduleDisabled
    ? locationType.filter(
        (Type) => Type?.code !== LOCATION_TYPE_CODES.AT_CLINIC
      )
    : locationType;

  // let userRoles =
  //   getMlovListFromCategory(commonData.MLOV, MLOV_CATEGORY.USER_ROLES) || [];
  // userRoles = userRoles?.filter(
  //   (mlov) => mlov.code !== USER_ROLE_CODES.EMPLOYER
  // );

  const appointmentTypeCategory =
    getMlovListFromCategory(
      commonData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_TYPE_CATEGORY
    ) || [];
  const singleAppointmentCategoryId = getMlovIdFromCode(
    appointmentTypeCategory,
    MLOV_CODES.ONE_ON_ONE_APPOINTMENT
  );
  const groupAppointmentCategoryId = getMlovIdFromCode(
    appointmentTypeCategory,
    MLOV_CODES.GROUP_APPOINTMENT
  );
  const groupSessionAppointmentCategoryId = getMlovIdFromCode(
    appointmentTypeCategory,
    MLOV_CODES.GROUP_SESSION
  );
  const scheduleLocationTypeList =
    getMlovListFromCategory(
      commonData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.SCHEDULE_LOCATION_TYPE
    ) || [];
  const appointmentTypeUserPoolId = getMlovId(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_TEAM_USER_POOL,
    USER_POOL_CODES.APPOINTMENT_TYPE,
  );
  const [type, setType] = useState(
    props.updateData?.categoryId === groupAppointmentCategoryId
      ? AppointmentTypeAction.groupAppointment
      : props.updateData?.categoryId === groupSessionAppointmentCategoryId
      ? AppointmentTypeAction.groupSessionAppointment
      : AppointmentTypeAction.singleAppointment
  );

  const appointmentTypeRestrictedUserPoolId = getMlovId(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_TEAM_USER_POOL,
    USER_POOL_CODES.APPOINTMENT_TYPE_RESTRICTED_USER_POOL
  )

  const restrictedUserIdList = props.updateData?.restrictedUserPool?.userPoolUsers?.map(user => user.userId);

  const initEmployers = props?.updateData?.appointmentTypeEmployer &&
  props?.updateData?.appointmentTypeEmployer?.length > 0
    ? props?.updateData?.appointmentTypeEmployer?.map(
        (employer) => employer.employerId
      )
    : [];

  const initPopGroups =props?.updateData?.appointmentTypePopulationGroup &&
  props?.updateData?.appointmentTypePopulationGroup?.length > 0
    ? props?.updateData?.appointmentTypePopulationGroup?.map(
        (popGroup) => popGroup.groupId
      )
    : []

  const initSelectBy =  props?.updateData?.appointmentTypePopulationGroup?.length ||
  props?.updateData?.appointmentTypeEmployer?.length
    ? SelectMembersBy.SELECTED_MEMBERS
    : SelectMembersBy.ALL_MEMBERS;

  const isCancelLimitFieldExist = checkFieldExistInCustomFields(
    props?.updateData?.customFields || [],
    appointmentTypeCustomFieldMlovs,
    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.CANCEL_LIMIT
  );

  const isRescheduleLimitFieldExist = checkFieldExistInCustomFields(
    props?.updateData?.customFields || [],
    appointmentTypeCustomFieldMlovs,
    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.RESCHEDULE_LIMIT
  );

  const [componentState, setComponentState] = useState<IComponentState>({
    userUuids: [],
    isAthenaLocationGroup: false,
  });
  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code, MAIN_MENU_CODES.TASKS);
  const allowedAccountLocations = accountLocationListWithEHR?.filter((location) => {
    return permissionConfig?.allowedLocationIds?.includes(location?.uuid)
  })?.map((location) => {
    return {
      ...location,
      uuid: location?.uuid,
      name: location?.practiceLocation?.name,
    }
  }) || [];
  const allowedAccountLocationIds =  allowedAccountLocations?.map((location) => location?.uuid) || [];

  const [formData, setFormData] = useState<IAppointmentFormType>({
    duration:
      props.updateData?.duration ||
      (type === AppointmentTypeAction.groupAppointment ? 0 : 30),
    description: props.updateData?.description || '',
    eventName: props.updateData?.eventName || '',
    endDate: props.updateData?.endDate || undefined,
    beginDate: props.updateData?.beginDate || undefined,
    locationTypeId: getSelectedLocationTypeIds(
      props.updateData?.locationTypeId || '',
      props.updateData?.isPatientFacingLocationType || false,
      locationType
    ),
    intervalType: checkSlotFieldsExistInCustomFields(
      props?.updateData?.customFields || [],
      appointmentTypeCustomFieldMlovs
    )
      ? APPOINTMENT_TYPE_INTERVAL_TYPES.BOOK_WITHIN_DAYS
      : APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME,
    isShownToPatient:
      props.updateData?.isShownToPatient !== undefined
        ? props.updateData?.isShownToPatient
        : false,
    isRsvpEnabled: props.updateData?.isRsvpEnabled ?? false,
    appointmentTypeGroup:
      type === AppointmentTypeAction.groupAppointment
        ? [defaultUser]
        : getPrevAppointmentGroupData(
            userRoles || [],
            props.updateData?.appointmentTypeGroup
          ),
    categoryId: props.updateData?.categoryId || singleAppointmentCategoryId,
    isPatientFacingLocationType: props.updateData?.isPatientFacingLocationType,
    existingTasks: props.updateData?.tasks || [],
    currentTasks: props.updateData?.tasks || [],
    shortName: props.updateData?.shortName || '',
    colorCode:
      props.updateData?.appointmentCardProperties?.bgColorPrimary ||
      props.updateData?.colorCode ||
      DefaultFallbackAppointmentTypeColor,
    availabilityTypeCode:
      props.updateData?.availabilityTypeCode ||
      AppointmentAvailabilityCode.ROLE,
    appointmentTypeUserPool: props.updateData?.userPool,
    restrictedUsers: restrictedUserIdList || [],
    restrictedUserPool: props?.updateData?.restrictedUserPool,
    providers: {
      primary:
        props.updateData?.userPool?.userPoolUsers
          ?.filter((item) => item.isDefault)
          ?.map((item) => item.userId) || [],
      secondary:
        props.updateData?.userPool?.userPoolUsers
          ?.filter((item) => !item.isDefault)
          ?.map((item) => item.userId) || [],
    },
    selectedApplicableForType: initSelectBy,
    employers: initEmployers,
    popGroups: initPopGroups,
    memberLimit: 0,
    categoryType: type,
    customFields: props?.updateData?.customFields || [],
    cancelIntervalType: isCancelLimitFieldExist
      ? APPOINTMENT_TYPE_INTERVAL_TYPES.CANCEL_BEFORE_TIME
      : APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME,
    rescheduleIntervalType: isRescheduleLimitFieldExist
        ? APPOINTMENT_TYPE_INTERVAL_TYPES.RESCHEDULE_BEFORE_TIME
        : APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME,
    isLimitCancelRescheduleEnabled: isCancelLimitFieldExist || isRescheduleLimitFieldExist || false,
    visitTypeId: props?.updateData?.visitTypeId || defaultVisitType?.id,
    locationGroupId: props?.updateData?.locationGroupId,
    locationGroupName: '',
  });
  const locationIdsBySelectedLocationGroup = accountLocationListWithEHR
    ?.filter(
      (location) => location.locationGroupId === formData.locationGroupId
    )
    .map((location) => location.uuid);
  
  const appointmentCapability = getResourceAbilities(
    CapabilityResource.appointment,
    formData?.locationGroupId,
    ''
  );
  const appointmentNameMaxChar = appointmentCapability.keyAllowedOperations?.appointmentType?.maxCharLimit;

  const slotWithinLimitCustomField = getAppointmentCustomFieldByCode(
    formData.customFields || [],
    appointmentTypeCustomFieldMlovs,
    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_LIMIT
  );
  const slotWithinOffsetCustomField = getAppointmentCustomFieldByCode(
    formData.customFields || [],
    appointmentTypeCustomFieldMlovs,
    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_OFFSET
  );
  const cancelLimitCustomField = getAppointmentCustomFieldByCode(
    formData.customFields || [],
    appointmentTypeCustomFieldMlovs,
    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.CANCEL_LIMIT
  );

  const rescheduleLimitCustomField = getAppointmentCustomFieldByCode(
    formData.customFields || [],
    appointmentTypeCustomFieldMlovs,
    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.RESCHEDULE_LIMIT
  );


  const [userAvailability, setUserAvailability] = useState<{
    showAvailability: boolean;
    selectedUsers: string[];
  }>({
    showAvailability: false,
    selectedUsers: [],
  });

  const [restrictedUserFeildLoading, setRestrictedUserFeildLoading] = useState(false);
  const [
    appointmentTypeNamesByRestrictedUser,
    setAppointmentTypeNamesByRestrictedUser,
  ] = useState<{list: string[]; show: boolean}>({
    list: [],
    show: false,
  });
  const isRoleOrCareTeamBasedAppointmentType =  [
    AppointmentAvailabilityCode.ROLE,
    AppointmentAvailabilityCode.CARE_TEAM,
  ].includes(formData.availabilityTypeCode as AppointmentAvailabilityCode)

    const onSelectMemberByChange = (e: RadioChangeEvent) => {
      if (isUpdate) {
        if (e.target.value === initSelectBy) {
          setEmployersAndPopGroupsToInitState();
        } else {
          resetEmployersAndPopGroups();
        }
      } else {
        resetEmployersAndPopGroups();
      }
      setFormData((prev) => ({...prev, selectedApplicableForType: e.target.value }));
    }

  const [appointmentTypeAvailability, setAppointmentTypeAvailability] = useState<IAppointmentTypeAvailabilityComponentState>({
    isLoading: false,
    showError: false,
    availabilityResponse: [],
    appointmentTypeAvailabilityData: [],
    accountLocations: [],
    locationGroupLocations: [],
    timezoneId: undefined,
  });

  const [accountUsers, setAccountUsers] = useState<{
    loading: boolean,
    list: any[],
    profileUrlsByUserId: { [index: string]: string; },
  }>({
    loading: true,
    list: [],
    profileUrlsByUserId: {},
  });

  const [
    addOrUpdateAppointmentType,
    {loading: addOrUpdateAppointmentTypeLoading},
  ] = useMutation(ScheduleEventQueries.ADD_OR_UPDATE_APPOINTMENT_TYPE, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });
  useQuery(permissionQueries.getAccountRoles, {
    fetchPolicy: 'no-cache',
    variables: {
      category: [MLOV_CATEGORY.GLOBAL_USER_ROLES,MLOV_CATEGORY.USER_ROLES]
    },
    onCompleted: (data: any) => {
      let userAccountRoles: IUserRoleMlov[] = []
      if (data?.accountRoles?.length) {
        userAccountRoles = getUserAccountRoles(data.accountRoles, [
          USER_ROLE_CODES.EMPLOYER,
          GLOBAL_ROLE_CODES.SUPER_ANALYTIC,
        ]);
      }
      if(isMultiTenancyEnabled){
        userAccountRoles = userAccountRoles.filter(role => (role.code !== GLOBAL_ROLE_CODES.GLOBAL_ADMIN ))
      }
        setUserRoles([...userAccountRoles])
    },
    onError: (data: any) => {
      setLoadingData(false);
    },
  });
  const [getAppointmentTypesByRestrictedUsers] = useLazyQuery(
    ScheduleEventQueries.GET_APPOINTMENT_TYPES_BY_RESTRICTED_USERS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getShortNameAggregate] = useLazyQuery(
    ScheduleEventQueries.GET_APPOINTMENT_SHORTNAME_COUNT,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (data: any) => {
        if (data?.aggregateAppointmentTypes?.aggregate?.count) {
          setErrors((prev: any) => {
            return {
              ...prev,
              duplicateShortName: 'Short name already exists',
            };
          });
        } else {
          setErrors((prev: any) => {
            return {
              ...prev,
              duplicateShortName: '',
            };
          });
        }
        setUniqueNameLoading(false);
      },
      onError(error) {
        setUniqueNameLoading(false);

      },
    }
  );

  const [getAppointmentTypeNameAggregate] = useLazyQuery(
    ScheduleEventQueries.GET_APPOINTMENT_TYPE_NAME_COUNT,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (data: any) => {
        if (
          data?.aggregateAppointmentTypes?.aggregate?.count &&
          props?.updateData?.eventName !== formData?.eventName
        ) {
          setErrors((prev: any) => {
            return {
              ...prev,
              duplicateEventName: 'Appointment type name already exists',
            };
          });
        } else {
          setErrors((prev: any) => {
            return {
              ...prev,
              duplicateEventName: '',
            };
          });
        }
        setUniqueNameLoading(false);
      },
      onError(error) {
        setUniqueNameLoading(false);

      },
    }
  );

  const checkUniqueShortName = (shortName: string) => {
    setUniqueNameLoading(true);
    getShortNameAggregate({
      variables: {
        searchString: shortName,
      },
    });
  };

  const checkAppointmentTypeName = (eventName: string) => {
    setUniqueNameLoading(true);
    getAppointmentTypeNameAggregate({
      variables: {
        searchString: eventName,
      },
    });
  };

  if (
    props.updateData &&
    props.updateData?.appointmentTypeGroup.length > 0 &&
    type === AppointmentTypeAction.groupAppointment
  ) {
    const userIds = props.updateData?.appointmentTypeGroup.map((data: any) => {
      return data.userId;
    });
    useQuery(UserQueries.GET_USERS_WITH_IDS, {
      variables: {
        userIds: userIds,
        accountId: getAccountUUID(),
      },
      onCompleted: (data: any) => {
        if (data.users && data.users.length > 0 && props.updateData) {
          const participants = setUserDetailsToAppointmentGroup(
            props?.updateData?.appointmentTypeGroup,
            data.users
          );
          setFormData({...formData, appointmentTypeGroup: participants});
          setLoadingData(false);
        } else {
          setFormData({...formData, appointmentTypeGroup: [defaultUser]});
          setLoadingData(false);
        }
      },
      onError: (data: any) => {
        setLoadingData(false);
      },
    });
  } else if (loadingData) {
    setLoadingData(false);
  }

  const [getAccountUsers,{loading: getAccountUsersLoading}] = useLazyQuery(GET_USER_FOR_SCHEDULE_ACCESS, {
    fetchPolicy: 'no-cache'
  });

  const agentList = getAgentsList()

  async function getAgentData(userList: IUser[]) {
    if (!userList?.length) {
      return;
    }
    if (agentList?.length) {
      const agentData = agentList;
      const profileUrlsByUserId: { [index: string]: string; } = {};
      userList.forEach((user) => {
        const userId = user.id;
        if (!userId) {
          return;
        }
        const matchUser = agentData?.find((agent: any) => {
          if (userId === agent?.id) {
            return agent;
          }
        });
        if (!matchUser) {
          return;
        }
        const thumbnail = matchUser?.thumbnail;
        if (!thumbnail || thumbnail?.includes('404')) {
          return;
        }
        profileUrlsByUserId[user.uuid as string] = thumbnail;
      });
      setAccountUsers((prev) => ({ ...prev, profileUrlsByUserId: profileUrlsByUserId }));
    }
  }

  const onSubmit = () => {
    try {
      const updatedErrors = getUpdatedErrorMessages(
        formData,
        type,
        selectData || [],
        appointmentCapability,
        ehrConfig,
        appointmentTypeCustomFieldAndDurationMlovs,
        {
          isMultiTenancyEnabled,
        }
      );
      setErrors(updatedErrors);
      const isInValidAvailabilityData = isInValidAppointmentTypeAvailability(appointmentTypeAvailability.appointmentTypeAvailabilityData);
      let isInValidFormInput =  false;
      if (updatedErrors && Object.keys(updatedErrors)?.length) {
        Object.keys(updatedErrors).forEach((key: string) => {
          if (updatedErrors[key]?.length) {
            isInValidFormInput = true;
          }
        });
      }
      setAppointmentTypeAvailability(prev => {
        return {
          ...prev,
          showError: isInValidAvailabilityData,
        }
      });
      if (isInValidFormInput || isInValidForm(formData, selectData || [], type, ehrConfig, appointmentTypeCustomFieldAndDurationMlovs, appointmentCapability) || isInValidAvailabilityData) {
        // is trying to add restricted user without availability
        if (
          appointmentTypeAvailability.appointmentTypeAvailabilityData &&
          appointmentTypeAvailability.appointmentTypeAvailabilityData[0]?.slots
            ?.length === 0 &&
          formData?.restrictedUsers.length > 0
        ) {
          notification.error({
            message: 'Users restricted without availability',
          });
        }
        return;
      }
      const timezoneId = appointmentTypeAvailability.timezoneId;
      const availabilityApiResponse = appointmentTypeAvailability?.availabilityResponse || [];
      const updatedAvailabilityWithSlots = appointmentTypeAvailability.appointmentTypeAvailabilityData || [];
      const appointmentTypeAvailabilityInputData = prepareAppointmentTypeAvailabilityData(timezoneId, typeId, availabilityApiResponse, updatedAvailabilityWithSlots);
      const submitData = getSubmitDataForAddUpdate(
        formData,
        scheduleLocationTypeList,
        props?.updateData?.appointmentTypeGroup || [],
        selectData,
        props?.updateData,
        appointmentTypeCustomFieldAndDurationMlovs,
        {
          appointmentTypeUserPoolId,
          appointmentTypeRestrictedUserPoolId
        }
      );
      submitData['appointmentTypeAvailability'] = appointmentTypeAvailabilityInputData || [];
      addOrUpdateAppointmentType({
        variables: {
          data: submitData,
        },
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
        onCompleted: (response) => {
          props?.refetchAppointments?.()
          props.onClose();
          showToast(
            toast,
            `Appointment type ${isUpdate ? 'updated' : 'added'} successfully`,
            ToastType.info,
            2000
          );
        },
        onError(error) {
          props.onClose();
          showToast(toast, 'Something went wrong!!!', ToastType.error);
        },
      });
    } catch (error) {
      showToast(toast, 'Something went wrong!!!', ToastType.error);
    }
  };

  const onBookingSpanChange = (value: string) => {
    let data: IAppointmentFormType = {...formData, intervalType: value};
    if (value === APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME) {
      data = {
        ...formData,
        beginDate: undefined,
        endDate: undefined,
        intervalType: value,
      };
      setFormData((prev) => ({
        ...prev,
        beginDate: undefined,
        endDate: undefined,
        intervalType: value,
      }));
    } else {
      const slotWithinLimitCustomField = getDefaultCustomFieldByCode(appointmentTypeCustomFieldMlovs, durationMlovs, APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_LIMIT);
      const slotWithinOffsetCustomField = getDefaultCustomFieldByCode(appointmentTypeCustomFieldMlovs, durationMlovs, APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_OFFSET);
      setFormData((prev) => ({
        ...prev,
        intervalType: value,
        ...(!checkSlotFieldsExistInCustomFields(formData.customFields || [], appointmentTypeCustomFieldMlovs) &&
          slotWithinLimitCustomField &&
          slotWithinOffsetCustomField && {
            customFields: [
              ...prev.customFields || [],
              slotWithinLimitCustomField,
              slotWithinOffsetCustomField,
            ],
          }),
      }));
    }
    if (errors.date) {
      setErrors(getUpdatedErrorMessages(data, type, undefined, appointmentCapability, ehrConfig, appointmentTypeCustomFieldAndDurationMlovs));
    }
  };

  const onCancelOrRescheduleSpanChange = (value: string, intervalTypeKey: string, fieldCode: string) => {
    if (value === APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME) {
      setFormData((prev) => ({
        ...prev,
        [intervalTypeKey]: value,
      }));
    } else {
      const cancelLimitCustomField = getDefaultCustomFieldByCode(appointmentTypeCustomFieldMlovs, durationMlovs, fieldCode);
      const updatedCustomFields = getUpdatedAppointmentCustomFields(formData.customFields || [], cancelLimitCustomField);
      setFormData((prev) => ({
        ...prev,
        [intervalTypeKey]: value,
        ...(!checkFieldExistInCustomFields(formData.customFields || [], appointmentTypeCustomFieldMlovs, fieldCode) &&
        cancelLimitCustomField &&
          {
            customFields: updatedCustomFields,
          }),
      }));
    }
  };

  const [getAccountPracticeLocations] = useLazyQuery(
    UserPracticeLocationQueries.GetAccountPracticeLocations, { fetchPolicy: 'no-cache' }
  );

  const configureAppointmentTypeAvailability = async () => {
    setAppointmentTypeAvailability(prev => {
      return {
        ...prev,
        isLoading: true,
      }
    });
    const accountLocationResponse = await getAccountPracticeLocations();
    const accountLocations = getLocationsForAppointmentTypeAvailability(userSettings, ehrCapabilities, accountLocationResponse?.data?.accountLocations || []);
    const appointmentTypeAvailabilityData = processAvailabilityApiResponse(appointmentTypeAvailabilityResponse);

    let locationGroupLocations: any[] = [];
    if (formData?.locationGroupId && accountLocations?.length) {
      locationGroupLocations = (accountLocations || []).filter(
        (item) => item?.locationGroupId === formData.locationGroupId || item?.key === VIRTUAL_LOCATION_CODE
      );
    }

    setAppointmentTypeAvailability(prev => {
      return {
        ...prev,
        isLoading: false,
        showError: false,
        accountLocations: accountLocations,
        availabilityResponse: appointmentTypeAvailabilityResponse,
        appointmentTypeAvailabilityData,
        timezoneId: appointmentTypeAvailabilityResponse?.length ? appointmentTypeAvailabilityResponse?.[0]?.timezoneId : undefined,
        locationGroupLocations: locationGroupLocations,
      }
    });
  }

  const configureAccountUsers = async () => {
    try {
      const accountUserParams = { accountUUID: accountUuid, roleCode: USER_ROLE_CODES.EMPLOYER };
      setAccountUsers((prev) => ({...prev, loading: true}));
      const response = await getAccountUsers({ variables: accountUserParams });
      let accountUsers: IUser[] = [];
      if (response?.data?.users?.length) {
        const users = filterWorkflowUser(response.data.users, loggedInUserId);
        accountUsers = users;
      }
      await getAgentData(accountUsers);
      setAccountUsers((prev) => ({ ...prev, loading: false, list: accountUsers }));
    } catch (error) {

      setAccountUsers((prev) => ({ ...prev, loading: false}));
    }
  }

  // extract user roles from api to state for select
  useEffect(() => {
    if (
      props.updateData?.appointmentTypeGroup &&
      props.updateData?.appointmentTypeGroup.length > 0
    ) {
      const data: ILocalAppointmentTypeRoles[] = getMultiSelectData(
        userRoles || [],
        props.updateData?.appointmentTypeGroup
      );
      setSelectData(data);
    }
  }, [props.updateData?.appointmentTypeGroup, userRoles?.length]);

  useEffect(() => {
    configureAppointmentTypeAvailability();
    configureAccountUsers();
    return () => {
      timeOutRefs.current.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
    }
  },[]);

  useEffect(() => {
    if (props.updateData?.categoryId?.length) {
      const type =
      props.updateData?.categoryId === groupAppointmentCategoryId
        ? AppointmentTypeAction.groupAppointment
        : props.updateData?.categoryId === groupSessionAppointmentCategoryId
        ? AppointmentTypeAction.groupSessionAppointment
        : AppointmentTypeAction.singleAppointment;
     setType(type);
     const memberLimitData = memberLimitCount(props.updateData?.memberLimit || {});
     setFormData((prev) => ({
      ...prev,
      memberLimit: memberLimitData,
      categoryId: props.updateData?.categoryId,
      categoryType: type,
    }));
    }
  }, [props.updateData?.categoryId]);

  useEffect(() => {
    let categoryId = props.updateData?.categoryId || '';
    if (type === AppointmentTypeAction.groupAppointment) {
      categoryId = groupAppointmentCategoryId;
    } else if (type === AppointmentTypeAction.groupSessionAppointment) {
      categoryId = groupSessionAppointmentCategoryId;
    } else {
      categoryId = singleAppointmentCategoryId;
    }

    setFormData((prev) => ({
      ...prev,
      categoryId: categoryId,
      categoryType: type,
    }));
  }, [type]);

  const handleAppointmentAvailabilityChanges = (action: string, availability: IAppointmentTypeAvailability, selectedSlot?: IAppointmentTypeAvailabilitySlot) => {
    switch(action) {
      case APPOINTMENT_TYPE_AVAILABILITY_ACTION.ADD_NEW_AVAILABILITY:
        onAddNewAvailabilityHandler();
        break;
      case APPOINTMENT_TYPE_AVAILABILITY_ACTION.ADD_NEW_LOCATION:
        updateAvailabilityLocationIds(availability);
        break;
      case APPOINTMENT_TYPE_AVAILABILITY_ACTION.ADD_NEW_SLOT:
        handleAddNewSlot(availability);
        break;
      case APPOINTMENT_TYPE_AVAILABILITY_ACTION.DELETE_AVAILABILITY:
        handleDeleteAvailability(availability);
        break;
      case APPOINTMENT_TYPE_AVAILABILITY_ACTION.DELETE_LOCATION:
        updateAvailabilityLocationIds(availability);
        break;
      case APPOINTMENT_TYPE_AVAILABILITY_ACTION.DELETE_SLOT:
        handleDeleteSlot(availability, selectedSlot);
        break;
      case APPOINTMENT_TYPE_AVAILABILITY_ACTION.WEEKDAY_UPDATE:
        handleWeekDaysUpdate(availability);
        break;
      case APPOINTMENT_TYPE_AVAILABILITY_ACTION.UPDATE_SLOT:
        updateSlot(availability, selectedSlot);
        break;
      default:
        break;
    }
  }

  const findAvailabilityById = (selectedAvailability: IAppointmentTypeAvailability, prevAvailability: IAppointmentTypeAvailability[]) => {
    return (prevAvailability || []).find(availability => {
      return availability?.cardId === selectedAvailability?.cardId;
    });
  }

  const findSlotById = (selectedAvailability: IAppointmentTypeAvailability, selectedSlot?: IAppointmentTypeAvailabilitySlot) => {
    return (selectedAvailability?.slots || []).find(slot => {
      return slot?.id === selectedSlot?.id;
    });
  }

  const isAppointmentTypeAvailabilityExist = (): boolean => {
    return appointmentTypeAvailability?.appointmentTypeAvailabilityData?.length > 0;
  }

  const handleWeekDaysUpdate = (availability: IAppointmentTypeAvailability) => {
    const prevAvailability = appointmentTypeAvailability.appointmentTypeAvailabilityData || [];
    const selectedAvailability = findAvailabilityById(availability, prevAvailability);
    if (selectedAvailability?.cardId) {
      selectedAvailability.weekDays = availability.weekDays || [];
      setAppointmentTypeAvailability(prev => {
        return {
          ...prev,
          appointmentTypeAvailabilityData: prevAvailability,
        };
      });
    }
  }

  const handleAddNewSlot = (availability: IAppointmentTypeAvailability) => {
    const prevAvailability = appointmentTypeAvailability.appointmentTypeAvailabilityData || [];
    const selectedAvailability = findAvailabilityById(availability, prevAvailability);
    if (selectedAvailability?.cardId) {
      const slot = getDefaultAvailabilitySlot();
      selectedAvailability.slots.push(slot);
      setAppointmentTypeAvailability(prev => {
        return {
          ...prev,
          appointmentTypeAvailabilityData: prevAvailability,
        };
      });
    }
  };

  const updateAvailabilityLocationIds = (availability: IAppointmentTypeAvailability) => {
    const prevAvailability = appointmentTypeAvailability.appointmentTypeAvailabilityData || [];
    const selectedAvailability = findAvailabilityById(availability, prevAvailability);
    if (selectedAvailability?.cardId) {
      selectedAvailability.isVirtualLocation = availability.isVirtualLocation;
      selectedAvailability.locationIds = availability?.locationIds || [];
      setAppointmentTypeAvailability(prev => {
        return {
          ...prev,
          appointmentTypeAvailabilityData: prevAvailability,
        };
      });
    }
  };

  const handleDeleteSlot = (availability: IAppointmentTypeAvailability, selectedSlot?: IAppointmentTypeAvailabilitySlot) => {
    const prevAvailability = appointmentTypeAvailability.appointmentTypeAvailabilityData || [];
    const selectedAvailability = findAvailabilityById(availability, prevAvailability);
    if (selectedAvailability?.cardId) {
      const slot = findSlotById(selectedAvailability, selectedSlot);
      if (slot?.id) {
        slot.isDeleted = true;
        setAppointmentTypeAvailability(prev => {
          return {
            ...prev,
            appointmentTypeAvailabilityData: prevAvailability,
          };
        });
      }
    }
  }

  const updateSlot = (availability: IAppointmentTypeAvailability, selectedSlot?: IAppointmentTypeAvailabilitySlot) => {
    const prevAvailability = appointmentTypeAvailability.appointmentTypeAvailabilityData || [];
    const selectedAvailability = findAvailabilityById(availability, prevAvailability);
    if (selectedAvailability?.cardId) {
      const slot = findSlotById(selectedAvailability, selectedSlot);
      if (slot?.id) {
        slot.startTime = selectedSlot?.startTime || '';
        slot.endTime = selectedSlot?.endTime || '';
        setAppointmentTypeAvailability(prev => {
          return {
            ...prev,
            appointmentTypeAvailabilityData: prevAvailability,
          };
        });
      }
    }
  };

  const handleDeleteAvailability = (availability: IAppointmentTypeAvailability) => {
    const prevAvailability = appointmentTypeAvailability.appointmentTypeAvailabilityData || [];
    if(prevAvailability.length === 1) {
      setFormData((prev) => ({...prev, restrictedUsers: []}));
    }
    const selectedAvailability = findAvailabilityById(availability, prevAvailability);
    if (selectedAvailability?.cardId) {
      selectedAvailability.isDeleted = true;
      setAppointmentTypeAvailability(prev => {
        return {
          ...prev,
          appointmentTypeAvailabilityData: prevAvailability,
        };
      });
    }
  }

  const getUserListForRestrictUsersDropdown = (): IUser[] => {
    switch (formData.availabilityTypeCode) {
      case AppointmentAvailabilityCode.PROVIDER:
        // show primary and secondary dropdown users
        return [
          ...accountUsers.list.filter(
            (item) =>
              formData.providers.secondary.includes(item.uuid) ||
              formData.providers.primary.includes(item.uuid)
          ),
        ];
      case AppointmentAvailabilityCode.ROLE:
        // show user by selected roles from account users
        return getFilteredAccountUsersByRoleIds();
      case AppointmentAvailabilityCode.CARE_TEAM:
        // show all account users(except employers)
        return accountUsers.list;
      default:
        return []
    }
  }

  const getFilteredAccountUsersByRoleIds = (): IUser[] => {
    const filteredUserList:IUser[] = []
    accountUsers?.list.map((user) => {
      const userRoleIds = getNestedUserRoles(user?.userRoles);
      if (
        userRoleIds?.filter((userRoleId) => selectedRoleList?.includes(userRoleId)).length >
        0
      ) {
        filteredUserList.push(user);
      }
    });
    return filteredUserList
  };

  const handleRestrictUserListChangeWhenSelectedRoleChange = () => {
    let showToastMessage = false
    const filteredAccountUsersUUIDBySelectedRoles =
      getFilteredAccountUsersByRoleIds().map((user) => user?.uuid);
    const list = formData?.restrictedUsers?.filter((userId) => {
      if(filteredAccountUsersUUIDBySelectedRoles.includes(userId)){
        return true ;
      } else {
        showToastMessage = true;
      }
      return false;
    }
    );
    const titmeOutId = setTimeout(() => setRestrictedUserFeildLoading(false), 500);
    timeOutRefs.current.push((titmeOutId))
    setFormData((prev) => ({...prev, restrictedUsers: list}));
    if (showToastMessage) {
      notification.info({
        message:
          'Blocked users associated with deleted user role have been removed',
        duration: 3,
      });
    }
  };

  const handleRestrictUserListChangeWhenSelectedProviderChange = () => {
    let showToastMessage = false;
    const { primary, secondary } = formData.providers;
    const userIdsSet = new Set([...primary, ...secondary]);
    const updatedRestrictedUsers = formData.restrictedUsers.filter(
      (restrictedUserId) => {
        if (userIdsSet.has(restrictedUserId)) {
          return true;
        } else {
          showToastMessage = true;
          return false;
        }
      }
    );
    setFormData(prev => ({
      ...prev,
      restrictedUsers: updatedRestrictedUsers
    }));
    const titmeOutId = setTimeout(() => setRestrictedUserFeildLoading(false), 500);
    timeOutRefs.current.push((titmeOutId))
    if (showToastMessage) {
      notification.info({
        message:
          'Deleted providers have also been removed from blocked user selection',
        duration: 3,
      });
    }
  };

  const handleRestrictUserChange = (userList: string[]) => {
    setFormData((prev) => ({...prev, restrictedUsers: userList}));
  };

  useEffect(() => {
    if(formData.availabilityTypeCode === AppointmentAvailabilityCode.ROLE  && !accountUsers.loading){
      setRestrictedUserFeildLoading(true);
      handleRestrictUserListChangeWhenSelectedRoleChange();
    }
  },[selectData,accountUsers]);

  useEffect(() => {
    if(formData.locationGroupId){
      onAddNewAvailabilityHandlerByLocation();
    }
  }, [formData.locationGroupId]);

  useEffect(() => {
    if (
      formData.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER &&
      (formData.providers?.primary?.length || formData.providers?.secondary?.length)
    ) {
      setRestrictedUserFeildLoading(true);
      handleRestrictUserListChangeWhenSelectedProviderChange();
    }
  },[formData.providers]);

  useEffect(() => {
    if (formData.restrictedUsers.length === 0) {
      setAppointmentTypeNamesByRestrictedUser({
        show: false,
        list: []
      });
      return;
    }
    getAppointmentTypesByRestrictedUsers({
      variables: {
        whereCondition: getAppointmentTypeNamesByRestrictedUserQueryVariables(
          formData.restrictedUsers,
          props.updateData?.id
        ),
      },
      onCompleted: (data) => {
        const apptList: string[] = data?.appointmentTypes?.map(
          (apptType: {eventName: string}) => apptType?.eventName
        );
        setAppointmentTypeNamesByRestrictedUser({
          show: apptList.length > 0,
          list: apptList,
        });
      },
    });
  },[formData.restrictedUsers])

  const onAddNewAvailabilityHandler = () => {
    const prevAvailability = appointmentTypeAvailability.appointmentTypeAvailabilityData || [];
    prevAvailability.push(getDefaultAvailabilityCard(true));
      setAppointmentTypeAvailability((prev) => {
        return {
          ...prev,
          appointmentTypeAvailabilityData: prevAvailability,
        };
      });
  }

  const onAddNewAvailabilityHandlerByLocation = () => {
    const prevAvailability = appointmentTypeAvailability.appointmentTypeAvailabilityData || [];
    if (formData.locationGroupId) {
      const filterLocations =
        appointmentTypeAvailability.accountLocations.filter(
          (item) => item?.locationGroupId === formData.locationGroupId || item?.key === VIRTUAL_LOCATION_CODE
        );
      setAppointmentTypeAvailability((prev) => {
        return {
          ...prev,
          locationGroupLocations: filterLocations,
        };
      });
      const updatedPrevAvailability = prevAvailability.map((availability) => ({
        ...availability,
        locationIds: filterLocations.map((item) => item.key),
      }));
      setAppointmentTypeAvailability((prev) => {
        return {
          ...prev,
          appointmentTypeAvailabilityData: updatedPrevAvailability,
        };
      });
    }
  }

  const renderAddNewAvailability = (titleId: string) => {
    return (
      <Pressable
        flexDir="row"
        alignItems="center"
        onPress={() => {
           onAddNewAvailabilityHandler();
        }}
      >
        <Feather name="plus" color={Colors.Custom.PurpleColor} />
        <Text ml={1} color={Colors.Custom.PurpleColor}>
          {intl.formatMessage({id: titleId})}
        </Text>
      </Pressable>
    );
  };

  const renderAvailabilityCard = (data: any) => {
    const availabilityCard = data?.item as IAppointmentTypeAvailability;
    if (availabilityCard.isVirtualLocation && !availabilityCard.locationIds.includes(VIRTUAL_LOCATION_CODE)) {
      availabilityCard.locationIds.push(VIRTUAL_LOCATION_CODE);
    }
    return (
      <AppointmentTypeAvailabilityCard
        key={availabilityCard.cardId}
        showError={appointmentTypeAvailability.showError}
        availability={availabilityCard}
        accountLocation={isMultiTenancyEnabled ? appointmentTypeAvailability.locationGroupLocations : appointmentTypeAvailability.accountLocations}
        onAvailabilityChangeHandler={handleAppointmentAvailabilityChanges}
      />
    )
  }

  const getMessageForSelectedUsersAndAvailability = (primaryUsers: any[], secondaryUsers: any[], availabilityLocations: string[]): string => {
    if (!availabilityLocations.length) {
      return '';
    }
    const nonMatchingLocationUser: string[] = [];
    primaryUsers.forEach((primaryUser) => {
      const userWiseLocationList = [availabilityLocations, getAccountLocationUuidsFromUser(primaryUser)];
      secondaryUsers.forEach((secondaryUser) => {
        userWiseLocationList.push(getAccountLocationUuidsFromUser(secondaryUser));
      })
      const commonLocations = userWiseLocationList.reduce((previousList, currentList) => {
        return previousList.filter(element => currentList.includes(element));
      });
      if (!commonLocations.length) {
        nonMatchingLocationUser.push(primaryUser.name);
      }
    })
    if (nonMatchingLocationUser.length > 0) {
      return `Selected availability location(s) are not associated with selected users`;
    }
    return '';
  }

  const renderAppointmentTypeAvailability = () => {
    const availabilityData = appointmentTypeAvailability?.appointmentTypeAvailabilityData?.filter(
      (availabilityCard) => { return !availabilityCard.isDeleted; }
    );
    const availabilityLocations: string[] = [];
    availabilityData?.forEach((availability) => {
      availability?.locationIds?.forEach((locationId: string) => {
        if (!availabilityLocations?.includes(locationId)) {
          availabilityLocations.push(locationId);
        }
      })
    })
    const infoMessage = getMessageForSelectedUsersAndAvailability(
      accountUsers.list.filter(
        (item) => formData.providers.primary.includes(item.uuid)
      ),
      accountUsers.list.filter(
        (item) => formData.providers.secondary.includes(item.uuid)
      ),
      availabilityLocations,
    );
    return (
      <View>
        <HStack alignItems={"center"} my={1}>
          <Text letterSpacing="lg" fontWeight={400} color={Colors.Custom.Gray400}>
            {intl.formatMessage({ id: "applicableOn" }).toUpperCase()}
          </Text>
          <Spacer />
          {isAppointmentTypeAvailabilityExist() && (
            <>
              <HStack alignItems={"center"} space={2}>
                {renderAddNewAvailability("addNewSchedule")}
                <View width={"200px"}>
                  <TimezoneSelect
                    showLabel={false}
                    showErrors={appointmentTypeAvailability.showError}
                    memorizeTimezone={false}
                    selectedTimezoneId={appointmentTypeAvailability.timezoneId}
                    onChange={onTimeZoneChange}
                  />
                </View>
              </HStack>
            </>
          )}
        </HStack>
        <Divider mb={1} />
        {!!infoMessage && (
          <HStack marginY={2}>
            <HStack alignItems={'flex-start'} space={2}>
              <Feather
                name="info"
                size={16}
                color={Colors.Custom.Gray500}
                style={{marginTop: 2}}
              />
              <Text fontSize={12} color={Colors.Custom.Gray500} width="full">
                {infoMessage}
              </Text>
            </HStack>
          </HStack>
        )}
        <FlatList
          data={availabilityData}
          renderItem={renderAvailabilityCard}
          keyExtractor={(item: IAppointmentTypeAvailability) => {
            return "_" + item.cardId;
          }}
        />
        {!isAppointmentTypeAvailabilityExist() && renderAddNewAvailability("selectDays")}
      </View>
    );
  };

  const getLocationName = (locationIds: string[]) => {
    const locationNames = [] as any;
    locationIds.map((qitem) => {
      locationType.map((location) => {
        if (qitem === location?.id) {
          locationNames.push(location.value);
        }
      });
    });
    return locationNames;
  };

  const onTimeZoneChange = (timezone?: ITimezone) => {
    setAppointmentTypeAvailability(prev => ({
      ...prev,
      timezoneId: timezone?.uuid
    }));
  }

  const emptyDataOnApplicableToChange = (newApplicableToValue: string) => {
   setSelectData([]);
   setFormData((prev) => {
     return {
       ...prev,
       isRsvpEnabled: type === AppointmentTypeAction.groupSessionAppointment
       ? true
       : false,
       providers: {
         primary: [],
         secondary: [],
       },
       restrictedUsers: [],
       employers: [],
     };
   });
  }

  const resetDataWithApiDataOnApplicableToChange = () => {
    const data: ILocalAppointmentTypeRoles[] = getMultiSelectData(
      userRoles || [],
      props.updateData?.appointmentTypeGroup as IUserGroupData[]
    );
    setSelectData(data);
    setFormData((prev) => {
      return {
        ...prev,
        isRsvpEnabled:
          props.updateData?.isRsvpEnabled ??
          type === AppointmentTypeAction.groupSessionAppointment
            ? true
            : false,
        providers: {
          primary:
            props.updateData?.userPool?.userPoolUsers
              ?.filter((item) => item.isDefault)
              ?.map((item) => item.userId) || [],
          secondary:
            props.updateData?.userPool?.userPoolUsers
              ?.filter((item) => !item.isDefault)
              ?.map((item) => item.userId) || [],
        },
        restrictedUsers: restrictedUserIdList || [],
        employers: initEmployers,
        popGroups: initPopGroups,
      };
    });
  }

  const handleDataChangesOnApplicableToChange = (newApplicableToValue: string) => {
    if (!isUpdate) {
      emptyDataOnApplicableToChange(newApplicableToValue);
      return;
    }
    // reset data if type changed
    if (props?.updateData?.availabilityTypeCode !== newApplicableToValue) {
      emptyDataOnApplicableToChange(newApplicableToValue);
    } else {
      // if changed back to prev type then prefill api data
      resetDataWithApiDataOnApplicableToChange();
    }
    setErrors((prev: any) => ({...prev, provider: '', roleId: ''}));
  };
  const onApplicableToChange = (e: RadioChangeEvent) => {
    setFormData((prev) => ({
      ...prev,
      availabilityTypeCode: e.target.value,
    }));
    handleDataChangesOnApplicableToChange(e.target.value);
  }

  const resetEmployersAndPopGroups = () => {
    setFormData((prev) => ({
      ...prev,
      employers: [],
      popGroups: [],
    }));
  };

  const setEmployersAndPopGroupsToInitState = () => {
    setFormData((prev) => ({
      ...prev,
      employers:
        props?.updateData?.appointmentTypeEmployer &&
        props?.updateData?.appointmentTypeEmployer?.length > 0
          ? props?.updateData?.appointmentTypeEmployer?.map(
              (employer) => employer.employerId
            )
          : [],
      popGroups:
        props?.updateData?.appointmentTypePopulationGroup &&
        props?.updateData?.appointmentTypePopulationGroup?.length > 0
          ? props?.updateData?.appointmentTypePopulationGroup?.map(
              (popGroup) => popGroup.groupId
            )
          : [],
    }));
  }

  const handleIsShownToPatientChange = (e:RadioChangeEvent) => {
    if(isUpdate){
      if(e.target.checked === props.updateData?.isShownToPatient){
        setEmployersAndPopGroupsToInitState();
      } else {
        resetEmployersAndPopGroups();
      }
    } else {
      resetEmployersAndPopGroups();
    }
    setFormData((prev) => ({
      ...prev,
      isShownToPatient: e.target.checked,
    }));
  }

  const renderApplicableToElement = (): JSX.Element => {
    const codes = [AppointmentAvailabilityCode.ROLE, AppointmentAvailabilityCode.PROVIDER];
    if (type !== AppointmentTypeAction.groupSessionAppointment) {
      codes.push(AppointmentAvailabilityCode.CARE_TEAM)
    }
    return (
      <View marginY={2} flex={1}>
        <HStack alignItems={"center"} my={1}>
          <Text letterSpacing="lg" fontWeight={400} color={Colors.Custom.Gray400}>
          {intl.formatMessage({ id: "applicableFor" }).toUpperCase()}
          </Text>
          <Tooltip
            title={intl.formatMessage({ id: "listOfProvidersInfoMsg" })}
            placement={'top'}
            destroyTooltipOnHide={true}
          >
          <Icon
            mt={1}
            mx={2}
            as={AntIcon}
            color={Colors.Custom.Gray500}
            name="infocirlceo"
            size="smMedium"
          />
          </Tooltip>
        </HStack>
        <Divider mb={1} />
        <View my={2}>
          <Radio.Group onChange={onApplicableToChange} value={formData.availabilityTypeCode}>
            {codes.map((code) => {
              const displayValue = getAvailableToDisplayValues(code);
              return <Radio key={code} value={code} style={{marginRight: 24}}>{displayValue}</Radio>
            })}
          </Radio.Group>
        </View>
        <VStack space={2} marginTop={3} flex={1}>
          {formData.availabilityTypeCode === AppointmentAvailabilityCode.ROLE && renderUserRoleElement()}
          {formData.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER && renderProviderElement()}
          {formData.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM && renderCareTeamElement()}
        </VStack>
      </View>
    );
  }

  const showRestrictUsersElement = (): boolean => {
    const availabilityList = appointmentTypeAvailability?.appointmentTypeAvailabilityData.filter(
      (availabilityCard) => { return !availabilityCard.isDeleted; }
    );
    if (
      formData.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER
    ) {
      return (
        (formData.providers.secondary.length > 0 ||
        formData.providers.primary.length > 0) && availabilityList?.length > 0
      );
    }
    if (isRoleOrCareTeamBasedAppointmentType) {
      return (
        selectedRoleList &&
        selectedRoleList.length > 0 &&
        availabilityList?.length > 0
      );
    }
    return false
  };

  const renderAppointmentTypesForRestrictedUsers = () => {
    if(appointmentTypeNamesByRestrictedUser.list.length > 0 && !restrictedUserFeildLoading){
      return (
        <VStack mt={2}>
          <HStack alignItems="center">
            <Feather
              name="info"
              color={Colors.Custom.Gray500}
              size={14}
              style={{
                marginRight: 4,
              }}
            />
            <Text color={Colors.Custom.Gray500}>
             {intl.formatMessage({id:'providerBlockedWithAnotherType'})}
            </Text>
            <Spacer />
            <Text
              fontWeight={'bold'}
              color={Colors.Custom.mainPrimaryPurple}
              onPress={() =>
                setAppointmentTypeNamesByRestrictedUser((prev) => ({
                  ...prev,
                  show: !prev.show,
                }))
              }
            >
              {`${
                appointmentTypeNamesByRestrictedUser.show ? 'Hide' : 'Show'
              } `}
            </Text>
          </HStack>
          {appointmentTypeNamesByRestrictedUser.show && (
            <VStack>
              {appointmentTypeNamesByRestrictedUser.list.map((name) => (
                <HStack ml={5} alignItems={'center'}>
                  <Feather
                    name="calendar"
                    color={Colors.Custom.Gray500}
                    size={12}
                    style={{
                      marginRight: 4,
                    }}
                  />
                  <Text color={Colors.Custom.Gray500}>{name}</Text>
                </HStack>
              ))}
            </VStack>
          )}
        </VStack>
      );
    }
    return <></>
  }

  const renderRestrictUsersElement = (): JSX.Element => {
    const userList = getUserListForRestrictUsersDropdown();
    if (!showRestrictUsersElement()) {
      return <></>;
    }
    const isSelectLoading = accountUsers.loading || getAccountUsersLoading;
    if (isSelectLoading) {
      return <Skeleton />;
    }
    return (
      <View flex={1} bg={Colors.Custom.Gray50} p={2} rounded={8}>
        <DisplayText
          textLocalId="restrictUsersFeildTitle"
          extraStyles={{
            color: Colors.Custom.Gray400,
          }}
        />
        <VStack marginTop={3} flex={1}>
          <AppointmentUserSelect
            isMultiSelect
            noDataMessage={
              isRoleOrCareTeamBasedAppointmentType
                ? 'No users for selected role'
                : undefined
            }
            placeholder={
              restrictedUserFeildLoading
                ? 'Loading...'
                : 'Search and select users'
            }
            onChange={handleRestrictUserChange}
            userList={restrictedUserFeildLoading ? [] : userList}
            value={restrictedUserFeildLoading ? [] : formData.restrictedUsers}
            isDisabled={restrictedUserFeildLoading}
            isLoading={restrictedUserFeildLoading}
          />
          {renderAppointmentTypesForRestrictedUsers()}
        </VStack>
      </View>
    );
  };
  const renderApplicableForElement = (): JSX.Element => {
    const selectMemberByCodes = [
      SelectMembersBy.ALL_MEMBERS,
      SelectMembersBy.SELECTED_MEMBERS,
    ];
    if(formData.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM){
      return <></>
    }
    return (
      <View
        marginY={2}
        flex={1}
        backgroundColor={Colors.Custom.Gray50}
        borderRadius={8}
        paddingX={3}
        paddingY={2}
        borderColor={Colors.Custom.Gray200}
        borderWidth={1}
      >
        <HStack alignItems={"center"} my={1}>
          <Text letterSpacing="lg" fontWeight={400} color={Colors.Custom.Gray400}>
            {intl.formatMessage({ id: "applicableFor" }).toUpperCase()}
          </Text>
        </HStack>
        <Divider mb={1} />
        <HStack my={2}>
          <Radio.Group
            onChange={onSelectMemberByChange}
            value={formData.selectedApplicableForType}
          >
            {selectMemberByCodes.map((selectMemberByCode) => {
              const displayValue =
                getSelectMembersByDisplayValues(selectMemberByCode);
              return (
                <Radio
                  key={selectMemberByCode}
                  value={selectMemberByCode}
                  style={{marginRight: 24}}
                >
                  {displayValue}
                </Radio>
              );
            })}
          </Radio.Group>
        </HStack>
        {formData.selectedApplicableForType ===
          SelectMembersBy.SELECTED_MEMBERS && (
          <VStack>
            <DisplayText
              textLocalId="populationGroup"
              extraStyles={{
                color: Colors.Custom.Gray400,
              }}
            />
            <PopulationGroupSearchAndSelect
              placeholder="Search and select population groups"
              value={formData.popGroups}
              onChange={(list) =>
                setFormData((prev) => ({
                  ...prev,
                  popGroups: list,
                }))
              }
              isMultiSelect
              locationGroupId={formData.locationGroupId}
            />
            <Box mt={2} />
            <DisplayText
              textLocalId="employers"
              extraStyles={{
                color: Colors.Custom.Gray400,
              }}
            />
            <EmployerSearch
              renderTag
              allowUserMultiSelect
              value={formData.employers}
              onChange={(list) => {
                setFormData((prev) => ({
                  ...prev,
                  employers: list,
                }));
              }}
              isShowError={false}
              fetchInitailEmployers
            />
          </VStack>
        )}
      </View>
    );
  }

  const renderUserRoleElement = (): JSX.Element => {
    return (
      <View flex={1}>
        <FormControl isInvalid={errors.roleId} isRequired maxWidth={'100%'}>
          <FormControl.Label isRequired>
            <DisplayText
              textLocalId="userRole"
              extraStyles={{
                color: Colors.Custom.Gray400
              }}
            />
          </FormControl.Label>
          <MultiSelect
            className='custom-select-styles'
            style={{
              borderRadius: 20,
            }}
            placeholder='Select User Role'
            mode="multiple"
            status={!!errors.roleId ? 'error' : undefined}
            onChange={(value: string[], list) => {
              const data: ILocalAppointmentTypeRoles[] = [];
              setRestrictedUserFeildLoading(true);
              list.forEach((item: any) => {
                data.push({
                  roleId: item.roleId,
                  id: '',
                  value: item.value,
                  accountRoleId: item.accountRoleid
                });
              });
              setSelectData(data);
            }}
            value={selectData?.map((item) => item.value)}
          >
            {userRoles &&
              userRoles.map((data) => {
                return (
                  <MultiSelect.Option
                    key={data.id}
                    roleId={data.roleId}
                    accountRoleid={data?.id}
                    value={data.value}
                  >
                    {data.value}
                  </MultiSelect.Option>
                );
              })}
          </MultiSelect>
          {errors.roleId && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errors.roleId}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
      </View>
    );
  };

  const onProviderChange = (userIds: string[], isPrimary: boolean) => {
    setRestrictedUserFeildLoading(true);
    setFormData((prev) => {
      const providers = prev.providers;
      return {
        ...prev,
        providers: {
          ...providers,
          ...(isPrimary && { primary: userIds }),
          ...(!isPrimary && { secondary: userIds }),
        }
      };
    })
  }

  const getAccountLocationUuidsFromUser = (user: any): string[] => {
    const accountLocationUuids: string[] = [];
    (user.userPracticeLocations || []).forEach((location: any) => {
      if (location.accountLocation?.practiceLocation?.uuid && location.accountLocation?.uuid) {
        accountLocationUuids.push(location.accountLocation?.uuid);
      }
    })
    return accountLocationUuids;
  }

  const getMessageForSelectedUsers = (primaryUsers: any[], secondaryUsers: any[]): string => {
    if (!secondaryUsers.length) {
      return '';
    }
    const nonMatchingLocationUser: string[] = [];
    primaryUsers.forEach((primaryUser) => {
      const userWiseLocationList = [getAccountLocationUuidsFromUser(primaryUser)];
      secondaryUsers.forEach((secondaryUser) => {
        userWiseLocationList.push(getAccountLocationUuidsFromUser(secondaryUser));
      })
      const commonLocations = userWiseLocationList.reduce((previousList, currentList) => {
        return previousList.filter(element => currentList.includes(element));
      });
      if (!commonLocations.length) {
        nonMatchingLocationUser.push(primaryUser.name);
      }
    })
    if (nonMatchingLocationUser.length > 0) {
      return `${nonMatchingLocationUser.join(', ')} and selected secondary users does not have any common location`;
    }
    return '';
  }
  const fetchUsersUUIdByPracticeLocationUuid = async () => {
    const response = await getUserUuidFromPracticeLocationUuid({
      variables: {
        params: {
          accountLocationIds: locationIdsBySelectedLocationGroup,
          isActive: true,
        },
      },
    });
    const userUuidList = response?.data?.searchUsers?.users?.map(
      (location: any) => location?.uuid
    );
    return userUuidList;
  };

  const fetchData = async () => {
    const userUuids = await fetchUsersUUIdByPracticeLocationUuid();
    setComponentState((prevState) => ({
      ...prevState,
      userUuids: userUuids,
    }));
  };

  useEffect(() => {
    if (isMultiTenancyEnabled) {
      fetchData();
    }
  }, [formData.locationGroupId]);

  const renderProviderElement = (): JSX.Element => {
    if (accountUsers.loading) {
      return <Skeleton active />;
    }
    const primaryUsers = accountUsers.list.filter(
      (item) => !formData.providers.secondary.includes(item.uuid)
    );

    const primaryUsersFilteredByLocationGroup = (isMultiTenancyEnabled && componentState?.userUuids)  ? primaryUsers?.filter((user:any) => componentState?.userUuids?.includes(user?.uuid)) : primaryUsers;
    const secondaryUsers = accountUsers.list.filter(
      (item) => !formData.providers.primary.includes(item.uuid)
    );
    const secondaryUsersFilteredByLocationGroup = (isMultiTenancyEnabled && componentState?.userUuids)  ? secondaryUsers?.filter((user:any) => componentState?.userUuids?.includes(user?.uuid)) : secondaryUsers;
    const infoMessage = getMessageForSelectedUsers(
      accountUsers.list.filter(
        (item) => formData.providers.primary.includes(item.uuid)
      ),
      accountUsers.list.filter(
        (item) => formData.providers.secondary.includes(item.uuid)
      )
    );
    return (
      <VStack space={2}>
        <VStack flex={1} space={3}>
          <FormControl isInvalid={errors.provider} isRequired>
            <FormControl.Label isRequired>
              <DisplayText
                textLocalId="primaryProvider"
                extraStyles={{
                  color: Colors.Custom.Gray400,
                }}
              />
              <Tooltip
                title={intl.formatMessage({ id: "primaryProviderInfoMsg" })}
                placement={'top'}
                destroyTooltipOnHide={true}
              >
              <Icon
                mt={1}
                mx={2}
                as={AntIcon}
                color={Colors.Custom.Gray500}
                name="infocirlceo"
                size="smMedium"
               />
              </Tooltip>
            </FormControl.Label>
            <AppointmentUserSelect
              showViewSchedule
              value={formData.providers.primary}
              onChange={(list: string[]) => {
                onProviderChange(list, true);
              }}
              showError={!!errors.provider}
              userList={primaryUsersFilteredByLocationGroup}
              placeholder={'Select primary provider'}
              isMultiSelect={true}
              userProfileImageMap={accountUsers.profileUrlsByUserId}
              onViewSchedule={(user) => {
                setUserAvailability((prev) => ({
                  ...prev,
                  showAvailability: true,
                  selectedUsers: [user.uuid],
                }));
              }}
            />

            {errors.provider && (
              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {errors.provider}
              </FormControl.ErrorMessage>
            )}
          </FormControl>
          <FormControl>
            <FormControl.Label>
              <DisplayText
                textLocalId="secondaryProvider"
                extraStyles={{
                  color: Colors.Custom.Gray400,
                }}
              />
            </FormControl.Label>
            <AppointmentUserSelect
              showViewSchedule
              value={formData.providers.secondary}
              onChange={(list: string[]) => {
                onProviderChange(list, false);
              }}
              userList={secondaryUsersFilteredByLocationGroup}
              placeholder={'Select secondary provider'}
              isMultiSelect={true}
              userProfileImageMap={accountUsers.profileUrlsByUserId}
              onViewSchedule={(user) => {
                setUserAvailability((prev) => ({
                  ...prev,
                  showAvailability: true,
                  selectedUsers: [user.uuid],
                }));
              }}
            />
          </FormControl>
        </VStack>
        {!!infoMessage && (
          <HStack>
            <HStack alignItems={'flex-start'} space={2}>
              <Feather
                name="info"
                size={16}
                color={Colors.Custom.Gray500}
                style={{marginTop: 2}}
              />
              <Text fontSize={12} color={Colors.Custom.Gray500} width="full">
                {infoMessage}
              </Text>
            </HStack>
          </HStack>
        )}
        {(formData.providers.primary.length > 0 ||
          formData.providers.secondary.length > 0) && (
          <HStack marginTop={2}>
            <Spacer />
            <Pressable
              onPress={() => {
                setUserAvailability((prev) => ({
                  ...prev,
                  showAvailability: true,
                  selectedUsers: [
                    ...formData.providers.primary,
                    ...formData.providers.secondary,
                  ],
                }));
              }}
            >
              <HStack alignItems={'center'} space={1}>
                <Feather name="eye" size={14} color={Colors.primary[400]} />
                <Text color={Colors.primary[400]}>View user availability</Text>
              </HStack>
            </Pressable>
          </HStack>
        )}
      </VStack>
    );
  };

  const renderCareTeamElement = (): JSX.Element => {
    return (
      <View flex={1}>
        <FormControl isInvalid={errors.roleId} isRequired>
          <FormControl.Label isRequired>
            <DisplayText
              textLocalId="appointmentTypeCareTeam"
              extraStyles={{
                color: Colors.Custom.Gray400
              }}
            />
          </FormControl.Label>
          <MultiSelect
            className='custom-select-styles'
            style={{
              borderRadius: 20,
            }}
            status={!!errors.roleId ? 'error' : undefined}
            placeholder='Select primary provider role'
            onChange={(value: any, selectedValue: any) => {
              const data: ILocalAppointmentTypeRoles[] = [];
              if (selectedValue) {
                data.push({
                  roleId: selectedValue.roleId,
                  id: '',
                  value: selectedValue.value,
                  accountRoleId: selectedValue.accountRoleid
                });
              }
              setSelectData(data);
            }}
            value={selectData?.map((item) => item.value)?.[0]}
          >
            {userRoles &&
              userRoles.map((data: any) => {
                return (
                  <MultiSelect.Option
                    key={data.id}
                    roleId={data.roleId}
                    accountRoleid={data?.id}
                    value={data.value}
                  >
                    {data.value}
                  </MultiSelect.Option>
                );
              })}
          </MultiSelect>

          {errors.roleId && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errors.roleId}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
      </View>
    );
  }

  const isDisableShortName = (): boolean => {
    return (
      (props?.updateData?.shortName || '')?.length > 0 ||
      uniqueNameLoading ||
      addOrUpdateAppointmentTypeLoading
    );
  };


  const renderAppointmentTypeNameField = (): JSX.Element => {
    return (
      <FormControl
        flex={isAthenaEHR && type !== AppointmentTypeAction.groupSessionAppointment ? 0.50 : 1}
        isRequired
        isInvalid={errors.eventName || errors.duplicateEventName}
      >
        <FormControl.Label>
          <DisplayText
            textLocalId="name"
            extraStyles={{
             color: Colors.Custom.Gray400
            }}
          />
        </FormControl.Label>
        <HStack
          alignItems="center"
          borderColor={Colors.Custom.Gray200}
          borderWidth={1}
          borderRadius={4}
        >
          <Input
            borderWidth={0}
            flex={1}
            value={formData.eventName}
            _focus={{
              borderColor: Colors.Custom.Gray200,
            }}
            _hover={{
              bg: Colors.Custom.buttonWhiteBg,
            }}
            bg={Colors.Custom.buttonWhiteBg}
            placeholder="Enter appointment type name"
            onChangeText={(text: string) => {
              const data = {...formData, eventName: text};
              setFormData(data);
              if (errors.eventName) {
                setErrors(getUpdatedErrorMessages(data, type, undefined, appointmentCapability, ehrConfig, appointmentTypeCustomFieldAndDurationMlovs));
              }
              if (text && text?.trim()?.length > 0) {
                checkAppointmentTypeName(text?.trim());
              }
            }}
            {...(appointmentNameMaxChar && type === AppointmentTypeAction.singleAppointment &&  { maxLength: appointmentNameMaxChar })}
          />
          <Box mx={2}>
            <ColorSelector
              customStyle={{}}
              roundBtnSize={APPOINTMENT_COLOR_INDICATOR_SIZE}
              viewCode={COLOR_SELECTOR_VIEW_CODES.CIRCLE_BUTTON}
              color={formData.colorCode}
              colorList={APPOINTMENT_TYPE_COLORS}
              onBtnPress={(color: string) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    colorCode: color,
                  };
                });
              }}
              onChangeHex={(text: any) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    colorCode: '#' + text,
                  };
                });
              }}
            />
          </Box>
        </HStack>
        {!!appointmentNameMaxChar && type === AppointmentTypeAction.singleAppointment && <Text fontSize={12} color={Colors.Custom.Gray500}>{`(Max ${appointmentNameMaxChar} characters allowed)`}</Text>}
        {(errors.eventName || errors.duplicateEventName) && (
          <FormControl.ErrorMessage
            _text={{
              fontSize: 'xs',
              color: 'error.500',
              fontWeight: 500,
            }}
          >
            {errors.eventName || errors.duplicateEventName}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
    );
  };

  const renderShortNameField = (): JSX.Element => {
    return <FormControl
      flex={0.50}
      isRequired
      isInvalid={errors.shortName || errors.duplicateShortName}>
    <HStack>
      <FormControl.Label>
        <DisplayText
          textLocalId="shortName"
          extraStyles={{
            color: Colors.Custom.Gray400
           }}
        />
      </FormControl.Label>
      <Tooltip
        title={intl.formatMessage({id: 'shortNameInfo'})}
        placement={'top'}
        destroyTooltipOnHide={true}
      >
        <View>
          <Button
            marginTop={0.5}
            marginLeft={-2}
            marginRight={2}
            height={5}
            width={5}
            size={'smMedium'}
            variant={'unstyled'}
            _text={{
              color: Colors.Custom.Gray500,
              fontWeight: 400,
              fontSize: 14,
            }}
            _hover={{
              _text: {
                color: Colors.Custom.PurpleColor,
              },
            }}
            endIcon={
              <Icon as={AntIcon} name="infocirlceo" size="smMedium" />
            }
          />
        </View>
      </Tooltip>
    </HStack>
    <Input
      placeholder='Enter Short Name'
      isDisabled={isDisableShortName()}
      value={formData.shortName}
      _focus={{
        borderColor: Colors.Custom.Gray200,
      }}
      onChangeText={(text: string) => {
        const data = {...formData, shortName: text};
        setFormData(data);
        if (errors.shortName) {
          setErrors(getUpdatedErrorMessages(data, type, undefined, appointmentCapability, ehrConfig, appointmentTypeCustomFieldAndDurationMlovs));
        }
        if (
          text &&
          text?.trim()?.length &&
          text?.trim()?.length === 4
        ) {
          checkUniqueShortName(text?.trim());
        }
      }}
    />
    {(errors.shortName || errors.duplicateShortName) && (
      <FormControl.ErrorMessage
        _text={{
          fontSize: 'xs',
          color: 'error.500',
          fontWeight: 500,
        }}
      >
        {errors.shortName || errors.duplicateShortName}
      </FormControl.ErrorMessage>
    )}
  </FormControl>
  }

  const renderDurationField = () => {
    return (
      <FormControl maxWidth={'50%'} isRequired isInvalid={errors.duration}>
        <FormControl.Label>
          <DisplayText
            textLocalId="duration"
            extraStyles={{
              color: Colors.Custom.Gray400
             }}
          />
        </FormControl.Label>
        <HStack
          pr={2}
          alignItems="center"
          borderColor={Colors.Custom.Gray200}
          borderWidth={1}
          borderRadius={4}
        >
          <Input
            placeholder='Enter Duration'
            isDisabled={formData?.id && ehrConfig.isAthena ? true : false}
            value={formData.duration.toString()}
            _focus={{
              borderColor: Colors.Custom.Gray200,
            }}
            flex={1}
            borderWidth={0}
            onChangeText={(text: string) => {
              const number = text.replace(/\D/, '');
              const data = {
                ...formData,
                duration: number ? parseInt(number) : 0,
              };
              setFormData(data);
              if (errors.duration) {
                setErrors(getUpdatedErrorMessages(data, type, undefined, appointmentCapability, ehrConfig, appointmentTypeCustomFieldAndDurationMlovs));
              }
            }}
          />
          <Text alignSelf={'center'} color={Colors.Custom.Gray500} fontWeight={400}>mins</Text>
        </HStack>
        {errors.duration && (
          <FormControl.ErrorMessage
            _text={{
              fontSize: 'xs',
              color: 'error.500',
              fontWeight: 500,
            }}
          >
            {errors.duration}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
    );
  }
  const renderMemberLimitField = () => {
    return (
      <FormControl isRequired isInvalid={errors.memberLimit}>
        <FormControl.Label>
          <DisplayText
            textLocalId="memberLimit"
            extraStyles={{
              color: Colors.Custom.Gray400
             }}
          />
        </FormControl.Label>
        <HStack
          pr={2}
          alignItems="center"
          borderColor={Colors.Custom.Gray200}
          borderWidth={1}
          borderRadius={4}
        >
          <Input
            placeholder='Specify a member limit'
            isDisabled={false}
            value={formData?.memberLimit?.toString() || ''}
            _focus={{
              borderColor: Colors.Custom.Gray200,
            }}
            flex={1}
            borderWidth={0}
            onChangeText={(text: string) => {
              const number = text.replace(/\D/, '');
              const data = {
                ...formData,
                memberLimit: number ? parseInt(number) : 0,
              };
              setFormData(data);
              if (errors.memberLimit) {
                setErrors(getUpdatedErrorMessages(data, type));
              }
            }}
          />
        </HStack>
        {errors.memberLimit && (
          <FormControl.ErrorMessage
            _text={{
              fontSize: 'xs',
              color: 'error.500',
              fontWeight: 500,
            }}
          >
            {errors.memberLimit}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
    );
  }
  const renderAppointmentCategory = () => {
    return (
      <View flex={1}>
        <FormControl
          isRequired
          isInvalid={errors.type}
          _text={{
            bold: true,
          }}
          justifyContent="center"
        >
          <FormControl.Label>
            <DisplayText textLocalId={'appointmentCategory'} extraStyles={{
             color: Colors.Custom.Gray400
            }} />
          </FormControl.Label>
          <MultiSelect
            className="custom-select-styles"
            placeholder="Select appointment category"
            disabled={!!isUpdate}
            value={type}
            onChange={(value: any) => {
              setType(value);

              const categoryId =
                value === AppointmentTypeAction.groupSessionAppointment
                    ? groupSessionAppointmentCategoryId
                    : singleAppointmentCategoryId;
              formData.categoryId = categoryId;
              setFormData((prev) => ({
                ...prev,
                categoryId: categoryId,
                categoryType: value,
                locationTypeId: [],
                isRsvpEnabled:
                  value === AppointmentTypeAction.groupSessionAppointment
                    ? true
                    : formData.availabilityTypeCode ===
                      AppointmentAvailabilityCode.ROLE
                    ? true
                    : false,
                isLimitCancelRescheduleEnabled:
                  value === AppointmentTypeAction.groupSessionAppointment
                    ? false
                    : prev.isLimitCancelRescheduleEnabled,
                customFields:
                  value === AppointmentTypeAction.groupSessionAppointment
                    ? []
                    : prev.customFields
              }));
              setSelectData([]);
            }}
          >
            {APPOINTMENT_TYPE_CATEGORY &&
              APPOINTMENT_TYPE_CATEGORY.map((category) => {
                return (
                  <MultiSelect.Option
                    key={category.key}
                    value={category.key}
                  >
                    {category.name}
                  </MultiSelect.Option>
                );
              })}
          </MultiSelect>
          {errors.type && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errors.type}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
      </View>
    );
  };

  const renderLocationGroups = () => {
    if (!isMultiTenancyEnabled) {
      return null;
    }
    return (
      <View>
        <FormControl.Label isRequired isInvalid={errors.locationGroupError}>
          <DisplayText
            textLocalId={'eHRInstance'}
            extraStyles={{
              color: Colors.Custom.Gray400,
            }}
          />
           <Tooltip
            title={intl.formatMessage({ id: "locationGroupInfoMsg" })}
            placement={'top'}
            destroyTooltipOnHide={true}
          >
          <Icon
            mt={1}
            mx={2}
            as={AntIcon}
            color={Colors.Custom.Gray500}
            name="infocirlceo"
            size="smMedium"
          />
          </Tooltip>
        </FormControl.Label>
        <ModalActionAntSelect
          disabled={!!isUpdate}
          style={{width: '100%'}}
          allowClear={true}
          errors={!!errors.locationGroupError}
          showSearch={true}
          leftMargin={'0'}
          loading={loadingData}
          value={locationGroupLoading ? '' : formData.locationGroupId}
          notFoundContent={locationGroupLoading && <Spin size="small" />}
          isRequired={true}
          errorText={errors.locationGroupError}
          placeholder={'Select EHR Instance'}
          onChange={(value: string) => {
            setFormData((prev) => ({...prev, locationGroupId: value,
              providers: {
                primary: [],
                secondary: [],
              },
            }));
            const filterLocationGroupId = accountLocationListWithEHR.find(
              (item: any) => item?.locationGroupId === value
            );
            const isAthenaLocationGroup =
              filterLocationGroupId?.ehrInfo?.ehrCode === EHRName.ATHENA;
            // function -- filter locations by location group id
            const filterLocations =
              appointmentTypeAvailability.accountLocations.filter(
                (item) => item?.locationGroupId === value
              );
            setAppointmentTypeAvailability((prev) => {
              return {
                ...prev,
                locationGroupLocations: filterLocations,
              };
            });
            setComponentState((prev) => {
              return {
                ...prev,
                isAthenaLocationGroup: isAthenaLocationGroup,
              };
            });
          }}
          data={filteredLocationGroups}
          optionProps={{key: 'code', value: 'code', label: 'display'}}
          extraStyle={{flex: 1}}
          customStyle={{flex: 1}}
        />
      </View>
    );
  };


  useEffect(() => {
    if (filteredLocationGroups.length === 1) {
      const value = filteredLocationGroups[0].code;
      setFormData((prev) => ({
        ...prev,
        locationGroupId: value
      }));

      const filterLocationGroupId = accountLocationListWithEHR.find(
        (item: any) => item?.locationGroupId === value
      );

      const isAthenaLocationGroup =
        filterLocationGroupId?.ehrInfo?.ehrCode === EHRName.ATHENA;

      const filterLocations =
        appointmentTypeAvailability.accountLocations.filter(
          (item) => item?.locationGroupId === value
        );

      setAppointmentTypeAvailability((prev) => {
        return {
          ...prev,
          locationGroupLocations: filterLocations,
        };
      });

      setComponentState((prev) => {
        return {
          ...prev,
          isAthenaLocationGroup: isAthenaLocationGroup,
        };
      });
    } else {

    }
  }, [filteredLocationGroups.length,appointmentTypeAvailability.accountLocations.length]);

  const renderAppointmentModeField = () => {
    return (
      <View flex={1}>
        <FormControl
          isRequired
          isInvalid={errors.locationTypeId}
          _text={{
            bold: true,
          }}
          justifyContent="center"
        >
          <FormControl.Label>
            <DisplayText
              textLocalId={'locationType'}
              extraStyles={{
                color: Colors.Custom.Gray400,
              }}
            />
          </FormControl.Label>
          {type === AppointmentTypeAction.groupSessionAppointment ? (
            <MultiSelect
              className="custom-select-styles"
              placeholder="Select mode of appointment"
              value={getLocationName(formData.locationTypeId)}
              onChange={(value: any, list: any) => {
                const locationIds = [list.locationId] as any;
                const data = {...formData, locationTypeId: locationIds};
                setFormData(data);
                if (errors.locationTypeId) {
                  setErrors(
                    getUpdatedErrorMessages(
                      data,
                      type,
                      undefined,
                      appointmentCapability,
                      ehrConfig
                    )
                  );
                }
              }}
            >
              {locationType &&
                locationType.map((location) => {
                  return (
                    <MultiSelect.Option
                      key={location.id}
                      value={location.value}
                      locationId={location.id}
                    >
                      {location.value}
                    </MultiSelect.Option>
                  );
                })}
            </MultiSelect>
          ) : (
            <MultiSelect
              mode="multiple"
              className="custom-select-styles"
              placeholder="Select mode of appointment"
              value={getLocationName(formData.locationTypeId)}
              onChange={(value: any, list) => {
                const locationIds = [] as any;
                list.forEach((item: any) => {
                  locationIds.push(item.locationId);
                });
                const data = {...formData, locationTypeId: locationIds};
                setFormData(data);
                if (errors.locationTypeId) {
                  setErrors(
                    getUpdatedErrorMessages(
                      data,
                      type,
                      undefined,
                      appointmentCapability,
                      ehrConfig
                    )
                  );
                }
              }}
            >
              {locationType &&
                locationType.map((location) => {
                  return (
                    <MultiSelect.Option
                      key={location.id}
                      value={location.value}
                      locationId={location.id}
                    >
                      {location.value}
                    </MultiSelect.Option>
                  );
                })}
            </MultiSelect>
          )}
          {errors.locationTypeId && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errors.locationTypeId}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
      </View>
    );
  };

  const renderParticipantField = () => {
    return (
      <View flex={1}>
        <FormControl.Label isRequired>
          <DisplayText
            textLocalId="participants"
            extraStyles={{
             color: Colors.Custom.Gray400
            }}
          />
        </FormControl.Label>
        {!loadingData && (
          <>
            <ParticipantGroup
              selectedUsers={formData?.appointmentTypeGroup}
              onChange={(data) => {
                const updatedData = {
                  ...formData,
                  appointmentTypeGroup: data,
                };
                setFormData(updatedData);
                if (errors.participants) {
                  setErrors(getUpdatedErrorMessages(updatedData, type, undefined, appointmentCapability, ehrConfig));
                }
              }}
            />
            {errors.participants && (
              <Text fontSize="xs" color="error.500" fontWeight={500}>
                {errors.participants}
              </Text>
            )}
          </>
        )}
        {loadingData && (
          <View width="full">
            <Skeleton active />
          </View>
        )}
      </View>
    );
  };

  const renderDescriptionField = () => {
    return (
      <FormControl flex={1}>
        <FormControl.Label>
          <DisplayText textLocalId="description" extraStyles={{ color: Colors.Custom.Gray400 }} />
        </FormControl.Label>
        <TextArea
          maxLength={2000} // zoom not allow more than 2000 character appointment description
          value={formData.description}
          onChangeText={(text: string) =>
            setFormData({...formData, description: text})
          }
        />
      </FormControl>
    );
  };

  const renderInstructionForStaffField = () => {
    return (
      <FormControl>
        <FormControl.Label>
          <DisplayText
            extraStyles={{ color: Colors.Custom.Gray400 }}
            textLocalId={'staffInstructionsAppointmentBookDrawerTitle'}
          />
        </FormControl.Label>
        <TaskCheckList
          key={props.updateData?.id}
          tasks={formData.currentTasks || []}
          canAddNewTasks={true}
          canDeleteTasks={true}
          canEditTitle={true}
          canEditStatus={false}
          showErrors={errors.showErrors}
          onChange={(tasks: IAppointmentTask[]) => {
            setFormData((prev) => {
              return {
                ...prev,
                currentTasks: tasks,
              };
            });
          }}
        />
      </FormControl>
    );
  };

  const renderIsShownToPatientField = () => {
    return (
      <VStack
        backgroundColor={Colors.Custom.Gray50}
        borderRadius={8}
        paddingX={3}
        paddingY={2}
        flex={1}
        my={4}
        borderColor={Colors.Custom.Gray200}
        borderWidth={1}
      >
        <FormControl isRequired>
          <FormControl.Label>
            <DisplayText
              textLocalId="inviteesCanSchedule"
              extraStyles={{
                color: Colors.Custom.Gray500,
              }}
            />
          </FormControl.Label>
          <Divider my={1} />
          <HStack space={2}>
            <Radio.Group
              name="intervalType"
              value={formData.intervalType}
              onChange={(event) => {
                onBookingSpanChange(event.target.value);
              }}
            >
              <VStack py={2}>
                <Radio className="typeRadio" value={APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME}>
                  <DisplayText
                    textLocalId="indefinitelyIntoTheFuture"
                    extraStyles={{marginLeft: 4, fontSize: 14}}
                  />
                </Radio>
                <HStack
                  style={{alignItems: 'center', flexWrap: 'wrap', flex: 1}}
                  marginTop={1}
                >
                  <Radio className="typeRadio" value={APPOINTMENT_TYPE_INTERVAL_TYPES.BOOK_WITHIN_DAYS}>
                    <DisplayText
                      textLocalId="within"
                      extraStyles={{
                        marginLeft: 4,
                        fontSize: 14,
                      }}
                    />
                  </Radio>
                  {formData.intervalType !== APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME && (
                    <>
                      <DateTimeDurationInput
                        durationUnits={durationMlovs}
                        value={slotWithinLimitCustomField?.value?.value}
                        selectedDurationUnitId={
                          slotWithinLimitCustomField?.value?.unitId
                        }
                        onValueChange={(value) => {
                          if (slotWithinLimitCustomField?.value) {
                            slotWithinLimitCustomField.value.value = value;
                          }
                          const updatedCustomFields = getUpdatedAppointmentCustomFields(
                            formData?.customFields || [],
                            slotWithinLimitCustomField
                          );
                          setFormData((prev) => ({
                            ...prev,
                            customFields: updatedCustomFields || [],
                          }));
                          setErrors(getUpdatedErrorMessages(formData, type, selectData || [], appointmentCapability, ehrConfig, appointmentTypeCustomFieldAndDurationMlovs));
                        }}
                        onDurationUnitChange={(unitId) => {
                          if (slotWithinLimitCustomField?.value) {
                            slotWithinLimitCustomField.value.unitId = unitId;
                          }
                          const updatedCustomFields = getUpdatedAppointmentCustomFields(
                            formData?.customFields || [],
                            slotWithinLimitCustomField
                          );
                          setFormData((prev) => ({
                            ...prev,
                            customFields: updatedCustomFields || [],
                          }));
                          setErrors(getUpdatedErrorMessages(formData, type, selectData || [], appointmentCapability, ehrConfig, appointmentTypeCustomFieldAndDurationMlovs));
                        }}
                      />
                      <DisplayText
                        textLocalId="inTheFuture"
                        extraStyles={{
                          marginLeft: 12,
                          fontSize: 14,
                        }}
                      />
                      <DisplayText
                        textLocalId={`${intl.formatMessage({
                          id: 'and',
                        })} ${intl.formatMessage({id: 'notBefore'})}`}
                        extraStyles={{
                          marginLeft: 4,
                          fontSize: 14,
                          marginRight: 12,
                        }}
                      />
                      <DateTimeDurationInput
                        durationUnits={durationMlovs}
                        value={slotWithinOffsetCustomField?.value?.value}
                        selectedDurationUnitId={
                          slotWithinOffsetCustomField?.value?.unitId
                        }
                        onValueChange={(value) => {
                          if (slotWithinOffsetCustomField?.value) {
                            slotWithinOffsetCustomField.value.value = value;
                          }
                          const updatedCustomFields = getUpdatedAppointmentCustomFields(
                            formData?.customFields || [],
                            slotWithinOffsetCustomField
                          );
                          setFormData((prev) => ({
                            ...prev,
                            customFields: updatedCustomFields || [],
                          }));
                          setErrors(getUpdatedErrorMessages(formData, type, selectData || [], appointmentCapability, ehrConfig, appointmentTypeCustomFieldAndDurationMlovs));
                        }}
                        onDurationUnitChange={(unitId) => {
                          if (slotWithinOffsetCustomField?.value) {
                            slotWithinOffsetCustomField.value.unitId = unitId;
                          }
                          const updatedCustomFields = getUpdatedAppointmentCustomFields(
                            formData?.customFields || [],
                            slotWithinOffsetCustomField
                          );
                          setFormData((prev) => ({
                            ...prev,
                            customFields: updatedCustomFields || [],
                          }));
                          setErrors(getUpdatedErrorMessages(formData, type, selectData || [], appointmentCapability, ehrConfig, appointmentTypeCustomFieldAndDurationMlovs));
                        }}
                      />
                      <DisplayText
                        textLocalId="fromTheCurrent"
                        extraStyles={{
                          marginLeft: 12,
                          fontSize: 14,
                          marginRight: 12,
                        }}
                      />
                    </>
                  )}
                </HStack>
                {(errors.withinDay ||
                  errors.slotOffsetLimitError) && (formData.intervalType !== APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME) && (
                    <View marginTop={2}>
                      <Text
                        fontSize="xs"
                        color="error.500"
                        fontWeight={500}
                        marginTop={2}
                      >
                        {errors.withinDay || errors.slotOffsetLimitError}
                      </Text>
                    </View>
                )}
              </VStack>
            </Radio.Group>
          </HStack>
        </FormControl>
      </VStack>
    );
  };

  const renderCancelRescheduleLimitToPatientField = () => {
    return (
      <VStack>
        <View
          backgroundColor={Colors.Custom.Gray50}
          borderRadius={8}
          paddingX={3}
          paddingY={2}
          flex={1}
          my={4}
          borderColor={Colors.Custom.Gray200}
          borderWidth={1}
        >
          <FormControl isRequired>
            <FormControl.Label>
              <DisplayText
                textLocalId="inviteesCanCancel"
                extraStyles={{
                  color: Colors.Custom.Gray500,
                }}
              />
            </FormControl.Label>
            <Divider my={1} />
            <HStack space={2}>
              <Radio.Group
                name="cancelIntervalType"
                value={formData.cancelIntervalType}
                onChange={(event) => {
                  onCancelOrRescheduleSpanChange(
                    event.target.value,
                    'cancelIntervalType',
                    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.CANCEL_LIMIT
                  );
                }}
              >
                <VStack py={2}>
                  <Radio className="typeRadio" value={APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME}>
                    <DisplayText
                      textLocalId="tillTheTimeOfAppointment"
                      extraStyles={{marginLeft: 4, fontSize: 14}}
                    />
                  </Radio>
                  <HStack
                    style={{alignItems: 'center', flexWrap: 'wrap', flex: 1}}
                    marginTop={1}
                  >
                    <Radio className="typeRadio" value={APPOINTMENT_TYPE_INTERVAL_TYPES.CANCEL_BEFORE_TIME}>
                      <DisplayText
                        textLocalId="upto"
                        extraStyles={{
                          marginLeft: 4,
                          fontSize: 14,
                        }}
                      />
                    </Radio>
                    {formData.cancelIntervalType !== APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME && (
                      <>
                        <DateTimeDurationInput
                          durationUnits={durationMlovs}
                          value={cancelLimitCustomField?.value?.value}
                          selectedDurationUnitId={
                            cancelLimitCustomField?.value?.unitId
                          }
                          onValueChange={(value) => {
                            if (cancelLimitCustomField?.value) {
                              cancelLimitCustomField.value.value = value;
                            }
                            const updatedCustomFields = getUpdatedAppointmentCustomFields(
                              formData?.customFields || [],
                              cancelLimitCustomField
                            );
                            setFormData((prev) => ({
                              ...prev,
                              customFields: updatedCustomFields || [],
                            }));
                            setErrors(getUpdatedErrorMessages(formData, type, selectData || [], appointmentCapability, ehrConfig, appointmentTypeCustomFieldAndDurationMlovs));
                          }}
                          onDurationUnitChange={(unitId) => {
                            if (cancelLimitCustomField?.value) {
                              cancelLimitCustomField.value.unitId = unitId;
                            }
                            const updatedCustomFields = getUpdatedAppointmentCustomFields(
                              formData?.customFields || [],
                              cancelLimitCustomField
                            );
                            setFormData((prev) => ({
                              ...prev,
                              customFields: updatedCustomFields || [],
                            }));
                            setErrors(getUpdatedErrorMessages(formData, type, selectData || [], appointmentCapability, ehrConfig, appointmentTypeCustomFieldAndDurationMlovs));
                          }}
                        />
                        <DisplayText
                          textLocalId="beforeAppointmentTime"
                          extraStyles={{
                            marginLeft: 12,
                            fontSize: 14,
                          }}
                        />
                      </>
                    )}
                  </HStack>
                </VStack>
              </Radio.Group>
            </HStack>
          </FormControl>
        </View>
        <View
          backgroundColor={Colors.Custom.Gray50}
          borderRadius={8}
          paddingX={3}
          paddingY={2}
          flex={1}
          borderColor={Colors.Custom.Gray200}
          borderWidth={1}
        >
          <FormControl isRequired>
            <FormControl.Label>
              <DisplayText
                textLocalId="inviteesCanReschedule"
                extraStyles={{
                  color: Colors.Custom.Gray500,
                }}
              />
            </FormControl.Label>
            <Divider my={1} />
            <HStack space={2}>
              <Radio.Group
                name="rescheduleIntervalType"
                value={formData.rescheduleIntervalType}
                onChange={(event) => {
                  onCancelOrRescheduleSpanChange(
                    event.target.value,
                    'rescheduleIntervalType',
                    APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.RESCHEDULE_LIMIT
                  );
                }}
              >
                <VStack py={2}>
                  <Radio className="typeRadio" value={APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME}>
                    <DisplayText
                      textLocalId="tillTheTimeOfAppointment"
                      extraStyles={{marginLeft: 4, fontSize: 14}}
                    />
                  </Radio>
                  <HStack
                    style={{alignItems: 'center', flexWrap: 'wrap', flex: 1}}
                    marginTop={1}
                  >
                    <Radio className="typeRadio" value={APPOINTMENT_TYPE_INTERVAL_TYPES.RESCHEDULE_BEFORE_TIME}>
                      <DisplayText
                        textLocalId="upto"
                        extraStyles={{
                          marginLeft: 4,
                          fontSize: 14,
                        }}
                      />
                    </Radio>
                    {formData.rescheduleIntervalType !== APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME && (
                      <>
                        <DateTimeDurationInput
                          durationUnits={durationMlovs}
                          value={rescheduleLimitCustomField?.value?.value}
                          selectedDurationUnitId={
                            rescheduleLimitCustomField?.value?.unitId
                          }
                          onValueChange={(value) => {
                            if (rescheduleLimitCustomField?.value) {
                              rescheduleLimitCustomField.value.value = value;
                            }
                            const updatedCustomFields = getUpdatedAppointmentCustomFields(
                              formData?.customFields || [],
                              rescheduleLimitCustomField
                            );
                            setFormData((prev) => ({
                              ...prev,
                              customFields: updatedCustomFields || [],
                            }));
                            setErrors(getUpdatedErrorMessages(formData, type, selectData || [], appointmentCapability, ehrConfig, appointmentTypeCustomFieldAndDurationMlovs));
                          }}
                          onDurationUnitChange={(unitId) => {
                            if (rescheduleLimitCustomField?.value) {
                              rescheduleLimitCustomField.value.unitId = unitId;
                            }
                            const updatedCustomFields = getUpdatedAppointmentCustomFields(
                              formData?.customFields || [],
                              rescheduleLimitCustomField
                            );
                            setFormData((prev) => ({
                              ...prev,
                              customFields: updatedCustomFields || [],
                            }));
                            setErrors(getUpdatedErrorMessages(formData, type, selectData || [], appointmentCapability, ehrConfig, appointmentTypeCustomFieldAndDurationMlovs));
                          }}
                        />
                        <DisplayText
                          textLocalId="beforeAppointmentTime"
                          extraStyles={{
                            marginLeft: 12,
                            fontSize: 14,
                          }}
                        />
                      </>
                    )}
                  </HStack>
                </VStack>
              </Radio.Group>
            </HStack>
          </FormControl>
        </View>
      </VStack>
    );
  };

  const renderVisitType = () => {
    return (
      <VStack
          _text={{
            bold: true,
          }}
          flex={1}
          justifyContent="center"
        >
          <FormControl.Label>
            <DisplayText
              textLocalId={'visitType'}
              extraStyles={{
                color: Colors.Custom.Gray400,
              }}
            />
          </FormControl.Label>
          <ModalActionAntSelect
              value={formData.visitTypeId || defaultVisitType?.id}
              data={visitTypeList}
              loading={loadingData}
              allowClear={false}
              onChange={(value: string) => {
                setFormData((prev) => ({...prev, visitTypeId: value }));
              }}
              filterOption={(input: string, option: any) =>
                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
              optionProps={{
                key: 'id',
                label: 'value',
                value: 'id',
              }}
              placeholder="Select visit type"
              customStyle={{flex: 1, width: '50%'}}
          />
      </VStack>
    );
  };

  return (
    <>
      <Drawer
        open={props.isVisible}
        width="45%"
        style={{
          paddingTop: 0,
        }}
        title={
          <ModalActionTitle
            isHeadNotSticky
            title={`${isUpdate ? 'Update' : 'Add'} Appointment Type`}
            buttonList={[
              {
                show: true,
                btnText: 'cancel',
                size: 'sm',
                colorScheme: 'muted',
                isDisabled:
                  uniqueNameLoading || addOrUpdateAppointmentTypeLoading,
                variant: BUTTON_TYPE.SECONDARY,
                backgroundColor: Colors.secondary['100'],
                textColor: Colors.secondary['800'],
                borderColor: Colors.secondary['200'],
                isRightDividerNotVisible: true,
                onClick: () => {
                  props.onClose();
                },
              },
              {
                show: true,
                btnText: 'save',
                isLoading:
                   addOrUpdateAppointmentTypeLoading,
                isDisabled:
                  uniqueNameLoading || addOrUpdateAppointmentTypeLoading,
                size: 'sm',
                colorScheme: 'primary',
                variant: BUTTON_TYPE.PRIMARY,
                backgroundColor: Colors.secondary['100'],
                textColor: Colors.secondary['800'],
                borderColor: Colors.secondary['200'],
                isRightDividerNotVisible: true,
                leftIcon: (
                  <Icon
                    as={AntIcon}
                    name={'save'}
                    size="4"
                    color={Colors.Custom.mainPrimaryPurple}
                  />
                ),
                onClick: () => {
                  onSubmit();
                },
              },
            ]}
          />
        }
      >
        <VStack space={4} overflow="scroll">
          {renderLocationGroups()}
          {allowAppointmentTypesGroup && renderAppointmentCategory()}
          <HStack space={4} flex={1}>
            {renderAppointmentTypeNameField()}
            {((isAthenaEHR && type !== AppointmentTypeAction.groupSessionAppointment) || componentState.isAthenaLocationGroup) && renderShortNameField()}
            {/* {!isAthenaEHR && renderUserRoleElement()} */}
          </HStack>
          {renderDescriptionField()}
          <HStack space={4} flex={1}>
            {type !== AppointmentTypeAction.groupAppointment &&
              renderDurationField()}
            {renderAppointmentModeField()}
          </HStack>
          {type !== AppointmentTypeAction.groupSessionAppointment && renderVisitType()}
          {type === AppointmentTypeAction.groupSessionAppointment && (
            <HStack space={4} flex={1}>
              {renderMemberLimitField()}
            </HStack>
          )}
          <HStack space={4} flex={1}>
            {type === AppointmentTypeAction.groupAppointment &&
              renderParticipantField()}
          </HStack>
          <HStack space={4} flex={1}>
            {renderApplicableToElement()}
          </HStack>
          {type !== AppointmentTypeAction.groupSessionAppointment && <HStack space={4} flex={1}>
            {renderRestrictUsersElement()}
          </HStack>}

          {appointmentTypeAvailability.isLoading &&
            [1, 2, 3, 5].map((a, index) => <Skeleton key={index} />)}
          {!appointmentTypeAvailability.isLoading &&
            type !== AppointmentTypeAction.groupSessionAppointment &&
            renderAppointmentTypeAvailability()}
          <Divider />
          <VStack space={4} flex={1}>
            {type !== AppointmentTypeAction.groupSessionAppointment && (
              <View flex={1}>
                <Checkbox
                  checked={formData.isShownToPatient}
                  value="isShownToPatient"
                  onChange={handleIsShownToPatientChange}
                >
                  <DisplayText
                    textLocalId="canPatientBookThisTypeOfAppointment"
                    extraStyles={{marginLeft: 8, fontSize: 14}}
                  />
                </Checkbox>

                {formData.isShownToPatient && renderApplicableForElement()}
                {formData.isShownToPatient && renderIsShownToPatientField()}
              </View>
            )}
            {(type === AppointmentTypeAction.groupSessionAppointment
              ? true
              : formData.availabilityTypeCode ===
                AppointmentAvailabilityCode.ROLE) && (
              <View flex={1}>
                <Checkbox
                  checked={formData.isRsvpEnabled}
                  value="isRsvpEnabled"
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      isRsvpEnabled: e.target.checked,
                    }))
                  }
                >
                  <DisplayText
                    textLocalId="isRsvpEnabledForThisTypeOfAppointment"
                    extraStyles={{marginLeft: 8, fontSize: 14}}
                  />
                </Checkbox>
              </View>
            )}
              <View flex={1}>
              {type !== AppointmentTypeAction.groupSessionAppointment && (
                <Checkbox
                  checked={formData.isLimitCancelRescheduleEnabled}
                  value="isLimitCancelRescheduleEnabled"
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      isLimitCancelRescheduleEnabled: e.target.checked,
                      ...(!e.target.checked && {
                        cancelIntervalType:
                          APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME,
                        rescheduleIntervalType:
                          APPOINTMENT_TYPE_INTERVAL_TYPES.ANY_TIME,
                      }),
                    }))
                  }
                >
                  <DisplayText
                    textLocalId="limitCancelOrRescheduleTime"
                    extraStyles={{marginLeft: 8, fontSize: 14}}
                  />
                </Checkbox>
              )}
              {formData.isLimitCancelRescheduleEnabled && renderCancelRescheduleLimitToPatientField()}
              </View>
          </VStack>
          <Divider />
          <View flex={1} width={'full'}>
            {renderInstructionForStaffField()}
          </View>
        </VStack>
      </Drawer>
      {userAvailability.showAvailability && (
        <Drawer
          open={userAvailability.showAvailability}
          width={'80%'}
          closable={true}
          title={
            <ModalActionTitle
              title={'User Schedule'}
              buttonList={[
                {
                  show: true,
                  id: 1,
                  btnText: 'close',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: () => {
                    setUserAvailability((prev) => ({
                      ...prev,
                      showAvailability: false,
                      selectedUsers: [],
                    }));
                  },
                },
              ]}
            />
          }
          onClose={() => {
            setUserAvailability((prev) => ({
              ...prev,
              showAvailability: false,
              selectedUsers: [],
            }));
          }}
        >
          <PracticeAvailability
            isInboxHours={false}
            isUserSchedule={true}
            isReadOnly={true}
            users={userAvailability.selectedUsers}
          />
        </Drawer>
      )}
    </>
  );
};

export default AddOrUpdateAppointmentTypes;
