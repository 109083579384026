import {Table} from 'antd';
import {Spinner, useToast} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {Dimensions,View} from 'react-native';
import {useNavigate, useParams} from 'react-router-dom';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import PageBodyContainer from '../../../../../../common/PageBodyContainer/PageBodyContainer';
import {TableWrapper} from '../../../../../../common/TableWrapper';
import {getQualifiedPatientList} from '../../../../HelperFiles/TablesColumnsView';
import {IQualityMeasuresPatientList} from '../../../../HelperFiles/interface';
import {MeasureCriteriaDetailDrawer} from '../MeasureCriteriaDetail/MeasureCriteriaDetailDrawer';
import {MIDDLE_CONTAINER_TABS} from '../../../../../../PersonOmniView/MiddleContainer/MiddleContainerConst';
import {ExecutionDate} from '../../../../ExecutionDateView';
import {useLazyQuery} from '@apollo/client';
import LeadQueries from '../../../../../../../services/Lead/LeadQueries';
import MessagingContactDetailsDrawer from '../../../../../TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import {PERSON_TYPES} from '../../../../../../../constants';
import {IContactForQuickDrawer, IContactResponseForQuickDrawer} from '../../../../../TeamInbox/Conversations/MessagingContactDetails/interface';
import FeatureFlags from '../../../../../../../constants/FeatureFlags.enums';
import {checkThisEmployeeIsRestricted, getBooleanFeatureFlag} from '../../../../../../../utils/commonUtils';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {onMultipleSelectedItem, removeDuplicates, removeDuplicatesQMContact} from '../../../../../Contacts/Leads/LeadView/LeadTableView/LeadListView/LeadListHelper';
import {isAccountConfigEnabled} from '../../../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../../../constants/AccountConfigConst';
import { useRestrictedEmployeeProfile } from '../../../../../../../utils/useRestrictedEmployeeProfile';
import { ToastType } from '../../../../../../../utils/commonViewUtils';
import { showToast } from '../../../../../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';

const PatientList = (props: IQualityMeasuresPatientList) => {
  const {stateData, handlePageChange, data, patientType, resetPatientSelection} = props;
  const [patientListState, setPatientListState] = useState({
    selectedItems: [] as any,
    selectedRowKey: [] as any,
    showMeasureCriteriaDetailDrawer: false,
    selectedMeasure: {} as any,
    contactData: {} as IContactForQuickDrawer,
    isLoadingContact: false,
  })
  const {height} = Dimensions.get('window');
  const {contractId, qmId} = useParams();
  const finalHeight = height - 340;
  const navigate = useNavigate();
  const commonData = useContext(CommonDataContext);
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const restrictedEmployeeProfileData = useRestrictedEmployeeProfile();
  const toast = useToast();
  const intl = useIntl();
  const hidePayerColumnInContract = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.HIDE_PAYER_COLUMN_IN_CONTRACT);
  const [GET_CONTACT_DATA_FOR_QUICK_DRAWER] = useLazyQuery(LeadQueries.contactDataForQuickDrawer,{
    fetchPolicy: 'network-only'
  })
  const onActionPerformed = async (actionCode: string, actionData?: any) => {
    switch(actionCode) {
      case COMMON_ACTION_CODES.SHOW_REASON:
        setPatientListState((prev) => {
          return{
            ...prev,
            showMeasureCriteriaDetailDrawer: true,
            selectedMeasure: actionData,
          }
        })
        break;
      case COMMON_ACTION_CODES.CLOSE:
        setPatientListState((prev) => {
          return{
            ...prev,
            showMeasureCriteriaDetailDrawer: false,
            selectedMeasure: {}
          }
        })
        break;
      case COMMON_ACTION_CODES.OPEN:
        const isRestrictedEmployeeProfileView = await checkThisEmployeeIsRestricted(
          restrictedEmployeeProfileData?.isRestrictedParticularLocationEmployeeEnabled,
          actionData?.contactData?.contactPracticeLocations?.map((location: any) => location?.accountLocation?.uuid),
          restrictedEmployeeProfileData?.restrictedLocationIds
        );
        if (!isRestrictedEmployeeProfileView) {
          handleOpenQuickDrawer(actionData?.contactData)
        } else {
          showToast(
            toast,
            intl.formatMessage({
              id: 'patientProfileAccessDenyMsg',
            }),
            ToastType.info,
            2000,
            true
          );
        }
        // const selectedMiddleContainerTab = MIDDLE_CONTAINER_TABS.ALERTS;
        // window.open(`#/members/patient/${actionData?.contactData?.id}/${selectedMiddleContainerTab}`, '_blank', 'noopener,noreferrer');
        break;
      case COMMON_ACTION_CODES.ON_BULK_ACTION:
        props?.onActionPerformed?.(actionCode, actionData);
        break;
      default:
        break;
    }
  }

  const handleOpenQuickDrawer = async (contactData: {uuid: string}) => {
    try {
      setPatientListState(prev => ({
        ...prev,
        isLoadingContact: true
      }));
  
      const response: IContactResponseForQuickDrawer = await GET_CONTACT_DATA_FOR_QUICK_DRAWER({
        variables: {
          uuid: contactData?.uuid
        }
      });
  
      setPatientListState((prev) => ({
        ...prev,
        contactData: response?.data?.contacts?.[0] || {} as IContactForQuickDrawer,
        isLoadingContact: false
      }));
    } catch  {
      setPatientListState((prev) => ({
        ...prev,
        contactData: {} as IContactForQuickDrawer,
        isLoadingContact: false
      }));
    }
  }
  useEffect(()=> {
    if(resetPatientSelection) {
      setPatientListState((prev) => ({
        ...prev,
        selectedItems: [],
        selectedRowKey: []
      }));
      onActionPerformed(COMMON_ACTION_CODES.ON_BULK_ACTION, []);
    }
  },[resetPatientSelection, patientType])
  return (
    <>
      <PageBodyContainer>
        <ExecutionDate date={stateData?.executionDate} loading={stateData.executionDateLoading}/>
        <TableWrapper
          wrapperStyle={{alignItems: 'center'}}
          minHeight={230}
          pagination={{
            current: stateData?.currentPage,
            pageSize: stateData?.pageSize || 10,
            total: stateData?.total,
            onChange: (currentPage, currentPageSize) => {
              handlePageChange?.(currentPage, currentPageSize);
            },
          }}
          hideOnSinglePage={false}
        >
          <Table
            scroll={{x: 1000, y: finalHeight}}
            rowClassName={(record, index) => {
              let className = index % 2 == 0 ? 'table-row-light' : ''
              className = className + ' quality-measures-report'
              return className
            }
            }
            className="lead-list-table-view patient-list-view"
            columns={getQualifiedPatientList({memberType: patientType,hidePayerColumnInContract},onActionPerformed)}
            dataSource={data}
            pagination={false}
            loading={{
              spinning: stateData.loading || patientListState.isLoadingContact,
              indicator: (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <Spinner size={'lg'} />
                </View>
              ),
            }}
            rowSelection={(isMsoEnabled) ? undefined : {
              type: 'checkbox',
              selectedRowKeys: patientListState.selectedRowKey,
              checkStrictly: true,
              onSelect: (item: any) => {
                onMultipleSelectedItem(
                  item,
                  patientListState.selectedItems,
                  (returnItems: any) => {
                    const selectedKey = returnItems.map(
                      (element: any) => element?.contactData?.uuid
                    ).filter(Boolean);

                    onActionPerformed(
                      COMMON_ACTION_CODES.ON_BULK_ACTION,
                      returnItems
                    );
                    setPatientListState((prev) => {
                      return {
                        ...prev,
                        selectedItems: returnItems,
                        selectedRowKey: selectedKey,
                      };
                    });
                  }
                );
              },
              onSelectAll: (isSelected: boolean) => {
                if (!isSelected) {
                  const arrayKey = [...patientListState?.selectedRowKey];
                  const arrayItems = [...patientListState?.selectedItems];
                  data?.map((item?: any) => {
                    arrayKey.map((itemId?: any) => {
                      if (item?.contactData?.uuid == itemId) {
                        const index = arrayKey.indexOf(itemId);
                        arrayKey.splice(index, 1);
                        setPatientListState(prev=> {
                          return {
                            ...prev,
                            selectedRowKey: arrayKey,
                          }
                        });
                      }
                    });
                    arrayItems.map((itemId?: any) => {
                      if (item?.contactData?.uuid == itemId?.contactData?.uuid) {
                        const index = arrayItems.indexOf(itemId);
                        arrayItems.splice(index, 1);
                        setPatientListState(prev=> {
                          return {
                            ...prev,
                            selectedItems: arrayItems,
                            selectedRowKey: arrayKey,
                          }
                        });
                      }
                    });
                  });
                  onActionPerformed(
                    COMMON_ACTION_CODES.ON_BULK_ACTION,
                    arrayItems
                  );
                } else {
                  const arrayItems = [...patientListState?.selectedItems];
                  data?.forEach((item?: any) => {
                    arrayItems.push(item);
                  });
                  const uniqueArray = removeDuplicatesQMContact(arrayItems);
                  const selectedKey: any = [];
                  uniqueArray.forEach((element: any) => {
                    selectedKey.push(element.contactData.uuid);
                  });

                  setPatientListState(prev=> {
                    return {
                      ...prev,
                      selectedItems: uniqueArray,
                      selectedRowKey: selectedKey,
                    }
                  });
                  onActionPerformed(
                    COMMON_ACTION_CODES.ON_BULK_ACTION,
                    uniqueArray
                  );
                }
              },
              defaultSelectedRowKeys: patientListState?.selectedRowKey
            }}
            rowKey={(record: any) => {
              return record?.contactData?.uuid;
            }}
          />
        </TableWrapper>
      </PageBodyContainer>
      {patientListState?.showMeasureCriteriaDetailDrawer && <MeasureCriteriaDetailDrawer
        selectedMeasureId={patientListState?.selectedMeasure?.id}
        isInverse={patientListState?.selectedMeasure?.inverse}
        onDrawerClose={onActionPerformed}
        criteriaType={patientType}
        qmMeasureEvidence={patientListState?.selectedMeasure?.qmMeasureEvidence}
      />}
      {patientListState?.contactData?.id && <MessagingContactDetailsDrawer
        contactData={patientListState?.contactData}
        isDrawerVisible={patientListState?.contactData ? true : false}
        contactType={PERSON_TYPES.CUSTOMER}
        onSideBarActionPerformed={() => {
          setPatientListState((prev) => {
            return {
              ...prev,
              contactData: {} as IContactForQuickDrawer
            }
          })
        }}
      />}
    </>
  );
};

export default PatientList;
