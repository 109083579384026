
import { Button, IconButton, Text, Tooltip, VStack, View } from 'native-base';
import { useState } from 'react';
import Feather from 'react-native-vector-icons/Feather';
import { useLocation, useNavigate } from 'react-router-dom';
import { Colors } from '../../../../styles/Colors';
import { getOnFocusButtonStyles, getUserUUID } from '../../../../utils/commonUtils';
import FHCustomSwitch from '../../../common/CustomSwtich/FHCustomSwitch';
import FormAnalytics from './FormAnalytics';
import FormReport from './FormReport';
import FormResponses from './FormResponses';
import { styles } from './style';
import moment from 'moment';
import {Employer} from '../../BrazilCodeMR/pages/analytics/AnalyticsController';
import { ALL_MEMBERS_OBJ } from '../../Analytics/EmployerAnalytics/EmployerUtils/EmployerConst';
import {useIntl} from 'react-intl';
import {ModuleCodes, useModuleSpecificUserLocations} from '../../../CustomHooks/useModuleSpecificUserLocations';
import {Skeleton} from 'antd';
import {usePermissions} from '../../../CustomHooks/usePermissions';
import {USER_ACCESS_PERMISSION} from '../../UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../../SideMenuBar/SideBarConst';

export enum FormReportScreen {
    INSIGHTS = 'INSIGHTS',
    REPORT = 'REPORT',
    RESPONSES = 'RESPONSES',
    RESPONDED = 'RESPONDED',
    PENDING = 'PENDING'
}


const FormsScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl();
    const [dateRange, setDateRange] = useState<Record<string, string>>({
        from: moment().subtract(1, 'month').startOf('month').format('yyyy-MM-DDTHH:mm:ss'),
        to: moment().endOf('month').format('yyyy-MM-DDTHH:mm:ss'),
    });
    const [employer, setEmployer] = useState({
        selected : ALL_MEMBERS_OBJ
    });
    const [locationGroupIds, setLocationGroupIds] = useState<string[]>([]);
    if (!location?.state?.selectedForm) {
        navigate('/admin/contentManagement/forms', { replace: true });
    }
    const [selectedScreen, setSelectedScreen] = useState(location?.state?.selectedScreen || 'INSIGHTS')
    const userUUID = getUserUUID();
    const {check} = usePermissions();
    const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code, MAIN_MENU_CODES.FORMS);
    const currentUserAllowedLocations = permissionConfig?.allowedLocationIds || [];

    return (
        <View overflowY={'scroll'} style={styles.height100}>
            <View style={styles.headerContainer}>
              <VStack style={styles.maxWidth60}>
                <View style={styles.flexDirectionRowAlignItemsCenterFlex1}>
                    <Feather onPress={() => {
                        navigate('/admin/contentManagement/forms')
                    }} style={styles.marginRight10} size={25} name='arrow-left' color={Colors.Custom.Gray500} />
                    <Text style={styles.marginRight6} size={'smBold'} fontSize={18}>
                        {location?.state?.selectedForm?.name}
                    </Text>
                </View>
                <Text
                  style={styles.colorGray400Padding4PaddingTop0FontSize12MarginLeft20}
                >
                  • {intl.formatMessage({id: 'analyticsSyncMessage'})}
                </Text>
              </VStack>
                <View style={styles.flexDirectionRowAlignItemsCenter}>
                    <FHCustomSwitch
                        btnTextArray={[
                            {
                                text: 'Insight',
                                code: FormReportScreen.INSIGHTS,
                                leadingIcon: <Feather
                                    name="bar-chart"
                                    size={17}
                                    style={styles.marginRight6}
                                    color={selectedScreen == FormReportScreen.INSIGHTS ? Colors.Custom.Gray500 : Colors.Custom.Gray300}
                                />
                            },
                            {
                                text: 'Report',
                                code: FormReportScreen.REPORT,
                                leadingIcon: <Feather
                                    name="file-text"
                                    size={17}
                                    style={styles.marginRight6}
                                    color={selectedScreen == FormReportScreen.REPORT ? Colors.Custom.Gray500 : Colors.Custom.Gray300}
                                />
                            },
                            {
                                text: 'Responses',
                                code: FormReportScreen.RESPONSES,
                                leadingIcon: <Feather
                                    name="arrow-down-right"
                                    size={17}
                                    style={styles.marginRight6}
                                    color={(selectedScreen == FormReportScreen.RESPONSES || selectedScreen == FormReportScreen.PENDING || selectedScreen == FormReportScreen.RESPONDED) ? Colors.Custom.Gray500 : Colors.Custom.Gray300}
                                />
                            },
                        ]}
                        customSelectedBackground={Colors.Custom.Gray100}
                        customSelectedTextColor={'#000'}
                        customerUnselectedColor={Colors.Custom.Gray400}
                        onBtnSelect={(code) => {
                            setSelectedScreen(code);
                        }}
                        selectedCode={(selectedScreen == FormReportScreen.RESPONSES || selectedScreen == FormReportScreen.PENDING || selectedScreen == FormReportScreen.RESPONDED) ? FormReportScreen.RESPONSES : selectedScreen}
                    />
                    <View style={styles.flexDirectionRow}>
                        <Tooltip label="Edit Form" placement="top">
                            <Button
                                style={styles.bgTrasparantAndPaddingHorizontal0}
                                _focus={{
                                    ...getOnFocusButtonStyles(),
                                }}
                            >
                                <IconButton
                                    colorScheme="gray"
                                    tintColor="gray.500"
                                    variant="ghost"
                                    icon={
                                        <Feather
                                            name="edit-3"
                                            size={17}
                                            color={Colors.Custom.Gray600}
                                        />
                                    }
                                    onPress={() => {
                                        // onEdit(form);
                                        navigate('/admin/contentManagement/forms/update', {
                                            state: {
                                                selectedForm: location?.state?.selectedForm,
                                            },
                                        });
                                    }}
                                />
                            </Button>
                        </Tooltip>
                        {/* <Tooltip label="Print Form" placement="top">
                            <Button
                                style={ {
                                    backgroundColor: 'transparent',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                }}
                                _focus={{
                                    ...getOnFocusButtonStyles(),
                                }}
                            >
                                <IconButton
                                    colorScheme="gray"
                                    tintColor="gray.500"
                                    variant="ghost"
                                    icon={
                                        <Feather
                                            name="printer"
                                            size={17}
                                            color={Colors.Custom.Gray600}
                                        />
                                    }
                                    onPress={() => {
                                        // onPrint(form);
                                        const printUrl = getFormPrintURL(location?.state?.selectedForm, accountId);
                                        window.open(printUrl, '_blank');
                                    }}
                                />
                            </Button>
                        </Tooltip> */}
                    </View>
                </View>
            </View>
            {selectedScreen == 'INSIGHTS'  && <FormAnalytics
                locationIds={currentUserAllowedLocations}
                dateRange={dateRange}
                setDateRange={setDateRange}
                locationGroupIds={locationGroupIds}
                onLocationGroupChange={(locationGroupIds: string[]) => {
                    setLocationGroupIds(locationGroupIds);
                }}
                onEmployerChange={(employer: Employer) => {
                    setEmployer((prev) => {
                        return {
                            ...prev,
                            selected: {
                                id: employer?.id,
                                name: employer?.name
                            }
                        }
                    })
                }}
                employerData={employer?.selected}
            />}

            {selectedScreen == 'REPORT' &&
            <FormReport
                locationIds={currentUserAllowedLocations}
                dateRange={dateRange}
                setDateRange={setDateRange}
                locationGroupIds={locationGroupIds}
                onLocationGroupChange={(locationGroupIds: string[]) => {
                    setLocationGroupIds(locationGroupIds);
                }}
                onEmployerChange={(employer: Employer) => {
                    setEmployer((prev) => {
                        return {
                            ...prev,
                            selected: {
                                id: employer?.id,
                                name: employer?.name
                            }
                        }
                    })
                }}
                employerData={employer?.selected}
            />}

            { selectedScreen !== 'REPORT'
              && selectedScreen !== 'INSIGHTS'
              && <FormResponses
                selectedScreen={selectedScreen}
                selectedForm={location?.state?.selectedForm}
                locationIds={currentUserAllowedLocations}
            />}
        </View>
    )
}

export default FormsScreen;
