export const TAB_KEYS = {
    PATIENT: 'patient',
    MY_PATIENT: 'myPatient',
    PROSPECT: 'prospect',
    GROUPS: 'groups',
    LEAD_GROUPS: 'lead_groups',
    CODE_GROUPS: 'codeGroups',
    IMPORT_SUMMARY: 'importSummary',
    OVERVIEW: 'OVERVIEW'
};

export const COMPARISON_OPERATOR = {
  ANY: 'ANY'
}

export const USER_SEARCH_TYPE = {
  CURRENT_USER_LOCATION_BASED: 'CURRENT_USER_LOCATION_BASED',
  FILTERED_CURRENT_USER_LOCATION_BASED: 'FILTERED_CURRENT_USER_LOCATION_BASED',
  FILTERED_CURRENT_USER_BASED: 'FILTERED_CURRENT_USER_BASED',
  SELECTED_LOCATION_BASED: 'SELECTED_LOCATION_BASED',
  CURRENT_SELECTED_PATIENT_BASED: 'CURRENT_SELECTED_PATIENT_BASED',
  CURRENT_USER_LOCATION_BASED_WITH_BUSINESS_OWNER: 'CURRENT_USER_LOCATION_BASED_WITH_BUSINESS_OWNER',
  DEFAULT: 'DEFAULT',
} as const;

export const PACKAGE_CONSTANTS = {
  CARE_JOURNEY: 'CARE_JOURNEY',
  USER_INPUT_KEY: 'packageList',
  DRAFT: 'DRAFT',
  JOURNEY_AUTOMATION: 'careJourneyAndAutomationConflict',
  JOURNEY: 'careJourneyConflict',
  AUTOMATION: 'automationConflict'
};

export const COMMUNICATION_MAP = {
  'lastTenMessages': 'Last 10 messages',
  'allConversation': 'All conversation',
  'lastArchived': 'Last archived'
};

export const COMPARISON_OPERATOR_MAP = {
  'BTW': 'Between',
  'EQ': 'Equals',
  'NEQ': 'Not Equals',
  'GT': 'Greater Than',
  'LT': 'Less Than',
  'SM' : 'Similar To',
  'GTE': 'Greater Than or Equals',
  'LTE': 'Less Than or Equals'
};


export const PHONE_TYPE = {
  DESK_PHONE: 'deskPhone',
  MOBILE: 'mobile',
  WEB_PHONE: 'webPhone',
  ON_CALL_SCHEDULE: 'onCallScheduleUsers'
}
