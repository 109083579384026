import { useContext, useEffect, useState } from "react"
import FormResponsesList from "../FormBuilderWidget/FormResponses/FormResponsesList"
import PendingForms from "../FormBuilderWidget/PendingForms/PendingForms"
import { FormReportScreen } from "."
import { View } from "native-base"
import { useLazyQuery, useQuery } from "@apollo/client"
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../constants/Configs"
import { FormsQueries } from "../../../../services"
import { showToast, ToastType } from "../../../../utils/commonViewUtils"
import { useLocation } from "react-router-dom"
import { getMlovIdFromCode, getMlovListFromCategory } from "../../../../utils/mlovUtils"
import { MLOV_CATEGORY } from "../../../../constants"
import { FORM_STATUS_CODE } from "../../../../constants/MlovConst"
import { CommonDataContext } from "../../../../context/CommonDataContext"
import { checkNoteCategoryForm } from "../FormBuilderWidget/FormList/FormListUtils"
import { useIntl } from "react-intl"
import {isAccountConfigEnabled} from "../../../../utils/configUtils"
import {CONFIG_CODES} from "../../../../constants/AccountConfigConst"



const FormResponses = (props: { selectedForm: any, selectedScreen?:string, locationIds?: string[] }) => {
    const [selectedScreen, setSelectedScreen] = useState<string>(props?.selectedScreen|| FormReportScreen.RESPONDED);
    const [responseCount, setResponseCount] = useState({
        [FormReportScreen.RESPONDED]: 0,
        [FormReportScreen.PENDING]: 0,
    })
    const location = useLocation();
    const mlovData = useContext(CommonDataContext);
    const intl = useIntl();
    const formStatusMlovList = getMlovListFromCategory(
        mlovData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.FORM_STATUS,
    );
    const isLocationEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_LOCATION_HANDLING);
    const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED) && isLocationEnabled;

    const pendingStatusId = getMlovIdFromCode(formStatusMlovList, FORM_STATUS_CODE.PENDING);
    const draftStatusId = getMlovIdFromCode(formStatusMlovList, FORM_STATUS_CODE.DRAFT);

    const isNoteCategoryForm = checkNoteCategoryForm(props?.selectedForm?.formCategory);

    useQuery(isMsoEnabled ? FormsQueries.GET_FORM_RESPONSE_BY_ID_AND_LOCATION_IDS : FormsQueries.GET_FORM_RESPONSE_BY_ID, {
        fetchPolicy: 'no-cache',
        variables: {
          formId: props?.selectedForm?.id,
          ...( isMsoEnabled && {locationIds: props?.locationIds || []})
        },
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        onCompleted: async (data: any) => {
          if (data && data.formResponses?.length) {
            const newResponseCount = { ...responseCount };
            newResponseCount[FormReportScreen.RESPONDED] = data.formResponses?.length;
            setResponseCount(newResponseCount);

          }
        },
      });

    useQuery(isMsoEnabled ? FormsQueries.GET_PENDING_FORMS_COUNT_WITH_LOCATION_IDS : FormsQueries.GET_PENDING_FORMS_COUNT, {
        fetchPolicy: 'no-cache',
        variables: {
            formId: props?.selectedForm?.id,
            statusIds: [pendingStatusId, draftStatusId],
            offset: 0,
            ...(isMsoEnabled && {locationIds: props?.locationIds || []})
        },
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        onCompleted: async (response: any) => {
            const newResponseCount = { ...responseCount };
            newResponseCount[FormReportScreen.PENDING] = response?.aggregateFormLogs?.aggregate?.count || 0;
            setResponseCount(newResponseCount);

        },
    })

    const getTabLabel = (type: string) => {
      switch (type) {
        case FormReportScreen.RESPONDED:
          if (isNoteCategoryForm) {
            return `${intl.formatMessage({id: 'signedOrAmmended'})} (${
              responseCount[FormReportScreen.RESPONDED] || 0
            })`;
          } else {
            return `${intl.formatMessage({id: 'responded'})} (${
              responseCount[FormReportScreen.RESPONDED] || 0
            })`;
          }
        case FormReportScreen.PENDING:
          return `${intl.formatMessage({id: 'pending'})} (${
            responseCount[FormReportScreen.PENDING] || 0
          })`;
      }
      return '';
    };

    const childProps = {
      onTabClick: setSelectedScreen,
      selectedForm: props?.selectedForm,
      tabArray: [
        {
          label: getTabLabel(FormReportScreen.RESPONDED),
          type: FormReportScreen.RESPONDED,
        },
        ...(!isNoteCategoryForm
          ? [
              {
                label: getTabLabel(FormReportScreen.PENDING),
                type: FormReportScreen.PENDING,
              },
            ]
          : []),
      ],
      setResponseCount: (count: number, type: FormReportScreen.RESPONDED | FormReportScreen.PENDING) => {
        const newResponseCount = { ...responseCount };
        newResponseCount[type] = count;
        setResponseCount(newResponseCount);
      },
      locationIds: props?.locationIds
    };
    return (
        <>{selectedScreen == FormReportScreen.PENDING ?
          <PendingForms {...childProps} /> :
          <FormResponsesList {...childProps} />
        }
        </>
    )
}

export default FormResponses;
