import {useLazyQuery, useQuery} from '@apollo/client';
import moment from 'moment';
import {
  HStack,
  Pressable,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
  CLOUD_TELEPHONY_CODE,
  DISPLAY_DATE_FORMAT,
  GROUP_MEMBER_TYPE,
  PERSON_TYPES,
} from '../../../constants';
import {CloudTelephonyQueries, LeadQueries} from '../../../services';
import {Colors} from '../../../styles';
import {
  getAccountId,
  getAccountUUID,
  getAllowedUserPracticeLocationUuids,
  getGenderTextByGenderCode,
  getUserUUID,
  isLoggedInUserWorkFlowOrCustomerSuccess,
  isLoginUserBusinessOwner,
} from '../../../utils/commonUtils';
import CallAccept from '../Svg/CallAccept';
import CallEnd from '../Svg/CallEnd';
import {styles} from './PhoneCallingStyles';
import {getLastTenDigit, getNumber, isUuid, removeCountryCode} from './utils';
import {DisplayCardAvatar} from '../DisplayCard/DisplayCardAvatar';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import ProfileDetailView from '../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ProfileDetailView';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {PERSON_ACTION_CODES} from '../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {
  addParticipantToConference,
  getCallSidDetail,
  getParticipantsByCallSid,
  getParticipantsByConferenceSid,
  putParticipantOnHold,
  removeParticipant,
  upgradeCallToConference,
} from './ConferenceCallService';
import UserQueries from '../../../services/User/UserQueries';
import CallTransfer from '../Svg/CallTransfer';
import CallMute from '../Svg/TelephonySvg/CallMute';
import ConferenceAdd from '../Svg/TelephonySvg/ConferenceAdd';
import UserSearch from './UserSearch';
import {IUsersResponse} from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import CallForwardLoading from '../Svg/TelephonySvg/CallForwardLoading';
import './phoneCallingStyle.css';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import CallHold from '../Svg/TelephonySvg/CallHold';
import {PhoneCallingConst, SIP_REGEX} from './PhoneCallingConstant';
import {useOnlineStatus} from '../../CustomHooks/useOnlineStatus';
import {notification} from 'antd';
import AvatarWithSpinner from './AvatarWithSpinner';
import UserSearchSelect from './UserSearchSelect';
import ShowMicroPhoneOffPopUp from './MircroPhonePermission';
import DialPadButtonSvg from '../Svg/DialpadButtonSvg';
import Dialpad from '../../RightSideContainer/CloudTelephony/ActiveNumbersTable/Dialpad';
import {TouchableOpacity} from 'react-native';
import DialpadDeleteSvg from '../Svg/DialpadDeleteSvg';
import { EventBus } from '../../../utils/EventBus';
import { CALL_SUPPORTED_EVENTS } from '../../../constants/WebSocketConst';
import { CallEventCode, ConferenceEventCode, ConferenceEventReasonCode, IConferenceEvent, IConferenceEventData, IPariticpant} from './interface';
import AddedMembers from './AddedMembers';
import CallMuteActive from '../Svg/TelephonySvg/CallMuteActive';
import { CLOUD_TELEPHONY_APOLLO_CONTEXT } from '../../../constants/Configs';
import { IVirtualNumber } from '../OutboundCallSmsView/interface';
import {getCallExtensionList} from '../../RightSideContainer/CallConfiguration/AddCallExtensionService';
import {getExtensionIdByTransferType} from '../../RightSideContainer/CallConfiguration/CallExtensionUtils';
import {IExtensionList} from '../../RightSideContainer/CallConfiguration/interface';
import {CommonDataContext} from '../../../context/CommonDataContext';
import { Call } from '@twilio/voice-sdk';
import InboxQueries from '../../../services/Inbox/InboxQueries';
import {CALL_CONFIGURATION_TRANSFER_TO_CODE} from '../../RightSideContainer/CallConfiguration/CallExtensionConst';
import { testID, TestIdentifiers } from '../../../testUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';

interface IPhoneCalling {
  callObject: Call;
  onCall: boolean;
  direction: string;
  contactData: IContact;
  phoneSetup: () => void;
  onActionPerformed: (actionCode: string, rowData?: any) => void;
  onLockScreen?: boolean;
  isAnotherCallInProgress: boolean;
  loggedInUserUuid?: string;
  isConference?: boolean;
  isConnected?: boolean;
  isDisconnected?: boolean;
}
const PhoneCalling = (props: IPhoneCalling) => {
  const isOnline = useOnlineStatus();
  const accountId = getAccountId();
  const accountUUID = getAccountUUID();
  const loggedInUserId = getUserUUID();
  const [isMuted, setIsMuted] = useState(props?.callObject?.isMuted?.() ?? false);
  const [contactStateData, setContactStateData] = useState({} as IContact);
  const toast = useToast();
  const commonData = useContext(CommonDataContext);
  const userList = commonData?.accountUserList;
  const sipNumberList = commonData?.sipNumberList;
  const isAllowedAllLocation = isLoginUserBusinessOwner() || isLoggedInUserWorkFlowOrCustomerSuccess();
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CALL
  );
  const [stateData, setStateData] = useState({
    callerName: '',
    contact: {} as any,
    contactType: '',
    genderValue: '',
    ageInYears: '',
    conferenceSid: null,
    isOnHold: false,
    isCallTransferred: false,
    callTransferSuccess: false,
    isConferenceActive: false,
    callConferenceSuccess: false,
    callForwardedUserName: '',
    conferenceLoading: false,
    callHoldLoading: false,
    conferenceIdForHold: '',
    initialCallerSid: '',
    isIncomingCallConference: props?.isConference || false,
    participants: [],
    actualUserList: [],
    microPhonePop: false,
    callerId: '',
    isOutgoingCallAnswered: false,
    conferenceCallerUserId: '',
    extensionList: [] as IExtensionList[],
  } as any);

  const [selectInputFocusState, setSelectInputFocusState] = useState<boolean>(false)

  const isUserSelectDisabled = () => {
    return (
      (stateData.isCallTransferred &&
        stateData.participants?.length === 3 &&
        !!stateData.conferenceSid) ||
      !!stateData.callForwardedUserName
    );
  };

  const [dialPadState, setDialPadState] = useState({
    show: false,
    dialPadNumberValue: '',
  });
  const fetchContactMaxTries = 3;

  const [GetContactNameByPhoneNumber, loadingContactName] = useLazyQuery(
    LeadQueries.GetContactNameByPhoneNumber,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [GetSmsChannelForPhoneNumber] = useLazyQuery(
    InboxQueries.GetSmsChannelForPhoneNumber,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getConversationForContactAndChannelIds] = useLazyQuery(
    InboxQueries.GetConversationForContactAndChannelIds,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getContactsByIds] = useLazyQuery(LeadQueries.getContactsByIds);

  const [getContactByPhoneNumberAndInboxIds] = useLazyQuery(
    LeadQueries.GetContactByPhoneNumberAndInboxIds,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getInboxDataByPhoneNumber] = useLazyQuery(
    InboxQueries.GetInboxDataByPhoneNumber,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [GET_NUMBER_CALL_SMS] = useLazyQuery(
    CloudTelephonyQueries.GET_SINGLE_NUMBER_BY_VIRTUAL_NUMBER,{
      fetchPolicy: 'no-cache',
    }
  );

  const [accountUserListApi] = useLazyQuery<IUsersResponse>(UserQueries.GET_ACTIVE_USERS_FOR_CALL, {
    variables: {
      accountId: getAccountId(),
    },
  });

  const [getUserLocationBasedAccountUserList] = useLazyQuery<IUsersResponse>(
    UserQueries.GET_USER_LOCATION_BASED_ACCOUNT_USER_LIST
  );

  const fetchAndUpdateConferenceUserList = async () => {
    let accountUsersRes: any;
    if (!isAllowedAllLocation && isCommunicationLocationHandlingEnabled){
      accountUsersRes = await getUserLocationBasedAccountUserList({
        variables: {
          accountId: accountId,
          locationUuids: allowedUserPracticeLocationUuids
        }
      })
    } else {
      accountUsersRes = await accountUserListApi();
    }
    const updatedUserList = accountUsersRes?.data?.users?.filter(
      (user: any) => {
        return user.uuid != loggedInUserId;
      }
    );
    setStateData((prev: any) => {
      return {
        ...prev,
        userList: updatedUserList,
        actualUserList: updatedUserList,
      };
    });
  };

  const getUserInboxIds = async () => {
    if (!props.callObject?.parameters?.To?.includes('client:')) {
      return [];
    }
    const callUserUuid = props.callObject?.parameters?.To.replace(
      'client:',
      ''
    );
    if (!callUserUuid || !props.callObject?.customParameters) {
      return [];
    }
    const toNumber = props.callObject?.customParameters?.get('toNumber');
    if (!toNumber) {
      return [];
    }
    const response = await getInboxDataByPhoneNumber({
      variables: {
        phoneNumber: toNumber,
      },
    });
    const inboxIds: number[] = (response?.data?.inboxes || []).map(
      (item: any) => {
        return item.id;
      }
    );
    return inboxIds;
  };

  const getUserPhoneNumber = () => {
    if (!props.callObject?.parameters?.To?.includes('client:')) {
      return '';
    }
    const callUserUuid = props.callObject?.parameters?.To.replace(
      'client:',
      ''
    );
    if (!callUserUuid || !props.callObject?.customParameters) {
      return '';
    }
    const toNumber = props.callObject?.customParameters?.get('toNumber');
    if (!toNumber) {
      return '';
    }
    const formattedNumber = toNumber.startsWith('+') ?
      toNumber.replace(/\s+/g, '') :
      `+${toNumber.replace(/\s+/g, '')}`;
    return formattedNumber;
  };

  const getExistingConversationContactData = async (
    contactPhoneNumber: string
  ) => {
    const providerPhoneNumber = getUserPhoneNumber();
    if (providerPhoneNumber) {
      const smsChannel = await GetSmsChannelForPhoneNumber({
        variables: {
          accountId: accountId,
          phoneNumber: `${providerPhoneNumber}`,
        },
      });
      const smsChannelIds: number[] = [];
      if (smsChannel?.data?.channelTwilioSmsList?.length) {
        smsChannel?.data?.channelTwilioSmsList?.forEach((channel: any) => {
          smsChannelIds.push(Number(channel.id));
        });
      }

      const smsConversations = await getConversationForContactAndChannelIds({
        variables: {
          sourceId: contactPhoneNumber,
          channelIds: smsChannelIds,
        },
      });

      if (
        smsConversations?.data?.conversations &&
        smsConversations?.data?.conversations?.length
      ) {
        const smsInbox = smsConversations?.data?.conversations?.[0];
        const contactId = smsInbox?.contactId;
        if (contactId) {
          return await getContactsByIds({
            variables: {
              contactIdList: [contactId],
              accountId: accountId,
            },
          });
        }
      }
      return {data: {contacts: []}};
    }
    return {data: {contacts: []}};
  };

  const getContactDataByPhoneNumber = async (contactPhoneNumber: string) => {
    const tenDigitContactPhoneNumber = getLastTenDigit(contactPhoneNumber)
    if (props.direction === CLOUD_TELEPHONY_CODE.OUTGOING) {
      return GetContactNameByPhoneNumber({
        variables: {
          accountId: accountId,
          phoneNumber: `%${tenDigitContactPhoneNumber}%`,
        },
      });
    }
    const getContactActualData = await getExistingConversationContactData(
      contactPhoneNumber
    );
    const contactPhoneNumberWithoutCountryCode = removeCountryCode(contactPhoneNumber);
    if (
      getContactActualData?.data?.contacts?.length &&
      getContactActualData?.data?.contacts?.[0]?.phoneNumber ===
      contactPhoneNumberWithoutCountryCode
    ) {
      return getContactActualData;
    }
    const inboxIds = await getUserInboxIds();
    if (inboxIds.length === 0) {
      return GetContactNameByPhoneNumber({
        variables: {
          accountId: accountId,
          phoneNumber: `%${tenDigitContactPhoneNumber}%`,
        },
      });
    }
    const response = await getContactByPhoneNumberAndInboxIds({
      variables: {
        contactPhoneNumber,
        inboxIds,
      },
    });
    if (
      response?.data?.contacts?.length > 0 &&
      response?.data?.contacts?.[0]?.phoneNumber === contactPhoneNumber
    ) {
      return response;
    }
    return GetContactNameByPhoneNumber({
      variables: {
        accountId: accountId,
        phoneNumber: `%${tenDigitContactPhoneNumber}%`,
      },
    });
  };

  const fetchAndUpdateIncomingCallParticipants = async () => {
    if (props.direction === CLOUD_TELEPHONY_CODE.OUTGOING) {
      const callSid = props.callObject?.parameters?.CallSid;
      const participantList: any = await getParticipantsByCallSid(
        callSid
      ).catch(() => {
      });
      setStateData((prev: any) => {
        return {
          ...prev,
          ...(participantList?.data && {
            participants: participantList?.data || [],
          }),
          ...(participantList?.data && {
            conferenceSid: participantList?.data[0]?.conferenceSid,
          }),
          callSid: callSid,
        };
      });
    }
  };

  const getCallersName = async (getContactInterval?: any, fetchContactTry = 0) => {
    const callSid = props.callObject?.parameters?.CallSid;
    if (props.direction === CLOUD_TELEPHONY_CODE.OUTGOING) {
      setContactStateData((prev) => {
        return {
          ...prev,
          ...props.contactData,
        };
      });
      const callerId = props.callObject?.customParameters?.get?.("userUUID");

      setStateData((prev: any) => {
        return {
          ...prev,
          callSid: callSid,
          callerId: callerId,
          calleeId: props.callObject?.parameters?.To
        };
      });
    } else {
      const calleeId = props.callObject?.parameters?.To;
      setStateData((prev: any) => {
        return {
          ...prev,
          callSid: callSid,
          calleeId: calleeId
        };
      });
      if (
        props.callObject?.parameters?.From?.includes('client:') &&
        !stateData.callerName
      ) {
        const callerUserId = props.callObject?.parameters?.From.replace(
          'client:',
          ''
        );
        const callerName = getUserDetailByUserId(callerUserId);
        setStateData((prev: any) => {
          return {
            ...prev,
            callerName: callerName || 'abc',
            callSid: callSid,
          };
        });
      } else {
        if (props?.isConference) {
          try {
            Promise.all([
              getContactDataByPhoneNumber(props.callObject?.parameters?.From),
              getVirtualNumbersList(props.callObject?.parameters?.From),
            ]).then((responses) => {
              const contactData = responses?.[0]?.data?.contacts;
              const numberAssigneeList: IVirtualNumber[] =
                responses?.[1]?.data?.virtualPhoneNumberAssignees;
              if (contactData?.length) {
                const firstContact: any = contactData[0];
                setContactStateData((prev) => {
                  return {
                    ...prev,
                    ...firstContact,
                  };
                });
                getContactDataAndUpdate(firstContact, callSid);
              } else {
                getUserNameFromAssigneeList(
                  callSid,
                  numberAssigneeList
                );
              }
            });
          } catch (error) {
            setStateData((prev: any) => {
              return {
                ...prev,
                callSid: callSid,
              };
            });
          }
        } else {
        const response = await getContactDataByPhoneNumber(props.callObject?.parameters?.From);
        if (response?.data?.contacts) {
          const contactData = response?.data?.contacts;
          if (contactData?.length) {
            const firstContact = contactData[0];

            getContactInterval && clearInterval(getContactInterval);

            setContactStateData((prev) => {
              return {
                ...prev,
                ...firstContact,
              };
            });
              getContactDataAndUpdate(firstContact, callSid);
            } else {
              if (fetchContactTry === 1) {
                const assigneeName = await updateCallerNameByVirtualNumbers(callSid);
                if (assigneeName) {
                  getContactInterval && clearInterval(getContactInterval);
                }
              }
              if (fetchContactTry < fetchContactMaxTries) {
                const getContactInterval = setTimeout(() => {
                  getCallersName(getContactInterval, fetchContactTry + 1);
                }, 2000);
              } else {
                // getContactInterval && clearInterval(getContactInterval);
                //get virtual PhoneNumber Assignees user data
                // await updateCallerNameByVirtualNumbers(callSid);
              }
            }
            setStateData((prev: any) => {
              return {
                ...prev,
                callSid: callSid,
              };
            });
          } else {
            setStateData((prev: any) => {
              return {
                ...prev,
                callSid: callSid,
              };
            });
            if (fetchContactTry === 1) {
              const assigneeName = await updateCallerNameByVirtualNumbers(callSid);
              if (assigneeName) {
                getContactInterval && clearInterval(getContactInterval);
              }
            }
            if (fetchContactTry <= fetchContactMaxTries) {

              const getContactInterval = setTimeout(() => {
                getCallersName(getContactInterval, fetchContactTry + 1);
              }, 2000);
            } else {
              // getContactInterval && clearInterval(getContactInterval);
              //get virtual PhoneNumber Assignees user data
              // await updateCallerNameByVirtualNumbers(callSid);
            }
          }
        }
      }
    }
  };

  const getContactDataAndUpdate = (firstContact: any, callSid: string) => {
    const contactType =
      firstContact?.contactType?.contactType?.code == 'CUSTOMER'
        ? 'Patient'
        : firstContact?.contactType?.contactType?.code == 'LEAD'
        ? 'Prospect'
        : '';
    const gender = getGenderTextByGenderCode(firstContact, commonData);
    const genderValue = gender
      ? gender?.toLowerCase().includes('female')
        ? 'Female'
        : gender?.toLowerCase().includes('male')
        ? 'Male'
        : gender
      : '';
    const birthDate = firstContact?.person?.birthDate
      ? moment(firstContact?.person?.birthDate).format(DISPLAY_DATE_FORMAT)
      : '';
    const ageInYears = birthDate
      ? moment().diff(birthDate, 'years', false)
      : '';
    setStateData((prev: any) => {
      return {
        ...prev,
        callerName: firstContact.name,
        contact: firstContact,
        contactType: contactType,
        genderValue: genderValue,
        ageInYears: ageInYears,
        callSid: callSid,
      };
    });
  };

  const getVirtualNumbersList = async (phoneNumer: string) => {
    return await GET_NUMBER_CALL_SMS({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        accountUuid: accountUUID,
        virtualPhoneNumber: phoneNumer,
      },
    }).catch((error) => {});
  }

  const updateCallerNameByVirtualNumbers = async (
    callSid: string
  ) => {
    const responseData = await getVirtualNumbersList(props.callObject?.parameters?.From);
    const numberAssigneeList: IVirtualNumber[] =
      responseData?.data?.virtualPhoneNumberAssignees;
    return getUserNameFromAssigneeList(callSid, numberAssigneeList);
  };

  const getUserNameFromAssigneeList = (
    callSid: string,
    numberAssigneeList: IVirtualNumber[]
  ) => {
    if (numberAssigneeList?.length) {
      const userNumberAssigneeData = numberAssigneeList?.[0];
      setStateData((prev: any) => {
        return {
          ...prev,
          callerName: userNumberAssigneeData?.assignee,
          callSid: callSid,
          conferenceCallerUserId: userNumberAssigneeData?.assigneeId,
        };
      });
      return userNumberAssigneeData?.assignee
    }
    return undefined;
  };

  const getUserDetailByUserId = (userId: string) => {
    const userList = stateData.actualUserList;
    const user = userList?.filter((user: any) => {
      return user.uuid === userId;
    });
    return user[0]?.name;
  };

  const acceptCall = async () => {
    const isMicroPhoneOfF = await checkMicrophonePermission();
    if (isMicroPhoneOfF) {
      return;
    }
    props.onActionPerformed(COMMON_ACTION_CODES.ACCEPT_CALL, props.callObject);
    setConferenceRelatedData();
  };

  const putOutboundCallerOnHold = async() => {
    const patientNumber = props?.callObject?.parameters?.To || props.callObject?.customParameters?.get?.("To");
    const participants = stateData.participants;
    const patientParticipant = participants.filter((participant: any) => {
      return participant.label === patientNumber?.replaceAll('-', '')
    });
    const bodyParams = {
      conferenceSid: stateData.conferenceSid,
      partySid: patientParticipant?.[0]?.callSid,
      hold: !stateData.isOnHold,
    };
    const conferenceResponse: any = await putParticipantOnHold(
      bodyParams
    ).catch((error) => {
    });
    if (conferenceResponse?.data) {
      const participants: IPariticpant[] = stateData.participants;
      participants.forEach((participant: IPariticpant) => {
        if (participant.label === patientNumber) {
          participant.hold = !stateData.isOnHold;
        }
      });
      setStateData((prev: any) => {
        return {
          ...prev,
          participants: participants,
          isOnHold: !stateData.isOnHold,
        };
      });
    }
  }

  const putCallerOnHold = async () => {
    if (stateData.conferenceSid) {
      if (props?.direction === 'OUTGOING') {
        await putOutboundCallerOnHold();
      } else {
        const callerUserId = props.callObject?.parameters?.From.replace(
          'client:',
          ''
        );
        const participantToBeHold = stateData.participants.filter(
          (participant: any) => {
            return participant.label === callerUserId;
          }
        );
        const bodyParams = {
          conferenceSid: stateData.conferenceSid,
          partySid: stateData.initialCallerSid,
          hold: !stateData.isOnHold,
        };
        const conferenceResponse: any = await putParticipantOnHold(
          bodyParams
        ).catch((error) => {
        });

        if (conferenceResponse.data) {
          const participants: IPariticpant[] = stateData.participants;
          participants.forEach((participant: IPariticpant) => {
            if (participant.callSid === participantToBeHold[0]?.callSid) {
              participant.hold = !stateData.isOnHold;
            }
          });
          setStateData((prev: any) => {
            return {
              ...prev,
              participants: participants,
              isOnHold: !stateData.isOnHold,
            };
          });
        }
      }
    } else {
      startConferenceToEnableHold();
    }
  };

  const setConferenceRelatedData = async () => {
    const participants = await getParticipantsByCallSid(
      props.callObject?.parameters?.CallSid
    );
    if (participants && participants.data?.length) {
      let updatedParticipants = participants.data.filter((participant: any) => {
        return participant.label != loggedInUserId;
      });
      const callerUserId = props.callObject?.parameters?.From.replace(
        'client:',
        ''
      );
      updatedParticipants = updatedParticipants.filter((participant: any) => {
        return participant.label != callerUserId;
      });
      updatedParticipants = updatedParticipants.filter((participant: any) => {
        return participant.label != stateData?.conferenceCallerUserId;
      });
      updatedParticipants.forEach((participant: any, index: number) => {
        const userFound = stateData?.userList?.filter((user: any) => {
          return user.uuid === participant.label;
        });
        if (userFound && userFound.length) {
          participant.name = userFound[0].name;
        }
        const isSip = SIP_REGEX.test(participant.label);
        if (isSip) {
          const sip = sipNumberList?.find(sip => sip?.sipUri === participant.label)
          if (sip?.id) {
            updatedParticipants[index] = {...updatedParticipants[index], extensionName: sip?.phoneName}
          }
        }
        if (isUuid(participant.label)) {
          const user = userList?.find(user => user?.uuid === participant.label);
          if (user?.uuid) {
            updatedParticipants[index] = {...updatedParticipants[index], ...user}
          }
        }
      });
      const regex = /^\+?1?\d{10}$/;
      const index = updatedParticipants?.findIndex((participant: any) => regex.test(participant.label));
      if (index !== -1) {
        const patientNumberRes = updatedParticipants[index];
        const response = await getContactDataByPhoneNumber(patientNumberRes?.label);
        if (response?.data?.contacts) {
          const contactData = response?.data?.contacts;
          if (contactData?.length) {
            const firstContact = contactData[0];
            //Update contact details instead of the contact number in the participant list
            updatedParticipants = updatedParticipants?.map((item: any, i: number) => i === index ? firstContact : item);
          }
        }
      }
      setStateData((prev: any) => {
        return {
          ...prev,
          conferenceSid: participants.data[0].conferenceSid,
          participants: updatedParticipants,
        };
      });
    }
  };

  const rejectCall = async () => {
    await hangUpOnEndCall();
    props.callObject?.reject();
    props.callObject?.disconnect();
    props.onActionPerformed(COMMON_ACTION_CODES.CALL_REJECT);
    setStateData({});
  };

  const endCallOnError = async () => {
    props.callObject?.reject();
    props.callObject?.disconnect();
    props.onActionPerformed(COMMON_ACTION_CODES.CALL_REJECT);
    setStateData({});
  };

  const unHoldAddedParticipants = () => {
    const addedParticipant = stateData?.participants;
    addedParticipant?.forEach((participant: any) => {
      if (participant.hold) {
        holdCall(participant.conferenceSid, participant.callSid, false);
      }
    });
  };

  const unHoldAllParticipants = () => {
    if (stateData.conferenceSid && !stateData.isIncomingCallConference) {
      if (stateData.isOnHold) {
        putCallerOnHold();
      }
      unHoldAddedParticipants();
    }
  }

  const hangUpOnEndCall = async () => {
    let newParticipantList;
    unHoldAllParticipants();
    if (
      (stateData.conferenceSid || stateData.isIncomingCallConference) &&
      stateData.callSid
    ) {
      newParticipantList = await hangUp(
        stateData.conferenceSid || stateData.isIncomingCallConference,
        stateData.callSid,
        false
      );
    }
    if (
      newParticipantList &&
      newParticipantList?.data?.length &&
      newParticipantList?.data?.length <= 1
    ) {
      newParticipantList?.data.forEach((participant: any) => {
        const callSid = participant.callSid;
        const conferenceSid = participant.conferenceSid;
        hangUp(conferenceSid, callSid, false);
      });
    } else if (newParticipantList && newParticipantList?.data?.length === 0) {
      newParticipantList = await getParticipantsByConferenceSid(
        stateData.conferenceSid || stateData.isIncomingCallConference
      );
      //if call is transfered but callee has rejected the call then only one participant in conference. Removing him here
      if (
        newParticipantList &&
        newParticipantList?.data?.length &&
        newParticipantList?.data?.length <= 1
      ) {
        newParticipantList?.data?.forEach((participant: any) => {
          const callSid = participant.callSid;
          const conferenceSid = participant.conferenceSid;
          hangUp(conferenceSid, callSid, false);
        });
      }
    }
  };

  const transferCall = async (virtualNumberData: any) => {
    setStateData((prev: any) => {
      return {
        ...prev,
        callForwardedUserName: virtualNumberData?.name ?? virtualNumberData?.extensionName,
      };
    });
    const bodyParams = {
      direction: props.direction,
      sid: stateData.callSid,
      ...(props?.direction === 'OUTGOING' && {callerId: stateData.callerId})
    };
    const conferenceResponse: any = await upgradeCallToConference(
      bodyParams
    ).catch(() => {
    });
    const participants = conferenceResponse?.data;
    participants.forEach((participant: any) => {
      participant.isHide = true;
    });
    const callerParticipant = participants.filter((participant: any) => {
      return participant.callSid !== stateData.callSid;
    });
    const twilioNumber: any = virtualNumberData?.newNumber
      ? await getCallSidDetail(props.callObject?.parameters?.CallSid)
      : {};

    let extensionEntityIdByTransferType;
    if (virtualNumberData?.transferToType) {
      extensionEntityIdByTransferType = getExtensionIdByTransferType(virtualNumberData?.transferToType, virtualNumberData);
    }
    const addedParticipant = await addParticipant(
      conferenceResponse.data[0]?.conferenceSid,
      virtualNumberData?.transferToType ? extensionEntityIdByTransferType : virtualNumberData?.uuid,
      twilioNumber?.data?.forwardedFrom,
      virtualNumberData?.transferToType === CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER
    );
    if (addedParticipant?.data) {
      const newParticipant = {
        ...virtualNumberData,
        ...addedParticipant?.data,
        eventReason: ConferenceEventReasonCode.ringing
      };
      newParticipant.label = virtualNumberData?.transferToType ? extensionEntityIdByTransferType : virtualNumberData?.uuid;
      participants.push(newParticipant);
      const updatedUserList = stateData.userList.filter((user: any) => {
        return user.uuid != virtualNumberData.uuid;
      });
      setStateData((prev: any) => {
        return {
          ...prev,
          conferenceSid: conferenceResponse.data[0].conferenceSid,
          isCallTransferred: true,
          callTransferSuccess: true,
          participants: participants,
          userList: updatedUserList,
          callForwardedUserName: '',
          initialCallerSid: prev?.initialCallerSid?.length
            ? prev?.initialCallerSid
            : callerParticipant?.[0]?.callSid,
        };
      });
    } else {
      setStateData((prev: any) => {
        return {
          ...prev,
          callForwardedUserName: '',
        };
      });
    }
  };

  const startConferenceToEnableHold = async () => {
    let holdBodyParams = {
      conferenceSid: '',
      partySid: '',
      hold: !stateData.isOnHold,
    };
    if (!stateData.conferenceIdForHold) {
      setStateData((prev: any) => {
        return {
          ...prev,
          callHoldLoading: true,
        };
      });
      const bodyParams = {
        direction: props.direction,
        sid: stateData.callSid,
        ...(props?.direction === 'OUTGOING' && {callerId: stateData.callerId})
      };
      const conferenceResponse: any = await upgradeCallToConference(
        bodyParams
      ).catch((error) => {
      });

      const participants = conferenceResponse?.data;
      const callerParticipant = participants.filter((participant: any) => {
        return participant.callSid !== stateData.callSid;
      });
      holdBodyParams = {
        conferenceSid: conferenceResponse?.data[0]?.conferenceSid,
        partySid: callerParticipant[0]?.callSid,
        hold: !stateData.isOnHold,
      };
      await putParticipantOnHold(holdBodyParams).catch((error) => {
      });
      setStateData((prev: any) => {
        return {
          ...prev,
          conferenceIdForHold: conferenceResponse?.data[0]?.conferenceSid,
          isOnHold: !stateData.isOnHold,
          initialCallerSid: prev?.initialCallerSid?.length
            ? prev?.initialCallerSid
            : callerParticipant?.[0]?.callSid,
          callHoldLoading: false,
        };
      });
    } else {
      holdBodyParams = {
        conferenceSid: stateData.conferenceIdForHold,
        partySid: stateData.initialCallerSid,
        hold: !stateData.isOnHold,
      };
      await putParticipantOnHold(holdBodyParams).catch((error) => {
      });
      setStateData((prev: any) => {
        return {
          ...prev,
          isOnHold: !stateData.isOnHold,
        };
      });
    }
  };

  const updateExistingConference = async () => {
    setStateData((prev: any) => {
      return {
        ...prev,
        conferenceLoading: true,
      };
    });
    const updatedUserList = stateData.userList.filter((user: any) => {
      return user.uuid != loggedInUserId;
    });
    const participantList: any = await getParticipantsByCallSid(
      stateData.callSid
    ).catch(() => {
    });
    const participants = participantList?.data;
    participants.forEach((participant: any) => {
      participant.isHide = true;
    });
    setStateData((prev: any) => {
      return {
        ...prev,
        participants: participants,
        conferenceSid: stateData.conferenceIdForHold,
        isConferenceActive: true,
        userList: updatedUserList,
        conferenceLoading: false,
      };
    });
  };

  const startConference = async () => {
    if (stateData.conferenceIdForHold) {
      updateExistingConference();
    } else {
      setStateData((prev: any) => {
        return {
          ...prev,
          conferenceLoading: true,
        };
      });
      const bodyParams = {
        direction: props.direction,
        sid: stateData.callSid,
        ...(props?.direction === 'OUTGOING' && {callerId: stateData.callerId})
      };
      const conferenceResponse: any = await upgradeCallToConference(
        bodyParams
      ).catch((error) => {
        showToast(toast, `Error in starting conference`, ToastType.error);
      });

      const updatedUserList = stateData.userList.filter((user: any) => {
        return user.uuid != loggedInUserId;
      });
      const participants = conferenceResponse?.data;
      participants.forEach((participant: any) => {
        participant.isHide = true;
      });
      const callerParticipant = participants.filter((participant: any) => {
        return participant.callSid !== stateData.callSid;
      });
      setStateData((prev: any) => {
        return {
          ...prev,
          participants: conferenceResponse?.data,
          conferenceSid: conferenceResponse?.data[0]?.conferenceSid,
          isConferenceActive: true,
          isCallTransferred: false,
          userList: updatedUserList,
          conferenceLoading: false,
          initialCallerSid: prev?.initialCallerSid?.length
            ? prev?.initialCallerSid
            : callerParticipant?.[0]?.callSid,
        };
      });
    }
  };
  const addParticipant = async (
    conferenceSid: string,
    userId: string,
    twilioNumber?: string,
    isExtensionNumber?: boolean,
  ) => {
    let callerId;
    if (props?.direction === 'OUTGOING') {
      callerId = props.callObject?.customParameters?.get?.("From");
    } else {
      if (isExtensionNumber) {
        const callerNumber = props.callObject?.parameters?.Params;
        const decodedParams = decodeURIComponent(callerNumber);
        const extractedNumber = getNumber(decodedParams);
        if (extractedNumber) {
          callerId = extractedNumber
        }
      } else {
        callerId = twilioNumber
        ? twilioNumber
        : props.callObject?.parameters?.From || stateData.calleeId;
      }
    }
    const bodyParams = {
      conferenceSid: conferenceSid,
      party: userId,
      CallerID: callerId,
      toNumber: props.callObject?.customParameters?.get('toNumber'),
    };
    const conferenceResponse: any = await addParticipantToConference(
      bodyParams
    ).catch(() => {
    });
    return conferenceResponse;
  };

  const hangUp = async (
    conferenceSid: string,
    partySid: string,
    isParticipantRemove: boolean
  ) => {
    const bodyParams = {
      conferenceSid: conferenceSid,
      partySid: partySid,
    };
    await removeParticipant(bodyParams).catch((error) => {
      if (!isParticipantRemove) {
        endCallOnError(); // handled on condition to minimise the impact. Will fix properly in dev cycle (This will be eventually handled in call events)
      }
    });
    // if (newParticipantList.length == 1) {
    //   const bodyParams = {
    //     conferenceSid : conferenceSid,
    //     partySid : newParticipantList[0]?.callSid,
    //   }
    //   const conferenceResponse: any =  await removeParticipant(bodyParams).catch(() => {
    //   });
    // }
    const newParticipantList = removeParticipantFromUI(partySid);
    return newParticipantList;
  };

  const removeParticipantFromUI = (partySid: string) => {
    const participants = stateData.participants;
    const newParticipantList = participants.filter((participant: any) => {
      return participant.callSid != partySid;
    });
    setStateData((prev: any) => {
      return {
        ...prev,
        participants: newParticipantList,
      };
    });
    return newParticipantList;
  }

  const holdCall = async (
    conferenceSid: string,
    partySid: string,
    hold: boolean
  ) => {
    const bodyParams = {
      conferenceSid: conferenceSid,
      partySid: partySid,
      hold: hold,
    };
    const conferenceResponse: any = await putParticipantOnHold(
      bodyParams
    ).catch((error) => {
    });

    if (conferenceResponse.data) {
      const participants = stateData.participants;
      participants.forEach((participant: any) => {
        if (participant.callSid === partySid) {
          participant.hold = hold;
        }
      });
      setStateData((prev: any) => {
        return {
          ...prev,
          participants: participants,
        };
      });
    }
  };

  const muteCall = () => {
    props.callObject?.mute(!isMuted);
    setIsMuted(!isMuted);
  };
  const handleEmail = () => {
    if (props.onActionPerformed) {
      props.onActionPerformed(COMMON_ACTION_CODES.MAIL, contactStateData);
    }
  };
  const handleSMS = () => {
    if (props.onActionPerformed) {
      props.onActionPerformed(COMMON_ACTION_CODES.SMS, contactStateData);
    }
  };

  const onParticipantAdd = async (selectedUser: any) => {
    let extensionEntityIdByTransferType;
    if (selectedUser?.transferToType) {
      extensionEntityIdByTransferType = getExtensionIdByTransferType(selectedUser?.transferToType, selectedUser);
    }
    const addParticipantResponse = await addParticipant(
      stateData.conferenceSid,
      selectedUser?.transferToType ? extensionEntityIdByTransferType : selectedUser.uuid,
      '',
      selectedUser?.transferToType === CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER
    );
    const participants = stateData.participants;
    const newParticipant = {
      ...selectedUser,
      ...addParticipantResponse?.data,
      eventReason: ConferenceEventReasonCode.ringing,
    };
    newParticipant.label = selectedUser?.transferToType ? extensionEntityIdByTransferType : selectedUser?.uuid;
    participants.push(newParticipant);
    const updatedUserList = stateData.userList.filter((user: any) => {
      return user.uuid != selectedUser.uuid;
    });
    setStateData((prev: any) => {
      return {
        ...prev,
        participants: participants,
        userList: updatedUserList,
        callConferenceSuccess: true,
      };
    });
  };

  const handleCall = () => {
    if (props.onActionPerformed) {
      props.onActionPerformed(COMMON_ACTION_CODES.CALL, contactStateData);
    }
  };

  const handleCallRejectOnNetworkError = () => {
    rejectCall();
  };

  const beforeunloadEvent = (ev: BeforeUnloadEvent) => {
    ev.preventDefault();
    ev.returnValue = '';
  };

  const fetchCallExtensionList = async (searchString?: string) => {
    setStateData((prev: any)=> {
      return {
        ...prev,
      }
    })
    const params = {
      pageNo: stateData?.pageNo,
      pageSize: stateData?.limit,
      searchString: searchString,
    }
    try {
      const response = await getCallExtensionList(params);
      if (response?.data?.data?.length) {
        const callExtensions = response?.data?.data;
        const filterActiveExtension = callExtensions?.filter((extension: any) => {
          return extension?.isActive
        })
        setStateData((prev: any)=> {
          return {
            ...prev,
            extensionList: filterActiveExtension,
            loading: false,
            total: response?.data?.count,
          }
        })
      } else {
        setStateData((prev: any)=> {
          return {
            ...prev,
            loading: false,
            extensionList: []
          }
        })
      }
    } catch (error: any) {
      setStateData((prev: any)=> {
        return {
          ...prev,
          loading: false
        }
      })
    }
  }

  useEffect(() => {
    if (!stateData.contact.id) {
      getCallersName();
    }
    fetchAndUpdateConferenceUserList();
    fetchAndUpdateIncomingCallParticipants();
    fetchCallExtensionList();
    window.addEventListener('beforeunload', beforeunloadEvent);

    return () => {
      window.removeEventListener('beforeunload', beforeunloadEvent);
    };
  }, []);
  useEffect(() => {
    if (isOnline) {
      // if device is online then only we will create device
      props?.phoneSetup();
    } else {
      // if device is offline then we will reject/disconnect call in 3 seconds with antd notification
      notification.warning({
        message: 'Network Error',
        description: 'Call will be disconnected in 3 seconds',
        duration: 3,
      });
      setTimeout(() => {
        props?.callObject?.reject();
        props?.callObject?.disconnect();
        handleCallRejectOnNetworkError();
      }, 3000);
    }
  }, [isOnline]);

  useEffect(() => {
    if (props.isDisconnected) {
      unHoldAllParticipants();
    }
  }, [props.isDisconnected]);

  useEffect(() => {
    if (props.isConnected) {
      setConferenceRelatedData();
    }
  }, [props?.isConnected]);

  const onConferenceUserEventListener = useCallback(
    (data: IConferenceEventData) => {
      const conferenceEvent = data.data;
      if (!conferenceEvent) {
        return;
      }
      if (conferenceEvent.eventCode === CallEventCode.callAnswered) {
        setStateData((prev: any) => {
          return {
            ...prev,
            isOutgoingCallAnswered: true,
          };
        });
        return;
      }
      if (conferenceEvent.conferenceSid !== stateData.conferenceSid) {
        return;
      }
      let updatedParticipantList = stateData.participants;
      if (conferenceEvent.eventCode === ConferenceEventCode.join) {
        updatedParticipantList =
          getParticipantListAfterUserJoined(conferenceEvent);
      } else if (conferenceEvent.eventCode === ConferenceEventCode.leave) {
        updatedParticipantList =
          getParticipantListAfterUserLeft(conferenceEvent);
      }
      setStateData((prev: any) => {
        return {
          ...prev,
          participants: updatedParticipantList,
        };
      });
    },
    [
      stateData.participants,
      stateData.actualUserList,
      stateData.callTransferSuccess,
    ]
  );

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    if (
      stateData.isConferenceActive ||
      stateData.callTransferSuccess ||
      stateData.isIncomingCallConference ||
      props.direction === CLOUD_TELEPHONY_CODE.OUTGOING || 
      stateData?.participants?.length >= 2
    ) {
      eventBus.addEventListener(
        CALL_SUPPORTED_EVENTS.CONFERENCE_CALL_EVENTS,
        onConferenceUserEventListener
      );
    } else {
      eventBus.removeEventListener(onConferenceUserEventListener);
    }

    return () => {
      eventBus.removeEventListener(onConferenceUserEventListener);
    };
  }, [
    stateData.isConferenceActive,
    stateData.callTransferSuccess,
    stateData.isIncomingCallConference,
    onConferenceUserEventListener,
    stateData?.participants?.length
  ]);

  useEffect(()=>{
    if (stateData?.participants?.length <= 2) {
      setStateData((prev: any) => {
        return {
          ...prev,
          isConferenceActive: false,
          callConferenceSuccess: false,
          isCallTransferred: false,
          callTransferSuccess: false,
        };
      });
    }
  }, [stateData?.participants?.length])

  const getParticipantListAfterUserJoined = (
    conferenceEvent: IConferenceEvent,
  ) => {
    return stateData.participants.map((participant: any) => {
      if (participant?.label === conferenceEvent.eventUserId) {
        participant.eventReason = ConferenceEventReasonCode.accepted;
      }
      return participant;
    }) ?? [];
  };

  const disableConferenceButtons = () => {
    let isDisable = false;
    if (props.direction === CLOUD_TELEPHONY_CODE.OUTGOING && !stateData.isOutgoingCallAnswered) {
      isDisable = true
    }
    return isDisable
  }

  const getParticipantListAfterUserLeft = (
    conferenceEvent: IConferenceEvent
  ) => {
    if (conferenceEvent.eventReason === ConferenceEventReasonCode.completed) {
      const participantLeft = stateData.participants.find(
        (participant: any) => {
          return participant?.label === conferenceEvent.eventUserId;
        }
      );
      if (participantLeft) {
        addParticipantInUserList(participantLeft);
        return stateData.participants.filter((participant: any) => {
          return participant?.label !== conferenceEvent.eventUserId;
        }) ?? [];
      } else {
        return stateData.participants ?? [];
      }
    } else {
      return stateData.participants.map((participant: any) => {
        if (participant?.label === conferenceEvent.eventUserId) {
          participant.eventReason = conferenceEvent.eventReason;
        }
        return participant;
      }) ?? [];
    }
  };

  const addParticipantInUserList = (participant: any) => {
    if (participant?.uuid) {
      const removedUser = stateData?.actualUserList?.find((user: any) => {
        if (user?.uuid === participant?.uuid) {
          return user;
        }
      });
      if (removedUser) {
        const newUserList = [removedUser, ...stateData.userList];
        setStateData((prev: any) => {
          return {
            ...prev,
            userList: newUserList,
          };
        });
      }
    }
  };
  const checkMicrophonePermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({audio: true});
      setStateData((prev: any) => {
        return {
          ...prev,
          microPhonePop: false,
        };
      });
      return false;
    } catch (error) {
      setStateData((prev: any) => {
        return {
          ...prev,
          microPhonePop: true,
        };
      });
      return true;
    }
  };

  const isCallUserToUser = () => {
    if (props.direction === CLOUD_TELEPHONY_CODE.OUTGOING) {
      if (
        (props.callObject?.parameters?.To ||
          props.callObject?.customParameters?.get?.('To')) &&
        contactStateData?.name
      ) {
        return true;
      }
      return false;
    } else if (stateData.conferenceCallerUserId?.length > 0) {
      return true;
    }
    return false;
  };

  const getCallerNameWithoutContact = () => {
    let callerName;
    if (props.direction === CLOUD_TELEPHONY_CODE.INCOMING) {
      const from = props.callObject?.parameters?.From;
      callerName =
        from && stateData.callerName
          ? stateData.callerName
          : from?.includes('client')
          ? ''
          : from;
    } else {
      const to = props.callObject?.customParameters?.get?.('To');
      if (to) {
        if (contactStateData?.name) {
          callerName = contactStateData?.name;
        } else {
          callerName = to;
        }
      }
    }

    return callerName;
  }

  const handleDeletePress = () => {
    if (dialPadState.dialPadNumberValue.length > 0) {
      setDialPadState((prev) => {
        return {
          ...prev,
          dialPadNumberValue: prev.dialPadNumberValue.slice(0, -1),
        };
      });
    }
  };
  return (
    <View
      style={{
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <View
        style={{
          width: '100%',
          borderRadius: 16,
          backgroundColor: '#fff',
          padding: 8,
          marginTop: 12,
        }}
      >
        {stateData.microPhonePop && (
          <ShowMicroPhoneOffPopUp
            onActionPerformed={() => {
              setStateData((prev: any) => {
                return {
                  ...prev,
                  microPhonePop: false,
                };
              });
            }}
          />
        )}
        <View flex={1} alignItems={'center'}>
          {contactStateData.id || props.contactData?.id ? (
            <ProfileDetailView
              isBigBannerNameTruncate={'66%'}
              hideVideoCallingButton={true}
              bannerDirection="HORIZONTAL"
              conversationData={{}}
              contactData={contactStateData}
              contactType={contactStateData?.contactType?.contactType}
              handleEmail={handleEmail}
              handleSMS={handleSMS}
              handleCall={handleCall}
              onLockScreen={props?.onLockScreen}
              onCallScreen={true}
              onActionPerformed={(type: any, data: any) => {
                if (type == PERSON_ACTION_CODES.CREATE_NOTES) {
                  props?.onActionPerformed?.(
                    PERSON_ACTION_CODES.CREATE_NOTES,
                    data
                  );
                }

                if (type == PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT) {
                  props?.onActionPerformed?.(
                    PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT,
                    data
                  );
                }
                props?.onActionPerformed?.(type, data);
              }}
            />
          ) : (
            <HStack alignItems={'center'} flex={1} flexDirection="column">
              {(contactStateData.id || props.contactData?.id) &&
              loadingContactName ? (
                <AvatarWithSpinner />
              ) : (
                <VStack flex={0.75} alignItems="center">
                  <DisplayCardAvatar
                    avatarStyle={{
                      avatarSize: '12',
                    }}
                    isLetterAvatarShow={true}
                    userData={{
                      userId: stateData?.contact?.id || '',
                      userType: isCallUserToUser() ? GROUP_MEMBER_TYPE.USER : GROUP_MEMBER_TYPE.CONTACT,
                      contactType: PERSON_TYPES.LEAD,
                      userName:
                        props.callObject?.parameters?.From &&
                        stateData.callerName
                          ? stateData.callerName
                          : getCallerNameWithoutContact(),
                    }}
                  />
                </VStack>
              )}
              <VStack
                flex={0.25}
                alignItems="center"
                style={{marginHorizontal: 16, marginTop: 16}}
              >
                <Text
                  size={'lgNormal'}
                  fontWeight={'600'}
                  color={Colors.Custom.Gray900}
                >
                  {getCallerNameWithoutContact()}
                </Text>
              </VStack>
            </HStack>
          )}
          {contactStateData.id || props.contactData?.id ? (
            <View
              style={{
                width: '100%',
                borderTopColor: Colors.Custom.Gray200,
                borderTopWidth: 1,
                marginVertical: 12,
              }}
            ></View>
          ) : (
            <View
              style={{
                width: '100%',
                marginBottom: 20,
                marginTop: 10,
                alignItems: 'center',
              }}
            >
              {/* <Text>{intl.formatMessage({id: 'fetchingDetails'})}</Text> */}
            </View>
          )}
          {!props.onCall && (
            <HStack>
              {/* Before call recieve */}
              {!props.onCall && (
                <VStack style={{marginHorizontal: 12}}>
                  <Pressable
                    onPress={() => {
                      rejectCall();
                    }}
                    alignItems={'center'}
                  >
                    <CallEnd />
                    <Text style={[styles.buttonTitle]}>Decline</Text>
                  </Pressable>
                </VStack>
              )}
              {!props.onCall &&
              props.direction !== CLOUD_TELEPHONY_CODE.OUTGOING ? (
                <VStack style={{marginHorizontal: 12}}>
                  <Pressable
                    onPress={() => {
                      acceptCall();
                    }}
                    alignItems={'center'}
                  >
                    <CallAccept />
                    <Text style={[styles.buttonTitle]}>
                      {`${props.isAnotherCallInProgress ? 'End & Accept' : 'Accept'}`}
                    </Text>
                  </Pressable>
                </VStack>
              ) : (
                <></>
              )}
            </HStack>
          )}

          {props.onCall && (
            <HStack
              alignItems={'center'}
              justifyContent={'center'}
              space={4}
            >
              <Pressable
                onPress={() => {
                  muteCall();
                }}
                alignItems={'center'}
                {...testID(TestIdentifiers.muteBtn)}
              >
                {isMuted ? <CallMuteActive/> : <CallMute/>}
                <Text size={'xsNormal'} style={[styles.buttonTitle]}>
                  {isMuted ? "Unmute" : "Mute"}
                </Text>
              </Pressable>

              <Pressable
                onPress={() => {
                  putCallerOnHold();
                }}
                alignItems={'center'}
                {...testID(TestIdentifiers.holdBtn)}
                disabled={
                  stateData.isIncomingCallConference
                  || disableConferenceButtons()
                }
              >
                {
                  <CallHold
                    showSpinnerWhenLoading={stateData?.callHoldLoading}
                    isActive={stateData.isOnHold}
                    isEnabled={
                      !(

                        stateData.isIncomingCallConference
                        || disableConferenceButtons()
                      )
                    }
                  />
                }
                <Text size={'xsNormal'} style={[styles.buttonTitle]}>
                  {stateData.isOnHold ? "Unhold" : "Hold"}
                </Text>

              </Pressable>

              {PhoneCallingConst.isTransferEnable && (
                <Pressable
                  onPress={() => {
                    setStateData((prev: any) => {
                      return {
                        ...prev,
                        isCallTransferred: true,
                        isConferenceActive: false,
                      };
                    });
                  }}
                  alignItems={'center'}
                  {...testID(TestIdentifiers.transferBtn)}
                  disabled={
                    stateData.callConferenceSuccess ||
                    stateData.isCallTransferred ||
                    stateData.isIncomingCallConference
                    || disableConferenceButtons()
                  }
                >
                  <CallTransfer
                    isActive={stateData.isCallTransferred}
                    isEnabled={
                      !(
                        stateData.callConferenceSuccess ||
                        stateData.isIncomingCallConference
                        || disableConferenceButtons()
                      )
                    }
                  />
                  <Text size={'xsNormal'} style={[styles.buttonTitle]}>Transfer</Text>
                </Pressable>
              )}

              {PhoneCallingConst.isConferenceEnable && (
                <Pressable
                  onPress={() => {
                    startConference();
                  }}
                  alignItems={'center'}
                  {...testID(TestIdentifiers.addCallBtn)}
                  disabled={
                    stateData.isConferenceActive ||
                    stateData.callTransferSuccess ||
                    stateData.isIncomingCallConference
                    || disableConferenceButtons()
                  }
                >
                  <ConferenceAdd
                    isActive={stateData.isConferenceActive}
                    isEnabled={
                      !(
                        stateData.callTransferSuccess ||
                        stateData.isIncomingCallConference
                        || disableConferenceButtons()
                      )
                    }
                  />
                  <Text size={'xsNormal'} style={[styles.buttonTitle]}>Add Call</Text>
                </Pressable>
              )}

              {props.direction === CLOUD_TELEPHONY_CODE.OUTGOING && (
                <Pressable
                  onPress={() => {
                    setDialPadState((prev) => {
                      return {
                        ...prev,
                        show: !prev.show,
                        dialPadNumberValue: '',
                      };
                    });
                  }}
                  alignItems={'center'}
                  {...testID(TestIdentifiers.keyPad)}
                >
                  <DialPadButtonSvg isActive={dialPadState.show} />
                  <Text size={'xsNormal'} style={[styles.buttonTitle]}>Keypad</Text>
                </Pressable>
              )}

              <Pressable
                style={{width: 51, height: 65}}
                onPress={() => {
                  rejectCall();
                }}
                alignItems={'center'}
                {...testID(TestIdentifiers.endCallBtn)}
              >
                <CallEnd />
                <Text size={'xsNormal'} style={[styles.buttonTitle]}>End Call</Text>
              </Pressable>
            </HStack>
          )}
        </View>
        {stateData.isConferenceActive && (
          <HStack alignItems={'center'} width={'full'} pt={3}>
            <UserSearch
              isShowError={true}
              userList={stateData.userList}
              extensionList={stateData?.extensionList}
              onFocus={() => {
                setSelectInputFocusState(true);
              }}
              onBlur={() => {
                setSelectInputFocusState(false);
              }}
              onChange={(virtualNumberData) => {
                const newValue = {
                  phoneNumber: undefined,
                  virtualPhoneNumber: virtualNumberData?.name || '',
                  userUuid: virtualNumberData?.uuid,
                };
                if (stateData.isCallTransferred) {
                  transferCall(virtualNumberData);
                } else {
                  onParticipantAdd(virtualNumberData);
                }
              }}
          />
          </HStack>
        )}
        {stateData.isCallTransferred && !isUserSelectDisabled() && (
          <HStack alignItems={'center'} width={'full'} pt={3}>
            <UserSearchSelect
              isShowError={true}
              userList={stateData.userList}
              extensionList={stateData?.extensionList}
              onFocus={() => {
                setSelectInputFocusState(true);
              }}
              onBlur={() => {
                setSelectInputFocusState(false);
              }}
              onChange={(virtualNumberData) => {
                const newValue = {
                  phoneNumber: undefined,
                  virtualPhoneNumber: virtualNumberData?.name || '',
                  userUuid: virtualNumberData?.uuid,
                };
                if (stateData.isCallTransferred) {
                  transferCall(virtualNumberData);
                } else {
                  onParticipantAdd(virtualNumberData);
                }
              }}
            />
          </HStack>
        )}
        {selectInputFocusState && !dialPadState.show && (
          <View height={'280px'} width={'100%'} display={'block'}></View>
        )}
        {stateData?.participants?.length ? (
          <View pt={2}>
            <AddedMembers
              title="Conference Call"
              contact={stateData.contact}
              showCallActions={!stateData.isIncomingCallConference}
              participants={stateData.participants?.filter(
                (participant: any) => {
                  return !participant?.isHide;
                }
              )}
              holdParticipant={(participant) => {
                holdCall(
                  participant.conferenceSid,
                  participant.callSid,
                  !participant.hold
                );
              }}
              removeParticipant={(participant) => {
                addParticipantInUserList(participant);
                if (participant.eventReason === ConferenceEventReasonCode.accepted) {
                  hangUp(participant.conferenceSid, participant.callSid, true);
                } else {
                  removeParticipantFromUI(participant.callSid);
                }
              }}
              locationId={
                props?.contactData?.contactPracticeLocations?.[0]
                  ?.accountLocation?.uuid
              }
            />
          </View>
        ) : null}
        {stateData.callForwardedUserName?.length > 0 && (
          <HStack
            alignItems="center"
            justifyContent={'center'}
            background={'gray.100'}
            padding={2}
          >
            <VStack alignItems="center" justifyContent={'center'}>
              <HStack alignItems="center" justifyContent={'center'} flex={1}>
                <VStack flex={1}>
                  <CallForwardLoading />
                </VStack>
                <VStack
                  flex={2}
                  alignItems="center"
                  justifyContent={'center'}
                  marginRight={1}
                >
                  <div className="col-3">
                    <div className="snippet" data-title="dot-flashing">
                      <div className="stage">
                        <div className="dot-flashing"></div>
                      </div>
                    </div>
                  </div>
                </VStack>
                <VStack flex={1}>
                  <CallForwardLoading />
                </VStack>
              </HStack>
              <Text textAlign={'center'} color={Colors.Custom.Gray500}>
                {`Call is transferring to \n${
                  stateData.callForwardedUserName ?? ''
                }`}
              </Text>
            </VStack>
          </HStack>
        )}
        {stateData.conferenceLoading && (
          <HStack
            alignItems="center"
            justifyContent={'center'}
            background={'gray.100'}
            padding={2}
          >
            <VStack alignItems="center" justifyContent={'center'}>
              <HStack alignItems="center" justifyContent={'center'} flex={1}>
                <VStack flex={1}>
                  <CallForwardLoading />
                </VStack>
                <VStack
                  flex={2}
                  alignItems="center"
                  justifyContent={'center'}
                  marginRight={1}
                >
                  <div className="col-3">
                    <div className="snippet" data-title="dot-flashing">
                      <div className="stage">
                        <div className="dot-flashing"></div>
                      </div>
                    </div>
                  </div>
                </VStack>
                <VStack flex={1}>
                  <CallForwardLoading />
                </VStack>
              </HStack>
              <HStack alignItems="center" justifyContent={'center'}>
                <Text> Starting conference </Text>
              </HStack>
            </VStack>
          </HStack>
        )}
        {dialPadState.show && (
          <View>
            <Text
              style={[styles.dialPadNumberTitle, {marginTop: "16px"}]}
              numberOfLines={1}
              size={'xslNormal'}
            >
              {dialPadState.dialPadNumberValue}
            </Text>
            <Dialpad
              showPhoneTreePad={true}
              onPress={(number?: string) => {
                if (number) {
                  props.callObject?.sendDigits(number);
                  setDialPadState((prev) => {
                    return {
                      ...prev,
                      dialPadNumberValue: prev.dialPadNumberValue + number,
                    };
                  });
                }
              }}
            />
          </View>
        )}
        {props.onCall && (
          <HStack alignItems={'center'} justifyContent={'center'} space={1}>
            {dialPadState.show && (
              <TouchableOpacity
                style={{
                  width: 56,
                  height: 56,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={handleDeletePress}
              >
                <DialpadDeleteSvg customBgColor={Colors.Custom.Gray100} />
              </TouchableOpacity>
            )}
          </HStack>
        )}
      </View>
    </View>
  );
};

export default PhoneCalling;
