import {AxiosInstance, AxiosResponse} from 'axios';
import {getFormURL} from '../../../utils/commonUtils';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import {
  getContactCustomAttributeMergeTagValues,
  getTemplateCategories,
  getTemplateCategoryList,
} from '../../RightSideContainer/ContentManagement/ContentManagementUtils';
import {ITemplateCategory} from '../../RightSideContainer/ContentManagement/EmailTemplates/interfaces';
import {SingleSMSTemplate} from '../../RightSideContainer/ContentManagement/SMSTemplates/interfaces';
import {
  getFormattedSMSTemplates,
  getSMSTemplates,
} from '../../RightSideContainer/ContentManagement/SMSTemplates/SMSTemplatesUtils';
import {
  getFormattedCarePlans,
} from '../EmailPopupView/EmailPopupViewUtils';
import {
  ContactSMSData,
  SendSMSPostObject,
  SMSDrawerControllerArgs,
  SMSDrawerState,
} from './interfaces';

export const getContactSMSData = (data: any[]): ContactSMSData[] => {
  const result: ContactSMSData[] = [];
  data.forEach((item) => {
    const contactData = item?.contactData || item;
    const contactName =
      item?.nameData?.name ||
      `${contactData?.person?.firstName || ''} ${
        contactData?.person?.lastName
      } || ''`;
    const phoneNumber = item?.mobileNumber || contactData?.phoneNumber;
    if (phoneNumber) {
      result.push({
        name: contactName,
        phoneNumber: phoneNumber,
        id: (item.id || '').toString(),
        contactUuid: contactData?.uuid || '',
        contactConsents: contactData?.contactConsents,
      });
    }
  });
  return result;
};

export class SMSDrawerController {
  state: SMSDrawerState;
  isSendButtonDisabled: boolean;
  baseService: AxiosInstance;
  data: ContactSMSData[];
  toast: any;
  showFormDropdown: boolean;
  showCarePlansDropdown: boolean;
  accountUuid: string;
  showError: boolean;
  showSMSBody: boolean;
  onCompleteAction: (isClose?: boolean) => void;
  setState: React.Dispatch<React.SetStateAction<SMSDrawerState>>;

  errorConsts = {
    categoryApiError: 'Something went wrong while fetching categories',
    templateApiError: 'Something went wrong while fetching templates',
    noTemplateError: 'Please add a template for the selected category',
    emptyDataError: 'Please select contacts with phone numbers',
  };

  constructor(args: SMSDrawerControllerArgs) {
    this.state = args.state;
    this.data = args.data;
    this.baseService = args.baseService;
    this.isSendButtonDisabled =
      this.state?.smsBody?.length === 0 && this.data.length === 0;
    this.toast = args.toast;
    this.showFormDropdown =
      this.state.selectedCategory.code == 'PAT_FORM' &&
      this.state.templateList.length !== 0;
    this.showCarePlansDropdown =
      this.state.selectedCategory.code == 'SINGLE_CAREPLAN_SUGGESTION' &&
      this.state.templateList.length !== 0 &&
      this.state.carePlans.length !== 0;
    this.accountUuid = args.accountUuid;
    this.showError = this.state.error.length > 0;
    this.showSMSBody =
      Object.keys(this.state.selectedTemplate || {}).length > 0;
    this.setState = args.setState;
    this.onCompleteAction = args.onCompleteAction;

    // binding
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.getCategoryData = this.getCategoryData.bind(this);
    this.getSMSTemplatesData = this.getSMSTemplatesData.bind(this);
    this.setSMSTemplate = this.setSMSTemplate.bind(this);
    this.setCategory = this.setCategory.bind(this);
    this.setTemplate = this.setTemplate.bind(this);
    this.setTemplateBody = this.setTemplateBody.bind(this);
    this.setCarePlan = this.setCarePlan.bind(this);
    this.setFormData = this.setFormData.bind(this);
    this.successHandler = this.successHandler.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.responseHandler = this.responseHandler.bind(this);
    this.getSMSPostObjects = this.getSMSPostObjects.bind(this);
    this.getTemplateData = this.getTemplateData.bind(this);
    this.setSubmitting = this.setSubmitting.bind(this);
    this.onMount = this.onMount.bind(this);
  }

  onMount() {
    this.getCategoryData();
  }

  async handleOnSubmit() {
    this.setSubmitting(true);
    const postObjectArray = await this.getSMSPostObjects();
    const contactWithPhoneNumber = postObjectArray.filter((item) => {
      return item.phoneNumber;
    });
    if (contactWithPhoneNumber.length) {
      const promiseList = contactWithPhoneNumber.map((item) => {
        return this.baseService.post('crm-nest/sms', item);
      });
      try {
        const response = await Promise.all(promiseList);
        this.responseHandler(response);
      } catch (error) {
        this.errorHandler(error);
      }
    } else {
      this.errorHandler(
        '',
        'Selected member does not have any phone number for sending sms'
      );
    }
  }

  handleOnClose() {
    this.onCompleteAction(true);
  }

  async getCategoryData() {
    this.setState((prev) => {
      return {
        ...prev,
        categoryLoading: true,
      };
    });
    try {
      const categoryResponse = await getTemplateCategories();
      const categoryList = getTemplateCategoryList(categoryResponse);
      this.setState((prev) => {
        return {
          ...prev,
          categoryList: categoryList,
          categoryLoading: false,
        };
      });
    } catch (error) {

      this.setState((prev) => {
        return {
          ...prev,
          categoryLoading: false,
          error: this.errorConsts.categoryApiError,
        };
      });
    }
  }

  async getSMSTemplatesData() {
    this.setState((prev) => {
      return {
        ...prev,
        templatesLoading: true,
      };
    });
    try {
      const categoryQueryString = `&category=${this.state.selectedCategory.code}`;
      const response = await getSMSTemplates(categoryQueryString, {});
      const list = getFormattedSMSTemplates(response);
      this.setState((prev) => {
        return {
          ...prev,
          templateList: list,
          templatesLoading: false,
          selectedTemplate:
            list.length === 1 ? list[0] : ({} as SingleSMSTemplate),
          smsBody: list.length === 1 ? list[0].body || '' : '',
          error:
            list.length === 0 &&
            Object.keys(this.state.selectedCategory || {}).length > 0
              ? this.errorConsts.noTemplateError
              : '',
        };
      });
    } catch (error) {
      this.setState((prev) => {
        return {
          ...prev,
          templatesLoading: false,
          error: this.errorConsts.templateApiError,
        };
      });
    }
  }

  setSMSTemplate(bodyString: string) {
    this.setState((prev) => {
      return {
        ...prev,
        selectedTemplate: {
          ...prev.selectedTemplate,
          body: bodyString,
        },
        smsBody: bodyString,
      };
    });
  }

  setCategory(value: string) {
    const selectedCategory = this.state.categoryList.find(
      (item) => item.code == value
    );
    this.setState((prev) => {
      return {
        ...prev,
        selectedCategory: selectedCategory as ITemplateCategory,
        smsBody: '',
        selectedTemplate: {} as SingleSMSTemplate,
      };
    });
  }

  setTemplate(value: string) {
    const selectedTemplate = this.state.templateList.find(
      (item) => item.id == value
    );
    this.setState((prev) => {
      return {
        ...prev,
        selectedTemplate: selectedTemplate as SingleSMSTemplate,
        smsBody: selectedTemplate?.body || '',
      };
    });
  }

  setTemplateBody(bodyString: string) {
    this.setState((prev) => {
      return {
        ...prev,
        selectedTemplate: {
          ...prev.selectedTemplate,
          body: bodyString,
        },
        smsBody: bodyString,
      };
    });
  }

  setCarePlan(id: string) {
    const carePlanSelected = this.state.carePlans.find(
      (item) => item.id === id
    );
    this.setState((prev) => {
      return {
        ...prev,
        smsData: {
          ...prev.smsData,
          careplan: {
            name1: carePlanSelected?.title,
            description1: carePlanSelected?.description,
            price1: carePlanSelected?.price,
          },
        },
      };
    });
  }

  setFormData(form: any) {
    this.setState((prev) => {
      return {
        ...prev,
        smsData: {
          ...prev.smsData,
          form: form,
        },
      };
    });
  }

  private setSubmitting(value: boolean) {
    this.setState((prev) => {
      return {
        ...prev,
        submitting: value,
      };
    });
  }

  private async getSMSPostObjects(): Promise<SendSMSPostObject[]> {
    const result: SendSMSPostObject[] = [];
    for (const item of this.data) {
      const templateData = await this.getTemplateData(item);
      result.push({
        contactId: item?.contactUuid,
        data: templateData,
        templateId: this.state.selectedTemplate.id,
        phoneNumber: item?.phoneNumber,
        smsBody: this.state.smsBody || '',
      });
    }
    return result;
  }

  private async getTemplateData(contactData: ContactSMSData) {
    const customAttributeValues = await getContactCustomAttributeMergeTagValues(
      contactData.contactUuid
    );
    const {smsData} = this.state;
    let data: any = {
      patient: {firstName: contactData.name},
    };
    if (smsData?.careplan) {
      data = {
        ...data,
        careplan: smsData.careplan,
      };
    }
    if (smsData.form) {
      data = {
        ...data,
        form: {
          link: getFormURL(
            smsData.form.id,
            contactData.contactUuid,
            this.accountUuid
          ),
        },
      };
    }

    data.customAttribute = customAttributeValues;

    return data;
  }

  private successHandler(response: any, message?: string) {
    this.setSubmitting(false);
    showToast(this.toast, message || 'Messages sent', ToastType.success);
    this.onCompleteAction();
  }

  private errorHandler(error: any, message?: string) {
    this.setSubmitting(false);
    showToast(
      this.toast,
      message ||
        error?.response?.data?.message ||
        'Something went wrong while sending SMS',
      ToastType.error
    );

    this.onCompleteAction();
  }

  private responseHandler(response: AxiosResponse<any, any>[]) {
    const data = this.data;
    response.forEach((item, index) => {
      const isErrorResponse =
        item.data.length > 0 && item.data[0].status == 'rejected';
      const isNumberNotFound =
        isErrorResponse && item.data[0].reason.status == 400;
      const contact = data[index];
      if (isErrorResponse) {
        if (isNumberNotFound) {
          this.errorHandler(
            item.data,
            `SMS could not be sent to ${contact.name}. Please check if you have the correct phone number`
          );
        } else {
          this.errorHandler(item.data);
        }
      } else {
        this.successHandler(
          item.data,
          `SMS sent successfully to ${contact.name}`
        );
      }
    });
  }
}

export const getContactDataHaveConsent = (isConsentRequired: boolean, data: ContactSMSData[]) => {
  if (!isConsentRequired || !data?.length) {
    return data;
  }
  const filteredContact = data?.filter((item: ContactSMSData) => {
    return item?.phoneNumber && item?.contactConsents?.length;
  });
  return filteredContact;
}

export const getWithoutConsentPatientsFromSmsData = (
  bulkContactData: ContactSMSData[]
) => {
  let allPatients = '';
   if (bulkContactData?.length) {
    allPatients = bulkContactData
       ?.filter(
         (contact) => !contact?.contactConsents?.length
       )
       ?.map((contact) => `${contact.name} (${contact.phoneNumber})`)
       ?.join(', ');
   }
  return allPatients;
};
