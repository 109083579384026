import LocalStorage from "../../../../utils/LocalStorage";
import CommonService from "../../../../services/CommonService/CommonService";
import { SingleHealthMapTemplate } from "./interfaces";


const commonService = CommonService.getCommonServiceInstance();
const llmService = commonService.llmService;

export const getHeaders = async () => {
  const userDataString = await LocalStorage.getItem('user');
  const userData = JSON.parse(userDataString);
  const accountUUID =
    userData?.data.accounts.length > 0
      ? userData?.data.accounts[0].uuid || ''
      : '';
  const userId = userData?.data.id;
  const responseAccessToken = userData?.accessToken || '';
  const fold_access_token = userData?.fold_access_token;
  const headersObj: any = {
    'access-token': responseAccessToken,
    fold_access_token,
    'x-hasura-account-uuid': accountUUID,
    'x-hasura-user-id': userId,
    'content-type': 'application/json'
  };
  return headersObj;
};

export const deleteHealthMapTemplate = async (templateId: string) => {
  try {
    const responseData = await llmService.delete(
      `/healthmap/template/${templateId}`
    )
    return responseData;
  } catch (err: any) {
    return
  }
};

export const updateHealthMapTemplateStatus = (templateId: string, isActive: boolean, templateData: any) => {

    llmService.put(
      `/healthmap/template/${templateId}`,
      {
        ...templateData,
        isActive
      }
    ).then((responseData: any) => {
    }) .catch(() => {

    })

};

export const createNewHealthMapTemplate = (templateBody: SingleHealthMapTemplate) => {

    return llmService.post(
      `/healthmap/template`,
      {
        ...templateBody,
        templateCategory: 'healthMap'
      }
    )
    .then((responseData: any) => {
      return responseData;
    })
    .catch((error: any) => {
      throw error?.response?.data;
    })
}

export const updateHealthMapTemplate = (templateBody: SingleHealthMapTemplate, templateId: string) => {
    return llmService.put(
      `/healthmap/template/${templateId}`,
      {
        ...templateBody
      }
    ).then((responseData: any) => {
    }) .catch(() => {

    })
}
