import {IMessageCreated, IMessageCreatedFormattedData} from "../../../../../../../Interfaces/WebSocketEventInterfaces";
import {CONVERSATION_STATUS, CONVERSATION_STATUS_STRING, SENDER_TYPE} from "../../../../../../../constants";
import {convertUnixDate} from "../../../../../../../utils/DateUtils";
import { isEFaxChannelInbox, isEmailChannelInboxType } from "../../../../../../../utils/commonUtils";
import {isWeb} from "../../../../../../../utils/platformCheckUtils";
import {CHANNEL_TYPE_CODE, CONVERSATION_SUBTAB_CODES} from "../../../ConversationConst";
import {CALL_ONLY_MESSAGE_TYPE_NUM, CONVERSATION_DISPLAY_MESSAGE_TYPE, CONVERSATION_UNREAD_COUNT_TYPE, IO_MESSAGE_AND_MISS_CALL_TYPE_NUM, MISS_CALL_MESSAGE_TYPE_NUM} from "../../../MessagingWindow/MessagingCustomCard/CustomCardConst";
import { getContactDataAndType } from "../../../MessagingWindow/MessagingUtils";
import {IContact, IConversationData, IReceivedConversationData, MESSAGE_TYPE} from "../../../interfaces";
import { getFormattedMsgDataForNewChange, getUniqueConversationListByUuid} from "../ConversationListUtils";
import { IConversationListState } from "../interface";
import {IConversationAssigneeLocalEventData} from "./interface";



export const MESSAGE_RECEIVED_DATA_ACTION = {
  MESSAGE_UPDATE: 'MESSAGE_UPDATE',
  ASSIGNEE_UPDATE: 'ASSIGNEE_UPDATE',
  CONVERSATION_STATUS_UPDATE: 'CONVERSATION_STATUS_UPDATE'
} as const;



export type IOnMessageReceivedDataAction = keyof typeof MESSAGE_RECEIVED_DATA_ACTION;

export const getFormattedReceivedMessageData = (receivedMessageData: IMessageCreated, groupTypeIdCodeObj: any): IMessageCreatedFormattedData => {
  let parentMessage = {} as any;
  let groupTypeCode = '';
  if (receivedMessageData?.parent_message) {
    parentMessage = getFormattedReceivedMessageData(receivedMessageData?.parent_message, groupTypeIdCodeObj);
  }
  const groupTypeId = receivedMessageData?.groupTypeId || receivedMessageData?.group_type_id;
  if (groupTypeId) {
    groupTypeCode = groupTypeIdCodeObj[groupTypeId];
  }

  let conversationStatus: any = receivedMessageData?.conversationStatus ?? receivedMessageData?.conversation?.status;
  if (typeof conversationStatus === 'string') {
    if (conversationStatus === CONVERSATION_STATUS_STRING.OPEN) {
      conversationStatus = CONVERSATION_STATUS.OPEN;
    } else if (conversationStatus === CONVERSATION_STATUS_STRING.RESOLVED) {
      conversationStatus = CONVERSATION_STATUS.RESOLVED;
    }
  }

  return {
    id: receivedMessageData?.id,
    uuid: receivedMessageData?.uuid,
    parentMessageUuid: receivedMessageData?.parentMessageUuid || receivedMessageData?.parent_message_uuid,
    content: receivedMessageData?.content,
    accountId: (receivedMessageData?.account_id || receivedMessageData?.accountId) as any,
    inboxId: receivedMessageData?.inboxId || receivedMessageData?.inbox_id,
    conversationUuid: (receivedMessageData?.conversationUuid || receivedMessageData?.conversation_uuid) as any,
    groupTypeId: (receivedMessageData?.groupTypeId || receivedMessageData?.group_type_id) as any,
    groupTypeCode: groupTypeCode,
    conversationStatus: conversationStatus as any,
    messageType: (receivedMessageData?.messageType ?? receivedMessageData?.message_type) as any,
    createdAt: (receivedMessageData?.createdAt || receivedMessageData?.created_at) as any,
    channel: (receivedMessageData?.channel) as any,
    updatedAt: (receivedMessageData?.updatedAt || receivedMessageData?.updated_at) as any,
    private: receivedMessageData?.private,
    status: receivedMessageData?.status as any,
    contentType: (receivedMessageData?.contentType || receivedMessageData?.content_type) as any,
    contentAttributes: (receivedMessageData?.contentAttributes || receivedMessageData?.content_attributes) as any,
    senderType: (receivedMessageData?.sender_type || receivedMessageData?.senderType) as any,
    senderId: (receivedMessageData?.senderId || receivedMessageData?.sender_id) as any,
    conversation: {
      uuid: (receivedMessageData?.conversationUuid || receivedMessageData?.conversation_uuid) as any,
      assigneeId: (receivedMessageData?.assigneeId || receivedMessageData?.assignee_id || receivedMessageData?.conversation?.assignee_id || receivedMessageData?.conversation?.assigneeId) as any,
      status: conversationStatus as any,
      practiceLocationUuid: receivedMessageData?.conversation?.practice_location_uuid
    },
    sender: {
      id: receivedMessageData?.sender?.id,
      name: receivedMessageData?.sender?.name,
      email: receivedMessageData?.sender?.email,
      phone_number: receivedMessageData?.sender?.phone_number,
      type: receivedMessageData?.sender?.type
    },
    attachments: receivedMessageData?.attachments,
    parentMessage: parentMessage,
    echoId: (receivedMessageData?.echo_id || receivedMessageData?.contentAttributes?.echo) as any,
    additionalDataAssigneeData: receivedMessageData?.additionalData,
    assigneeId: (receivedMessageData?.assigneeId || receivedMessageData?.assignee_id || receivedMessageData?.conversation?.assignee_id || receivedMessageData?.conversation?.assigneeId) as any,
    eventCode: (receivedMessageData?.eventCode) as any,
  }
}
export const setConversationDataInMsgReceivedData = (data: IMessageCreated) => {
  if (data?.conversationUuid || data?.conversation_uuid) {
    data.conversationUuid = data?.conversationUuid || data?.conversation_uuid;
  } else if (data?.additionalData?.conversationUuid || data?.additional_data?.conversationUuid) {
    data.conversationUuid = data?.additionalData?.conversationUuid || data?.additional_data?.conversationUuid;
  }

  if (data?.groupTypeId || data?.group_type_id) {
    data.groupTypeId = data?.groupTypeId || data?.group_type_id;
  }
  if (data.channel) {
    data.channel = data?.groupTypeId || data?.group_type_id
  }
}


export const updateMessageDataInMatchedConversation = (
  conversationList: IConversationData[],
  selectedConversation: IConversationData,
  receivedMessageData: IMessageCreatedFormattedData,
  userId: number,
  selectedInboxTypeCode: string,
  selectedSubtabCode?: string,
): IConversationData[] => {
  let finalConversationList = [] as IConversationData[];
  const msgData = getFormattedMsgDataForNewChange(receivedMessageData);

  conversationList.forEach((singleConversationData) => {
    let finalSingleConversationData = {...singleConversationData};
    const messageType = receivedMessageData?.messageType;
    if (
      singleConversationData?.uuid === receivedMessageData?.conversationUuid &&
      isShowRealTimeMessageOnConversationList(selectedInboxTypeCode, messageType)
    ) {
      let unreadMessages = [
        ...singleConversationData.unreadMessages ?? [],
      ];
      let unreadMsgCount = (singleConversationData?.unreadMsgCount || 0);
      if (isIncreaseUnReadCountOnConversationList(selectedInboxTypeCode, messageType) && !checkSenderIsCurrentUser(msgData?.senderId, msgData?.senderType,userId)) {
        if ((isWeb() && (selectedConversation.uuid !== singleConversationData.uuid)) ||
          !isWeb()) {
          unreadMessages = [
            ...singleConversationData.unreadMessages ?? [],
            {
              id: msgData.id,
              messageType: messageType,
            },
          ];
          unreadMsgCount = (singleConversationData?.unreadMsgCount || 0) + 1;
        }
      }
      if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS && isMissCallMessageType(messageType)) {
        singleConversationData.lastMissCallAt = receivedMessageData?.updatedAt;
      }


      if (
        selectedConversation?.uuid === singleConversationData?.uuid
        && (receivedMessageData?.senderId === userId && receivedMessageData?.senderType === SENDER_TYPE.USER)) {
        unreadMessages = [];
        unreadMsgCount = 0;
      }

      let updateMsgData = msgData;
      if (isEmailChannelInboxType(selectedInboxTypeCode)) {
        const senderType = receivedMessageData?.sender?.type ?? receivedMessageData?.senderType;
        if (
          selectedSubtabCode === CONVERSATION_SUBTAB_CODES.INBOX &&
          senderType !== SENDER_TYPE.CONTACT
        ) {
          updateMsgData = {
            ...updateMsgData,
            updatedAt:
              singleConversationData.conversationMessages?.[0]?.updatedAt,
          };
          if (receivedMessageData?.private) {
            updateMsgData = {
              ...updateMsgData,
              updatedAt:
              receivedMessageData?.updatedAt,
            };
          }
        } else if (
          selectedSubtabCode === CONVERSATION_SUBTAB_CODES.SENT &&
          senderType !== SENDER_TYPE.USER
        ) {
          updateMsgData = {
            ...updateMsgData,
            updatedAt:
              singleConversationData.conversationMessages?.[0]?.updatedAt,
          };
          if (receivedMessageData?.private) {
            updateMsgData = {
              ...updateMsgData,
              updatedAt:
              receivedMessageData?.updatedAt,
            };
          }
        }
        if (receivedMessageData?.messageType == MESSAGE_TYPE.outgoing) {
          singleConversationData.lastOutgoingEmailAt = receivedMessageData?.updatedAt
        }
      }
      if (isEFaxChannelInbox(selectedInboxTypeCode)) {
        const senderType =
          receivedMessageData?.sender?.type ?? receivedMessageData?.senderType;
        if (
          selectedSubtabCode === CONVERSATION_SUBTAB_CODES.INBOX &&
          senderType !== SENDER_TYPE.CONTACT
        ) {
          updateMsgData = {
            ...updateMsgData,
            updatedAt:
              singleConversationData.conversationMessages?.[0]?.updatedAt,
          };
        } else if (
          selectedSubtabCode === CONVERSATION_SUBTAB_CODES.SENT &&
          senderType !== SENDER_TYPE.USER
        ) {
          updateMsgData = {
            ...updateMsgData,
            updatedAt:
              singleConversationData.conversationMessages?.[0]?.updatedAt,
          };
        }
        if (receivedMessageData?.messageType == MESSAGE_TYPE.outgoing) {
          singleConversationData.lastOutgoingEFaxAt = receivedMessageData?.updatedAt
        }
      }

      finalSingleConversationData = {
        ...singleConversationData,
        conversationMessages: [updateMsgData],
        unreadMessages: unreadMessages ? [...unreadMessages] : [],
        unreadMsgCount: unreadMsgCount,
      };
      if (
        IO_MESSAGE_AND_MISS_CALL_TYPE_NUM.includes(
          receivedMessageData?.messageType
        ) ||
        isAllCallsMessageType(receivedMessageData?.messageType)
      ) {
        finalConversationList = [
          finalSingleConversationData,
          ...finalConversationList,
        ];
      } else {
        finalConversationList.push(finalSingleConversationData);
      }
    } else {
      finalConversationList.push(finalSingleConversationData);
    }
  });
  return finalConversationList;
};

export const updateConversationAssigneeDataInMatchedConversation = (
  conversationList: IConversationData[],
  receivedMessageData: IMessageCreatedFormattedData,
): IConversationData[] => {
  const finalConversationList = [] as IConversationData[];
  conversationList.forEach((singleConversationData) => {
    let finalSingleConversationData = {...singleConversationData};
    if (
      singleConversationData?.uuid === receivedMessageData?.conversationUuid
    ) {
      if (receivedMessageData?.additionalDataAssigneeData?.assigneeToUserData?.uuid) {
        singleConversationData.status = receivedMessageData?.conversationStatus as any;
        const assignedUseData = {
          ...singleConversationData.assignedUser,
          id: receivedMessageData?.additionalDataAssigneeData?.assigneeToUserData?.id,
          uuid: receivedMessageData?.additionalDataAssigneeData?.assigneeToUserData?.uuid,
          name: receivedMessageData?.additionalDataAssigneeData?.assigneeToUserData?.name,
          email: receivedMessageData?.additionalDataAssigneeData?.assigneeToUserData?.email
        };
        singleConversationData = {
          ...singleConversationData,
          assignedUser: {
            ...assignedUseData
          },
        };
      } else {
        const unAssignedUserData = {
          id: -1,
          name: '',
          uuid: '',
          email: ''
        };
        singleConversationData = {
          ...singleConversationData,
          assignedUser: {
            ...unAssignedUserData
          },
        }
      }
      finalSingleConversationData = {
        ...singleConversationData,
      };
      finalConversationList.push(finalSingleConversationData);
    } else {
      finalConversationList.push(finalSingleConversationData);
    }
  });
  return finalConversationList;
};


export const updateConversationStatusDataInMatchedConversation = (
  conversationList: IConversationData[],
  receivedMessageData: IMessageCreatedFormattedData,
): IConversationData[] => {
  const finalConversationList = [] as IConversationData[];
  conversationList.forEach((singleConversationData) => {
    let finalSingleConversationData = {...singleConversationData};
    if (
      singleConversationData?.uuid === receivedMessageData?.conversationUuid
    ) {
      if (!isNaN(receivedMessageData?.conversationStatus as any)) {
        singleConversationData.status = receivedMessageData?.conversationStatus as any;
        if (receivedMessageData?.conversationStatus === CONVERSATION_STATUS.RESOLVED) {
          const unAssignedUserData = {
            id: -1,
            name: '',
            uuid: '',
            email: ''
          };
          singleConversationData = {
            ...singleConversationData,
            assignedUser: {
              ...unAssignedUserData
            },
          }
        }
      }
      finalSingleConversationData = {
        ...singleConversationData,
      };
      finalConversationList.push(finalSingleConversationData);
    } else {
      finalConversationList.push(finalSingleConversationData);
    }
  });
  return finalConversationList;
};

export const updateConversationDataInMatchedConversation = (
  conversationList: IConversationData[],
  selectedConversation: IConversationData,
  receivedMessageData: IMessageCreatedFormattedData,
  userId: number,
  selectedInboxTypeCode: string,
  messageReceivedDataAction: IOnMessageReceivedDataAction[],
  selectedSubtabCode?: string,
): IConversationData[] => {
  let finalConversationList = [...conversationList];
  messageReceivedDataAction.forEach((actionCode) => {
    switch (actionCode) {
      case "MESSAGE_UPDATE":
        finalConversationList = updateMessageDataInMatchedConversation(
          finalConversationList,
          selectedConversation,
          receivedMessageData,
          userId,
          selectedInboxTypeCode,
          selectedSubtabCode,
        );
        break;
      case "ASSIGNEE_UPDATE":
        finalConversationList = updateConversationAssigneeDataInMatchedConversation(
          finalConversationList,
          receivedMessageData,
        );
        break;
      case "CONVERSATION_STATUS_UPDATE":
        finalConversationList = updateConversationStatusDataInMatchedConversation(
          finalConversationList,
          receivedMessageData,
        );
        break;
    }
  });
  return [...finalConversationList];
}


export const updateConversationAssigneeDataOnLocalEvent = (
  conversationList: IConversationData[],
  receivedConversationData: IConversationData,
  assigneeUserData: IConversationAssigneeLocalEventData,
  formattedMessageData: IMessageCreatedFormattedData,
  selectedInboxTypeCode: string,
  showChatsInChronologicalOrder?: boolean
): IConversationData[] => {
  let finalConversationList = [] as IConversationData[];
  conversationList.forEach((singleConversationData) => {
    let finalSingleConversationData = {...singleConversationData};
    if (singleConversationData?.uuid === receivedConversationData?.uuid) {
      if (assigneeUserData?.assigneeId) {
        const assignedUseData = {
          ...singleConversationData.assignedUser,
          id: assigneeUserData?.assigneeId,
          uuid: '',
          name: assigneeUserData?.assigneeName,
        };
        singleConversationData = {
          ...singleConversationData,
          assignedUser: {
            ...assignedUseData
          } as any,
        };
      } else {
        const unAssignedUserData = {
          id: -1,
          name: '',
          uuid: '',
          email: ''
        };
        singleConversationData = {
          ...singleConversationData,
          assignedUser: {
            ...unAssignedUserData
          },
        }
      }
      finalSingleConversationData = {
        ...singleConversationData,
      };
      if (
        isShowRealTimeMessageOnConversationList(selectedInboxTypeCode, formattedMessageData?.messageType)
      ) {
        const msgData = getFormattedMsgDataForNewChange(formattedMessageData);
        finalSingleConversationData = {
          ...singleConversationData,
          conversationMessages: [msgData],
        };
      }
      const updatedIOMessageAndMissCallTypeNum = showChatsInChronologicalOrder
      ? [...IO_MESSAGE_AND_MISS_CALL_TYPE_NUM, MESSAGE_TYPE.activity]
      : IO_MESSAGE_AND_MISS_CALL_TYPE_NUM;
      if (updatedIOMessageAndMissCallTypeNum.includes(formattedMessageData?.messageType)) {
        finalConversationList = [
          finalSingleConversationData,
          ...finalConversationList,
        ];
      } else {
        finalConversationList.push(finalSingleConversationData);
      }
    } else {
      finalConversationList.push(finalSingleConversationData);
    }
  });
  finalConversationList = getUniqueConversationListByUuid(finalConversationList);
  return finalConversationList;
};

export const updateUnixTimeStampOnIOMessage = (receivedMessageData: IMessageCreated) => {
  if (receivedMessageData?.data) {
    receivedMessageData = receivedMessageData?.data;
  }
  if (receivedMessageData?.createdAt || receivedMessageData?.created_at) {
    const createdAt = receivedMessageData?.createdAt || receivedMessageData?.created_at;
    if (typeof createdAt === 'number') {
      const createdAtDate = convertUnixDate(createdAt);
      receivedMessageData.createdAt = createdAtDate.toISOString() as any;
      receivedMessageData.created_at = createdAtDate.toISOString() as any;
    }
  }
  if (receivedMessageData?.updatedAt || receivedMessageData?.updated_at) {
    const updatedAt: any = receivedMessageData?.updatedAt || receivedMessageData?.updated_at;
    if (typeof updatedAt === 'number') {
      const updatedAtDate = convertUnixDate(updatedAt);
      receivedMessageData.updatedAt = updatedAtDate.toISOString() as any;
      receivedMessageData.updated_at = updatedAtDate.toISOString() as any;
    }
  }

}


const isShowRealTimeMessageOnConversationList = (selectedInboxTypeCode: string, receivedMessageType: number) => {
  const allowedMessageTypeForSelectedInboxType = CONVERSATION_DISPLAY_MESSAGE_TYPE[selectedInboxTypeCode];
  return allowedMessageTypeForSelectedInboxType?.includes(receivedMessageType);
}

const isIncreaseUnReadCountOnConversationList = (selectedInboxTypeCode: string, receivedMessageType: number) => {
  const allowedMessageTypeForSelectedInboxType = CONVERSATION_UNREAD_COUNT_TYPE[selectedInboxTypeCode];
  return allowedMessageTypeForSelectedInboxType?.includes(receivedMessageType);
}

export const getUniqueConversationsByConversationMentionId = (conversationList: IConversationData[]) => {
  return conversationList?.filter((conversation, index, self) =>
    index === self?.findIndex((t) => t?.conversationMentionId === conversation?.conversationMentionId)
  ) || [];
}

const checkSenderIsCurrentUser = (
  senderId?: number,
  senderType?: string,
  userId?: number
) => {
  if (senderId && senderType) {
    return senderId === userId && senderType === SENDER_TYPE.USER;
  }
  return false
};

export const isAssignedToOtherFilterApplied = (assigneeIds: number[], formattedReceivedMessageData: IMessageCreatedFormattedData, userId: number | string) => {
  return (
    assigneeIds?.length && assigneeIds?.includes(formattedReceivedMessageData?.assigneeId) ||
    (assigneeIds?.length === 0 && formattedReceivedMessageData?.assigneeId !== userId)
  );
}

export const isMissCallMessageType = (messageType: number) => {
  return MISS_CALL_MESSAGE_TYPE_NUM.includes(messageType);
}

export const isAllCallsMessageType = (messageType: number) => {
  return CALL_ONLY_MESSAGE_TYPE_NUM.includes(messageType);
}

export const getSelectedMentionConversationFromList = (selectedConversation: IConversationData, finalUpdatedConversationsList: IConversationData[]) => {
  return finalUpdatedConversationsList.find((singleData) => {
    return singleData?.conversationMentionId === selectedConversation?.conversationMentionId;
  });
}

export const getPatientName = (conversationListState: IConversationListState, formattedMessageData: IMessageCreatedFormattedData) => {
  const actionPerformedConversation = conversationListState.conversationList.find((conversation) => conversation.uuid === formattedMessageData.conversationUuid) || {} as IConversationData;
  const contactInfo: any = getContactDataAndType(actionPerformedConversation);
  const contactData: IContact = contactInfo.contactData;
  let contactName = '';
  if (contactData.name) {
    contactName = contactData.name;
  } else if ((actionPerformedConversation?.groupConversation?.id && actionPerformedConversation?.groupConversation?.name) && !contactData?.name) {
    contactName = actionPerformedConversation?.groupConversation?.name;
  } else if ((actionPerformedConversation?.inboxId > 0 && actionPerformedConversation?.conversationContact?.name) && !contactData?.name ) {
    contactName = actionPerformedConversation?.conversationContact?.name;
  }
  return contactName;
}

export const getConversationByConversationUuid = (conversationList: IConversationData[], conversationUuid: string) => {
  const selectedConversation =
    conversationList.find(
      (conversation) => conversation.uuid === conversationUuid
    ) || ({} as IConversationData);
  return selectedConversation;
};

export const getConversationUuid = (receivedConversationData: IReceivedConversationData) => {
  return receivedConversationData?.conversationUuid || receivedConversationData?.conversation_uuid || receivedConversationData?.uuid;
};

export const getConversationId = (receivedConversationData: IReceivedConversationData) => {
  return receivedConversationData?.displayId || receivedConversationData?.id;
};