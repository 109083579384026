import { Typography } from 'antd';
import moment from 'moment';
import { HStack,Pressable,View,VStack } from 'native-base';
import { useEffect,useState } from 'react';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Colors } from '../../../styles';
import { demoTenants, getAccountId } from '../../../utils/commonUtils';
import { getCommunicationLog } from '../ChatUI/MessageBox/MessageReadInfo/MessageReadInfoService';
import CustomTabsView from '../CustomTabsView/CustomTabsView';
import AutomationCommunicationAnalytics from './AutomationCommunicationAnalytics';
import CampaignCommunicationAnalytics from './CampaignCommunicationAnalytics';
import CustomDateRangePicker, { DATE_RANGE_OPTIONS, getUpdatedTimeRangeByCurrentTime } from './CustomDateRangePicker';
import OtherAnalytics from './OtherAnalytics';
import ExclamationSvg from '../../../assets/svg/ExclamationSvg';
import BulletedListSvg from '../Svg/BulletedListSvg';
import LineChartUpSvg from '../Svg/LineChartUpSvg';
import CommunicationLogLineChart from './CommunicationLogLineChart';
import { getDateStrFromFormat } from '../../../utils/DateUtils';
import { DATE_FORMATS } from '../../../constants';
import RefreshSvg from '../../../assets/svg/RefreshSvg';
import Tooltip from 'antd/es/tooltip';
import {getNeedsAttentionWidgetData} from '../../RightSideContainer/Analytics/EmployerAnalytics/tempDataFile';
const {Text, Title} = Typography;

export const COMMUNICATION_LOG_TAB = {
  AUTOMATION: {KEY: 'Automation', DISPLAY: 'Automation'},
  CAMPAIGN: {KEY: 'Campaign', DISPLAY: 'Campaign'},
  OTHER: {KEY: 'Other', DISPLAY: 'Other'},
} as const;
const CommunicationLogChart = (props: {employerId?: string, dashboardType?: string, isAccountDashboard?: boolean, locations?: {
  locationGroupIds: string[];
  locationIds: string[];
}}) => {
  const tenantId = getAccountId();
  const [isLineChartSelected, setIsLineChartSelected] = useState<boolean>(true);
  const now = moment();
  const [state, setState] = useState<any>({
    selectedTab: COMMUNICATION_LOG_TAB.CAMPAIGN.KEY,
    loading: false,
    startDate: now.clone().subtract(24, 'hours').toISOString(),
    endDate: now.toISOString(),
    appliedFilter: DATE_RANGE_OPTIONS.last24hours,
    isOpenDetails: false,
    failedData: {
      automation: {
        automationList: [],
      },
      campaign: {
        campaignList: [],
      },
      other: {
        data: {},
        count: 0,
      },
      graph: [],
    },
  });

  const loadFailedCount = async () => {

    setState((oldData: any) => {
      return {
        ...oldData,
        loading: true,
      };
    });

    const isDemoAccount = await demoTenants();
    const failedCountResponse = isDemoAccount && props.dashboardType && !props?.isAccountDashboard ? await getNeedsAttentionWidgetData() : await getCommunicationLog(
      state.startDate,
      state.endDate
    );
    const otherFailedCountResponse = failedCountResponse?.Other;
    const workflowMasterIdMap = failedCountResponse?.workflowMasterIdMap;
    const campaignIdMap = failedCountResponse?.campaignIdMap;
    setState((oldState: any) => {
      return {
        ...oldState,
        loading: false,
        failedData: {
          automation: {
            automationList: Object.keys(workflowMasterIdMap || {}).map(
              (workflowMasterId) => {
                return {
                  workflowMasterId,
                  smsFailedCount:
                    workflowMasterIdMap[workflowMasterId]?.failedSmsCount,
                  emailFailedCount:
                    workflowMasterIdMap[workflowMasterId]?.failedEmailCount,
                };
              }
            ),
          },
          campaign: {
            campaignList: Object.keys(campaignIdMap || {}).map((campaignId) => {
              return {
                campaignId,
                smsFailedCount: campaignIdMap[campaignId]?.failedSmsCount,
                emailFailedCount: campaignIdMap[campaignId]?.failedEmailCount,
              };
            }),
          },
          other: {
            data: {
              smsFailedCount: otherFailedCountResponse?.failedSmsCount,
              emailFailedCount:
                otherFailedCountResponse?.failedEmailCount ||
                otherFailedCountResponse?.EMAIL,
            },
            count:
              (otherFailedCountResponse?.failedSmsCount || 0) +
              (otherFailedCountResponse?.failedEmailCount ||
                otherFailedCountResponse?.EMAIL ||
                0),
          },
          graph : failedCountResponse?.graph
        },
      };
    });
  };
  useEffect(() => {
    loadFailedCount();
  }, [state.appliedFilter, state.startDate, state.endDate]);

  const containerStyle = props?.dashboardType && props?.dashboardType === 'EMPLOYER_REPORTING'
    ? {height: '390px', border: 'none', paddingBottom: '10px', borderRadius: '16px'}
    : {height: '358px'}
  const mainClassName = props?.dashboardType && props?.dashboardType === 'EMPLOYER_REPORTING'
    ? "display_card_container widgetBoxShadow"
    : "display_card_container"

  const containerHeight = props?.dashboardType && props?.dashboardType === 'EMPLOYER_REPORTING'
    ? "250px"
    : "210px"
  return (
    <VStack style={{flex: 1}}>
      <div className={mainClassName} style={containerStyle}>
        <header
          style={{
            display: 'flex',
            justifyContent: '',
            paddingTop: '10px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            paddingRight: '0px'
          }}
        >
          <div
            style={{
              width: '100%',
            }}
          >
            <HStack width={'full'} style={{
              borderBottomWidth: 1,
              borderBottomColor: '#e0e0e0',
              paddingBottom: 4,
            }}>
              <HStack space={2} alignItems='center'>
                <View
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: '20px'
                  }}
                >
                  <ExclamationSvg />
                </View>

                <Title level={5} className="display_card_title" style={{ marginTop: '6px' }}>
                  {'Needs Attention'}
                </Title>
              </HStack>
              <HStack flex={1} justifyContent={'flex-end'}>
                <HStack space={'2'}>
                  <Tooltip title='Refresh'>
                    <Pressable
                      style={{
                        height: 32,
                        width: 32,
                        borderRadius: 18,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginHorizontal: 2,
                      }}
                      onPress={() => {
                        let startDate = state.startDate;
                        let endDate = state.endDate;
                        if (state.appliedFilter !== DATE_RANGE_OPTIONS.custom) {
                          ({startDate, endDate} = getUpdatedTimeRangeByCurrentTime(state.startDate, state.endDate));
                        }
                        setState((oldState: any) => {
                          return {
                            ...oldState,
                            startDate,
                            endDate,
                          };
                        });
                      }}
                    >
                      <RefreshSvg />
                    </Pressable>
                  </Tooltip>

                  <View style={{ width: 1, height: 28, backgroundColor: '#d3d3d3' }} />
                  <View>
                    <CustomDateRangePicker
                      onDateRangeChange={(range: {
                        startDate: string | undefined;
                        endDate: string | undefined;
                        appliedFilter: string | undefined
                      }) => {
                        if (!range.startDate || !range.endDate) {
                          return;
                        }
                        setState((oldValue: any) => {
                          return {
                            ...oldValue,
                            startDate: range.startDate,
                            endDate: range.endDate,
                            appliedFilter: range.appliedFilter,
                          };
                        });
                      }}
                    />
                  </View>
                  <View style={{ width: 1, height: 28, backgroundColor: '#d3d3d3' }} />
                  <Pressable
                    style={{
                      height: 32,
                      width: 32,
                      borderRadius: 18,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginHorizontal: 2,
                      paddingRight: '10px'
                    }}
                    onPress={() => {
                      setIsLineChartSelected(!isLineChartSelected);
                    }}
                  >
                    {isLineChartSelected ? (
                      <LineChartUpSvg></LineChartUpSvg>
                    ) : (
                      <BulletedListSvg></BulletedListSvg>
                    )}
                  </Pressable>
                </HStack>
              </HStack>
            </HStack>
          </div>
        </header>

        <main style={{}}>{/* overflowY={'auto'} */}
        {isLineChartSelected ?
          !state?.loading ? (
            <CustomTabsView
              borderBottomWidth={2}
              fontWeight={600}
              onTabClick={(tab) => {
                setState((oldValue: any) => {
                  return {...oldValue, selectedTab: tab?.key};
                });
              }}
              tabsList={[
                {
                  key: COMMUNICATION_LOG_TAB.AUTOMATION.KEY,
                  title: COMMUNICATION_LOG_TAB.AUTOMATION.DISPLAY,
                  count: state.failedData.automation.automationList?.length,
                  tabContainerElem: () => {
                    return (
                      <VStack style={{marginTop: -5}} padding={0} paddingTop={0} height={containerHeight} borderRadius={30}>
                          <AutomationCommunicationAnalytics
                            startDate={state.startDate}
                            endDate={state.endDate}
                            automationList={
                              state?.failedData?.automation?.automationList || []
                            }
                          />
                      </VStack>
                    );
                  },
                },
                {
                  key: COMMUNICATION_LOG_TAB.CAMPAIGN.KEY,
                  title: COMMUNICATION_LOG_TAB.CAMPAIGN.DISPLAY,
                  count: state.failedData.campaign.campaignList?.length,
                  tabContainerElem: () => {
                    return (
                      <VStack style={{marginTop: -5 }} padding={0} paddingTop={0} overflowY={'auto'} height={containerHeight} borderRadius={18}>
                        <CampaignCommunicationAnalytics
                          startDate={state.startDate}
                          endDate={state.endDate}
                          campaignList={
                            state?.failedData?.campaign?.campaignList || []
                          }
                        ></CampaignCommunicationAnalytics>
                      </VStack>
                    );
                  },
                },
                {
                  key: COMMUNICATION_LOG_TAB.OTHER.KEY,
                  title: COMMUNICATION_LOG_TAB.OTHER.DISPLAY,
                  count: state.failedData.other.count,
                  tabContainerElem: () => {
                    return (
                      <VStack style={{marginTop: -5 }} padding={0}>
                        <OtherAnalytics
                          startDate={state.startDate}
                          endDate={state.endDate}
                          emailFailedCount={
                            state?.failedData?.other?.data?.emailFailedCount ||
                            0
                          }
                          smsFailedCount={
                            state?.failedData?.other?.data?.smsFailedCount || 0
                          }
                        ></OtherAnalytics>
                      </VStack>
                    );
                  },
                },
              ]}
              tabListWrapperStyle={{marginHorizontal: 8}}
            />
          ) : (
            <></>
          ) :
          <CommunicationLogLineChart data ={state.failedData.graph}/>
        }
        </main>
      </div>
    </VStack>
  );
};

export default CommunicationLogChart;
