import { useContext, useEffect, useState } from 'react';
import { View} from 'native-base';
import { FlowType } from '../../../../context/WorkflowContext';
import CustomTabsView from '../../../common/CustomTabsView/CustomTabsView';
import { ITabsList } from '../../../common/CustomTabsView/interfaces';
import TitleSubtitleView from '../../../common/TitleSubtitleView/TitleSubtitleView';
import CallLogs from './CallLogs';
import { CallDirections } from './CallLogsConst';
import { getAccountId, getAccountUUID, getAllowedUserAccountLocationUuids, getUserAccountLocationUuids, getUserId, getUserUUID, isAllowedAllLocationToUser } from '../../../../utils/commonUtils';
import { IVirtualPhoneNumberAssignee, IVirtualPhoneNumberData } from '../Conversations/ConversationContainer/ConversationFilters/interface';
import { useLazyQuery } from '@apollo/client';
import { GetAllVirtualPhoneNumber, GetVirtualPhoneNumberForAssigneeAndNumber } from './VirtualPhoneNumberQueries';
import { IUserPracticeLocationData } from '../../../../services/Location/interfaces';
import UserPracticeLocationQueries from '../../../../services/Location/UserPracticeLocationQueries';
import { IUser } from '../../../../Interfaces';
import { CloudTelephonyQueries, UserQueries } from '../../../../services';
import { CLOUD_TELEPHONY_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { VIRTUAL_ASSIGNEE_TYPE_CODES } from '../../../../constants/MlovConst';
import LoadingSpinner from '../../../common/Loader/LoadingSpinner';
import { updateAssigneeNameInVirtualNumber } from '../Conversations/conversationUtils';
import {isAccountConfigEnabled} from '../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../constants/AccountConfigConst';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import { isSettingCallsTabAllowed, USER_ACCESS_PERMISSION } from '../../UserAccess/UserAccessPermission';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { StyleSheet } from 'react-native';
import { getUserSearchByBoRoleWhereCondition } from '../../../common/OutboundCallSmsView/utils';
import { SEARCH_USERS_BY_ROLE_CODES } from '../../../../services/User/UserQueries';
import {CHANNEL_TYPE} from '../Conversations/ConversationConst';
import {IInbox} from '../../../../services/Inbox/interfaces';
import InboxQueries from '../../../../services/Inbox/InboxQueries';


const CallLogsMainView = () => {
  const accountId = getAccountId();
  const accountUuid = getAccountUUID();
  const userUuid = getUserUUID();
  const userId = getUserId();
  const commonData = useContext(CommonDataContext);
  const [callLogsState, setCallLogsState] =
    useState({
      isLoading: true,
      selectedTab: {} as ITabsList,
      conversationFilterProps: {} as any,
      conversationFilterQuery: {} as any,
      toNumber: undefined,
      showNoData: false,
    });
  const [userVirtualMobileNo, setUserVirtualMobileNo] = useState<IVirtualPhoneNumberAssignee[]>([]);
  const isAllowedAllLocation = isAllowedAllLocationToUser(commonData.currentUserRoles);
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const allowedUserAccountLocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CALL_LOGS
  );
  const isCSAndWorkFlowUserEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_CS_AND_WORKFLOW_USER_ENABLED);
  const loggedInUserUuid = getUserUUID();
  const loggedInUserLocations = getUserAccountLocationUuids();
  const isSettingCallsTabAccess = isSettingCallsTabAllowed()

  const [GetAllVirtualPhoneNumberAPI] = useLazyQuery<IVirtualPhoneNumberData>(GetAllVirtualPhoneNumber, {
    fetchPolicy: 'no-cache',
  });

  const [GetVirtualPhoneNumberForAssigneeAndNumberApi] = useLazyQuery<IVirtualPhoneNumberData>(GetVirtualPhoneNumberForAssigneeAndNumber, {
    fetchPolicy: 'no-cache',
  });

  const [getUserPracticeLocationAPI] = useLazyQuery<IUserPracticeLocationData>(
    UserPracticeLocationQueries.GetUserPracticeLocations,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const [GetUsersByUserPracticeLocations] = useLazyQuery(
    UserPracticeLocationQueries.GetUsersByUserPracticeLocations, {
      fetchPolicy: 'no-cache',
    }
  );
  const [getUsersByRoles] = useLazyQuery(SEARCH_USERS_BY_ROLE_CODES, {
    fetchPolicy: 'no-cache',
  });
  const [getUsersByUserUuidsAPI] = useLazyQuery<{
    accountUsers: {
      users: IUser
    }[]
  }>(
    UserQueries.GetUsersByUserUuids,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const getVirtualPhoneNumberList = async () => {
    const response = await GetAllVirtualPhoneNumberAPI({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        accountUuid: accountUuid
      }
    });
    return response;
  };

  const [getTwilioSmsInboxesDataForUser] = useLazyQuery<{inboxes: IInbox[]}>(
    InboxQueries.GetInboxesData,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getAssigneeIdFromAssigneeCode = (virtualPhoneNumberAssignees: IVirtualPhoneNumberAssignee[], code: string) => {
    const selectedIds: string[] = [];
    virtualPhoneNumberAssignees.forEach((singleNumber) => {
      if (singleNumber?.assigneeId && singleNumber?.assigneeType?.code === code) {
        selectedIds.push(singleNumber?.assigneeId)
      }
    });
    return selectedIds;
  }

  const getUserAndLocationDataForVirtualNumbers = async () => {
    let response;
    if (!isAllowedAllLocation && isCommunicationLocationHandlingEnabled) {
      const twilioSmsInboxesDataForUser = await getTwilioSmsInboxesDataForUser({
        variables: {
          whereCondition: {
            channelType: {
              _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS
            },
            inboxMembers: {
              userId: {
                _eq: userId
              },
              isDeleted: {_eq: false}
            },
            isDeleted: {_eq: false}
          }
        }
      });
      const phoneNumberList = twilioSmsInboxesDataForUser?.data?.inboxes?.map((inbox) => {
        return inbox?.channelTwilioSms?.phoneNumber
      })
      response = await GetVirtualPhoneNumberForAssigneeAndNumberApi({
        context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
        variables: {
          whereCondition: {
            accountUuid: {
              _eq: accountUuid
            },
            _or: [
              {
                assigneeId: {
                  _eq: userUuid
                },
              },
              {
                virtualPhoneNumber: {
                  _in: phoneNumberList
                }
              }
            ]
          }
        }
      });
      if (!response?.data?.virtualPhoneNumberAssignees?.length) {
        setCallLogsState((prev) => {
          return {
            ...prev,
            showNoData: true,
          };
        });
      }
    } else {
      response = await getVirtualPhoneNumberList();
    }
    if (response?.data) {
      const userUuids = getAssigneeIdFromAssigneeCode(response.data?.virtualPhoneNumberAssignees, VIRTUAL_ASSIGNEE_TYPE_CODES.USER);
      const userData = await getUsersByUserUuidsAPI({
        variables: {
          userUuids
        }
      });
      const accountLocationData = await getUserPracticeLocationAPI();
      const virtualPhoneNumberAssignees=  updateAssigneeNameInVirtualNumber(response?.data?.virtualPhoneNumberAssignees, accountLocationData?.data?.accountLocations || [], (userData?.data?.accountUsers || [] as any))
      if (isCommunicationLocationHandlingEnabled) {
        virtualPhoneNumberAssignees?.sort((number1, number2) => {
          if (number2?.assigneeId === loggedInUserUuid) {
            return 1;
          } else if (
            number2.assigneeType.code === 'CLINIC' &&
            number2.assigneeId &&
            number1?.assigneeId !== loggedInUserUuid
          ) {
            return loggedInUserLocations.includes(number2.assigneeId) ? 1 : -1;
          }
          return -1;
        });
      }
      setUserVirtualMobileNo(virtualPhoneNumberAssignees);
      setCallLogsState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    }else{
      setCallLogsState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    }
  }

  useEffect(() => {
    getUserAndLocationDataForVirtualNumbers()
  }, []);

  const tagsTabList = (): ITabsList[] => {
    const array: ITabsList[] = [
      {
        key: CallDirections.All,
        title: 'All',
        path: 'allCalls',
        tabContainerElem: () => {
          return (
            <CallLogs
              conversationFilterQuery={callLogsState.conversationFilterQuery}
              selectedTab={callLogsState.selectedTab}
              userVirtualMobileNo={userVirtualMobileNo}
              showNoData={callLogsState?.showNoData}
              conversationFilterProps={callLogsState.conversationFilterProps}
            />
          );
        },
      },
      {
        key: CallDirections.Incoming,
        title: 'Incoming',
        path: 'incoming',
        tabContainerElem: () => {
          return (
            <CallLogs
              conversationFilterQuery={callLogsState.conversationFilterQuery}
              selectedTab={callLogsState.selectedTab}
              userVirtualMobileNo={userVirtualMobileNo}
              showNoData={callLogsState?.showNoData}
              conversationFilterProps={callLogsState.conversationFilterProps}
            />
          );
        },
      },
      {
        key: CallDirections.Outgoing,
        title: 'Outgoing',
        path: 'outgoing',
        tabContainerElem: () => {
          return (
            <CallLogs
              conversationFilterQuery={callLogsState.conversationFilterQuery}
              selectedTab={callLogsState.selectedTab}
              userVirtualMobileNo={userVirtualMobileNo}
              showNoData={callLogsState?.showNoData}
              conversationFilterProps={callLogsState.conversationFilterProps}
            />
          );
        },
      },
      {
        key: CallDirections.Missed,
        title: 'Missed',
        path: 'missed',
        tabContainerElem: () => {
          return (
            <CallLogs
              conversationFilterQuery={callLogsState.conversationFilterQuery}
              selectedTab={callLogsState.selectedTab}
              userVirtualMobileNo={userVirtualMobileNo}
              showNoData={callLogsState?.showNoData}
              conversationFilterProps={callLogsState.conversationFilterProps}
            />
          );
        },
      },
    ];
    return array;
  };

  const onTabClick = (tabItem: ITabsList) => {
    const conversationFinalFilters: any = {
      accountId: { _eq: accountId },
      _and: [],
    };
    const callLogFilters: any = {};
    const selectedChannelType = tabItem.key;
    if (selectedChannelType === CallDirections.Incoming) {
      callLogFilters.direction = CallDirections.Incoming;
    } else if (selectedChannelType === CallDirections.Outgoing) {
      callLogFilters.direction = CallDirections.Outgoing;
    } else if (selectedChannelType === CallDirections.Missed) {
      callLogFilters.status = CallDirections.Missed;
    }
    if (callLogsState?.toNumber) {
      callLogFilters.toNumber = callLogsState?.toNumber;
    }
    conversationFinalFilters.callLogFilters = callLogFilters;
    setCallLogsState((prev) => {
      return {
        ...prev,
        selectedTab: tabItem,
        conversationFilterProps: {},
        conversationFilterQuery: conversationFinalFilters,
      };
    });
  };

  return (
    <View>
      <View style={styles.container} backgroundColor={'#fff'}>
        <TitleSubtitleView
          titleLabelId="campaignManagement"
          subtitleLabelId=""
        />
      </View>
      <View>
        {callLogsState.isLoading ?
        <LoadingSpinner />
        :
        <CustomTabsView
          flowType={FlowType.communication}
          basePath={'/call-logs'}
          showHeading
          heading="calls"
          tabsList={tagsTabList()}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
          }}
          canNavigate={true}
          onTabClick={(tabItem: ITabsList) => {
            onTabClick(tabItem);
          }}
          contentTopMargin={0}
          settingsPath={ isSettingCallsTabAccess ? "/admin/call/ivr" : ''}
          isLoading={callLogsState.isLoading}
        />
        }
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // backgroundColor: '#fff',
    flex: 1,
  },
});

export default CallLogsMainView;
