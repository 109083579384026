import {Drawer} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {
  Box,
  FormControl,
  Input,
  View,
  VStack,
  useToast,
  useMediaQuery,
} from 'native-base';
import {Colors} from '../../../../styles';
import {BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../constants';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import '../../../RightSideContainer/Contacts/TeamMembers/AddEditUser/AddEditUserStyle.css';
import {styles} from '../../../RightSideContainer/Contacts/TeamMembers/AddEditUser/AddEditUserStyles';
import {IConfigureAccessProps} from './interfaces';
import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {cloneDeep} from 'lodash';
import TaskPoolQueries, {
  CreateTaskPoolAccess,
} from '../../../../services/TaskPool/TaskPoolQueries';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import {Dimensions} from 'react-native';
import { ModalActionUserMultiSelect } from '../../../common/ModalActionCommonComponent/ModalActionUserMultiSelect';
import { ALL_USERS_CODE } from '../../UserScheduleSetting/UserScheduleHelper';
import { ToastType, showToast } from '../../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';

const AddEditUserConfigureAccess = (props: IConfigureAccessProps) => {
  const toast = useToast();
  const intl = useIntl();
  const [userFormData, setUserFormData]: any = useState({
    userId: props.userId,
    userName: props.userName,
    email: props.email,
    userIds: cloneDeep(props.userIds),
  });

  const [addUserConfigureAccess] = useMutation(CreateTaskPoolAccess);
  const [componentLoading,setComponentLoading] = useState<boolean>(false);
  /**
   * Edit Task Pool Users Mutation
   */
  const [EditTaskPoolUsers] = useMutation(TaskPoolQueries.EditTaskPoolUsers, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const [updateUserPoolAccess] = useMutation(TaskPoolQueries.UPDATE_USER_POOL_ACCESS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const handleSubmit = async () => {
    setComponentLoading(true);
    try {
      let userScheduleAccesses: any = prepareUserScheduleAccessData() || [];
      const updatedUserScheduleAccess =
        prepareUserScheduleAccessDataForUpdate() || [];
      let addUpdateUserResponse = null;

      userScheduleAccesses = userScheduleAccesses.concat(
        updatedUserScheduleAccess
      );

      if (userScheduleAccesses && userScheduleAccesses?.length) {
        if (
          props.userScheduleAccessList &&
          props.userScheduleAccessList.length > 0
        ) {
          // Already created the task pool access entry then just need to edit task pool users
          // Edit task pool users
          const updatedUserPoolUsers = userScheduleAccesses
            .filter(
              (userScheduleAccessesItem: any) =>
                !props.userIds.filter(
                  (userString: string) =>
                    userScheduleAccessesItem.scheduleAccessUserId === userString
                ).length || userScheduleAccessesItem.isDeleted
            )
            .map((userScheduleAccessesItem: any) => ({
              userId: userScheduleAccessesItem.scheduleAccessUserId,
              userPoolId: props.userScheduleAccessList[0]?.user_pool?.id,
              isDeleted: userScheduleAccessesItem.isDeleted,
              id: props.userScheduleAccessList[0]?.user_pool?.userPoolUsers.filter(
                (poolItem) =>
                  poolItem.userId ===
                  userScheduleAccessesItem.scheduleAccessUserId
              )?.[0]?.id,
            }));

          addUpdateUserResponse = await EditTaskPoolUsers({
            variables: {
              data: updatedUserPoolUsers,
            },
          });
          await updateUserPoolAccess({
            variables: {
              userPoolAccessId: props.userScheduleAccessList[0]?.id,
              updatedOn: new Date().toISOString(),
            },
          });
        } else {
          // Need to create task pool access entry with task pool users
          // create
          const userPoolUsersId = userScheduleAccesses
            .filter(
              (userScheduleAccessesItem: any) =>
                !userScheduleAccessesItem.isDeleted
            )
            .map((userScheduleAccessesItem: any) => ({
              userId: userScheduleAccessesItem.scheduleAccessUserId,
            }));

          const payloadData = {
            data: {
              userId: props.userId,
              user_pool: {
                data: {
                  typeId: props.accessUserTypeId,
                  userPoolUsers: {
                    data: userPoolUsersId,
                  },
                },
              },
            },
          };

          addUpdateUserResponse = await addUserConfigureAccess({
            variables: payloadData,
            context: {service: CARESTUDIO_APOLLO_CONTEXT},
          });
        }
      }
      setComponentLoading(false);
      showToast(
        toast,
        intl.formatMessage({id:'userTaskAccessMsg'}),
        ToastType.info,
      );
      props.onClose(true);
    } catch (error) {
      setComponentLoading(false);
      props.onClose(false);
      showToast(
        toast,
        'Error while updating user schedule access',
        ToastType.error,
      );
    }
  };

  const prepareUserScheduleAccessDataForUpdate = () => {
    return (props.userIds || [])
      .filter((id: string) => {
        const selectedUser = getUserFromOriginalPropList(id);
        return (
          !selectedUser?.id &&
          !userFormData.userIds.filter((userItem: string) => userItem === id)
            .length
        );
      })
      .map((id: string) => {
        return {
          userId: userFormData.userId,
          scheduleAccessUserId: id,
          isDeleted: true,
        };
      });
  };

  const prepareUserScheduleAccessData = () => {
    return (userFormData.userIds || [])
      .filter((id: string) => {
        const selectedUser = getUserFromOriginalPropList(id);
        return !selectedUser?.id;
      })
      .map((id: string) => {
        return {
          userId: userFormData.userId,
          scheduleAccessUserId: id,
          isDeleted: false,
        };
      });
  };

  const getUserFromOriginalPropList = (userId: string) => {
    return (props.userScheduleAccessList || []).find((user) => {
      return user.scheduleAccessUserId === userId;
    });
  };

  const isOldUserScheduleAccessUpdated = (userId: string) => {
    return (userFormData.userIds || []).some((id: string) => {
      return id === userId;
    });
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const {width} = Dimensions.get('window');
  const finalWidth = isIPadMiniScreen || isIPadScreen ? '60vw' : '40vw';
  
  return (
    <Drawer
      visible={props.showDrawer}
      style={{minWidth: 700}}
      width={finalWidth}
      onClose={() => {
        props.onClose(false);
      }}
      title={ <ModalActionTitle
        title={'editConfigureAccess'}
        titleColor={''}
        titleSize={24}
        buttonList={[
          {
            show: true,
            id: 1,
            btnText: 'cancel',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              props.onClose(false);
            },
          },
          {
            show: true,
            id: 2,
            btnText: 'save',
            isLoading: componentLoading,
            textColor: Colors.Custom.mainPrimaryPurple,
            variant: BUTTON_TYPE.PRIMARY,
            isTransBtn: false,
            // leftIcon: '',
            onClick: () => {
              handleSubmit();
            },
          },
        ]}
      />}
    >
      <Content className={'addEDitUserMultiSelect'}>
        <View>
          <VStack>
            <FormControl
              style={[styles.formElement, {marginTop: 0, paddingTop: 0}]}
            >
              <FormControl.Label style={styles.formLabel}>
                <DisplayText
                  size={'smRegular'}
                  extraStyles={{color: Colors.FoldPixel.GRAY250}}
                  textLocalId={'name'}
                />
              </FormControl.Label>
              <Input isDisabled={true} value={userFormData.userName} />
            </FormControl>
            <FormControl style={styles.formElement}>
              <FormControl.Label style={styles.formLabel}>
                <DisplayText
                  size={'smRegular'}
                  extraStyles={{color: Colors.FoldPixel.GRAY250}}
                  textLocalId={'users'}
                />
              </FormControl.Label>
              <ModalActionUserMultiSelect
                value={userFormData.userIds}
                onChange={(selectedData: any)=> {
                  setUserFormData((prev: any) => {
                    return {
                      ...prev,
                      userIds: selectedData
                    };
                  });
                }}
                data={[
                  {
                    userId: ALL_USERS_CODE,
                    userName: 'All users',
                    email: '',
                    scheduleUsers: [],
                    userRoles: [],
                  },
                  ...props.scheduleUsers,
                ]}
              />
            </FormControl>
          </VStack>
        </View>
      </Content>
    </Drawer>
  );
};

export default AddEditUserConfigureAccess;
