import { Divider, HStack, Pressable, View, Text, Spinner } from "native-base";
import { DisplayText } from "../../../common/DisplayText/DisplayText";
import { COMMON_ACTION_CODES } from "../../../../constants/ActionConst";
import { Colors } from "../../../../styles/Colors";
import Feather from "react-native-vector-icons/Feather";
import { Popover, Switch } from "antd";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_USER_BY_UUID_LIST } from "../../../../services/User/UserQueries";
import { SingleSummarizationPromptTemplate } from "./interfaces";
import UserGroupIcon from "../../../../assets/Icons/UserGroupIcon";
import SingleUserIcon from "../../../../assets/Icons/SingleUserIcon";
import LockKeyholeIcon from "../../../../assets/Icons/LockKeyholeIcon";
import { styles } from "./styles";
import { SummarizationPromptsViewCodes } from "./SummarizationPromptConsts";




export const RenderCreatedBy = (
  props: {
    userUuid: string
  }
) => {

  const [userDetail, setUserDetails] = useState<any>();
  const getUserByUuidList = useQuery(GET_USER_BY_UUID_LIST, {
    variables: {
      uuidList: [props.userUuid],
    },
    onCompleted: (data: any) => {
      setUserDetails(data?.users[0])
    },
  });

  return (
    <Text style={styles.tableContent}>
      {userDetail?.name}
    </Text>
  )
}

export const GetCloseAndContinue = (
  props: {
    isPressed: boolean;
    onActionPerformed: any;
    viewCode: string;
    adminAccess: boolean;
    isDefaultTemplate?: boolean | false;
  }) => {

  return (
    <HStack alignItems={'end'} space={2} paddingX={5} >
      {(props?.adminAccess || !props?.isDefaultTemplate) &&
        <>
          <Pressable
            disabled={props.isPressed}
            onPress={() => {
              props.onActionPerformed(COMMON_ACTION_CODES.SAVE, SummarizationPromptsViewCodes.LIST);
            }}
          >
            <View
              style={{
                backgroundColor: Colors.primary[300],
                height: 32,
                justifyContent: 'center',
                paddingHorizontal: 8,
                borderRadius: 4,
                minWidth: props.viewCode == SummarizationPromptsViewCodes.EDIT ? 70 : 65
              }}
            >
              {props.isPressed ? <Spinner></Spinner> :
                <DisplayText textLocalId={props.viewCode == SummarizationPromptsViewCodes.EDIT ? 'Update' : 'Create'} size={'smBold'} extraStyles={{ color: 'white' }} />
              }
            </View>
          </Pressable>

          <Divider orientation='vertical' backgroundColor={Colors.FoldPixel.GRAY200} height={4} alignSelf='center'></Divider>
        </>}

      <Pressable
        onPress={() => {
          props.onActionPerformed(COMMON_ACTION_CODES.CANCEL, SummarizationPromptsViewCodes.LIST);
        }}
      >
        <View justifyContent={'center'} height={8}>
          <Feather name="x" size={24} color={Colors.FoldPixel.GRAY300} />
        </View>
      </Pressable>
    </HStack>
  );
};


// export const RenderStatusSwitch = (props: { data: any , isDisabled: boolean}) => {
//   const [localIsActive, setLocalIsActive] = useState(props?.data?.isActive);

//   const handleStatusChange = async (checked: boolean, record: any) => {
//     try {
//       setLocalIsActive(checked);
//       await updateTemplateStatus(record?.id, checked, record);
//     } catch (error) {
//     }
//   };

//   return (
//     <>
//       <Switch
//         disabled={props?.isDisabled || props?.data?.isDefault}
//         size='small'
//         style={{
//           backgroundColor: localIsActive ? Colors.primary[300] : Colors.muted[500],
//         }}
//         checked={localIsActive}
//         onChange={(value: boolean) => handleStatusChange(value, props?.data)}
//       />
//     </>
//   );
// };

