import { gql } from '@apollo/client';

export const SEARCH_USER_BY_TEXT = gql`
  query GetUsers($searchString: String, $limit: Int) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        name: {_ilike: $searchString}
        isDeleted: {_eq: false}
        _not: {
          userRoles: {
            userRole: {
              userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
            }
          }
        }      }
      limit: $limit
    ) {
      id
      name
      uuid
      email
      externalUserId
      userRoles {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;


export const GET_ACTIVE_USERS_BY_ROLE_IDS = gql`
  query GetUsersByRoleIds($roleIds: [uuid!]!) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        _or: [
          {userRoles: {userRole: {userRole: {id: {_in: $roleIds}}}}}
          {userRoles: {userRole: {id: {_in: $roleIds}}}}
        ]
        _not: {
          userRoles: {
            userRole: {
              userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
              isDeleted: {_eq: false}
            }
          }
        }
      }
    ) {
      id
      email
      name
      uuid
      externalUserId
      userRoles {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          id
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        uuid
        practiceLocationUuid
        accountLocation {
          uuid
          locationGroupId
          practiceLocation {
            uuid
            name
            addressLine1
            addressLine2
            cityUuid
            stateUuid
            practiceZipcode {
              code
              cityId
              stateId
            }
            practiceState {
              name
            }
            practiceCountry {
              name
            }
            practiceCity {
              name
            }
          }
        }
      }
    }
  }
`;


export const SEARCH_USER_BY_TEXT_WITH_OFFSET = gql`
  query GetUsers($searchString: String, $limit: Int, $offset: Int) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        name: {_ilike: $searchString}
        isDeleted: {_eq: false}
        _not: {
          userRoles: {
            userRole: {
              userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
            }
          }
        }      }
      limit: $limit
      offset: $offset,
      order_by: { name: asc }
    ) {
      id
      name
      uuid
      email
      externalUserId
      userRoles {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;

export const CREATE_UPDATE_SINGLE_USER = gql`
  mutation createOrUpdateSingleUser($params: users_insert_input!) {
    createUser(
      object: $params
      on_conflict: {
        constraint: users_pkey
        update_columns: [
          name
          subtitle
          description
          personId
        ]
      }
    ) {
      id
      name
    }
  }
`;
export const GET_SINGLE_USER = gql`
  query GetSingleUser($userId: uuid) {
    users(where: {uuid: {_eq: $userId}}) {
      id
      name
      uuid
      email
      subtitle
      description
      personId
      externalUserId
      accountUsers {
        externalUserId
        isActive
        uuid
        account {
          uuid
          id
        }
      }
      providerIdentifiers {
        id
        identifierTypeId
        value
        accountLocationId
        states {
          name
        }
      }
      persons {
        id
        lastName
        middleName
        name
        firstName
        genderId
        dateOfBirth
        birthSexId
        personAddressDetails {
          id
          addressDetails {
            id
            typeId
            cityId
            zipcodeId
            line2
            line1
            countryId
            city {
              name
              id
            }
            state {
              id
              name
            }
            zipcode{
              code
              id
            }
          }
        }
        personContactDetails {
          id
          contactDetailId
          contactDetails {
            id
            value
            contactTypeId
            relationType {
              code
              value
              id
            }
          }
        }
      }
      userRoles(where: {isDeleted: {_eq: false}}) {
        id
        userRoleId
        accountId
        accountLocationId
        userRole {
          id
          roleName
          customRoleCode
          roleCategory {
            code
          }
          userRole {
            id
            code
            value
          }
        }
      }
      userPracticeLocations(
        where: {
          isDeleted: {_eq: false}
          accountLocation: {practiceLocation: {isDeleted: {_eq: false}}}
        }
      ) {
        userUuid
        uuid
        accountLocationUuid
        accountLocation {
          id
          uuid
          practiceLocation {
            name
            id
            uuid
            timezone: practiceTimezone {
              uuid
              abbreviations
              timezone
              gmtOffset
              utcOffset
            }
          }
        }
      }
    }
  }
`;

export const GET_USERS_WITH_IDS = gql`
  query GetUsersWithIds($userIds: [uuid!]) {
    users(where: {uuid: {_in: $userIds}}) {
      id
      name
      uuid
      email
      externalUserId
      userRoles {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;

export const GET_USERS_WITH_INT_IDS = gql`
  query GetUsersWithIds($userIds: [bigint!]) {
    users(where: {id: {_in: $userIds}}) {
      id
      name
      userRoles {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;


export const GET_USERS_BY_ROLE_IDS = gql`
   query GetUsersByRoleIds($roleIds: [uuid!]!) {
    users(
      where: {
        _or:[{ userRoles: {userRole: {userRole: {id: {_in: $roleIds}}}} }, {userRoles: {userRole:  {id: {_in: $roleIds}}} }]
        _not: {
          userRoles: {
            userRole: {
              userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
            }
          }
        }      }
    ) {
      id
      email
      name
      uuid
      externalUserId
      userRoles {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          id
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        uuid
        practiceLocationUuid
        accountLocation {
          uuid
          locationGroupId
          practiceLocation {
            uuid
            name
            addressLine1
            addressLine2
            cityUuid
            stateUuid
            practiceZipcode {
              code
              cityId
              stateId
            }
            practiceState {
              name
            }
            practiceCountry {
              name
            }
            practiceCity {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_USERS_WITH_LOCATION_AND_ROLES_FROM_IDS = gql`
  query GetUsersWithIds($userIds: [uuid!]) {
    users(where: {
      uuid: {_in: $userIds},
      accountUsers: {isActive: {_eq: true}}
      }
    ) {
      id
      name
      uuid
      externalUserId
      userRoles {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        uuid
        practiceLocationUuid
        accountLocation {
          uuid
          practiceLocation {
            uuid
            name
            addressLine1
            addressLine2
            cityUuid
            stateUuid
            practiceZipcode {
              code
              cityId
              stateId
            }
            practiceState {
              name
            }
            practiceCountry {
              name
            }
            practiceCity {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query GetUserAgent($searchString: String!, $limit: Int, $offset: Int) {
    users(
      where: {
        _or: [{name: {_ilike: $searchString}}, {email: {_ilike: $searchString}}]
        _not: {
          userRoles: {
            userRole: {
              userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
            }
          }
        }      },
        offset: $offset, limit: $limit
    ) {
      id
      uid
      type
      email
      name
      subtitle
      description
      uuid
      externalUserId
      externalUserMap {
        externalUserId
        locationGroupId
        id
      }
      accountUsers {
        employerId
        isActive
        externalUserId
        uuid
        account {
          uuid
          id
          name
        }
      }
      userRoles(where: {isDeleted: {_eq: false}}) {
        id
        userRoleId
        accountId
        accountLocationId
        userRole {
          id
          customRoleCode
          roleName
          roleCategory {
            code
          }
          userRole {
            id
            code
            value
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        userUuid
        uuid
        isDeleted
        accountLocationUuid
        accountLocations(where: {isDeleted: {_eq: false}}) {
          uuid
          isDeleted
          locationGroupId
          practiceLocation {
            isDeleted
            name
            uuid
            timezoneUuid
            practiceCity {
              name
              id
            }
            practiceCountry {
              name
              id
            }
            practiceState {
              name
            }
            zipcodeUuid
            practiceZipcode {
              areaCode
              code
              id
            }
          }
        }
        practiceLocation {
          name
          uuid
          timezoneUuid
          practiceCity {
            name
            id
          }
          practiceCountry {
            name
            id
          }
          practiceState {
            name
          }
          zipcodeUuid
          practiceZipcode {
            areaCode
            code
            id
          }
        }
      }
    },
    userAggregate(where: {_or: [{name: {_ilike: $searchString}}, {email: {_ilike: $searchString}}], _not: {userRoles: {userRole: {userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}}}}}) {
    aggregate {
      count
    }
  }
  }
`;

// This is the copy of above query but with isActive: true
export const GET_ACTIVE_USERS = gql`
  query GetUserAgent($searchString: String!, $limit: Int, $offset: Int) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        _or: [{name: {_ilike: $searchString}}, {email: {_ilike: $searchString}}]
        _not: {
          userRoles: {
            userRole: {
              userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
            }
          }
        }      },
        offset: $offset, limit: $limit
    ) {
      id
      uid
      type
      email
      name
      subtitle
      description
      uuid
      externalUserId
      externalUserMap {
        externalUserId
        locationGroupId
        id
      }
      accountUsers {
        employerId
        isActive
        externalUserId
        uuid
        account {
          uuid
          id
          name
        }
      }
      userRoles(where: {isDeleted: {_eq: false}}) {
        id
        userRoleId
        accountId
        accountLocationId
        userRole {
          id
          customRoleCode
          roleName
          roleCategory {
            code
          }
          userRole {
            id
            code
            value
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        userUuid
        uuid
        isDeleted
        accountLocationUuid
        accountLocations(where: {isDeleted: {_eq: false}}) {
          uuid
          isDeleted
          locationGroupId
          practiceLocation {
            isDeleted
            name
            uuid
            timezoneUuid
            practiceCity {
              name
              id
            }
            practiceCountry {
              name
              id
            }
            practiceState {
              name
            }
            zipcodeUuid
            practiceZipcode {
              areaCode
              code
              id
            }
          }
        }
        practiceLocation {
          name
          uuid
          timezoneUuid
          practiceCity {
            name
            id
          }
          practiceCountry {
            name
            id
          }
          practiceState {
            name
          }
          zipcodeUuid
          practiceZipcode {
            areaCode
            code
            id
          }
        }
      }
    },
    userAggregate(where: { accountUsers: {isActive: {_eq: true}}, _or: [{name: {_ilike: $searchString}}, {email: {_ilike: $searchString}}], _not: {userRoles: {userRole: {userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}}}}}) {
    aggregate {
      count
    }
  }
  }
`;

export const GET_USER_BY_UUID = gql`
  query GetUserAgent($userUuid: uuid!) {
    users(where: {uuid: {_eq: $userUuid}}) {
      id
      uid
      type
      email
      name
      subtitle
      description
      uuid
      externalUserId
      externalUserMap {
        externalUserId
        locationGroupId
        id
      }
      accountUsers {
        employerId
        isActive
        externalUserId
        uuid
        account {
          uuid
          id
          name
        }
      }
      userRoles(where: {isDeleted: {_eq: false}}) {
        id
        userRoleId
        accountId
        accountLocationId
        userRole {
          id
          customRoleCode
          roleName
          roleCategory {
            code
          }
          userRole {
            id
            code
            value
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        userUuid
        uuid
        isDeleted
        accountLocationUuid
        accountLocations(where: {isDeleted: {_eq: false}}) {
          uuid
          isDeleted
          locationGroupId
          practiceLocation {
            isDeleted
            name
            uuid
            timezoneUuid
            practiceCity {
              name
              id
            }
            practiceCountry {
              name
              id
            }
            practiceState {
              name
            }
            zipcodeUuid
            practiceZipcode {
              areaCode
              code
              id
            }
          }
        }
        practiceLocation {
          name
          uuid
          timezoneUuid
          practiceCity {
            name
            id
          }
          practiceCountry {
            name
            id
          }
          practiceState {
            name
          }
          zipcodeUuid
          practiceZipcode {
            areaCode
            code
            id
          }
        }
      }
    }
  }
`;

export const GET_USERS_BY_IVR_AND_LOCATION_UUID_WITH_SEARCH = gql`
  query GetUsersForIVR($roleCode: String!, $locationUuids: [uuid!], $searchString: String!, $limit: Int) {
    users(
      where: {
        _or: [{name: {_ilike: $searchString}}, {email: {_ilike: $searchString}}]
        accountUsers: {isActive: {_eq: true}}
        userPracticeLocations: {
          isDeleted: {_eq: false}
          accountLocations: {practiceLocation: {uuid: {_in: $locationUuids}}}
        }
        _not: {
          userRoles: {
            userRole: {
              userRole: {
                code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS", $roleCode]}
              }
            }
          }
        }
      }
      limit: $limit
    ) {
      id
      name
      uuid
      email
      userRoles {
        userRole {
          customRoleCode
          roleName
          userRole {
            value
          }
        }
      }
    }
  }
`;

export const GET_USERS_WITHOUT_EMPLOYER = gql`
  query GetUserAgentWithoutEmployer(
    $searchString: String!
    $roleCode: String!
  ) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        _or: [{name: {_ilike: $searchString}}, {email: {_ilike: $searchString}}]
        userRoles: {userRole: {userRole: {code: {_neq: $roleCode}}}}
      }
    ) {
      id
      uid
      type
      email
      name
      subtitle
      description
      uuid
      externalUserId
      accountUsers {
        isActive
        uuid
        account {
          uuid
        }
      }
      userRoles {
        id
        userRoleId
        userRole {
          id
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        userUuid
        uuid
        isDeleted
        accountLocations(where: {isDeleted: {_eq: false}}) {
          uuid
          isDeleted
          practiceLocation {
            isDeleted
            name
            uuid
            timezoneUuid
            practiceCity {
              name
              id
            }
            practiceCountry {
              name
              id
            }
            practiceState {
              name
            }
            zipcodeUuid
            practiceZipcode {
              areaCode
              code
              id
            }
          }
        }
        practiceLocation {
          name
          uuid
          timezoneUuid
          practiceCity {
            name
            id
          }
          practiceCountry {
            name
            id
          }
          practiceState {
            name
          }
          zipcodeUuid
          practiceZipcode {
            areaCode
            code
            id
          }
        }
      }
    }
  }
`;

export const GET_USER_TASK_DISPLAY_CATEGORY = gql`
  query getUserTaskDisplayCategories($userId: uuid!) {
    userTaskDisplayCategories(
      where: {userId: {_eq: $userId}, isDeleted: {_eq: false}}
      order_by: {updatedOn: desc}
    ) {
      id
      taskDisplayCategoryId
      categoryValue
      isDeleted
      sequenceNumber
      tenantId
    }
  }
`;

export const GET_USERS_BY_ID = gql`
  query GetUserAgent($userUuid: uuid!) {
    users(where: {uuid: {_eq: $userUuid}}) {
      id
      uid
      type
      email
      name
      uuid
      externalUserId
      accountUsers {
        uuid
        account {
          uuid
        }
      }
      userRoles {
        id
        userRoleId
        accountLocationId
        userRole {
          id
          customRoleCode
          roleName
          roleCategory {
            code
          }
          userRole {
            id
            code
            value
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        userUuid
        uuid
        accountLocations {
          uuid
          practiceLocation {
            name
            uuid
            timezoneUuid
            practiceCity {
              name
              id
            }
            practiceCountry {
              name
              id
            }
            practiceState {
              name
            }
            zipcodeUuid
            practiceZipcode {
              areaCode
              code
              id
            }
          }
        }
        practiceLocation {
          name
          uuid
          timezoneUuid
          practiceCity {
            name
            id
          }
          practiceCountry {
            name
            id
          }
          practiceState {
            name
          }
          zipcodeUuid
          practiceZipcode {
            areaCode
            code
            id
          }
        }
      }
    }
  }
`;

export const GET_USER_ROLE_CATEGORIES = gql`
  query getAccountRoles ($category: [String!]) {
    accountRoles(where: {isDeleted: {_eq: false}, userRole: {category: {category: {_in: $category}}}}) {
      id
      roleId
      isDeleted
      customRoleCode
      roleName
    }
  }
`;

export const CURRENT_USER_ROLE = gql`
  query GetUserRole($userId: bigint) {
    userRoles(where: {userId: {_eq: $userId}}) {
      userRole {
        customRoleCode
        roleName
        userRole {
          code
          value
        }
      }
    }
  }
`;

export const USER_PERMISSIONS = gql`
  query getAccountLevelUserPermission {
    userPermissions(where: {isDeleted: {_eq: false}}) {
      actionCode
      entityCode
      id
      userRoleCode
      permissionCode
      createdAt
    }
  }
`;

export const GET_ALL_USERS_FOR_CARETEAM = gql`
  query GetAllUsersForCareTeam($limit: Int, $offset: Int) {
    users(
      limit: $limit
      offset: $offset
      where: {
        _not: {
          userRoles: {
            userRole: {
              userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
            }
          }
        }      }
    ) {
      id
      name
      uuid
      email
      externalUserId
      accountUsers {
        employerId
        externalUserId
        isActive
      }
      userRoles {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        id
        uuid
        practiceLocationUuid
        accountLocation {
          uuid
          practiceLocation {
            id
            uuid
            name
            addressLine1
            addressLine2
            cityUuid
            stateUuid
            countryUuid
            practiceZipcode {
              code
              cityId
              stateId
            }
            practiceState {
              name
            }
            practiceCountry {
              name
            }
            practiceCity {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_USERS_FOR_CALENDAR = gql`
  query GetUsersForCalendar($limit: Int, $offset: Int) {
    users(
      limit: $limit
      offset: $offset
      where: {
        accountUsers: {isActive: {_eq: true}}
        _not: {
          userRoles: {
            userRole: {
              userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
            }
          }
        }      }
    ) {
      id
      name
      uuid
      email
      externalUserId
      externalUserMap {
        locationGroupId
        externalUserId
      }
      accountUsers {
        employerId
        externalUserId
        isActive
      }
      userRoles {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        id
        uuid
        practiceLocationUuid
        accountLocation {
          uuid
          locationGroupId
          practiceLocation {
            uuid
            name
            addressLine1
            addressLine2
            cityUuid
            stateUuid
            countryUuid
            practiceZipcode {
              code
              cityId
              stateId
            }
            practiceState {
              name
            }
            practiceCountry {
              name
            }
            practiceCity {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_USERS_FOR_CALENDAR_V2 = gql`
  query GetUsersForCalendar($limit: Int, $offset: Int, $userboolExp: users_bool_exp!) {
    users(
      limit: $limit
      offset: $offset
      where: {
        _and: [
          {
            accountUsers: {
              isActive: { _eq: true }
            }
          },
          $userboolExp,
          {
            _not: {
              userRoles: {
                userRole: {
                  userRole: { code: { _in: ["WORKFLOW", "CUSTOMER_SUCCESS"] } }
                }
              }
            }
          }
        ]
      }
    ) {
      id
      name
      uuid
      email
      externalUserId
      accountUsers {
        employerId
        externalUserId
        isActive
      }
      userRoles {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;


export const GET_USERS_BY_RESOURCE_CODES = gql`
  query SearchUsers($params: SearchUsersInput!) {
    searchUsers(params: $params) {
      users {
        id
        uuid
        name
        email
        userPracticeLocations {
          accountLocationUuid
        }
        userRoles {
          id
          accountLocationId
          userRole {
            id
            roleId
            roleName
            customRoleCode
          }
        }
      }
      userAggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_USERS_BY_RESOURCE_CODES_V2 = gql`
  query SearchUsers($params: SearchUsersInput!) {
    searchUsers(params: $params) {
      users {
        id
        uuid
        name
      }
    }
  }
`;

export const GET_USERS_FOR_CALENDAR_WITHOUT_EMPLOYER = gql`
  query GetUsersForCalendarWithoutEmployer($roleCode: String!) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        _not: {
          userRoles: {
            userRole: {userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS",$roleCode]}}}
          }
        }
      }
    ) {
      id
      name
      uuid
      email
      externalUserId
      accountUsers {
        employerId
      }
      userRoles {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;

export const CREATE_PROVIDER_IDENTIFIERS = gql`
  mutation CREATE_PROVIDER_IDENTIFIERS(
    $data: [provider_identifiers_insert_input!]!
  ) {
    createProviderIdentifiers(
      objects: $data
      on_conflict: {
        constraint: provider_identifiers_pkey
        update_columns: [value, accountLocationId]
      }
    ) {
      affected_rows
    }
  }
`;

export const CREATE_PERSON_ADDRESS_DETAILS = gql`
  mutation CREATE_PERSON_ADDRESS_DETAIL(
    $params: person_address_details_insert_input!
  ) {
    createPersonAddressDetail(object: $params) {
      id
    }
  }
`;

export const CREATE_ADDRESS = gql`
  mutation MyMutation($params: addresses_insert_input!) {
    createAddress(object: $params) {
      id
    }
  }
`;

export const CREATE_PERSON_CONTACT_DETAILS = gql`
  mutation createPersonContactDetails(
    $params: [person_contact_details_insert_input!]!
  ) {
    createPersonContactDetails(objects: $params) {
      affected_rows
    }
  }
`;

export const UPDATE_CONTACT_DETAILS_BY_ID = gql`
  mutation updateContactDetails($value: String!, $id: uuid!) {
    updateContactDetails(where: {id: {_eq: $id}}, _set: {value: $value}) {
      affected_rows
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER($id: bigint!, $name: String!, $subtitle: String!, $description: String!,$personId: uuid, $signatureAttachmentId: uuid) {
    updateUser(pk_columns: {id: $id}, _set: {name: $name, subtitle: $subtitle, description: $description, personId: $personId, signatureAttachmentId: $signatureAttachmentId}) {
      email
      id
      name
      description
      subtitle
      personId
      # line1
      # line2
      # phone
      # stateId
      # zipcodeId
      # cityId
    }
  }
`;

export const ADD_PERSON_DATA = gql`
  mutation CREATE_PERSON($params: persons_insert_input!) {
    createPerson(
      object: $params
      on_conflict: {constraint: persons_pkey, update_columns: [isDeleted, id]}
    ) {
      id
      name
    }
  }
`;

export const ADD_CONTACT_DETAILS = gql`
  mutation createContactDetails($params: [contact_details_insert_input!]!) {
    createContactDetails(objects: $params) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const GET_CONTACT_DETAILS_ID = gql`
query GET_CONTACT_DETAILS_ID($personId: uuid!) {
  ContactDetails(where: {id: {_eq: $personId}}) {
    value
    contactTypeId
    id
  }
}
`;



export const CREATE_PRACTICE_LOCATION = gql`
  mutation MyMutation3(
    $addressLine1: String!
    $addressLine2: String
    $cityUuid: uuid
    $stateUuid: uuid
    $zipcodeUuid: uuid!
    $name: String!
    $countryUuid: uuid!
    $timeZoneUuid: uuid!
  ) {
    createPracticeLocation(
      object: {
        name: $name
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        cityUuid: $cityUuid
        stateUuid: $stateUuid
        zipcodeUuid: $zipcodeUuid
        countryUuid: $countryUuid
        timezoneUuid: $timeZoneUuid
      }
    ) {
      name
      id
      uuid
    }
  }
`;
export const CREATE_ACCOUNT_LOCATION = gql`
  mutation MyMutation($accountUuid: uuid!, $locationUuid: uuid!, $googleMapLink: String, $locationGroupId: uuid!) {
    createAccountLocation(
      object: {accountUuid: $accountUuid, locationUuid: $locationUuid, googleMapLink: $googleMapLink, locationGroupId: $locationGroupId}
    ) {
      uuid
      id
      practiceLocation {
        id
        uuid
        addressLine1
        cityUuid
        name
      }
    }
  }
`;

export const UPDATE_ACCOUNT_LOCATION = gql`
  mutation MyMutation($id: bigint, $googleMapLink: String) {
    updateAccountLocations(where: {id: {_eq: $id}}, _set: {googleMapLink: $googleMapLink}) {
      returning {
        id
        googleMapLink
        locationUuid
        uuid
      }
    }
  }
`;

export const UPDATE_ACCOUNT_LOCATION_CONTAINING_MAPPED_LOCATION = gql`
  mutation MyMutation($id: bigint, $googleMapLink: String, $uuid: uuid) {
    updateAccountLocations(where: {id: {_eq: $id}}, _set: {googleMapLink: $googleMapLink, uuid: $uuid}) {
      returning {
        id
        googleMapLink
        locationUuid
        uuid
      }
    }
  }
`;

export const UPDATE_PRACTICE_LOCATION = gql`
  mutation MyMutation3(
    $id: bigint!
    $addressLine1: String!
    $addressLine2: String
    $cityUuid: uuid
    $stateUuid: uuid
    $zipcodeUuid: uuid!
    $name: String!
    $countryUuid: uuid!
    $timeZoneUuid: uuid!
  ) {
    updatePracticeLocation(
      _set: {
        name: $name
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        cityUuid: $cityUuid
        stateUuid: $stateUuid
        zipcodeUuid: $zipcodeUuid
        countryUuid: $countryUuid
        timezoneUuid: $timeZoneUuid
      }
      pk_columns: {id: $id}
    ) {
      name
      id
      uuid
    }
  }
`;

export const DELETE_PRACTICE_LOCATION = gql`
  mutation MyMutation3(
    $id: bigint!
  ) {
    updatePracticeLocation(
      _set: {
        isDeleted: true,
      }
      pk_columns: {id: $id}
    ) {
      id
    }
  }
`;

export const GetUserDetails = gql`
  query GetUserDetails {
    userDetails(where: {isDeleted: {_eq: false}}) {
      virtualPhoneNumber
      user {
        id
        name
        uuid
      }
    }
  }
`;

export const Get_Users_IVR = gql`
  query GetUsersForIVR($roleCode: String!) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        _not: {
          userRoles: {
            userRole: {userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS",$roleCode]}}}
          }
        }
      }
    ) {
      id
      name
      uuid
      email
      userRoles {
        userRole {
          customRoleCode
          roleName
          userRole {
            value
          }
        }
      }
    }
  }
`;

export const Get_Users_IVR_With_Multiple_Role_Codes = gql`
  query GetUsersForIVR($roleCodes: [String!]!) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        _not: {
          userRoles: {
            userRole: {userRole: {code: {_in: $roleCodes}}}
          }
        }
      }
    ) {
      id
      name
      uuid
      email
      userRoles {
        userRole {
          customRoleCode
          roleName
          userRole {
            value
          }
        }
      }
    }
  }
`;

export const GET_USERS_FOR_CALENDAR_WITH_LOCATION = gql`
  query GetUsersForCalendarWithLocation($roleCode: String!) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        _not: {
          userRoles: {
            userRole: {userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS",$roleCode]}}}
          }
        }
      }
    ) {
      id
      uuid
      name
      externalUserId
      externalUserMap {
        locationGroupId
        externalUserId
      }
      accountUsers {
        externalUserId
        isActive
      }
      userRoles {
        userRole {
          id
          customRoleCode
          roleName
          userRole {
            id
            value
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        uuid
        practiceLocationUuid
        accountLocation {
          uuid
          locationGroupId
          practiceLocation {
            uuid
            name
            addressLine1
            addressLine2
            cityUuid
            stateUuid
            countryUuid
            practiceZipcode {
              code
              cityId
              stateId
            }
            practiceState {
              name
            }
            practiceCountry {
              name
            }
            practiceCity {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_USERS_FOR_CALENDAR_BY_LOCATION = gql`
  query GetUsersForCalendarByLocation(
    $roleCode: String!
    $locationUuids: [uuid!]
  ) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        _not: {
          userRoles: {
            userRole: {userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS",$roleCode]}}}
          }
        }
        userPracticeLocations: {
          isDeleted: {_eq: false}
          accountLocations: {uuid: {_in: $locationUuids}}
        }
      }
    ) {
      id
      uuid
      name
      externalUserId
      accountUsers {
        externalUserId
        isActive
      }
      userRoles {
        userRole {
          customRoleCode
          roleName
          id
          userRole {
            id
            value
          }
        }
      }
      userPracticeLocations {
        uuid
        practiceLocationUuid
        accountLocation {
          uuid
          locationGroupId
          practiceLocation {
            uuid
            name
            addressLine1
            addressLine2
            cityUuid
            stateUuid
            countryUuid
            practiceZipcode {
              code
              cityId
              stateId
            }
            practiceState {
              name
            }
            practiceCountry {
              name
            }
            practiceCity {
              name
            }
          }
        }
      }
    }
  }
`;

// We have to use this query to get users which non delete and active or non active status
export const GET_USERS_FOR_CALENDAR_WITH_LOCATION_AND_CONTACT_DETAILS = gql`
  query GetUsersForCalendarWithLocation($roleCode: String!, $locationGroupId: uuid) {
    users(
      where: {
        accountUsers: {isDeleted: {_eq: false}}
        _not: {
          userRoles: {
            userRole: {userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS",$roleCode]}}}
          }
        }
      }
    ) {
      id
      uuid
      name
      externalUserMap (where: {locationGroupId: {_eq: $locationGroupId}}) {
        externalUserId
      }
      externalUserId
      accountUsers {
        externalUserId
        isActive
      }
      persons {
        personContactDetails {
          contactDetails {
            value
            relationType {
              code
            }
          }
        }
      }
      userRoles {
        userRole {
          customRoleCode
          roleName
          userRole {
            id
            value
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        uuid
        practiceLocationUuid
        accountLocation {
          uuid
          locationGroupId
          practiceLocation {
            uuid
            name
            addressLine1
            addressLine2
            cityUuid
            stateUuid
            countryUuid
            practiceZipcode {
              code
              cityId
              stateId
            }
            practiceState {
              name
            }
            practiceCountry {
              name
            }
            practiceCity {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_DEFAULT_ROLES_FOR_JOURNEY = gql`
  query GetUsersForCalendarWithLocation($roleCode: String!) {
    users(
      where: {
        _not: {
          userRoles: {
            userRole: {userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS",$roleCode]}}}
          }
        }
      }
    ) {
      id
      uuid
      name
      userRoles {
        userRole {
          id
          customRoleCode
          roleName
          userRole {
            id
            value
          }
        }
      }
    }
  }
`;

export const GET_USER_FOR_SCHEDULE_ACCESS = gql`
  query GetUsersForIVR($roleCode: String!) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        _not: {userRoles: {userRole: {userRole: {code: {_in: [$roleCode]}}}}}
      }
    ) {
      id
      name
      uuid
      email
      accountUsers {
        isActive
      }
      userRoles {
        userRole {
          customRoleCode
          roleName
          userRole {
            code
            value
            id
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        uuid
        practiceLocationUuid
        accountLocation {
          uuid
          practiceLocation {
            uuid
            name
            addressLine1
            addressLine2
            cityUuid
            stateUuid
            countryUuid
            practiceZipcode {
              code
              cityId
              stateId
            }
            practiceState {
              name
            }
            practiceCountry {
              name
            }
            practiceCity {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_FOR_TASKS = gql`
  query GetUsersForIVR($roleCodes: [String]!) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        _not: {userRoles: {userRole: {userRole: {code: {_in: $roleCodes}}}}}
      }
    ) {
      id
      name
      uuid
      email
      accountUsers{
        id
        isActive
      }
      userRoles {
        userRole {
          customRoleCode
          roleName
          userRole {
            code
            value
            id
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        uuid
        practiceLocationUuid
        accountLocation {
          uuid
          practiceLocation {
            uuid
            name
            addressLine1
            addressLine2
            cityUuid
            stateUuid
            countryUuid
            practiceZipcode {
              code
              cityId
              stateId
            }
            practiceState {
              name
            }
            practiceCountry {
              name
            }
            practiceCity {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_USERS_FOR_CALENDAR = gql`
  query GetUsersForIVR($notInRoleCodes: [String!]!, $users: [uuid!]!) {
    users(
      where: {
        uuid: {_in: $users}
        accountUsers: {isActive: {_eq: true}}
        _not: {
          userRoles: {userRole: {userRole: {code: {_in: $notInRoleCodes}}}}
        }
      }
    ) {
      uuid
    }
  }
`;

export const GET_MANAGER_ROLES = gql`
  query user {
    users(
      where: {
        _not: {
          userRoles: {
            userRole: {
              userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
            }
          }
        }      }
    ) {
      id
      uuid
      name
      email
      accountUsers {
        accountId
      }
      userRoles {
        userRole {
          customRoleCode
          roleName
          userRole {
            code
            value
          }
        }
      }
    }
  }
`;

export const UpdateAccountUserWithExternalUserId = gql`
  mutation UpdateAccountUser($userId: bigint!, $externalUserId: String) {
    updateAccountUsers(
      where: {userId: {_eq: $userId}}
      _set: {externalUserId: $externalUserId}
    ) {
      affected_rows
    }
    updateUser(
      pk_columns: {id: $userId}
      _set: {externalUserId: $externalUserId}
    ) {
      externalUserId
    }
  }
`;


const GET_CONTACTS_BY_ID = gql`
  query getUser($userId: bigint!) {
    user(id: $userId) {
      name
      provider
      email
      id
      userRoles {
        id
        userRoleId
        userRole {
          id
          customRoleCode
          roleName
          roleCategory {
            code
          }
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;

export const GET_USER_ACTION_MASTERS = `
  query GetUserActionMasters($level: String = "BEGINNER") {
    userActionMasters(where: {level: {_eq: $level}, isDeleted: {_eq: false}}) {
      actionCode
      createdOn
      display
      id
      isDeleted
      tenantId
      level
    }
  }
`;

export const GET_ALL_USER_ACTION_MASTERS_DATA = `
query GetUserActionMasters($userId: uuid, $tenantId: uuid) {
  userActionMasters(order_by: [{createdOn: asc}], where: {isDeleted: {_eq: false}}) {
    actionCode
    createdOn
    display
    id
    isDeleted
    sideMenuCode
    mainTabCode
    tenantId
    level
  }
  userActionLevels: mlovs(order_by: [{sequenceNumber: asc}], where: {category: {_eq: "UserActionLevel"}}) {
    code
    value
    description
    metadata
    sequenceNumber
  }
  userActions(where: {tenantId: {_eq: $tenantId}, _or: [{userId: {_eq: $userId}}, {userId: {_is_null: true}}]}) {
    status
  }
}

`;
export const GET_SINGLE_USER_ACTION_BY_ACTION_CODE = `
  query GetSingleUserActionByActionCode($userId: uuid, $actionCode: String) {
    userActions(
      where: {userId: {_eq: $userId}, actionCode: {_eq: $actionCode}}
    ) {
      actionCode
      createdOn
      id
      isDeleted
      level
      metadata
      numberOfTry
      status
      tenantId
      userId
    }
  }
`;

export const INSERT_USER_ACTION = `
  mutation InsertUserAction(
    $actionCode: String
    $level: String
    $status: String
    $userId: uuid
    $metadata: jsonb = {}
  ) {
    insertUserAction(
      object: {
        actionCode: $actionCode
        level: $level
        status: $status
        userId: $userId
        numberOfTry: 1
        metadata: $metadata
      }
      on_conflict: {
        constraint: user_action_pkey
        update_columns: status
        where: {actionCode: {_eq: $actionCode}, userId: {_eq: $userId}}
      }
    ) {
      actionCode
      id
      level
      status
      tenantId
      userId
      numberOfTry
    }
  }
`;

export const AGGREGATE_USER_ACTION = `
  query AggregateUserAction($level: String, $userId: uuid) {
    aggregateUserAction(
      where: {level: {_eq: $level}, userId: {_eq: $userId}}
      distinct_on: actionCode
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const AGGREGATE_USER_ACTION_MASTER = `
  query AggregateUserActionMaster($level: String) {
    aggregateUserActionMaster(where: {level: {_eq: $level}}) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_USER_ACTION_MASTER_DATA = `
  query GetUserActionMasterData {
    userActionMasters {
      actionCode
      display
      id
      isDeleted
      level
      mainTabCode
      sideMenuCode
    }
  }
`;

export const GET_USER_CUSTOM_ATTR = gql`
  query getUserCustomAttributes($userId:bigint!){
    user(id:$userId){
      id
      uiSettings
      subtitle
    }
  }`
export const GET_ACCOUNT_LEVEL_USER_ACTION = `
  query GetAccountLevelUserAction($tenantId: uuid, $actionCode: String) {
    userActions(
      where: {tenantId: {_eq: $tenantId}, actionCode: {_eq: $actionCode}}
    ) {
      actionCode
      createdOn
      id
      isDeleted
      level
      metadata
      numberOfTry
      status
      tenantId
      userId
    }
  }
`;

export const SET_USER_CUSTOM_ATTR = gql`
mutation updateUserCustomAttributes($userId:bigint, $settingDisplayConfig:json){
  updateUsers(where:{id:{_eq:$userId}}, _append:{uiSettings:{settingDisplayConfig:$settingDisplayConfig}}){
    returning {
      uiSettings
      id
    }
  }
}
`;

export const GET_INVITED_USERS = `
  query GetInvitedUsers($searchString: String) {
    user_reset_passwords(
      where: {
        isDeleted: {_eq: false}
        name: {_is_null: false, _ilike: $searchString}
        roleCode: {_is_null: false}
      }
    ) {
      accountUuid
      name
      userName
      roleCode
      userNameType
      userOrContactUuid
      status
      isDeleted
      isContact
      id
      uuid
    }
  }
`;

export const DELETE_USER_RESET_PASSWORD = `
  mutation DeleteUserResetPassword($userName: String, $updatedAt: timestamp) {
    update_user_reset_passwords(
      where: {userName: {_eq: $userName}}
      _set: {isDeleted: true, updatedAt: $updatedAt}
    ) {
      affected_rows
    }
  }
`;

export const GET_USERS_BY_USER_IDS_OR_EXT_USER_IDS = gql`
  query GetUserDetails($userIds: [uuid!], $externalUserIds: [String!]) {
    accountUsers(
      where: {
        _or: [
          {user: {uuid: {_in: $userIds}}}
          {user: {externalUserId: {_in: $externalUserIds}}}
          {externalUserId: {_in: $externalUserIds}}
        ]
      }
    ) {
      user {
        name
        uuid
        externalUserId
      }
      uuid
      externalUserId
    }
  }
`;

export const GetUsersByUserUuids = gql`
  query GetUserDetails($userUuids: [uuid!]) {
    accountUsers(
      where: {
        _or: [
          {
            user: {
              uuid: {
                _in: $userUuids
              }
            }
          }
        ]
      }
    ) {
      user {
        name
        uuid
        id
      }
    }
  }
`;

export const GET_USERS_WITH_ROLE = gql`
  query GetUsersWithRole($roleCodes: [String!]) {
    users(
      where: {userRoles: {userRole: {userRole: {code: {_in: $roleCodes}}}}}
    ) {
      id
      name
      uuid
      email
      externalUserId
      accountUsers {
        employerId
      }
      userRoles {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
      providerIdentifiers {
        id
        identifierTypeId
        value
        accountLocationId
        states {
          name
        }
      }
      persons {
        personContactDetails {
          id
          contactDetailId
          contactDetails {
            id
            value
            contactTypeId
            relationType {
              code
              value
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_IDENTIFIERS_WITH_UUID = gql`
  query GetUserIdentifiers($userId: uuid) {
    users(where: {uuid: {_eq: $userId}}) {
      providerIdentifiers{
        id
        identifierTypeId
        value
        accountLocationId
        states {
          name
        }
      }
      persons {
        personContactDetails {
          id
          contactDetailId
          contactDetails {
            id
            value
            contactTypeId
            relationType {
              code
              value
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_ACTIVE_INACTIVE_ACCOUNT_USER = gql`
  query GetActiveAndInActiveUsers {
      users {
        email
        name
        uuid
        externalUserId
        accountUsers {
          isActive
        }
      }
  }`;
export const GetAccountUsersByUserUuid = gql`
  query GetAccountUserBy($userUuid: uuid!) {
    accountUsers(where: {user: {uuid: {_eq: $userUuid}}}) {
      id
      externalUserId
      user {
      externalUserId
    }
    }
  }
`;

export const GET_ACCOUNT_USERS_NAME_AND_IS_ACTIVE = gql`
  query GetUsersForCalendarWithLocation($roleCode: String!) {
    users(
      where: {
        _not: {
          userRoles: {
            userRole: {userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS",$roleCode]}}}
          }
        }
      }
    ) {
      uuid
      name
      accountUsers {
        isActive
      }
    }
  }
`;

export const GET_ACCOUNT_USERS = gql`
  query GetAccountUsers {
    accountUsers {
      id
      uuid
      user {
        name
        uuid
        id
      }
    }
  }
`;

const GET_USER_PREFERENCE = gql`
  query GetUserPreference($userId: uuid!, $userPreferencesTypeId: uuid!) {
    userPreferences(
      where: {
        userPreferencesTypeId: {_eq: $userPreferencesTypeId}
        userId: {_eq: $userId}
      }
      order_by: {updatedOn: desc}
    ) {
      id
      preferencesJson
    }
  }
`;

const ADD_OR_UPDATE_USER_PREFERENCE = gql`
  mutation createOrUpdateUserPreference(
    $object: user_preferences_insert_input!
  ) {
    createUserPreference(
      object: $object
      on_conflict: {
        constraint: user_preferences_pkey
        update_columns: [preferencesJson, preferencesModelVersion]
      }
    ) {
      id
    }
  }
`;

const GET_ACCOUNT_USER_COUNT = gql`
  query GetUsersAggregate {
    userAggregate(
      where: {
        accountUsers: {isActive: {_eq: true}}
        _not: {
          userRoles: {
            userRole: {
              userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
            }
          }
        }      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_USER_COUNT = gql`
  query GetAllUsersAggregate {
    userAggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_USERS_FOR_FILTER = gql`
  query GetUsersForFilter($searchString: String!, $userUuid: uuid!) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        _or: [{name: {_ilike: $searchString}}, {email: {_ilike: $searchString}}]
        _not: {userRoles: {userRole: {userRole: {code: {_eq: "WORKFLOW"}}}}}
        uuid: {_neq: $userUuid}
      }
    ) {
      id
      uuid
      name
    }
  }
`;

export const GET_ALL_USERS_BY_IDS = gql`
  query GetAllUsers($limit: Int, $offset: Int) {
    users(
      limit: $limit,
      offset: $offset
      where: {
      _not: {
        userRoles: {
          userRole: {
            userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
          }
        }
      }      }) {
      id
      name
      uuid
      email
      userRoles(where: {isDeleted: {_eq: false}}) {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;


export const GET_ALL_USERS_WITH_ROLES = gql`
query GetAllUsers($limit: Int, $offset: Int) {
  users(limit: $limit, offset: $offset, where: {_not: {userRoles: {userRole: {userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}}}}}) {
    id
    name
    uuid
    email
    userRoles(where: {isDeleted: {_eq: false}}) {
      userRole {
        roleCategory {
          code
        }
        customRoleCode
        roleName
        userRole {
          id
          code
          value
        }
      }
    }
  }
}
`;

export const GET_ACTIVE_USERS_FOR_CALL = gql`
  query GetUserAgent {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        _not: {
          userRoles: {
            userRole: {
              userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
            }
          }
        }      }
    ) {
      id
      uid
      type
      email
      name
      subtitle
      description
      uuid
      externalUserId
      accountUsers {
        employerId
        isActive
        uuid
        account {
          uuid
        }
      }
      userRoles {
        id
        userRoleId
        userRole {
          id
          customRoleCode
          roleName
          roleCategory {
            code
          }
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;


export const GET_USER_LOCATION_BASED_ACCOUNT_USER_LIST = gql`
  query GET_USER_LOCATION_BASED_ACCOUNT_USER_LIST(
    $accountId: bigint!
    $locationUuids: [uuid!]
  ) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}, accountId: {_eq: $accountId}}
        _not: {
          userRoles: {
            userRole: {
              userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
            }
          }
        }
        _or: [
          {
            userRoles: {
              userRole: {userRole: {_and: [{code: {_eq: "GLOBAL_ADMIN"}}]}}
            }
          }
          {
            userPracticeLocations: {
              isDeleted: {_eq: false}
              accountLocations: {
                practiceLocation: {uuid: {_in: $locationUuids}}
              }
            }
          }
        ]
      }
    ) {
      id
      uid
      type
      email
      name
      subtitle
      description
      uuid
      externalUserId
      accountUsers {
        employerId
        isActive
        uuid
        account {
          uuid
        }
      }
      userRoles {
        id
        userRoleId
        userRole {
          id
          customRoleCode
          roleName
          roleCategory {
            code
          }
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;


export const GET_USERS_BY_CONDITION = gql`
  query GET_USERS_BY_CONDITION($where: users_bool_exp = {}) {
    users(where: $where) {
      id
      uuid
      name
      email
    }
  }
`;

export const GET_ELATION_USERS_HAVING_EXT_USER_IDS = gql`
  query GetElationUsersHavingExtUserIds($name: String!, $locationGroupId: uuid) {
    users(
      where: {
        accountUsers: {
          isActive: {_eq: true}
          user: {name: {_ilike: $name}}
          externalUserId: {_is_null: false}
        }
        _not: {
          userRoles: {
            userRole: {
              userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
            }
          }
        }
      }
      limit: 10
    ) {
      id
      uuid
      name
      externalUserMap (where: {locationGroupId: {_eq: $locationGroupId}}) {
        externalUserId
      }
      userRoles {
        userRole {
          customRoleCode
          roleName
          userRole {
            id
            value
            code
          }
        }
      }
    }
  }
`;

export const GET_ELATION_USERS_BY_EXTERNAL_USER_ID = gql`
  query GetElationUsersHavingExtUserIds($externalUserId: String!, $locationGroupId: uuid) {
    users(
      where: {
        accountUsers: {
          isActive: {_eq: true}
          externalUserId: {_eq: $externalUserId}
        }
        _not: {
          userRoles: {
            userRole: {
              userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
            }
          }
        }
      }
      limit: 10
    ) {
      id
      uuid
      name
      externalUserMap (where: {locationGroupId: {_eq: $locationGroupId}}) {
        externalUserId
      }
      userRoles {
        userRole {
          customRoleCode
          roleName
          userRole {
            id
            value
            code
          }
        }
      }
    }
  }
`;

export const GET_USERS_AND_CONTACTS_BY_CONDITION = gql`
  query GET_USERS_AND_CONTACTS_BY_CONDITION(
    $userWhere: users_bool_exp = {}
    $contactWhere: contacts_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 0
  ) {
    users(
      where: $userWhere
      limit: $limit
      offset: $offset
      order_by: [{name: asc}]
    ) {
      id
      uuid
      name
      email
    }
    contacts(
      where: $contactWhere
      limit: $limit
      offset: $offset
      order_by: [{name: asc}]
    ) {
      id
      uuid
      name
      email
    }
  }
`;

export const GET_ACTIVE_AND_INACTIVE_USERS_FOR_CALENDAR = gql`
  query GetUsersForCalendar($limit: Int, $offset: Int) {
    users(
      limit: $limit
      offset: $offset
      where: {
        _not: {userRoles: {userRole: {userRole: {code: {_eq: "WORKFLOW"}}}}}
      }
    ) {
      id
      name
      uuid
      email
      accountUsers {
        isActive
      }
    }
  }
`;

export const GET_ACCOUNT_ACTIVE_AND_INACTIVE_USER_COUNT = gql`
  query GetUsersAggregate {
    userAggregate(
      where: {
        _not: {userRoles: {userRole: {userRole: {code: {_eq: "WORKFLOW"}}}}}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;


export const SEARCH_USERS_BY_ROLE_CODES = gql`
  query SearchUsersByRoleCodes($where: users_bool_exp) {
    users(where: $where) {
      id
      email
      name
      uuid
      externalUserId
      userRoles {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;

export const SEARCH_USERS_BY_NAME_AND_ROLE_CODES = gql`
  query SearchUsersByNameAndRoleCodes($where: users_bool_exp) {
    users(where: $where, limit: 10) {
      id
      email
      name
      uuid
      externalUserId
      userRoles {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;

export const SEARCH_USERS_FOR_CARE_TEAM = gql`
  query SearchUsersByNameAndRoleCodes($where: users_bool_exp) {
    users(where: $where, limit: 10) {
      id
      email
      name
      uuid
      externalUserId
      userRoles {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;


export const GET_ALL_USER_COUNT_FOR_SPECIFIC_ROLES = gql`
  query GetAllUsersAggregate {
    userAggregate(where: {_not: {userRoles: {userRole: {userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}}}}}) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ACTIVE_INACTIVE_ACCOUNT_USERS = gql`
  query GetActiveAndInActiveAccountUsers($userIds: [uuid!]!) {
    accountUsers(where: {isDeleted: {_eq: false}, user: {uuid: {_in: $userIds}}}) {
      id
      uuid
      isActive
      user {
        name
        uuid
        id
      }
    }
  }
`;


export const GET_USERS_FOR_TASK_POOL_ADD_UPDATE = gql`
  query GetUsersForIVR($userRoleCodes: [String!]!) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}, isDeleted: {_eq: false}}
        _not: {
          userRoles: {
            userRole: {userRole: {code: {_in: $userRoleCodes}}}
          }
        }
      }
    ) {
      id
      name
      uuid
      email
      userRoles(where: {isDeleted: {_eq: false}}) {
        userRole {
          customRoleCode
          roleName
          userRole {
            code
            value
            id
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        uuid
        practiceLocationUuid
        accountLocation {
          uuid
          practiceLocation {
            uuid
            name
            addressLine1
            addressLine2
            cityUuid
            stateUuid
            countryUuid
            practiceZipcode {
              code
              cityId
              stateId
            }
            practiceState {
              name
            }
            practiceCountry {
              name
            }
            practiceCity {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_ACTIVE_USERS = gql`
query GetAllUserList($accountId: bigint) {
  users(where: {isDeleted: {_eq: false}, accountUsers: {accountId: {_eq: $accountId}, isActive: {_eq: true}}}) {
    id
    email
    name
    uuid
    accountUsers {
      userId
      accountId
      isActive
      uuid
    }
  }
}
`

export const GET_ALL_DISTINCT_USER_ROLES =  gql`
query GetAllUserRoleList {
  accountRoles(distinct_on: roleId) {
    id
    roleId
    roleName
    customRoleCode
    userRole {
      code
      value
      id
    }
  }
}
`

export const GET_USER_BY_UUID_LIST = gql`
  query getUserByUuidList($uuidList: [uuid!]) {
    users(where: {uuid: {_in: $uuidList}, isDeleted: {_eq: false}}) {
      id
      uuid
      name
    }
  }
`;

export const GET_USERS_DATA_BY_ID = gql`
  query getUserByUuidList($ids: [bigint!]) {
    users(where: {id: {_in: $ids}, isDeleted: {_eq: false}}) {
      id
      uuid
      name
    }
  }
`;

export const GET_USERS_FOR_TIME_LOG_PERFORMER = gql`
  query GetAllUsers($limit: Int, $offset: Int) {
    users(limit: $limit, offset: $offset, where: {accountUsers: {isActive: {_eq: true}}, userRoles: {userRole: {userRole: {code: {_in: ["CARE_PROGRAM_MANAGER", "CLINICAL_NURSE_SPECIALIST", "CERTIFIED_NURSE_MIDWIVES", "NURSE_PRACTITIONER", "PHYASST", "PHYSICIAN", "QUALIFIED_HEALTH_PROFESSIONAL"]}}}}}) {
      id
      name
      uuid
      email
      userRoles(where: {isDeleted: {_eq: false}}) {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;

export const GET_ALL_USER_COUNT_FOR_TIME_LOG_PERFORMER = gql`
  query GetAllUsersAggregate {
    userAggregate(where: {accountUsers: {isActive: {_eq: true}}, userRoles: {userRole: {userRole: {code: {_in: ["CARE_PROGRAM_MANAGER", "CLINICAL_NURSE_SPECIALIST", "CERTIFIED_NURSE_MIDWIVES", "NURSE_PRACTITIONER", "PHYASST", "PHYSICIAN", "QUALIFIED_HEALTH_PROFESSIONAL"]}}}}}) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_USERS_BY_IDS_FOR_POP_GROUP_AUDIT = gql`
  query GetAllUsers($limit: Int, $offset: Int) {
    users(limit: $limit, offset: $offset, where: {isDeleted: {_eq: false}}) {
      id
      name
      uuid
      email
      userRoles(where: {isDeleted: {_eq: false}}) {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;

export const GET_ALL_USER_COUNT_FOR_GROUP_DETAILS_AUDIT = gql`
  query GetAllUsersAggregate {
    userAggregate(where: {accountUsers: {isDeleted: {_eq: false}}, userRoles: {isDeleted: {_eq: false}}}) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_USER_BY_USER_ID = gql`
  query GetUsersWithIds($userIds: uuid!, $accountId: bigint) {
    users(
      where: {
        uuid: {_eq: $userIds}
        accountUsers: {accountId: {_eq: $accountId}}
      }
    ) {
      id
      name
      uuid
      email
    }
  }
`;

export const GET_USERS_BY_LOCATION_UUIDS = gql`
  query GetUsersByLocationUuid(
    $searchString: String
    $locationUuids: [uuid!]
    $limit: Int
  ) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        userPracticeLocations: {
          isDeleted: {_eq: false}
          accountLocations: {practiceLocation: {uuid: {_in: $locationUuids}}}
        }
        name: {_ilike: $searchString}
        isDeleted: {_eq: false}
        _not: {
          userRoles: {
            userRole: {
              userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
            }
          }
        }
      }
      limit: $limit
    ) {
      id
      name
      uuid
      email
      externalUserId
      userRoles {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;

export const GET_USERS_BY_IVR_AND_LOCATION_UUID = gql`
  query GetUsersForIVR($roleCodes: [String!]!, $locationUuids: [uuid!]) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        userPracticeLocations: {
          isDeleted: {_eq: false}
          accountLocations: {practiceLocation: {uuid: {_in: $locationUuids}}}
        }
        _not: {
          userRoles: {
            userRole: {
              userRole: {
                code: {_in: $roleCodes}
              }
            }
          }
        }
      }
    ) {
      id
      name
      uuid
      email
      userRoles {
        userRole {
          customRoleCode
          roleName
          userRole {
            value
          }
        }
      }
    }
  }
`;

export const SEARCH_USER_BY_TEXT_BY_LOCATION_UUID = gql`
  query GetUsersByTextByLocationUuid(
    $searchString: String
    $limit: Int
    $locationUuids: [uuid!]
    $boolExp: users_bool_exp!
  ) {
    users(
      where: {
        _or: [
          $boolExp,
          {
            accountUsers: {isActive: {_eq: true}},
            name: {_ilike: $searchString},
            userPracticeLocations: {
              isDeleted: {_eq: false}
              accountLocations: {uuid: {_in: $locationUuids}}
            }
            isDeleted: {_eq: false},
            _not: {
              userRoles: {
                userRole: {
                  userRole: {code: {_in: ["WORKFLOW", "CUSTOMER_SUCCESS"]}}
                }
              }
            }
          }
        ]
      }
      limit: $limit
    ) {
      id
      name
      uuid
      email
      externalUserId
      userRoles {
        userRole {
          customRoleCode
          roleName
          roleCategory {
            code
          }
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;

export const GET_USERS_BY_LOCATION_ID_WITHOUT_EMPLOYER = gql`
  query GetUserAgentWithoutEmployer(
    $roleCode: String!
    $locationUuid: uuid!
  ) {
    users(
      where: {
        accountUsers: {isActive: {_eq: true}}
        _or: [
          {
            userRoles: {
              userRole: {
                userRole: {
                  _and: [
                    {code: {_neq: $roleCode}}
                    {code: {_eq: "GLOBAL_ADMIN"}}
                  ]
                }
              }
            }
          }
          {
            userRoles: {userRole: {userRole: {code: {_neq: $roleCode}}}}
            userPracticeLocations: {
              isDeleted: {_eq: false}
              accountLocations: {practiceLocation: {uuid: {_eq: $locationUuid}}}
            }
          }
        ]
      }
    ) {
      id
      uid
      type
      email
      name
      subtitle
      description
      uuid
      externalUserId
      accountUsers {
        isActive
        uuid
        account {
          uuid
        }
      }
      userRoles {
        id
        userRoleId
        userRole {
          id
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        userUuid
        uuid
        isDeleted
        accountLocations(where: {isDeleted: {_eq: false}}) {
          uuid
          isDeleted
          practiceLocation {
            isDeleted
            name
            uuid
            timezoneUuid
            practiceCity {
              name
              id
            }
            practiceCountry {
              name
              id
            }
            practiceState {
              name
            }
            zipcodeUuid
            practiceZipcode {
              areaCode
              code
              id
            }
          }
        }
        practiceLocation {
          name
          uuid
          timezoneUuid
          practiceCity {
            name
            id
          }
          practiceCountry {
            name
            id
          }
          practiceState {
            name
          }
          zipcodeUuid
          practiceZipcode {
            areaCode
            code
            id
          }
        }
      }
    }
  }
`;

const GET_LOGGED_IN_USER_LOCATION_DETAILS = gql`
  query GetSingleUser($userId: uuid) {
    users(where: {uuid: {_eq: $userId}}) {
      id
      name
      uuid
      email
      externalUserId
      userPracticeLocations(
        where: {
          isDeleted: {_eq: false}
          accountLocation: {practiceLocation: {isDeleted: {_eq: false}}}
        }
      ) {
        accountLocation {
          id
          uuid
          locationGroupId
        }
      }
    }
  }
`;

export const GET_SEARCH_USERS_WITHOUT_AGGREGATE = gql`
  query SearchUsers($params: SearchUsersInput!) {
    searchUsers(params: $params) {
      users {
        id
        uuid
        name
        email
        userRoles {
          id
          accountLocationId
          userRole {
            id
            roleId
            roleName
            customRoleCode
          }
        }
        userPracticeLocations {
          uuid
          accountLocationUuid
          accountLocation {
            uuid
          }
        }
      }
    }
  }
`;

export const GET_USER_DATA_WITH_ACCOUNT_LOCATION_ID_BY_USER_UUID = gql`
  query GetUserDataWithAccountLocationIdByUserUuid($userUuid: uuid!) {
    users(where: {uuid: {_eq: $userUuid}, isDeleted: {_eq: false}}) {
      id
      name
      uuid
      email
      externalUserId
      externalUserMap {
        locationGroupId
        externalUserId
      }
      accountUsers(where: {isDeleted: {_eq: false}}) {
        employerId
        externalUserId
        isActive
      }
      userRoles(where: {isDeleted: {_eq: false}}) {
        userRole {
          roleCategory {
            code
          }
          customRoleCode
          roleName
          userRole {
            id
            code
            value
          }
        }
      }
      userPracticeLocations(where: {isDeleted: {_eq: false}}) {
        id
        uuid
        practiceLocationUuid
        accountLocation {
          uuid
          locationGroupId
          practiceLocation {
            uuid
            name
            addressLine1
            addressLine2
            cityUuid
            stateUuid
            countryUuid
            practiceZipcode {
              code
              cityId
              stateId
            }
            practiceState {
              name
            }
            practiceCountry {
              name
            }
            practiceCity {
              name
            }
          }
        }
      }
    }
  }
`;

export default {
  GET_ACCOUNT_USER_COUNT,
  ADD_OR_UPDATE_USER_PREFERENCE,
  GET_USER_PREFERENCE,
  GET_SINGLE_USER,
  GET_USERS_FOR_CALENDAR,
  GET_USERS_FOR_CALENDAR_V2,
  SEARCH_USER_BY_TEXT,
  SEARCH_USER_BY_TEXT_WITH_OFFSET,
  GET_USERS_WITH_IDS,
  GET_USERS,
  GET_USERS_WITHOUT_EMPLOYER,
  GET_USER_TASK_DISPLAY_CATEGORY,
  GET_USERS_BY_ROLE_IDS,
  GET_USER_ROLE_CATEGORIES,
  CURRENT_USER_ROLE,
  USER_PERMISSIONS,
  UPDATE_USER,
  CREATE_PRACTICE_LOCATION,
  CREATE_ACCOUNT_LOCATION,
  GET_ACTIVE_USERS_BY_ROLE_IDS,
  GetUserDetails,
  Get_Users_IVR,
  GET_USERS_FOR_CALENDAR_WITH_LOCATION,
  GET_USERS_FOR_CALENDAR_BY_LOCATION,
  GET_DEFAULT_ROLES_FOR_JOURNEY,
  GET_USER_FOR_SCHEDULE_ACCESS,
  GET_USER_FOR_TASKS,
  GET_ALL_USERS_FOR_CALENDAR,
  GET_MANAGER_ROLES,
  GET_USERS_BY_ID,
  UpdateAccountUserWithExternalUserId,
  GET_CONTACTS_BY_ID,
  GET_USER_ACTION_MASTERS,
  GET_SINGLE_USER_ACTION_BY_ACTION_CODE,
  INSERT_USER_ACTION,
  AGGREGATE_USER_ACTION,
  AGGREGATE_USER_ACTION_MASTER,
  GET_ALL_USER_ACTION_MASTERS_DATA,
  GET_USER_ACTION_MASTER_DATA,
  GET_USER_CUSTOM_ATTR,
  SET_USER_CUSTOM_ATTR,
  GET_ACCOUNT_LEVEL_USER_ACTION,
  UPDATE_PRACTICE_LOCATION,
  UPDATE_ACCOUNT_LOCATION,
  UPDATE_ACCOUNT_LOCATION_CONTAINING_MAPPED_LOCATION,
  GET_INVITED_USERS,
  DELETE_USER_RESET_PASSWORD,
  DELETE_PRACTICE_LOCATION,
  GET_USERS_FOR_CALENDAR_WITHOUT_EMPLOYER,
  GetUsersByUserUuids,
  GET_USERS_WITH_ROLE,
  GET_USER_IDENTIFIERS_WITH_UUID,
  GET_ACTIVE_INACTIVE_ACCOUNT_USER,
  GetAccountUsersByUserUuid,
  GET_USERS_WITH_LOCATION_AND_ROLES_FROM_IDS,
  GET_ACCOUNT_USERS_NAME_AND_IS_ACTIVE,
  GET_ACCOUNT_USERS,
  GET_USERS_WITH_INT_IDS,
  GET_USERS_FOR_FILTER,
  GET_ALL_USERS_BY_IDS,
  GET_ACTIVE_USERS_FOR_CALL,
  GET_USER_LOCATION_BASED_ACCOUNT_USER_LIST,
  GET_USERS_BY_CONDITION,
  GET_USERS_AND_CONTACTS_BY_CONDITION,
  GET_ACTIVE_AND_INACTIVE_USERS_FOR_CALENDAR,
  GET_ACCOUNT_ACTIVE_AND_INACTIVE_USER_COUNT,
  SEARCH_USERS_BY_ROLE_CODES,
  GET_ALL_USERS_WITH_ROLES,
  GET_ALL_USER_COUNT,
  GET_ACTIVE_INACTIVE_ACCOUNT_USERS,
  GET_USERS_FOR_TASK_POOL_ADD_UPDATE,
  GET_ALL_ACTIVE_USERS,
  GET_ALL_DISTINCT_USER_ROLES,
  GET_USER_BY_UUID_LIST,
  GET_USERS_FOR_TIME_LOG_PERFORMER,
  GET_ALL_USERS_BY_IDS_FOR_POP_GROUP_AUDIT,
  GET_ALL_USER_COUNT_FOR_GROUP_DETAILS_AUDIT,
  GET_USER_BY_USER_ID,
  GET_USERS_BY_LOCATION_ID_WITHOUT_EMPLOYER,
  GET_USERS_BY_LOCATION_UUIDS,
  GET_USERS_BY_IVR_AND_LOCATION_UUID,
  SEARCH_USER_BY_TEXT_BY_LOCATION_UUID,
  GET_USERS_DATA_BY_ID,
  GET_USERS_BY_IVR_AND_LOCATION_UUID_WITH_SEARCH,
  GET_LOGGED_IN_USER_LOCATION_DETAILS,
  GET_USERS_BY_RESOURCE_CODES,
  GET_USER_BY_UUID,
  GET_USER_DATA_WITH_ACCOUNT_LOCATION_ID_BY_USER_UUID,
  GET_ACTIVE_USERS,
  Get_Users_IVR_With_Multiple_Role_Codes,
};
